import React, { Component } from 'react';
import { Row, Col, Input, Select, Button, Table, Popconfirm, Form, message } from 'antd';
import { ShareAltOutlined,DeleteOutlined } from '@ant-design/icons';
import { randomFixedInteger, isValidEmail } from '..';
import URL_WITH_VERSION, { postAPICall, openNotificationWithIcon } from '../../shared';

const { Option } = Select;
const FormItem = Form.Item;

class ShareLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          title: 'Email',
          width: 150,
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Access',
          width: 60,
          dataIndex: 'access',
          key: 'access',
        },
        {
          title: 'Passcode',
          width: 60,
          dataIndex: 'passcode',
          key: 'passcode',
        },
        {
          title: 'Action',
          width: 150,
          key: 'action',
          fixed: 'right',
          render: (text, record) => {
            return (
              <>
                <span>
                  <div className="editable-row-operations">
                    <span className="iconWrapper cancel">
                      <Popconfirm title="Are you sure, you want to delete it?" onConfirm={() => this.onDelete(record.key)}>
                       <DeleteOutlined /> 
                      </Popconfirm>
                    </span>
                  </div>
                  <div className="editable-row-operations">
                    <span className="iconWrapper" onClick={() => this.share(record.email, record.passcode, record.access)}>
                    <ShareAltOutlined />
                    </span>
                  </div>
                </span>
              </>
            )
          }
        },
      ],
      users: [], // { email: 'neel@theoceann.com', passcode: '123456' }, { email: 'prashant@theoceann.com', passcode: '123456' }
      email: '',
      access: 'Read Only',
      invalidEmail: false,
      vesselId: props.vesselId,
    }
  }

  handleAccessChange = (accesss) => {
    this.setState({ ...this.state, access: accesss })
  }

  onDelete = (key) => {
    const users = [...this.state.users];
    this.setState({ users: users.filter(item => item.key !== key) });
  }

  addToList = () => {
    if (this.state.email.length < 1) {
      return message.error('Please enter user email.');
    }
    if (!isValidEmail(this.state.email)) {
      return message.error('Please enter valid email.');
    }
    const random = randomFixedInteger(6)
    const newUsers = [...this.state.users, { email: this.state.email, passcode: random, access: this.state.access }]
    this.setState({ ...this.state, users: newUsers, email: '' })
  }

  share = (email, passcode, access) => {

    const suURL = `${URL_WITH_VERSION}/vessel/vesselattachment/share`;
    const suMethod = 'POST';
    const data = {
      email,
      passcode,
      access: access === 'Read Only' ? 0 : 1,
      vessel_id: this.state.vesselId
    }
    postAPICall(suURL, data, suMethod, (data) => {
      if (data.data)
        openNotificationWithIcon('success', 'Documents shared successfully.');
      else 
        openNotificationWithIcon('error', 'Failed to share link via email.', 5);
    });
  }

  render() {
    return (
      <>
        {'Add User'}
        <Form>
          <Row gutter={16} style={{ marginRight: 0 }}>
            <Col>
              <FormItem
                label="Email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <Input size="default" defaultValue="" value={this.state.email} placeholder={'Enter Email'} onChange={({ target }) => this.setState({ ...this.state, email: target.value })} />
              </FormItem>
            </Col>
            <Col>
              <FormItem label="Access" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Select defaultValue="Read Only" style={{ width: 160 }} onChange={(e) => this.handleAccessChange(e)}>
                  <Option value="Read Only">Read Only Access</Option>
                  <Option value="Read Write">Read Write Access</Option>
                </Select>
              </FormItem>
            </Col>
            <Col>
              <FormItem label="Action" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Button onClick={this.addToList}>
                  Add to List
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>

        <Row style={{ marginTop: 20 }}>
          <Table
            bordered
            columns={this.state.columns}
            dataSource={this.state.users}
            scroll={{
              y: 400
            }}
            className="inlineTable resizeableTable"
            size="small"
            pagination={false}
            rowClassName={(r, i) =>
              i % 2 === 0 ? 'table-striped-listing' : 'dull-color table-striped-listing'
            }
            // footer={() => <div className="text-center">
            //   <Button type="link" onClick={this.share}>Share</Button>
            // </div>
            // }
          />
        </Row>
      </>
    );
  }
}

export default ShareLink;
