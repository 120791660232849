import URL_WITH_VERSION, { awaitPostAPICall, getAPICall } from "../shared";

export const getAttachments = async (id, type) => {
  const url = `${process.env.REACT_APP_ATTACHMENT}/importexport/fetch?id=${id}&type=${type}`
  const response = await getAPICall(url);
  return response['data'] || []
}


// export const getAttachments = async (id, type) => {
//   const url = `${URL_WITH_VERSION}/importexport/fetch?id=${id}&type=${type}`
//   const response = await getAPICall(url);
//   return response['data'] || []
// }


export const deleteAttachment = async (fileUrl, fileName, type, bucket) => {
  const url = `${process.env.REACT_APP_ATTACHMENT}/importexport/delete?url=${fileUrl}&type=${type}&file-name=${fileName}&bucket=${bucket}`
  return await awaitPostAPICall(url);
}

export const uploadAttachment = async (data, id, type, bucket) => {
  const item = data[data.length - 1];
  const url = `${process.env.REACT_APP_ATTACHMENT}/importexport/save?id=${id}&type=${type}&content-type=${item.contentType}&bucket=${bucket}`
 return await awaitPostAPICall(url, item)
}