import React, { Component } from 'react';
import { Button, Tooltip } from 'antd';
import {PrinterOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
// import '../print-report.scss';

class ComponentToPrint extends React.Component {
  render() {
    return (
      <article className="article">
        <div className="box box-default print-wrapper">
          <div className="box-body">
            <div className="invoice-wrapper">
              <section className="invoice-container hire" id="invoice">
                <div className="invoice-inner">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center">
                        <span className="title">ABC</span>
                        <p className="sub-title">Louis Dreyfus Company</p>
                      </div>
                    </div>
                  </div>

                  <div className="row view-list">
                    <div className="col-12">
                      <div className="sub-heading text-right">Other Report</div>
                    </div>

                    <div className="col-12">
                      <div className="wrap-data border-line">
                        <div className="col-4 text-left">
                          <ul className="list-unstyled">
                            <li>
                              <strong>Vessel:</strong> GOLDEN ROSE
                            </li>
                            <li>
                              <strong>Delivery:</strong> May 05 2021
                            </li>
                          </ul>
                        </div>

                        <div className="col-4 text-left">
                          <ul className="list-unstyled">
                            <li>
                              <strong>CP Date:</strong> May 05 2021
                            </li>
                            <li>
                              <strong>Redelivery:</strong> May 05 2021
                            </li>
                          </ul>
                        </div>

                        <div className="col-4 text-left">
                          <ul className="list-unstyled">
                            <li>
                              <strong>Charterered From:</strong> GOLDEN ROSE INC.
                            </li>
                            <li>
                              <strong>Printed On:</strong> May 05 2021
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="divider my-2" />

                  <div className="row ">
                    <div className="col-md-12">
                      <div className="table-responsive custom-table">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" rowSpan="3">
                                Gross Hire
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                10/05/2021 15:36 - 08/07/19 02:18, 94.445833 @ 13,250.00
                              </th>
                              <td></td>
                              <td className="text-right no-padding">1,251,407.29</td>
                            </tr>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                100.00% OFF HIRE-PERF. CLAIMS (07/31/19 00:00 - 07/31/19 02:19,
                                0.097083 Days): ECa zone perf.claim
                              </th>
                              <td className="text-right no-padding">(1,286.35)</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th scope="row">Total Off Hire</th>
                              <td></td>
                              <td className="text-right">
                                <div className="amount">(1,286.35)</div>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className="no-top-padding">
                                Net Hire
                              </th>
                              <td></td>
                              <td className="text-right no-top-padding">1,250,120.94</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="spacer" />

                    <div className="col-md-12">
                      <div className="table-responsive custom-table">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" rowSpan="3">
                                Hire Commissions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                Address Commissions (05/04/19 15:36 - 08/07/2021 02:18, 3.75%)
                              </th>
                              <td></td>
                              <td className="text-right no-padding">(1,251,407.29)</td>
                            </tr>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                100.00% OFF HIRE Address Commission (07/31/19 00:00 - 07/31/19
                                02:19, 3.75%)
                              </th>
                              <td></td>
                              <td className="text-right no-padding">48.24</td>
                            </tr>
                            <tr>
                              <th scope="row">Total Hire Commissions</th>
                              <td></td>
                              <td className="text-right">
                                <div className="amount">(46,879.54)</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="spacer" />

                    <div className="col-md-12">
                      <div className="table-responsive custom-table">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" rowSpan="3">
                                Bunker Value on Delivery
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                HFO Delivery (1,247.55 MT @425.00)
                              </th>
                              <td className="text-right no-padding">530,168.38</td>
                              <td></td>
                            </tr>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                MGO Delivery (128.261 MT @650.00)
                              </th>
                              <td className="text-right no-padding">83,369.65</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th scope="row">Total Bunker Value At Delivery</th>
                              <td></td>
                              <td className="text-right">
                                <div className="amount">613,538.03</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="spacer" />

                    <div className="col-md-12">
                      <div className="table-responsive custom-table">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" rowSpan="3">
                                Bunker Value on Delivery
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                HFO Delivery (1,247.55 MT @425.00)
                              </th>
                              <td className="text-right no-padding">530,168.38</td>
                              <td></td>
                            </tr>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                MGO Delivery (128.261 MT @650.00)
                              </th>
                              <td className="text-right no-padding">83,369.65</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th scope="row">Total Bunker Value At Redelivery</th>
                              <td></td>
                              <td className="text-right">
                                <div className="amount">613,538.03</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="spacer" />

                    <div className="col-md-12">
                      <div className="table-responsive custom-table">
                        <table className="table">
                          {/* <thead>
                                                        <tr>
                                                            <th scope="col" rowSpan="3">Bunker Value on Delivery</th>
                                                        </tr>
                                                    </thead> */}
                          <tbody>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                OFF HIRE Cable / Gratuities @1,500.00 / Month (07/31/19 00:00 -
                                07/31/19 02:19)
                              </th>
                              <td className="text-right no-padding">(4.70)</td>
                              <td></td>
                            </tr>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                Cable / Gratuities @ 1,500 / Month (05/04/19 15:36 - 08/07/19 02:18)
                              </th>
                              <td></td>
                              <td className="text-right no-padding">4,618.35</td>
                            </tr>
                            <tr>
                              <th scope="row">Total Cable / Gratuities</th>
                              <td></td>
                              <td className="text-right">
                                <div className="amount">4,613.65</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="spacer" />

                    <div className="col-md-12">
                      <div className="table-responsive custom-table">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" rowSpan="3">
                                Other Adjustment
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                100.00% OFF HIRE MGO 1.480 MT @650.00 (07/31/19 00:00 - 07/31/19
                                02:19, 0.097083 Days)
                              </th>
                              <td className="text-right no-padding">(962.00)</td>
                              <td></td>
                            </tr>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                ILOHC (D002875-I001-3, 08/05/2019)
                              </th>
                              <td className="text-right no-padding">5,000.00</td>
                              <td></td>
                            </tr>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                Off hire survey (3 party B+c cost) (D00289-I0001-3, 08/05/2019)
                              </th>
                              <td className="text-right no-padding">(600.00)</td>
                              <td></td>
                            </tr>
                            <tr>
                              <th scope="row">Total Adjustment</th>
                              <td></td>
                              <td className="text-right">
                                <div className="amount">3,438.00</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="spacer" />

                    <div className="col-md-12">
                      <div className="table-responsive custom-table">
                        <table className="table">
                          <thead>
                            <tr className="border-bottom-line">
                              <th scope="col" rowSpan="3">
                                Payment
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                Amount Paid On 05/22/2019
                              </th>
                              <td className="text-right no-padding">1,024,169.35</td>
                              <td></td>
                            </tr>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                Amount Paid On 07/22/2019
                              </th>
                              <td className="text-right no-padding">140,816.63</td>
                              <td></td>
                            </tr>
                            <tr className="content">
                              <th scope="row" className="no-padding">
                                Amount Paid On 08/22/2019
                              </th>
                              <td className="text-right no-padding">48,692.21</td>
                              <td></td>
                            </tr>

                            <tr className="border-bottom-line">
                              <th scope="row">Total Amount Paid</th>

                              <td className="text-right">
                                <div>3,438.00</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

class OtherStatement extends Component {
  constructor() {
    super();
    this.state = {
      name: 'Printer',
    };
  }

  printReceipt() {
    window.print();
  }

  render() {
    return (
      <div className="body-wrapper modalWrapper modal-report-wrapper">
        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <div className="toolbar-ui-wrapper">
                <div className="leftsection">
                  <span key="first" className="wrap-bar-menu">
                    <ul className="wrap-bar-ul">
                      <li>
                        <Tooltip title="Back">
                          <Button
                            type="dashed"
                            shape="circle"
                            icon="double-left"
                            size={'default'}
                            onClick={() => this.props.changeOtherReport(true)}
                          />
                        </Tooltip>
                      </li>
                      <li>
                        <span className="text-bt">Other Report</span>
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="rightsection">
                  <span key="first" className="wrap-bar-menu">
                    <ul className="wrap-bar-ul">
                      <li>
                        <ReactToPrint
                          trigger={() => (
                            <span className="text-bt">
                             <PrinterOutlined /> Print
                            </span>
                          )}
                          content={() => this.componentRef}
                        />
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="article">
          <div className="box box-default">
            <div className="box-body print-scroll">
              <ComponentToPrint ref={el => (this.componentRef = el)} />
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default OtherStatement;
