import React, { forwardRef, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Modal,
  Radio,
  Checkbox,
  Spin,
  Alert,
  Tooltip,
  Button,
} from "antd";
import NormalFormIndex from "../../NormalForm/normal_from_index";
import TcoConfirmStatement from "../../../routes/chartering/routes/make-payment/TcoConfirmStatement";
import StatementOfAccountReport from "../All-Print-Reports/StatementOfAccountReport";
//import MakePayment from '../../../components/MakePayment';
import SOARecapReport from "../All-Print-Reports/SOARecapReport";

import URL_WITH_VERSION, {
  getAPICall,
  useStateCallback,
} from "../../../shared";
import OtherStatement from "../other-statement/OtherStatement";

const FormItem = Form.Item;

const getFormItemLayout = () => {
  return {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
};

const HireStatement = forwardRef((props, ref) => {
  const [state, setState] = useStateCallback({
    showDiv: true,
    showDivAccount: true,
    showDivRecap: true,
    showDivOtherReport: true,
    value: 1,
    frmName: "tco_hire_payment_form",
    externalFormFields: null,
    visible: false,
    tciID: props.tciID,
    formData: props.paymentData || {},
    paymentFormData: props.paymentFormData || {},
    paymentData: null,
    showForm: false,
    // totalAmountpaid: 0,
  });
  const totalAmountpaidRef = useRef(0);
  const changeState = (showDiv) =>
    setState((prevState) => ({ ...prevState, showDiv }));

  const changeAccountState = (showDivAccount) =>
    setState((prevState) => ({ ...prevState, showDivAccount }));
  const changeOtherReport = (showDivOtherReport) =>
    setState((prevState) => ({ ...prevState, showDivOtherReport }));

  const changeRecapState = (showDivRecap) =>
    setState((prevState) => ({ ...prevState, showDivRecap }));

  const onChange = (e) =>
    setState((prevState) => ({ ...prevState, value: e.target.value }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        //await updatePaymentRows(state.formData, state.tciID, state.paymentFormData);
        await updatePaymentRows(
          props.paymentData,
          props.tciID,
          props.paymentFormData
        );
        setState((prevState) => ({
          ...prevState,
          showForm: true,
          externalFormFields: {
            isShowInMainForm: true,
            content: getExternalFormFields(),
          },
        }));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [props]);

  const getExternalFormFields = () => {
    return (
      <>
        <Row style={{ width: "97%", margin: "10px auto" }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Radio.Group onChange={onChange} value={state.value}>
              <Radio value={1}>Invoiced</Radio>
              {/* <Radio value={2}>Paid</Radio> */}
            </Radio.Group>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            {/* <Checkbox.Group onChange={() => { }}>
            <Row>
              <Col span={24}>
                <Checkbox className="one-by-one" value="A">Hide Lines with Zero Amount</Checkbox>
                <Checkbox className="one-by-one" value="B">Exclude Pending Invoices</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group> */}
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <FormItem {...getFormItemLayout()} label="Total">
              <Input
                placeholder="0.00"
                style={{ textAlign: "right" }}
                disabled
                value={totalAmountpaidRef.current}
              />
            </FormItem>
          </Col>
        </Row>

        {/* <Row style={{ width: '100%' }}>
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <FormItem
            {...getFormItemLayout()}
            label="Display Rebillable Admin Fee As: ">
            <Input placeholder="Please Enter Value For Display Rebillable Admin Fee As" style={{ textAlign: 'right' }} />
          </FormItem>
        </Col>
      </Row> */}
      </>
    );
  };

  const updatePaymentRows = async (formData, tciID, paymentFormData) => {
    setState((prevState) => ({
      ...prevState,
      showForm: false,
    }));
    let response = await getAPICall(
      `${URL_WITH_VERSION}/make_payment/tco-list?l=0`,
      {
        id: {
          where:
            "`vessel_id`= " +
            tciID.vessel_id +
            " AND `tc_id`='" +
            tciID.tc_id +
            "'",
        },
      }
    );
    let respData = await response["data"];

    let totalAmount = 0;
    if (respData) {
      respData.map((e) => {
        totalAmount = totalAmount + e.amount_usd.replaceAll(",", "") * 1;
      });
    }

    formData["-"] = respData;
    totalAmountpaidRef.current = totalAmount;
    setState((prevState) => ({
      ...prevState,
      formData: formData,
      paymentFormData: paymentFormData,
      // totalAmountpaid: totalAmount,

      tciID: tciID,
      // showForm: true
    }));
  };

  const {
    frmName,
    externalFormFields,
    showDiv,
    showDivAccount,
    showDivOtherReport,
    showDivRecap,
    formData,
    showForm,
    tciID,
  } = state;
  return (
    <>
      {(() => {
        if (showDivAccount === false) {
          return (
            <div className="reports-container">
              <StatementOfAccountReport
                changeAccountState={(e1) => changeAccountState(e1)}
              />
            </div>
          );
        } else if (showDivOtherReport === false) {
          return (
            <div className="reports-container">
              <OtherStatement
                changeOtherReport={(e3) => changeOtherReport(e3)}
              />
            </div>
          );
        } else if (showDivRecap === false) {
          return (
            <div className="reports-container">
              <SOARecapReport changeRecapState={(e2) => changeRecapState(e2)} />
            </div>
          );
        } else if (showDiv === true) {
          return (
            <div className="body-wrapper modalWrapper" ref={ref}>
              <article className="article ">
                <div className="box box-default">
                  <div className="box-body">
                    {externalFormFields && showForm ? (
                      <NormalFormIndex
                        key={"key_" + frmName + "_0"}
                        formClass="label-min-height"
                        formData={formData}
                        showForm={true}
                        frmCode={frmName}
                        inlineLayout={true}
                        extraFormFields={externalFormFields}
                        showToolbar={[
                          {
                            isLeftBtn: [],
                            isRightBtn: [
                              {
                                key: "s2",
                                isSets: [
                                  // { key: "make_payment", isDropdown: 0, withText: "Make Payment", type: "", menus: null},
                                  {
                                    key: "hire_statement",
                                    isDropdown: 0,
                                    withText: "Hire Statement",
                                    type: "",
                                    menus: null,
                                    event: (key) => {
                                      changeState(!showDiv);
                                    },
                                  },
                                  // { key: "statementof_account", isDropdown: 0, withText: "Statement of Account", type: "", menus: null, "event": (key) => { }},   //"event": (key) => //{ changeAccountState(!showDivAccount) }},
                                  // { key: "other_report", isDropdown: 0, withText: "Other Report", type: "", menus: null, "event": (key) => { }},   //{ changeOtherReport(!showDivOtherReport) }},
                                  {
                                    key: "print",
                                    isDropdown: 0,
                                    withText: "Print",
                                    type: "",
                                    menus: null,
                                    event: (key) => {
                                      props.handlePrint();
                                    },
                                  },
                                ],
                              },
                            ],
                            isResetOption: false,
                          },
                        ]}
                        // extraTableButton={{
                        //   "-": [{"icon": "eye", "onClickAction": (action) => { console.log(action) }}],
                        // }}
                      />
                    ) : (
                      <div className="col col-lg-12">
                        <Spin tip="Loading...">
                          <Alert
                            message=" "
                            description="Please wait..."
                            type="info"
                          />
                        </Spin>
                      </div>
                    )}
                  </div>
                </div>
              </article>
            </div>
          );
        }
      })()}

      {showDiv === false ? (
        // <div className="reports-container"><HireStatementReport changeState={(e) => changeState(e)} /></div>
        <Modal
          title="Hire Statement"
          open={!showDiv}
          width="80%"
          onCancel={() => changeState(true)}
          style={{ top: "10px" }}
          bodyStyle={{ maxHeight: 790, overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          <TcoConfirmStatement
            vesselID={tciID.vessel_id}
            chartrerID={tciID.chartrer_id}
            tcoID={tciID.tco_id}
            tcocode={tciID.tc_id}
            from={tciID.delivery_date}
            to={tciID.redelivery_date}
          />
        </Modal>
      ) : undefined}
    </>
  );
});

export default HireStatement;
