import React, { Component } from "react";
import { Button, Layout,  Drawer, Row, Col, Modal, Tooltip } from "antd";
import { RightOutlined, PlusOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import NormalFormIndex from "../../../../shared/NormalForm/normal_from_index";
import URL_WITH_VERSION, {
  postAPICall,
  openNotificationWithIcon,
  objectToQueryStringFunc,
  getAPICall,
} from "../../../../shared";
import RightBarUI from "../../../../components/RightBarUI";
// import VoyReletPortInformation from "../../../right-port-info/VoyReletPortInformation";
import VoyReletPlSummary from "../../../pl-summary-list/VoyReletPlSummary";
import Properties from "./right-panel/Properties";
import RightSearch from "../tcto/right-panel/RightSearch";
import EstimateSummary from "../cargo-contract/EstimateSummary";

const { Content } = Layout;

class QuickEstimate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      formDataArrays: [],
      showArray: [],
      refreshForm: false,
      showCollapse: [true],
      frmName: "voyage_relet_quick_form",
      gID: undefined,
      isEdit: false,
      formDataValues: {},
      visibleDrawer: false,
      title: undefined,
      loadComponent: undefined,
      width: 1200,
      expandIconPosition: "right",
      showAddButton: this.props.showAddButton || true,
      fullEstButton: undefined,
      estimateID: undefined,
      fullEstData: null,
    };
  }

  componentDidMount = async () => {
    const { match, estimateID } = this.props;
    const { rightBtnPanel } = this.state;
    let respData = undefined,
      response = undefined;
    // _rightBtnPanel = [...rightBtnPanel];

    if (
      (match && match.params.id && match.path == "/edit-voy-relet/:id") ||
      estimateID
    ) {

      let qParams = {
        ae: match && match.params.id ? match.params.id : estimateID,
      };
      let qParamString = objectToQueryStringFunc(qParams);
      response = await getAPICall(
        `${URL_WITH_VERSION}/voyage-relet/quick-estimate/edit?${qParamString}`
      );
      respData = await response["data"];
      this.setState(
        {
          ...this.state,
          // fullEstData: respData,
          formDataValues: respData,
          fullToQuick: false,
          showAddButton: false,
          // rightBtnPanel: _rightBtnPanel,
        },
        () => this.onAddMore("0", false, true)
      );
    } else {
      this.onAddMore();
    }
  };

  onCloseDrawer = () =>
    this.setState({
      ...this.state,
      visibleDrawer: false,
      title: undefined,
      loadComponent: undefined,
    });

  onClickRightMenu = (key, options) => {
    this.onCloseDrawer();
    let loadComponent = undefined;
    switch (key) {
      case "search":
        loadComponent = (
          <RightSearch showExtraFields={true} history={this.props.history} />
        );
        break;
      case "properties":
        loadComponent = <Properties />;
        break;
      case "pl":
        loadComponent = <VoyReletPlSummary />;
        break;
      case "summary":
        loadComponent = <EstimateSummary />;
        break;
      //      case 'portinformation':
      //        loadComponent = <VoyReletPortInformation />;
      //        break;
    }

    this.setState({
      ...this.state,
      visibleDrawer: true,
      title: options.title,
      loadComponent: loadComponent,
      width: options.width && options.width > 0 ? options.width : 1200,
    });
  };

  onAddMore = async (index = "", editValue = false, editing = false) => {
    const {
      showArray,
      rightBtnPanel,
      showCollapse,
      formDataValues,
    } = this.state;

    let _formData = Object.assign({}, {});
    if (editValue || editing == true) {
      _formData = Object.assign({}, formDataValues);
    }
    if (editValue === true && editing === false) {
      showArray[index] = [];
    }

    this.setState(
      {
        ...this.state,
        formDataValues: _formData,
        isEdit: true,
        rightBtnPanel:
          _formData && _formData.hasOwnProperty("id") && _formData["id"] > 0
            ? rightBtnPanel
            : [
              {
                key: "s2",
                isSets: [
                  {
                    id: "3",
                    key: "fullest",
                    isDropdown: 0,
                    type: "small-dash",
                    withText: "Full Estimate" + index,
                    showButton: true,
                    showToolTip: true,
                    event: () => this.viewQuickEstimate(index),
                  },
                  {
                    id: "2",
                    key: "menu",
                    isDropdown: 1,
                    type: "small-dash",
                    withText: "Menu",
                    showButton: true,
                    showToolTip: true,
                    menus: [
                      // {
                      //   id: '21',
                      //   key: 'menu-1',
                      //   href: null,
                      //   icon: null,
                      //   label: 'Convert to Full Estimate',
                      //   modalKey: null,
                      //   event: (key, data) => this.props.history.push(`/edit-voy-relet/${data.estimate_id}`),
                      // },

                      {
                        id: "24",
                        key: "menu-4",
                        href: null,
                        icon: null,
                        label: "Bunker-Live",
                        modalKey: null,
                      },

                      {
                        id: "25",
                        key: "menu-5",
                        href: null,
                        icon: null,
                        label: "Route to Map",
                        modalKey: null,
                      },

                      {
                        id: "26",
                        key: "menu-6",
                        href: null,
                        icon: null,
                        label: "Voyage optimize",
                        modalKey: null,
                      },
                    ],
                  },

                ],
              },
            ],
      },
      () => {
        if (editValue === true) {
          showArray[index] = this.getContent(index, true);
        } else {
          showArray.push(this.getContent(showArray.length, false));
        }
        showCollapse[showArray.length] = true;
        this.setState({ ...this.state, showArray: showArray, showCollapse });
      }
    );
  };

  onLessMore = (index) => {
    const { showArray } = this.state;
    if (index > 0) {
      showArray.pop(this.getContent(index));
      this.setState({ ...this.state, showArray: showArray });
    }
  };

  onToggle = (i) => {
    this.state.showCollapse[i] = !this.state.showCollapse[i];
    this.setState(
      { ...this.state, showCollapse: this.state.showCollapse },
      () => {
        this.state.showArray[i] = this.getContent(i);
        this.setState({ ...this.state, showArray: this.state.showArray });
      }
    );
  };

  onSaveFormData = async (data, index) => {
    const { frmName } = this.state;

    if (data) {
      let _url = `${URL_WITH_VERSION}/voyage-relet/quick-estimate-save?frm=${frmName}`;
      let _method = "POST";

      if (data["id"] > 0) {
        _url = `${URL_WITH_VERSION}/voyage-relet/quick-estimate-update?frm=${frmName}`;
        _method = "PUT";
      }

      await postAPICall(`${_url}`, data, _method, async (response) => {
        if (response && response.data) {
          openNotificationWithIcon("success", response.message);

          if (data && !data.hasOwnProperty("id")) {
            let estimateID =
              response && response.row && response.row.estimateID;
            let qParams = { ae: estimateID };
            let qParamString = objectToQueryStringFunc(qParams);
            let response1 = await getAPICall(
              `${URL_WITH_VERSION}/voyage-relet/quick-estimate/edit?${qParamString}`
            );
            let respData = await response1["data"];
            // let show = this.state.showArray.shift();
            this.setState(
              {
                ...this.state,
                fullEstData: respData,
                refreshForm: false,
                formDataValues: respData,
                // showArray: [],
                formDataArrays: [...this.state.formDataArrays, ...[respData]],
              },
              () => this.onAddMore(index, true)
            );
          } else {
            this.setState({ ...this.state, isEdit: false, refreshForm: false });
          }
        } else {
          openNotificationWithIcon("error", response.message);
        }
      });
    }
  };

  // onEditFormData = async data => {
  //   const { isEdit } = this.state;
  //   if (data && isEdit) {
  //     let _url = `${URL_WITH_VERSION}/tcov/update?frm=tcov_form`;
  //     await postAPICall(`${_url}`, data, 'patch', response => {
  //       if (response && response.data) {
  //         openNotificationWithIcon('success', response.message);
  //       } else {
  //         openNotificationWithIcon('error', 'Some Server Error');
  //       }
  //     });
  //     this.setState({ ...this.state, isEdit: true, formDataValues: {} }, () =>
  //       this.setState({ ...this.state })
  //     );
  //   }
  // };

  onPositionChange = (expandIconPosition) => {
    this.setState({ expandIconPosition });
  };

  getContent(index) {
    const { showCollapse, frmName, formDataValues, rightBtnPanel } = this.state;
    return (
      <>
        {!showCollapse[index] ? (
          <div className="all-sections wrap-small-collapse">
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="toolbar-ui-wrapper">
                  <div className="action-btn">
                    <Tooltip title="Expand">
                      <Button
                        type="dashed"
                        shape="circle"
                        icon= {<RightOutlined />}
                        size={"default"}
                        onClick={() => this.onToggle(index)}
                      />
                    </Tooltip>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />

            <div className="body-se">
              <h1 class="main-title main-text">
                {formDataValues &&
                  formDataValues.hasOwnProperty("generate_id") &&
                  formDataValues["generate_id"] &&
                  formDataValues["generate_id"] !== ""
                  ? formDataValues["generate_id"]
                  : "EST-VOYR-0000" + index}
              </h1>
            </div>
          </div>
        ) : (
          <div className="all-sections">
            {this.state.refreshForm === false ? (
              <NormalFormIndex
                key={"key_" + frmName + "_0"}
                frmCode={frmName}
                formClass="label-min-height"
                formData={formDataValues}
                showForm={true}
                addForm={true}
                showButtons={[
                  { id: "cancel", title: "Reset", type: "danger" },
                  {
                    id: "save",
                    title: "Save",
                    type: "primary",
                    event: (data) => {
                      this.onSaveFormData(data, index);
                    },
                  },
                ]}
                formDevideInCols="1"
                inlineLayout={true}
                showToolbar={[
                  {
                    isLeftBtn: [
                      {
                        key: "s1",
                        isSets: [
                          {
                            id: "1",
                            key: "save",
                            type: <SaveOutlined />,
                            withText: "Save",
                            showButton: true,
                            showToolTip: true,
                            event: (key, data) =>
                              this.onSaveFormData(data, index),
                          },
                          {
                            id: "6",
                            key: "close",
                            type:  <CloseOutlined />,
                            withText: "Close",
                            showButton: true,
                            showToolTip: true,
                            event: (key) => this.onLessMore(index),
                          },
                          {
                            id: "7",
                            key: "collapse",
                            type: "left",
                            withText: "Collapse",
                            showButton: true,
                            showToolTip: true,
                            event: (key) => this.onToggle(index),
                          },
                        ],
                      },
                    ],
                    isRightBtn: [
                      {
                        key: "s2",
                        isSets: [
                          {
                            id: "3",
                            key: "fullest",
                            isDropdown: 0,
                            type: "small-dash",
                            withText: "Full Estimate",
                            showButton: true,
                            showToolTip: true,
                            event: () => this.viewQuickEstimate(index),
                          },
                          {
                            id: "2",
                            key: "menu",
                            isDropdown: 1,
                            type: "small-dash",
                            withText: "Menu",
                            showButton: true,
                            showToolTip: true,
                            menus: [

                              // {
                              //   id: '21',
                              //   key: 'menu-1',
                              //   href: null,
                              //   icon: null,
                              //   label: 'Convert to Full Estimate',
                              //   modalKey: null,
                              //   event: (key, data) => this.props.history.push(`/edit-voy-relet/${data.estimate_id}`),
                              // },

                              // {
                              //   id: "22",
                              //   key: "menu-2",
                              //   href: null,
                              //   icon: null,
                              //   label: "Go to Quick TCTO",
                              //   modalKey: null,
                              // },

                              // {
                              //   id: "23",
                              //   key: "menu-3",
                              //   href: null,
                              //   icon: null,
                              //   label: "Go Quick Voy relet",
                              //   modalKey: null,
                              //   event: () =>
                              //     this.props.history.push(
                              //       "/voy-relet-full-estimate"
                              //     ),
                              // },

                              {
                                id: "24",
                                key: "menu-4",
                                href: null,
                                icon: null,
                                label: "Bunker-Live",
                                modalKey: null,
                              },

                              {
                                id: "25",
                                key: "menu-5",
                                href: null,
                                icon: null,
                                label: "Route to Map",
                                modalKey: null,
                              },

                              {
                                id: "26",
                                key: "menu-6",
                                href: null,
                                icon: null,
                                label: "Voyage optimize",
                                modalKey: null,
                              },
                            ],
                          },

                        ],
                      },
                    ],
                    isResetOption: false,
                  },
                ]}
              />
            ) : (
              undefined
            )}
          </div>
        )}
      </>
    );
  }
  onCancel = () => this.setState({ ...this.state, visisbleCargoChild: false });

  viewQuickEstimate = (index = "") => {
    const { formDataValues, formDataArrays } = this.state;

    if (formDataValues && formDataValues.hasOwnProperty("tcov_ref_id")) {
      this.props.history.push(
        `/voy-relet-full-estimate-edit/${formDataValues["tcov_ref_id"]}`
      );
    } else if (
      this.state.formDataArrays[index] ||
      (formDataValues &&
        formDataValues.hasOwnProperty("id") &&
        formDataValues["id"] > 0 &&
        !formDataValues.hasOwnProperty("tcov_ref_id"))
    ) {
      let values = [];

      if (formDataArrays[index]) {
        values = formDataArrays[index];
      } else {
        values = formDataValues;
      }

      this.props.history.push({
        pathname: `/voy-relet-full-estimate`,
        state: values,
      });
    } else {
      openNotificationWithIcon("error", "Quick estimate to be generated first");
    }
  };

  render() {
    const {
      loadComponent,
      title,
      visibleDrawer,
      showArray,
      showAddButton,
      estimateID,
      fullEstData,
    } = this.state;
    return (
      <div className="tcov-wrapper scroll-borad">
        <Layout className="layout-wrapper">
          <Layout>
            <Content className="content-wrapper">
              <div className="wrap-scrumboard-design">
                {showArray.map((field) => {
                  return <> {field} </>;
                })}

                {/* {showAddButton ? ( */}
                <div className="wrap-action-plus">
                  <div className="fieldscroll-wrap add-section-wrapper">
                    <Tooltip
                      title="Add Estimates"
                      onClick={() =>
                        this.setState({ formDataValues: {} }, () =>
                          this.onAddMore()
                        )
                      }
                    >
                    <PlusOutlined />
                    </Tooltip>
                  </div>
                </div>
                {/* // ) : (
                //   undefined
                // )} */}
              </div>
            </Content>
          </Layout>
          <RightBarUI
            pageTitle="voy-relet-quick-righttoolbar"
            callback={(data, options) => this.onClickRightMenu(data, options)}
          />
          {loadComponent !== undefined &&
            title !== undefined &&
            visibleDrawer === true ? (
            <Drawer
              title={this.state.title}
              placement="right"
              closable={true}
              onClose={this.onCloseDrawer}
              open={this.state.visibleDrawer}
              getContainer={false}
              style={{ position: "absolute" }}
              width={this.state.width}
              maskClosable={false}
              className="drawer-wrapper-container"
            >
              <div className="tcov-wrapper">
                <div className="layout-wrapper scrollHeight">
                  <div className="content-wrapper noHeight">
                    {this.state.loadComponent}
                  </div>
                </div>
              </div>
            </Drawer>
          ) : (
            undefined
          )}
        </Layout>
      </div>
    );
  }
}

export default QuickEstimate;
