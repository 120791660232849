import moment from "moment";
import URL_WITH_VERSION, { postAPICall } from "./index.js";

export const checkBunkerPriceFromTable = async (portselected) => {
  try {
    let BunkerExSpotPrices = [];
    let BunkerExFuturePrices = [];
    let isPortSelected = portselected.length > 0 ? true : false;

    if (portselected.length > 0) {
      let _method = "post";
      let postData = { ports: portselected };
      await postAPICall(
        `${URL_WITH_VERSION}/voyage-manager/voyage-spotprice`,
        postData,
        _method,
        (response) => {
          response.row.BunkerExSpotPrices.map((el) => {
            BunkerExSpotPrices.push({
              port: el.Port,
              country: el.Country,
              grade: el.Grade,
              price: el.Price,
              latitude: el.Latitude,
              longitude: el.Longitude,
              priceType: el.PriceType,
              lastUpdated: moment(el.LastUpdatedOnUTC).format(
                "YYYY-MM-DD HH:mm"
              ),
              netChange: el.NetChange,
            });
          });
          response.row.BunkerExFuturePrices.map((el) => {
            BunkerExFuturePrices.push({
              port: el.Port,
              futureMonth: moment(el.FutureMonth).format("YYYY-MM-DD"),
              grade: el.Grade,
              lastUpdated: moment(el.LastUpdatedOnUTC).format(
                "YYYY-MM-DD HH:mm"
              ),
              price: el.Price,
            });
          });
        }
      );
    }
    return {
      isPortSelected,
      BunkerExSpotPrices,
      BunkerExFuturePrices,
    };
  } catch (err) {
    console.log("err", err);
  }
};
