import React, { useEffect, useState } from "react";
import PortAnalaytics from "./PortAnalytics";
import TrackMyFleetTable from "./trackMyFleetTable";
import PortPostion from "./portPostion";
import { getAPICall, useStateCallback } from "../../shared";

const MapIntellegence = () => {
  const [state, setState] = useStateCallback({
    isShowLocationFromtable: false,
    mapDataArr: [],
    loading: false,
    filterVessels: [],
    istrackMyFleet: false,
    isPortPostion: false,
  });

  const getMapData = async () => {
    try {
      setState({ ...state, loading: true });
      const url = `${process.env.REACT_APP_URL}v1/vessel/live/data?p=0`;
      const response = await getAPICall(url);
      const respdata = await response.data;
      setState((prevState) => ({
        ...prevState,
        mapDataArr: [...respdata],
        loading: false,
      }));
    } catch (err) {
      console.log("err", err);
    }
  };

  const searchedVessel = (searchItem) => {
    setState((prevState) => ({
      ...prevState,
      mapDataArr: [searchItem],
      filterVessels: [searchItem],
    }));
  };

  const switchTable = (table) => {
    if (table === "postion") {
      setState((prevState) => ({
        ...prevState,
        istrackMyFleet: false,
        isPortPostion: true,
      }));
    } else if (table === "global") {
      setState((prevState) => ({
        ...prevState,
        istrackMyFleet: true,
        isPortPostion: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        istrackMyFleet: false,
        isPortPostion: false,
      }));
    }
  };

  return (
    <div>
      {/* {state.mapDataArr?.length > 0 && ( */}
      <PortAnalaytics
        // mapData={state.mapDataArr}
        searchedItem={
          state.filterVessels.length > 0 ? state.filterVessels[0] : {}
        }
        modalCloseEvent={(val) => {
          console.log('val........', val);
          setState((prevState) => ({
            ...prevState,
            isShowLocationFromtable: val,
          }));
        }}
        switchTable={switchTable}
        isShowLocationFromtable={state.isShowLocationFromtable}
      />
      {/* )} */}

      {/* {state.istrackMyFleet && (
        <TrackMyFleetTable
          searchedVessel={searchedVessel}
         
          showlocation={(data) =>
            setState({ ...state, isShowLocationFromtable: data })
          }
        />
      )} */}
      {state.isPortPostion && <PortPostion searchedVessel={searchedVessel} />}
    </div>
  );
};

export default MapIntellegence;
