import { Modal } from "antd";
import React from "react";
import SpotPrice from "../../port-to-port/SpotPrice";

const BunkerSpotPriceModal = ({
  showBunkerPriceModal,
  setShowBunkerPriceModal,
}) => {
  return (
    <Modal
      title="Bunker Spot Price"
      open={showBunkerPriceModal}
      closable={true}
      onCancel={() => setShowBunkerPriceModal(false)}
      footer={null}
      style={{
        top: 20,
      }}
      width={1000}
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <SpotPrice />
    </Modal>
  );
};

export default BunkerSpotPriceModal;
