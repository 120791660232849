import { Modal } from 'antd';
import React from 'react'
import VesselSchedule from '../../../components/vessel-form';

const AddVesselModal = ({showAddVesselModal,setShowAddVesselModal}) => {
    return (
      <Modal
        title="Add Vessel Form"
        open={showAddVesselModal}
        closable={true}
        onCancel={()=>setShowAddVesselModal(false)}
        footer={null}
        style={{
          top: 20,
        }}
        width={"90%"}
        bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
      >
        <VesselSchedule />
      </Modal>
    );
  };
  
  export default AddVesselModal;