import {
  Button,
  Col,
  Collapse,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Tabs,
  Tooltip,
} from "antd";
import { Component } from "react";
import { createStore } from "redux";
import DynamicFormFields from "../../services/dynamic_form_fields";
import ReduxState from "../../services/redux_state";
import URL_WITH_VERSION, {
  URL_WITHOUT_VERSION,
  getAPICall,
  openNotificationWithIcon,
} from "../../shared";
import IndexTableForm from "../../shared/TableForm/index_table";
import ModalAlertBox from "../ModalAlertBox";
import PopupSelection from "../PopupSelection";
import SideList from "../components/side-list/SideList";
// import * as resp1 from '../../mock_data.json';
import * as moment from "moment";
import Cookies from "universal-cookie";
import CustomLoader from "../../components/Loading/CustomLoader";
import {
  VoyageCIIDynamicsCalculation,
  VoyageEuetsCalculation,
} from "../../services/ciiCalculation";
import { BunkerDetailsCalculation } from "./normalhelper";

// import * as momenttz from 'moment-timezone';
// momenttz.tz.setDefault('UTC');
let divshow = "";
const { Panel } = Collapse;
const FormItem = Form.Item;
const GroupEle = Input.Group;
const TabPane = Tabs.TabPane;
const INITIAL_MODAL = {
  modalHeader: null,
  modalStatus: false,
  modalBody: null,
  modalFooter: null,
};
const cookies = new Cookies();
class NormalFormIndex extends Component {
  constructor(props) {
    super(props);
    this.resetFormFlag = false;
    this.elements = [];
    this.state = {
      // isRemovePlaceHolder : false,
      showSideListBar:
        this.props.showSideListBar === false
          ? this.props.showSideListBar
          : true,
      resetFormFlag: false,
      responseData: { frm: [], tabs: [], active_tab: {} },
      forms: {},
      resp: this.props.formData || {},

      groupAction: this.props.hasOwnProperty("showGroupTableAction")
        ? this.props.showGroupTableAction === false
        : false,
      isShowFixedColumn: this.props.hasOwnProperty("isShowFixedColumn")
        ? this.props.isShowFixedColumn
        : "",
      extraFormFields: this.props.extraFormFields || undefined,
      extraTableButton: this.props.extraTableButton || undefined,
      labelModal: { ...INITIAL_MODAL },
      formDevideInCols: this.props.formDevideInCols || 3,
      tabEvents: this.props.tabEvents || [],
      formExtraEvents: this.props.formExtraEvents || {},
      formGroupDevideInCols: this.props.formGroupDevideInCols || {},
      dyncTabs: this.props.dyncTabs || {},
      sideList: this.props.sideList || {},
      frmOptions: this.props.frmOptions || [],
      sendBackData: this.props.sendBackData || false,
      hideGroup: this.props.hideGroup || [],
      showButtons: this.props.showButtons || [],
      disableTabs: this.props.disableTabs || [],
      tableRowDeleteAction: this.props.tableRowDeleteAction || (() => {}),
      editMode: this.props.hasOwnProperty("editMode")
        ? this.props.editMode
        : true,
      parentformName: this.props.parentformName || undefined,
      vesselData: null,
      prevVessel: null,
      // initialAmount: 0
    };

    if (!this.props.dynamicForm) {
      ReduxState.setForm(this.props.frmCode);
      this.createStore = createStore(ReduxState.createReducer);
      this.dynamicForm = new DynamicFormFields(
        this.createStore,
        this.props.frmCode,
        this.props.ogState,
        (resp) => {
          this.setState({ ...this.state, resp }, () => {
            this.dynamicForm.setStateObject(this.state["resp"]);
          });
        },
        this.onEventResponse
      );
    } else {
      this.dynamicForm = this.props.dynamicForm;
      this.createStore = this.props.createStore;
    }
  }

  componentDidMount = async () => {
    if (this.props.formData && this.props.formData.hasOwnProperty("id")) {
      if (this.props.formData["id"] === 0) {
        delete this.props.formData["id"];
      }

      this.dynamicForm.setStateObject(this.props.formData);

      this.createStore.dispatch({
        type: "edit_info",
        editFormData: this.props.formData,
        setOriginalData: true,
      });
    }

    if (this.props.frmCode) {
      try {
        const response = await getAPICall(
          `${URL_WITHOUT_VERSION}get/${this.props.frmCode}`
        );
        const data = await response["data"];

        if (
          this.props.formData &&
          this.props.dyncTabs &&
          this.props.dyncTabs.hasOwnProperty("copyTab") &&
          this.props.dyncTabs.hasOwnProperty("tabs") &&
          data &&
          data.hasOwnProperty("tabs")
        ) {
          let tabs = data["tabs"].filter(
            (e) => e.frm_code === this.props.dyncTabs["copyTab"]
          );
          tabs = tabs && tabs.length === 1 ? tabs[0] : {};
          data["tabs"] = [];
          this.props.dyncTabs["tabs"].map((e, index) => {
            let tab = Object.assign({}, tabs, {
              tab_name: e.tabName,
              groupName: e.tabGroup,
            });
            data["tabs"].push(tab);
          });
        }

        if (this.props.frmOptions && this.props.frmOptions.length > 0) {
          this.props.frmOptions.map((e) => {
            data["frm"].map((fe, fi) => {
              if (fe["f_name"] === e["key"]) {
                data["frm"][fi]["f_dyc_extras"] = {
                  multiSelected: undefined,
                  options: e.data != 0 ? e.data : e.f_default,
                };
              }
            });
          });
        }
        this.setDefaultData(this.props.formData, data, () => {
          setTimeout(() => {
            this.setState({ ...this.state, responseData: data }, () => {
              let visitedtabs = [];
              const { responseData } = this.state;
              if (responseData?.tabs?.length > 0) {
                visitedtabs.push(responseData.tabs[0]["tab_name"]);
                localStorage.setItem(
                  "tabsVisited",
                  JSON.stringify(visitedtabs)
                );
              }
            });
          }, 5);
        });

        this.dynamicForm.originalState.setFormFields(data["frm"]);
      } catch (err) {
        openNotificationWithIcon("error", "Something Went wrong.", 3);
      }
    } else if (this.props.frm) {
      this.setState({ ...this.state, responseData: this.props.frm }, () => {
        let visitedtabs = [];
        const { responseData } = this.state;
        if (responseData?.tabs?.length > 0) {
          visitedtabs.push(responseData.tabs[0]["tab_name"]);
          localStorage.setItem("tabsVisited", JSON.stringify(visitedtabs));
        }
      });
      this.callForDdlBinding(this.props.frm);
    }
  };

  // componentDidUpdate =()=>{

  //   if(this.props.frmCode=="tcov_full_estimate_form"){
  //     console.log("formdata",this.state.resp)
  //     let originalMarineData = { ...this.state.resp.marineData };
  //   }
  //   // console.log("hi")
    
  // }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.frmCode === "tcov_full_estimate_form" || this.props.frmCode === "tcto_full_estimate_form" || this.props.frmCode === "VOYAGE_RELET") {
      const currentMarineData = this.state.resp.vesselInfo;
      
      // Check if marineData has changed
      if (JSON.stringify(this.originalMarineData) !== JSON.stringify(currentMarineData)) {

        // Trigger your desired function here
        this.props.getVesselNameFromChild(currentMarineData)

        // Update the original marineData to the current state
        this.originalMarineData = { ...currentMarineData };
      }
    }
  }


  foldMenu() {
    const { showSideListBar } = this.state;
    this.setState({ ...this.state, showSideListBar: !showSideListBar });
  }

  setDefaultData = (formData, formProps, cb) => {
    let data = {};
    if (
      formProps &&
      formProps.hasOwnProperty("frm") &&
      formProps["frm"].length > 0
    ) {
      formProps.frm.map((e) => {
        if (e && e.f_default && e.f_default !== "" && !e.group_name) {
          let v = e.f_default;
          if (e.f_type === "boolean") {
            v =
              e.f_default === true
                ? e.f_default
                : e.f_default === "1"
                ? true
                : false;
          } else if (
            e.f_default === "now" &&
            (e.f_type === "date" ||
              e.f_type === "time" ||
              e.f_type === "datetime")
          ) {
            v = moment(new Date()).format("YYYY-MM-DDTHH:mm");
          }

          data[e.f_name] = v;
        } else if (
          e &&
          e.f_default &&
          e.f_default !== "" &&
          e.group_name &&
          e.group_name !== "" &&
          !e.f_g_frm_type
        ) {
          let v = e.f_default,
            gKey = this.dynamicForm.getGroupKey(e.group_name);
          if (!data.hasOwnProperty(gKey)) data[gKey] = {};
          if (e.f_type === "boolean") {
            v =
              e.f_default === true
                ? e.f_default
                : e.f_default === "1"
                ? true
                : false;
          } else if (
            e.f_default === "now" &&
            (e.f_type === "date" ||
              e.f_type === "time" ||
              e.f_type === "datetime")
          ) {
            v = moment(new Date()).format("YYYY-MM-DDTHH:mm");
          }

          data[gKey][e.f_name] = v;
        } else if (
          e &&
          e.f_default &&
          e.f_default !== "" &&
          e.group_name &&
          e.group_name !== "" &&
          e.f_g_frm_type
        ) {
          let v = e.f_default,
            gKey = this.dynamicForm.getGroupKey(e.group_name);
          let ffg = formProps.groups.filter((e) => e.group_key === gKey);

          if (ffg && ffg.length > 0) {
            if (ffg[0]["group_type"] === "Table") {
              if (!data.hasOwnProperty(gKey)) data[gKey] = [];
              if (e.f_type === "boolean") {
                v =
                  e.f_default === true
                    ? e.f_default
                    : e.f_default === "1"
                    ? true
                    : false;
              } else if (
                e.f_default === "now" &&
                (e.f_type === "date" ||
                  e.f_type === "time" ||
                  e.f_type === "datetime")
              ) {
                v = moment(new Date()).format("YYYY-MM-DDTHH:mm");
              }

              if (data[gKey].length === 0) {
                let ele = {};
                ele[e.f_name] = v;
                data[gKey].push(ele);
              } else {
                data[gKey][0][e.f_name] = v;
              }
            } else if (ffg[0]["group_type"] === "Normal") {
              if (!data.hasOwnProperty(gKey)) data[gKey] = {};
              if (e.f_type === "boolean") {
                v =
                  e.f_default === true
                    ? e.f_default
                    : e.f_default === "1"
                    ? true
                    : false;
              } else if (
                e.f_default === "now" &&
                (e.f_type === "date" ||
                  e.f_type === "time" ||
                  e.f_type === "datetime")
              ) {
                v = moment(new Date()).format("YYYY-MM-DDTHH:mm");
              }
              data[gKey][e.f_name] = v;
            }
          }
        }
      });

      data = Object.assign(data, formData);
      this.dynamicForm.setStateObject(data);
      this.createStore.dispatch({ type: "edit_info", editFormData: data });
      cb();
    }
  };

  onEventResponse = (event, data, selectedid) => {
    const { responseData } = this.state;

    let formFields = Object.assign({}, responseData);
    let fieldValue = {};
    formFields.frm.map((e, i) => {
      if (e.f_name === event.addOptionsIn) {
        formFields.frm[i]["f_dyc_extras"] = {
          options: data,
          //multiSelected: formFields.frm[i]["f_dyc_extras"]["multiSelected"],
        };
      }
    });

    let selectedoption = selectedid
      ? data.filter((el) => el.id == selectedid)
      : data;

    fieldValue[event.addOptionsIn] =
      selectedoption && selectedoption.length > 0 ? selectedoption[0] : "";

    fieldValue["tci_id"] =
      selectedoption && selectedoption.length > 0
        ? selectedoption[0]["tci_id"]
        : "";

    fieldValue[event.showInField] =
      selectedoption && selectedoption.length > 0
        ? selectedoption[0]["id"]
        : "";

    this.setState({ ...this.state, responseData: formFields }, () => {
      if (
        (event.isUpdateValue && event.isUpdateValue === true) ||
        typeof event.isUpdateValue === "undefined"
      ) {
        this.createStore.dispatch({
          type: "add",
          groupName: undefined,
          index: -1,
          value: fieldValue,
        });
      }
    });
  };

  callForDdlBinding = async (data) => {
    if (data.frm.length > 0) {
      data.frm.map((e, i) => {
        if (e.f_name === "year_built") {
          let currentYear = new Date().getFullYear();
          let years = [],
            ddl = {};
          for (let i = currentYear; i > currentYear - 30; i--) {
            years.push({ label: i, value: i });
          }
          ddl["options"] = years;
          data.frm[i]["f_dyc_extras"] = ddl;
        }
      });
    }
    this.setState({ ...this.state, responseData: data });
  };

  generateMenus = (menus) => {
    return (
      <Menu>
        {menus
          ? menus.map((menu, i) => {
              if (menu.link != null) {
                let linkhrf = "href=" + menu.href ? menu.href : "#";
              } else {
                let linkhrf = "";
              }

              if (menu.label.length > 0) {
                return (
                  <Menu.Item key={i}>
                    <a
                      rel="noopener noreferrer"
                      linkhrf="true"
                      onClick={() => {
                        if (
                          menu.hasOwnProperty("event") &&
                          typeof menu.event === "function"
                        ) {
                          menu.event(menu.modalKey, this.state.resp);
                        }
                      }}
                    >
                      <>
                        {menu.icon ? menu.icon : undefined}
                        {menu.label ? menu.label : null}
                      </>
                    </a>
                  </Menu.Item>
                );
              }
            })
          : " "}
      </Menu>
    );
  };

  onCloseModal = async (showData) => {
    if (
      showData &&
      showData.hasOwnProperty("data") &&
      showData["data"] &&
      showData["data"].hasOwnProperty("rid")
    ) {
      this.dynamicForm.onChangeEvent(
        showData["data"]["rid"],
        showData["element"],
        undefined
      );
    }

    if (
      showData &&
      showData.hasOwnProperty("element") &&
      showData["element"] &&
      showData["element"].hasOwnProperty("f_label_display") &&
      showData["element"]["f_label_display"]
    ) {
      let dEle = JSON.parse(showData["element"]["f_label_display"]);
      if (
        dEle &&
        dEle.hasOwnProperty("listEvent") &&
        dEle["listEvent"] &&
        dEle["listEvent"].hasOwnProperty("apiLink")
      ) {
        let response = await getAPICall(
          `${URL_WITH_VERSION}${dEle["listEvent"]["apiLink"]}`
        );
        let respData = await response;
        if (
          respData &&
          respData.hasOwnProperty("data") &&
          respData["data"] === false
        ) {
          openNotificationWithIcon(
            "error",
            <div
              className="notify-error"
              dangerouslySetInnerHTML={{ __html: respData["message"] }}
            />,
            5
          );
        } else {
          this.onEventResponse(dEle["listEvent"], respData.data);
        }
      }

      if (
        dEle &&
        dEle.hasOwnProperty("updateFields") &&
        dEle["updateFields"] &&
        typeof dEle["updateFields"] !== "string"
      ) {
        let jEle = {},
          oEle = dEle["updateFields"];
        if (showData && showData.hasOwnProperty("data") && showData["data"]) {
          Object.keys(oEle).map((e) => {
            jEle[e] = showData["data"][oEle[e]];
          });

          this.createStore.dispatch({
            type: "add",
            groupName: undefined,
            index: -1,
            value: jEle,
            formName: this.props.frmCode,
          });
        }
      }

      if (
        dEle &&
        dEle.hasOwnProperty("updateFields") &&
        dEle["updateFields"] &&
        typeof dEle["updateFields"] == "string"
      ) {
        let jEle = {},
          oEle = dEle["updateFields"];
        if (showData && showData.hasOwnProperty("data") && showData["data"]) {
          jEle[dEle["fieldName"]] =
            showData["data"][dEle["updateFields"]] ||
            showData["data"][dEle["field"]]["port_name"];

          if (
            dEle.hasOwnProperty("otherUpdateFields") &&
            dEle["otherUpdateFields"]
          ) {
            jEle[dEle["otherUpdateFields"]] =
              showData?.["data"]?.["distance"]?.["features"]?.[0]?.[
                "properties"
              ]?.["total_length"] ?? 0;
          }

          this.createStore.dispatch({
            type: "add",
            groupName: undefined,
            index: -1,
            value: jEle,
            formName: this.props.frmCode,
          });

          this.dynamicForm.testamar1();
        }
      }
    }

    if (typeof this.props.reFreshForm === "function") {
      this.props.reFreshForm();
    }

    this.setState({ ...this.state, labelModal: { status: false } }, () =>
      this.setState({ ...this.state, labelModal: { ...INITIAL_MODAL } })
    );
  };

  onPopupRowSelect = async (data, fieldSetting, field) => {
    let fjson = field["f_label_display"];
    if (typeof fjson === "string") {
      fjson = JSON.parse(fjson);
    }

    if (fjson && fjson.hasOwnProperty("putValues")) {
      let fCounter = [];
      fieldSetting.forEach((e) => {
        let fields = {};
        fields[e["toField"]] = data[e["fromField"]];
        this.createStore.dispatch({
          type: "add",
          groupName: field.group_name,
          value: fields,
        });
        fCounter.push(e);

        if (fCounter.length === fieldSetting.length) {
          setTimeout(() => this.onCloseModal(), 10);
        }
      });
    } else if (
      fjson &&
      fjson.hasOwnProperty("showAllValues") &&
      fjson["showAllValues"] === true &&
      data[fjson.orderBy] !== undefined
    ) {
      this.setState({ ...this.state, resetFormFlag: true });
      const resp = await getAPICall(
        `${URL_WITH_VERSION}${fjson.editApiLink}${data[fjson.orderBy]}`
      );
      const respData = await resp["data"];
      this.dynamicForm.setStateObject(Object.assign(respData, this.props.resp));
      this.setState(
        { ...this.state, resp: respData, resetFormFlag: false },
        () => this.onCloseModal()
      );
    }
  };

  updateMainForm = () => {
    if (typeof this.props.updateMainForm === "function") {
      this.props.updateMainForm(this.state.resp);
    }
  };

  showLinkPopup = (
    pElements,
    popupElements,
    filter = undefined,
    index = -1,
    cFormData = undefined,
    handelPopupReturn = undefined
  ) => {
    let stObject = this.dynamicForm.getStateObject();
    let _header = {};
    const { formExtraEvents } = this.state;
    let popupForms = {},
      editLink = undefined;
    if (
      pElements.hasOwnProperty("type") &&
      pElements.type !== "" &&
      pElements.type.toUpperCase() === "FORM"
    ) {
      let formData = {};

      if (
        pElements.hasOwnProperty("passData") &&
        pElements.passData !== "" &&
        pElements.passData.hasOwnProperty("length") &&
        pElements.passData.length > 0
      ) {
        const { resp } = this.state;

        pElements.passData.map((e) => {
          formData[e] = resp[e];
        });
      }

      if (
        formExtraEvents.hasOwnProperty("popups") &&
        formExtraEvents["popups"].hasOwnProperty(popupElements.f_name)
      ) {
        popupForms = formExtraEvents["popups"][popupElements.f_name];
      }

      if (
        pElements.hasOwnProperty("condition") &&
        pElements.hasOwnProperty("conditionValue") &&
        pElements["conditionValue"] !=
          cFormData[pElements["groupKey"]][index][pElements["condition"]]
      ) {
        return;
      }

      if (
        pElements.hasOwnProperty("editAPILink") &&
        pElements["editAPILink"].hasOwnProperty("fieldName") &&
        pElements["editAPILink"]["fieldName"] !== "" &&
        formData[pElements["editAPILink"]["fieldName"]] !== ""
      ) {
        editLink =
          pElements.editAPILink["apiLink"] +
          formData[pElements["editAPILink"]["fieldName"]];
      } else if (
        pElements.hasOwnProperty("editLink") &&
        pElements.editLink !== "" &&
        pElements.hasOwnProperty("condition") &&
        pElements["condition"]["tci_fieldname"]
      ) {
        editLink =
          pElements.editLink + filter[pElements["condition"]["tci_fieldname"]];
      } else if (
        pElements.hasOwnProperty("editLink") &&
        pElements.editLink !== "" &&
        pElements.hasOwnProperty("condition")
      ) {
        editLink =
          pElements.editLink + filter[pElements["condition"]["fieldName"]];
      } else if (
        pElements.hasOwnProperty("editLink") &&
        pElements.editLink !== "" &&
        !pElements.hasOwnProperty("condition")
      ) {
        editLink = pElements.editLink;
        if (pElements.hasOwnProperty("xFilter") && pElements["xFilter"]) {
          _header = { where: { id: stObject[pElements["fromField"]] } };
        }
      }

      this.setState({
        ...this.state,
        labelModal: {
          modalHeader: pElements.headerTitle,
          modalStatus: true,
          modalBody: () => (
            <PopupSelection
              apiLink={editLink}
              elementIndex={index}
              completeDataObject={{ fd: cFormData, pe: pElements }}
              formData={formData}
              frmCode={pElements.frmCode}
              header={_header}
              isShowInPopup={pElements.isShowInPopup || false}
              isShowImport={pElements.isShowImport || false}
              isShowAddButton={
                pElements.hasOwnProperty("isShowAddButton")
                  ? pElements.isShowAddButton
                  : true
              }
              onCloseModal={
                pElements.hasOwnProperty("autoClose") &&
                pElements["autoClose"] === false
                  ? undefined
                  : this.onCloseModal
              }
              referenceElement={popupElements}
              popupFroms={popupForms}
              handelPopupReturn={handelPopupReturn}
              updateMainForm={() => {
                if (pElements.isUpdateMainForm === true) this.updateMainForm();
              }}
              handlePortInfoDrawer={this.props?.frmProp?.handlePortInfoDrawer}
            />
          ),
          modalFooter: null,
          modalWidth: pElements.width || "90%",
        },
      });
    } else {
      editLink = pElements.apiLink;
      if (
        pElements.hasOwnProperty("valueFrom") &&
        pElements["valueFrom"] &&
        pElements["valueFrom"] !== ""
      ) {
        editLink =
          editLink + this.dynamicForm.getStateObject(pElements["valueFrom"]);
      }
      this.setState({
        ...this.state,
        labelModal: {
          modalHeader: pElements.headerTitle,
          modalStatus: true,
          modalBody: () => (
            <PopupSelection
              apiLink={editLink}
              identifyName={pElements.identifyName}
              orderBy={pElements.orderBy}
              filterBy={filter}
              onRowSelect={(e) =>
                this.onPopupRowSelect(e, pElements.putValues, popupElements)
              }
              handlePortInfoDrawer={this.props?.frmProp?.handlePortInfoDrawer}
            />
          ),
          modalFooter: null,
          modalWidth: pElements.width || "90%",
        },
      });
    }
  };

  loadPopup = (
    popupElements,
    index = 0,
    formData = undefined,
    handelPopupReturn = undefined
  ) => {
    const { resp } = this.state;

    //let pElements = JSON.parse(popupElements["f_label_display"]);
    let pElements = JSON.parse(popupElements["f_label_display"] ?? "{}");

    if (
      pElements &&
      pElements.hasOwnProperty("condition") &&
      pElements["condition"].hasOwnProperty("fieldName")
    ) {
      if (
        pElements["condition"]["fieldName"] == "vessel_id" &&
        (pElements["condition"]["section"] == "tcov_quick" ||
          pElements["condition"]["section"] == "voy-relet") &&
        resp.hasOwnProperty("vesseldetails")
      ) {
        resp[pElements["condition"]["fieldName"]] =
          resp["vesseldetails"]["vessel_id"];
      }
      if (
        pElements["condition"]["fieldName"] == "vessel_id" &&
        pElements["condition"]["section"] == "tcto_quick" &&
        resp.hasOwnProperty("tcidetails")
      ) {
        resp[pElements["condition"]["fieldName"]] =
          resp["tcidetails"]["vessel_id"];
      }

      let filter = {};

      if (
        pElements["condition"].hasOwnProperty("gt") &&
        popupElements.hasOwnProperty("group_name") &&
        popupElements["group_name"] &&
        popupElements["group_name"] !== "" &&
        resp.hasOwnProperty(popupElements["group_name"]) &&
        resp[popupElements["group_name"]].hasOwnProperty(
          pElements["condition"]["fieldName"]
        ) &&
        resp[popupElements["group_name"]][pElements["condition"]["fieldName"]] *
          1 >
          0
      ) {
        // tci_fieldname

        filter[pElements["condition"]["fieldName"]] =
          resp[popupElements["group_name"]][
            pElements["condition"]["fieldName"]
          ];

        filter[pElements["condition"]["tci_fieldname"]] =
          resp[popupElements["group_name"]][
            pElements["condition"]["tci_fieldname"]
          ];

        this.showLinkPopup(
          pElements,
          popupElements,
          filter,
          index,
          formData,
          handelPopupReturn
        );
      } else if (
        pElements["condition"].hasOwnProperty("gt") &&
        resp.hasOwnProperty(pElements["condition"]["fieldName"]) &&
        resp[pElements["condition"]["fieldName"]] * 1 > 0
      ) {
        filter[pElements["condition"]["fieldName"]] =
          resp[pElements["condition"]["fieldName"]];

        filter[pElements["condition"]["tci_fieldname"]] =
          resp[pElements["condition"]["tci_fieldname"]];

        this.showLinkPopup(
          pElements,
          popupElements,
          filter,
          index,
          formData,
          handelPopupReturn
        );
      } else {
        let msg = "Please select " + popupElements.name + " and then click.";
        if (
          pElements["condition"].hasOwnProperty("gt") &&
          pElements["condition"].hasOwnProperty("message")
        ) {
          msg = pElements["condition"]["message"];
        }
        openNotificationWithIcon(
          "error",
          <div
            className="notify-error"
            dangerouslySetInnerHTML={{ __html: msg }}
          />,
          15
        );
      }
    } else {
      this.showLinkPopup(
        pElements,
        popupElements,
        undefined,
        index,
        formData,
        handelPopupReturn
      );
    }
  };

  displayColon = (field) => {
    let notNames = [".", "-"];
    let evaluate =
      field.name &&
      field.name !== "" &&
      field.name.trim() !== "" &&
      notNames.indexOf(field.name) === -1;
    return evaluate !== "" ? evaluate : false;
  };

  hasBooleanCheckbox = (field) => {
    let rv = false;
    if (
      field.f_type === "boolean" &&
      field.hasOwnProperty("f_dyc_extras") &&
      field.f_dyc_extras !== null &&
      field.f_dyc_extras !== undefined &&
      field.f_dyc_extras !== ""
    ) {
      let fProperty = field["f_dyc_extras"];
      if (typeof fProperty === "string") {
        fProperty = JSON.parse(fProperty);
      }
      rv =
        fProperty.hasOwnProperty("isCheckbox") &&
        fProperty["isCheckbox"] === true;
    }

    return rv;
  };

  displayLabel = (field) => {
    let notNames = [".", "-"],
      lableName = undefined;

    if (
      field.name &&
      field.name !== "" &&
      notNames.indexOf(field.name) === -1
    ) {
      lableName = field.name;

      if (
        field["f_label_display"] &&
        field["f_label_display"] !== "" &&
        field["f_type"] !== "link-field"
      ) {
        lableName = <a onClick={() => this.loadPopup(field)}>{field.name}</a>;
      }
    }

    return this.hasBooleanCheckbox(field) === true ? undefined : lableName;
  };

  collapseGroupItems = (e, i, formRows) => {
    let frRows = formRows.filter((fr) => e.group_name === fr.group_name);
    let _items = [];
    frRows.map((_fr, _fri) => {
      let __fr = Object.assign({}, _fr);
      __fr["collapse_group"] = 0;
      _items.push(this.renderItems(__fr, i + _fri, formRows, true)[0]);
    });

    let items = (
      <div className="col-md-12 quick-estimate-collapse">
        {" "}
        <Collapse>
          <Panel header={e.group_name}>{_items}</Panel>
        </Collapse>{" "}
      </div>
    );

    return [items, i + frRows.length];
  };

  renderItems = (e, i, formRows, noGroupTitle = false) => {
    let gKey = this.dynamicForm.getGroupKey(e.group_name);
    let items = undefined;

    if (e.collapse_group === 1 && e.group_name && e.group_name !== "") {
      let ipLen = this.collapseGroupItems(e, i, formRows);
      i = ipLen[1];
      items = ipLen[0];
    } else if (this.elements.indexOf(e.f_id) === -1) {
      if (
        (e.f_prefix && e.f_prefix !== "" && e.f_prefix * 1 > 0) ||
        (e.f_postfix && e.p_postfix !== "" && e.f_postfix * 1 > 0)
      ) {
        let frElement = formRows.filter(
          (fr) => fr.f_id === (e.f_prefix || e.f_postfix) * 1
        );
        if (frElement && frElement.length === 1) {
          items = (
            <div
              className={
                this.state.formDevideInCols > 1
                  ? "col-md-" + 12 / this.state.formDevideInCols
                  : "col-md-12"
              }
              key={e.f_name + "-" + i}
            >
              <FormItem
                label={this.displayLabel(e)}
                colon={this.displayColon(e)}
                labelCol={{ span: this.props.inlineLayout ? 12 : 24 }}
                wrapperCol={{ span: this.props.inlineLayout ? 12 : 24 }}
                // validateStatus={isFieldTouched(e.f_name) && getFieldError(e.f_name) ? 'error' : ''}
                // help={isFieldTouched(e.f_name) && getFieldError(e.f_name) || ''}
                className={e.f_req === 1 ? "astrick-on" : ""}
                // className = {(e.name === null || e.name === '') ? "no-label-display" : ""}
              >
                <GroupEle compact>
                  {this.dynamicForm.emptyCell(
                    e,
                    undefined,
                    { editable: true },
                    this.elements.indexOf(e.f_id) === -1,
                    false,
                    this.loadPopup
                  )}
                  {this.dynamicForm.emptyCell(
                    frElement[0],
                    undefined,
                    { editable: true },
                    undefined,
                    false,
                    this.loadPopup
                  )}
                </GroupEle>
              </FormItem>
            </div>
          );
        }
        this.elements.push((e.f_prefix || e.f_postfix) * 1);
      } else {
        items = (
          <div
            className={
              this.state.formDevideInCols > 1
                ? "col-md-" + 12 / this.state.formDevideInCols
                : "col-md-12"
            }
            key={e.f_name + "-" + i}
          >
            {this.hasBooleanCheckbox(e) === true ? (
              this.dynamicForm.emptyCell(e, undefined, { editable: true })
            ) : (
              <FormItem
                label={this.displayLabel(e) === "" ? " " : this.displayLabel(e)}
                colon={this.displayColon(e)}
                labelCol={{ span: this.props.inlineLayout ? 12 : 24 }}
                wrapperCol={{ span: this.props.inlineLayout ? 12 : 24 }}
                className={e.f_req === 1 ? "astrick-on" : ""}
              >
                {this.dynamicForm.emptyCell(
                  e,
                  undefined,
                  { editable: true },
                  undefined,
                  true,
                  this.loadPopup
                )}
              </FormItem>
            )}
          </div>
        );
      }
    }

    return [items, i];
  };

  // setReRenderStatus = (status) => {
  //   this.setState({ ...this.state, renderItems: { reRender: status } });
  // };

  calculatedData = (copyData, cp1, cp2, ft, spdtype, passage, cb) => {
    let defaultRow = {};
    ft.map((t) => (defaultRow[t] = 0));

    if (
      spdtype &&
      typeof spdtype !== "string" &&
      spdtype.hasOwnProperty("length") &&
      cp1 &&
      cp2
    ) {
      cp2.map((e, i) => {
        let tsd = e.tsd;
        let std = cp1[spdtype[0] + "_data"];
        let ptd = passage[0] + "_value";
        if (e.spd_type === "2") std = cp1[spdtype[1] + "_data"];
        if (e.passage === "2") ptd = passage[1] + "_value";

        if (i > 0) {
          ft.map((t) => {
            let v = std
              ? std.filter((s) => s.fuel_code.toLowerCase() === t)
              : [];
            if (v && v.length === 1) {
              let value = v[0][ptd] * tsd;
              copyData[i][t] = isNaN(value) ? 0 : value;
            }
          });
        } else {
          copyData[i] = Object.assign(copyData[i], defaultRow);
        }
      });

      if (cb && typeof cb === "function") {
        return cb(copyData);
      }
    }

    return copyData;
  };

  findCpPassage = (fuelType, passageType, consArr) => {
    let cp_price = 0;
    let fuel_cons = 0;
    consArr?.map((el) => {
      const { fuel_type, fuel_code } = el;
      if (fuel_type == fuelType) {
        cp_price = parseFloat(el.cp_price);
        if (passageType === "1") {
          fuel_cons = isNaN(el?.ballast_value)
            ? 0
            : parseFloat(el.ballast_value);
        } else if (passageType === "2") {
          fuel_cons = isNaN(el?.laden_value) ? 0 : parseFloat(el.laden_value);
        }
      }
    });
    cp_price = isNaN(cp_price) ? 0 : parseFloat(cp_price);
    fuel_cons = isNaN(fuel_cons) ? 0 : parseFloat(fuel_cons);
    return { cp_price, fuel_cons };
  };

  removeWordAndTrim = (str, word) => {
    // Create a regular expression to match the word
    const regex = new RegExp(`\\b${word}\\b`, "g");
    // Replace the word with an empty string
    const result = str.replace(regex, "");
    // Trim the resulting string
    return result.trim();
  };

  addDaysAndFormat = (dateString, daysToAdd) => {
    // Parse the input date
    let date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  tabChangeEvent = (e) => {
    try {
      const { tabEvents, showButtons } = this.state;
      const { formData } = this.props;
      let visitedtabs = JSON.parse(localStorage.getItem("tabsVisited"));
      if (e == visitedtabs[0]) {
        localStorage.removeItem("tabsVisited");
        visitedtabs = [];
      }
      let stObject = this.dynamicForm.getStateObject();

      if (e === "Notices Alerts") {
        stObject["."] = {
          delivery_date: stObject["deliveryterm"]["0"]["actual_gmt"],
        };

        stObject["-"] = {
          redelivery_date: stObject["deliveryterm"]["1"]["actual_gmt"],
        };

        stObject[".."]?.map((el, index) => {
          let initialDate = stObject["deliveryterm"]["0"]["actual_gmt"]; // example datetime
          let initialDate2 = stObject["deliveryterm"]["1"]["actual_gmt"];
          let numberOfDaysToAdd = Number(
            this.removeWordAndTrim(el["notice_type"], "DAYS")
          );
          if (initialDate) {
            stObject[".."][index]["project_delivary"] = this.addDaysAndFormat(
              initialDate,
              numberOfDaysToAdd
            );
          }
          if (initialDate2) {
            stObject["..."][index]["project_delivary"] = this.addDaysAndFormat(
              initialDate2,
              numberOfDaysToAdd
            );
          }
        });
      }

      let { resp } = this.state;

      tabEvents.map((te) => {
        if (
          te.tabName === e &&
          te?.event?.type === "copy" &&
          !te.event.condition &&
          !te.event.showSingleIndex &&
          !visitedtabs.includes(e)
        ) {
          let fromkeyName = this.dynamicForm.getGroupKey(te.event.from);
          let copyName = this.dynamicForm.getGroupKey(te.tabName);

          let fromfields = Object.keys(te.event.fields);
          let tofields = Object.values(te.event.fields);
          let copyData = [];
          let groupData = [];
          let copyobj = {};

          resp[fromkeyName]?.map((el, index) => {
            copyobj = {};

            if (resp?.[copyName]?.[index] && resp?.[copyName]?.[index]["id"]) {
              copyobj = resp?.[copyName]?.[index];
            } else {
              copyobj = {
                id: -9e6 + index,
                index: index,
              };
            }

            for (let i = 0; i < tofields.length; i++) {
              copyobj = {
                ...copyobj,
                [tofields[i]]: resp[fromkeyName][index][fromfields[i]],
              };
            }
            if (copyName == "bunkerdetails") {
              const values = BunkerDetailsCalculation(stObject, index);
              copyobj = { ...copyobj, ...values };
              copyData.push(copyobj);
            }

            if (copyName === "portdatedetails") {
              let copy = { ...copyobj };
              let totaldaysum =
                parseFloat(copy["days"]) + parseFloat(copy["xpd"]);
              copy["pdays"] =
                totaldaysum > 0 && !isNaN(totaldaysum)
                  ? totaldaysum.toFixed(2)
                  : "0.00";
              copyData.push(copy);
            }
            if (copyName === "portcargo") {
              copyData.push(copyobj);
            }

            if (copyName == "ciidynamics") {
              const values = BunkerDetailsCalculation(resp, index);
              copyobj = { ...copyobj, ...values };
              let days = resp?.["portitinerary"]?.[index]?.["days"] ?? 0;
              let xpd = resp?.["portitinerary"]?.[index]?.["xpd"] ?? 0;
              let tsd = el["tsd"] ? parseFloat(el["tsd"]).toFixed(2) : 0;
              let opsport = parseFloat(Number(days) + Number(xpd)).toFixed(2);
              let total = tsd * 1 + opsport * 1;
              let date = new Date();
              copyobj = {
                ...copyobj,
                year: date.getFullYear(),
                dst_sailed: el["miles"] ? el["miles"] : "0.00",
                sea: tsd,
                ops_port: parseFloat(opsport).toFixed(2),
                port: el["port"],
                funct: el["funct"],
                passage: resp["portitinerary"][index]["passage"],
                total: isNaN(total) ? 0 : parseFloat(total).toFixed(2),
              };
              copyData.push(copyobj);
            }

            if (copyName == "euets") {
              const values = BunkerDetailsCalculation(resp, index);
              copyobj = { ...copyobj, ...values };
              let date = new Date();
              copyobj = {
                ...copyobj,
                year: date.getFullYear(),
                dst_sailed: el["miles"] ? el["miles"] : "0.00",
                sea: el["tsd"],
                port: el["port"],
                funct: el["funct"],
                is_eur:
                  resp?.["portitinerary"]?.[index]?.["is_eur"] === "False"
                    ? 0
                    : 1,
              };

              copyData.push(copyobj);
            }
          });

          this.dynamicForm.setData(
            copyName,
            copyData,
            groupData,
            undefined,
            "COPYST"
          );
          if (copyName == "ciidynamics") {
            setTimeout(() => {
              //  const { groupName, updateFields, field_name } = f_event;
              let cii_calculation_fields = {
                cii_ref: "",
                cii_req: "",
                cii_att: "",
                cii_ret: "",
                cii_band: "",
                cii_pred: "",
                cii_pred_ret: "",
                year: "",
                port: "",
                function: "",
                passage: "",
                sea: "",
                total: "",
                dst_sailed: "",
                ifo: "",
                vlsfo: "",
                lsmgo: "",
                mgo: "",
                pc_ifo: "",
                pc_vlsfo: "",
                pc_lsmgo: "",
                pc_mgo: "",
                pc_ulsfo: "",
                co2_emission_vu: "",
                co2_emission_ml: "",
                co2_emission_ifo: "",
                co2_emission_total: "",
              };

              const ciidynamicsarr = resp["ciidynamics"];
              let groupName = "ciidynamics";
              ciidynamicsarr &&
                ciidynamicsarr.length > 0 &&
                ciidynamicsarr?.map((el, _index) => {
                  let action = {
                    type: "add",
                    formName: this.props.frmCode,
                    groupName: this.dynamicForm.getGroupKey(groupName),
                    index: _index,
                    value: {},
                  };
                  let cii_field_values = resp[groupName][_index];
                  let dwt = resp["dwt"];
                  let cii_values = {
                    ...cii_calculation_fields,
                    ...cii_field_values,
                  };
                  let vesselType = cookies.get("typecode");
                  let values = VoyageCIIDynamicsCalculation(
                    vesselType,
                    dwt,
                    cii_values
                  ); // for default value of shipt type='bulk carrier'

                  let updateFields = [
                    "co2_emission_vu",
                    "co2_emission_ml",
                    "co2_emission_ifo",
                    "co2_emission_total",
                    "cii_ref",
                    "cii_req",
                    "cii_att",
                    "cii_ret",
                    "cii_band",
                    "cii_pred",
                    "cii_pred_ret",
                  ];
                  updateFields?.map((el) => {
                    let ciiAction = Object.assign({}, action);
                    ciiAction["value"][el] = values[el];
                    this.createStore.dispatch(ciiAction);
                  });
                });
            }, 1000);
          }

          if (copyName === "euets") {
            setTimeout(() => {
              let groupName = "euets";

              let euets_calculation_fields = {
                year: "",
                port: "",
                function: "",
                ifo: "",
                vlsfo: "",
                lsmgo: "",
                mgo: "",
                ulsfo: "",
                pc_ifo: "",
                pc_vlsfo: "",
                pc_ulsfo: "",
                pc_lsmgo: "",
                pc_mgo: "",
                ttl_ems: "",
                phase_pre: "",
                sea_pre_ets: "",
                sea_ets_ems: "",
                port_pre_ems: "",
                port_ets_ems: "",
                ttl_eu_ets: "",
                ttl_eu_ets_exp: "",
              };
              const euetsarr = resp["euets"];

              euetsarr &&
                euetsarr.length > 0 &&
                euetsarr?.map((el, _index) => {
                  let action = {
                    type: "add",
                    formName: this.props.frmCode,
                    groupName: this.dynamicForm.getGroupKey(groupName),
                    index: _index,
                    value: {},
                  };
                  let euets_field_values = resp[groupName][_index];
                  let euets_values = {
                    ...euets_calculation_fields,
                    ...euets_field_values,
                  };

                  let prevPort =
                    _index - 1 >= 0 ? euetsarr[_index - 1]?.is_eur : "";
                  let currentPort = euetsarr[_index]?.is_eur ? 1 : 0;

                  let values = VoyageEuetsCalculation(
                    prevPort,
                    currentPort,
                    euets_values
                  ); // for default value of shipt type='bulk carrier';

                  let updateFields = [
                    "sea_ems",
                    "port_ems",
                    "ttl_ems",
                    "phase_pre",
                    "sea_pre_ets",
                    "sea_ets_ems",
                    "port_pre_ems",
                    "port_ets_ems",
                    "ttl_eu_ets",
                    "ttl_eu_ets_exp",
                  ];
                  updateFields?.map((el) => {
                    let ciiAction = Object.assign({}, action);
                    ciiAction["value"][el] = values[el];
                    this.createStore.dispatch(ciiAction);
                  });
                });
            }, 1000);
          }

          // if(copyName === '')

          // if (copyName === "portdatedetails") {
          //   setTimeout(() => {
          //     let commence =
          //       resp?.["portdatedetails"]?.[0]?.["arrival_date_time"];
          //     let completed =
          //       resp?.["portdatedetails"]?.[
          //         resp["portdatedetails"]?.length - 1
          //       ]?.["departure"];
          //     this.dynamicForm.setData("commence_date", commence, undefined);
          //     this.dynamicForm.setData("completing_date", completed, undefined);
          //   }, 1000);
          // }
        } else if (
          te.tabName === e &&
          te.event &&
          te.event.type === "copy" &&
          te.event.hasOwnProperty("condition") &&
          te.event.hasOwnProperty("showSingleIndex")
        ) {
          let fromGKey = this.dynamicForm.getGroupKey(te.event.group.from);
          let toGKey = this.dynamicForm.getGroupKey(te.event.group.to);
          let stObject = this.dynamicForm.getStateObject();
          if (stObject.hasOwnProperty(fromGKey)) {
            let row = stObject[fromGKey].filter(
              (frow) =>
                frow[te.event.condition.columnName] ===
                te.event.condition.columnValue
            );
            if (
              row &&
              typeof row === "object" &&
              row.hasOwnProperty("length") &&
              row.length > 0
            ) {
              let copyData = {};
              row = row[0];
              Object.keys(te.event.fields).map(
                (field) => (copyData[field] = row[field])
              );
              this.dynamicForm.setData(toGKey, copyData, te.event.group.to);
            }
          }
        } else if (
          te.tabName === e &&
          te.event &&
          te.event.type === "rename-button" &&
          showButtons.hasOwnProperty("length")
        ) {
          showButtons.map((be, bidx) => {
            if (be.id === te.event.key)
              showButtons[bidx]["title"] = te.event.title;
          });
          this.setState({ ...this.state, showButtons: showButtons });
        } else if (
          te.tabName === e &&
          te.event &&
          te.event.from === "Bunkers"
        ) {
          let stObject = this.dynamicForm.getStateObject();
          let groupName = "tcbunkeradjustment";
          let action = {
            type: "add",
            formName: this.props.frmCode,
            groupName: this.dynamicForm.getGroupKey(groupName),
            index: undefined,
            value: {},
          };
          let tcbunkeradjustment = [];
          if (stObject && stObject.hasOwnProperty("tcbunkeradjustment")) {
            let deliveryData = null;
            let reDeliveryData = null;

            stObject?.bunkers?.forEach((val, indx) => {
              let {
                f_IFO,
                f_IFO_p,
                f_LSMGO,
                f_LSMGO_p,
                f_MGO,
                f_MGO_p,
                f_ULSFO,
                f_ULSFO_p,
                f_VLSFO,
                f_VLSFO_p,
              } = val;

              let ifo_cal = f_IFO && f_IFO_p ? +f_IFO * +f_IFO_p : 0;
              let lsmgo_cal = f_LSMGO && f_LSMGO_p ? +f_LSMGO * +f_LSMGO_p : 0;
              let mgo_cal = f_MGO && f_MGO_p ? +f_MGO * +f_MGO_p : 0;
              let ulsfo_cal = f_ULSFO && f_ULSFO_p ? +f_ULSFO * +f_ULSFO_p : 0;
              let vlsfo_cal = f_VLSFO && f_VLSFO_p ? +f_VLSFO * +f_VLSFO_p : 0;

              if (indx === 0) {
                deliveryData = [
                  ifo_cal,
                  lsmgo_cal,
                  ulsfo_cal,
                  vlsfo_cal,
                  mgo_cal,
                ];
              } else if (indx === stObject.bunkers.length - 1) {
                reDeliveryData = [
                  ifo_cal,
                  lsmgo_cal,
                  ulsfo_cal,
                  vlsfo_cal,
                  mgo_cal,
                ];
              }
            });

            let rows = [
              {
                bod: "Delivery Value IFO",
                bod_value: "0.00",
                bor: "Redelivery Value IFO",
                bor_value: "0.00",
                net_bunker: "Net IFO Value",
                per_allowed: "100.00",
                all_bunker_amount: "0.00",
                net_bunker_value: "0.00",
                voyage_number: "",
              },
              {
                bod: "Delivery Value LSMGO",
                bod_value: "0.00",
                bor: "Redelivery Value LSMGO",
                bor_value: "0.00",
                net_bunker: "Net LSMGO Value",
                per_allowed: "100.00",
                all_bunker_amount: "0.00",
                net_bunker_value: "0.00",
                voyage_number: "",
              },
              {
                bod: "Delivery Value ULSFO",
                bod_value: "0.00",
                bor: "Redelivery Value ULSFO",
                bor_value: "0.00",
                net_bunker: "Net ULSFO Value",
                per_allowed: "100.00",
                all_bunker_amount: "0.00",
                net_bunker_value: "0.00",
                voyage_number: "",
              },
              {
                bod: "Delivery Value VLSFO",
                bod_value: "0.00",
                bor: "Redelivery Value VLSFO",
                bor_value: "0.00",
                net_bunker: "Net VLSFO Value",
                per_allowed: "100.00",
                all_bunker_amount: "0.00",
                net_bunker_value: "0.00",
                voyage_number: "",
              },
              {
                bod: "Delivery Value MGO",
                bod_value: "0.00",
                bor: "Redelivery Value MGO",
                bor_value: "0.00",
                net_bunker: "Net MGO Value",
                per_allowed: "100.00",
                all_bunker_amount: "0.00",
                net_bunker_value: "0.00",
                voyage_number: "",
              },
              {
                // bod : "Delivery Value MGO",
                // "bod_value": "0.00",
                // "bor": "Redelivery Value MGO",
                // "bor_value": "0.00",
                net_bunker: "Net Bunker Value",
                per_allowed: "100.00",
                all_bunker_amount: "0.00",
                net_bunker_value: "0.00",
                voyage_number:
                  this.props.formData.tcbunkeradjustment[5].voyage_number || "",
              },
            ];

            let sum = 0;
            stObject?.tcbunkeradjustment?.forEach((val, indx) => {
              let bod_value = deliveryData[indx];
              let bor_value = ![indx]
                ? deliveryData[indx]
                : reDeliveryData[indx];
              let net_bunker_value = bor_value - bod_value;
              // let voyage_number = 0;
              if (stObject.tcbunkeradjustment.length - 1 !== indx) {
                let all_bunker_amount =
                  (Number(val.per_allowed) * net_bunker_value) / 100;
                tcbunkeradjustment.push({
                  ...val,
                  bod_value,
                  bor_value,
                  net_bunker_value,
                  all_bunker_amount,
                });
                sum += net_bunker_value;
              } else {
                let all_bunker_amount = (Number(val.per_allowed) * sum) / 100;
                tcbunkeradjustment.push({
                  ...rows[indx],
                  net_bunker_value: sum,
                  all_bunker_amount,
                  id: val.id,
                });
              }
            });

            action.value = { tcbunkeradjustment };
            this.createStore.dispatch(action);

            this.setState({ ...this.state, isRemovePlaceHolder: true });

            // document.getElementById("bor_value5")?.setAttribute("placeholder","")

            // document.getElementById("bod_value5")?.setAttribute("placeholder","")
          }
        }
      });

      if (!visitedtabs.includes(e)) {
        visitedtabs.push(e);
        localStorage.setItem("tabsVisited", JSON.stringify([...visitedtabs]));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  formInnerItems = (responseData, showButtons, showToolbar) => {
    const {
      extraFormFields,
      resp,
      groupAction,
      isShowFixedColumn,
      extraTableButton,
      sideList,
      showSideListBar,
      hideGroup,
      disableTabs,
      tableRowDeleteAction,
      editMode,
    } = this.state;
    let groupItems = [],
      showDiv = false,
      toolbarElements = "",
      formTagElements = "",
      skipIndex = -1,
      findTab = [];

    toolbarElements = (
      <>
        {showToolbar && showToolbar.length > 0
          ? showToolbar.map((t, i) => {
              return (
                <div key={"lr_" + i} className="full-width">
                  <Row>
                    <Col
                      xs={24}
                      sm={5}
                      md={t.leftWidth || 3}
                      lg={t.leftWidth || 3}
                      xl={t.leftWidth || 3}
                    >
                      {t.isLeftBtn.map((el, i) => {
                        return (
                          <span
                            key={el.key + "_" + (i + 1).toString()}
                            className="wrap-bar-menu"
                          >
                            <ul className="wrap-bar-ul">
                              {el?.isSets?.map((set, j) => {
                                if (set) {
                                  return (
                                    <li
                                      id={set.key}
                                      key={"opt-" + j}
                                      onClick={() => {
                                        if (
                                          set.hasOwnProperty("event") &&
                                          typeof set.event === "function"
                                        ) {
                                          set.event(
                                            set.key,
                                            this.state.resp,
                                            this.resetForm
                                          );
                                        } else if (
                                          set.hasOwnProperty("event") &&
                                          typeof set.event !== "function" &&
                                          set.event === "reset"
                                        ) {
                                          this.resetForm();
                                        } else if (
                                          set.hasOwnProperty("event") &&
                                          typeof set.event !== "function" &&
                                          set.event === "menu-fold"
                                        ) {
                                          this.foldMenu();
                                        }
                                      }}
                                    >
                                      {set.showToolTip === true ? (
                                        <Tooltip title={set.withText}>
                                          {set.type}
                                        </Tooltip>
                                      ) : (
                                        <>
                                          {set.type}
                                          <span className="text-bt">
                                            {set.withText}
                                          </span>
                                        </>
                                      )}
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </span>
                        );
                      })}
                    </Col>

                    <Col
                      xs={24}
                      sm={19}
                      md={t.rightWidth || 21}
                      lg={t.rightWidth || 21}
                      xl={t.rightWidth || 21}
                    >
                      <Row type="flex" justify="end">
                        {t.isRightBtn.map((el, i) => {
                          return (
                            <span
                              key={(i + 1).toString()}
                              className="wrap-bar-menu"
                            >
                              <ul className="wrap-bar-ul">
                                {el.isSets.map((set, j) => {
                                  if (typeof set === "object" && set) {
                                    var myvar = (
                                      <li key={j} id={set.key}>
                                        {
                                          set.isDropdown === 0 ? (
                                            <span
                                              onClick={() => {
                                                if (
                                                  set.hasOwnProperty("event") &&
                                                  typeof set.event ===
                                                    "function"
                                                ) {
                                                  set.event(
                                                    set.key,
                                                    this.state.resp
                                                  );
                                                }
                                              }}
                                            >
                                              {set.showToolTip === true ? (
                                                <Tooltip title={set.withText}>
                                                  {set.type}
                                                </Tooltip>
                                              ) : (
                                                <>
                                                  {set.type}
                                                  <span className="text-bt">
                                                    {set.withText}
                                                  </span>
                                                </>
                                              )}
                                            </span>
                                          ) : (
                                            <Dropdown
                                              key={j}
                                              overlay={() =>
                                                this.generateMenus(set.menus)
                                              }
                                              trigger={["click"]}
                                            >
                                              {set.showToolTip === true ? (
                                                <Tooltip title={set.withText}>
                                                  {set.type}
                                                </Tooltip>
                                              ) : (
                                                <>
                                                  {set.type}
                                                  <span className="text-bt">
                                                    {set.withText}
                                                  </span>
                                                </>
                                              )}
                                              {/* <span className="text-bt">
                                                {set.withText}
                                              </span> */}
                                            </Dropdown>
                                          )
                                          //TODO TECH LOCAL
                                          // <span className="text-bt">{set.withText}</span>
                                        }
                                      </li>
                                    );

                                    return myvar;
                                  }
                                })}
                              </ul>
                            </span>
                          );
                        })}
                        <div>
                          {t.isResetOption ? (
                            <span className="wrap-bar-menu more-icon">
                              <Button
                                type="danger"
                                onClick={() => this.resetForm()}
                              >
                                Reset
                              </Button>
                            </span>
                          ) : null}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </div>
              );
            })
          : undefined}
      </>
    );

    formTagElements = (
      <>
        {responseData && responseData.frm && responseData.frm.length > 0 ? (
          responseData.frm.map((e, i) => {
            if (e.group_name && i > skipIndex) {
              let it = responseData.groups.filter(
                (ge) => ge.group_name === e.group_name
              );
              if (it && it.length > 0 && groupItems.indexOf(e.group_name) < 0) {
                groupItems.push(e.group_name);
                let groupKey = this.dynamicForm.getGroupKey(e.group_name);
                if (it[0].g_width * 1 < 100 && showDiv === false) {
                  showDiv = true;
                } else {
                  showDiv = false;
                }
                if (it[0].group_type.toLowerCase() === "normal") {
                  divshow = "";
                  if (
                    groupKey == "totalitinerarysummary" ||
                    groupKey == "totalbunkersummary" ||
                    groupKey == "companytype"
                  ) {
                    divshow = (
                      <div
                        className={
                          groupKey == "companytype"
                            ? "astrick_comapny"
                            : "normal_cls"
                        }
                      >
                        {e.group_name}
                      </div>
                    );
                  }

                  if (
                    hideGroup.length == 0 ||
                    (hideGroup.length > 0 &&
                      hideGroup.indexOf(it[0]["group_name"]) === -1)
                  ) {
                    return (
                      <div
                        key={groupKey + "_div_" + i}
                        className={"nmp normal-" + groupKey}
                        style={{
                          marginTop: "10px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          width:
                            it[0].g_width && it[0].g_width === "100"
                              ? "100%"
                              : it[0].g_width + "%",
                        }}
                      >
                        {divshow}
                        <div key={groupKey + "_div__" + i} className="row">
                          <NormalFormIndex
                            key={groupKey}
                            frm={{ frm: it[0].columns, groups: [] }}
                            frmProp={this.props}
                            parentformName={this.props.frmCode}
                            formData={resp}
                            inlineLayout={true}
                            editMode={editMode}
                            dynamicForm={this.dynamicForm}
                            createStore={this.createStore}
                            isShowFixedColumn={isShowFixedColumn}
                            formDevideInCols={
                              this.state.formGroupDevideInCols.hasOwnProperty(
                                groupKey
                              )
                                ? this.state.formGroupDevideInCols[groupKey]
                                : it[0].view_cols > 0
                                ? it[0].view_cols
                                : it[0].g_width && it[0].g_width !== "100"
                                ? "1"
                                : undefined
                            }
                            summary={this.props.summary || []}
                            disableTabs={disableTabs}
                            tableRowDeleteAction={tableRowDeleteAction}
                          />
                        </div>
                      </div>
                    );
                  }
                } else if (it[0].group_type.toLowerCase() === "table") {
                  findTab =
                    disableTabs && disableTabs.length > 0
                      ? disableTabs.find(
                          (de) => de["groupKey"] === it[0]["group_key"]
                        )
                      : undefined;
                  if (
                    hideGroup.length == 0 ||
                    (hideGroup.length > 0 &&
                      hideGroup.indexOf(it[0]["group_name"]) === -1)
                  ) {
                    return (
                      <IndexTableForm
                        key={groupKey}
                        className={"table-" + groupKey}
                        mainFormName={this.props.frmCode}
                        parentformName={this.state.parentformName}
                        frmConfig={{
                          frm: it[0].columns,
                          groups: [
                            Object.assign({}, it[0], {
                              g_width: "100",
                              actual_width: it[0]["g_width"],
                            }),
                          ],
                        }}
                        dynamicForm={this.dynamicForm}
                        createStore={this.createStore}
                        formData={resp}
                        displayOnly={groupAction}
                        editMode={editMode}
                        groupKey={groupKey}
                        fullWidth={it[0].g_width * 1 < 100 ? false : true}
                        showDiv={showDiv}
                        isShowFixedColumn={isShowFixedColumn}
                        extraTableButton={extraTableButton}
                        summary={this.props.summary || []}
                        modalEvent={this.loadPopup}
                        disableTab={
                          findTab && findTab.hasOwnProperty("tabName")
                            ? findTab
                            : undefined
                        }
                        tableRowDeleteAction={tableRowDeleteAction}
                      />
                    );
                  }
                }
              } else if (
                it &&
                it.length === 0 &&
                groupItems.indexOf(e.group_name) < 0
              ) {
                let _return = this.renderItems(e, i, responseData.frm);
                if (i === _return[1]) {
                  return _return[0];
                } else {
                  skipIndex = _return[1];
                  return _return[0];
                }
              }
            } else if (!e.group_name) {
              return this.renderItems(e, i, responseData.frm)[0];
            }
          })
        ) : (
          <div className="col col-lg-12">
            <CustomLoader />
          </div>
        )}

        {responseData && responseData.tabs && responseData.tabs.length > 0 ? (
          <div
            className="tabbed-wrapper-cust"
            style={{ width: responseData.tabs[0]["tab_width"] + "%" }}
          >
            <Tabs
              size="small"
              style={{ width: "100%" }}
              onChange={this.tabChangeEvent}
            >
              {responseData.tabs.map((e, i) => {
                {
                  findTab =
                    disableTabs && disableTabs.length > 0
                      ? disableTabs.find(
                          (de) =>
                            de.tabName.toLowerCase() ===
                            e.tab_name.toLowerCase()
                        )
                      : undefined;
                }

                return (
                  <TabPane tab={e.tab_name} key={e.tab_name}>
                    {e.frm_type === "Table" ? (
                      this.props.staticTabs &&
                      this.props.staticTabs[e.tab_name] ? (
                        this.props.staticTabs[e.tab_name]()
                      ) : (
                        // <div>{`${e.tab_name}/${e.frm_code}`}</div>
                        <IndexTableForm
                          key={e.frm_code}
                          frmCode={e.frm_code}
                          dynamicForm={this.dynamicForm}
                          createStore={this.createStore}
                          mainFormName={this.props.frmCode}
                          parentformName={this.state.parentformName}
                          formData={resp}
                          editMode={editMode}
                          originalForm={responseData.tabs}
                          isShowFixedColumn={isShowFixedColumn}
                          extraTableButton={extraTableButton}
                          fullWidth={true}
                          summary={this.props.summary || []}
                          dyncGroupName={e.groupName || undefined}
                          modalEvent={this.loadPopup}
                          disableTab={
                            findTab && findTab.hasOwnProperty("tabName")
                              ? findTab
                              : undefined
                          }
                          tableRowDeleteAction={tableRowDeleteAction}
                        />
                      )
                    ) : (
                      <div className="row">
                        {this.props.staticTabs &&
                        this.props.staticTabs[e.tab_name] ? (
                          this.props.staticTabs[e.tab_name]()
                        ) : (
                          <NormalFormIndex
                            key={e.frm_code}
                            frmCode={e.frm_code}
                            frmProp={this.props}
                            parentformName={this.props.frmCode}
                            formData={resp}
                            dynamicForm={this.dynamicForm}
                            createStore={this.createStore}
                            editMode={editMode}
                            isShowFixedColumn={isShowFixedColumn}
                            inlineLayout={true}
                            summary={this.props.summary || []}
                            extraTableButton={
                              this.props.extraTableButton || undefined
                            }
                            disableTabs={disableTabs}
                            tableRowDeleteAction={tableRowDeleteAction}
                          />
                        )}
                      </div>
                    )}
                  </TabPane>
                );
              })}
            </Tabs>
          </div>
        ) : undefined}

        {extraFormFields &&
        extraFormFields.hasOwnProperty("isShowInMainForm") &&
        extraFormFields.isShowInMainForm === true
          ? extraFormFields.content
          : undefined}
        <div
          style={{ width: "100%", paddingTop: "10px", paddingRight: "10px" }}
        >
          <Row align="middle" justify="end" type="flex">
            {showButtons && showButtons.length > 0
              ? showButtons.map((b, i) => {
                  if (b.id === "cancel") {
                    return (
                      <Button
                        key={b.id}
                        type={b.type}
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          this.resetForm();
                          if (b.event && typeof b.event === "function") {
                            b.event();
                          }
                        }}
                      >
                        {b.title}
                      </Button>
                    );
                  } else {
                    return (
                      <Button
                        key={b.id}
                        type={b.type}
                        onClick={() => b.event(this.state.resp, this.resetForm)}
                      >
                        {b.title}
                      </Button>
                    );
                  }
                })
              : undefined}
          </Row>
        </div>
        {/* {this.dynamicForm.resetGroupList()} */}
      </>
    );

    if (
      sideList &&
      sideList.hasOwnProperty("showList") &&
      sideList["showList"] === true
    ) {
      return (
        <>
          {toolbarElements}
          {showSideListBar === true ? (
            <div className="col-md-2 pr-0">
              <SideList
                selectedID={sideList.selectedID}
                statusList={sideList.statusList}
                toolbarKey={sideList.toolbarKey}
                title={sideList.title}
                columns={sideList.columns}
                uri={sideList.uri}
                searchString={sideList.searchString}
                rowClick={(event) => sideList.rowClickEvent(event)}
                icon={sideList.icon || false}
              />
            </div>
          ) : (
            ""
          )}
          <div className={showSideListBar === true ? "col-md-10" : "col-md-12"}>
            <div className="row">{formTagElements}</div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {toolbarElements}
          {formTagElements}
        </>
      );
    }
  };

  resetForm = () => {
    this.setState({ ...this.state, resetFormFlag: true });
    setTimeout(() => {
      this.createStore.dispatch({ type: "reset" });
      this.setState({ ...this.state, resp: {}, resetFormFlag: false });
    }, 50);
  };

  renderFormTag = (showForm, showButtons, showToolbar, responseData) => {
    let formTag = this.formInnerItems(responseData, showButtons, showToolbar);
    if (showForm === true) {
      let formTagEle = (
        <Form>
          <div className="row">{formTag}</div>
        </Form>
      );
      if (this.props.formClass) {
        formTagEle = (
          <Form className={this.props.formClass}>
            <div className="row">{formTag}</div>
          </Form>
        );
      }
      formTag = formTagEle;
    }

    return formTag;
  };

  render() {
    const { responseData, resetFormFlag, showButtons, labelModal } = this.state;
    const { showForm, showToolbar, sendBackData, triggerEvent } = this.props;

    if (
      sendBackData &&
      sendBackData["show"] === true &&
      typeof triggerEvent === "function"
    ) {
      this.props.triggerEvent(this.state.resp);
    }

    return (
      <>
        {!resetFormFlag ? (
          this.renderFormTag(showForm, showButtons, showToolbar, responseData)
        ) : (
          <div className="col col-lg-12">{/* <CustomLoader /> */}</div>
        )}
        {labelModal.modalStatus ? (
          <ModalAlertBox
            modalStatus={labelModal.modalStatus}
            modalHeader={labelModal.modalHeader}
            modalBody={labelModal.modalBody}
            modalFooter={labelModal.modalFooter}
            onCancelFunc={(data) => this.onCloseModal(data)}
            modalWidth={labelModal.modalWidth}
          />
        ) : undefined}
      </>
    );
  }
}

export default NormalFormIndex;
