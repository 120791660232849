import React, { Component } from "react";
import { Table, Tabs, Input, Row, Col, Form, Spin, Alert } from "antd";

import ToolbarUI from "../../../../components/ToolbarUI";
const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const FormItem = Form.Item;

const columns = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "20%",
  },

  {
    title: "Estimated",
    dataIndex: "estimate",
    key: "estimate",
    width: "8%",
  },
  {
    title: "Actual",
    dataIndex: "actual",
    key: "actual",
    width: "8%",
  },
  {
    title: "Posted",
    dataIndex: "posted",
    key: "posted",
    width: "8%",
  },
  {
    title: "Cash In",
    dataIndex: "cash_in",
    key: "cash_in",
    width: "8%",
  },
  {
    title: `Diff`,
    dataIndex: "Diff",
    key: "Diff",
    width: "8%",
  },
  {
    title: "%Diff",
    dataIndex: "perDiff",
    key: "perDiff",
    width: "8%",
  },
  {
    title: "Post Vs Cash",
    dataIndex: "sec_variance",
    key: "sec_variance",
    width: "8%",
  },
  {
    title: "% Post Vs Cash",
    dataIndex: "sec_per",
    key: "sec_per",
    width: "8%",
  },
];

const columns2 = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "20%",
  },
  {
    title: "Estimated",
    dataIndex: "estimate",
    key: "estimate",
    width: "12%",
    align: "right",
  },
  { title: "", dataIndex: "", key: "blank", width: "68%" },
];

class PL extends Component {
  callback = (evt) => {};
  constructor(props) {
    super(props);

    this.state = {
      dollarUSLocale: Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      formData: this.props.formData || {},
      estimateDatavalue: this.props.estimateData || {},
      showPL: false,
      vesselAmount: 0,
      viewTabs: this.props.viewTabs || [
        "Estimate View",
        "Actual &  Operation View",
        "Account View",
      ],
      estimateData: [
        {
          key: "revenue",
          description: "Revenue",
          estimate: 0,
          children: [
            { key: "rev10", description: "Freight", estimate: 0 },
            { key: "rev11", description: "Freight Commission", estimate: 0 },
            { key: "rev12", description: "Misc. Revenue", estimate: 0 },
            { key: "rev13", description: "Demurrage Amt", estimate: 0 },
            { key: "rev14", description: "Demm Comm Amt", estimate: 0 },
            { key: "rev15", description: "Despatch Amt", estimate: 0 },
            { key: "rev16", description: "Gross Revenue", estimate: 0 },
            { key: "rev17", description: "Net Revenue", estimate: 0 },
          ],
        },
        {
          key: "expenses",
          description: "Expenses",
          estimate: 0,
          children: [
            { key: "ex10", description: "Vessel expenses" },
            { key: "ex11", description: "TCI Address Commission", estimate: 0 },
            { key: "ex12", description: "TCI Bro Commission", estimate: 0 },
            { key: "ex13", description: "Port Expenses", estimate: 0 },
            { key: "ex14", description: "Emmission Expenses", estimate: 0 },
            { key: "ex15", description: "Misc. Expenses", estimate: 0 },
            {
              key: "ex16",
              description: "Bunker Expenses",
              estimate: 0,
              children: [
                {
                  key: "ex160",
                  description: "Sea Consp Non ECA",
                  estimate: 0,
                  children: [
                    { key: "ex1600", description: "IFO", estimate: 0 },
                    { key: "ex1601", description: "MGO", estimate: 0 },
                    { key: "ex1602", description: "VLSFO", estimate: 0 },
                    { key: "ex1603", description: "LSMGO", estimate: 0 },
                    { key: "ex1604", description: "ULSFO", estimate: 0 },
                  ],
                },

                {
                  key: "ex161",
                  description: "Sea Consp ECA",
                  estimate: 0,
                  children: [
                    { key: "ex1610", description: "IFO", estimate: 0 },
                    { key: "ex1611", description: "MGO", estimate: 0 },
                    { key: "ex1612", description: "VLSFO", estimate: 0 },
                    { key: "ex1613", description: "LSMGO", estimate: 0 },
                    { key: "ex1614", description: "ULSFO", estimate: 0 },
                  ],
                },

                {
                  key: "ex162",
                  //description: "Port Consp Non ECA",
                  description: "Port Consp",
                  estimate: 0,
                  children: [
                    { key: "ex1620", description: "IFO", estimate: 0 },
                    { key: "ex1621", description: "MGO", estimate: 0 },
                    { key: "ex1622", description: "VLSFO", estimate: 0 },
                    { key: "ex1623", description: "LSMGO", estimate: 0 },
                    { key: "ex1624", description: "ULSFO", estimate: 0 },
                  ],
                },
                {
                  key: "ex163",
                  //description: "Port Consp Non ECA",
                  description: "TC Bunker Adjustment",
                  estimate: 0,
                  children: [
                    {
                      key: "ex1630",
                      description: "TCI Bunker Adjustment",
                      estimate: props.formData.tci_pl_tc_bunker,
                    },
                  ],
                },

                // {
                //   key: "ex163",
                //   description: "Port Consp ECA",
                //   estimate: 0,
                //   children: [
                //     { key: "ex1630", description: "IFO", estimate: 0 },
                //     { key: "ex1631", description: "MGO", estimate: 0 },
                //     { key: "ex1632", description: "VLSFO", estimate: 0 },
                //     { key: "ex1633", description: "LSMGO", estimate: 0 },
                //     { key: "ex1634", description: "ULSFO", estimate: 0 },
                //   ],
                // },
              ],
            },
            { key: "ex17", description: " Gross. Expenses", estimate: 0 },
            { key: "ex18", description: "Net. Expenses", estimate: 0 },
          ],
        },
        {
          key: "voyage-result",
          description: "Voyage Result",
          estimate: 0,
          children: [
            { key: "vr20", description: "Profit (Loss)", estimate: 0 },
            { key: "vr21", description: "Daily Profit (Loss)", estimate: 0 },
            { key: "vr22", description: "TCE Hire ( Net Daily )", estimate: 0 },
            { key: "vr23", description: "Gross TCE", estimate: 0 },
            {
              key: "vr24",
              description: "Average Freight rate ($/T)",
              estimate: 0,
            },
            {
              key: "vr25",
              description: "Breakeven & Freight rate ($/T)",
              estimate: 0,
            },

            {
              key: "vr26",
              description: "Co2 Cost",
              estimate: 0,
            },

            {
              key: "vr27",
              description: "CO2 Adjusted Profit (Loss)",
              estimate: 0,
            },

            {
              key: "vr28",
              description: "CO2 Adjusted (Net) TCE",
              estimate: 0,
            },

            { key: "vr29", description: "Total Sea Days", estimate: 0 },
            { key: "vr210", description: "Total Port Days", estimate: 0 },
            { key: "vr211", description: "Net Voyage Days", estimate: 0 },
          ],
        },
      ],
    };
  }

  DemurrageDespatchCalculation = (itenaryarr) => {
    let demurrage = 0;
    let despatch = 0;
    itenaryarr?.forEach((el) => {
      if (el.dem_disp === "Despatch") {
        despatch += parseFloat(el.dem_disp_amt);
      } else if (el.dem_disp === "Demmurage") {
        demurrage += parseFloat(el.dem_disp_amt);
      }
    });
    return { demurrage, despatch };
  };

  checkNaN = (el) => {
    return isNaN(el) ? 0 : el * 1;
  };

  findCpPassage = (fuelType, passageType, consArr) => {
    let cp_price = 0;
    let fuel_cons = 0;
    consArr?.map((el) => {
      const { fuel_type, fuel_code } = el;
      if (fuel_type == fuelType) {
        cp_price = parseFloat(el.cp_price);
        fuel_cons = passageType === "1" ? el?.ballast_value : el.laden_value;
      }
    });
    cp_price = isNaN(cp_price) ? 0 : parseFloat(cp_price);
    fuel_cons = isNaN(fuel_cons) || !fuel_cons ? 0 : parseFloat(fuel_cons);
    return { cp_price, fuel_cons };
  };

  EcaSeaconsCalculation = (formdata) => {
    let ecaSeaCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };

    let fuelType = formdata?.["eca_fuel_grade"] ?? "7";

    let ecafuelConsPrice = 0;

    formdata?.portitinerary?.map((el) => {
      const { eca_days, passage } = el;
      const { cp_price, fuel_cons } = this.findCpPassage(
        fuelType,
        passage,
        formdata["."]
      );

      ecafuelConsPrice += cp_price * eca_days * fuel_cons;
    });

    switch (fuelType) {
      case "3": // IFO
        ecaSeaCons["ifo"] = ecafuelConsPrice;
        break;

      case "4": // MGO
        ecaSeaCons["mgo"] = ecafuelConsPrice;
        break;

      case "5": //Vlsfo
        ecaSeaCons["vlsfo"] = ecafuelConsPrice;
        break;

      case "7": // lsmgo
        ecaSeaCons["lsmgo"] = ecafuelConsPrice;
        break;

      case "10": // ulsfo
        ecaSeaCons["ulsfo"] = ecafuelConsPrice;
        break;
      case "11": // HFO
        ecaSeaCons["hfo"] = ecafuelConsPrice;
        break;
    }

    return ecaSeaCons;
  };

  nonEcaSeaconsCalculation = (formdata) => {
    let nonEcaSeaCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };

    let nonifo;
    let nonmgo;
    let nonlsmgo;
    let nonvlsfo;
    let nonulsfo;

    let ecafuelType = formdata?.["eca_fuel_grade"];
    formdata?.portitinerary?.map((el) => {
      const { eca_days, passage, tsd } = el;
      let nonEcadays = tsd - eca_days;
      nonifo = this.findCpPassage(3, passage, formdata["."]);
      nonmgo = this.findCpPassage(4, passage, formdata["."]);
      nonlsmgo = this.findCpPassage(7, passage, formdata["."]);
      nonvlsfo = this.findCpPassage(5, passage, formdata["."]);
      nonulsfo = this.findCpPassage(10, passage, formdata["."]);

      if (ecafuelType == 7) {
        // LSMGO
        if (nonvlsfo.fuel_cons > 0 && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (!nonvlsfo.fuel_cons && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonifo;
          nonEcaSeaCons["ifo"] += parseFloat(fuel_cons * nonEcadays * cp_price);
        } else if (nonvlsfo.fuel_cons > 0 && !nonifo.fuel_cons) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        }
      } else if (ecafuelType == 10) {
        // ULSFO

        if (nonulsfo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonulsfo;
          nonEcaSeaCons["ulsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (nonvlsfo.fuel_cons > 0 && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonifo;

          nonEcaSeaCons["ifo"] += parseFloat(fuel_cons * nonEcadays * cp_price);
        }
      } else {
        if (
          nonifo.fuel_cons > 0 &&
          nonmgo.fuel_cons > 0 &&
          nonlsmgo.fuel_cons > 0 &&
          nonvlsfo.fuel_cons > 0 &&
          nonulsfo.fuel_cons > 0
        ) {
          let { cp_price, fuel_cons } = nonvlsfo;

          nonEcaSeaCons["vlsfo"] += parseFloat(fuel_cons * tsd * cp_price);
        } else {
          nonEcaSeaCons["ifo"] += nonifo.fuel_cons
            ? parseFloat(nonifo.fuel_cons * tsd * nonifo.cp_price)
            : 0;
          nonEcaSeaCons["lsmgo"] += nonlsmgo.fuel_cons
            ? parseFloat(nonlsmgo.fuel_cons * tsd * nonlsmgo.cp_price)
            : 0;
          nonEcaSeaCons["mgo"] += nonmgo.fuel_cons
            ? parseFloat(nonmgo.fuel_cons * tsd * nonmgo.cp_price)
            : 0;
          nonEcaSeaCons["vlsfo"] += nonvlsfo.fuel_cons
            ? parseFloat(nonvlsfo.fuel_cons * tsd * nonvlsfo.cp_price)
            : 0;
          nonEcaSeaCons["ulsfo"] += nonulsfo.fuel_cons
            ? parseFloat(nonulsfo.fuel_cons * tsd * nonulsfo.cp_price)
            : 0;
        }
      }
    });
    return nonEcaSeaCons;
  };

  nonEcaPortConsCalculation = (formdata) => {
    let nonEcaPortCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };

    formdata?.bunkerdetails?.map((el) => {
      nonEcaPortCons["ifo"] += isNaN(el["pc_ifo"])
        ? 0
        : parseFloat(el["pc_ifo"]);
      nonEcaPortCons["mgo"] += isNaN(el["pc_mgo"])
        ? 0
        : parseFloat(el["pc_mgo"]);
      nonEcaPortCons["lsmgo"] += isNaN(el["pc_lsmgo"])
        ? 0
        : parseFloat(el["pc_lsmgo"]);
      nonEcaPortCons["vlsfo"] += isNaN(el["pc_vlsfo"])
        ? 0
        : parseFloat(el["pc_vlsfo"]);
      nonEcaPortCons["ulsfo"] += isNaN(el["pc_ulsfo"])
        ? 0
        : parseFloat(el["pc_ulsfo"]);
    });

    return nonEcaPortCons;
  };

  totalEcaSecafuelCons = (consObj) => {
    let fuelValue = Object.values(consObj);
    return fuelValue.reduce((ac, el) => ac + el, 0);
  };

  ecaPortConsCalculation = () => {
    let ecaPortCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };

    // till now we dont have any update on this, how to calculate.

    return ecaPortCons;
  };

  dollarFormatedValue = (value) => {
    const { dollarUSLocale } = this.state;
    return isNaN(value)
      ? "0.00"
      : dollarUSLocale.format(parseFloat(value).toFixed(2));
  };

  __pl = () => {
    try {
      let { estimateData, dollarUSLocale, formData, estimateDatavalue } =
        this.state;

      let totalSeaConsumption = 0,
        totalPortConsumption = 0,
        totalArriveConsumption = 0,
        totalDepConsumption = 0,
        totalAdjConsumption = 0;
      let colName = "estimate";
      let totalVoyageDays = formData["total_days"]
        ? isNaN(("" + formData["total_days"]).replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["total_days"]).replaceAll(",", "") * 1
        : 0;
      let tsd = 0,
        tpd = 0,
        pi = 0,
        fr = 0,
        mr = 0,
        grossRevenue = 0,
        netRevenue = 0,
        demmurage_amt = 0,
        desmurage_amt = 0,
        demcommissionamt = 0,
        descommissionamt = 0,
        freightCommission = 0,
        demmurageCommission = 0,
        dispatch = 0,
        GrossExpenses = 0,
        miscExpense = 0,
        break_even_frtrate = 0,
        bunkerExpense = 0,
        tciBunkerAdjustment = 0,
        co2_adjusted_profit = 0,
        co2_adjusted_tce = 0,
        netExpenses = 0,
        profitLoss = 0,
        tceHire = 0,
        cpQty = 0;
      let co2expense = 0;
      if (formData && formData.hasOwnProperty("portitinerary")) {
        let portItinerary = formData["portitinerary"];
        portItinerary?.map((e) => {
          tsd += (e.tsd + "").replaceAll(",", "") * 1;
          tpd += (e.t_port_days + "").replaceAll(",", "") * 1;
        });
        tsd = tsd * 1;
        tpd = tpd * 1;
      }

      totalVoyageDays = tpd + tsd > 0 ? tpd + tsd : totalVoyageDays;

      const { demurrage, despatch } = this.DemurrageDespatchCalculation(
        formData.portitinerary
      );

      let bb = formData["ballast_bonus"]
        ? isNaN(("" + formData["ballast_bonus"]).replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["ballast_bonus"]).replaceAll(",", "") * 1
        : 0;

      let misCost = formData["bb"]
        ? isNaN(("" + formData["bb"]).replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["bb"]).replaceAll(",", "") * 1
        : 0;
      let hire = formData["tci_d_hire"]
        ? (formData["tci_d_hire"] + "").replaceAll(",", "") * 1
        : 0;
      let addPercentage = formData["add_percentage"]
        ? (formData["add_percentage"] + "").replaceAll(",", "") * 1
        : 0;
      let amt_add_percentage = hire * totalVoyageDays * addPercentage * 0.01;
      let broPercentage = formData["bro_percentage"]
        ? (formData["bro_percentage"] + "").replaceAll(",", "") * 1
        : 0;
      let amt_bro_percentage = hire * totalVoyageDays * broPercentage * 0.01;
      let portExpenses = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        seaExpenses = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        arrivalrev = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        deprev = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        arrivaladjuestmentrev = {
          ifo: 0,
          mgo: 0,
          vlsfo: 0,
          ulsfo: 0,
          lsmgo: 0,
        };

      if (formData && formData.hasOwnProperty("bunkerdetails")) {
        let bunkerDetails = formData["bunkerdetails"];
        let i = 0;
        bunkerDetails?.map((e, i, { length }) => {
          seaExpenses["ifo"] += e["ifo"]
            ? isNaN(("" + e["ifo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["ifo"]).replaceAll(",", "") * 1
            : 0;
          seaExpenses["mgo"] += e["mgo"]
            ? isNaN(("" + e["mgo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["mgo"]).replaceAll(",", "") * 1
            : 0;
          seaExpenses["vlsfo"] += e["vlsfo"]
            ? isNaN(("" + e["vlsfo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["vlsfo"]).replaceAll(",", "") * 1
            : 0;
          seaExpenses["lsmgo"] += e["lsmgo"]
            ? isNaN(("" + e["lsmgo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["lsmgo"]).replaceAll(",", "") * 1
            : 0;
          seaExpenses["ulsfo"] += e["ulsfo"]
            ? isNaN(("" + e["ulsfo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["ulsfo"]).replaceAll(",", "") * 1
            : 0;

          portExpenses["ifo"] += e["pc_ifo"]
            ? isNaN(("" + e["pc_ifo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["pc_ifo"]).replaceAll(",", "") * 1
            : 0;
          portExpenses["mgo"] += e["pc_mgo"]
            ? isNaN(("" + e["pc_mgo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["pc_mgo"]).replaceAll(",", "") * 1
            : 0;
          portExpenses["vlsfo"] += e["pc_vlsfo"]
            ? isNaN(("" + e["pc_vlsfo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["pc_vlsfo"]).replaceAll(",", "") * 1
            : 0;
          portExpenses["lsmgo"] += e["pc_lsmgo"]
            ? isNaN(("" + e["pc_lsmgo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["pc_lsmgo"]).replaceAll(",", "") * 1
            : 0;
          portExpenses["ulsfo"] += e["pc_ulsfo"]
            ? isNaN(("" + e["pc_ulsfo"]).replaceAll(",", "") * 1)
              ? 0
              : ("" + e["pc_ulsfo"]).replaceAll(",", "") * 1
            : 0;

          if (i == 0) {
            arrivalrev["ifo"] += e["arob_ifo"]
              ? isNaN(("" + e["arob_ifo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_ifo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["mgo"] += e["arob_mgo"]
              ? isNaN(("" + e["arob_mgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_mgo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["vlsfo"] += e["arob_vlsfo"]
              ? isNaN(("" + e["arob_vlsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_vlsfo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["lsmgo"] += e["arob_lsmgo"]
              ? isNaN(("" + e["arob_lsmgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_lsmgo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["ulsfo"] += e["arob_ulsfo"]
              ? isNaN(("" + e["arob_ulsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_ulsfo"]).replaceAll(",", "") * 1
              : 0;
          }

          //last element
          if (i + 1 === length) {
            deprev["ifo"] += e["dr_ifo"]
              ? isNaN(("" + e["dr_ifo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_ifo"]).replaceAll(",", "") * 1
              : 0;
            deprev["mgo"] += e["dr_mgo"]
              ? isNaN(("" + e["dr_mgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_mgo"]).replaceAll(",", "") * 1
              : 0;
            deprev["vlsfo"] += e["dr_vlsfo"]
              ? isNaN(("" + e["dr_vlsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_vlsfo"]).replaceAll(",", "") * 1
              : 0;
            deprev["lsmgo"] += e["dr_lsmgo"]
              ? isNaN(("" + e["dr_lsmgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_lsmgo"]).replaceAll(",", "") * 1
              : 0;
            deprev["ulsfo"] += e["dr_ulsfo"]
              ? isNaN(("" + e["dr_ulsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_ulsfo"]).replaceAll(",", "") * 1
              : 0;
          }
        });
      }

      if (formData && formData.hasOwnProperty(".")) {
        let cpData = formData["."];
        let price_cp_eco =
          formData.hasOwnProperty("price_cp_eco") && formData["price_cp_eco"]
            ? 1
            : 0; // 1 is P$ else CP$
        cpData?.map((e) => {
          let _price = 0;
          let _pprice = 0;
          if (
            e["cp_price"] &&
            !isNaN(("" + e["cp_price"]).replaceAll(",", "") * 1)
          ) {
            _price = ("" + e["cp_price"]).replaceAll(",", "") * 1;
          }
          if (
            e["purchase_price"] &&
            !isNaN(("" + e["purchase_price"]).replaceAll(",", "") * 1)
          ) {
            _pprice = ("" + e["purchase_price"]).replaceAll(",", "") * 1;
          }

          switch (e.fuel_code) {
            case "IFO":
              seaExpenses["ifo"] =
                price_cp_eco == 1
                  ? seaExpenses["ifo"] * _pprice
                  : seaExpenses["ifo"] * _price;

              portExpenses["ifo"] =
                price_cp_eco == 1
                  ? portExpenses["ifo"] * _pprice
                  : portExpenses["ifo"] * _price;

              arrivalrev["ifo"] = arrivalrev["ifo"] * _price;
              deprev["ifo"] = deprev["ifo"] * _pprice;
              arrivaladjuestmentrev["ifo"] = deprev["ifo"] - arrivalrev["ifo"];
              break;

            case "MGO":
              seaExpenses["mgo"] =
                price_cp_eco == 1
                  ? seaExpenses["mgo"] * _pprice
                  : seaExpenses["mgo"] * _price;
              portExpenses["mgo"] =
                price_cp_eco == 1
                  ? portExpenses["mgo"] * _pprice
                  : portExpenses["mgo"] * _price;
              arrivalrev["mgo"] = arrivalrev["mgo"] * _price;
              deprev["mgo"] = deprev["mgo"] * _pprice;
              arrivaladjuestmentrev["mgo"] = deprev["mgo"] - arrivalrev["mgo"];
              break;

            case "VLSFO":
              seaExpenses["vlsfo"] =
                price_cp_eco == 1
                  ? seaExpenses["vlsfo"] * _pprice
                  : seaExpenses["vlsfo"] * _price;
              portExpenses["vlsfo"] =
                price_cp_eco == 1
                  ? portExpenses["vlsfo"] * _pprice
                  : portExpenses["vlsfo"] * _price;
              arrivalrev["vlsfo"] = arrivalrev["vlsfo"] * _price;
              deprev["vlsfo"] = deprev["vlsfo"] * _pprice;
              arrivaladjuestmentrev["vlsfo"] =
                deprev["vlsfo"] - arrivalrev["vlsfo"];
              break;

            case "LSMGO":
              seaExpenses["lsmgo"] =
                price_cp_eco == 1
                  ? seaExpenses["lsmgo"] * _pprice
                  : seaExpenses["lsmgo"] * _price;
              portExpenses["lsmgo"] =
                price_cp_eco == 1
                  ? portExpenses["lsmgo"] * _pprice
                  : portExpenses["lsmgo"] * _price;
              arrivalrev["lsmgo"] = arrivalrev["lsmgo"] * _price;
              deprev["lsmgo"] = deprev["lsmgo"] * _pprice;
              arrivaladjuestmentrev["lsmgo"] =
                deprev["lsmgo"] - arrivalrev["lsmgo"];
              break;

            case "ULSFO":
              seaExpenses["ulsfo"] =
                price_cp_eco == 1
                  ? (isNaN(seaExpenses["ulsfo"]) ? 0 : seaExpenses["ulsfo"]) *
                    _pprice
                  : (isNaN(seaExpenses["ulsfo"]) ? 0 : seaExpenses["ulsfo"]) *
                    _price;
              portExpenses["ulsfo"] =
                price_cp_eco == 1
                  ? (isNaN(portExpenses["ulsfo"]) ? 0 : portExpenses["ulsfo"]) *
                    _price
                  : (isNaN(portExpenses["ulsfo"]) ? 0 : portExpenses["ulsfo"]) *
                    _price;
              arrivalrev["ulsfo"] =
                (isNaN(arrivalrev["ulsfo"]) ? 0 : arrivalrev["ulsfo"]) * _price;
              deprev["ulsfo"] =
                (isNaN(deprev["ulsfo"]) ? 0 : deprev["ulsfo"]) * _pprice;
              arrivaladjuestmentrev["ulsfo"] =
                deprev["ulsfo"] - arrivalrev["ulsfo"];
              break;
          }
        });
      }

      Object.keys(seaExpenses)?.map(
        (e) => (totalSeaConsumption += seaExpenses[e])
      );
      Object.keys(portExpenses)?.map(
        (e) => (totalPortConsumption += portExpenses[e])
      );

      Object.keys(arrivalrev)?.map(
        (e) => (totalArriveConsumption += arrivalrev[e])
      );
      Object.keys(deprev)?.map((e) => (totalDepConsumption += deprev[e]));
      Object.keys(arrivaladjuestmentrev)?.map(
        (e) => (totalAdjConsumption += arrivaladjuestmentrev[e])
      );

      if (formData && formData.hasOwnProperty("portitinerary")) {
        let portitinerary = formData["portitinerary"];
        portitinerary?.map(
          (e) =>
            (pi += isNaN(("" + e.p_exp).replaceAll(",", "") * 1)
              ? 0
              : ("" + e.p_exp).replaceAll(",", "") * 1)
        );
      }

      let avgfreightRate = 0,
        avgfreightRateOpt = 0,
        frtAvg = 0,
        cpQtyOpt = 0;
      if (formData && formData.hasOwnProperty("cargos")) {
        let cargos = formData["cargos"];
        cargos?.map((e, i) => {
          let frtRate = 0,
            commission = 0,
            frtAmount = 0;

          let frt_rate =
            e.frt_type == "259"
              ? parseFloat(e.world_scale)
              : e.freight_rate || e.frat_rate || e.f_rate || 0;
          let lumsum_amt = e.lumsum || 0;
          let frt_type_opt = e.frt_type || e.frt_type || e.frt_type || 0;
          let demmurage = e.dem_rate_pd || e.dem_rate_pd || e.dem_rate_pd || 0;
          let desmurage = e.des_rate_pd || e.des_rate_pd || e.des_rate_pd || 0;

          let cp_qty = e.cp_qty || e.quantity || 0;
          let b_commission = e.b_commission || e.commission || 0;

          let opt_per =
            parseFloat(e.option_percentage) ||
            parseFloat(e.opt_percentage) ||
            0;
          mr += e.extra_rev ? (e.extra_rev + "").replaceAll(",", "") * 1 : 0;
          cpQty += cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0;
          cpQtyOpt += cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0;

          if (opt_per !== 0) {
            let _cpq = cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0;
            let _fr = frt_rate ? (frt_rate + "").replaceAll(",", "") * 1 : 0;
            opt_per = opt_per ? (opt_per + "").replaceAll(",", "") : 0;
            let cpOptQty = _cpq + (_cpq * opt_per) / 100;
            cpQtyOpt = cpQtyOpt - _cpq + cpOptQty; //way to use opt% of cargo qty : refactor
            frtAmount = cpOptQty * _fr;
            if (frt_type_opt == "104") {
              frtAmount = lumsum_amt
                ? (lumsum_amt + "").replaceAll(",", "") * 1
                : 0;
            }
            commission =
              (frtAmount *
                (b_commission
                  ? (b_commission + "").replaceAll(",", "") * 1
                  : 0)) /
              100;
            frtRate =
              (cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0) *
              (frt_rate ? (frt_rate + "").replaceAll(",", "") * 1 : 0);
          } else {
            frtAmount =
              (cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0) *
              (frt_rate ? (frt_rate + "").replaceAll(",", "") * 1 : 0);
            if (frt_type_opt == "104") {
              frtAmount = lumsum_amt
                ? (lumsum_amt + "").replaceAll(",", "") * 1
                : 0;
            }
            commission =
              (frtAmount *
                (b_commission
                  ? (b_commission + "").replaceAll(",", "") * 1
                  : 0)) /
              100;
            frtRate = frtAmount;
          }

          fr += frtAmount;
          freightCommission += commission;
          frtAvg += frtRate;

          demmurage_amt += demmurage * 1;
          demcommissionamt +=
            (demurrage *
              (b_commission
                ? (b_commission + "").replaceAll(",", "") * 1
                : 0)) /
            100;

          desmurage_amt += desmurage * 1;
          //   descommissionamt += (desmurage * (b_commission ? (b_commission + "").replaceAll(",", "") * 1 : 0)) / 100;
        });
        avgfreightRateOpt = fr / cpQtyOpt;
        avgfreightRate = fr / cpQty;
      }

      // Gross Revenue =total freight + miscreve+despatch-demurrage;(changed at 14 dec 2023, as per jeetendra mishra)
      grossRevenue = fr + mr + despatch - demurrage * -1; // we dont know days of demurrage
      netRevenue = grossRevenue - freightCommission - demcommissionamt * -1; // dispatch is revenue

      // expense calculation
      let vesselHire = totalVoyageDays * hire;
      miscExpense = misCost + bb;

      co2expense = formData?.["-----"]?.["inc_pnl"]
        ? formData?.["-----"]?.["ttl_co2_cost"]
        : 0; // co2expense is emission expense calculated from euets tab

      let ecaSeacons = this.EcaSeaconsCalculation(formData);
      let nonEcaSeacons = this.nonEcaSeaconsCalculation(formData);
      let nonecaPortcons = portExpenses;
      let ecaPortCons = this.ecaPortConsCalculation(formData);

      let totalecaSeacons = this.totalEcaSecafuelCons(ecaSeacons);
      let totalnonEcaSeacons = this.totalEcaSecafuelCons(nonEcaSeacons);
      let totalnonecaPortcons = this.totalEcaSecafuelCons(nonecaPortcons);
      let totalecaPortCons = this.totalEcaSecafuelCons(ecaPortCons);

      bunkerExpense =
        this.checkNaN(totalecaSeacons) +
        this.checkNaN(totalnonEcaSeacons) +
        this.checkNaN(totalnonecaPortcons) +
        this.checkNaN(totalecaPortCons);

      GrossExpenses =
        this.checkNaN(vesselHire) +
        this.checkNaN(miscExpense) +
        this.checkNaN(pi) +
        this.checkNaN(bunkerExpense) +
        this.checkNaN(co2expense);

      // netexpense=grossexpense-(amt_add_percentage + amt_bro_percentage)

      netExpenses = GrossExpenses - (amt_add_percentage + amt_bro_percentage);

      estimateData[0][colName] = isNaN(netRevenue)
        ? 0
        : dollarUSLocale.format(netRevenue.toFixed(2));

      estimateData[0]["children"][0][colName] = isNaN(fr)
        ? 0
        : dollarUSLocale.format(fr.toFixed(2));

      estimateData[0]["children"][1][colName] = isNaN(freightCommission)
        ? 0
        : dollarUSLocale.format(freightCommission.toFixed(2));

      estimateData[0]["children"][2][colName] = isNaN(mr)
        ? 0
        : dollarUSLocale.format(mr.toFixed(2));

      estimateData[0]["children"][3][colName] = isNaN(demurrage)
        ? 0
        : dollarUSLocale.format((demurrage * -1).toFixed(2));

      estimateData[0]["children"][4][colName] = isNaN(demcommissionamt)
        ? 0
        : dollarUSLocale.format((demcommissionamt * -1).toFixed(2));

      estimateData[0]["children"][5][colName] = isNaN(despatch)
        ? 0
        : dollarUSLocale.format(despatch.toFixed(2));

      estimateData[0]["children"][6][colName] = isNaN(grossRevenue)
        ? 0
        : dollarUSLocale.format(grossRevenue.toFixed(2));

      estimateData[0]["children"][7][colName] = isNaN(netRevenue)
        ? 0
        : dollarUSLocale.format(netRevenue.toFixed(2));

      estimateData[1][colName] = this.dollarFormatedValue(netExpenses);
      estimateData[1]["children"][0][colName] =
        this.dollarFormatedValue(vesselHire);
      estimateData[1]["children"][1][colName] =
        this.dollarFormatedValue(amt_add_percentage);
      estimateData[1]["children"][2][colName] =
        this.dollarFormatedValue(amt_bro_percentage);
      estimateData[1]["children"][3][colName] = this.dollarFormatedValue(pi);
      estimateData[1]["children"][4][colName] =
        this.dollarFormatedValue(co2expense);
      estimateData[1]["children"][5][colName] =
        this.dollarFormatedValue(miscExpense);

      // non ecA SEA CONS

      estimateData[1]["children"][6][colName] =
        this.dollarFormatedValue(bunkerExpense);

      estimateData[1]["children"][6]["children"][0][colName] =
        this.dollarFormatedValue(totalnonEcaSeacons);

      estimateData[1]["children"][6]["children"][0]["children"][0][colName] =
        this.dollarFormatedValue(nonEcaSeacons.ifo);
      estimateData[1]["children"][6]["children"][0]["children"][1][colName] =
        this.dollarFormatedValue(nonEcaSeacons.mgo);
      estimateData[1]["children"][6]["children"][0]["children"][2][colName] =
        this.dollarFormatedValue(nonEcaSeacons.vlsfo);
      estimateData[1]["children"][6]["children"][0]["children"][3][colName] =
        this.dollarFormatedValue(nonEcaSeacons.lsmgo);
      estimateData[1]["children"][6]["children"][0]["children"][4][colName] =
        this.dollarFormatedValue(nonEcaSeacons.ulsfo);

      // sea cons eca

      estimateData[1]["children"][6]["children"][1][colName] =
        this.dollarFormatedValue(totalecaSeacons);

      estimateData[1]["children"][6]["children"][1]["children"][0][colName] =
        this.dollarFormatedValue(ecaSeacons.ifo);
      estimateData[1]["children"][6]["children"][1]["children"][1][colName] =
        this.dollarFormatedValue(ecaSeacons.mgo);
      estimateData[1]["children"][6]["children"][1]["children"][2][colName] =
        this.dollarFormatedValue(ecaSeacons.vlsfo);
      estimateData[1]["children"][6]["children"][1]["children"][3][colName] =
        this.dollarFormatedValue(ecaSeacons.lsmgo);
      estimateData[1]["children"][6]["children"][1]["children"][4][colName] =
        this.dollarFormatedValue(ecaSeacons.ulsfo);

      // port cons non eca

      estimateData[1]["children"][6]["children"][2][colName] =
        this.dollarFormatedValue(totalnonecaPortcons);

      estimateData[1]["children"][6]["children"][2]["children"][0][colName] =
        this.dollarFormatedValue(nonecaPortcons.ifo);
      estimateData[1]["children"][6]["children"][2]["children"][1][colName] =
        this.dollarFormatedValue(nonecaPortcons.mgo);
      estimateData[1]["children"][6]["children"][2]["children"][2][colName] =
        this.dollarFormatedValue(nonecaPortcons.vlsfo);
      estimateData[1]["children"][6]["children"][2]["children"][3][colName] =
        this.dollarFormatedValue(nonecaPortcons.lsmgo);
      estimateData[1]["children"][6]["children"][2]["children"][4][colName] =
        this.dollarFormatedValue(nonecaPortcons.ulsfo);

      estimateData[1]["children"][6]["children"][3][colName] = "0.00";
      estimateData[1]["children"][6]["children"][3]["children"][0][colName] =
        this.dollarFormatedValue(tciBunkerAdjustment);

      // port cons eca

      // estimateData[1]["children"][6]["children"][3][colName] =
      //   this.dollarFormatedValue(totalecaPortCons);

      // estimateData[1]["children"][6]["children"][3]["children"][0][colName] =
      //   this.dollarFormatedValue(ecaPortCons.ifo);
      // estimateData[1]["children"][6]["children"][3]["children"][1][colName] =
      //   this.dollarFormatedValue(ecaPortCons.mgo);
      // estimateData[1]["children"][6]["children"][3]["children"][2][colName] =
      //   this.dollarFormatedValue(ecaPortCons.vlsfo);
      // estimateData[1]["children"][6]["children"][3]["children"][3][colName] =
      //   this.dollarFormatedValue(ecaPortCons.lsmgo);
      // estimateData[1]["children"][6]["children"][3]["children"][4][colName] =
      //   this.dollarFormatedValue(ecaPortCons.ulsfo);

      estimateData[1]["children"][7][colName] =
        this.dollarFormatedValue(GrossExpenses);
      estimateData[1]["children"][8][colName] =
        this.dollarFormatedValue(netExpenses);

      profitLoss = netRevenue - netExpenses;
      co2_adjusted_profit = profitLoss - co2expense;
      co2_adjusted_tce = co2_adjusted_profit / totalVoyageDays;
      break_even_frtrate = cpQtyOpt > 0 ? netExpenses / cpQtyOpt : 0;
      // Voyage Result Calculations

      tceHire = (
        (netRevenue -
          (GrossExpenses -
            (totalVoyageDays * hire + bb + misCost - amt_add_percentage))) /
        totalVoyageDays
      ).toFixed(2);
      estimateData[2][colName] = this.dollarFormatedValue(profitLoss);
      estimateData[2]["children"][0][colName] =
        this.dollarFormatedValue(profitLoss);
      estimateData[2]["children"][1][colName] =
        totalVoyageDays > 0
          ? this.dollarFormatedValue(profitLoss / (totalVoyageDays - 0))
          : "0.00";
      estimateData[2]["children"][2][colName] =
        totalVoyageDays > 0 ? this.dollarFormatedValue(tceHire) : "0.00";
      estimateData[2]["children"][3][colName] = addPercentage
        ? this.dollarFormatedValue(tceHire / (1 - addPercentage * 0.01))
        : "0.00";
      estimateData[2]["children"][4][colName] =
        this.dollarFormatedValue(break_even_frtrate);
      // ((0 + netExpenses) / cpQtyOpt).toFixed(2) >= 0
      //   ? dollarUSLocale.format(((0 + netExpenses) / cpQtyOpt).toFixed(2))
      //   : "(" +
      //     dollarUSLocale.format(
      //       isNaN(((0 + netExpenses) / cpQtyOpt) * 1)
      //         ? 0
      //         : ((netExpenses / cpQtyOpt) * 1).toFixed(2)
      //     ) +
      //     ")"; // Profit is 0 since we are doing Breakeven

      estimateData[2]["children"][5][colName] =
        this.dollarFormatedValue(break_even_frtrate);
      // ((0 + netExpenses) / cpQtyOpt).toFixed(2) >= 0
      //   ? dollarUSLocale.format(((0 + netExpenses) / cpQtyOpt).toFixed(2))
      //   : "(" +
      //     dollarUSLocale.format(
      //       isNaN(((0 + netExpenses) / cpQtyOpt) * 1)
      //         ? 0
      //         : ((netExpenses / cpQtyOpt) * 1).toFixed(2)
      //     ) +
      //     ")"; // Profit is 0 since we are doing Breakeven

      estimateData[2]["children"][6][colName] =
        this.dollarFormatedValue(co2expense);
      estimateData[2]["children"][7][colName] =
        this.dollarFormatedValue(co2_adjusted_profit);
      estimateData[2]["children"][8][colName] = isFinite(co2_adjusted_tce)
        ? this.dollarFormatedValue(co2_adjusted_tce)
        : "0.00";
      estimateData[2]["children"][9][colName] = this.dollarFormatedValue(tsd);
      estimateData[2]["children"][10][colName] = this.dollarFormatedValue(tpd);
      estimateData[2]["children"][11][colName] =
        this.dollarFormatedValue(totalVoyageDays);

      return estimateData;
    } catch (err) {
      console.log("err", err);
    }
  };

  componentDidMount() {
    this.setState(
      {
        ...this.state,
        showPL: false,
        estimateData: this.__pl(),
      },
      () => this.setState({ ...this.state, showPL: true })
    );
  }

  render() {
    const { estimateData, showPL, viewTabs } = this.state;

    return (
      <>
        <div>
          <ToolbarUI routeUrl={"pl-main"} callback={(e) => this.callback(e)} />
        </div>

        {showPL === true ? (
          <Tabs defaultActiveKey="1">
            {viewTabs?.map((e) => {
              if (e === "Actual &  Operation View") {
                return (
                  <TabPane tab={e} key="ao2">
                    <Table
                      className="pl-summary-list-view"
                      bordered
                      columns={columns}
                      dataSource={estimateData}
                      pagination={false}
                      rowClassName={(r, i) =>
                        i % 2 === 0
                          ? "table-striped-listing"
                          : "dull-color table-striped-listing"
                      }
                    />
                    <Row gutter={16} className="m-t-18">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FormItem
                          label="Remark"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea
                            placeholder="Remark"
                            autoSize={{ minRows: 6, maxRows: 6 }}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </TabPane>
                );
              } else if (e === "Estimate View") {
                return (
                  <TabPane tab={e} key="ev1">
                    <Table
                      className="pl-summary-list-view"
                      bordered
                      columns={columns2}
                      dataSource={estimateData}
                      pagination={false}
                      rowClassName={(r, i) =>
                        i % 2 === 0
                          ? "table-striped-listing"
                          : "dull-color table-striped-listing"
                      }
                    />
                    <Row gutter={16} className="m-t-18">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FormItem
                          label="Remark"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea
                            placeholder="Remark"
                            autoSize={{ minRows: 6, maxRows: 6 }}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </TabPane>
                );
              } else if (e === "Account View") {
                return (
                  <TabPane tab={e} key="av3">
                    Accounts
                  </TabPane>
                );
              }
            })}
          </Tabs>
        ) : (
          <div className="col col-lg-12">
            <Spin tip="Loading...">
              <Alert message=" " description="Please wait..." type="info" />
            </Spin>
          </div>
        )}
      </>
    );
  }
}

export default PL;
