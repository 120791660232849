import { postAPICall } from "../shared";

export function sendermaillReturn(current_mail) {
  // debugger

  if (current_mail.mailClient === "custom") {
    return current_mail?.From
      ? current_mail?.From[0]
      : current_mail?.From
      ? current_mail.From
      : current_mail?.From;
  } else {
    return current_mail?.Sender
      ? current_mail?.Sender
      : current_mail?.From
      ? current_mail.From
      : current_mail?.from;
  }
}

export function toMailReturn(current_mail) {
  return current_mail?.Recipient
    ? current_mail?.Recipient
    : current_mail?.to
    ? current_mail?.to
    : current_mail?.To;
}

export function parseSender(sender) {
  // debugger
  let name = "";
  let email = "";
  if (sender) {
    // Regular expression to match the name and email pattern
    const match = sender?.match(/(.*?)\s*<(.+?)>/);
    let name = "";
    let email = "";

    if (match) {
      // Extract name and email if pattern is matched
      name = match[1].trim().replace(/['"]/g, ""); // Remove quotes from the name
      email = match[2].trim();
    } else {
      // If no match, check if the sender string is a valid email
      const emailMatch = sender?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if (emailMatch) {
        email = sender.trim();
      }
    }

    // If email is still empty, set name as the sender string without quotes
    if (!email) {
      name = sender.trim().replace(/['"]/g, "");
    }

    return { name, email };
  }
  return { name, email };
}

export const extractEmail = (inputString) => {
  try {
    if (typeof inputString === "string") {
      const regex = /<([^>]+)>/; // Regular expression to match anything inside <...>
      const matches = inputString?.match(regex); // Find matches
      const { email, name } = parseSender(inputString);
      if (email.length > 0) {
        return email;
      }
      if (matches) {
        const email = matches[1]; // Extract the email from the matched string
        return email;
      } else {
        return inputString; // If no match found, return the input string
      }
    } else return inputString;
  } catch (error) {
    console.log(error);
  }
};

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export const getAllLabel = async (email) => {
  try {
    const url = `${process.env.REACT_APP_MT_URL}mail/getLables`;
    if (email) {
      const payload = {
        email: email,
      };
      const response = await postAPICall(url, payload, "post");
      console.log("label response=>", response);
      if (response?.status) {
        const allLabels = response?.labels?.map((el) => ({
          ...el,
          checked: false,
        }));
        return { response, allLabels };
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const fulldate = (mail) => {
  let givenDate = new Date(mail?.Date);

  if (mail?.mailClient === "custom") {
    const inputDate = JSON.stringify(mail?.Date);
    const modifiedDate = inputDate.replace("IST", "UTC+05:30");
    const parsedDate = new Date(modifiedDate);
    givenDate = new Date(parsedDate.toISOString());
  }

  return givenDate
    .toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
    .replace("AM", " AM")
    .replace("PM", " PM");
};
