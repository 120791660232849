import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const RippleComponent = () => {
  const hoverRef = useRef(null);
  const automatticRef = useRef(null);

  useEffect(() => {
    const $ = window.jQuery;

    try {
      $(hoverRef.current).ripples({
        resolution: 1080,
        perturbance: 0.01,
        interactive: true,
      });
    } catch (e) {
      console.error(e);
    }

    const intervalId = setInterval(() => {
      const $el = $(automatticRef.current);
      const x = Math.random() * $el.outerWidth();
      const y = Math.random() * $el.outerHeight();
      const dropRadius = 30;
      const strength = 0.04 + Math.random() * 0.04;

      $el.ripples("drop", x, y, dropRadius, strength);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div style={{overflow:'hidden', position: 'fixed', zIndex: '9', left: 0, top: '0'}}>
        <section
          ref={hoverRef}
          className="hover"
          style={{
            width: "100vw",
            height: "100vh",
            float: "left",
            backgroundSize: "cover",
            backgroundImage: "url(/404BG.jpg)",
          }}
        ></section>
      </div>
      <div className="not-found-page-content">
      <svg width="160" height="124" viewBox="0 0 160 124" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M82.8805 65.2V91.6C90.1588 95.44 101.076 83.6 105.625 77.2L97.285 78L115.48 65.2L113.964 86L111.69 82C107.444 89.04 98.8013 95.6 95.0106 98C90.1585 99.92 82.375 106.267 79.0896 109.2C78.4831 107.28 65.6959 98.2667 59.378 94C56.9519 92.72 50.2803 84.4 47.2477 80.4L44.2152 86V65.2L60.1361 78H54.071L55.5873 80.4C63.4719 90.64 72.0136 92.1333 75.2989 91.6V65.2C75.2989 58.16 69.2338 52.1333 66.2012 50L10.8571 14C8.43101 21.04 12.8789 29.2 15.4061 32.4C10.554 38.16 14.3952 46.5333 16.9223 50C15.7093 60.88 22.9874 68.4 26.7781 70.8C31.0237 89.36 50.786 99.8667 60.1363 102.8C65.5949 104.72 76.0572 111.067 80.6061 114C83.6386 109.52 94 104.667 98.8015 102.8C124.882 95.76 132.918 78.5333 133.676 70.8C143.38 65.68 144.29 53.2 143.532 47.6C148.384 36.72 145.554 32.9333 143.532 32.4C152.023 26 150.102 14.8 148.081 10L92.7363 50C83.6386 54.48 82.3751 62 82.8805 65.2Z" fill="#39B1FF" stroke="#39B1FF"></path></svg>
        <h2>Page Not Found!</h2>
        <p>We can't seem to find the page you are looking for!</p>
        <Link className="not_found_return_link" to="/chartering-dashboard">Return to Dashboard</Link>
      </div>
    </>
  );
};

export default RippleComponent;
