import React, { Component } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import Email from "../../components/Email";
import { Modal, Spin } from "antd";

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    const formReportdata = {};

    this.state = {
      formReportdata: Object.assign(formReportdata, this.props.response || {}),
    };
  }
  render() {
    const { formReportdata } = this.state;

    return (
      <article className="article toolbaruiWrapper">
        <div className="box box-default" id="divToPrint">
          <div className="box-body">
            <div className="invoice-inner-download mt-3">
              <div className="row">
                <div className="col-12 text-center">
                  <span className="title">ABC</span>
                  <p className="sub-title m-0">Meritime Company</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="text-center invoice-top-address">
                  <p>Abc Maritime pvt ltd, sector-63, noida, up-201301</p>
                </div>
              </div>
            </div>

            <div className="row p10">
              <div className="col-md-12">
                <table className="table table-bordered table-invoice-report-colum">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Full Name :</td>
                      <td>{formReportdata.full_name}</td>

                      <td className="font-weight-bold">Short Name :</td>
                      <td>{formReportdata.short_name}</td>

                      <td className="font-weight-bold">Cargo Group :</td>
                      <td>{formReportdata.group}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">API :</td>
                      <td>{formReportdata.c_api}</td>

                      <td className="font-weight-bold">SG :</td>
                      <td>{formReportdata.sg}</td>
                      {/* 
                      <td className="font-weight-bold">Group Description :</td>
                      <td>Value</td> */}
                      <td className="font-weight-bold">Cargo Type :</td>
                      <td>{formReportdata.cargo_type}</td>
                    </tr>

                    <tr>
                      {/* <td className="font-weight-bold">Cargo Type :</td>
                      <td>Value</td> */}
                      <td className="font-weight-bold">Cargo Class :</td>
                      <td>{formReportdata.class}</td>

                      <td className="font-weight-bold">UN Number :</td>
                      <td>{formReportdata.un_number}</td>

                      <td className="font-weight-bold">Class :</td>
                      <td>{formReportdata.class_2}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">IMO Name :</td>
                      <td>{formReportdata.imo_name}</td>

                      <td className="font-weight-bold">Product Code :</td>
                      <td>{formReportdata.product_code}</td>

                      <td className="font-weight-bold">IBC code :</td>
                      <td>{formReportdata.ibc_code}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Default CP Unit :</td>
                      <td>{formReportdata.default_cp_unit}</td>

                      <td className="font-weight-bold">IMSBC code :</td>
                      <td>{formReportdata.imsbc_code}</td>

                      <td className="font-weight-bold">
                        Pre Clearance for US and Canada :
                      </td>
                      <td>{formReportdata.pre_clearance_uscn}</td>
                    </tr>

                    <tr>
                      {/* <td className="font-weight-bold">Bill by :</td>
                      <td>Value</td> */}
                      {/* 
                      <td className="font-weight-bold">Description :</td>
                      <td>Value</td> */}
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Dangerous :</td>
                      <td>{formReportdata.dangerous}</td>

                      {/* <td className="font-weight-bold">Inactive :</td>
                      <td>Value</td> */}

                      <td className="font-weight-bold">
                        Special Handling Required :
                      </td>
                      <td>{formReportdata.special_handling}</td>
                      <td className="font-weight-bold"></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

class AddCargoReport extends Component {
  constructor() {
    super();
    this.state = {
      name: "Printer",
      pdfData: "",
      userInput: "",
      emailModal: false,
      loading: false,
      mailTitlePayload: {},
    };
  }

  printReceipt() {
    window.print();
  }

  printDocument() {
    htmlToImage
      .toPng(document.getElementById("divToPrint"), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "my-image-name.jpeg";
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("Add_Cargo_Report.pdf");
      });
  }

  sendEmailReportModal = async () => {
    try {
      this.setState({ loading: true });
      const quotes = document.getElementById("divToPrint");
      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });
      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      const blob = doc.output("blob");
      this.setState({
        loading: false,
        pdfData: blob,
        emailModal: true,
      });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
      // Handle errors here
    }
  };

  render() {
    return (
      <div className="body-wrapper modalWrapper">
        <article className="article toolbaruiWrapper">
          <div className="box box-default">
            <div className="box-body">
              <div className="toolbar-ui-wrapper">
                <div className="leftsection"></div>
                <div className="rightsection">
                  <span className="wrap-bar-menu">
                    <ul className="wrap-bar-ul">
                      <li onClick={this.sendEmailReportModal}>Send Email</li>
                      <li onClick={this.printDocument}>Download</li>

                      <li>
                        <ReactToPrint
                          trigger={() => (
                            <span className="text-bt">
                              <PrinterOutlined /> Print
                            </span>
                          )}
                          content={() => this.componentRef}
                        />
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <ComponentToPrint
                response={this.props.response}
                ref={(el) => (this.componentRef = el)}
              />
            </div>
          </div>
        </article>

        {this.state.emailModal && (
          <Modal
            title="New Message"
            visible={this.state.emailModal}
            onOk={() => {
              // Instead of calling this.state.emailModal, set it to false
              this.setState({ emailModal: false });
            }}
            onCancel={() => {
              // Instead of calling this.state.emailModal, set it to false
              this.setState({ emailModal: false });
            }}
            footer={null}
          >
            {this.state.pdfData && (
              <Email
                handleClose={() => {
                  this.setState({ emailModal: false });
                }}
                attachmentFile={this.state.pdfData}
                title={window.corrector(`Cargo_Report`)}
              />
            )}
          </Modal>
        )}

        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    );
  }
}

export default AddCargoReport;
