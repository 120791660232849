export const calculateTotalSummary = (fromarr, toarr, fromgroup, formdata) => {
  let _totaldata = {};
  for (let i = 0; i < toarr.length; i++) {
    _totaldata[toarr[i]] = 0;
  }
  formdata?.[fromgroup]?.map((el, index) => {
    fromarr?.map((ele, ind) => {
      _totaldata[toarr[ind]] += Number(el[ele]);
    });
  });
  for (let key in _totaldata) {
    _totaldata[key] = isNaN(_totaldata[key])
      ? 0
      : parseFloat(_totaldata[key]).toFixed(2);
  }
  return _totaldata;
};

export const calculateTotalaverage = (fromfields, fromgroup, formdata) => {
  let avValue = 0;
  let formrow = 0;
  formdata?.[fromgroup]?.map((el, ind) => {
    avValue += el[fromfields] ? parseFloat(el[fromfields]) : 0;
    formrow++;
  });

  return parseFloat(avValue / formrow).toFixed(2);
};
