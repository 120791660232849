import React, { useEffect, useState } from "react";

import { FilterOutlined, SearchOutlined, UnorderedListOutlined } from "@ant-design/icons";

const FilterVessel = ({
  onSearchLiveData,
  onSearchDbData,
  onFilterData,
  children,
  hideIcons,
  right
}) => {
  const [isSticky, setSticky] = useState(false);
  useEffect(
    () => {
      window.addEventListener("scroll", handleScroll, false);

      return () => {
        window.removeEventListener("scroll", handleScroll, false);
      };
    },
    [isSticky]
  );

  const handleScroll = () => {
    setSticky(window.scrollY > 10);
  };

  const top = isSticky ? 0 : 60;
 
  return (
    <div
      className="vessel-filter"
      style={{ height: `calc(100vh - ${top}px)`, top, right }}
    >
      {/* Filter views */}
      {children}

      <ul className="vessel-filter-actions">
        {typeof onSearchDbData === "function" && onSearchDbData ? (
          <li onClick={onSearchDbData}>
          {hideIcons ? null :  <UnorderedListOutlined />}
          </li>
        ) : (
          undefined
        )}

        {typeof onSearchLiveData === "function" && onSearchLiveData ? (
          <li onClick={onSearchLiveData}>
           
           {hideIcons ? null :  <SearchOutlined />}
          </li>
        ) : (
          undefined
        )}

        {typeof onFilterData === "function" && onFilterData ? (
          <li onClick={onFilterData}>
          
          {hideIcons ? null :  <FilterOutlined />}
          </li>
        ) : (
          undefined
        )}
      </ul>
    </div>
  );
};

export default FilterVessel;
