import MailLayout from "./MailLayout";

const ArchivedList = () => {
    return(
        <>
        <MailLayout archived={true} />
        </>
    )
}

export default ArchivedList;