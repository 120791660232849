import React, { Component } from "react";
import { Spin, Row, Col, Divider, Table } from "antd";
import axios from "axios";
import {FireOutlined,CaretDownOutlined,CaretUpOutlined} from '@ant-design/icons';
import mapboxgl, { MapWheelEvent } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import URL_WITH_VERSION, {IMAGE_PATH} from "../../shared";
const REACT_APP_MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line
mapboxgl.accessToken = REACT_APP_MAPBOX_TOKEN;

class ShipDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finalPortFrom: "",
      finalPortTo: "",
      coordinates: [],
      lng: 37.74872,
      lat: -0.74767,
      zoom: 1,
      shipData: null,
      loading: false,
      weatherData: {},
      mapSettings: {
        center: { lat: 20.5937, lng: 78.9629 },
        zoom: 1,
      },
      datas: [],
      poly_lat_lng: [],
      columns: [
        {
          title: "Port",
          key: "port",
          dataIndex: "name",
        },
        {
          title: "Fuel Type",
          dataIndex: "fuel",
          key: "fuel",
        },
        {
          title: "Month",
          dataIndex: "validYearMonth",
          key: "validYearMonth",
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
        },
      ],
      columnsSpot: [
        {
          title: "Port",
          key: "port", //dataIndex: 'port',
          width: "15%",
          render: (record) => {
            let data = record && record.length > 0 ? record[0] : null;
            return (
              <span>{data && data != null && data.port ? data.port : "-"}</span>
            );
          },
        },
        {
          title: "Region",
          key: "region",
          width: "15%",
          render: (record) => {
            let data = record && record.length > 0 ? record[0] : null;
            return (
              <span>
                {data && data != null && data.region ? data.region : "-"}
              </span>
            );
          },
        },
        {
          title: "IFO",
          key: "IFO",
          width: "13%",
          align: "right",
          render: (record) => {
            let data = record && record.length > 0 ? record[0] : null;
            return (
              <span>
                {data && data != null && data.IFO && data.IFO.spotPrice
                  ? data.IFO.spotPrice
                  : "-"}
                &nbsp;
                {data &&
                data != null &&
                data.IFO &&
                data.IFO.spotPrice &&
                data.IFO.spotPrice > 0 ? (
                  <CaretUpOutlined
                    style={{
                      color:
                        data &&
                        data != null &&
                        data.IFO &&
                        data.IFO.spotPrice &&
                        data.IFO.spotPrice > 0
                          ? "red"
                          : data &&
                            data != null &&
                            data.IFO &&
                            data.IFO.spotPrice &&
                            data.IFO.spotPrice < 0
                          ? "green"
                          : undefined,
                    }}
                  />
                ) : data &&
                  data != null &&
                  data.IFO &&
                  data.IFO.spotPrice &&
                  data.IFO.spotPrice < 0 ? (
                  <CaretDownOutlined
                    style={{
                      color:
                        data &&
                        data != null &&
                        data.IFO &&
                        data.IFO.spotPrice &&
                        data.IFO.spotPrice > 0
                          ? "red"
                          : data &&
                            data != null &&
                            data.IFO &&
                            data.IFO.spotPrice &&
                            data.IFO.spotPrice < 0
                          ? "green"
                          : undefined,
                    }}
                  />
                ) : undefined}
              </span>
            );
          },
        },
        {
          title: "MGO",
          key: "MGO",
          width: "13%",
          align: "right",
          render: (record) => {
            let data = record && record.length > 0 ? record[0] : null;
            return (
              <span>
                {data && data != null && data.MGO && data.MGO.spotPrice
                  ? data.MGO.spotPrice
                  : "-"}
                &nbsp;
                {data &&
                data != null &&
                data.MGO &&
                data.MGO.spotPrice &&
                data.MGO.spotPrice > 0 ? (
                  <CaretUpOutlined
                    style={{
                      color:
                        data &&
                        data != null &&
                        data.MGO &&
                        data.MGO.spotPrice &&
                        data.MGO.spotPrice > 0
                          ? "red"
                          : data &&
                            data != null &&
                            data.MGO &&
                            data.MGO.spotPrice &&
                            data.MGO.spotPrice < 0
                          ? "green"
                          : undefined,
                    }}
                  />
                ) : data &&
                  data != null &&
                  data.MGO &&
                  data.MGO.spotPrice &&
                  data.MGO.spotPrice < 0 ? (
                  <CaretDownOutlined
                    style={{
                      color:
                        data &&
                        data != null &&
                        data.MGO &&
                        data.MGO.spotPrice &&
                        data.MGO.spotPrice > 0
                          ? "red"
                          : data &&
                            data != null &&
                            data.MGO &&
                            data.MGO.spotPrice &&
                            data.MGO.spotPrice < 0
                          ? "green"
                          : undefined,
                    }}
                  />
                ) : undefined}
              </span>
            );
          },
        },
        {
          title: "VLSFO",
          key: "VLSFO",
          width: "13%",
          align: "right",
          render: (record) => {
            let data = record && record.length > 0 ? record[0] : null;
            return (
              <span>
                {data && data != null && data.VLSFO && data.VLSFO.spotPrice
                  ? data.VLSFO.spotPrice
                  : "-"}
                &nbsp;{" "}
                {data &&
                data != null &&
                data.VLSFO &&
                data.VLSFO.spotPrice &&
                data.VLSFO.spotPrice > 0 ? (
                  <CaretUpOutlined
                    style={{
                      color:
                        data &&
                        data != null &&
                        data.VLSFO &&
                        data.VLSFO.spotPrice &&
                        data.VLSFO.spotPrice > 0
                          ? "red"
                          : data &&
                            data != null &&
                            data.VLSFO &&
                            data.VLSFO.spotPrice &&
                            data.VLSFO.spotPrice < 0
                          ? "green"
                          : undefined,
                    }}
                  />
                ) : data &&
                  data != null &&
                  data.VLSFO &&
                  data.VLSFO.spotPrice &&
                  data.VLSFO.spotPrice < 0 ? (
                  <CaretDownOutlined
                    style={{
                      color:
                        data &&
                        data != null &&
                        data.VLSFO &&
                        data.VLSFO.spotPrice &&
                        data.VLSFO.spotPrice > 0
                          ? "red"
                          : data &&
                            data != null &&
                            data.VLSFO &&
                            data.VLSFO.spotPrice &&
                            data.VLSFO.spotPrice < 0
                          ? "green"
                          : undefined,
                    }}
                  />
                ) : undefined}
              </span>
            );
          },
        },
        {
          title: "ULSFO",
          key: "ULSFO",
          width: "13%",
          align: "right",
          render: (record) => {
            let data = record && record.length > 0 ? record[0] : null;
            return (
              <span>
                {data && data != null && data.ULSFO && data.ULSFO.spotPrice
                  ? data.ULSFO.spotPrice
                  : "-"}
                &nbsp;{" "}
                {data &&
                data != null &&
                data.ULSFO &&
                data.ULSFO.spotPrice &&
                data.ULSFO.spotPrice > 0 ? (
                  <CaretUpOutlined
                    style={{
                      color:
                        data &&
                        data != null &&
                        data.ULSFO &&
                        data.ULSFO.spotPrice &&
                        data.ULSFO.spotPrice > 0
                          ? "red"
                          : data &&
                            data != null &&
                            data.ULSFO &&
                            data.ULSFO.spotPrice &&
                            data.ULSFO.spotPrice < 0
                          ? "green"
                          : undefined,
                    }}
                  />
                ) : data &&
                  data != null &&
                  data.ULSFO &&
                  data.ULSFO.spotPrice &&
                  data.ULSFO.spotPrice < 0 ? (
                  <CaretDownOutlined
                    style={{
                      color:
                        data &&
                        data != null &&
                        data.ULSFO &&
                        data.ULSFO.spotPrice &&
                        data.ULSFO.spotPrice > 0
                          ? "red"
                          : data &&
                            data != null &&
                            data.ULSFO &&
                            data.ULSFO.spotPrice &&
                            data.ULSFO.spotPrice < 0
                          ? "green"
                          : undefined,
                    }}
                  />
                ) : undefined}
              </span>
            );
          },
        },
        {
          title: "Time",
          key: "time",
          render: (record) => {
            let data = record && record.length > 0 ? record[0] : null;
            let time =
              data &&
              data != null &&
              data.IFO &&
              data.IFO.timeUTC &&
              data.IFO.timeUTC.split("T").length >= 2 &&
              data.IFO.timeUTC.split("T")[1] &&
              data.IFO.timeUTC.split("T")[1].substring(0, 5);
            return <span style={{ fontSize: "10px" }}>{time}</span>;
          },
        },
      ],
    };
    this.mapContainer = React.createRef();
  }

  componentDidMount = async () => {
    const { data, lng, lat, portItin } = this.props;

    let portname = "Balboa";
    // lat = "53.94", lng = "9.2";
    let array = [];
    if (portItin && portItin.length > 0) {
      portItin.map((val, ind) => {
        array.push({
          latitude: val.latitude,
          longitude: val.longitude,
          port: val.port,
        });
      });
    }
    if (data && data.hasOwnProperty("port") && data.port) {
      portname = data.port;
    }
    if (data && data.hasOwnProperty("latitude") && data.latitude) {
      lat = data.latitude;
    }
    if (data && data.hasOwnProperty("longitude") && data.longitude) {
      lng = data.longitude;
    }
    this.setState({ poly_lat_lng: array, data });
    // this.fetchData(portname, lat, lng);

    this.maprender(array);
    // this.loadAPI();
  };
  fetchData = async (portname, lat, lng) => {
    this.setState({
      loading: true,
    });

    const response1 = await axios.get(
      `${process.env.REACT_APP_URL_NEW}PortWeatherBunker/bunkerAtPort?portname=${portname}`,
      { mode: "cors" }
    );
    const data1 = await response1;
    const resp = await axios.get(
      `${process.env.REACT_APP_URL_NEW}PortWeatherBunker/weather?lat=${lat}&lng=${lng}`,
      { mode: "cors" }
    );
    const data11 = await resp;
    let wData =
      data11 && data11.data && data11.data.length > 0 ? data11.data[0] : {};
    let arrrrr = [];
    let children = [];
    if (data1.data && data1.data.fwdPrice && data1.data.fwdPrice.length > 0) {
      data1.data.fwdPrice.map((val, i) => {
        let obj = {};
        Object.keys(val).forEach(function (key) {
          obj["name"] = key;
          var value = val[key];
          if (value && value.length > 0) {
            value.map((ival, iind) => {
              Object.keys(ival).forEach(function (k) {
                var vv = ival[k];
                vv &&
                  vv.map((i, j) => {
                    if (j == 0)
                      children.push({
                        fuel: k,
                        price: i.estPrice,
                        validYearMonth: i.validYearMonth,
                      });
                    else
                      children.push({
                        price: i.estPrice,
                        validYearMonth: i.validYearMonth,
                      });
                  });
              });
              obj["children"] = children;
            });
          }
          arrrrr.push(obj);
          children = [];
        });
      });
    }

    this.setState({
      shipData: data1.data,
      datas: arrrrr,
      weatherData: wData,
      loading: false,
    });
  };

  maprender(dataArray) {
    const geojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            properties: {},
            coordinates: this.state.coordinates ? this.state.coordinates : [],
          },
        },
      ],
    };
    const map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: "mapbox://styles/techtheocean/cl6yw3vjx000h14s0yrxn5cf6",
      center:
        dataArray && dataArray.length > 0
          ? [dataArray[0].longitude, dataArray[0].latitude]
          : [0.0, 0.0],
      zoom: this.state.zoom,
    });

    map.on("load", () => {
      // add mapbox terrain dem source for 3d terrain rendering
      map.addSource("LineString", {
        type: "geojson",
        data: geojson,
      });

      map.on("click", (e) => {
        //   console.log(e);
      });

      map.loadImage(
        // "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
        IMAGE_PATH+"icons/harbor.png",
        (error, image) => {
          if (error) throw error;
          map.addImage("custom-marker", image);

          map.addLayer({
            id: "LineString",
            type: "line",
            source: "LineString",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#2414E2",
              "line-width": 4,
              "line-dasharray": [0, 1.5],
            },
          });

          // console.log('Objects map data ', dataArray);

          let features = [];
          dataArray.map((e, ind) => {
            features.push({
              type: "Feature",
              properties: {
                description: "<strong>" + e.port + "</strong>",
                icon: "custom-marker",
              },
              geometry: {
                type: "Point",
                coordinates: [e.longitude, e.latitude],
              },
            });
          });

          map.addSource("places", {
            // This GeoJSON contains features that include an "icon"
            // property. The value of the "icon" property corresponds
            // to an image in the Mapbox Streets style's sprite.
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: features,
            },
          });
          // Add a layer showing the places.
          map.addLayer({
            id: "places",
            type: "symbol",
            source: "places",
            layout: {
              "icon-image": "{icon}",
              "icon-size": 1,
              "icon-allow-overlap": true,
            },
          });

          // When a click event occurs on a feature in the places layer, open a popup at the
          // location of the feature, with description HTML from its properties.
          map.on("click", "places", (e) => {
            // Copy coordinates array.
            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.description;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            let descriptionTemp = description.split("<strong>");
            descriptionTemp = descriptionTemp[1].split("</strong>");
            this.fetchData(descriptionTemp[0], e.lngLat.lat, e.lngLat.lng);

            this.setState({
              data: {
                port: descriptionTemp[0],
                longitude: e.lngLat.lng.toFixed(4),
                latitude: e.lngLat.lat.toFixed(4),
              },
            });

            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(description)
              .addTo(map);

            // console.log(descriptionTemp[0])
            // console.log(e)
          });
        }
      );
      // Change the cursor to a pointer when the mouse is over the places layer.
      map.on("mouseenter", "places", () => {
        map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      map.on("mouseleave", "places", () => {
        map.getCanvas().style.cursor = "";
      });
    });

    // cleanup function to remove map on unmount
    return () => map.remove();
  }

  loadHeadders = (portName) => {
    let headers = {
      order_by: {
        port_name: "ASC",
      },
      where: {
        OR: {
          id: {
            eq: "'" + portName + "'",
          },
          PortID: {
            l: portName,
          },
          port_name: {
            l: portName,
          },
          latitude: {
            l: portName,
          },
          longitude: {
            l: portName,
          },
          country_code: {
            l: portName,
          },
        },
      },
    };
    return headers;
  };

  render() {
    const {
      shipData,
      columns,
      mapSettings,
      datas,
      columnsSpot,
      loading,
      poly_lat_lng,
      data,
      weatherData,
    } = this.state;

    return (
      <div className="body-wrapper">
        <article className="article">
          <Row>
            <Col span={12} style={{ minHeight: "700px" }}>
              {/* <Map
                                google={this.props.google}
                                zoom={mapSettings.zoom}
                                initialCenter={mapSettings.center}
                                mapId="c29c09d0a6b2a312"
                            >
                                {poly_lat_lng.map((e, ind) => {
                                    return (
                                        <Marker
                                            title={`Port Name:${e.port}\nlatitude:${e.latitude}\nlongitude:${e.longitude}`}
                                            icon={{
                                                url: "../../assets/ship.png",
                                                anchor: new this.props.google.maps.Point(32, 32),
                                                scaledSize: new this.props.google.maps.Size(30, 30)
                                            }}
                                            onClick={() => this.setState({ data: e }, () => this.fetchData(e.port, e.latitude, e.longitude))}
                                            position={{ lat: e.latitude, lng: e.longitude }}
                                        />
                                    )
                                })}
                            </Map> */}
              {/* <div className="sidebar">
                                Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom: {this.state.zoom}
                            </div> */}
              <div
                ref={this.mapContainer}
                className="map-container"
                style={{ width: "100%", height: "70vh" }}
              />
            </Col>
            <Col span={12}>
              <div className="box box-default">
                <div className="box-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="user-details-list">
                        <p>
                          <b>Port:</b>
                          {data && data.port ? data.port : "-"}{" "}
                          <span>
                            {" "}
                            &nbsp;{" "}
                            {shipData != null && shipData.region
                              ? shipData.region
                              : "-"}
                          </span>
                          <i> on latitiude </i>{" "}
                          {data && data.latitude ? data.latitude : "-"}{" "}
                          <i>, longitude </i>{" "}
                          {data && data.longitude ? data.longitude : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Divider>Bunker Prices at port</Divider>
                  {/* refactor all duplication and use loops*/}
                  <p style={{ fontSize: "8px", textAlign: "right" }}>
                    {" "}
                    * at :{" "}
                    <strong>
                      {" "}
                      &nbsp;{" "}
                      {shipData != null && shipData.IFO && shipData.IFO.timeUTC
                        ? shipData.IFO.timeUTC
                        : "-"}
                    </strong>
                  </p>
                  <Row>
                    <Col
                      style={{
                        backgroundColor: "#efefef",
                        height: "60px",
                        padding: "5px",
                        borderRadius: "8px",
                      }}
                      span={4}
                    >
                      <Row>
                        <Col span={10}>
                          <FireOutlined
                            style={{
                              fontSize: "20px",
                              textAlign: "left",
                              color: "red",
                            }}
                          />
                          <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
                            IFO
                          </h2>
                        </Col>
                        <Col span={14}>
                          <h4
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {shipData != null &&
                            shipData.IFO &&
                            shipData.IFO.spotPrice
                              ? shipData.IFO.spotPrice
                              : "0.00"}
                          </h4>
                          <h6
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {shipData != null &&
                            shipData.IFO &&
                            shipData.IFO.priceChange
                              ? shipData.IFO.priceChange
                              : "0.00"}
                            %&nbsp;{" "}
                            {shipData != null &&
                            shipData.IFO &&
                            shipData.IFO.priceChange &&
                            shipData.IFO.priceChange > 0 ? (
                              <CaretUpOutlined
                                style={{
                                  color:
                                    shipData != null &&
                                    shipData.IFO &&
                                    shipData.IFO.priceChange &&
                                    shipData.IFO.priceChange > 0
                                      ? "red"
                                      : shipData != null &&
                                        shipData.IFO &&
                                        shipData.IFO.priceChange &&
                                        shipData.IFO.priceChange < 0
                                      ? "green"
                                      : undefined,
                                }}
                              />
                            ) : shipData != null &&
                              shipData.IFO &&
                              shipData.IFO.priceChange &&
                              shipData.IFO.priceChange < 0 ? (
                              <CaretDownOutlined
                                style={{
                                  color:
                                    shipData != null &&
                                    shipData.IFO &&
                                    shipData.IFO.priceChange &&
                                    shipData.IFO.priceChange > 0
                                      ? "red"
                                      : shipData != null &&
                                        shipData.IFO &&
                                        shipData.IFO.priceChange &&
                                        shipData.IFO.priceChange < 0
                                      ? "green"
                                      : undefined,
                                }}
                              />
                            ) : undefined}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1}></Col>
                    <Col
                      style={{
                        backgroundColor: "#efefef",
                        height: "60px",
                        padding: "5px",
                        borderRadius: "8px",
                      }}
                      span={5}
                    >
                      <Row>
                        <Col span={10}>
                          <FireOutlined
                            style={{
                              fontSize: "20px",
                              textAlign: "left",
                              color: "red",
                            }}
                          />
                          <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
                            MGO
                          </h2>
                        </Col>
                        <Col span={14}>
                          <h4
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {shipData != null &&
                            shipData.MGO &&
                            shipData.MGO.spotPrice
                              ? shipData.MGO.spotPrice
                              : "0.00"}
                          </h4>
                          <h6
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {shipData != null &&
                            shipData.MGO &&
                            shipData.MGO.priceChange
                              ? shipData.MGO.priceChange
                              : "0.00"}
                            %&nbsp;{" "}
                            {shipData != null &&
                            shipData.MGO &&
                            shipData.MGO.priceChange &&
                            shipData.MGO.priceChange > 0 ? (
                              <CaretUpOutlined
                                style={{
                                  color:
                                    shipData != null &&
                                    shipData.MGO &&
                                    shipData.MGO.priceChange &&
                                    shipData.MGO.priceChange > 0
                                      ? "red"
                                      : shipData != null &&
                                        shipData.MGO &&
                                        shipData.MGO.priceChange &&
                                        shipData.MGO.priceChange < 0
                                      ? "green"
                                      : undefined,
                                }}
                              />
                            ) : shipData != null &&
                              shipData.MGO &&
                              shipData.MGO.priceChange &&
                              shipData.MGO.priceChange < 0 ? (
                              <CaretDownOutlined
                                style={{
                                  color:
                                    shipData != null &&
                                    shipData.MGO &&
                                    shipData.MGO.priceChange &&
                                    shipData.MGO.priceChange > 0
                                      ? "red"
                                      : shipData != null &&
                                        shipData.MGO &&
                                        shipData.MGO.priceChange &&
                                        shipData.MGO.priceChange < 0
                                      ? "green"
                                      : undefined,
                                }}
                              />
                            ) : undefined}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1}></Col>
                    <Col
                      style={{
                        backgroundColor: "#efefef",
                        height: "60px",
                        padding: "5px",
                        borderRadius: "8px",
                      }}
                      span={6}
                    >
                      <Row>
                        <Col span={10}>
                          <FireOutlined
                            style={{
                              fontSize: "20px",
                              textAlign: "left",
                              color: "red",
                            }}
                          />
                          <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
                            VLSFO
                          </h2>
                        </Col>
                        <Col span={14}>
                          <h4
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {shipData != null &&
                            shipData.VLSFO &&
                            shipData.VLSFO.spotPrice
                              ? shipData.VLSFO.spotPrice
                              : "0.00"}
                          </h4>
                          <h6
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {shipData != null &&
                            shipData.VLSFO &&
                            shipData.VLSFO.priceChange
                              ? shipData.VLSFO.priceChange
                              : "0.00"}
                            % &nbsp;{" "}
                            {shipData != null &&
                            shipData.VLSFO &&
                            shipData.VLSFO.priceChange &&
                            shipData.VLSFO.priceChange > 0 ? (
                              <CaretUpOutlined
                                style={{
                                  color:
                                    shipData != null &&
                                    shipData.VLSFO &&
                                    shipData.VLSFO.priceChange &&
                                    shipData.VLSFO.priceChange > 0
                                      ? "red"
                                      : shipData != null &&
                                        shipData.VLSFO &&
                                        shipData.VLSFO.priceChange &&
                                        shipData.VLSFO.priceChange < 0
                                      ? "green"
                                      : undefined,
                                }}
                              />
                            ) : shipData != null &&
                              shipData.VLSFO &&
                              shipData.VLSFO.priceChange &&
                              shipData.VLSFO.priceChange < 0 ? (
                              <CaretDownOutlined
                                style={{
                                  color:
                                    shipData != null &&
                                    shipData.VLSFO &&
                                    shipData.VLSFO.priceChange &&
                                    shipData.VLSFO.priceChange > 0
                                      ? "red"
                                      : shipData != null &&
                                        shipData.VLSFO &&
                                        shipData.VLSFO.priceChange &&
                                        shipData.VLSFO.priceChange < 0
                                      ? "green"
                                      : undefined,
                                }}
                              />
                            ) : undefined}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1}></Col>
                    <Col
                      style={{
                        backgroundColor: "#efefef",
                        height: "60px",
                        padding: "5px",
                        borderRadius: "8px",
                      }}
                      span={6}
                    >
                      <Row>
                        <Col span={10}>
                          <FireOutlined
                            style={{
                              fontSize: "20px",
                              textAlign: "left",
                              color: "red",
                            }}
                          />
                          <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
                            ULSFO
                          </h2>
                        </Col>
                        <Col span={14}>
                          <h4
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {shipData != null &&
                            shipData.ULSFO &&
                            shipData.ULSFO.spotPrice
                              ? shipData.ULSFO.spotPrice
                              : "0.00"}
                          </h4>
                          <h6
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {shipData != null &&
                            shipData.ULSFO &&
                            shipData.ULSFO.priceChange
                              ? shipData.ULSFO.priceChange
                              : "0.00"}
                            %&nbsp;{" "}
                            {shipData != null &&
                            shipData.ULSFO &&
                            shipData.ULSFO.priceChange &&
                            shipData.ULSFO.priceChange > 0 ? (
                              <CaretUpOutlined
                                style={{
                                  color:
                                    shipData != null &&
                                    shipData.ULSFO &&
                                    shipData.ULSFO.priceChange &&
                                    shipData.ULSFO.priceChange > 0
                                      ? "red"
                                      : shipData != null &&
                                        shipData.ULSFO &&
                                        shipData.ULSFO.priceChange &&
                                        shipData.ULSFO.priceChange < 0
                                      ? "green"
                                      : undefined,
                                }}
                              />
                            ) : shipData != null &&
                              shipData.ULSFO &&
                              shipData.ULSFO.priceChange &&
                              shipData.ULSFO.priceChange < 0 ? (
                              <CaretUpOutlined
                                style={{
                                  color:
                                    shipData != null &&
                                    shipData.ULSFO &&
                                    shipData.ULSFO.priceChange &&
                                    shipData.ULSFO.priceChange > 0
                                      ? "red"
                                      : shipData != null &&
                                        shipData.ULSFO &&
                                        shipData.ULSFO.priceChange &&
                                        shipData.ULSFO.priceChange < 0
                                      ? "green"
                                      : undefined,
                                }}
                              />
                            ) : undefined}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider>Weather at port</Divider>
                  {!loading ? (
                    <>
                      <Row>
                        <Col span={12}>
                          <label>Air Pressure</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.airPressure &&
                              weatherData.airPressure.sg
                                ? weatherData.airPressure.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.airPressure &&
                              weatherData.airPressure.noaa
                                ? weatherData.airPressure.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                        <Col span={12}>
                          <label>Air Temperature</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.airTemperature &&
                              weatherData.airTemperature.sg
                                ? weatherData.airTemperature.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.airTemperature &&
                              weatherData.airTemperature.noaa
                                ? weatherData.airTemperature.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <label>Air Temperature80m</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.airTemperature80m &&
                              weatherData.airTemperature80m.sg
                                ? weatherData.airTemperature80m.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.airTemperature80m &&
                              weatherData.airTemperature80m.noaa
                                ? weatherData.airTemperature80m.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                        <Col span={12}>
                          <label>Swell Direction</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.swellDirection &&
                              weatherData.swellDirection.sg
                                ? weatherData.swellDirection.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.swellDirection &&
                              weatherData.swellDirection.noaa
                                ? weatherData.swellDirection.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <label>Swell Height</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.swellHeight &&
                              weatherData.swellHeight.sg
                                ? weatherData.swellHeight.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.swellHeight &&
                              weatherData.swellHeight.noaa
                                ? weatherData.swellHeight.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                        <Col span={12}>
                          <label>Wave Height</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.waveHeight &&
                              weatherData.waveHeight.sg
                                ? weatherData.waveHeight.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.waveHeight &&
                              weatherData.waveHeight.noaa
                                ? weatherData.waveHeight.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <label>Wind10m</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.wind10m &&
                              weatherData.wind10m.sg
                                ? weatherData.wind10m.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.wind10m &&
                              weatherData.wind10m.noaa
                                ? weatherData.wind10m.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                        <Col span={12}>
                          <label>Wind50m</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.wind50m &&
                              weatherData.wind50m.sg
                                ? weatherData.wind50m.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.wind50m &&
                              weatherData.wind50m.noaa
                                ? weatherData.wind50m.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <label>Wind80m</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.wind80m &&
                              weatherData.wind80m.sg
                                ? weatherData.wind80m.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.wind80m &&
                              weatherData.wind80m.noaa
                                ? weatherData.wind80m.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                        <Col span={12}>
                          <label>Wind Direction10m</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.windDirection10m &&
                              weatherData.windDirection10m.sg
                                ? weatherData.windDirection10m.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.windDirection10m &&
                              weatherData.windDirection10m.noaa
                                ? weatherData.windDirection10m.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <label>Wind Direction50m</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.windDirection50m &&
                              weatherData.windDirection50m.sg
                                ? weatherData.windDirection50m.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.windDirection50m &&
                              weatherData.windDirection50m.noaa
                                ? weatherData.windDirection50m.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                        <Col span={12}>
                          <label>Wind Direction80m</label>
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {weatherData &&
                              weatherData.windDirection80m &&
                              weatherData.windDirection80m.sg
                                ? weatherData.windDirection80m.sg
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                sg
                              </sup>
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {weatherData &&
                              weatherData.windDirection80m &&
                              weatherData.windDirection80m.noaa
                                ? weatherData.windDirection80m.noaa
                                : 0.0}
                              <sup
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                  verticalAlign: "super",
                                }}
                              >
                                noaa
                              </sup>
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Spin spinning={loading} />
                  )}
                  <Divider>Bunker prices at major ports</Divider>
                  <Table
                    columns={columnsSpot}
                    pagination={false}
                    loading={loading}
                    rowClassName={(r, i) =>
                      i % 2 === 0
                        ? "table-striped-listing"
                        : "dull-color table-striped-listing"
                    }
                    dataSource={
                      shipData &&
                      shipData.spotPriceTop10 &&
                      shipData.spotPriceTop10.length > 0
                        ? shipData.spotPriceTop10
                        : []
                    }
                    scroll={{
                      x: 800,
                    }}
                  />
                  <Divider>Fwd Bunker Prices</Divider>

                  <Table
                    columns={columns}
                    dataSource={datas}
                    pagination={false}
                    loading={loading}
                    rowClassName={(r, i) =>
                      i % 2 === 0
                        ? "table-striped-listing"
                        : "dull-color table-striped-listing"
                    }
                    size="small"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </article>
      </div>
    );
  }
}
export default ShipDetails;
