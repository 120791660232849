import React, { Component } from 'react';
import { Table, Tabs, Input, Row, Col, Form } from 'antd';
import ToolbarUI from '../../components/ToolbarUI';

const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const FormItem = Form.Item;

const columns = [
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '20%',
  },
  {
    title: 'Invoice No.',
    dataIndex: 'invoice_no',
    key: 'invoice_no',
    width: '8%',
  },
  {
    title: 'Estimated',
    dataIndex: 'estimate',
    key: 'estimate',
    width: '8%',
  },
  {
    title: 'Actual',
    dataIndex: 'actual',
    key: 'actual',
    width: '8%',
  },
  {
    title: 'Posted',
    dataIndex: 'posted',
    key: 'posted',
    width: '8%',
  },
  {
    title: 'Cash In',
    dataIndex: 'cash_in',
    key: 'cash_in',
    width: '8%',
  },
  {
    title: 'Est. Vs Act',
    dataIndex: 'variance',
    key: 'variance',
    width: '8%',
  },
  {
    title: '% Est. Vs Act',
    dataIndex: 'per',
    key: 'per',
    width: '8%',
  },
  {
    title: 'Post Vs Cash',
    dataIndex: 'sec_variance',
    key: 'sec_variance',
    width: '8%',
  },
  {
    title: '% Post Vs Cash',
    dataIndex: 'sec_per',
    key: 'sec_per',
    width: '8%',
  },
];

const data = [
  {
    key: 1,
    description: 'Revenue',
    invoice_no: 60,
    estimate: 543545,
    actual: 4,
    posted: 4535,
    cash_in: 435345,
    variance: 335,
    per: 5345435,
    sec_variance: 5345345,
    sec_per: 43535,
    children: [
      {
        key: 11,
        description: 'Freight',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 11,
        description: 'Freight Commission',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 11,
        description: 'Demurrage',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 11,
        description: 'Demurrage Commission',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 11,
        description: 'Dispatch',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 11,
        description: 'Dispatch Commission',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 11,
        description: 'Mis Revenue',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 11,
        description: 'Gross Revenue',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 11,
        description: 'Net Revenue',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },
    ],
  },

  {
    key: 12,
    description: 'Expenses.',
    invoice_no: 30,
    estimate: 543545,
    actual: 4,
    posted: 4535,
    cash_in: 435345,
    variance: 335,
    per: 5345435,
    sec_variance: 5345345,
    sec_per: 43535,
    children: [
      {
        key: 121,
        description: 'Vessel expenses',
        invoice_no: 16,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 121,
        description: 'less add com',
        invoice_no: 16,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 121,
        description: 'less Broker.com',
        invoice_no: 16,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 121,
        description: 'Port expenses',
        invoice_no: 16,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 121,
        description: 'Bunker expenses',
        invoice_no: 16,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 121,
        description: 'Mis expenses',
        invoice_no: 16,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 121,
        description: 'Vessel expenses',
        invoice_no: 16,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 121,
        description: 'Mis revenue',
        invoice_no: 16,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },
    ],
  },
  {
    key: 13,
    description: 'Voyage result',
    invoice_no: 72,
    estimate: 543545,
    actual: 4,
    posted: 4535,
    cash_in: 435345,
    variance: 335,
    per: 5345435,
    sec_variance: 5345345,
    sec_per: 43535,
    children: [
      {
        key: 131,
        description: 'Profit (Loss)',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 131,
        description: 'Daily Profit(Loss)',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 131,
        description: 'TCE ( Net Daily)',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 131,
        description: 'Gross TCE',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 131,
        description: 'Freight rate ($/T)',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 131,
        description: 'Breakeven',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 131,
        description: 'Net voyage days',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 131,
        description: 'Total Sea days',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },

      {
        key: 131,
        description: 'Total port days',
        invoice_no: 42,
        estimate: 543545,
        actual: 4,
        posted: 4535,
        cash_in: 435345,
        variance: 335,
        per: 5345435,
        sec_variance: 5345345,
        sec_per: 43535,
      },
    ],
  },
];

const columns2 = [
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '20%',
  },

  {
    title: 'Estimated',
    dataIndex: 'estimate',
    key: 'estimate',
    width: '8%',
  },
];

const data2 = [
  {
    key: 1,
    description: 'Revenue',
    estimate: 543545,
    children: [
      {
        key: 11,
        description: 'Freight',
        estimate: 543545,
      },

      {
        key: 11,
        description: 'Freight Commission',
        estimate: 543545,
      },

      {
        key: 11,
        description: 'Demurrage',
        estimate: 543545,
      },

      {
        key: 11,
        description: 'Demurrage Commission',
        estimate: 543545,
      },

      {
        key: 11,
        description: 'Dispatch',
        estimate: 543545,
      },

      {
        key: 11,
        description: 'Dispatch Commission',
        estimate: 543545,
      },

      {
        key: 11,
        description: 'Mis Revenue',
        estimate: 543545,
      },

      {
        key: 11,
        description: 'Gross Revenue',
        estimate: 543545,
      },

      {
        key: 11,
        description: 'Net Revenue',
        estimate: 543545,
      },
    ],
  },

  {
    key: 12,
    description: 'Expenses.',
    estimate: 543545,
    children: [
      {
        key: 121,
        description: 'Vessel expenses',
        estimate: 543545,
      },

      {
        key: 121,
        description: 'less add com',
        estimate: 543545,
      },

      {
        key: 121,
        description: 'less Broker.com',
        estimate: 543545,
      },

      {
        key: 121,
        description: 'Port expenses',
        estimate: 543545,
      },

      {
        key: 121,
        description: 'Bunker expenses',
        estimate: 543545,
      },

      {
        key: 121,
        description: 'Mis expenses',
        estimate: 543545,
      },

      {
        key: 121,
        description: 'Vessel expenses',
        estimate: 543545,
      },

      {
        key: 121,
        description: 'Mis revenue',
        estimate: 543545,
      },
    ],
  },
  {
    key: 13,
    description: 'Voyage result',
    estimate: 543545,
    children: [
      {
        key: 131,
        description: 'Profit (Loss)',
        estimate: 543545,
      },

      {
        key: 131,
        description: 'Daily Profit(Loss)',
        estimate: 543545,
      },

      {
        key: 131,
        description: 'TCE ( Net Daily)',
        estimate: 543545,
      },

      {
        key: 131,
        description: 'Gross TCE',
        estimate: 543545,
      },

      {
        key: 131,
        description: 'Freight rate ($/T)',
        estimate: 543545,
      },

      {
        key: 131,
        description: 'Breakeven',
        estimate: 543545,
      },

      {
        key: 131,
        description: 'Net voyage days',
        estimate: 543545,
      },

      {
        key: 131,
        description: 'Total Sea days',
        estimate: 543545,
      },

      {
        key: 131,
        description: 'Total port days',
        estimate: 543545,
      },
    ],
  },
];

class VoyReletPlSummary extends Component {
  callback = evt => {};

  render() {
    return (
      <>
        <div>
          <ToolbarUI routeUrl={'pl-main'} callback={e => this.callback(e)} />
        </div>

        <Tabs defaultActiveKey="1">
          <TabPane tab="Estimate View" key="1">
            {' '}
            <Table columns={columns2} dataSource={data2} pagination={false} bordered 
            scroll={{x: 'max-content' }}
            rowClassName={(r,i) => ((i%2) === 0 ? 'table-striped-listing' : 'dull-color table-striped-listing')}
            />
            <Row gutter={16} className="m-t-18">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormItem label="Remark" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <TextArea placeholder="Remark" autoSize={{ minRows: 6, maxRows: 6 }} />
                </FormItem>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Actual &  Operation View" key="2">
            <Table columns={columns} dataSource={data} pagination={false} bordered 
            scroll={{x: 'max-content' }}
            rowClassName={(r,i) => ((i%2) === 0 ? 'table-striped-listing' : 'dull-color table-striped-listing')}
            />
            <Row gutter={16} className="m-t-18">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormItem label="Remark" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <TextArea placeholder="Remark" autoSize={{ minRows: 6, maxRows: 6 }} />
                </FormItem>
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="Account View" key="3">
            Accounts
          </TabPane>
        </Tabs>
      </>
    );
  }
}

export default VoyReletPlSummary;
