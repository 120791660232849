import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table, Input, Button, Modal } from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import URL_WITH_VERSION, { getAPICall } from "../../shared";

const columns = [
  {
    title: "Port",
    dataIndex: "port",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Grade",
    dataIndex: "grade",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },

  {
    title: "Last Updated",
    dataIndex: "lastUpdated",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Future Month",
    dataIndex: "futureMonth",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Price",
    dataIndex: "price",
    align: "left",
    render: (el) => (
      <span className="spot-first">
        {"$ "} {el}
      </span>
    ),
  },
];

export default function FuturePrice({ futurePricedata }) {
 
  const [isLoading, setIsLoading] = useState(false);
  //const [data, setData] = useState([{}]);
  const [filteredData, setFilteredData] = useState(futurePricedata);
  const [pageSize, setPageSize] = useState(50);
  const onSearchFilter = (e) => {
    const { value } = e.target;

    if (value.length > 2) {
      const filtered = futurePricedata.filter((item) => {
        return (
          item.port?.toLowerCase().includes(value.toLowerCase()) ||
          item.country?.toLowerCase().includes(value.toLowerCase()) ||
          item.grade?.toLowerCase().includes(value.toLowerCase()) ||
          (item.price !== null &&
            item.price !== undefined &&
            item.price.toString().includes(value)) ||
          (item.netChange !== null &&
            item.netChange !== undefined &&
            item.netChange.toString().includes(value))
        );
      });

      setFilteredData(filtered);
    } else if (value.length === 0) {
      setFilteredData([]);
    }
  };

  const handleTableChange = (pagination) => {
    if (pagination.pageSize) {
      setPageSize(pagination.pageSize);
    }
  };

  
  return (
    <div style={{ maxWidth: "650px" }}>
      <div>
        <Input
          placeholder="Search"
          name="filter"
          prefix={<SearchOutlined className="site-form-item-icon" />}
          style={{ width: "300px", margin: "8px" }}
          onChange={onSearchFilter}
        />
      </div>
      <Table
        className="spot"
        columns={columns}
        dataSource={filteredData.length > 0 ? filteredData : futurePricedata}
        size="medium"
        //loading={isLoading}

        // pagination={{
        //   pageSize: 50,
        //   showSizeChanger: true,
        //   total: futurePricedata.length,
        // }}

        pagination={{
          pageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
          total: futurePricedata.length,
        }}
        onChange={handleTableChange}
        scroll={{ y: `calc(100vh - 250px)` }}
      />
    </div>
  );
}
