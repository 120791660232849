import { useCallback, useEffect, useRef, useState } from "react";
//import * as axios from 'axios';
import axios from "axios";

import { notification } from "antd";
import { Resizable } from "react-resizable";
// import { constant, head } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export * from "./constants";

//generic
//const URL_WITH_VERSION = window.location.protocol+ process.env.REACT_APP_URL + process.env.REACT_APP_VERSION;
//docker
const URL_WITH_VERSION =
  process.env.REACT_APP_URL + process.env.REACT_APP_VERSION;
//console.log("URL is " + URL_WITH_VERSION);
//docker
export const URL_WITHOUT_VERSION = process.env.REACT_APP_URL;
export const IMAGE_PATH = process.env.REACT_APP_IMAGE_PATH;
// const cookies = new Cookies();
//export const URL_WITHOUT_VERSION = window.location.protocol + process.env.REACT_APP_URL;

// let password = cookies.get('encpass');

// let username = cookies.get('username');

// axios.interceptors.request.use(req => {

//     // `req` is the Axios request config, so you can modify

//     // the `headers`.

//     req.headers.password =password;

//     req.headers.username =username;

//     return req;

//   });

const apiGetCall = async (url, headers = undefined, callback = null) => {
  // alert("dsfdfds")
  const token = localStorage.getItem("oceanToken");
  const lastClickedItem = localStorage.getItem("lastClickedItem");
  let getHeaders = {
    Authorization: token,
    endpoint: lastClickedItem,
  };
  if (headers) {
    headers = JSON.stringify(headers);
    getHeaders["CONTENT-TYPE"] = "application/json";
    getHeaders["Access-Control-Allow-Origin"] = "*";
    getHeaders["X-FILTER"] = headers;
  }
  const response = await axios({
    method: "GET",
    url: url,
    headers: getHeaders,
    validateStatus: function (status) {
      // Resolve the promise if the status code is less than 500
      return status < 500;
    },
  });

  if (response.status === 401) {
    openNotificationWithIcon("info", "Session expired , please login", 3);
    localStorage.clear();
    window.myNavigator("user/login", { replace: true });
    return;
  }
  if (response.status === 403) {
    openNotificationWithIcon("info", "Dont have access to this utility", 3);

    return;
  }
  callback(response.data);
};

const apiPostCall = async (
  method,
  url,
  data,
  callback = null,
  postHeaders = null
) => {
  let headers = {
    "Content-Type": "application/json", // You can adjust the content type based on your API's requirements
  };
  postHeaders["headers"]["Content-Type"] = "application/json";
  if (url?.includes("send-with-file")) {
    postHeaders["headers"]["Content-Type"] = "multipart/form-data";
  }

  if (postHeaders) {
    // headers = { ...headers, ...postHeaders };
    Object.assign(headers, postHeaders);
  }
  try {
    const response = await axios({
      method: method,
      url: url,
      data: data,
      headers: postHeaders["headers"],
      validateStatus: function (status) {
        // Resolve the promise if the status code is less than 500
        return status < 500;
      },
    });

    // console.log(response, "====");
    if (response.status === 401) {
      openNotificationWithIcon("info", "Session expired , please login", 3);
      localStorage.clear();
      window.myNavigator("/user/login", { replace: true });
      // return <Navigate to="/user/login" replace={true}/>
      // window.location.href = "user/login";
      return;
    }
    if (response.status === 403) {
      openNotificationWithIcon("info", "Dont have access to this utility", 3);
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Access Denied',
      //   text: 'You do not have permission to access this resource.',
      // });
      return;
    }
    if (typeof callback === "function") {
      // console.log("callback");
      return callback(response.data);
    } else {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const awaitPostAPICall = async (url, data) => {
  const token = localStorage.getItem("oceanToken");
  const lastClickedItem = localStorage.getItem("lastClickedItem");
  let callback;
  try {
    callback = await axios({
      method: "POST",
      url: url,
      data: data,
      headers: { Authorization: token, endpoint: lastClickedItem },
      validateStatus: function (status) {
        // Resolve the promise if the status code is less than 500
        return status < 500;
      },
    });
  } catch (err) {
    openNotificationWithIcon("error", err.message, 5);
  }
  return callback ? callback["data"] : {};
};

export const postAPICallService = async ({ url, payload, signal }) => {
  try {
    const token = localStorage.getItem("oceanToken");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token, // Make sure to set the appropriate content type
      },
      body: JSON.stringify(payload),
      signal: signal, // Pass the abort signal
    });
    // console.log(response,"response");
    // Handle the response as needed
    if (response.status === 401) {
      openNotificationWithIcon("info", "Session expired , please login", 3);
      localStorage.clear();

      window.myNavigator("user/login", { replace: true });
      return;
    }
    if (response.status === 403) {
      openNotificationWithIcon("info", "Dont have access to this utility", 3);
      return;
    }
    const data = await response.json();
    // console.log(data,"dataaaa");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const postAPICallFormData = async (url, data) => {
  // alert("this function");
  let callback;
  const token = localStorage.getItem("oceanToken");
  const lastClickedItem = localStorage.getItem("lastClickedItem");
  try {
    callback = await axios({
      method: "POST",
      url,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
        endpoint: lastClickedItem,
      },
    });
  } catch (err) {
    openNotificationWithIcon("Error !!", err.message, 5);
  }
  return callback ? callback["data"] : null;
};

export const getAPICall = async (url, headers = "") => {
  try {
    const token = localStorage.getItem("oceanToken");
    const lastClickedItem = localStorage.getItem("lastClickedItem");
    if (token) {
      let getHeaders = {};
      if (headers) {
        getHeaders["headers"] = {
          "CONTENT-TYPE": "application/json",
          crossorigin: "true",
          "X-FILTER": JSON.stringify(headers),
          "Access-Control-Allow-Origin": "*",
          Authorization: token,
          endpoint: lastClickedItem,
        };
      } else {
        getHeaders["headers"] = {
          "CONTENT-TYPE": "application/json",
          Authorization: token,
          endpoint: lastClickedItem,
        };
      }
      // crossorigin: "true",
      //   USERNAME: cookies.get("username"),
      //   PASSWORD: cookies.get("encpass"),

      //getHeaders["headers"] = {};
      //getHeaders["headers"]["Authorization"] = token;
      //getHeaders["headers"]["endpoint"] = lastClickedItem;

      // console.log(getHeaders, "-----");

      const response = await axios.get(url, {
        ...getHeaders,
        validateStatus: function (status) {
          return status < 500; // Only resolve if the status code is less than 500
        },
      });

      if (response.status === 401) {
        openNotificationWithIcon("info", "Session expired, please login again");
        localStorage.clear();
        window.myNavigator("user/login", { replace: true });
        return;
      } else if (response.status === 403) {
        openNotificationWithIcon("info", "Don't have access to this utility");
        // window.myNavigator("/access-denied",{replace:true})

        return;
      }

      return response.data;
    } else {
      openNotificationWithIcon("info", "Session expired ,please login again");
      window.myNavigator("user/login", { replace: true });
      return;
      // <Navigate to="user/login" replace={true} />;
    }
  } catch (error) {
    console.log(error);
    openNotificationWithIcon("error", "Error in api call", 3);
  }
};

export const postAPICall = async (
  url,
  data,
  method = null,
  callback = null,
  headers = null
) => {
  const token = localStorage.getItem("oceanToken");
  const lastClickedItem = localStorage.getItem("lastClickedItem");
  let postHeaders = {};
  if (headers) {
    // headers = JSON.stringify(headers)
    let token = headers["auth_token"];
    delete headers["auth_token"]; // remove it
    postHeaders["headers"] = {
      crossorigin: "true",
      auth_token: token,
    };
    postHeaders["Access-Control-Allow-Origin"] = "*";
  }
  // console.log(postHeaders);
  postHeaders["headers"] = {};
  postHeaders["headers"]["Authorization"] = token ? token : "";
  postHeaders["headers"]["endpoint"] = lastClickedItem ? lastClickedItem : "";

  if (typeof method === "function") {
    callback = method;
    method = "post";
  }

  return apiPostCall(method, url, data, callback, postHeaders);
};

export const CALL_MASTER_API = (
  method,
  pathUrl,
  postData = null,
  qParams = null,
  headers = null,
  callback = null
) => {
  if (typeof headers === "function") {
    callback = headers;
    headers = undefined;
  }
  switch (method) {
    case "get":
      let _url = `${URL_WITH_VERSION}/master/list?t=${pathUrl}`;
      if (qParams) {
        qParams = objectToQueryString(qParams);
        _url += `&${qParams}`;
      }
      return apiGetCall(_url, headers, callback);
    case "post":
    case "put":
      return postAPICall(
        `${URL_WITH_VERSION}/master/${
          method === "put" ? "update" : "save"
        }?t=${pathUrl}`,
        postData,
        method,
        callback
      );
    case "delete":
      // return apiPostCall(
      return postAPICall(
        `${URL_WITH_VERSION}/master/delete?t=${pathUrl}`,
        postData,
        method,
        callback
      );
    default:
      return null;
  }
};

function objectToQueryString(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
};

export const objectToQueryStringFunc = objectToQueryString;

export const GET_STATUS_BADGE = (status) => {
  switch (status) {
    case 1:
      return "#52c41a";
    case 0:
      return "#6c757d";
    case 3:
      return "#f5222d";
    default:
      return null;
  }
};

export const GET_STATUS_TEXT = (status) => {
  switch (status) {
    case 1:
      return "Active";
    case 0:
      return "Inactive";
    case 3:
      return "Deleted";
    default:
      return null;
  }
};

export const apiDeleteCall = async (url, data, callback = null) => {
  const token = localStorage.getItem("oceanToken");
  const lastClickedItem = localStorage.getItem("lastClickedItem");

  const response = await axios({
    method: "DELETE",
    url: url,
    data: data,
    headers: {
      Authorization: token,
      endpoint: lastClickedItem,
    },
  });

  if (response.status === 401) {
    openNotificationWithIcon("info", "Session expired, please login again");
    localStorage.clear();
    window.myNavigator("user/login", { replace: true });
    return;
  }

  if (response.status === 403) {
    openNotificationWithIcon("info", "Don't have access to this utility");
    return;
  }
  if (typeof callback === "function") {
    return callback(response.data);
  } else {
    return response.data;
  }

  // .then((response) => {
  // if (typeof callback === "function") {
  //   return callback(response.data);
  // } else {
  //   return response.data;
  // }
  // });
};

// type = success | info | warning | error
export const openNotificationWithIcon = (type, msg, duration = 5) => {
  let upperCaseTitle = (
    <h4 className={"notify-" + type}>
      {type.charAt(0).toUpperCase() + type.slice(1)}
    </h4>
  );

  notification.config({
    getContainer() {
      return document.body;
    },
  });
  notification.destroy();

  notification[type]({
    message: upperCaseTitle,
    description: msg,
    duration: duration,
  });
};

export const ResizeableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export const sanitize = (object) => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === "object" && !v.hasOwnProperty("_isAMomentObject"))
      sanitize(v);
    if (
      (v && typeof v === "object" && !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      v.length === 0
    ) {
      if (Array.isArray(object)) object.splice(k, 1);
      else if (!(v instanceof Date)) delete object[k];
    }
  });
  return object;
};

export const ValidateDisplayValue = (val) => {
  return isFinite(val) ? val : "N/A";
};

export const randomFixedInteger = function (length) {
  return Math.floor(
    Math.pow(10, length - 1) +
      Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
  );
};

export const isValidEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !(!email || regex.test(email) === false);
};

export const getBunkerspotprice = async (value = null) => {
  try {
    const data = await fetch(
      `${process.env.REACT_APP_ATTACHMENT}/port-bunker-activity/spotprice`
    );
    const respdata = await data.json();
    if (respdata.prices) {
      //dispatch({ type: "INITIAL_LOAD_DATA", payload: respdata.prices });
      value = respdata.prices;
    }
  } catch (err) {
    console.log(err);
    openNotificationWithIcon("error", "Something went wrong", 3);
  }
  return value;
};

export const getVesselParticulars = async (vesselId) => {
  const url = `${process.env.REACT_APP_VESSEL_NONAIS}/${vesselId}?apikey=${process.env.REACT_APP_VESSEL_API_KEY}`;

  return await fetch(url, {
    method: "GET",
    headers: {
      "access-control-allow-origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const withLocation = (Component) => (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  return <Component {...props} router={{ location, navigate, params }} />;
};

// custom hoook for calling a callback just after the setstate
export const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks
  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`
  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);
  return [state, setStateCallback];
};

export default URL_WITH_VERSION;
