import React, { Component } from 'react';
import { Form, Select } from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const Properties=()=> {
    return (
      <div className="body-wrapper modalWrapper m-0">
        <article className="article toolbaruiWrapper">
          <div className="box box-default mb-2">
            <div className="box-body">
              <h4>Company</h4>
              <hr />
              <Form>
                <FormItem {...formItemLayout} label="My Company">
                  <Select defaultValue="1" size="default">
                    <Option value="1">SEML</Option>
                    <Option value="2">Option 1</Option>
                    <Option value="3">Option 2</Option>
                  </Select>
                </FormItem>

                <FormItem {...formItemLayout} label="Ref Company">
                  <Select defaultValue="1" size="default">
                    <Option value="1">Seatide Maritime</Option>
                    <Option value="2">Option 1</Option>
                    <Option value="3">Option 2</Option>
                  </Select>
                </FormItem>

                <FormItem {...formItemLayout} label="LOB">
                  <Select defaultValue="1" size="default">
                    <Option value="1">OPR</Option>
                    <Option value="2">Option 1</Option>
                    <Option value="3">Option 2</Option>
                  </Select>
                </FormItem>
              </Form>
            </div>
          </div>

          <div className="box box-default mb-2">
            <div className="box-body">
              <h4>Users</h4>
              <hr />
              <Form>
                <FormItem {...formItemLayout} label="Team">
                  <Select defaultValue="1" size="default">
                    <Option value="1">Operations</Option>
                    <Option value="2">Option 1</Option>
                    <Option value="3">Option 2</Option>
                  </Select>
                </FormItem>

                <FormItem {...formItemLayout} label="Chtr Coord">
                  <Select defaultValue="1" size="default">
                    <Option value="1">CS Andersson</Option>
                    <Option value="2">Option 1</Option>
                    <Option value="3">Option 2</Option>
                  </Select>
                </FormItem>

                <FormItem {...formItemLayout} label="Ops Coord 2">
                  <Select defaultValue="1" size="default">
                    <Option value="1">KT Treiber</Option>
                    <Option value="2">Option 1</Option>
                    <Option value="3">Option 2</Option>
                  </Select>
                </FormItem>

                <FormItem {...formItemLayout} label="Finance">
                  <Select defaultValue="1" size="default">
                    <Option value="1">FP Korres</Option>
                    <Option value="2">Option 1</Option>
                    <Option value="3">Option 2</Option>
                  </Select>
                </FormItem>

                
              </Form>
            </div>
          </div>
        </article>
      </div>
    );
}

export default Properties;
