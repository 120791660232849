import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Collapse,
  Dropdown,
  Flex,
  Input,
  Menu,
  message,
  Modal,
  Popover,
  Tooltip,
  Typography,
} from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";
import { chipStyles, events, getInitial } from "./constants";
import ComposeReply from "../../components/emails/ComposeReply";
import {
  extractEmail,
  parseSender,
  sendermaillReturn,
} from "../../services/email_utils";
import Swal from "sweetalert2";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import LabelManager from "./LabelManager";
import { renderToString } from "react-dom/server";
import { useDispatch } from "react-redux";
import { setVesselName } from "../../actions/emailActions";
import ApplyTagsModal from "./ApplyTagsModal";
import ShadowContainer from "./ShadowContainer";
import EmailHeader from "./EmailHeader";
import {
  DeleteOutlined,
  FormOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

// class ShadowContainer extends HTMLElement {
//   constructor() {
//     super();
//     this.attachShadow({ mode: "open" });
//   }

//   /**
//    * @param {string} value
//    */
//   set content(value) {
//     this.shadowRoot.innerHTML = value;
//   }
// }

// customElements.define("shadow-container", ShadowContainer);

const Tags = ({ mail }) => (
  <div
    className="current_mail_header_labels"
    style={{
      // marginLeft: handleCurrentMailClose ? "49px" : "0px",
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "5px",
      alignItems: "baseline",
      rowGap: "5px",
    }}
  >
    {(mail?.MailType ||
      (mail?.vessel_name && mail.vessel_name.length > 0) ||
      mail?.tags?.length > 0) && <b style={{ marginRight: "10px" }}>Tags: </b>}

    {
      <Tooltip title="Mail Type">
        <span className="mail_list_badge">{mail?.MailType}</span>
      </Tooltip>
    }
    {mail?.vessel_name &&
      mail?.vessel_name?.length > 0 &&
      mail?.vessel_name.map((vessel, index) => (
        <Tooltip title="Vessel Name">
          <div
            className="mail_list_labels vessels"
            style={{ display: "inline" }}
            key={index}
          >
            <span className="mail_list_badge">{vessel}</span>
          </div>
        </Tooltip>
      ))}
    {mail?.tags &&
      mail?.tags?.length > 0 &&
      mail?.tags.map((tag, index) => (
        <Tooltip title="Tag">
          <div
            className="mail_list_labels"
            style={{
              display: "inline",
              backgroundColor:
                chipStyles[index % chipStyles.length].backgroundColor,
              color: chipStyles[index % chipStyles.length].color,
              borderColor: chipStyles[index % chipStyles.length].color,
              marginRight: "4px",
              borderRadius: "4px",
              padding: "2px 8px",
              border: "1px solid",
              fontSize: "11px",
            }}
            key={index}
          >
            <span className="">{tag}</span>
          </div>
        </Tooltip>
      ))}
  </div>
);

const Label = ({
  mail,
  dropitems,
  handleIconClick,
  currentMailIndex,
  mailDetails,
  handleSocketEvent = () => {},
}) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);
  const [isLabelModalVisible, setIsLabelModalVisible] = useState(false);
  const popoverRef = useRef(null);

  console.log({ mail, mailDetails });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !isLabelModalVisible
      ) {
        setIsLabelOpen(false);
      }
    };

    if (isLabelOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isLabelOpen, isLabelModalVisible]);

  const generateMenuItems = () => {
    const items = [
      {
        key: "1",
        label: (
          <div
            style={{
              flex: 1,
              listStyle: "none",
              cursor: "pointer",
              borderRadius: "0.5rem",
              marginLeft: "1rem !important",
            }}
            className="file-upload-list"
            onClick={(e) => {
              e.stopPropagation();
              handleIconClick(
                e,
                currentMailIndex,
                "markAsRead",
                mail,
                mailDetails
              );
            }}
          >
            <Typography>
              <Icon
                style={{
                  fontSize: "15px",
                  marginRight: "10px",
                }}
                icon={"material-symbols:mark-email-read-outline-sharp"}
              />
              Mark as read
            </Typography>
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div
            style={{
              flex: 1,
              listStyle: "none",
              cursor: "pointer",
              borderRadius: "0.5rem",
              marginLeft: "1rem !important",
            }}
            className="file-upload-list"
            onClick={(e) => {
              e.stopPropagation();
              handleIconClick(
                e,
                currentMailIndex,
                "markImportant",
                mail,
                mailDetails
              );
            }}
          >
            <Icon
              style={{
                fontSize: "15px",
                marginRight: "10px",
              }}
              icon={"basil:star-outline"}
            />
            Mark Important
          </div>
        ),
      },
    ];

    if (currentMailIndex === 0) {
      items.push({
        key: "3",
        label: (
          <div
            style={{
              flex: 1,
              listStyle: "none",
              cursor: "pointer",
              borderRadius: "0.5rem",
              marginLeft: "1rem !important",
            }}
            className="file-upload-list"
            onClick={(e) => {
              e.stopPropagation();
              handleIconClick(e, currentMailIndex, "delete", mail, mailDetails);
            }}
          >
            <Typography style={{ color: "red" }}>
              <DeleteOutlined
                style={{
                  fontSize: "15px",
                  marginRight: "10px",
                }}
              />
              Delete
            </Typography>
          </div>
        ),
      });
    }

    return items;
  };

  return (
    <div className="current_mail_header">
      <div className="user_mail_profile">
        {getInitial(
          mail?.Sender ||
            mail?.sender?.emailAddress?.address ||
            mail?.owner_email
        )}
      </div>
      <div className="mail_cc_bcc">
        <div>
          {mail?.Sender ||
            mail?.sender?.emailAddress?.address ||
            mail?.owner_email}
        </div>
        <Dropdown
          overlay={
            <Menu style={{ maxWidth: "400px" }}>
              {/* {<div><span>From :</span> {mail?.To}</div>}
              {<p>To : {mail?.To}</p>}
              {mail?.CC && <p>CC : {mail?.CC}</p>}
              {mail?.BCC && <p>BCC : {mail?.BCC}</p>} */}
              <EmailHeader currentMail={mailDetails} />
            </Menu>
          }
          placement="bottomLeft"
          arrow
          trigger={"click"}
        >
          <button onClick={(e) => e.stopPropagation()}>&#11167;</button>
        </Dropdown>
      </div>
      <div className="mail_feature_icons">
        <div className="mail_header_icons">
          <Tooltip title="Click to Reply">
            <Icon
              className="current_mail_icons"
              icon={"tabler:corner-up-left"}
              onClick={(e) => {
                e.stopPropagation();
                handleIconClick(
                  e,
                  currentMailIndex,
                  "reply",
                  mail,
                  mailDetails
                );
              }}
            />
          </Tooltip>
        </div>

        <div className="mail_header_icons">
          <Tooltip title="Click to Forward">
            <Icon
              className="current_mail_icons"
              icon={"tabler:corner-up-right"}
              onClick={(e) => {
                e.stopPropagation();
                handleIconClick(
                  e,
                  currentMailIndex,
                  "forward",
                  mail,
                  mailDetails
                );
              }}
            />
          </Tooltip>
        </div>

        <div className="mail_header_icons">
          <Tooltip title="Click to Reply All">
            <Icon
              className="current_mail_icons"
              icon={"tabler:corner-up-left-double"}
              onClick={(e) => {
                e.stopPropagation();
                handleIconClick(
                  e,
                  currentMailIndex,
                  "replyAll",
                  mail,
                  mailDetails
                );
              }}
            />
          </Tooltip>
        </div>
        <div className="mail_header_icons">
          <Tooltip title="Click to Print">
            <Icon
              className="current_mail_icons"
              icon={"prime:print"}
              onClick={(e) => {
                e.stopPropagation();
                handleIconClick(
                  e,
                  currentMailIndex,
                  "print",
                  mail,
                  mailDetails
                );
              }}
            />
          </Tooltip>
        </div>
        <div className="mail_header_icons">
          <Tooltip title="Click to add Labels">
            <Popover
              placement="bottom"
              title=""
              content={
                <LabelManager
                  setIsLabelOpen={setIsLabelOpen}
                  popoverRef={popoverRef}
                  isLabelModalVisible={isLabelModalVisible}
                  setIsLabelModalVisible={setIsLabelModalVisible}
                  mail={mail}
                  mailDetails={mailDetails}
                  handleIconClick={handleIconClick}
                  currentMailIndex={currentMailIndex}
                  handleSocketEvent={handleSocketEvent}
                />
              }
              trigger="click"
              open={isLabelOpen}
            >
              <div ref={popoverRef}>
                <Icon
                  className="current_mail_icons"
                  icon={"tabler:tag"}
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleIconClick(
                    //   e,
                    //   currentMailIndex,
                    //   "addLabel",
                    //   mail,
                    //   mailDetails
                    // );
                    setIsLabelOpen(true);
                  }}
                />
              </div>
            </Popover>
          </Tooltip>
        </div>
        {currentMailIndex === 0 && (
          <div className="mail_header_icons">
            <Tooltip title="Click to Delete">
              <Icon
                className="current_mail_icons"
                icon={"tabler:trash"}
                onClick={(e) => {
                  e.stopPropagation();
                  handleIconClick(
                    e,
                    currentMailIndex,
                    "delete",
                    mail,
                    mailDetails
                  );
                }}
              />
            </Tooltip>
          </div>
        )}
        {currentMailIndex === 0 && (
          <div className="mail_header_icons">
            <Tooltip title="Click to add Tags">
              <Icon
                className="current_mail_icons"
                icon={"tabler:bookmark"}
                onClick={(e) => {
                  e.stopPropagation();
                  handleIconClick(
                    e,
                    currentMailIndex,
                    "applyTags",
                    mail,
                    mailDetails
                  );
                }}
              />
            </Tooltip>
          </div>
        )}
        {currentMailIndex === 0 && (
          <div className="mail_header_icons">
            <Tooltip title="Click to Archive">
              <Icon
                className="current_mail_icons"
                icon={"tabler:square-arrow-down"}
                onClick={(e) => {
                  e.stopPropagation();
                  handleIconClick(
                    e,
                    currentMailIndex,
                    "archive",
                    mail,
                    mailDetails
                  );
                }}
              />
            </Tooltip>
          </div>
        )}
        {currentMailIndex === 0 && (
          <div className="mail_header_icons">
            <Tooltip title="Click to mark this as Spam">
              <Icon
                className="current_mail_icons"
                icon={"tabler:alert-octagon"}
                onClick={(e) => {
                  e.stopPropagation();
                  handleIconClick(
                    e,
                    currentMailIndex,
                    "spam",
                    mail,
                    mailDetails
                  );
                }}
              />
            </Tooltip>
          </div>
        )}
        {currentMailIndex === 0 && (
          <>
            <div
              className="mail_header_icons"
              onClick={(e) => e.stopPropagation()}
            >
              <Popover
                placement="bottom"
                trigger="click"
                title={"Assigned To"}
                content={<div>Comming Soon..!!</div>}
              >
                <Tooltip title="Assign To">
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      border: "1px solid grey",
                      padding: "2px 8px",
                      borderRadius: "5px",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Assign To
                  </div>
                </Tooltip>
              </Popover>
            </div>
            <div
              className="mail_header_icons"
              onClick={(e) => e.stopPropagation()}
            >
              <Popover
                placement="bottom"
                trigger="click"
                title={<div>Who viewed this mail.</div>}
                content={
                  <div onClick={(e) => e.stopPropagation()}>
                    {Array.isArray(mail?.readBy)
                      ? mail.readBy.length > 0
                        ? mail.readBy.map((data, index) => (
                            <div key={index}>
                              <span style={{ margin: "5px" }}>
                                {index + 1}.
                              </span>{" "}
                              {data}
                            </div>
                          ))
                        : mail?.client
                      : mail?.readBy || mail?.client}
                  </div>
                }
              >
                <Tooltip title="Click to see who viewed this email">
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      border: "1px solid grey",
                      padding: "2px 8px",
                      borderRadius: "5px",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Viewed By
                  </div>
                </Tooltip>
              </Popover>
            </div>
          </>
        )}
      </div>
      <div className="mail_header_icons">
        {/* <Dropdown overlay={<div>Hello</div> } trigger={["click"]}>
          <Tooltip title="More options">
            <Icon
              className="current_mail_icons"
              icon={"tabler:dots-vertical"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Tooltip>
        </Dropdown> */}
        <Dropdown
          menu={{
            items: generateMenuItems(),
          }}
          placement="bottomRight"
          arrow
          trigger={["click"]}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Tooltip title="More options">
            <Icon
              className="current_mail_icons"
              icon={"tabler:dots-vertical"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Tooltip>
        </Dropdown>
      </div>
    </div>
  );
};

const Children = ({
  body,
  replyMailIndex,
  currentMailIndex,
  mail,
  handleReplyClose,
  showComposeModal,
  setShowComposeModal,
  replyComposeData,
  setReplyComposeData,
  mailType,
}) => {
  const [processedHtml, setProcessedHtml] = useState(body);
  const dispatch = useDispatch();

  function searchVessel(inputString, keyword) {
    const regex = new RegExp(`\\b(${keyword})\\b`, "gi"); // Regular expression to match the keyword in a case-insensitive manner
    const matches = inputString.match(regex); // Find all matches

    return matches ? matches : []; // Return matches if found, otherwise return an empty array
  }
  const fieldObject = {
    vessel_name: {
      color: "#4B0082",
      backgroundColor: "#E6E6FA",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          height="17"
          width="17"
        >
          <path d="M4 10.4V4C4 3.44772 4.44772 3 5 3H10V1H14V3H19C19.5523 3 20 3.44772 20 4V10.4L21.0857 10.7257C21.5974 10.8792 21.8981 11.4078 21.7685 11.9261L20.2516 17.9938C20.1682 17.9979 20.0844 18 20 18C19.4218 18 18.8665 17.9019 18.3499 17.7213L19.6 12.37L12 10L4.4 12.37L5.65008 17.7213C5.13348 17.9019 4.5782 18 4 18C3.91564 18 3.83178 17.9979 3.74845 17.9938L2.23152 11.9261C2.10195 11.4078 2.40262 10.8792 2.91431 10.7257L4 10.4ZM6 9.8L12 8L18 9.8V5H6V9.8ZM4 20C5.53671 20 6.93849 19.4223 8 18.4722C9.06151 19.4223 10.4633 20 12 20C13.5367 20 14.9385 19.4223 16 18.4722C17.0615 19.4223 18.4633 20 20 20H22V22H20C18.5429 22 17.1767 21.6104 16 20.9297C14.8233 21.6104 13.4571 22 12 22C10.5429 22 9.17669 21.6104 8 20.9297C6.82331 21.6104 5.45715 22 4 22H2V20H4Z"></path>
        </svg>
      ),
    },
    dwt: { color: "#448aff", icon: "" },
    build_year: { color: "#ff6e40", icon: "" },
    open_area: { color: "#ff4081", icon: "" },
    open_date: { color: "#40c4ff", icon: "" },
    imo: { color: "#2B5179", icon: "" },
    cranes: { color: "#bcaaa4", icon: "" },
    loa: { color: "#e040fb", icon: "" },
    beam: { color: "#ffd740", icon: "" },
    port_cons: { color: "#b0bec5", icon: "" },
    sea_cons: { color: "#536dfe", icon: "" },
    bod: { color: "#ffab40", icon: "" },
    cargo: { color: "#40c4ff", icon: "" },
    cargo_size: { color: "#b0bec5", icon: "" },
    discharge_port: { color: "#536dfe", icon: "" },
    load_port: { color: "#40c4ff", icon: "" },
    load_term: { color: "#ff4081", icon: "" },
    laycan: { color: "#ff6e40", icon: "" },
  };

  const MapIconSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="#165fd4"
        fillRule="evenodd"
        d="m17.861 3.163l.16.054l1.202.4c.463.155.87.29 1.191.44c.348.162.667.37.911.709c.244.338.341.707.385 1.088c.04.353.04.781.04 1.27v8.212c0 .698 0 1.287-.054 1.753c-.056.484-.182.962-.535 1.348a2.25 2.25 0 0 1-.746.538c-.478.212-.971.18-1.448.081c-.46-.096-1.018-.282-1.68-.503l-.043-.014c-1.12-.374-1.505-.49-1.877-.477a2.251 2.251 0 0 0-.441.059c-.363.085-.703.299-1.686.954l-1.382.922l-.14.093c-1.062.709-1.8 1.201-2.664 1.317c-.863.116-1.705-.165-2.915-.57l-.16-.053l-1.202-.4c-.463-.155-.87-.29-1.191-.44c-.348-.162-.667-.37-.911-.71c-.244-.338-.341-.706-.385-1.088c-.04-.353-.04-.78-.04-1.269V8.665c0-.699 0-1.288.054-1.753c.056-.484.182-.962.535-1.348a2.25 2.25 0 0 1 .746-.538c.478-.213.972-.181 1.448-.081c.46.095 1.018.282 1.68.503l.043.014c1.12.373 1.505.49 1.878.477a2.25 2.25 0 0 0 .44-.059c.363-.086.703-.3 1.686-.954l1.382-.922l.14-.094c1.062-.708 1.8-1.2 2.663-1.316c.864-.116 1.706.165 2.916.57m-2.111.943V16.58c.536.058 1.1.246 1.843.494l.125.042c.717.239 1.192.396 1.555.472c.356.074.477.04.532.016a.75.75 0 0 0 .249-.179c.04-.044.11-.149.152-.51c.043-.368.044-.869.044-1.624V7.163c0-.54-.001-.88-.03-1.138c-.028-.239-.072-.328-.112-.382c-.039-.054-.109-.125-.326-.226c-.236-.11-.56-.218-1.07-.389l-1.165-.388c-.887-.296-1.413-.464-1.797-.534m-1.5 12.654V4.434c-.311.18-.71.441-1.276.818l-1.382.922l-.11.073c-.688.46-1.201.802-1.732.994v12.326c.311-.18.71-.442 1.276-.819l1.382-.921l.11-.073c.688-.46 1.201-.802 1.732-.994m-6 3.135V7.42c-.536-.058-1.1-.246-1.843-.494l-.125-.042c-.717-.239-1.192-.396-1.556-.472c-.355-.074-.476-.041-.53-.017a.75.75 0 0 0-.25.18c-.04.043-.11.148-.152.509c-.043.368-.044.87-.044 1.625v8.128c0 .54.001.88.03 1.138c.028.239.072.327.112.382c.039.054.109.125.326.226c.236.11.56.218 1.07.389l1.165.388c.887.295 1.412.463 1.797.534"
        clipRule="evenodd"
      />
    </svg>
  );

  window.handleClick = (keyword) => {
    console.log({ keyword });
    dispatch(setVesselName({ vesselName: keyword, isVisible: true }));
  };

  function highlightKeywords(userInput) {
    try {
      let { html, field } = userInput;
      if (mail[field] && Array.isArray(mail[field])) {
        mail[field]?.forEach((keyword) => {
          const escapedKeyword = searchVessel(mail.Body, keyword);
          const regex = new RegExp(`\\b${escapedKeyword}\\b`, "gi");
          // const icon = fieldObject[field as keyof typeof fieldObject].icon
          const icon = renderToString(fieldObject[field].icon);
          const MapIcon = renderToString(MapIconSvg);
          if (icon && field === "vessel_name") {
            dispatch(setVesselName({ vesselName: keyword, isVisible: false }));
            escapedKeyword.map((data) => {});
            html = html.replace(
              escapedKeyword[0],
              `<span title="${field.replace(/_/g, " ").toUpperCase()}">
              <div style="color:#9400D3; display: inline;">
                <span onclick="handleClick('${keyword}')">${icon}</span>
                <span>${keyword.toUpperCase()}</span>
                <span>
                <button onclick="handleClick('${keyword}')" style="margin: 2px; color: #86549D;">${MapIcon}</button></span>
              </div>
            </span>`
            );

            // return html
          } else {
            html = html.replace(
              keyword,
              `<span  title="${field
                .replace(/_/g, " ")
                .toUpperCase()}" style="display:inline;color:${
                fieldObject[field].color
              };padding:0px 4px;border-radius:5px;font-weight:900" class='tests'>
                <span>${keyword}</span> 
            </span>`
            );
          }
        });
        return html;
      } else {
        if (mail[field] && mail[field].length !== 0) {
          let keywords = mail[field];
          html = html.replace(
            keywords,
            `<span  title="${field
              .replace(/_/g, " ")
              .toUpperCase()}" style="display:inline;color:${
              fieldObject[field].color
            };padding:0px 4px;border-radius:5px;font-weight:900" class='tests'>
              <span>${keywords}</span> 
            </span>`
          );
        }
      }
      return html;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (body) {
      let updatedHtml = body;
      Object.keys(fieldObject).forEach((field) => {
        updatedHtml = highlightKeywords({ html: updatedHtml, field });
      });

      // if (updatedHtml !== processedHtml) {
      setProcessedHtml(updatedHtml);
      // setCurrentMail({ ...mail, Body: updatedHtml });
      // }
    }
  }, [body]);
  return (
    <div className="current_mail_body_box">
      <div className="current_mail_body mail_scrolls">
        {mail?.CUSTOM_LABELS && mail?.CUSTOM_LABELS?.length && (
          <div>
            <div>Labels: </div>
            <div>{mail?.CUSTOM_LABELS?.map((item)=>{
              <div>{item}</div>
            })}</div>
          </div>
        )}
        {/* <shadow-container ref={ref}></shadow-container> */}
        <Tags
          // mail={
          //   currentMailIndex == 0 ? mail : mail?.Threads[currentMailIndex - 1]
          // }
          mail={mail}
        />
        <ShadowContainer>
          <div dangerouslySetInnerHTML={{ __html: processedHtml }} />
        </ShadowContainer>
        {replyMailIndex == currentMailIndex && !showComposeModal && (
          <div
            style={{
              marginTop: "10px",
              border: "1px solid #e3d3d3",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <ComposeReply
              mail={mail}
              handleReplyClose={handleReplyClose}
              showComposeModal={showComposeModal}
              setShowComposeModal={setShowComposeModal}
              replyComposeData={replyComposeData}
              setReplyComposeData={setReplyComposeData}
              mailType={mailType}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const initializeRefs = (length) => {
  return Array.from({ length }).reduce((acc, _, index) => {
    acc[index] = React.createRef();
    return acc;
  }, {});
};

const CurrentMail = ({
  mail,
  handleCurrentMailClose = () => {},
  threads,
  threadIndex = null,
  currentMailIndex,
  threadData,
  allMails,
  setMailData,
  handleDeleteMail,
  setCurrentMail,
  openCurrentMail = false,
}) => {
  const [threadList, setThreadList] = useState([]);
  const [replyMailIndex, setReplyMailIndex] = useState(null);
  const [composeData, setComposeData] = useState(null);
  const [showComposeModal, setShowComposeModal] = useState(false);
  const [replyComposeData, setReplyComposeData] = useState(null);
  const [mailType, setMailType] = useState("");
  const [showApplyTagsModal, setShowApplyTagsModal] = useState(false);
  const [refs, setRefs] = useState(() => initializeRefs(null));
  const collapseRef = useRef(null);
  // const tabContentRefs = useRef({});

  const tabContentRefs = useRef({});
  console.log({ mail });

  const handlePrint = (tabKey) => {
    const contentToPrint = tabContentRefs.current[tabKey];
    if (contentToPrint) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Print</title></head><body>"
      );
      printWindow.document.write(contentToPrint.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      setTimeout(() => {
        printWindow.close();
      }, 1000);
    }
  };

  useEffect(() => {
    if (mail) {
      setComposeDataFromMail(mail);
    }
  }, [mail]);

  const setComposeDataFromMail = (mail) => {
    if (!mail) return;
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    if (mail) {
      let {
        Sender,
        Body,
        Subject,
        GmailMessageId,
        CC,
        BCC,
        ThreadID,
        id,
        to,
        To,
        from,
        From,
        ccRecipients,
        bccRecipients,
        client,
        Attachments,
        _id,
        Threads,
      } = mail;
      let customId = "";
      let parentcurrentMail = { ...mail };
      let mailId = mail.mailClient === "outlook" ? id : GmailMessageId;
      if (mail?.mailClient == "custom") {
        mailId = mail?.message_id;
        customId = mail.id;
      }
      let Tomail =
        mail.mailClient === "outlook"
          ? from?.emailAddress?.address
          : mail?.labelIds?.includes("SENT")
          ? mail.Recipient
          : From
          ? From
          : sendermaillReturn(mail);

      let newClient = mail.client ? mail.client : mail.Client;

      let ccmail;
      if (mail.mailClient === "outlook") {
        if (Array.isArray(ccRecipients)) {
          if (ccRecipients.length !== 0) {
            ccmail = [
              ccRecipients.map((cc) => cc.emailAddress?.address).join(", "),
            ];
          } else {
            ccmail = ccRecipients;
          }
        } else {
          ccmail = [];
        }
      } else {
        if (ccmail) {
          ccmail = Array.isArray(CC) ? CC : CC.length > 0 ? CC.split(",") : [];
        } else {
          ccmail = [];
        }
      }

      let bccmail;
      if (mail.mailClient === "outlook") {
        if (Array.isArray(bccRecipients)) {
          if (bccRecipients.length !== 0) {
            bccmail = [
              bccRecipients.map((bcc) => bcc.emailAddress?.address).join(", "),
            ];
          } else {
            ccmail = bccRecipients;
          }
        } else {
          bccmail = [];
        }
      } else {
        bccmail = Array.isArray(BCC)
          ? BCC
          : BCC?.length > 0
          ? BCC?.split(",")
          : [];
      }
      // extractEmail(mail?.From)
      if (mail.mailClient === "custom") {
        Tomail = parseSender(mail?.From[0]).email;
        customId = mail?.id;
      }
      let payload = {
        to: [extractEmail(Tomail)],
        cc: ccmail,
        bcc: bccmail,
        subject: Subject?.includes(":")
          ? `RE:${Subject?.split(":")[1]}`
          : Subject,
        body: `<br/><br/><br/><p></p> <p></p>${Body}`,
        message_id: mailId,
        owner_email: newClient,
        // mailType: "reply",
        attachments: Attachments,
        _id:
          _id?.length > 0
            ? _id
            : parentcurrentMail?._id
            ? parentcurrentMail?._id
            : id,
      };
      setReplyComposeData(payload);
    }
    let obj = {
      Date: formattedDate,
      to: [],
      cc: [],
      bcc: [],
      body: "",
      subject: "",
      attachments: [],
      //   editorStateKey: EditorState.createEmpty(),
      HTMLBody: undefined,
      owner_email: mail?.Client,
      ThreadID: "",
      GmailMessageId: "",
      listOfFile: [],
      totalSize: 0,
      // uid: uuidv4(),
      _id: null,
    };
  };

  // useEffect(() => {
  //   if (replyMailIndex !== null && refs[replyMailIndex].current) {
  //     refs[replyMailIndex].current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //     });
  //   }
  // }, [replyMailIndex, refs]);

  const ref = useRef(null);

  useEffect(() => {
    if (collapseRef.current && replyMailIndex !== -1) {
      collapseRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [replyMailIndex]);

  useEffect(() => {
    if (ref.current) {
      ref.current.content =
        mail?.Body || mail?.body || mail?.snippet || "No Email Body found!!";
    }
    setReplyMailIndex(null);
  }, [mail]);

  useEffect(() => {
    if (mailType) {
      let payload = { ...replyComposeData };
      if (mailType == "reply") {
        payload["cc"] = [];
        payload["bcc"] = [];
        payload["mailType"] = "reply";
      } else if (mailType === "forward") {
        payload["to"] = [];
        payload["cc"] = [];
        payload["bcc"] = [];
        payload["mailType"] = "forward";
      } else if (mailType == "replyAll") {
        payload[mailType] = "replyAll";
      }
      setReplyComposeData(payload);
    }
  }, [mailType]);

  const [activeKey, setActiveKey] = useState(["0"]);

  // Handle user interaction with the collapse component
  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const dropitems = (
    <Menu>
      {mail?.To && <p>To : {mail?.To}</p>}
      {mail?.CC && <p>CC : {mail?.CC}</p>}
      {mail?.BCC && <p>BCC : {mail?.BCC}</p>}
    </Menu>
  );

  const handleSocketEvent = async (payLoad) => {
    alert(JSON.stringify(payLoad))
    try {
      // Check if webSocketManager is available and WebSocket is open
      const webSocketManager = window?.webSocketManager;
      if (
        webSocketManager &&
        webSocketManager.socket.readyState === WebSocket.OPEN
      ) {
        // Create a unique responseId to track this request
        const responseId = Date.now();

        // Create a promise to handle the delete response
        const responsePromise = new Promise((resolve, reject) => {
          // Define a temporary event handler
          const handleResponse = (response) => {
            if (response?.content?.AcknowledgmentID) {
              if (payLoad?.event === "spamMail") {
                payLoad?.Spam
                  ? openNotificationWithIcon(
                      "success",
                      "Mail successfully marked as spam."
                    )
                  : openNotificationWithIcon(
                      "success",
                      "Email successfully unmarked as spam."
                    );
                setCurrentMail({});
                handleCurrentMailClose();
                const filteredMails = allMails?.filter(
                  (mail) => mail?._id !== payLoad?._id
                );
                setMailData(filteredMails);
              } else if (payLoad?.event === "archivedMail") {
                payLoad?.Archive
                  ? openNotificationWithIcon(
                      "success",
                      "Mail successfully archived."
                    )
                  : openNotificationWithIcon(
                      "success",
                      "Email successfully removed from archive."
                    );
                setCurrentMail({});
                handleCurrentMailClose();
                const filteredMails = allMails?.filter(
                  (mail) => mail?._id !== payLoad?._id
                );
                setMailData(filteredMails);
              } else if (payLoad?.event === "markImportant") {
                payLoad?.important
                  ? openNotificationWithIcon(
                      "success",
                      "Email marked as important."
                    )
                  : openNotificationWithIcon(
                      "success",
                      "Email unmarked as important."
                    );
                // setCurrentMail({});
                // handleCurrentMailClose();
                const filteredMails = allMails?.map((mail) => {
                  let newMail = { ...mail };
                  if (newMail?._id == payLoad?._id) {
                    if (newMail.hasOwnProperty("important")) {
                      // const newMail={...mail}
                      newMail.important = !newMail.important;
                    } else {
                      newMail = { ...newMail, important: true };
                    }
                    setCurrentMail(newMail);
                    return newMail;
                  } else {
                    return newMail;
                  }
                });
                setMailData(filteredMails);
              } else if (payLoad?.event === "markRead") {
                payLoad?.isRead
                  ? openNotificationWithIcon("success", "Email marked as read.")
                  : openNotificationWithIcon(
                      "success",
                      "Email unmarked as read."
                    );
                // setCurrentMail({});
                // handleCurrentMailClose();
                const filteredMails = allMails?.map((mail) => {
                  let newMail = { ...mail };
                  if (newMail?._id == payLoad?._id) {
                    if (newMail.hasOwnProperty("isRead")) {
                      // const newMail={...mail}
                      newMail.isRead = !newMail.isRead;
                    } else {
                      newMail = { ...newMail, isRead: true };
                    }
                    setCurrentMail(newMail);
                    return newMail;
                  } else {
                    return newMail;
                  }
                });
                setMailData(filteredMails);
              } else if (payLoad?.event === "applyLabel") {
                // payLoad?.isRead
                //   ? openNotificationWithIcon("success", "Email marked as read.")
                //   : openNotificationWithIcon(
                //       "success",
                //       "Email unmarked as read."
                //     );
                // setCurrentMail({});
                // handleCurrentMailClose();
                openNotificationWithIcon(
                  "success",
                  "Label updated successfully"
                );
                const filteredMails = allMails?.map((mail) => {
                  let newMail = { ...mail };
                  if (newMail?._id == payLoad?._id) {
                    if (newMail.hasOwnProperty("CUSTOM_LABELS")) {
                      // const newMail={...mail}
                      newMail.CUSTOM_LABELS = payLoad?.labels;
                    } else {
                      newMail = { ...newMail, CUSTOM_LABELS: payLoad?.labels };
                    }
                    setCurrentMail(newMail);
                    return newMail;
                  } else {
                    return newMail;
                  }
                });
                setMailData(filteredMails);
              }
            } else {
              message.error("Please try again.");
            }
            resolve(response);
            // Remove the event listener after handling the response
            webSocketManager.off(payLoad?.event, handleResponse);
          };

          // Register the temporary event handler
          webSocketManager.on(payLoad?.event, handleResponse);
        });

        // Send payload with the unique responseId
        await webSocketManager.send({ ...payLoad, responseId });

        // Await the response promise
        await responsePromise;
      } else {
        message.error("Please try again.");
        console.error("WebSocket is not open or not initialized.");
      }
    } catch (error) {
      console.error("WebSocket error:", error);
      message.error("WebSocket error");
    }
  };

  // const handleSocket = async (payLoad) => {
  //   let response = await handleSocketEvent(payLoad);
  //   console.log("response-->",response)
  //   if (response) {
  //     if (payLoad?.event === "spamMail") {
  //       payLoad?.Spam
  //         ? openNotificationWithIcon(
  //             "success",
  //             "Mail successfully marked as spam."
  //           )
  //         : openNotificationWithIcon(
  //             "success",
  //             "Email successfully unmarked as spam."
  //           );
  //     } else if (payLoad?.event === "archivedMail") {
  //       payLoad?.Archive
  //         ? openNotificationWithIcon("success", "Mail successfully archived.")
  //         : openNotificationWithIcon(
  //             "success",
  //             "Email successfully removed from archive."
  //           );
  //     }
  //     setCurrentMail({});
  //     handleCurrentMailClose();
  //     const filteredMails = allMails?.filter(
  //       (mail) => mail?._id !== payLoad?._id
  //     );
  //     setMailData(filteredMails);
  //   }
  // };

  const handleIconClick = async (event, index, action, mail, mailDetails) => {
    // event.stopPropagation();
    setComposeDataFromMail(mail);
    if (action === "reply") {
      // Temporarily set replyMailIndex to null to force re-render
      setReplyMailIndex(null);
      setMailType("reply");
      setTimeout(() => {
        setReplyMailIndex(index); // Set the reply mail index to the intended value
      }, 0);
    } else if (action === "replyAll") {
      setReplyMailIndex(null);
      setMailType("replyAll");
      setTimeout(() => {
        setReplyMailIndex(index); // Set the reply mail index to the intended value
      }, 0);
    } else if (action === "forward") {
      setReplyMailIndex(null);
      setMailType("forward");
      setTimeout(() => {
        setReplyMailIndex(index); // Set the reply mail index to the intended value
      }, 0);
    } else if (action === "delete") {
      if (mailDetails?.["_id"]) {
        handleDeleteMail({
          GmailMessageId: mailDetails?.GmailMessageId,
          _id: mailDetails?._id,
          client: mailDetails?.client,
        });
      }
    } else if (action === "spam") {
      const payLoad = {
        GmailMessageId: mailDetails?.GmailMessageId,
        _id: mailDetails?._id,
        context: mailDetails?.client,
        Spam: mailDetails?.Spam ? false : true,
        event: "spamMail",
      };
      handleSocketEvent(payLoad);
      // handleSocket(payLoad);
    } else if (action === "archive") {
      const payLoad = {
        GmailMessageId: mailDetails?.GmailMessageId,
        _id: mailDetails?._id,
        context: mailDetails?.client,
        Archive: mailDetails?.Archive ? false : true,
        event: "archivedMail",
      };
      handleSocketEvent(payLoad);
      // handleSocket(payLoad);
    } else if (action === "print") {
      handlePrint(index);
    } else if (action === "applyLabel") {
    } else if (action === "applyTags") {
      setShowApplyTagsModal(true);
    } else if (action === "markAsRead") {
      const payLoad = {
        GmailMessageId: mailDetails?.GmailMessageId,
        _id: mailDetails?._id,
        context: mailDetails?.client,
        isRead: true,
        event: "markRead",
      };
      handleSocketEvent(payLoad);
      // handleSocket(payLoad);
    } else if (action === "markImportant") {
      const payLoad = {
        GmailMessageId: mailDetails?.GmailMessageId,
        _id: mailDetails?._id,
        context: mailDetails?.client,
        important: !mail?.important, // Toggle the important value
        event: "markImportant",
      };
      handleSocketEvent(payLoad);
      // handleSocket(payLoad);
    }

    setActiveKey(() => [...activeKey, `${index}`]);
    if (collapseRef.current && replyMailIndex !== -1) {
      collapseRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const applyTagsEvent = () => {};

  useEffect(() => {
    // const threadss = threadData?.map((mail) => {
    //   if (!mail?.labelIds?.includes("DRAFT")) {
    //     return {
    //       // key: "1",
    //       label: (
    //         <div className="current_mail_header">
    //           <div className="user_mail_profile">
    //             {getInitial(
    //               mail?.Sender ||
    //                 mail?.sender?.emailAddress?.address ||
    //                 mail?.owner_email
    //             )}
    //           </div>
    //           <div className="mail_cc_bcc">
    //             <div>
    //               {mail?.Sender ||
    //                 mail?.sender?.emailAddress?.address ||
    //                 mail?.owner_email}
    //             </div>
    //             <Dropdown overlay={dropitems} placement="bottomLeft" arrow>
    //               <button onClick={handleIconClick}>&#11167;</button>
    //             </Dropdown>
    //           </div>
    //           <div className="mail_feature_icons">
    //             <div className="mail_header_icons">
    //               <Tooltip title="Click to Reply">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"tabler:corner-up-left"}
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     handleIconClick(e);
    //                   }}
    //                 />
    //               </Tooltip>
    //             </div>

    //             <div className="mail_header_icons">
    //               <Tooltip title="Click to Forward">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"tabler:corner-up-right"}
    //                   onClick={handleIconClick}
    //                 />
    //               </Tooltip>
    //             </div>

    //             <div className="mail_header_icons">
    //               <Tooltip title="Click to Reply All">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"tabler:corner-up-left-double"}
    //                   onClick={handleIconClick}
    //                 />
    //               </Tooltip>
    //             </div>
    //             <div className="mail_header_icons">
    //               <Tooltip title="Click to Print">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"prime:print"}
    //                   onClick={handleIconClick}
    //                 />
    //               </Tooltip>
    //             </div>
    //             <div className="mail_header_icons">
    //               <Tooltip title="Click to add Labels">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"tabler:tag"}
    //                   onClick={handleIconClick}
    //                 />
    //               </Tooltip>
    //             </div>
    //             <div className="mail_header_icons">
    //               <Tooltip title="Click to Delete">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"tabler:trash"}
    //                   onClick={handleIconClick}
    //                 />
    //               </Tooltip>
    //             </div>
    //             <div className="mail_header_icons">
    //               <Tooltip title="Click to add Tags">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"tabler:bookmark"}
    //                   onClick={handleIconClick}
    //                 />
    //               </Tooltip>
    //             </div>
    //             <div className="mail_header_icons">
    //               <Tooltip title="Click to Archive">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"tabler:square-arrow-down"}
    //                   onClick={handleIconClick}
    //                 />
    //               </Tooltip>
    //             </div>
    //             <div className="mail_header_icons">
    //               <Tooltip title="Click to mark this as Spam">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"tabler:alert-octagon"}
    //                   onClick={handleIconClick}
    //                 />
    //               </Tooltip>
    //             </div>
    //             <div className="mail_header_icons">
    //               <Tooltip title="More options">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"tabler:dots-vertical"}
    //                   onClick={handleIconClick}
    //                 />
    //               </Tooltip>
    //             </div>
    //           </div>
    //         </div>
    //       ),
    //       children: (
    //         <div className="current_mail_body_box">
    //           <div className="current_mail_body mail_scrolls">
    //             <shadow-container ref={ref}></shadow-container>
    //           </div>
    //           <div className="current_mail_extra_features_tab">
    //             <div className="mail_header_icons">
    //               <Icon
    //                 className="current_mail_icons"
    //                 icon={"tabler:bookmark"}
    //               />
    //             </div>
    //             <div className="mail_header_icons">
    //               <Icon
    //                 className="current_mail_icons"
    //                 icon={"tabler:message-dots"}
    //               />
    //             </div>
    //             <div className="mail_header_icons">
    //               <Icon className="current_mail_icons" icon={"tabler:map"} />
    //             </div>
    //             <div className="mail_header_icons">
    //               <Icon className="current_mail_icons" icon={"tabler:ship"} />
    //             </div>
    //             <div className="mail_header_icons">
    //               <Icon
    //                 className="current_mail_icons"
    //                 icon={"tabler:droplet-dollar"}
    //               />
    //             </div>
    //             <div className="mail_header_icons">
    //               <Icon
    //                 className="current_mail_icons"
    //                 icon={"tabler:calculator"}
    //               />
    //             </div>
    //             {/* <div className="mail_header_icons">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"tabler:alert-octagon"}
    //                 />
    //               </div>
    //               <div className="mail_header_icons">
    //                 <Icon
    //                   className="current_mail_icons"
    //                   icon={"tabler:alert-octagon"}
    //                 />
    //               </div> */}
    //           </div>
    //         </div>
    //       ),
    //     };
    //   }
    // });
    // setThreadList(threadss);

    if (threadData?.length > 0) {
      setActiveKey([`${threadData?.length}`]);
      // setThreadList(threadData);
      setRefs(initializeRefs(threadData?.length + 1));
    } else {
      setActiveKey(["0"]);
      setThreadList([]);
      setRefs(initializeRefs(1));
    }

    // console.log({ threadss });
    // }
  }, [threadData]);

  const saveDraft = async (data) => {
    const url = `${process.env.REACT_APP_MT_URL}mail/draft`;
    try {
      // Make the API call to save the draft
      const response = await postAPICall(url, data, "post");

      // Swal.fire({
      //   title: "Saved!",
      //   text: "Your draft has been saved.",
      //   icon: "success",
      //   confirmButtonColor: "#3085d6",
      // });
      if (response?.msg == "Draft successfully updated ") {
        const updatedAllMails = allMails?.map((item) => {
          if (item._id === data?._id) {
            return { ...item, labelIds: [...(item?.labelIds || []), "DRAFT"] };
          } else {
            return item;
          }
        });
        setMailData(updatedAllMails);
      }

      return response; // Return the response if needed
    } catch (error) {
      console.error("Error saving draft:", error);

      Swal.fire({
        title: "Error!",
        text: "There was an error saving your draft.",
        icon: "error",
        confirmButtonColor: "#3085d6",
      });

      // Optionally, you can rethrow the error or handle it accordingly
      throw error;
    }
  };

  const handleReplyClose = (replyComposeData, mail) => {
    Swal.fire({
      title: "",
      text: "Do you want to save as draft?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Don't Save",
      confirmButtonText: "Save",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // setReplyMailIndex(null);
        // setShowComposeModal(false);
        const draftPayload = {
          ...mail,
          labelIds: [...(mail?.labelIds || []), "DRAFT"],
          Threads: [{ ...replyComposeData, labelIds: ["DRAFT"] }],
        };
        const response = await saveDraft(draftPayload);
        if (response?.msg == "Draft successfully updated ") {
          setReplyMailIndex(null);
          setShowComposeModal(false);
          // setCurrentMail({});
          Swal.fire({
            title: "Saved!",
            text: "Your draft has been saved.",
            icon: "success",
            confirmButtonColor: "#3085d6",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "There was an error saving your draft.",
            icon: "error",
            confirmButtonColor: "#3085d6",
          });
        }
      } else if (result.isDismissed) {
        setReplyMailIndex(null);
        setShowComposeModal(false);
      }
    });
  };

  // const handleReplyClose = (replyComposeData, mail) => {
  //   console.log({ replyComposeData, replyMailIndex, mail });

  //   Swal.fire({
  //     title: "",
  //     text: "Do you want to save as draft?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Save",
  //     cancelButtonText: "Don't Save",
  //     preConfirm: async () => {
  //       // Handle the confirm button click
  //       const draftPayload = {
  //         ...mail,
  //         labelIds: [...(mail?.labelIds || []), "DRAFT"],
  //         Threads: [{ ...replyComposeData, labelIds: ["DRAFT"] }],
  //       };
  //       console.log({ draftPayload });
  //       try {
  //         const response = await saveDraft(draftPayload);
  //         console.log({ response });
  //         if (response?.msg === "Draft successfully updated ") {
  //           setReplyMailIndex(null);
  //           setShowComposeModal(false);
  //           Swal.fire({
  //             title: "Saved!",
  //             text: "Your draft has been saved.",
  //             icon: "success",
  //             confirmButtonColor: "#3085d6",
  //           });
  //         } else {
  //           Swal.fire({
  //             title: "Error!",
  //             text: "There was an error saving your draft.",
  //             icon: "error",
  //             confirmButtonColor: "#3085d6",
  //           });
  //         }
  //       } catch (error) {
  //         console.error('Error handling save draft confirmation:', error);
  //         Swal.fire({
  //           title: "Error!",
  //           text: "There was an error saving your draft.",
  //           icon: "error",
  //           confirmButtonColor: "#3085d6",
  //         });
  //       }
  //     },
  //     preDeny: () => {
  //       // Handle the cancel button click
  //       alert("cancel click")
  //       setReplyMailIndex(null);
  //       setShowComposeModal(false);
  //     }
  //   });
  // };

  return (
    <>
      {mail["_id"] || mail["id"] || mail?.GmailMessageId ? (
        <>
          <div
            className="current_mail_collapse"
            style={{ padding: handleCurrentMailClose ? "14px" : "0px" }}
          >
            <div className="current_mail_subject_header">
              <Flex align="center">
                {openCurrentMail && (
                  <div style={{ marginRight: "14px", cursor: "pointer" }}>
                    <Icon
                      icon="tabler:circle-arrow-left"
                      fontWeight={"500"}
                      fontSize={"35"}
                      onClick={handleCurrentMailClose}
                    />
                  </div>
                )}
                {mail?.Subject && (
                  <h4>
                    <b>Subject : {mail?.Subject}</b>
                  </h4>
                )}
              </Flex>
              {/* <div
                className="current_mail_header_labels"
                style={{
                  marginLeft: handleCurrentMailClose ? "49px" : "0px",
                }}
              >
                {(mail?.MailType ||
                  (mail?.vessel_name && mail.vessel_name.length > 0)) && (
                  <b>Tags: </b>
                )}

                {
                  <Tooltip title="Mail Type">
                    <span className="mail_list_badge">{mail?.MailType}</span>
                  </Tooltip>
                }
                {mail?.vessel_name &&
                  mail?.vessel_name?.length > 0 &&
                  mail?.vessel_name.map((vessel, index) => (
                    <Tooltip title="Vessel Name">
                      <div
                        className="mail_list_labels vessels"
                        style={{ display: "inline" }}
                        key={index}
                      >
                        <span className="mail_list_badge">{vessel}</span>
                      </div>
                    </Tooltip>
                  ))}
              </div> */}
            </div>

            <Collapse
              bordered={false}
              ghost={true}
              // defaultActiveKey={[threadList?.length?threadList?.length?.toString():'0']}
              activeKey={activeKey}
              onChange={onChange}
              // ref={collapseRef}
              // accordion
            >
              <Collapse.Panel
                ref={refs[0]}
                header={
                  <Label
                    mail={mail}
                    dropitems={dropitems}
                    handleIconClick={handleIconClick}
                    currentMailIndex={0}
                    mailDetails={mail}
                  />
                }
                key="0"
              >
                {/* {items?.children} */}
                <div ref={(el) => (tabContentRefs.current[0] = el)}>
                  <Children
                    body={
                      mail?.Body ||
                      mail?.body ||
                      mail?.snippet ||
                      "No Email Body found!!"
                    }
                    currentMailIndex={0}
                    replyMailIndex={replyMailIndex}
                    mail={mail}
                    handleReplyClose={handleReplyClose}
                    showComposeModal={showComposeModal}
                    setShowComposeModal={setShowComposeModal}
                    replyComposeData={replyComposeData}
                    setReplyComposeData={setReplyComposeData}
                    mailType={mailType}
                  />
                </div>
                {/* {replyMailIndex == 0 && (
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      border: "1px solid black",
                    }}
                  >
                    <ComposeReply mail={mail} />
                  </div>
                )} */}
              </Collapse.Panel>
              {mail?.Threads?.length > 0 &&
                mail?.Threads?.map((item, index) => {
                  if (
                    item?.labelIds?.includes("INBOX") &&
                    !item?.labelIds?.includes("DRAFT")
                  ) {
                    return (
                      <Collapse.Panel
                        ref={refs[index]}
                        header={
                          <Label
                            mail={mail}
                            dropitems={dropitems}
                            handleIconClick={handleIconClick}
                            currentMailIndex={index + 1}
                            mailDetails={item}
                            handleSocketEvent={handleSocketEvent}
                          />
                        }
                        key={index + 1}
                      >
                        <div
                          ref={(el) => (tabContentRefs.current[index + 1] = el)}
                        >
                          <Children
                            body={item?.Body || item?.body}
                            currentMailIndex={index + 1}
                            replyMailIndex={replyMailIndex}
                            mail={mail}
                            handleReplyClose={handleReplyClose}
                            showComposeModal={showComposeModal}
                            setShowComposeModal={setShowComposeModal}
                            replyComposeData={replyComposeData}
                            setReplyComposeData={setReplyComposeData}
                            mailType={mailType}
                          />
                        </div>
                      </Collapse.Panel>
                    );
                  }
                })}
            </Collapse>
            {showComposeModal && (
              <Modal
                centered
                // open={maximizedPopup}
                open={showComposeModal}
                closable={false}
                footer={null}
                width={"85vw"}
                wrapClassName="composeModal"
                style={{
                  padding: 0,
                  margin: 0,
                  zIndex: showComposeModal ? 1301 : 1300,
                }}
              >
                <ComposeReply
                  mail={mail}
                  handleReplyClose={handleReplyClose}
                  showComposeModal={showComposeModal}
                  setShowComposeModal={setShowComposeModal}
                  replyComposeData={replyComposeData}
                  setReplyComposeData={setReplyComposeData}
                  mailType={mailType}
                />
              </Modal>
            )}
          </div>
        </>
      ) : (
        <div className="no_current_mail">
          <img src="/images/mailSVG.gif" width={100} />
          <h4>Welcome to OceannMail</h4>
        </div>
      )}
      <ApplyTagsModal
        showApplyTagsModal={showApplyTagsModal}
        setShowApplyTagsModal={setShowApplyTagsModal}
        mail={mail}
        setCurrentMail={setCurrentMail}
        allMails={allMails}
        setMailData={setMailData}
      />
    </>
  );
};

export default CurrentMail;
