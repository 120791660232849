import React, { useState, useEffect, useRef, Suspense, lazy } from "react";
import { Form, Button, Select, Spin, Layout, Drawer, Modal, Alert } from "antd";
import {
  useAsyncError,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  DeleteOutlined,
  EyeOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  SaveOutlined,
  SyncOutlined,
  UnderlineOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import URL_WITH_VERSION, {
  getAPICall,
  postAPICall,
  apiDeleteCall,
  awaitPostAPICall,
  openNotificationWithIcon,
  objectToQueryStringFunc,
  withLocation,
  useStateCallback,
  sanitize,
} from "../../../../shared";
//import Map from "../../../port-to-port/MapIntellegence";
import SpotPrice from "../../../port-to-port/SpotPrice";
import QuickEstimateTCOV from "./QuickEstimateTCOV";
import Attachment from "../../../../shared/components/Attachment";
import Remarks from "../../../../shared/components/Remarks";
import RightBarUI from "../../../../components/RightBarUI";
import PL from "../../../../shared/components/PL/tcov/TcovEstimatePL";
import NormalFormIndex from "../../../../shared/NormalForm/normal_from_index";
//import CargoChildForm from "../cargo/cargo-child";
import Properties from "./Properties";
import TcovReports from "../../../form-reports/TcovReports";
import TcovEstimateDetail from "../../../form-reports/TcovEstimateDetail";
import moment from "moment";
//import AddCargo from "../cargo-contract/index";
import CargoContract from "../cargo-contract";
import PortDistance from "../../../port-to-port/PortAnalytics";
import ShipDistance from "../../../port-to-port/ShipDetails";
import { useTour } from "@reactour/tour";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../../../shared/attachments";
import RepositionAnalysis from "./modals/RepositionAnalysis";
import GenerateCargoEnquiry from "./modals/GenerateCargoEnquiry";
//import VoygenBunkerPlan from "../../../voyage-bunker-plan";
import GenerateTonnageEnquiry from "./modals/GenerateTonnageEnquiry";
import { calculateTotalSummary, calculateTotalaverage } from "../utils";
import MapIntellegence from "../../../port-to-port/MapIntellegence";
import Email from "../../../../components/Email";
import { BunkerDetailsCalculation } from "../../../../shared/NormalForm/normalhelper";
import CargoEnquiryTemplate from "../../../enquire-templates/CargoEnquiryTemplates";
import TonnageEnquiryTemplate from "../../../enquire-templates/TonnageEnquiryTemplates";
import {
  VoyageCIIDynamicsCalculation,
  VoyageEuetsCalculation,
} from "../../../../services/ciiCalculation";

import Cookies from "universal-cookie";
import { stepsobj } from "./Steps";
import ParsedInfo from "../../../voyage-list/components/ParsedInfo";
import PortCongestion from "../../../../../src/routes/port-congestion/index";
import CustomLoader from "../../../../components/Loading/CustomLoader";
import { checkBunkerPriceFromTable } from "../../../../shared/helper";
import LoaderAtCenter from "../../../../components/Loading/LoaderAtCenter";
import EstimateMenu from "../../EstimateMenu";
import redux_state from "../../../../services/redux_state";
import PortInfo from "../port-info";
import MailSpiltView from "../../../inbox/Inbox";
import { toggleCollapsedNav } from "../../../../actions/settingsActions";
import { useDispatch, useSelector } from "react-redux";
const VoygenBunkerPlan = lazy(() =>
  import("../../../voyage-bunker-plan/index")
);

const MemoizedVoygenBunkerPlan = React.memo(VoygenBunkerPlan);
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const FormItem = Form.Item;
const Option = Select.Option;
const { Content } = Layout;
const cookies = new Cookies();

const TCOV = (props) => {
  const istourguide = localStorage.getItem("istourguide");
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } =
    useTour();
  const [showDataLoader, setShowDataLoader] = useState(false);
  const [portInfo, setPortInfo] = useState({
    isVisible: false,
    portData: null,
  });
  const [state, setState] = useStateCallback({
    isBunker: false,
    isweather: false,
    editCargoIndex: -1,
    portItin: [],
    showItemList: false,
    showDiv: false,
    visible: false,
    visibleEstimate: false,
    isVisible: false,
    isShowPortDistance: false,
    estimatePL: false,
    isShowPortRoute: false,
    isShowVoyageBunkerPlan: false,
    selectedPorts: [],
    frmName: "tcov_full_estimate_form",
    formData: { commence_date: dayjs().format("YYYY-MM-DD HH:mm") },
    portData: null,
    reportFormData: {},
    cargoformData: {},
    value: 2,
    cargoContracts: [],
    vesselData: [],
    visibleSchedule: false,
    visibleAttachment: false,
    loading: false,
    fileArr: [],
    fullFormData: {},
    refreshForm: true,
    fixModal: false,
    fixData: {},
    visibleDrawer: false,
    disableSave: false,
    title: undefined,
    loadComponent: undefined,
    width: 1000,
    isShowTcovReports: false,
    isShowEstimateReport: false,
    fieldOptions: undefined,
    visisbleCargoChild: false,
    cargoData: {},
    visibleviewmodal: false,
    attachment: {},
    editMode: true,
    viewData: {},
    isShowShare: false,

    // showLeftBtn: _isShowLeftBtn,
    // showRightBtn: _isShowRightBtn,
    showQuickEstimate: {
      key: "quickview",
      isDropdown: 0,
      withText: "View Quick Est.",
      type: "",
      event: (key, data) => {
        showQuickEstimatePopup(data, true);
      },
    },
    sendBackData: { show: false },
    estimateID: undefined,
    quickFormData: {},
    showQuickEstimateBoolean: false,
    cargosData: props.cargosData || null,
    vmCheck: props.vmCheck !== undefined ? props.vmCheck : false,
    openReposModal: false,
    opencargoenquiryModal: false,
    openTonnageEnquiryModal: false,
    isPortSelected: false,
    BunkerExSpotPrices: null,
    BunkerExFuturePrices: null,
    opencargoenquiryListModal: false,
    openTonnagequiryListModal: false,
    marineData: null,
    showimport: false,
  });
  const [showBunkerAlert, setShowBunkerAlert] = useState(false);

  const storeData = useSelector((store) => store.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentStep(0);
    setSteps(stepsobj[window.location.hash]);
    if (!istourguide) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 2000);

      return () => {
        clearTimeout(timer);
        setIsOpen(false);
      };
    }
  }, []);

  const [sharebtn, setSharebtn] = useState();
  const [shareLink, setShareLink] = useState();

  const [quickdata, setQuickdata] = useState(null);
  const location = useLocation();
  const params = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const _isShowLeftBtn = [
      {
        key: "s1",
        isSets: [
          {
            id: "7",
            key: "menu-fold",
            type: <MenuFoldOutlined />,
            withText: "List",
            showToolTip: true,
            event: "menu-fold",
          },
          {
            id: "1",
            key: "plus",
            type: <PlusOutlined />,
            withText: "Add New",
            showToolTip: true,
            event: () => {
              _onCreateFormData();
            },
          },

          ...(state?.formData?.tcov_status !== "2"
            ? [
                {
                  id: "2",
                  key: "save",
                  type: <SaveOutlined />,
                  withText: "Save",
                  showToolTip: true,
                  event: (key, saveData, innerCB) => {
                    saveFormData(saveData, innerCB);
                  },
                },
              ]
            : []),

          ...(state?.formData?.id && state?.formData?.tcov_status !== "2"
            ? [
                {
                  id: "3",
                  key: "delete",
                  type: <DeleteOutlined />,
                  withText: "Delete",
                  showToolTip: true,
                  event: (key, saveData, innerCB) => {
                    if (saveData["id"] && saveData["id"] > 0) {
                      Modal.confirm({
                        title: "Confirm",
                        content: "Are you sure, you want to delete it?",
                        onOk: () =>
                          deleteData(
                            saveData["id"],
                            saveData["tcov_id"],
                            innerCB
                          ),
                      });
                    }
                  },
                },
              ]
            : []),

          {
            id: "20",
            key: "refresh",
            type: <SyncOutlined />,
            withText: "Refresh",
            showToolTip: true,
            event: () => {
              reFreshForm();
            },
          },
        ],
      },
    ];
    setState((prevState) => ({
      ...prevState,
      showLeftBtn: _isShowLeftBtn,
    }));
  }, [state.formData]);

  const handleTourGuide = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const quickdata = location?.state?.data;
    const fullestimatedatafromquick = location?.state?.fullestimatedata;

    if (quickdata) {
      firstLoadForm(quickdata, fullestimatedatafromquick);
    } else {
      if (
        location.key === "default" &&
        location.pathname === "/add-voyage-estimate"
      ) {
        _onCreateFormData();
      } else {
        editformdata(params.id);
      }
    }
    return () => {
      setState((prevState) => ({
        ...prevState,
        formData: { commence_date: dayjs().format("YYYY-MM-DD HH:mm") },
      }));
    };
  }, [location]);

  let formref = useRef(null);
  let savedataref = useRef(null);
  let fixdataref = useRef(null);
  let cargodataref = useRef(null);
  let allFormDataRef = useRef(null);
  const reFreshForm = () => {
    // setRefreshCounter((prevCounter) => prevCounter + 1);
    if (location.pathname === "/add-voyage-estimate") {
      _onCreateFormData();
    } else {
      editformdata(params.id);
    }
    setState(
      (prevState) => ({
        ...prevState,
        formData: { ...formref.current },
        refreshForm: true,
      }),
      () => {
        setState({ ...state, refreshForm: false });
      }
    );
  };

  useEffect(() => {
    formref.current = Object.assign({}, state.formData);
  }, []);

  const firstLoadForm = async (data, fullestimatedata) => {
    setQuickdata(data);
    const {
      vessel_details,
      estimate_id,
      cargo_details,
      blastformdata,
      loadformdata,
      dischargeformdata,
      reposformdata,
      full_estimate_id,
      id,
    } = data;

    let res = {};
    if (vessel_details.vessel_id != fullestimatedata.vessel_id) {
      res = await updateMainForm(vessel_details);
    } else {
      res["."] = fullestimatedata["."];
      res["-"] = fullestimatedata["-"];
      res["tci_code"] = fullestimatedata["tci_code"];
    }

    let _portItarr = formPortItinerary(data, fullestimatedata);

    _portItarr =
      _portItarr.length > 0
        ? _portItarr
        : [
            {
              port_id: "",
              port: "",
              funct: "",
              s_type: 1,
              wf_per: 10,
              miles: "0.00",
              speed: "0.00",
              eff_speed: "0.00",
              gsd: "0.00",
              tsd: "0.00",
              xsd: "0.00",
              l_d_qty: "0.00",
              l_d_rate: "0.00",
              l_d_rate1: "0.00",
              turn_time: "0.00",
              days: "0.00",
              xpd: "0.00",
              p_exp: "0.00",
              t_port_days: "0.00",
              l_d_term: 1,
              editable: true,
              index: 0,
              ID: -9e6,
            },
          ];

    if (fullestimatedata.hasOwnProperty("fixData")) {
      fixdataref.current = { ...fullestimatedata.fixdata };
    }

    let _formdata = {
      ...fullestimatedata,
      quick_estimate_id: estimate_id,
      quick_id: id,
      vessel_id: vessel_details.vessel_id,
      vessel_code: vessel_details.vessel_code,
      dwt: vessel_details.dwt,
      tci_d_hire: vessel_details.tci_daily_cost,
      add_percentage: vessel_details.tci_add_comm,
      bro_percentage: fullestimatedata?.bro_percentage,
      dwf: vessel_details.wf,
      commence_date: vessel_details.commence_date,
      completing_date: vessel_details?.completed_date,
      total_days: vessel_details.total_voyage_days,
      tcov_id: full_estimate_id,
      mis_cost: vessel_details.other_cost,
      ballast_bonus: vessel_details.blast_bonus,
      bb: vessel_details.other_cost,
      eca_fuel_grade: fullestimatedata.eca_fuel_grade
        ? fullestimatedata.eca_fuel_grade
        : "",
      trade_area: fullestimatedata.trade_area
        ? fullestimatedata.trade_area
        : "",
      tci_code: fullestimatedata.tci_code ? fullestimatedata.tci_code : "",
      //wf: vessel_details?.wf,
      my_company_lob: vessel_details.my_company_lob,
      company_lob: vessel_details.company_lob,
      // ["-"]: vessel_details?.["-"] || [],
      // ["."]: vessel_details?.["."] || [],
      estimate_id: fullestimatedata["estimate_id"],
      id: fullestimatedata?.["id"] ?? "",

      ops_user: fullestimatedata?.["ops_user"]
        ? fullestimatedata?.["ops_user"]
        : "",
      user_name: fullestimatedata?.["user_name"]
        ? fullestimatedata?.["user_name"]
        : "",

      portitinerary: [..._portItarr],
    };

    _formdata["-"] = res["-"];
    _formdata["."] = res["."];

    let _cargos = fullestimatedata?.["cargos"]
      ? [...fullestimatedata["cargos"]]
      : [
          {
            b_commission: cargo_details.commission,
            cp_qty: cargo_details.cp_qty,
            frat_rate: cargo_details.frt_rate,
            frt_type: cargo_details.frt_type,
          },
        ];
    _cargos[0] = {
      ..._cargos[0],
      b_commission: cargo_details.commission,
      cp_qty: cargo_details.cp_qty,
      frat_rate: cargo_details.frt_rate,
      frt_type: cargo_details.frt_type,
    };
    _formdata["cargos"] =
      fullestimatedata || fullestimatedata["estimate_id"]
        ? _cargos
        : [
            {
              b_commission: cargo_details.commission,
              cp_qty: cargo_details.cp_qty,
              frat_rate: cargo_details.frt_rate,
              frt_type: cargo_details.frt_type,
            },
          ];

    // const bunkdata = await checkBunkerByPort(_formdata);

    let totalitenary = calculatetotalitinarysummary(_formdata);
    const totalspeed = calculateTotalaverage(
      "speed",
      "portitinerary",
      _formdata
    );
    const totaleffspeed = calculateTotalaverage(
      "eff_speed",
      "portitinerary",
      _formdata
    );
    totalitenary = {
      ...totalitenary,
      total_speed: totalspeed,
      total_eff_spd: totaleffspeed,
    };

    _formdata = {
      ..._formdata,
      totalitinerarysummary: totalitenary,
    };

    fixdataref.current = fullestimatedata.fixData;

    setState((prevState) => {
      return {
        ...prevState,
        refreshForm: false,
        formData: {
          ...prevState.formData,
          ..._formdata,
        },
        // isPortSelected: bunkdata.isPortSelected,
        // BunkerExSpotPrices: bunkdata.BunkerExSpotPrices,
        // BunkerExFuturePrices: bunkdata.BunkerExFuturePrices,
        // selectedPorts: bunkdata.selectedPorts,
        fixData: { ...fullestimatedata.fixData },
      };
    });
  };

  const calculatetotalitinarysummary = (respData) => {
    let toitinaryfields = [
      "total_distance",
      "total_tsd",
      "total_gsd",
      "total_seca",
      "total_seca_days",
      "total_xsd",
      "total_lq",
      "total_turntime",
      "totalt_port_days",
      "total_xpd",
      "total_exp",
      "total_port_pdays",
    ];

    let fromitinaryfields = [
      "miles",
      "tsd",
      "gsd",
      "seca_length",
      "eca_days",
      "xsd",
      "l_d_qty",
      "turn_time",
      "days",
      "xpd",
      "p_exp",
      "t_port_days",
    ];

    let totalitenary = calculateTotalSummary(
      fromitinaryfields,
      toitinaryfields,
      "portitinerary",
      respData
    );
    return totalitenary;
  };

  const formPortItinerary = (data, fulldata) => {
    let portItinerary = fulldata?.portitinerary
      ? fulldata["portitinerary"]
      : [];

    const {
      vessel_details,
      blasttoloadDistance = 0,
      loadTodischargeDistance = 0,
      dischargetoreposDistance = 0,
      seca_crossed,
    } = data;

    const { ballast_port, load_port, discharge_port, repos_port, wf } =
      vessel_details;
    if (data?.blastformdata.port_name || ballast_port) {
      let portData = data?.blastformdata;

      let portdays = isNaN(portData?.port_days)
        ? 0
        : parseFloat(portData.port_days);
      let xpd = isNaN(portData?.xpd) ? 0 : parseFloat(portData?.xpd);
      let totalportdays = portdays + xpd;
      let fullportdata = fulldata?.["portitinerary"]?.[0] ?? {};
      portItinerary[0] = {
        ...fullportdata,
        days: isNaN(portdays) ? 0 : portdays.toFixed(2),
        funct: portData?.port_func,
        l_d_qty: portData?.ld_qty_unit,
        l_d_rate: portData?.ld_rate_per_day,
        l_d_rate1: portData?.ld_rate_per_hour,
        l_d_term: portData?.term,
        port: ballast_port || portData?.port_name,
        turn_time: portData?.turn_time,
        xpd: isNaN(xpd) ? 0 : xpd.toFixed(2),
        id: -9e6 + portItinerary.length - 3,
        index: portItinerary.length - 3,
        p_exp: portData.port_expense,
        miles: 0,
        t_port_days: isNaN(totalportdays) ? 0 : totalportdays.toFixed(2),
        seca_length: "",
        crossed: "",
      };
    }

    if (data?.loadformdata.port_name || load_port) {
      let portData = data?.loadformdata;
      let portdays = isNaN(portData?.port_days)
        ? 0
        : parseFloat(portData.port_days);
      let xpd = isNaN(portData?.xpd) ? 0 : parseFloat(portData?.xpd);
      let totalportdays = portdays + xpd;
      let fullportdata = fulldata?.["portitinerary"]?.[1] ?? {};

      portItinerary[1] = {
        ...fullportdata,
        days: isNaN(portdays) ? 0 : portdays.toFixed(2),
        funct: portData?.port_func,
        l_d_qty: portData?.ld_qty_unit,
        l_d_rate: portData?.ld_rate_per_day,
        l_d_rate1: portData?.ld_rate_per_hour,
        l_d_term: portData?.term,
        port: load_port || portData?.port_name,
        turn_time: portData?.turn_time,
        xpd: isNaN(xpd) ? 0 : xpd.toFixed(2),
        id: -9e6 + portItinerary.length - 2,
        index: portItinerary.length - 2,
        p_exp: portData.port_expense,
        miles: blasttoloadDistance,
        t_port_days: isNaN(totalportdays) ? 0 : totalportdays.toFixed(2),
        seca_length:
          load_port == fullportdata.port
            ? fullportdata["seca_length"]
            : seca_crossed.blseca,
        crossed:
          load_port == fullportdata.port
            ? fullportdata["crossed"]
            : seca_crossed.blcrossed,
      };
    }
    if (data?.dischargeformdata.port_name || discharge_port) {
      let portData = data?.dischargeformdata;
      let portdays = isNaN(portData?.port_days)
        ? 0
        : parseFloat(portData.port_days);
      let xpd = isNaN(portData?.xpd) ? 0 : parseFloat(portData?.xpd);
      let totalportdays = portdays + xpd;

      let fullportdata = fulldata?.["portitinerary"]?.[2] ?? {};

      portItinerary[2] = {
        ...fullportdata,
        days: isNaN(portdays) ? 0 : portdays.toFixed(2),
        funct: portData?.port_func,
        l_d_qty: portData?.ld_qty_unit,
        l_d_rate: portData?.ld_rate_per_day,
        l_d_rate1: portData?.ld_rate_per_hour,
        l_d_term: portData?.term,
        port: discharge_port || portData?.port_name,
        turn_time: portData?.turn_time,
        xpd: isNaN(xpd) ? 0 : xpd.toFixed(2),
        id: -9e6 + portItinerary.length - 1,
        index: portItinerary.length - 1,
        p_exp: portData.port_expense,
        miles: loadTodischargeDistance,
        t_port_days: isNaN(totalportdays) ? 0 : totalportdays.toFixed(2),
        // seca_length: seca_crossed.ldseca,
        // crossed: seca_crossed.ldcrossed,

        seca_length:
          discharge_port == fullportdata.port
            ? fullportdata["seca_length"]
            : seca_crossed.ldseca,
        crossed:
          discharge_port == fullportdata.port
            ? fullportdata["crossed"]
            : seca_crossed.ldcrossed,
      };
    }
    if (data?.reposformdata.port_name || repos_port) {
      let portData = data?.reposformdata;
      let portdays = isNaN(portData?.port_days)
        ? 0
        : parseFloat(portData.port_days);
      let xpd = isNaN(portData?.xpd) ? 0 : parseFloat(portData?.xpd);
      let totalportdays = portdays + xpd;

      let fullportdata = fulldata?.["portitinerary"]?.[3] ?? {};
      portItinerary[3] = {
        ...fullportdata,
        days: isNaN(portdays) ? 0 : portdays.toFixed(2),
        funct: portData?.port_func,
        l_d_qty: portData?.ld_qty_unit,
        l_d_rate: portData?.ld_rate_per_day,
        l_d_rate1: portData?.ld_rate_per_hour,
        l_d_term: portData?.term,
        port: repos_port || portData?.port_name,
        turn_time: portData?.turn_time,
        xpd: isNaN(xpd) ? 0 : xpd.toFixed(2),
        id: -9e6 + portItinerary.length,
        index: portItinerary.length,
        p_exp: portData.port_expense,
        miles: dischargetoreposDistance,
        t_port_days: isNaN(totalportdays) ? 0 : totalportdays.toFixed(2),
        seca_length:
          repos_port == fullportdata.port
            ? fullportdata["seca_length"]
            : seca_crossed.drseca,
        crossed:
          fullportdata.port === repos_port
            ? fullportdata["crossed"]
            : seca_crossed.drcrossed,
      };
    }
    return portItinerary;
  };

  const autoFillData = async (locationData) => {
    setState(
      (prevState) => ({
        ...prevState,
        formData: locationData,
        refreshForm: true,
      }),
      () =>
        setState((prev) => ({
          ...prev,
          refreshForm: false,
        }))
    );
  };

  const initialLoadData = async () => {
    const { showLeftBtn, formData, showQuickEstimate, showFixMenu } = state;

    let vessel = undefined,
      estimateID = props.estimateID,
      cp_echo = undefined,
      tci_field = undefined,
      diffDays = undefined,
      vessel_id = undefined,
      _showQuickEstimate = undefined,
      _showFixMenu = undefined;
    let response = null,
      respDataC = {},
      respData = {},
      fixData = null,
      editMode = true,
      fieldOptions = undefined,
      _showLeftBtn = Object.assign([], showLeftBtn);
    if (location?.state) {
      estimateID = location?.state?.estimate_id;
    }

    let fullEstData = location?.state;
    if (
      estimateID ||
      (params?.id &&
        location?.pathname === `/edit-voyage-estimate/${params.id}`)
    ) {
      let qParams = { ae: estimateID || params.id };
      let qParamString = objectToQueryStringFunc(qParams);
      response = await getAPICall(
        `${URL_WITH_VERSION}/tcov/edit?${qParamString}`
      );
      respDataC = await response;

      // respData = respDataC["data"];
      // let selectedPorts = [];
      // respData?.portitinerary?.forEach((el) => {
      //   ports.push(el.port);
      //   selectedPorts.push({ id: el.port_id, port_name: el.port });
      // });

      if (respData.tcov_status === 1) {
        delete _showLeftBtn[0]["isSets"][1];

        setState({
          ...state,
          disableSave: true,
        });
      }

      vessel_id =
        respDataC.hasOwnProperty("row") && respDataC["row"] === "quick_estimate"
          ? respData["vesseldetails"]["vessel_id"]
          : respData["vessel_id"];
      // Vessel Details
      // response = await getAPICall(
      //   `${URL_WITH_VERSION}/vessel/list/${vessel_id}`
      // );
      // vessel = await response["data"];

      // Get CP ECHO Data
      response = await getAPICall(
        `${URL_WITH_VERSION}/vessel/cp_echo/${vessel_id}/tcov/${
          estimateID || params.id
        }`
      );
      cp_echo = await response["data"];

      // Get TCI Get Fields
      // response = await getAPICall(
      //   `${URL_WITH_VERSION}/tci/get-fields/${vessel_id}`
      // );
      // tci_field = await response["data"];

      /*
      if (
        respDataC.hasOwnProperty("row") &&
        respDataC["row"] === "quick_estimate"
      ) {
        let _quickDetails = _quickToFullEstimate(respData);
        respData = Object.assign({}, formData, _quickDetails);

        respData["tcov_id"] = _quickDetails["estimate_id"];
        respData["purchase_data"] = cp_echo["."];
        // respData["vessel_code"] = vessel["vessel_code"];
        respData["dwt"] = respDataC.data.vesseldetails.dwt; //vessel["vessel_dwt"];
        // respData["description"] = tci_field["description"];
        respData["hire_rate"] = respDataC.data.vesseldetails.tci_d_hire;
        // respData["tci_code"] = respDataC.data.vesseldetails.tci_code; //tci_field["tci_code"];
        respData["vessel_code"] = respDataC.data.vesseldetails.vessel_code;
      }


 no need of this code
      */
      if (respData && respData.hasOwnProperty("fix")) {
        fixData = respData["fix"];
        delete respData["fix"];
        //respData['disablefield'] = ["tci_code", "my_company_lob"]
        //respData['disablefield'] = ["vessel_id","tci_code","my_company_lob"]
      }

      if (
        respData &&
        respData.hasOwnProperty("tcov_status") &&
        respData["tcov_status"] === 0
      ) {
        _showFixMenu = showFixMenu;
      } else if (
        respData &&
        respData.hasOwnProperty("full_tcov_id") &&
        respData["full_tcov_id"] > 0 &&
        respData.hasOwnProperty("tcov_status") &&
        respData["tcov_status"] === 1
      ) {
        setState({ ...state, editMode: false });
        delete _showLeftBtn[0]["isSets"][1];
        delete _showLeftBtn[0]["isSets"][2];
      }
      respData["-"] =
        respData.hasOwnProperty("-") && respData["-"].length > 0
          ? respData["-"]
          : cp_echo["-"];
      respData["purchase_data"] =
        respData.hasOwnProperty(".") && respData["."].length > 0
          ? respData["."]
          : cp_echo["."];
      respData["."] =
        respData.hasOwnProperty(".") && respData["."].length > 0
          ? respData["."]
          : cp_echo["."]["eco_data"];

      // respData["vessel_code"] = vessel["vessel_code"];
      _showQuickEstimate = showQuickEstimate;
    } else if (
      params.tci_code &&
      location.pathname === "/tci-voy-est/:tci_code"
    ) {
      let qParamString = objectToQueryStringFunc({
        tci_code: params.tci_code,
      });
      response = await getAPICall(
        `${URL_WITH_VERSION}/tci/edit?${qParamString}`
      );
      respData = await response["data"];

      // Vessel Details
      response = await getAPICall(
        `${URL_WITH_VERSION}/vessel/list/${respData["vessel_id"]}`
      );
      vessel = await response["data"];

      // Get CP ECHO Data
      response = await getAPICall(
        `${URL_WITH_VERSION}/vessel/cp_echo/${respData["vessel_id"]}`
      );
      cp_echo = await response["data"];

      // Get TCI Get Fields
      response = await getAPICall(
        `${URL_WITH_VERSION}/tci/get-fields/${respData["vessel_id"]}`
      );
      tci_field = await response["data"];

      diffDays = moment(respData["deliveryterm"][1]["est_gmt"]).diff(
        moment(respData["deliveryterm"][0]["est_gmt"]),
        "days"
      );
      respData = {
        "-": cp_echo["-"],
        ".": cp_echo["."]["eco_data"],
        purchase_data: cp_echo["."],
        vessel_id: respData["vessel_id"],
        vessel_code: vessel["vessel_code"],
        tci_d_hire: vessel["daily_cost"],
        dwt: vessel["vessel_dwt"],
        add_percentage: tci_field["add_percentage"],
        bro_percentage: tci_field["bro_percentage"],
        description: tci_field["description"],
        hire_rate: tci_field["hire_rate"],
        tci_code: tci_field["tci_code"],
        b_port: respData["deliveryterm"][0]["port"],
        repos_port: respData["deliveryterm"][1]["port"],
        repso_days: diffDays,
        total_days: diffDays,
        bb: respData["otherterm"][0]["amount"],
        trade_area: respData["trade_area"],
        my_company_lob: respData["company_fixed_with"],
        company_lob: respData["company_lob"],
      };
      fieldOptions = { tci_code: tci_field["options"] };
    } else if (fullEstData) {
      // Vessel Details
      // response = await getAPICall(
      //   `${URL_WITH_VERSION}/vessel/list/${fullEstData.vesseldetails.vessel_id}`
      // );
      // vessel = await response["data"];

      // Get CP ECHO Data

      response = await getAPICall(
        `${URL_WITH_VERSION}/vessel/cp_echo/${fullEstData.vesseldetails.vessel_id}`
      );
      cp_echo = await response["data"];
      respData["purchase_data"] = cp_echo["."];

      // respData["vessel_code"] = vessel["vessel_code"];

      respData["-"] = respData.hasOwnProperty("-")
        ? respData["-"]
        : cp_echo["-"];
      respData["."] = respData.hasOwnProperty(".")
        ? respData["."]
        : cp_echo["."]["eco_data"];

      if (fullEstData && fullEstData.estimate_id) {
        respData["tcov_id"] = fullEstData.estimate_id;
      }

      const {
        vessel_id,
        reposition_port_name,
        ballast_port_name,
        dwt,
        repos_days,
      } = fullEstData && fullEstData.vesseldetails;
      respData["vessel_id"] = vessel_id;
      respData["repos_port_name"] = reposition_port_name;
      respData["b_port_name"] = ballast_port_name;
      respData["dwt"] = dwt;
      respData["repso_days"] = repos_days;
      const { commence_date, completed_date } =
        fullEstData && fullEstData.voyageresults;
      respData["commence_date"] = commence_date;
      respData["completing_date"] = completed_date;
      let {
        cargo_name,
        commission,
        f_rate,
        f_type,
        other_revenue,
        quantity,
        unit,
      } = fullEstData && fullEstData.cargodetails;

      let cargoObj = {
        cargo: cargo_name,
        frat_rate: f_type == 38 ? f_rate : 0,
        lumsum: f_type == 104 ? f_rate : 0,
        frt_type: f_type,
        extra_rev: other_revenue,
        cp_qty: quantity,
        cp_unit: unit,
        b_commission: commission,
      };

      respData["cargos"] = [{ ...cargoObj }];
    }

    if (props.cargosData) {
      Object.assign(respData, props.cargosData);
    }

    if (!respData.hasOwnProperty("id")) {
      delete _showLeftBtn[0]["isSets"][2];
    }

    if (JSON.stringify(respData) === JSON.stringify({})) {
      respData = Object.assign({}, formData);
    }

    // if (respData && !respData.hasOwnProperty("portitinerary")) {
    //   respData["portitinerary"] = [{ port: "Select Port", index: 0 }];
    // }

    setState(
      (prevState) => ({
        ...prevState,
        showLeftBtn: _showLeftBtn,
        showItemList: respData["id"] ? false : true,
        formData: respData,
        estimatePL: true,
        fixData: fixData,
        fieldOptions: fieldOptions,
        showQuickEstimate: _showQuickEstimate,
        showFixMenu: _showFixMenu,
        estimateID: estimateID || params?.id,
      }),
      () => setState({ ...state, refreshForm: false })
    );
  };

  const voyageBunkerPlan = (showVoyageBunkerPlan, data) => {
    setState((prevState) => ({
      ...prevState,
      isShowVoyageBunkerPlan: showVoyageBunkerPlan,
      //formData: Object.assign({}, data),  // no need of this
    }));
  };

  const checkBunkerByPort = async (respData) => {
    try {
      let ports = [];
      let selectedPorts = [];
      respData?.portitinerary?.forEach((el) => {
        ports.push(el.port);
        selectedPorts.push({ id: el.port_id, port_name: el.port });
      });
      const data = await checkBunkerPriceFromTable(ports);

      return { ...data, selectedPorts };
    } catch (err) {
      console.log("err", err);
    }
  };

  const editformdata = async (
    estid = null,
    quick_estimate_id = null,
    quick_id = null
  ) => {
    let estimateID = estid ? estid : params.id;
    let qParams = { ae: estimateID };
    let fixdata = {};
    let respData;
    if (estimateID) {
      try {
        setState((prevState) => ({ ...prevState, refreshForm: true }));

        let qParamString = objectToQueryStringFunc(qParams);
        let response = await getAPICall(
          `${URL_WITH_VERSION}/tcov/edit?${qParamString}`
        );

        let respDataC = await response;
        respData = respDataC["data"];
        let url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${respData.fix.vessel_name}`;
        let marineData = await getAPICall(url);
        let marinVessel = marineData.filter(
          (item) =>
            item.SHIPNAME.toLowerCase() ===
            respData.fix.vessel_name.toLowerCase()
        );

        if (marinVessel.length) {
          marinVessel = marinVessel[0];
        }

        respData = respDataC["data"];
        let selectedPorts = [];
        respData?.portitinerary?.forEach((el) => {
          // ports.push(el.port);
          selectedPorts.push({ id: el.port_id, port_name: el.port });
        });

        if (quick_estimate_id !== null) {
          respData["quick_estimate_id"] = quick_estimate_id;
        }
        if (quick_id !== null) {
          respData["quick_id"] = quick_id;
        }

        respData["tcov_id"] = respData["estimate_id"];

        // let ports = [];
        // let selectedPorts = [];
        // respData?.portitinerary?.forEach((el) => {
        //   ports.push(el.port);
        //   selectedPorts.push({ id: el.port_id, port_name: el.port });
        // });
        // const bunkdata = await checkBunkerByPort(respData);

        //  Vessel Details
        let response1 = await getAPICall(
          `${URL_WITH_VERSION}/vessel/list/${respData["vessel_id"]}`
        );
        let vessel = await response1["data"];

        let response2 = await getAPICall(
          `${URL_WITH_VERSION}/vessel/cp_echo/${respData["vessel_id"]}/tcov/${respData["estimate_id"]}`
        );

        let cp_echo = await response2["data"];
        respData["purchase_data"] = cp_echo["."];
        respData["vessel_code"] = vessel["vessel_code"];
        respData["vessel_name"] = vessel["vessel_name"];
        respData["-"] =
          respData.hasOwnProperty("-") && respData["-"].length > 0
            ? respData["-"]
            : cp_echo["-"];

        respData["."] =
          respData.hasOwnProperty(".") && respData["."].length > 0
            ? respData["."]
            : cp_echo["."]["eco_data"];

        if (respData.hasOwnProperty("fix")) {
          fixdata = { ...respData.fix };
          delete respData["fix"];
        }

        if (respData["cargos"] && respData["cargos"].length > 0) {
          //updating serial_no
          respData["cargos"].forEach((cargo, index) => {
            cargo.serial_no = index + 1;
          });
        }

        // let toitinaryfields = [
        //   "total_distance",
        //   "total_tsd",
        //   "total_gsd",
        //   "total_seca",
        //   "total_seca_days",
        //   "total_xsd",
        //   "total_lq",
        //   "total_turntime",
        //   "totalt_port_days",
        //   "total_xpd",
        //   "total_exp",
        //   "total_port_pdays",
        // ];

        // let fromitinaryfields = [
        //   "miles",
        //   "tsd",
        //   "gsd",
        //   "seca_length",
        //   "eca_days",
        //   "xsd",
        //   "l_d_qty",
        //   "turn_time",
        //   "days",
        //   "xpd",
        //   "p_exp",
        //   "t_port_days",
        // ];

        // let totalitenary = calculateTotalSummary(
        //   fromitinaryfields,
        //   toitinaryfields,
        //   "portitinerary",
        //   respData
        // );

        let totalitenary = calculatetotalitinarysummary(respData);

        const totalspeed = calculateTotalaverage(
          "speed",
          "portitinerary",
          respData
        );

        const totaleffspeed = calculateTotalaverage(
          "eff_speed",
          "portitinerary",
          respData
        );

        totalitenary = {
          ...totalitenary,
          total_speed: isNaN(totalspeed) ? 0 : totalspeed,
          total_eff_spd: isNaN(totaleffspeed) ? 0 : totaleffspeed,
        };

        let tobunkerarr = [
          "ttl_miles",
          "ttl_seca_length",
          "ttl_eca_days",
          "ttl_speed",
          // "ttl_ec_lsmgo",
          "ttl_ec_cons",
          "ttl_ifo",
          "ttl_vlsfo",
          "ttl_lsmgo",
          "ttl_mgo",
          "ttl_ulsfo",
          "ttl_pc_ifo",
          "ttl_pc_vlsfo",
          "ttl_pc_lsmgo",
          "ttl_pc_mgo",
          "ttl_pc_ulsfo",
        ];

        let frombunkerarr = [
          "miles",
          "seca_length",
          "eca_days",
          "speed",
          "eca_consp",
          "ifo",
          "vlsfo",
          "lsmgo",
          "mgo",
          "ulsfo",
          "pc_ifo",
          "pc_vlsfo",
          "pc_lsmgo",
          "pc_mgo",
          "pc_ulsfo",
        ];

        const totalbunker = calculateTotalSummary(
          frombunkerarr,
          tobunkerarr,
          "bunkerdetails",
          respData
        );

        const fromciiarr = [
          "ifo",
          "vlsfo",
          "lsmgo",
          "mgo",
          "ulsfo",
          "pc_ifo",
          "pc_vlsfo",
          "pc_lsmgo",
          "pc_mgo",
          "pc_ulsfo",
          "co2_emission_total",
        ];

        const tociiarr = [
          "ttl_ifo_con",
          "ttl_vlsfo_con",
          "ttl_lsmgo_con",
          "ttl_mgo_con",
          "ttl_ulsfo_con",
          "ttl_pc_ifo_con",
          "ttl_pc_vlsfo_con",
          "ttl_pc_lsmgo_con",
          "ttl_pc_mgo_con",
          "ttl_pc_ulsfo_con",
          "ttl_co_emi",
        ];

        const totalcii = calculateTotalSummary(
          fromciiarr,
          tociiarr,
          "ciidynamics",
          respData
        );

        const totalciiVal = {
          ttl_vlsfo_con: (
            parseFloat(totalcii["ttl_vlsfo_con"]) +
            parseFloat(totalcii["ttl_pc_vlsfo_con"])
          ).toFixed(2),
          ttl_lsmgo_con: (
            parseFloat(totalcii["ttl_lsmgo_con"]) +
            parseFloat(totalcii["ttl_pc_lsmgo_con"])
          ).toFixed(2),
          ttl_ulsfo_con: (
            parseFloat(totalcii["ttl_ulsfo_con"]) +
            parseFloat(totalcii["ttl_pc_ulsfo_con"])
          ).toFixed(2),
          ttl_mgo_con: (
            parseFloat(totalcii["ttl_mgo_con"]) +
            parseFloat(totalcii["ttl_pc_mgo_con"])
          ).toFixed(2),
          ttl_ifo_con: (
            parseFloat(totalcii["ttl_ifo_con"]) +
            parseFloat(totalcii["ttl_pc_ifo_con"])
          ).toFixed(2),
          ttl_co_emi: totalcii["ttl_co_emi"],
        };

        const fromeuetcs = [
          "sea_ems",
          "port_ems",
          "ttl_ems",
          "sea_ets_ems",
          "port_ets_ems",
          "ttl_eu_ets",
          "ttl_eu_ets_exp",
        ];

        const toeuetcs = [
          "ttl_sea_emi",
          "ttl_port_emi",
          "ttl_emi",
          "ttl_sea_ets_emi",
          "ttl_port_ets_emi",
          "ttl_ets_emi",
          "ttl_eu_ets_emi",
        ];
        const totaleuets = calculateTotalSummary(
          fromeuetcs,
          toeuetcs,
          "euets",
          respData
        );

        let totalVoyDays =
          (totalitenary?.["total_port_pdays"] ?? 0) * 1 +
          (totalitenary?.["total_tsd"] ?? 0) * 1;

        totalVoyDays = totalVoyDays > 0 ? totalVoyDays : respData["total_days"];
        respData["totaleuetssummary"] = { ...totaleuets };
        respData["totalitinerarysummary"] = { ...totalitenary };
        respData["totalbunkersummary"] = { ...totalbunker };
        respData["totalciidynamicssummary"] = { ...totalciiVal };
        respData["total_days"] = isNaN(totalVoyDays)
          ? 0
          : parseFloat(totalVoyDays).toFixed(2);
        formref.current = respData;
        fixdataref.current = { ...fixdata };
        if (respData["tcov_status"] == "1" || respData["tcov_status"] == "2") {
          localStorage.setItem("fixedvessel", JSON.stringify(true));
        }
        setState((prevState) => ({
          ...prevState,
          refreshForm: false,
          estimateID: respData["estimate_id"],
          formData: Object.assign({}, respData),
          fixData: { ...fixdata },
          // isPortSelected: bunkdata.isPortSelected,
          // BunkerExSpotPrices: bunkdata.BunkerExSpotPrices,
          // BunkerExFuturePrices: bunkdata.BunkerExFuturePrices,
          marineData: marinVessel,
          // selectedPorts: bunkdata.selectedPorts,
          selectedPorts: selectedPorts,
        }));
      } catch (err) {
        openNotificationWithIcon("error", err.message, 3);
        setState((prevState) => ({
          ...prevState,
          refreshForm: false,
          formData: Object.assign({}, formref.current),
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        refreshForm: false,
      }));
    }
    return respData;
  };

  const tcovReports = async (showTcovReports) => {
    let estimateID = params.id || state.formData.estimate_id;

    if (showTcovReports) {
      if (estimateID) {
        try {
          let qParamString = objectToQueryStringFunc({
            ae: estimateID,
          });
          // for report Api
          const responseReport = await getAPICall(
            `${URL_WITH_VERSION}/tcov/estimatereport?${qParamString}`
          );
          const respDataReport = await responseReport["data"];
          if (respDataReport) {
            setState(
              (prevState) => ({ ...prevState, reportFormData: respDataReport }),
              () => setState({ ...state, isShowTcovReports: showTcovReports })
            );
          } else {
            openNotificationWithIcon("error", "Unable to show report", 5);
          }
        } catch (err) {
          openNotificationWithIcon("error", "Something went wrong.", 5);
        }
      } else {
        openNotificationWithIcon(
          "error",
          "Please save Voyage Estimate First",
          2
        );
      }
    } else {
      setState({ ...state, isShowTcovReports: showTcovReports });
    }
  };

  const estimateReport = async (showEstimateReport) => {
    let estimateID = params.id || state.formData.estimate_id;
    if (showEstimateReport) {
      if (estimateID) {
        try {
          let qParamString = objectToQueryStringFunc({
            ae: estimateID,
          });
          // for report Api
          const responseReport = await getAPICall(
            `${URL_WITH_VERSION}/tcov/estimatereport?${qParamString}`
          );
          const respDataReport = await responseReport["data"];
          if (respDataReport) {
            setState((prevState) => ({
              ...prevState,
              isShowEstimateReport: showEstimateReport,
              reportFormData: respDataReport,
            }));
          } else {
            openNotificationWithIcon("error", "Unable To Show Report.", 5);
          }
        } catch (err) {
          openNotificationWithIcon("error", "something went wrong", 2);
        }
      } else {
        openNotificationWithIcon(
          "info",
          "Please Save Voyage Estimate First.",
          2
        );
      }
    } else {
      setState({ ...state, isShowEstimateReport: showEstimateReport });
    }
  };

  const showShareEmail = () => {
    try {
      const pageUrl = window.location.href;
      const linkBlob = new Blob([pageUrl], { type: "text/plain" });

      const emailContent = pageUrl;

      setState((prev) => ({ ...prev, isShowShare: true }));
      setSharebtn(linkBlob);
      setShareLink(emailContent);
    } catch (err) {
      console.log("err", err);
    }
  };

  const onclose = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const handleClose = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const onok = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const onCloseDrawer = () =>
    setState({
      ...state,
      visibleDrawer: false,
      title: undefined,
      loadComponent: undefined,
    });

  const onClickRightMenu = async (key, options) => {
    try {
      onCloseDrawer();

      if (!storeData.collapsedNav.collapsedNav) {
        dispatch(toggleCollapsedNav(true));
      }

      let loadComponent = undefined;
      switch (key) {
        // case 'search':showAttachementModal
        //   loadComponent = <RightSearch />;
        //   break;

        case "attachment":
          // const { estimateID } = state;
          const estimateID = params.id;
          if (estimateID) {
            const attachments = await getAttachments(estimateID, "EST");
            const callback = (fileArr) =>
              uploadAttachment(fileArr, estimateID, "EST", "voyage-manager");
            loadComponent = (
              <Attachment
                uploadType="Estimates"
                attachments={attachments}
                onCloseUploadFileArray={callback}
                deleteAttachment={(file) => {
                  deleteAttachment(
                    file.url,
                    file.name,
                    "EST",
                    "voyage-manager"
                  );
                }}
                tableId={0}
              />
            );
          } else {
            openNotificationWithIcon(
              "info",
              "Attachments are not allowed here. Please Save First."
            );
          }
          break;
        case "remark":
          const { formData } = state;
          if (formData && formData.hasOwnProperty("id") && formData["id"] > 0) {
            loadComponent = (
              <Remarks
                remarksID={formData.estimate_id}
                remarkType="tcov"
                idType="tcov_id"
              />
            );
          } else
            openNotificationWithIcon(
              "info",
              "Please save Voyage Estimate First"
            );
          break;
        case "port_route_details":
          portDistance(true, state.formData, key);
          break;
        case "port_distance":
          portDistance(true, state.formData, key);
          break;
        case "properties":
          loadComponent = <Properties />;
          break;
        case "home":
          navigate("/chartering-dashboard");
          break;
        case "pl":
          setState({
            ...state,
            sendBackData: { show: true, options: options },
          });
          break;
        // case 'summary':
        //   loadComponent = <EstimateSummary />;
        //   break;
        // case 'portinformation':
        //   loadComponent = <TcovPortInformation />;
        //   break;
        case "bunker": {
          try {
            setShowDataLoader(true);
            const bunkdata = await checkBunkerByPort(state.formData);
            if (
              bunkdata?.isPortSelected &&
              bunkdata?.BunkerExSpotPrices?.length
            ) {
              setState((prev) => ({
                ...prev,
                isPortSelected: bunkdata.isPortSelected,
                BunkerExSpotPrices: bunkdata.BunkerExSpotPrices,
                BunkerExFuturePrices: bunkdata.BunkerExFuturePrices,
                selectedPorts: bunkdata.selectedPorts,
                isBunker: true,
              }));
              setShowDataLoader(false);
            } else if (
              bunkdata.isPortSelected &&
              !bunkdata.BunkerExSpotPrices.length
            ) {
              openNotificationWithIcon(
                "error",
                "Bunker Information Unavailable for Selected Port"
              );
              setShowDataLoader(false);
            } else if (!state.isPortSelected) {
              openNotificationWithIcon(
                "error",
                "Please select port in port itinerary"
              );
              setShowDataLoader(false);
            }
          } catch (err) {
            console.log("Error in bunker case:", err);
            setShowDataLoader(false);
          }
          break;
          // if (state.isPortSelected && state.BunkerExSpotPrices.length) {
          //   setState((prev) => ({ ...prev, isBunker: true }));
          // } else if (state.isPortSelected && !state.BunkerExSpotPrices.length) {
          //   openNotificationWithIcon(
          //     "error",
          //     "Bunker Information Unavailable for Selected Port"
          //   );
          // } else if (!state.isPortSelected) {
          //   openNotificationWithIcon(
          //     "error",
          //     "Please select port in port itinerary"
          //   );
          // }
          // break;
        }
        case "map": {
          setState((prev) => ({ ...prev, isMap: true }));
          break;
        }
        case "weather": {
          setState((prev) => ({ ...prev, isweather: true }));
          break;
        }
        case "sideMap": {
          if (!state?.marineData?.SHIPNAME) {
            openNotificationWithIcon("info", "Vessel data not found");
            return;
          }
          loadComponent = (
            <Suspense fallback={<Spin />}>
              <ParsedInfo marineData={state.marineData} />
            </Suspense>
          );
          break;
        }
        case "port-congestion": {
          loadComponent = <PortCongestion ports={state.selectedPorts} />;
          break;
        }

        case "mail": {
          loadComponent = <MailSpiltView />;
          break;
        }
      }

      if (loadComponent) {
        setState({
          ...state,
          visibleDrawer: true,
          title: options.title,
          loadComponent: loadComponent,
          width: options.width && options.width > 0 ? options.width : 1000,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const onClickquick = (data) => {
    showQuickEstimatePopup(data, true);
    navigate(`/edit-quick-estimate/${data.estimate_id}`);
  };

  const getCargo = (data) => {
    try {
      let _fullFormData = Object.assign({}, state.fullFormData);

      if (Object.keys(_fullFormData).length === 0) {
        // _fullFormData = state.formData;
        if (allFormDataRef.current !== null) {
          _fullFormData = allFormDataRef.current;
        }
      } else {
        //check & compare with incoming cargo from existig cargos that whether it is added already
        if (_fullFormData.hasOwnProperty("cargos")) {
          // _fullFormData['cargos'].forEach((ele,index)=> {
          //     return ele.serial_no=index+1;
          // });
          const existingCargoes = _fullFormData["cargos"];
          if (
            existingCargoes.some(
              (cargo) => cargo.cargo_contract_id === data.cargo_contract_id
            )
          ) {
            openNotificationWithIcon(
              "info",
              "Seems this Voyage Charter already been added"
            );
            return;
          }
        }
      }

      let _index = 0;
      const _editCargoIndex = state.editCargoIndex;
      if (
        _fullFormData.hasOwnProperty("cargos") &&
        _fullFormData["cargos"].length == 1
      ) {
        if (
          _fullFormData["cargos"][0]["cargo_contract_id"] == "" ||
          !_fullFormData["cargos"][0]["cargo_contract_id"]
        ) {
          _fullFormData["cargos"] = [];
        }
      }

      if (
        _fullFormData.hasOwnProperty("cargos") &&
        _fullFormData["cargos"].length > 1
      ) {
        for (const cargo of _fullFormData["cargos"]) {
          if (cargo["cargo_contract_id"] === data["cargo_contract_id"]) {
            openNotificationWithIcon(
              "info",
              "Cannot import same Voyage Charter Twice."
            );
            return;
          }
        }
        _index = _fullFormData["cargos"].length;
      }
      setState(
        (prevState) => ({
          ...prevState,
          refreshForm: true,
          visisbleCargoChild: false,
          showimport: false,
        }),
        () => {
          let _cargoTermData = {};
          _cargoTermData = {
            sp_type: data["sp_type"],
            serial_no:
              _fullFormData?.["cargos"]?.length > 0
                ? _fullFormData?.["cargos"]?.length + 1
                : 1,
            cargo_contract_id: data["cargo_contract_id"],
            cargo: data["cargo_name"],
            charterer: data["charterer"],
            cp_qty: data["cp_qty"],
            cp_unit: data["cp_unit"],
            opt_percentage: "0.00",
            option_type: undefined,
            frt_type: data["freight_type"],
            frat_rate:
              data["freight_type"] == 38 ? data["freight_rate"] : "0.00",
            lumsum: data["freight_type"] == 104 ? data["freight_rate"] : "0.00",
            b_commission: "0.00",
            extra_rev: "0.00",
            curr: data["currency"],
            dem_rate_pd: "0.00",
            editable: true,
            index: _index,
            ocd: true,
            id: -9e6 + _fullFormData?.["cargos"]?.length,
            opt_percentage: Number(data["booking_no"]),
          };
          if (_editCargoIndex >= 0) {
            const newCargo = {
              ..._cargoTermData,
              index: _editCargoIndex,
              serial_no: _editCargoIndex + 1,
            };

            if (_fullFormData.hasOwnProperty("cargos")) {
              _fullFormData["cargos"][_editCargoIndex] = { ...newCargo };
            } else {
              _fullFormData["cargos"] = [{ ...newCargo }];
            }
          } else {
            if (
              _fullFormData.hasOwnProperty("cargos") &&
              _fullFormData["cargos"].length >= 1
            ) {
              _fullFormData["cargos"].push(_cargoTermData);
            } else {
              _fullFormData["cargos"] = [{ ..._cargoTermData }];
            }
          }

          const tcov_id = state?.formData?.id;
          _fullFormData?.cargos?.forEach((cargo, index) => {
            cargo.tcov_id = tcov_id;
            //bellow id's are needed to update to sync so that delete can work as desired
            cargo.id = -9e6 + index;
            cargo.serial_no = index + 1;
          });

          _fullFormData.cargos = _fullFormData.cargos.map((cargo) => {
            if (isNaN(cargo.id)) {
              const { id, ...cargoWithoutId } = cargo;
              return cargoWithoutId;
            }
            return cargo;
          });

          setState((prevState) => ({
            ...prevState,
            formData: { ...prevState.formData, ..._fullFormData },
            //formData: _fullFormData,
            editCargoIndex: -1,
            fullFormData: {},
            indexData: 0,
            refreshForm: false,
          }));
        }
      );
    } catch (err) {
      console.log("err", err);
    }
  };

  const redirectToAdd = async (e, id = null) => {
    if (id) {
      navigate(`/edit-voyage-charter/${id}`);
    } else navigate(`/add-voyage-charter`);
  };

  const _quickToFullEstimate = (data) => {
    let _vesselDetails = data["vesseldetails"];
    let _cargoDetails = data["cargodetails"];
    let _voyageResults = data["voyageresults"];
    let _fullDetails = {
      estimate_id: data["estimate_id"],
      cargos: [],
      portitinerary: [],
    };

    _fullDetails["vessel_id"] = _vesselDetails["vessel_id"];
    _fullDetails["dwt"] = _vesselDetails["dwt"];
    _fullDetails["tci_d_hire"] = _vesselDetails["tci_d_hire"];
    _fullDetails["add_percentage"] = _vesselDetails["add_percentage"];
    _fullDetails["bro_percentage"] = _vesselDetails["bro_percentage"];
    _fullDetails["bb"] = _vesselDetails["bb"];
    _fullDetails["mis_cost"] = _vesselDetails["other_cost"];
    _fullDetails["b_port_name"] = _vesselDetails["ballast_port_name"];
    _fullDetails["repos_port_name"] = _vesselDetails["reposition_port_name"];
    _fullDetails["repso_days"] = _vesselDetails["repos_days"];
    _fullDetails["commence_date"] = _voyageResults["commence_date"];
    _fullDetails["completing_date"] = _voyageResults["completed_date"];
    _fullDetails["total_days"] = data["voyageresults"]["total_voyage_days"];

    _fullDetails["portitinerary"].push({
      funct: 3,
      port: _vesselDetails["ballast_port_name"],
      port_id: _vesselDetails["ballast_port"],
      passage: 1,
      s_type: 1,
      miles: "0.00",
      wf_per: "0.00",
      eff_speed: "0.00",
      gsd: "0.00",
      tsd: "0.00",
      p_exp: _vesselDetails["port_exp"],
      speed: _vesselDetails["ballast_spd"],
      l_d_qty: _cargoDetails[0]["quantity"],
    });

    //

    _fullDetails["cargos"].push({
      cargo: _cargoDetails[0]["cargo_name"],
      cp_qty: _cargoDetails[0]["quantity"] * 1,
      cp_unit: _cargoDetails[0]["unit"],
      frt_type: _cargoDetails[0]["f_type"],
      frat_rate:
        _cargoDetails[0]["f_type"] == 38 ? _cargoDetails[0]["f_rate"] : 0,
      lumsum:
        _cargoDetails[0]["f_type"] == 104 ? _cargoDetails[0]["f_rate"] : 0,
      b_commission: _cargoDetails[0]["commission"],
      extra_rev: _cargoDetails[0]["other_revenue"],
    });

    return _fullDetails;
  };

  const onClickAddCargo = (data = {}, dType = "purchase") => {
    cargodataref.current = {};
    setState((prevState) => ({
      ...prevState,
      fullFormData: data,
      spType: dType,
      visisbleCargoChild: true,
      showimport: true,
    }));
  };

  const onCancel = () =>
    setState({
      ...state,
      visisbleCargoChild: false,
      showimport: false,
      visibleviewmodal: false,
    });

  const getFormData = (data) => {
    setState({
      ...state,
      formData: data,
      cargoformData: {
        id: 0,
        estimate_id: data["estimate_id"],
      },
    });
  };

  const handlePortInfoDrawer = async (data) => {
    let formData = redux_state.reduxStore?.["tcov_full_estimate_form"];
    const bunkdata = await checkBunkerByPort(formData);
    // if (
    //   bunkdata?.isPortSelected &&
    //   bunkdata?.BunkerExSpotPrices?.length
    // ) {
    //   setState((prev) => ({
    //     ...prev,
    // isPortSelected: bunkdata.isPortSelected,
    // BunkerExSpotPrices: bunkdata.BunkerExSpotPrices,
    // BunkerExFuturePrices: bunkdata.BunkerExFuturePrices,
    // selectedPorts: bunkdata.selectedPorts,
    //     isBunker: true,
    //     formData:formData
    //   }));
    // }

    setPortInfo({
      ...portInfo,
      isVisible: true,
      portData: data ? data : null,
    });
    setState({
      ...state,
      selectedPorts: formData?.portitinerary,
      isPortSelected: bunkdata.isPortSelected,
      BunkerExSpotPrices: bunkdata.BunkerExSpotPrices,
      BunkerExFuturePrices: bunkdata.BunkerExFuturePrices,
      selectedPorts: bunkdata.selectedPorts,
    });
  };

  const getVesselNameFromChild = (data) => {
    showVesselMap(data?.vessel_name);
  };
  const showVesselMap = async (vessel_name) => {
    try {
      let url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${vessel_name}`;
      let marineData = await getAPICall(url);
      let vesselDetails = { SHIPNAME: "", IMO: "" };
      if (marineData?.length) {
        vesselDetails = marineData[0];
        setState({
          ...state,
          visibleDrawer: true,
          title: "Vessel Map",
          marineData: vesselDetails,
          loadComponent: (
            <Suspense fallback={<Spin />}>
              <ParsedInfo marineData={vesselDetails} />
            </Suspense>
          ),
        });
      }
    } catch (err) {
      console.log(err?.message);
    }
  };

  const saveFormData = (postData, innerCB) => {
    try {
      const { frmName, fileArr, formData } = state;

      setState((prevState) => ({ ...prevState, refreshForm: true }));
      let _url = "save";
      let _method = "post";
      let bunkerdetailarr = [];
      let portdatearr = [];
      postData["ciidynamics"] = [];
      postData["euets"] = [];
      let total_tsd = 0;
      let total_tpd = 0;
      let totaldays = 0;

      postData["portitinerary"] =
        postData?.["portitinerary"].length > 0
          ? postData?.["portitinerary"]
          : [{ id: null }];

      postData?.["portitinerary"]?.map((el, ind) => {
        let copyObj = {};
        total_tsd += parseFloat(el.tsd);
        total_tpd += parseFloat(el.t_port_days);

        copyObj = {
          ...postData?.["bunkerdetails"]?.[ind],
          port: el.port,
          miles: el.miles,
          funct: el.funct,
          tsd: el.tsd,
          seca_length: el.seca_length,
          spd_type: el.s_type,
          eca_days: el.eca_days,
          speed: el.speed,
          id:
            formData?.["bunkerdetails"]?.[ind]?.["id"] &&
            formData?.["portitinerary"]?.[ind]?.["id"] > 0
              ? formData?.["bunkerdetails"]?.[ind]?.["id"]
              : -9e6 + ind,
        };

        const values = BunkerDetailsCalculation(postData, ind);
        copyObj = { ...copyObj, ...values };
        bunkerdetailarr.push(copyObj);
      });
      postData["bunkerdetails"] = bunkerdetailarr;
      postData["portitinerary"]?.map((el, ind) => {
        let copyObj2 = {
          ...postData?.["portdatedetails"]?.[ind],
          port: el.port,
          funct: el.funct,
          miles: el.miles,
          passage: el.passage,
          s_type: el.s_type,
          speed: el.speed,
          wf_per: el.wf_per,
          tsd: el.tsd,
          xsd: el.xsd,
          pdays: el.t_port_days,
          id: formData?.["portdatedetails"]?.[ind]?.["id"]
            ? formData?.["portdatedetails"]?.[ind]?.["id"]
            : -9e6 + ind,
        };
        portdatearr.push(copyObj2);
      });
      postData["portdatedetails"] = portdatearr;

      postData["portitinerary"]?.map((el, ind) => {
        let copyObj3 = {};

        const values = BunkerDetailsCalculation(postData, ind);

        copyObj3 = { ...copyObj3, ...values };
        let days = postData?.["portitinerary"]?.[ind]?.["days"] ?? 0;
        let xpd = postData?.["portitinerary"]?.[ind]?.["xpd"] ?? 0;
        let tsd = el["tsd"] ? parseFloat(el["tsd"]).toFixed(2) : 0;
        let opsport = parseFloat(Number(days) + Number(xpd)).toFixed(2);
        let total = tsd * 1 + opsport * 1;
        let date = new Date();
        copyObj3 = {
          ...copyObj3,
          year: date.getFullYear(),
          dst_sailed: el["miles"] ? el["miles"] : "0.00",
          sea: tsd,
          ops_port: parseFloat(opsport).toFixed(2),
          port: el["port"],
          funct: el["funct"],
          passage: postData["portitinerary"][ind]["passage"],
          total: isNaN(total) ? 0 : parseFloat(total).toFixed(2),
          id: formData?.["ciidynamics"]?.[ind]?.["id"]
            ? formData?.["ciidynamics"]?.[ind]?.["id"]
            : -9e6 + ind,
        };
        postData["ciidynamics"].push(copyObj3);

        let cii_calculation_fields = {
          cii_ref: "",
          cii_req: "",
          cii_att: "",
          cii_ret: "",
          cii_band: "",
          cii_pred: "",
          cii_pred_ret: "",
          year: "",
          port: "",
          function: "",
          passage: "",
          sea: "",
          total: "",
          dst_sailed: "",
          ifo: "",
          vlsfo: "",
          lsmgo: "",
          mgo: "",
          pc_ifo: "",
          pc_vlsfo: "",
          pc_lsmgo: "",
          pc_mgo: "",
          pc_ulsfo: "",
          co2_emission_vu: "",
          co2_emission_ml: "",
          co2_emission_ifo: "",
          co2_emission_total: "",
        };
        const ciidynamicsarr = postData["ciidynamics"];
        let cii_values_array = [];
        ciidynamicsarr &&
          ciidynamicsarr.length > 0 &&
          ciidynamicsarr?.map((el, _index) => {
            let cii_field_values = { ...el };

            let dwt = postData["dwt"];

            let cii_values = {
              ...cii_calculation_fields,
              ...cii_field_values,
            };

            let vesselType = cookies.get("typecode");

            let values = VoyageCIIDynamicsCalculation(
              vesselType,
              dwt,
              cii_values
            );

            let updateFields = [
              "co2_emission_vu",
              "co2_emission_ml",
              "co2_emission_ifo",
              "co2_emission_total",
              "cii_ref",
              "cii_req",
              "cii_att",
              "cii_ret",
              "cii_band",
              "cii_pred",
              "cii_pred_ret",
            ];
            updateFields?.map((el) => {
              cii_values[el] = values[el];
            });
            cii_values_array.push(cii_values);
          });
        postData["ciidynamics"] = cii_values_array;
      });

      postData["portitinerary"]?.map((el, ind) => {
        let date = new Date();
        const values = BunkerDetailsCalculation(postData, ind);

        let copyObj4 = {};
        copyObj4 = { ...copyObj4, ...values };

        copyObj4 = {
          ...copyObj4,

          year: date.getFullYear(),
          dst_sailed: el["miles"] ? el["miles"] : "0.00",
          sea: el["tsd"],
          port: el["port"],
          funct: el["funct"],
          is_eur:
            postData?.["portitinerary"]?.[ind]?.["is_eur"] === "False" ? 0 : 1,
          id: formData?.["euets"]?.[ind]?.["id"]
            ? formData?.["euets"]?.[ind]?.["id"]
            : -9e6 + ind,
        };
        postData["euets"].push(copyObj4);

        let euets_calculation_fields = {
          year: "",
          port: "",
          function: "",
          ifo: "",
          vlsfo: "",
          lsmgo: "",
          mgo: "",
          ulsfo: "",
          pc_ifo: "",
          pc_vlsfo: "",
          pc_ulsfo: "",
          pc_lsmgo: "",
          pc_mgo: "",
          ttl_ems: "",
          phase_pre: "",
          sea_pre_ets: "",
          sea_ets_ems: "",
          port_pre_ems: "",
          port_ets_ems: "",
          ttl_eu_ets: "",
          ttl_eu_ets_exp: "",
        };

        const euetsarr = postData["euets"];
        const euets_array = [];
        euetsarr &&
          euetsarr.length > 0 &&
          euetsarr?.map((el, _index) => {
            let euets_field_values = { ...el };
            let euets_values = {
              ...euets_calculation_fields,
              ...euets_field_values,
            };

            let prevPort = _index - 1 >= 0 ? euetsarr[_index - 1]?.is_eur : "";
            let currentPort = euetsarr[_index]?.is_eur ? 1 : 0;

            let values = VoyageEuetsCalculation(
              prevPort,
              currentPort,
              euets_values
            ); // for default value of shipt type='bulk carrier';

            let updateFields = [
              "sea_ems",
              "port_ems",
              "ttl_ems",
              "phase_pre",
              "sea_pre_ets",
              "sea_ets_ems",
              "port_pre_ems",
              "port_ets_ems",
              "ttl_eu_ets",
              "ttl_eu_ets_exp",
            ];
            updateFields?.map((el) => {
              euets_values[el] = values[el];
            });
            euets_array.push(euets_values);
          });
        postData["euets"] = euets_array;
      });

      totaldays = total_tsd + total_tpd;

      if (postData.hasOwnProperty("id") && postData["id"]) {
        _url = "update";
        _method = "put";
      }

      Object.keys(postData)?.forEach((key) => {
        if (postData[key] == null) {
          delete postData[key];
        } else if (postData[key] === "") {
          postData[key] = 0;
        }
      });
      postData["attachments"] = fileArr;

      if (!postData["cp_date"]) {
        postData["cp_date"] = moment(new Date()).format("YYYY-MM-DD HH:mm");
      } else {
        postData["cp_date"] = moment(postData["cp_date"]).format(
          "YYYY-MM-DD HH:mm"
        );
      }

      if (
        postData.hasOwnProperty("bunkerdetails") &&
        !postData["bunkerdetails"].length > 0
      ) {
        delete postData["bunkerdetails"];
      }

      if (postData["cargos"] && postData["cargos"].length > 0) {
        postData["cargos"] = postData["cargos"].filter(
          (el) => el?.cargo_contract_id
        );

        //updating serial_no
        postData["cargos"].forEach((cargo, index) => {
          cargo.serial_no = index + 1;
        });
      }

      const keysToDelete = [
        "vesselInfo",
        "totalitinerarysummary",
        "totalbunkersummary",
        "totalciidynamicssummary",
        "totaleuetssummary",
        "purchase_data",
        "fixData",
        "tci_id",
      ];

      keysToDelete.forEach((key) => {
        if (postData.hasOwnProperty(key)) {
          delete postData[key];
        }
      });

      savedataref.current = JSON.parse(JSON.stringify(postData));

      postData["total_days"] = totaldays
        ? totaldays.toFixed(2)
        : postData["total_days"];

      if (!state.disableSave) {
        try {
          postAPICall(
            `${URL_WITH_VERSION}/tcov/${_url}?frm=${frmName}`,
            postData,
            _method,
            (data) => {
              if (data.data) {
                openNotificationWithIcon("success", data.message);
                if (
                  postData?.portitinerary[0]?.port &&
                  postData?.portitinerary[0]?.port !== "Select Port"
                ) {
                  setTimeout(() => {
                    setShowBunkerAlert(true);
                    setTimeout(() => {
                      setShowBunkerAlert(false);
                    }, 3000);
                  }, 2000);
                }
                setState(
                  (prevState) => ({
                    ...prevState,
                    // refreshForm: false,
                    estimate_id: data?.row?.estimate_id,
                    formData: {
                      ...state.formData,
                      id: data?.row?.id,
                      estimate_id: data?.row?.estimate_id,
                    },
                  }),
                  () => {
                    // editformdata(data?.row?.estimate_id);

                    setTimeout(() => {
                      navigate(
                        `/edit-voyage-estimate/${data.row.estimate_id}`,
                        {
                          replace: true,
                        }
                      );
                    }, 2000);
                  }
                );
              } else {
                setState(
                  (prevState) => ({
                    ...prevState,
                    refreshForm: false,
                    formData: savedataref.current,
                  }),
                  () => {
                    let dataMessage = data.message;
                    let msg = "<div className='row'>";

                    if (typeof dataMessage !== "string") {
                      Object.keys(dataMessage)?.map(
                        (i) =>
                          (msg +=
                            "<div className='col-sm-12'>" +
                            dataMessage[i] +
                            "</div>")
                      );
                    } else {
                      msg += dataMessage;
                    }

                    msg += "</div>";
                    openNotificationWithIcon(
                      "error",
                      <div
                        className="notify-error"
                        dangerouslySetInnerHTML={{ __html: msg }}
                      />
                    );
                  }
                );
              }
            }
          );
        } catch (err) {
          setState((prevState) => ({ ...prevState, refreshForm: false }));
          openNotificationWithIcon("error", "something went wrong.", 2);
        }
      } else {
        openNotificationWithIcon("error", "This Estimate is already fixed");
      }
    } catch (err) {
      console.log("err", err);
      setState((prevState) => ({ ...prevState, refreshForm: false }));
    }
  };

  const deleteData = (id, tcov_id, innerCB) => {
    try {
      apiDeleteCall(
        `${URL_WITH_VERSION}/tcov/delete`,
        { id: id, tcov_id: tcov_id },
        (resData) => {
          if (resData.data === true) {
            openNotificationWithIcon("success", resData.message);
            navigate("/Voyage-Estimate-list");
          } else {
            openNotificationWithIcon("error", resData.message);
          }
        }
      );
    } catch (err) {
      console.log("err", err);
    }
  };

  const isAttachmentOk = () => {
    setState({ ...state, loading: true }, () => {
      setState({ loading: false, visibleAttachment: false });
    });
  };

  const onClickExtraIcon = (action, data) => {
    const { formData } = state;

    try {
      if (data.hasOwnProperty("cargos") && action.gKey == "Cargos") {
        let dataNew =
          data && data["cargos"].length > 0 ? data["cargos"][action.index] : {};

        const preCargos = data.cargos;
        cargodataref.current = dataNew;

        if (dataNew["cargo_contract_id"]) {
          setState(
            (prevState) => ({
              ...prevState,
              viewData: dataNew,
              // editCargoIndex: action.index,
              cargoformData: preCargos,
            }),
            () => {
              setState((prevState) => ({
                ...prevState,
                visisbleCargoChild: true,
                showimport: false,
              }));
            }
          );
        } else {
          openNotificationWithIcon("info", "Please import a cargo first.", 2);
          return;
        }
      } else {
        let delete_id = data && data.id;

        let groupKey = action["gKey"];
        let frm_code = "";
        if (groupKey == "Cargos") {
          groupKey = "cargos";
          frm_code = "tcov_full_estimate_form";
        }
        if (groupKey == "Port Itinerary") {
          groupKey = "portitinerary";
          frm_code = "tcov_port_itinerary";

          // if (formData && formData.portitinerary) {
          //   formData.portitinerary.splice(data.index, 1);

          //   formData.bunkerdetails.splice(data.index, 1);
          //   formData.portdatedetails.splice(data.index, 1);
          //   formData.ciidynamics.splice(data.index, 1);
          //   formData.euets.splice(data.index, 1);
          //   setState((prev) => ({ ...prev, formData: formData }));
          // }
        }
        if (groupKey == "Bunker Details") {
          groupKey = "bunkerdetails";
          frm_code = "tcov_bunker_details";
        }
        if (groupKey == "Port Date Details") {
          groupKey = "portdatedetails";
          frm_code = "tcov_port_date_details";
        }
        if (groupKey == ".") {
          groupKey = ".";
          frm_code = "tcov_full_estimate_form";
        }
        //if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
        if (groupKey && delete_id && frm_code) {
          let data1 = {
            id: delete_id,
            frm_code: frm_code,
            group_key: groupKey,
          };

          postAPICall(
            `${URL_WITH_VERSION}/tr-delete`,
            data1,
            "delete",
            (response) => {
              if (response && response.data) {
                openNotificationWithIcon("success", response.message);
                if (formData && formData.portitinerary) {
                  formData.portitinerary.splice(data.index, 1);
                  formData.bunkerdetails.splice(data.index, 1);
                  formData.portdatedetails.splice(data.index, 1);
                  formData.ciidynamics.splice(data.index, 1);
                  formData.euets.splice(data.index, 1);
                  setState((prev) => ({ ...prev, formData: formData }));
                }
              } else {
                openNotificationWithIcon("error", response.message);
              }
            }
          );

          // setState((prevState) => ({
          //   ...prevState,
          //   //           isShowportExp: true,
          //   //           portexpid: event.eventData.port_id,
          //   //           port_name: event.eventData.port,
          //   //           Pe_arrival: event.eventData.arrival_date_time,
          //   //           pe_departure: event.eventData.departure,
          // }));
        } else if (groupKey == "cargos") {
          let _fullFormData = state.formData;
          let _cargo = _fullFormData["cargos"];
          let _leftcargo = _cargo?.filter(
            (el) => el.cargo_contract_id != data.cargo_contract_id
          );
          _leftcargo =
            _leftcargo?.length > 0
              ? _leftcargo?.forEach(
                  (el, index) => (el["serial_no"] = index + 1)
                )
              : [];
          _fullFormData["cargos"] = [..._leftcargo];
          setState((prevState) => ({
            ...prevState,
            formData: _fullFormData,
          }));
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const onClickFixTCOV = (boolVal) => {
    try {
      if (
        state.formData.tcov_status === "0" ||
        state.formData.tcov_status === "1"
      ) {
        setState((prevState) => ({ ...prevState, fixModal: boolVal }));
      } else {
        openNotificationWithIcon(
          "info",
          "Please Save Voyage Estimate First.",
          3
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const fixVoyage = async () => {
    try {
      let _url = `${URL_WITH_VERSION}/tcov/fix`;
      let response = await awaitPostAPICall(_url, fixdataref.current);
      return response;
    } catch (err) {
      console.log("err", err);
    }
  };

  const performFix = async () => {
    try {
      const { fixData, formData } = state;
      let respData;
      if (
        formData &&
        formData.hasOwnProperty("id") &&
        formData["id"] > 0 &&
        formData["tci_code"] &&
        formData["tci_code"] !== 0
      ) {
        respData = await fixVoyage();
        if (respData["data"]) {
          //openNotificationWithIcon("success", respData.message);

          window.emitNotification({
            n_type: "Voyage Estimate Fixed",
            msg: window.notificationMessageCorrector(
              `Voyage Full Estimate(${formData.estimate_id}) is fixed, for vessel(${formData.vessel_code}), by ${window.userName}`
            ),
          });

          // if schedule success message
          if (respData["message"] && state.formData.tcov_status === "1") {
            navigate(`/voyage-manager/${formData.estimate_id}`, {
              replace: true,
            });
          }

          setState(
            () => ({
              ...state,
              //tcov_status: respData.row.tcov_status,
              fixModal: false,
            }),
            () => {
              editformdata();
            }
          );
        } else {
          let dataMessage = respData.message;
          let msg = "<div className='row'>";

          if (typeof dataMessage !== "string") {
            Object.keys(dataMessage)?.map(
              (i) =>
                (msg +=
                  "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
            );
          } else {
            msg += dataMessage;
          }
          msg += "</div>";
          openNotificationWithIcon(
            "error",
            <div dangerouslySetInnerHTML={{ __html: msg }} />
          );
        }
      } else {
        // openNotificationWithIcon(
        //   "info",
        //   `Please Save the Voyage Estimate First,then Click On Fix.`
        // );
        openNotificationWithIcon(
          "info",
          "Please Select  Head Fix-TCI code .",
          3
        );
      }
      return respData;
    } catch (err) {
      console.log("err", err);
    }
  };

  const createVoyageManger = async () => {
    const data = await fixVoyage();
    if (data.data) {
      navigate(`/voyage-manager/${fixdataref.current["estimate_id"]}`);
    } else {
      openNotificationWithIcon("error", data.message, 2);
    }
  };

  const _onLeftSideListEvent = async (evt) => {
    // const data = await editformdata(evt["estimate_id"]);
    // if (data.hasOwnProperty("id") && data["id"]) {
    //   navigate(
    //     `/edit-voyage-estimate/` +
    //       (evt && evt["estimate_id"] ? evt["estimate_id"] : evt)
    //   );
    // }

    navigate(
      `/edit-voyage-estimate/` +
        (evt && evt["estimate_id"] ? evt["estimate_id"] : evt)
    );
  };

  const updateMainForm = async (formData) => {
    let response = await getAPICall(
      `${URL_WITH_VERSION}/vessel/list/${formData["vessel_id"]}`
    );
    let vessel = await response["data"];

    // Get CP ECHO Data
    response = await getAPICall(
      `${URL_WITH_VERSION}/vessel/cp_echo/${formData["vessel_id"]}`
    );

    let cp_echo = await response["data"];

    // Get TCI Get Fields
    response = await getAPICall(
      `${URL_WITH_VERSION}/tci/get-fields/${formData["vessel_id"]}`
    );

    let tci_field = await response["data"];

    let respData = Object.assign(formData, {
      "-": cp_echo["-"],
      ".": cp_echo["."]["cp_data"],
      // vessel_code: vessel["vessel_code"],
      // tci_d_hire: vessel["daily_cost"],
      // dwt: vessel["vessel_dwt"],
      // add_percentage: tci_field["add_percentage"],
      // bro_percentage: tci_field["bro_percentage"],

      // hire_rate: tci_field["hire_rate"],
      tci_code: tci_field["tci_code"],
    });

    return respData;

    // setState({ ...state, formData: respData }, () =>
    //   setState({ ...state, refreshForm: false })
    // );
  };

  /*

  {
    "cargo_details": {
        "cargo": "Select Cargo",
        "commission": "0.00",
        "cp_qty": "0.00",
        "cp_qty_type": "MT",
        "frt_rate": "0.00",
        "frt_type": 5,
        "frt_unit": "USD",
        "owner": "Select Owner",
        "other_rev": "0.00"
    },
    "co2_view": {
        "total_bunker_cons": "0.00",
        "total_co2": "0.00",
        "euets": "0.00",
        "emmission_mt": "0.00",
        "co2_dwt": "2342412",
        "total_distance": "0.00",
        "transport_work": "0.00",
        "attained_cii": 0,
        "eeoi": 0
    },
    "estimate_id": "",
    "sale_freight": {
        "s_cargo": "Select Cargo",
        "s_commission": "0.00",
        "s_cp_qty": "0.00",
        "s_cp_qty_type": "MT",
        "s_frt_rate": "0.00",
        "s_frt_type": 5,
        "s_frt_unit": "USD",
        "s_other_exp": "0.00"
    },
    "vessel_details": {
        "ballast_port": "Delma Port",
        "ballast_spd": "0.00",
        "bunker_gas_price": "0.00",
        "bunker_oil_price": "0.00",
        "discharge_port": "Singapore",
        "laden_spd": "0.00",
        "load_port": "Mina Zayed",
        "lsmgo_cons_b": 0,
        "lsmgo_cons_l": 0,
        "lsmgo_fuel": 7,
        "port_days": 4,
        "repos_port": "Khor Fakkan",
        "routing": "1",
        "sea_days": 0,
        "tci_add_comm": "12",
        "tci_bro_comm": "1",
        "tci_daily_cost": "12",
        "vessel_name": "FSO AFRICA",
        "vlsfo_cons_b": 0,
        "vlsfo_cons_l": 0,
        "vlsfo_fuel": 5,
        "vessel_code": "FSORIC2408",
        "vessel_id": 50,
        "dwt": "2342412",
        "total_voyage_days": "4.00",
        "completed_date": "2024-08-31 10:19:18"
    },
    "voyage_results": {
        "daily_profit": "-10.44",
        "gross_income": "0.00",
        "net_income": "0.00",
        "other_expenses": "0.00",
        "total_expenses": "41.76",
        "total_port_expenses": "0.00",
        "total_profit": "-41.76",
        "commence_date": "2024-08-27T04:48:41.090Z",
        "completed_date": "2024-08-31 10:18:41",
        "total_voyage_days": "4.00",
        "total_bunker_expenses": 0,
        "vessel_hire": 0,
        "tce": 0
    },
    "co2_calc_value": {
        "vlsfo_fuel_multiplier": 3.15,
        "lsmgo_fuel_multiplier": 3.15,
        "ballast_port_eu_country": false,
        "load_port_eu_country": false,
        "redelivery_port_eu_country": false,
        "repos_port_eu_country": false,
        "discharge_port_eu_country": false
    },
    "blastformdata": {
        "port_expense": 0
    },
    "loadformdata": {
        "port_expense": 0
    },
    "dischargeformdata": {
        "port_expense": 0
    },
    "reposformdata": {
        "port_expense": 0
    },
    "blasttoloadDistance": 0,
    "loadTodischargeDistance": 0,
    "dischargetoreposDistance": 0,
    "blastPortExpenses": 0,
    "loadPortExpenses": 0,
    "dischargePortExpenses": 0,
    "reposPortExpenses": 0,
    "new_id": "25aa4df0-4f8c-4537-99e4-63b40ec503ff"
}





*/

  const triggerEvent = (data) => {
    const { sendBackData, estimatePL } = state;
    if (data && sendBackData["show"] === true) {
      // data["estimatePL"] = true;
      let objdata = { ...data };
      if (!objdata.hasOwnProperty("bunkerdetails")) {
        const bunkerconsp = objdata["portitinerary"].map((el, ind) => {
          const values = BunkerDetailsCalculation(objdata, ind);
          return values;
        });
        objdata["bunkerdetails"] = bunkerconsp;
      }
      onCloseDrawer();
      let loadComponent = (
        <PL
          formData={objdata}
          viewTabs={["Estimate View"]}
          estimatePL={estimatePL}
        />
      );
      setState({
        ...state,
        visibleDrawer: true,
        title: sendBackData?.options?.title,
        loadComponent: loadComponent,
        width:
          sendBackData?.options?.width > 0 ? sendBackData.options.width : 1200,
        sendBackData: { show: false },
      });
    }
  };

  const showQuickEstimatePopup = (sd, bolVal) =>
    setState(
      (prevState) => ({
        ...prevState,
        estimateID: sd["estimate_id"],
        quickFormData: sd,
      }),
      () => setState({ ...state, showQuickEstimateBoolean: bolVal })
    );

  const portDistance = (val, data, key) => {
    if (key == "port_route_details") {
      if (
        data &&
        data.hasOwnProperty("portitinerary") &&
        data["portitinerary"].length > 0
      ) {
        setState(
          (prevState) => ({
            ...prevState,
            portData: data.portitinerary[0],
            portItin: data.portitinerary,
          }),
          () => setState({ ...state, isShowPortRoute: val })
        );
      } else {
        setState({ ...state, isShowPortRoute: val });
      }
    } else {
      if (key == "port_distance") {
        setState({ ...state, isShowPortDistance: val });
      }
    }
  };

  const openRepositionAnalysis = (bool) => {
    if (bool) {
      setState((prevState) => ({ ...prevState, openReposModal: true }));
    }
  };

  const opencargoenquiry = (bool) => {
    if (bool) {
      setState((prevState) => ({ ...prevState, opencargoenquiryModal: true }));
    }
  };

  const openTonnageenquiry = (bool) => {
    if (bool) {
      setState((prevState) => ({
        ...prevState,
        openTonnageEnquiryModal: true,
      }));
    }
  };

  const _onCreateFormData = () => {
    setState(
      (prevState) => ({
        ...prevState,
        refreshForm: true,
        formData: Object.assign({}, {}),
      }),
      () => {
        setState((state) => ({
          ...state,
          refreshForm: false,
          estimateID: null,
          formData: { commence_date: dayjs().format("YYYY-MM-DD HH:mm") },
        }));
      }
    );
    navigate("/add-voyage-estimate");
  };

  const updataCPPrice = (plandata) => {
    let consFuelarr = state.formData?.["."];
    let arr = [];

    consFuelarr?.map((fuelarr, index) => {
      let obj = Object.assign({}, fuelarr);
      let value1 = CpfromBunkerPlan(plandata, obj["fuel_type"]);
      obj["cp_price"] = value1;
      arr.push(obj);
    });

    let _formdata = Object.assign({}, state.formData);
    _formdata["."] = [...arr];
    setState(
      (prevstate) => ({
        ...prevstate,
        isShowVoyageBunkerPlan: false,
      }),
      () => {
        saveFormData(_formdata);
      }
    );
  };

  const CpfromBunkerPlan = (planarr, consFueltype) => {
    let price = 0;
    planarr?.map((el, ind) => {
      if (el.type === consFueltype) {
        price = el?.end_prc;
      }
    });
    price = price ?? "0.00";
    return price;
  };

  const sendCargotonnage = (isFlag) => {
    setState((prev) => ({
      ...prev,
      opencargoenquiryListModal: isFlag,
    }));
  };

  const sendTempleteTonnage = (isFlag) => {
    setState((prev) => ({
      ...prev,
      openTonnagequiryListModal: isFlag,
    }));
  };

  const { viewData } = state;
  return (
    <div>
      {showDataLoader && <LoaderAtCenter />}
      {state.showDiv === false ? (
        <div className="tcov-wrapper full-wraps">
          <Layout className="layout-wrapper">
            <Layout>
              <Content className="content-wrapper">
                <div className="fieldscroll-wrap">
                  <div className="body-wrapper">
                    <article className="article">
                      <h4 className="cust-header-title">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <b>
                            Voyage Estimate Full Estimation: &nbsp;
                            {state.formData.estimate_id
                              ? state.formData.estimate_id
                              : ""}
                          </b>
                          <EstimateMenu data={"voy-estimate"} />
                        </div>
                      </h4>
                      <div className="box box-default">
                        <div
                          className="box-body common-fields-wrapper"
                          // key={refreshCounter}
                        >
                          {state.refreshForm === false ? (
                            <NormalFormIndex
                              key={"key_" + state.frmName + "_0"}
                              formClass="label-min-height"
                              formData={state.formData}
                              showForm={true}
                              frmCode={state.frmName}
                              addForm={true}
                              editMode={state.editMode}
                              getVesselNameFromChild={getVesselNameFromChild}
                              handlePortInfoDrawer={handlePortInfoDrawer}
                              // saveFormData={saveFormData}
                              showToolbar={[
                                {
                                  isLeftBtn: state.showLeftBtn,

                                  isRightBtn: [
                                    {
                                      key: "rbs1",
                                      isSets: [
                                        {
                                          key: "tour_guide",
                                          isDropdown: 0,
                                          withText: "Start The Tour Guide",
                                          showToolTip: true,
                                          type: "Tour Guide",
                                          menus: null,
                                          event: () => {
                                            handleTourGuide();
                                          },
                                        },

                                        {
                                          key: "reposition_analysis",
                                          isDropdown: 1,
                                          withText:
                                            "To Perform Reposition Analysis",
                                          showToolTip: true,
                                          type: "Reposition Analysis",

                                          menus: [
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Reposition Analysis",
                                              modalKey: "reposition-analysis",

                                              event: (key) => {
                                                openRepositionAnalysis(true);
                                              },
                                            },
                                          ],
                                        },
                                        {
                                          key: "quick_est",
                                          isDropdown: 0,
                                          withText: "To View Quick Estimates",
                                          showToolTip: true,
                                          type: "View Quick Est.",

                                          menus: null,
                                          modalKey: "",
                                          event: (key, data) => {
                                            if (
                                              data["quick_estimate_id"] &&
                                              data["quick_id"]
                                            ) {
                                              navigate(
                                                `/edit-quick-estimate/` +
                                                  data["quick_estimate_id"],
                                                {
                                                  state: {
                                                    data: {
                                                      ...data,
                                                      fixData: {
                                                        ...state.fixData,
                                                      },
                                                    },
                                                    fullEstimate: true,
                                                    quickdata: quickdata,
                                                  },
                                                }
                                              );
                                            } else {
                                              navigate(`/quick-estimate`, {
                                                state: {
                                                  data: {
                                                    ...data,
                                                    fixData: {
                                                      ...state.fixData,
                                                    },
                                                  },
                                                  fullEstimate: true,
                                                  quickdata: quickdata,
                                                },
                                              });
                                            }
                                          },
                                        },

                                        {
                                          key: "add_cargo",
                                          isDropdown: 0,
                                          withText: "To Add Cargo Details",
                                          showToolTip: true,
                                          type: "Add Cargo",
                                          menus: null,
                                          modalKey: "add_cargo",
                                          event: (key, data) => {
                                            if (
                                              state.formData.tcov_status == "2"
                                            ) {
                                              openNotificationWithIcon(
                                                "info",
                                                "Estimate is already Scheduled",
                                                3
                                              );
                                            } else {
                                              onClickAddCargo(data, "sale");
                                            }
                                          },
                                        },
                                        {
                                          key: "bunker_plan",
                                          isDropdown: 0,
                                          withText:
                                            "To Create Or View Bunker Plans",
                                          showToolTip: true,
                                          type: "Bunker Plan",
                                          menus: null,
                                          modalKey: "",
                                          event: (key, data) => {
                                            voyageBunkerPlan(true, data);
                                          },
                                        },

                                        // {
                                        //   key: "ai_tools",
                                        //   isDropdown: 0,
                                        //   withText: "AI",
                                        //   type: "",
                                        //   menus: null,
                                        //   modalKey: "",
                                        //   event: (key, data) => {},
                                        // },

                                        // {
                                        //   key: "benchmarking",
                                        //   isDropdown: 0,
                                        //   withText: "Benchmarking",
                                        //   type: "",
                                        //   menus: null,
                                        //   modalKey: "benchmarking",
                                        //   event: (key, data) => { },
                                        // },
                                        {
                                          key: "sendcargo",
                                          isDropdown: 1,
                                          withText:
                                            "To Make Cargo Or Tonnage Enquiries",
                                          showToolTip: true,
                                          type: "Cargo/Tonnage Enquiry",
                                          menus: [
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Template Cargo Order",
                                              modalKey: "template-cargo-order",
                                              event: (key) => {
                                                sendCargotonnage(true);
                                              },
                                            },
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Template Tonnage Order",
                                              modalKey:
                                                "template-tonnage-order",
                                              event: (key) => {
                                                sendTempleteTonnage(true);
                                              },
                                            },
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Generate Cargo Enquiry",
                                              modalKey:
                                                "generate-cargo-enquiry",
                                              event: (key) => {
                                                opencargoenquiry(true);
                                              },
                                            },
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Generate Tonnage Enquiry",
                                              modalKey:
                                                "generate-tonnage-enquiry",
                                              event: (key) => {
                                                openTonnageenquiry(true);
                                              },
                                            },
                                          ],
                                        },

                                        // {
                                        //   key: "send_firm_offer",
                                        //   isDropdown: 0,
                                        //   withText: "Send Firm Offer",
                                        //   type: "",
                                        //   menus: null,
                                        //   modalKey: "send_firm_offer",
                                        //   event: (key, data) => {},
                                        // },

                                        // {
                                        //   key: "draw_cp",
                                        //   isDropdown: 0,
                                        //   withText: "Draw CP",
                                        //   type: "",
                                        //   menus: null,
                                        //   modalKey: "draw_cp",
                                        //   event: (key, data) => { },
                                        // },
                                        {
                                          key: "share",
                                          isDropdown: 0,
                                          withText:
                                            "To Share This Voyage Estimate",
                                          showToolTip: true,
                                          type: "Share",
                                          menus: null,
                                          modalKey: "Share",
                                          event: (key, data) => {
                                            if (state.formData.tcov_id) {
                                              showShareEmail(data);
                                            } else {
                                              openNotificationWithIcon(
                                                "info",
                                                "Please save the form first",
                                                3
                                              );
                                            }
                                          },
                                        },

                                        state?.formData?.tcov_status !== "2" &&
                                          state?.formData["tcov_status"] ===
                                            "0" && {
                                            key: "fix",
                                            isDropdown: 0,
                                            withText: "Fix",
                                            type: "",
                                            menus: null,
                                            event: () => {
                                              if (
                                                state.formData[
                                                  "tcov_status"
                                                ] === "0"
                                              ) {
                                                Modal.confirm({
                                                  title: "Confirm",
                                                  content:
                                                    "Are you sure, you want to Fix it?",
                                                  onOk: () =>
                                                    onClickFixTCOV(true),
                                                });
                                              }
                                            },
                                          },

                                        state?.formData?.tcov_status !== "2" &&
                                          state?.formData["tcov_status"] ===
                                            "1" && {
                                            key: "schedule",
                                            isDropdown: 0,
                                            withText: "Schedule",
                                            type: "",
                                            menus: null,
                                            event: () => {
                                              if (
                                                state.formData[
                                                  "tcov_status"
                                                ] === "1"
                                              ) {
                                                Modal.confirm({
                                                  title: "Confirm",
                                                  content:
                                                    "Are you sure, you want to Schedule it?",
                                                  onOk: () =>
                                                    createVoyageManger(),
                                                });
                                              }
                                            },
                                          },

                                        {
                                          key: "reports",
                                          isDropdown: 1,
                                          withText:
                                            "To View And Generate Reports",
                                          type: "Reports",
                                          showToolTip: true,
                                          menus: [
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Estimate Details Report",
                                              modalKey: null,
                                              event: (key) =>
                                                estimateReport(true),
                                            },
                                            // {
                                            //   href: null,
                                            //   icon: null,
                                            //   label: "Download Report",
                                            //   modalKey: null,
                                            //   event: (key) => tcovReports(true),
                                            // },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                  isResetOption: false,
                                },
                              ]}
                              inlineLayout={true}
                              sideList={{
                                showList: true,
                                title: "VOY-EST List",
                                uri: "/tcov/list?l=0",
                                columns: [
                                  "estimate_id",
                                  "vessel_name",
                                  "tcov_status",
                                ],
                                icon: true,
                                rowClickEvent: (evt) =>
                                  _onLeftSideListEvent(evt),
                              }}
                              isShowFixedColumn={[
                                "Port Date Details",
                                "Bunker Details",
                                "Port Itinerary",
                                "Cargos",
                                "Total Itinerary Details",
                                "CII Dynamics",
                                "EU ETS",
                                ".",
                                "Total Bunker Details",
                              ]}
                              tabEvents={[
                                {
                                  tabName: "Bunker Details",
                                  event: {
                                    type: "copy",
                                    from: "Port Itinerary",
                                    page: "tcov",
                                    fields: {
                                      port_id: "port_id",
                                      port: "port",
                                      funct: "funct",
                                      passage: "passage",
                                      speed: "speed",
                                      miles: "miles",
                                      seca_length: "seca_length",
                                      eca_days: "eca_days",
                                      s_type: "spd_type",
                                      tsd: "tsd",
                                    },
                                    showSingleIndex: false,
                                    group: {
                                      to: "----------",
                                      from: "---------",
                                    },
                                    otherCopy: [
                                      {
                                        gKey: "portdatedetails",
                                        tgKey: "bunkerdetails",
                                        type: "arrDep",
                                      },
                                    ],
                                  },
                                },
                                {
                                  tabName: "Port Date Details",
                                  event: {
                                    type: "copy",
                                    from: "Port Itinerary",
                                    page: "tcov",
                                    fields: {
                                      port_id: "port_id",
                                      port: "port",
                                      funct: "funct",
                                      passage: "passage",
                                      speed: "speed",
                                      miles: "miles",
                                      wf_per: "wf_per",
                                      tsd: "tsd",
                                      xsd: "xsd",
                                      days: "days",
                                      xpd: "xpd",
                                      //  t_port_days: "pdays",
                                      s_type: "s_type",
                                    },
                                    showSingleIndex: false,
                                    group: {
                                      to: "-----------",
                                      from: "---------",
                                    },
                                  },
                                },

                                {
                                  tabName: "CII Dynamics",
                                  event: {
                                    type: "copy",
                                    from: "Port Itinerary",
                                    page: "tcov",
                                    fields: {
                                      port: "port",
                                      funct: "funct",
                                      // ifo: "ifo",
                                      // vlsfo: "vlsfo",
                                      // ulsfo: "ulsfo",
                                      // lsmgo: "lsmgo",
                                      // mgo: "mgo",
                                      // pc_ifo: "pc_ifo",
                                      // pc_vlsfo: "pc_vlsfo",
                                      // pc_ulsfo: "pc_ulsfo",
                                      // pc_lsmgo: "pc_lsmgo",
                                      // pc_mgo: "pc_mgo",
                                    },
                                    showSingleIndex: false,
                                  },
                                },

                                {
                                  tabName: "EU ETS",
                                  event: {
                                    type: "copy",
                                    from: "Port Itinerary",
                                    page: "tcov",
                                    fields: {
                                      port: "port",
                                      funct: "funct",
                                      // ifo: "ifo",
                                      // vlsfo: "vlsfo",
                                      // ulsfo: "ulsfo",
                                      // lsmgo: "lsmgo",
                                      // mgo: "mgo",
                                      // pc_ifo: "pc_ifo",
                                      // pc_vlsfo: "pc_vlsfo",
                                      // pc_ulsfo: "pc_ulsfo",
                                      // pc_lsmgo: "pc_lsmgo",
                                      // pc_mgo: "pc_mgo",
                                    },
                                    showSingleIndex: false,
                                  },
                                },
                              ]}
                              showSideListBar={state.showItemList}
                              tableRowDeleteAction={(action, data) => {
                                if (
                                  state.formData.tcov_status == "2" &&
                                  action.gKey == "Cargos"
                                ) {
                                  openNotificationWithIcon(
                                    "info",
                                    "Estimate is  Scheduled.",
                                    3
                                  );
                                  return;
                                } else {
                                  onClickExtraIcon(action, data);
                                }
                              }}
                              extraTableButton={{
                                Cargos: [
                                  {
                                    icon: <EyeOutlined />,
                                    onClickAction: (action, data) => {
                                      onClickExtraIcon(action, data);
                                    },
                                  },
                                ],
                                "Port Itinerary": [
                                  {
                                    icon: <PlusOutlined />,
                                    internal: true,
                                    callType: "ADD_BETWEEN",
                                    inLastRow: false,
                                  },
                                ],
                              }}
                              updateMainForm={updateMainForm}
                              sendBackData={state.sendBackData}
                              triggerEvent={triggerEvent}
                            />
                          ) : (
                            <div className="col col-lg-12">
                              {/* <Spin tip="Loading...">
                                <Alert
                                  message=" "
                                  description="Please wait..."
                                  type="info"
                                />
                              </Spin> */}
                              <CustomLoader />
                            </div>
                          )}
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </Content>
            </Layout>

            <RightBarUI
              pageTitle="tcov-righttoolbar"
              callback={(data, options) => onClickRightMenu(data, options)}
              showBunkerAlert={showBunkerAlert}
              setShowBunkerAlert={setShowBunkerAlert}
            />
          </Layout>
          {state.loadComponent !== undefined &&
          state.title !== undefined &&
          state.visibleDrawer === true ? (
            <Drawer
              title={state.title}
              placement="right"
              closable={true}
              onClose={onCloseDrawer}
              open={state.visibleDrawer}
              getContainer={false}
              style={{ position: "absolute" }}
              width={state.width}
              maskClosable={false}
              className="drawer-wrapper-container"
            >
              {state.loadComponent}
            </Drawer>
          ) : undefined}

          {portInfo?.isVisible && (
            <Drawer
              title="Port Information"
              placement="right"
              closable={true}
              onClose={() => setPortInfo({ ...portInfo, isVisible: false })}
              open={portInfo?.isVisible}
              getContainer={false}
              style={{ position: "absolute" }}
              // width={state.width}
              maskClosable={false}
              className="drawer-wrapper-container"
            >
              <div className="tcov-wrapper">
                <div className="layout-wrapper scrollHeight">
                  <div className="content-wrapper noHeight">
                    {/* {state.loadComponent} */}
                    <PortInfo
                      portInfo={portInfo}
                      ports={state.selectedPorts}
                      BunkerExSpotPrices={state.BunkerExSpotPrices}
                    />
                    {/* <PortInfo portInfo={portInfo} ports={state.selectedPorts} state={state}/> */}
                  </div>
                </div>
              </div>
            </Drawer>
          )}

          {/*   


            no need of thiss
          {state.visibleviewmodal ? (
            <Modal
              style={{ top: "2%" }}
              title={
                state.viewData && state.viewData.sp_type == 187
                  ? "VC (Purchase)"
                  : "Add Voy.Contract (Cargo)"
              }
              open={state.visibleviewmodal}
              onCancel={() => setState({ ...state, visibleviewmodal: false })}
              width="95%"
              footer={null}
            >
              <CargoContract
                formData={state.viewData}
                isShowAddButton={false}
                fullEstimate={true}
                modalCloseEvent={onCancel}
              />
            </Modal>
          ) : undefined}


           */}

          {state.isShowVoyageBunkerPlan ? (
            <Modal
              className="page-container"
              style={{ top: "2%" }}
              title="Voyage Bunker Plan"
              open={state.isShowVoyageBunkerPlan}
              //onOk={handleOk}
              onCancel={() => voyageBunkerPlan(false)}
              width="90%"
              footer={null}
              maskClosable={false}
            >
              <MemoizedVoygenBunkerPlan
                data={state?.formData?.bunkerdetails}
                updatecp={(data) => updataCPPrice(data)}
              />
            </Modal>
          ) : undefined}

          {state.visisbleCargoChild ? (
            <Modal
              style={{ top: "2%" }}
              open={state.visisbleCargoChild}
              title="Cargo Contract"
              onCancel={onCancel}
              footer={null}
              width={"90%"}
              maskClosable={false}
            >
              <CargoContract
                formData={cargodataref.current}
                showPendingList={{ cargo_status: "ENQUIRY" }}
                isShowImport={state.showimport}
                isShowAddButton={false}
                fullEstimate={true}
                getCargo={(data) => getCargo(data)}
              />{" "}
            </Modal>
          ) : undefined}

          {state.isShowPortDistance ? (
            <Modal
              style={{ top: "2%" }}
              title="Port Distance"
              open={state.isShowPortDistance}
              // onOk={handleOk}
              onCancel={() => portDistance(false, {}, "port_distance")}
              width="95%"
              footer={null}
            >
              <PortDistance />
            </Modal>
          ) : undefined}
          {state.stateisShowPortRoute ? (
            <Modal
              style={{ top: "2%" }}
              title="Port Route Details"
              open={state.isShowPortRoute}
              // onOk={handleOk}
              onCancel={() => portDistance(false, {}, "port_route_details")}
              width="100%"
              footer={null}
            >
              <ShipDistance
                data={state.portData}
                is_port_to_port={true}
                portItin={state.portItin}
                parentFormData={state.formData}
              />
            </Modal>
          ) : undefined}

          {state.fixModal ? (
            <Modal
              style={{ top: "2%" }}
              open={state.fixModal}
              title="Create Voyage"
              onCancel={() => onClickFixTCOV(false)}
              footer={null}
              width="40%"
              maskClosable={false}
            >
              <div>
                <div className="body-wrapper">
                  <article className="article">
                    <div className="box box-default">
                      <div className="box-body">
                        <Form>
                          <FormItem {...formItemLayout} label="Vessel">
                            <input
                              type="text"
                              className="ant-input"
                              name="vessel"
                              value={state.fixData["vessel_name"]}
                              readOnly
                            />
                          </FormItem>

                          <FormItem {...formItemLayout} label="Operation Type">
                            <input
                              type="text"
                              className="ant-input"
                              name="opt_type"
                              value={state.fixData["ops_type_name"]}
                              readOnly
                            />
                          </FormItem>

                          <FormItem {...formItemLayout} label="Commence Date">
                            <input
                              type="text"
                              className="ant-input"
                              name="commence_date"
                              value={state.fixData["commence_date"]}
                              readOnly
                            />
                          </FormItem>

                          <FormItem {...formItemLayout} label="Completed Date">
                            <input
                              type="text"
                              className="ant-input"
                              name="completed_date"
                              value={state.fixData["completed_date"]}
                              readOnly
                            />
                          </FormItem>

                          <div className="action-btn text-right">
                            <Button
                              type="primary"
                              onClick={() => {
                                performFix();
                              }}
                            >
                              Ok
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </Modal>
          ) : undefined}
        </div>
      ) : undefined}

      {state.isShowTcovReports ? (
        <Modal
          style={{ top: "2%" }}
          title="Reports"
          open={state.isShowTcovReports}
          onCancel={() => tcovReports(false)}
          width="95%"
          footer={null}
        >
          <TcovReports data={state.reportFormData} />
        </Modal>
      ) : undefined}

      {state.openReposModal ? (
        <Modal
          style={{ top: "2%" }}
          title="Reposition Analysis"
          open={state.openReposModal}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, openReposModal: false }))
          }
          width="85%"
          footer={null}
        >
          <RepositionAnalysis formdata={state.formData} />
        </Modal>
      ) : undefined}

      {state.opencargoenquiryModal ? (
        <Modal
          style={{ top: "2%" }}
          title="Generate Cargo Enquiry"
          open={state.opencargoenquiryModal}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              opencargoenquiryModal: false,
            }))
          }
          width="70%"
          footer={null}
        >
          <GenerateCargoEnquiry />
        </Modal>
      ) : undefined}

      {state.openTonnageEnquiryModal ? (
        <Modal
          style={{ top: "2%" }}
          title="Tonnage Enquiry Template"
          open={state.openTonnageEnquiryModal}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              openTonnageEnquiryModal: false,
            }))
          }
          width="70%"
          footer={null}
        >
          <GenerateTonnageEnquiry />
        </Modal>
      ) : undefined}

      {state.isShowEstimateReport ? (
        <Modal
          style={{ top: "2%" }}
          title="Voyage Estimate Detail Report"
          open={state.isShowEstimateReport}
          onCancel={() => estimateReport(false)}
          width="95%"
          footer={null}
        >
          <TcovEstimateDetail data={state.reportFormData} />
        </Modal>
      ) : undefined}

      {state.showQuickEstimateBoolean ? (
        <Modal
          style={{ top: "2%" }}
          title="Voyage Profit & Loss Report"
          open={state.showQuickEstimateBoolean}
          onCancel={() => showQuickEstimatePopup(null, false)}
          width="95%"
          footer={null}
        >
          <QuickEstimateTCOV
            key="qck-est"
            // changeState={(e, data) => changeState(false, data)}
            estimateID={state.estimateID}
            formData={state.quickFormData}
          />
        </Modal>
      ) : undefined}
      {state.isBunker && (
        <Modal
          style={{ top: "2%" }}
          title=""
          open={state.isBunker}
          onCancel={() => setState((prev) => ({ ...prev, isBunker: false }))}
          width="80%"
          footer={null}
        >
          <SpotPrice
            BunkerExSpotPrices={state.BunkerExSpotPrices}
            BunkerExFuturePrices={state.BunkerExFuturePrices}
          />
        </Modal>
      )}

      {state.isMap && (
        <Modal
          style={{ top: "2%" }}
          title="Map Report"
          open={state.isMap}
          onCancel={() => setState((prev) => ({ ...prev, isMap: false }))}
          width="90%"
          footer={null}
        >
          <MapIntellegence />
        </Modal>
      )}

      {state.isweather && (
        <Modal
          style={{ top: "2%" }}
          title="Weather Report"
          open={state.isweather}
          onCancel={() => setState((prev) => ({ ...prev, isweather: false }))}
          width="90%"
          footer={null}
        >
          {/* <Map /> */}

          <iframe
            src="https://www.ventusky.com/?p=19.1;72.9;5&l=radar"
            title="Report Section"
            style={{ width: "100%", height: "100vh" }}
            frameborder="0"
            allowFullScreen={true}
          />
        </Modal>
      )}

      {state.isShowShare && (
        <Modal
          title="New Message"
          open={true}
          onCancel={() => onclose()}
          onOk={() => onok()}
          footer={null}
        >
          <Email
            title={window.corrector(
              `TCOV Estimate Detail || ${state.formData.tcov_id}`
            )}
            attachmentFile={sharebtn}
            // link={shareLink}
            showpage={true}
            condition={false}
            shareLink={shareLink}
            handleClose={handleClose}
          />
        </Modal>
      )}

      {state.opencargoenquiryListModal ? (
        <Modal
          style={{ top: "2%" }}
          title="Cargo Enquiry Template List"
          open={state.opencargoenquiryListModal}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              opencargoenquiryListModal: false,
            }))
          }
          width="70%"
          footer={null}
        >
          <CargoEnquiryTemplate />
        </Modal>
      ) : undefined}

      {true ? (
        <Modal
          style={{ top: "2%" }}
          title="Tonnage Enquiry Template List"
          open={state.openTonnagequiryListModal}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              openTonnagequiryListModal: false,
            }))
          }
          width="70%"
          footer={null}
        >
          <TonnageEnquiryTemplate />
        </Modal>
      ) : undefined}
    </div>
  );
};

export default TCOV;
