import React from "react";

const MarineModal = ({ moreInfo }) => {
    return (
        <div className='data-row more-Info-Modal'>
            <table>
                <tbody>
                    <tr>
                        <td><span>Vessel Name :</span></td>
                        <td><span>{moreInfo.vessel_name ? moreInfo.vessel_name : "N/A"}</span></td>
                    </tr>
                    <tr>
                        <td><span>Vessel IMO : </span></td>
                        <td><span>{moreInfo.imo_no ? moreInfo.imo_no : "N/A"}</span></td>
                    </tr>
                    <tr>
                        <td><span>MMSI Code : </span></td>
                        <td><span>{moreInfo.mmsi_number ? moreInfo.mmsi_number : "N/A"}</span></td>
                    </tr>
                    <tr>
                        <td><span>Status : </span></td>
                        <td><span>{moreInfo.status ? moreInfo.status.toUpperCase() : "N/A"}</span></td>
                    </tr>
                    <tr className="bg-voilet font-black">
                        <td><span>Latitude :</span></td>
                        <td><span>{moreInfo.last_pos_lat ? moreInfo.last_pos_lat : "N/A"}</span></td>
                    </tr>
                    <tr className="bg-voilet font-black">
                        <td><span>Longitude : </span></td>
                        <td><span>{moreInfo.last_pos_lon
                            ? moreInfo.last_pos_lon
                            : "N/A"}</span></td>
                    </tr>
                    <tr>
                        <td><span>Last Port : </span></td>
                        <td><span>{moreInfo.last_port
                            ? moreInfo.last_port
                            : "N/A"}</span></td>
                    </tr>
                    <tr>
                        <td><span>Next Port : </span></td>
                        <td><span>{moreInfo.next_port
                            ? moreInfo.next_port
                            : "N/A"}</span></td>
                    </tr>
                    <tr>
                        <td><span>Destination : </span></td>
                        <td><span>{moreInfo.destination
                            ? moreInfo.destination
                            : "N/A"}</span></td>
                    </tr>
                    <tr>
                        <td><span>Speed: </span></td>
                        <td><span>{moreInfo.speed ? moreInfo.speed : "N/A"}</span></td>
                    </tr>
                    <tr>
                        <td><span>Current Port : </span></td>
                        <td><span>{moreInfo.current_port_name ? moreInfo.current_port_name : "N/A"}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default MarineModal