import React, { useEffect } from "react";
import { Form, Input, DatePicker, Checkbox } from "antd";
import moment from "moment";
import URL_WITH_VERSION, {
  awaitPostAPICall,
  getAPICall,
  openNotificationWithIcon,
  useStateCallback,
} from "../../../../shared";
import SOARecapReport from "../../../../shared/components/All-Print-Reports/SOARecapReport";
import dayjs from "dayjs";
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const ConfirmStatementAccountRecap = (props) => {
  const [state, setState] = useStateCallback({
    showForm: false,
    showInvoice: false,
    invoiceData: {},
    statementAC: {
      tci_id: props.tciID || 0,
      tci_code: props.tcID || 0,
      vessel_id: props.vesselID || 0,
      vessel_name: undefined,
      chartrer_id: props.chartrerID || 0,
      chartrer_name: undefined,
      date_from: props.from || null,
      date_to: props.to || null,
      account_head: {
        TCIH: true,
        TCIADCOM: true,
        TCIBCOM: true,
        TCIBB: true,
        TCIILOHC: true,
        TCIIHC: true,
        BD: true,
        TCIREA: true,
        BR: true,
        TCILAST: true,
        TCIGRA: true,
        TCIVIC: true,
        TCIOTHREV: true,
        TCIOTHEXP: true,
      },
    },
  });

  useEffect(() => {
    const getFormdata = async () => {
      let request = await getAPICall(
        `${URL_WITH_VERSION}/tci/edit?e=${state?.statementAC?.tci_code}&sn=1`
      );
      let response = await request["data"];
      if (response) {
        let sac = Object.assign({}, state.statementAC);
        sac["vessel_name"] = response["vessel_name"];
        sac["chartrer_name"] = response["chartrer_name"];
        sac["date_from"] = sac["date_from"] ? moment(sac["date_from"]) : null;
        sac["date_to"] = sac["date_to"] ? moment(sac["date_to"]) : null;
        setState(
          (prevState) => ({ ...prevState, statementAC: sac }),
          () => setState((prevState) => ({ ...prevState, showForm: true }))
        );
      } else {
        setState((prevState) => ({ ...prevState, showForm: true }));
      }
    };
    getFormdata();
  }, []);

  const onChangeEvent = (event, name) => {
    let sac = Object.assign({}, state.statementAC);
    if (moment.isMoment(event)) {
      sac[name] = event;
    } else if (
      event &&
      event.hasOwnProperty("target") &&
      event["target"] &&
      event["target"].hasOwnProperty("checked") &&
      sac["account_head"].hasOwnProperty(name)
    ) {
      sac["account_head"][name] = event["target"]["checked"];
    }
    setState((prevState) => ({ ...prevState, statementAC: sac }));
  };

  const onClickSOAButton = async () => {
    try {
      state.statementAC.hire_invoice = true;
      delete state?.statementAC?.tci_code;
      let request = await awaitPostAPICall(
        `${URL_WITH_VERSION}/report/hire-statement-account`,
        state.statementAC
      );
      let respdata = await request;
      let response = respdata["data"];

      if (respdata["data"]) {
        setState(
          (prevState) => ({ ...prevState, invoiceData: response }),
          () => {
            setState((prevState) => ({
              ...prevState,
              showInvoice: true,
              showForm: false,
            }));
          }
        );
      } else {
        openNotificationWithIcon("info", respdata.message, 2);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const { statementAC, showForm, showInvoice, invoiceData } = state;

  return (
    <>
      {showForm ? (
        <div className="body-wrapper modalWrapper">
          <article className="article toolbaruiWrapper">
            <div className="box box-default">
              <div className="box-body">
                <Form>
                  <div className="row p10">
                    <div className="col-md-6">
                      <FormItem {...formItemLayout} label="Vessel Name">
                        <Input
                          type="text"
                          size="default"
                          defaultValue={statementAC.vessel_name}
                          readOnly
                        />
                      </FormItem>
                    </div>

                    <div className="col-md-6">
                      <FormItem {...formItemLayout} label="Chartered">
                        <Input
                          type="text"
                          size="default"
                          defaultValue={statementAC.chartrer_name}
                          readOnly
                        />
                      </FormItem>
                    </div>

                    <div className="col-md-6">
                      <FormItem {...formItemLayout} label="Date From">
                        <DatePicker
                          value={dayjs(statementAC.date_from)}
                          format={"YYYY-MM-DD HH:mm"}
                          placeholder="YYYY-MM-DD HH:MM"
                          onChange={(evt) => onChangeEvent(evt, "date_from")}
                          showTime={{ defaultValue: moment("00:00", "HH:mm") }}
                        />
                      </FormItem>
                    </div>

                    <div className="col-md-6">
                      <FormItem {...formItemLayout} label="Date To">
                        <DatePicker
                          value={dayjs(statementAC.date_to)}
                          format={"YYYY-MM-DD HH:mm"}
                          placeholder="YYYY-MM-DD HH:MM"
                          onChange={(evt) => onChangeEvent(evt, "date_to")}
                          showTime={{ defaultValue: moment("00:00", "HH:mm") }}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="row p10">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={statementAC["account_head"]["TCIH"]}
                            checked={statementAC["account_head"]["TCIH"]}
                            onChange={(evt) => onChangeEvent(evt, "TCIH")}
                          >
                            <span>Hire Payment</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCIADCOM"]
                            }
                            checked={statementAC["account_head"]["TCIADCOM"]}
                            onChange={(evt) => onChangeEvent(evt, "TCIADCOM")}
                          >
                            <span>Add Comm</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCIBCOM"]
                            }
                            checked={statementAC["account_head"]["TCIBCOM"]}
                            onChange={(evt) => onChangeEvent(evt, "TCIBCOM")}
                          >
                            <span>Broker Comm</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCIBB"]
                            }
                            checked={statementAC["account_head"]["TCIBB"]}
                            onChange={(evt) => onChangeEvent(evt, "TCIBB")}
                          >
                            <span>Ballast Bonus</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={true}
                            checked={true}
                            onChange={(evt) => onChangeEvent(evt, "")}
                          >
                            <span>Other Adjustment</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCIILOHC"]
                            }
                            checked={statementAC["account_head"]["TCIILOHC"]}
                            onChange={(evt) => onChangeEvent(evt, "TCIILOHC")}
                          >
                            <span>ILHOC</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCIGRA"]
                            }
                            checked={statementAC["account_head"]["TCIGRA"]}
                            onChange={(evt) => onChangeEvent(evt, "TCIGRA")}
                          >
                            <span>Gratuity</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCIVIC"]
                            }
                            checked={statementAC["account_head"]["TCIVIC"]}
                            onChange={(evt) => onChangeEvent(evt, "TCIVIC")}
                          >
                            <span>Victualling</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCILAST"]
                            }
                            checked={statementAC["account_head"]["TCILAST"]}
                            onChange={(evt) => onChangeEvent(evt, "TCILAST")}
                          >
                            <span>Lashing & Stowage</span>
                          </Checkbox>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={true}
                            checked={true}
                            onChange={(evt) => onChangeEvent(evt, "")}
                          >
                            <span>Off Hire Period</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={true}
                            checked={true}
                            onChange={(evt) => onChangeEvent(evt, "")}
                          >
                            <span>Off Hire Bunker</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={true}
                            checked={true}
                            onChange={(evt) => onChangeEvent(evt, "")}
                          >
                            <span>Off Hire Adj.</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCIIHC"]
                            }
                            checked={statementAC["account_head"]["TCIIHC"]}
                            onChange={(evt) => onChangeEvent(evt, "TCIIHC")}
                          >
                            <span>IHC</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={statementAC["account_head"]["BD"]}
                            checked={statementAC["account_head"]["BD"]}
                            onChange={(evt) => onChangeEvent(evt, "BD")}
                          >
                            <span>Bunker Items</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCIREA"]
                            }
                            checked={statementAC["account_head"]["TCIREA"]}
                            onChange={(evt) => onChangeEvent(evt, "TCIREA")}
                          >
                            <span>Rep. Exp/Allowance</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCIOTHREV"]
                            }
                            checked={statementAC["account_head"]["TCIOTHREV"]}
                            onChange={(evt) => onChangeEvent(evt, "TCIOTHREV")}
                          >
                            <span>other revenue</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCIOTHEXP"]
                            }
                            checked={statementAC["account_head"]["TCIOTHEXP"]}
                            onChange={(evt) => onChangeEvent(evt, "TCIOTHEXP")}
                          >
                            <span>other expenses</span>
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row p10">
                    <div className="col-md-12">
                      <button
                        className="btn ant-btn-primary mr-2"
                        onClick={onClickSOAButton}
                      >
                        SOA-R
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </article>
        </div>
      ) : undefined}

      {showInvoice ? <SOARecapReport invData={invoiceData} /> : undefined}
    </>
  );
};

export default ConfirmStatementAccountRecap;
