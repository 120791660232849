import { Col, Input, Row, Select, Table, Tooltip } from "antd";
import React, { Component } from "react";
import URL_WITH_VERSION, { getAPICall } from "..";
import { FIELDS } from "../tableFields";
import VesselSchedule from '../../components/vessel-form/index';
import AddAddressBook from '../../components/AddAddressBook/index';
import TCI from '../../routes/chartering/routes/tci';
import VoyagePopUp from '../../routes/chartering/routes/tci/voyage_pop_up';
import TCO from '../../routes/chartering/routes/tco';
import CargoCargoContract from '../../routes/chartering/routes/cargo-contract';
import AddCargoName from '../../routes/cargo-name/add-cargo-name';
import CargoDetails from "../../routes/chartering/routes/voyage-cargo-in";
import CargoContract from "../../routes/chartering/routes/cargo-contract";
import PortSelection from '../../routes/port-selection/PortSelection';
import VoyageList from '../../routes/voyage-list/components/VoyageManagerList'
import VesselFile from "../../routes/vesselfile/VesselFile";
import DemDesForm from '../../shared/TableForm/DemDesForm';
import WorldScale from "../../routes/chartering/routes/tcov/modals/WorldScale";
const Option = Select.Option;

const INITIAL_STATE = {
  "responseData": [],
  "loading": false,
  "columns": [],
  "search": null,
  "selectedValue": "all",
  "filterBy": null
}

class PopupSelection extends Component {
  constructor(props) {
    super(props);
    let tableHeaders = Object.assign([], FIELDS && props.identifyName && FIELDS[props.identifyName] ? FIELDS[props.identifyName]["tableheads"] : [])
    this.state = {
      ...INITIAL_STATE,
      columns: tableHeaders,
      "filterBy": (this.props.filterBy || null),
      "isShowAddButton": this.props.isShowAddButton || false,
      "isShowInPopup": this.props.isShowInPopup || false,
      "isShowImport": this.props.isShowImport || false,
      "formData": this.props.formData || {},
      "referenceElement": this.props.referenceElement || {},
      "popupFroms": this.props.popupFroms || {},
      "elementIndex": this.props.elementIndex || -1,
      "completeDataObject": this.props.completeDataObject || {},
      "fieldNames": this.props.fieldNames || [],

    }
  }

  componentDidMount = async () => {
    if (this.props.identifyName) {
      this.getTableData(this.props.filterBy, this.props.fieldNames);
    } else if (this.props.frmCode) {
      if (this.props.apiLink) {
        let _url = `${URL_WITH_VERSION}/${this.props.apiLink}`;

        const response = await getAPICall(_url, this.props.header);
        const respData = await response['data'];

        let outputData = {};
        if (this.props.formData) {
          outputData = Object.assign(respData, this.props.formData);
        }
        this.setState({ ...this.state, frmCode: this.props.frmCode, frmData: outputData });
      } else if (!this.props.apiLink && this.props.formData) {
        this.setState({ ...this.state, frmCode: this.props.frmCode, frmData: this.props.formData });
      } else {
        this.setState({ ...this.state, frmCode: this.props.frmCode, frmData: {} });
      }
    }

    if (this.props.referenceElement) {
      this.setState({ ...this.state, referenceElement: this.props.referenceElement });
    }
  }

  componentWillUnmount = () => {
    //PopupSelection = null;
    this.setState({
      ...INITIAL_STATE
    })
  }

  getTableData = async (filterBy, fieldNames, headers = null) => {
    let dataFilter = { "order_by": { [this.props.orderBy]: "desc" } };

    if (headers) {
      headers = { ...dataFilter, ...headers };
    } else {
      headers = dataFilter;
    }

    if (filterBy && typeof filterBy === 'object' && JSON.stringify('{}') !== filterBy) {
      let whereClause = filterBy;
      if (headers.hasOwnProperty('where')) {
        whereClause = headers['where'];
      }
      headers['where'] = whereClause;
    }

    if (fieldNames && typeof fieldNames === 'object') {
      headers['where'] = fieldNames.where
    }

    this.setState({
      ...this.state,
      loading: true,
      responseData: []
    });

    let _url = `${URL_WITH_VERSION}/${this.props.apiLink}`;
    const response = await getAPICall(_url, headers);
    const data = await response;

    let dataArr = (data && data.data ? data.data : []);
    let state = { loading: false }
    if (dataArr.length > 0) {
      state["responseData"] = dataArr;
    }
    this.setState({
      ...this.state,
      ...state
    });
  }

  onSelectChange = (value) => this.setState({ ...this.state, selectedValue: value, search: null }, () => this.getTableData(this.state.filterBy));

  onKeypress = (e) => {
    let headers = {}, value = e.target.value;
    if (this.state.selectedValue === 'all') {
      headers["OR"] = {};
      this.state.columns.map((poc, i) => {
        if ((poc.hasOwnProperty('dataIndex') && poc.dataIndex !== 'action') || (poc.hasOwnProperty('key') && poc.key !== 'action')) {
          headers["OR"][poc.dataIndex || poc.key] = { l: value };
        }
      });
    } else {
      headers[this.state.selectedValue] = { l: value };
    }
    this.getTableData(this.state.filterBy, { "where": headers });
  };

  updateSearch = (e) => {
    this.setState({
      ...this.state,
      search: e.target.value
    })



  }

  modalCloseEvent = (rowData) => {

    if (typeof this.props.onCloseModal === 'function') {
      this.props.onCloseModal({ element: this.state.referenceElement, data: rowData });
    }
    if (typeof this.props.updateMainForm === 'function') {
      this.props.updateMainForm();
    }
    if (typeof this.props.handelPopupReturn === 'function') {
      this.props.handelPopupReturn(rowData, this.state.elementIndex, this.state.referenceElement);
    }

    if (typeof (this.props.reFreshForm) === 'function') {
      this.props.reFreshForm()
    }
    if(this.props?.handlePortInfoDrawer && this.props?.frmCode==="port_list"){
      this.props?.handlePortInfoDrawer(rowData?.port);
    }
  };


  selectVoyageNo = (rowData) => {

    if (typeof this.props.updateMainForm === 'function') {
      this.props.updateMainForm();
    }
    if (typeof this.props.handelPopupReturn === 'function') {
      this.props.handelPopupReturn(rowData, this.state.elementIndex, this.state.referenceElement);
    }

    if (typeof (this.props.reFreshForm) === 'function') {
      this.props.reFreshForm()
    }
  }




  render() {
    const { referenceElement, elementIndex, completeDataObject, loading, responseData, columns, selectedValue,
      search, frmCode, frmData, isShowAddButton, popupFroms, isShowInPopup, isShowImport } = this.state;
      if (frmCode && frmCode !== '' && frmCode === 'vessel_list') {
        return (<VoyageList formData={frmData} isShowAddButton={isShowAddButton} modalCloseEvent={this.modalCloseEvent} />);
      } 
      else if (frmCode && frmCode !== '' && frmCode === 'vessel_schedule') {
      return (<VesselSchedule formData={frmData} isShowAddButton={isShowAddButton} modalCloseEvent={this.modalCloseEvent} />);
    } 
    else if (frmCode && frmCode !== '' && frmCode === 'address_form') {
      return (<AddAddressBook formData={frmData} isShowAddButton={isShowAddButton} modalCloseEvent={this.modalCloseEvent} />);
    } else if (frmCode && frmCode !== '' && frmCode === 'time_charter_in_form') {
      return (<TCI refresh={this.props.refreshVM} formData={frmData} isShowAddButton={isShowAddButton} popupFroms={popupFroms} modalCloseEvent={this.modalCloseEvent} />);
    } else if (frmCode && frmCode !== '' && frmCode === 'tco_form') {
      return (<TCO reFreshForm={this.props.reFreshForm} formData={frmData} isShowAddButton={isShowAddButton} popupFroms={popupFroms} modalCloseEvent={this.modalCloseEvent} />);
    }
    else if (frmCode && frmCode !== '' && frmCode === 'cargo_contract_form') {
      return (<CargoCargoContract import={true} formData={frmData} isShowAddButton={isShowAddButton} isShowInPopup={isShowInPopup} isShowImport={isShowImport} modalCloseEvent={this.modalCloseEvent} />);
    } else if (frmCode && frmCode !== '' && frmCode === 'cargo_form') {
      return (<AddCargoName formData={frmData[0]} isShowAddButton={isShowAddButton} modalCloseEvent={this.modalCloseEvent} />);
    } else if (frmCode && frmCode !== '' && frmCode === 'vcargo_in_contract_form') {
      return (<CargoDetails formData={frmData} isShowAddButton={isShowAddButton} modalCloseEvent={this.modalCloseEvent} />);
    } else if (frmCode && frmCode !== '' && frmCode === 'cargo_contract_form') {
      return (<CargoContract formData={frmData} isShowAddButton={isShowAddButton} modalCloseEvent={this.modalCloseEvent} />);
    }
    else if (frmCode && frmCode !== '' && frmCode === 'Vessel_file_form') {
      return (<VesselFile formdata={frmData} isShowAddButton={isShowAddButton} modalCloseEvent={this.modalCloseEvent} />);
    }
    else if (frmCode && frmCode !== '' && frmCode === 'port_list') {

      if (elementIndex > -1 && completeDataObject && completeDataObject.hasOwnProperty('fd') && completeDataObject.hasOwnProperty('pe')) {
        let index = (elementIndex >= 1 ? (elementIndex - 1) : 0), portID = 0;
        if (completeDataObject['fd'].hasOwnProperty(completeDataObject['pe']['groupKey']) && completeDataObject['fd'][completeDataObject['pe']['groupKey']][index]) {
          const fieldKey = completeDataObject['pe'].hasOwnProperty('nameField') ? 'nameField' : 'fieldName';
          portID = completeDataObject['fd'][completeDataObject['pe']['groupKey']][index][completeDataObject['pe'][fieldKey]];
          // portID = completeDataObject['fd'][completeDataObject['pe']['groupKey']][index][completeDataObject['pe']['fieldName']];
        }
        if (portID) {
          return (<PortSelection fromPortID={portID} referenceElement={referenceElement} modalCloseEvent={this.modalCloseEvent} />);
        } else {
          return (<PortSelection />)
        }
      } else {
        if (completeDataObject['pe']['mainformName'] == "tcov_full_repos_form") {
          let fromportid = completeDataObject['pe']['fromField'] ? completeDataObject['fd'][completeDataObject['pe']['fromField']] : 0

          return (<PortSelection fromPortID={fromportid} referenceElement={referenceElement} modalCloseEvent={this.modalCloseEvent} />);
        } else {
          let _fromPortID = (completeDataObject['pe']['fromField'] ? completeDataObject['fd'][completeDataObject['pe']['fromField']['groupKey']][completeDataObject['pe']['fromField']['fieldName']] : 0);
          return (<PortSelection fromPortID={_fromPortID} referenceElement={referenceElement} modalCloseEvent={this.modalCloseEvent} />);
        }
      }
    }
    else if (frmCode && frmCode !== '' && frmCode === 'dem_des_form') {
      if (elementIndex > -1 && completeDataObject && completeDataObject.hasOwnProperty('fd') && completeDataObject.hasOwnProperty('pe')) {
        let index = (elementIndex >= 1 ? elementIndex : 0);
        if (completeDataObject['fd'].hasOwnProperty(completeDataObject['pe']['groupKey']) && completeDataObject['fd'][completeDataObject['pe']['groupKey']][index]) {
          let _formdata = completeDataObject?.['fd']?.[completeDataObject['pe']?.['groupKey']]?.[index] ?? {};
          return (<DemDesForm formData={_formdata} referenceElement={referenceElement} modalCloseEvent={this.modalCloseEvent} />)
        }
      } else {
        let _formdata = completeDataObject?.['fd']?.[completeDataObject['pe']?.['groupKey']]?.[0];
        return (<DemDesForm formData={_formdata} referenceElement={referenceElement} modalCloseEvent={this.modalCloseEvent} />);
      }
    }

    else if (frmCode && frmCode !== '' && frmCode === 'worldscale_rate') {

      if (elementIndex > -1 && completeDataObject && completeDataObject.hasOwnProperty('fd') && completeDataObject.hasOwnProperty('pe')) {
        let index = (elementIndex >= 1 ? elementIndex : 0);
        if (completeDataObject['fd'].hasOwnProperty(completeDataObject['pe']['groupKey']) && completeDataObject['fd'][completeDataObject['pe']['groupKey']][index]) {
          let _formdata = completeDataObject?.['fd']?.[completeDataObject['pe']?.['groupKey']]?.[index] ?? {};

          if (_formdata && _formdata['frt_type'] === '259') {
            return (<WorldScale formData={_formdata} referenceElement={referenceElement} modalCloseEvent={this.modalCloseEvent} />);
          } else {
            return (<WorldScale referenceElement={referenceElement} modalCloseEvent={this.modalCloseEvent} />);
          }
        }
      } else {
        let _formdata = completeDataObject?.['fd']?.[completeDataObject['pe']?.['groupKey']]?.[0] ?? completeDataObject?.['fd'];

        if (_formdata && _formdata['frt_type'] == 259) {
          return (<WorldScale formData={_formdata} referenceElement={referenceElement} modalCloseEvent={this.modalCloseEvent} />);
        } else {


          return (<WorldScale formData={_formdata} referenceElement={referenceElement} modalCloseEvent={this.modalCloseEvent} />);
        }

      }
    }
    else if (frmCode && frmCode !== '' && frmCode === 'isvoyageform') {
      return <VoyagePopUp completeDataObject={completeDataObject.fd} index={elementIndex} referenceElement={referenceElement} selectVoyageNo={this.selectVoyageNo} />
    }
    else {
      let dropdownColumns = [{ 'label': 'All', 'value': 'all' }];
      columns.map((poc, i) => {
        if (poc.hasOwnProperty('dataIndex') && poc.dataIndex !== 'action' && poc.dataIndex !== 'spd_laden' && poc.dataIndex !== 'spd_ballast' && poc.dataIndex !== 'daily_cost' ) {
          dropdownColumns.push({ 'label': poc.title, 'value': poc.dataIndex })
        } else if (poc.hasOwnProperty('key') && poc.key !== 'action'  && poc.dataIndex !== 'spd_laden' && poc.dataIndex !== 'spd_ballast' && poc.dataIndex !== 'daily_cost') {
          dropdownColumns.push({ 'label': poc.title, 'value': poc.key })
        }
      });

      return (
        <div className="body-wrapper">
          <article className="article">
            <div className="box box-default">
              <div className="box-body">
                <div className="p-b-15">
                  {
                    loading === false ?
                      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Row gutter={16} className="p-b-15">
                          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Select id="filter_type" value={selectedValue} onChange={this.onSelectChange}>
                              {
                                dropdownColumns.length > 0 ? (
                                  dropdownColumns.map((d, i) => {
                                    return <Option key={i} value={d.value}>{d.label}</Option>
                                  })) : undefined
                              }
                            </Select>
                          </Col>
                          <Col xs={10} sm={10} md={10} lg={12} xl={12}>
                            <Tooltip title="Please enter a value and hit enter key to search.">
                              <Input placeholder="Please Enter" value={search} onChange={this.updateSearch} id="is_search" onPressEnter={this.onKeypress} />
                            </Tooltip>
                          </Col>
                        </Row>
                      </Col>
                      : undefined
                  }
                </div>
                <div>
                  <Table
                    rowKey={record => record.vessel_id}
                    className="inlineTable editableFixedHeader resizeableTable"
                    bordered
                    scroll={{ x: "max-content" }}
                    columns={columns}
                    size="small"
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: event => this.props.onRowSelect(record)
                      };
                    }}
                    dataSource={responseData}
                    loading={loading}
                    pagination={true}
                    rowClassName={(r, i) => ((i % 2) === 0 ? 'table-striped-listing' : 'dull-color table-striped-listing')}
                  />
                </div>
              </div>
            </div>
          </article>
        </div>
      );
    }
  }
}

PopupSelection.defaultProps = {
  "identifyName": null,
  "apiLink": null,
  "orderBy": "id",
  "onRowSelect": function (e) { return e }
}

export default PopupSelection;
