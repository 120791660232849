import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import URL_WITH_VERSION, { IMAGE_PATH } from "../../shared";
import { CustomSpinner } from "../port-to-port/portUtil";
const LiveVesselSearch = ({
  onCloseFilter,
  onChangeLiveSearchInput,
  onLiveSearchDataClick,
  listData = [],
  isLoading,
  liveSearchResult,
  isDetailLoading,
}) => {
  
  
  return (
    <div className="filter-search-list">
      <div className="close-icon-wrapper" onClick={onCloseFilter}>
        <CloseOutlined />
      </div>
      <div className="close-icon">
        <input
          type="text"
          onChange={onChangeLiveSearchInput}
          placeholder="Enter minimum 3 letter vessel name or IMO number"
        />
      </div>
      <div className="list-data" style={{height:'100%'}}>
        { !isLoading && (Array.isArray(listData) && listData.length >= 0) ? (
          !isDetailLoading ? listData.map((v, index) =>
            <div
                key={index}
                className="list-item"
                onClick={() => onLiveSearchDataClick(v)}
              >
                <img
                  loading="lazy"
                  src={IMAGE_PATH + "icons/ship.png"}
                  alt=""
                />
                <div className="middle-wrapper">
                  <span className="vessel-name">{v.SHIPNAME}</span>
                  <div className="middle-wrapper-discription">
                    <span>MMSI: {v.MMSI}</span>
                    <span>IMO no. : {v.IMO ? v.IMO : "NA"}</span>
                  </div>
                </div>
                <div className="last-wrapper">
                  <p>live</p>
                </div>
            </div>
          ) : <div style={{display: 'flex', justifyContent:'center', alignItems:'center', height:'100%'}}><CustomSpinner/></div>
        ) : (
          <div style={{display:'flex', justifyContent:'center', height:'100%', alignItems:'center'}}><CustomSpinner /></div>
        )}
      </div>
    </div>
  );
};

export default LiveVesselSearch;
