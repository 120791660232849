import { Drawer } from "antd";
import moment from "moment";
import React, { useState } from "react";

export const PortDistanceSteps = ({ data, visible, onClose }) => {
  // const [table, setTable] = useState(data.pop())
  const currentDate = moment();
  const getDateForStep = (index) => {
    let date = currentDate.clone();
    for (let i = 0; i <= index; i++) {
      let days = data[i].seaDays;
      let fullDays = Math.floor(days);
      let hours = (days - fullDays) * 24;
      date.add(fullDays, "days").add(hours, "hours");
    }
    return date.format("DD-MM-YYYY HH:mm");
  };
 
  return (
    <Drawer
      title="Port Details Estimation"
      placement="right"
      onClose={onClose}
      open={visible}
      // width={220}
      rootClassName="custom-drawer"
      key={"drawer"}
    >
      <div className="steps-chart">
        {data?.length > 0 &&
          data.map((port, index) => (
            <div className="step" key={port.id}>
              {index !== 0 && (
                <div className="step-description">
                  {/* <p>To: {index < data.length - 1 ? data[index + 1].port_name : "End"}</p> */}
                  <p>Distance: {port.distance || "N/A"}</p>
                  <p style={{ color: "green" }}>Speed: {port.spd}</p>
                  <p>Sea Days: {port.seaDays}</p>
                  <p>ECA: {port.eca}</p>
                  <p>LSMGO: {port.lsmgo + " MT"}</p>
                  <p>Total Sea consp. : {port.seaCons + " MT"}</p>
                  <p>SECA: {port.seca}</p>
                  <p>VLSFO/day: {port.vlsfoPerDay} MT</p>
                  <p style={{ color: "red" }}>CO2 Emissions: {port.co2}</p>
                  <p>Crossed: {port.crossed}</p>
                  <p>Load Term: {port.ldTerm}</p>
                  <p>Total Bunker Cons.: {port.ttlBunkercons} MT</p>
                </div>
              )}
              <div className="step-title">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBlock: "10px",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/location.png"
                      alt="Port"
                      width="16px"
                      style={{ marginRight: "6px" }}
                    />
                    {port.port_name}
                  </span>
                  <span style={{ fontSize: "13px" }}>
                    <span style={{ color: "#d531d5" }}>
                      {index === 0 ? "Departure - " : "Arrival - "}
                    </span>
                    <span style={{ color: "rgb(21 160 227)" }}>
                      {getDateForStep(index)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </Drawer>
  );
};
