
import { DeleteOutlined, FilePdfOutlined, MenuFoldOutlined, PlusOutlined, SaveOutlined, SearchOutlined } from "@ant-design/icons";
export const REACT_APP_IMAGE_PATH = process.env.REACT_APP_IMAGE_PATH;

const CONFIGURATION = {
  "voyage-list": {
    isLeftBtn: [
      {
        isSets: [
          { id: "1", key: "add", type: <PlusOutlined />, withText: "" },
          { id: "2", key: "search", type: <SearchOutlined />, withText: "" },
          { id: "3", key: "save", type: <SaveOutlined />, withText: "Save" }
        ]
      },
      {
        isSets: [
          { id: "1", key: "file", type: <FilePdfOutlined />, withText: "" },
          { id: "2", key: "delete", type: <DeleteOutlined />, withText: "" }
        ]
      },
      {
        isSets: [
          { id: "1", key: "left-move", type: "arrow-left", withText: "" },
          { id: "2", key: "right-move", type: "arrow-right", withText: "" }
        ]
      }
    ],
    isRightBtn: [
      {
        isSets: [
          { key: "Estimate", isDropdown: 0, withText: "Estimate", type: "", menus: null },
          { key: "Freight", isDropdown: 1, withText: "Freight/Relet", type: "", menus: [
            { "href": null, "icon": null, "label": "Freight Invoice", modalKey: "" },
            { "href": null, "icon": null, "label": "Freight Invoice summary", modalKey: "" },
            { "href": null, "icon": null, "label": "Freight Commission Invoice", modalKey: "" },
            { "href": null, "icon": null, "label": "Freight Commission summary", modalKey: "" },
          ] },
          { key: "TC Commission", isDropdown: 1, withText: "TC Commission", type: "", menus: [
            { "href": null, "icon": null, "label": "Commission Summary", modalKey: "commission-payment" },
            { "href": null, "icon": null, "label": "Broker Commission Creation", modalKey: "" },
          ] },
          { key: "Other Rev/Exp", isDropdown: 1, withText: "Other Revenu Expenses", type: "", menus: null },
          { key: "Laytime", isDropdown: 1, withText: "Laytime", type: "", menus: [
            { "href": null, "icon": null, "label": "Create New Laytime", modalKey: "new_laytime" },
            { "href": null, "icon": null, "label": "Laytime Summary", modalKey: "" }
          ] },
          { key: "Delays", isDropdown: 1, withText: "Delays", type: "", menus: null },
          { key: "Bunkers", isDropdown: 1, withText: "Bunkers", type: "", menus: null },
          // { key: "Deviation", isDropdown: 1, withText: "Deviation", type: "", menus: null },
          {
            key: "portcall", isDropdown: 1, withText: "Port Call", type: "", menus: [
              { "href": null, "icon": null, "label": "Create Agency Appointment", modalKey: "create_agency_appointment" },
              { "href": null, "icon": null, "label": "Saved appointment", modalKey: "saved_appointment" },
              { "href": null, "icon": null, "label": "My Portcall", modalKey: "myportcall" },
              { "href": null, "icon": null, "label": "Port Expense Summary", modalKey: "port_expense_summary" },
              { "href": null, "icon": null, "label": "Port Information", modalKey: "port_information" },
            ]
          },
        ],
      },
    ],
    isMoreoprtion: true
  }, 
  "pl-main": {
    isLeftBtn: [
      {
        isSets: [
          { key: "Snapshot", isDropdown: 1, withText: "Snapshot", type: "", menus: [{ "href": null, "icon": null, "label": "", modalKey: "" }] },
          { key: "Basic: Actual", isDropdown: 1, withText: "Basic: Actual", type: "", menus: [{ "href": null, "icon": null, "label": "", modalKey: "" }] },
          { key: "Compare: Posted", isDropdown: 1, withText: "Compare: Posted", type: "", menus: [{ "href": null, "icon": null, "label": "", modalKey: "" }] },
        ],
      },
    ],
    isRightBtn: [],
    isMoreoprtion: false
  },

  "p&l-tcov": {
    isLeftBtn: [
      {
        isSets: [
          { key: "Voyage Period Journals", isDropdown: 0, withText: "Voyage Period Journals", type: "", menus: null },
          { key: "Snapshot", isDropdown: 1, withText: "Snapshot", type: "", menus: [{ "href": null, "icon": null, "label": "", modalKey: "" }] },
          { key: "Basic: Actual", isDropdown: 1, withText: "Basic: Actual", type: "", menus: [{ "href": null, "icon": null, "label": "", modalKey: "" }] },
          { key: "Compare: Posted", isDropdown: 1, withText: "Compare: Posted", type: "", menus: [{ "href": null, "icon": null, "label": "", modalKey: "" }] },
        ],
      },
    ],
    isRightBtn: [],
    isMoreoprtion: false
  },

  "laytime-toolbar": {
    isLeftBtn: [
      {
        isSets: [
          { id: "1", key: "save", type: <SaveOutlined />, withText: "Save" }
        ]
      },
      {
        isSets: [
          { id: "1", key: "file", type: <FilePdfOutlined />, withText: "" },
          { id: "2", key: "delete", type: <DeleteOutlined />, withText: "" }
        ]
      },
    ],
    isRightBtn: [
      {
        isSets: [
          { id: "1", key: "incremental", type: "", withText: "Incremental", isDropdown: 0, menus: null },
          { id: "2", key: "refresh", type: "", withText: "Refresh", isDropdown: 0, menus: null },
          { id: "3", key: "Attachments", type: "", withText: "Attachments", isDropdown: 0, menus: null },
        ]
      },
      {
        isSets: [
          { id: "1", key: "Commission", type: "", withText: "Commission", isDropdown: 0, menus: null },
          { id: "3", key: "Allocation", type: "", withText: "Allocation", isDropdown: 0, menus: null },
        ]
      },
      {
        isSets: [
          { key: "report", isDropdown: 1, withText: "Report", type: "", menus: [{ "href": null, "icon": null, "label": "Invoice", modalKey: "invoice" }] },
        ],
      },
      {
        isSets: [
          { id: "1", key: "standard_paragarph", type: "", withText: "Standard Paragarph", isDropdown: 0, menus: null },
        ]
      },
    ],
    isMoreoprtion: false
  },

  "voyage-fixture-toolbar": {
    isLeftBtn: [
      {
        isSets: [
          { id: "1", key: "save", type: <SaveOutlined />, withText: "Save" }
        ]
      },
      {
        isSets: [
          { id: "1", key: "file", type: <FilePdfOutlined />, withText: "" },
          { id: "2", key: "delete", type: <DeleteOutlined />, withText: "" }
        ]
      },
    ],
    isRightBtn: [
      {
        isSets: [
          { id: "1", key: "incremental", type: "", withText: "Incremental", isDropdown: 0, menus: null },
          { id: "2", key: "refresh", type: "", withText: "Refresh", isDropdown: 0, menus: null },
          { id: "3", key: "Attachments", type: "", withText: "Attachments", isDropdown: 0, menus: null },
        ]
      },
      {
        isSets: [
          { id: "1", key: "Commission", type: "", withText: "Commission", isDropdown: 0, menus: null },
          { id: "3", key: "Allocation", type: "", withText: "Allocation", isDropdown: 0, menus: null },
        ]
      },
      {
        isSets: [
          { key: "report", isDropdown: 1, withText: "Report", type: "", menus: [{ "href": null, "icon": null, "label": "Invoice", modalKey: "invoice" }] },
        ],
      },
      {
        isSets: [
          { id: "1", key: "standard_paragarph", type: "", withText: "Standard Paragarph", isDropdown: 0, menus: null },
        ]
      },
    ],
    isMoreoprtion: false
  },
  "coa-vci-list": {
    isLeftBtn: [
      {
        isSets: [
          { id: "1", key: "add", type: <PlusOutlined />, withText: "" },
          { id: "2", key: "search", type: <SearchOutlined />, withText: "" },
          { id: "3", key: "save", type: <SaveOutlined />, withText: "Save" }
        ]
      },
      {
        isSets: [
          { id: "1", key: "file", type: <FilePdfOutlined />, withText: "" },
          { id: "2", key: "delete", type: <DeleteOutlined />, withText: "" }
        ]
      },
    ],
    isRightBtn: [
      {
        isSets: [
          { key: "vc_in", isDropdown: 1, withText: "VC In", type: "", menus: [{ "href": null, "icon": null, "label": "", modalKey: "" }] },
          { key: "Refresh", isDropdown: 0, withText: "Refresh", type: "", menus: null },
          { key: "Report", isDropdown: 1, withText: "Report", type: "", menus: [{ "href": null, "icon": null, "label": "", modalKey: "" }] },
          { key: "Attachments", isDropdown: 0, withText: "Attachments", type: "", menus: null },
          { key: "Standard Paragraphs", isDropdown: 0, withText: "Standard Paragraphs", type: "", menus: null },
          { key: "Add Unpriced Elements", isDropdown: 0, withText: "Add Unpriced Elements", type: "", menus: null },
        ],
      },
    ],
    isMoreoprtion: false
  },
  "add-voyage-cargo": {
    isLeftBtn: [
      {
        isSets: [
          { id: "5", key: "menu-fold", type: <MenuFoldOutlined/>, withText: "", "event": "menu-fold"},
          { id: "1", key: "add", type: <PlusOutlined />, withText: "" },
          { id: "2", key: "search", type: <SearchOutlined />, withText: "" },
          { id: "3", key: "save", type: <SaveOutlined />, withText: "" },
          { id: "4", key: "delete", type: <DeleteOutlined />, withText: "" },
        ]
      },
      {
        isSets: [
          
        ]
      },
    ],
    isRightBtn: [
      {
        isSets: [
          { key: "menu", isDropdown: 0, withText: "Menu", type: "", menus:null },
          { key: "cargo", isDropdown: 0, withText: "Create Cargo", type: "", menus: null },
          { key: "schedule_vayage", isDropdown: 0, withText: "Schedule Voyage", type: "", menus: null },
          { key: "estimate", isDropdown: 0, withText: "Estimate", type: "", menus: null },
          { key: "report", isDropdown: 0, withText: "Reports", type: "", menus: null },
        ],
      },
    ],
    isMoreoprtion: false
  },

  "my_portcall_dashboard_menu": {
    isLeftBtn: [
      {
        isSets: [
          { key: "fixture", isDropdown: 0, withText: "Back", type: "", menus: null },
        ],
      },
      
    ],
    isRightBtn: [
      {
        isSets: [
         
          // {
          //   key: "portcall", isDropdown: 1, withText: "Port Call", type: "", menus: [
          //     { "href": null, "icon": null, "label": "My Portcall", modalKey: "myportcall" },
          //     { "href": null, "icon": null, "label": "Port Expense Summary", modalKey: "port_expense_summary"},
          //   ]
          // },

          {
            key: "portcall", isDropdown: 1, withText: "Setting", type: "", menus: [
              { "href": null, "icon": null, "label": "Archive", modalKey: "archive" },
              // { "href": null, "icon": null, "label": "Company Registration", modalKey: "company_registration" },
              // { "href": null, "icon": null, "label": "Bank Account Management", modalKey: "bank_account_management"},
              // { "href": null, "icon": null, "label": "User Password Management", modalKey: "user_password_management"},
            ]
          },
        ],
      },
    ],
    isMoreoprtion: false
  },

  "user_list_top_menu": {
    isLeftBtn: [
      {
        isSets: [
          { key: "fixture", isDropdown: 0, withText: "Users | Showing All User", type: "", menus: null },
        ],
      },
      
    ],
    isRightBtn: [
      {
        isSets: [
          {
            key: "portcall", isDropdown: 0, withText: "Add User", type: "",  modalKey: "add_user_modal", menus: [null]
          },
          {
            key: "portcall", isDropdown: 0, withText: "Update User", type: "", menus: [null]
          },
          {
            key: "portcall", isDropdown: 0, withText: "Download User", type: "", menus: [null]
          },
          {
            key: "portcall", isDropdown: 1, withText: "More", type: "", menus: [
              { "href": null, "icon": null, "label": "Active", modalKey: "active"  },
              { "href": null, "icon": null, "label": "In Active", modalKey: "inactive"  },
              ]
          },
        ],
      },
    ],
    isMoreoprtion: false
  },
  "group_list_top_menu": {
    isLeftBtn: [
      {
        isSets: [
          { key: "fixture", isDropdown: 0, withText: "Group | Showing All Group", type: "", menus: null },
        ],
      },
      
    ],
    isRightBtn: [
      {
        isSets: [
          {
            key: "portcall", isDropdown: 0, withText: "New Group", type: "",  modalKey: "add_user_modal", menus: [null]
          },
          {
            key: "portcall", isDropdown: 0, withText: "Update Group", type: "", menus: [null]
          },
          {
            key: "portcall", isDropdown: 0, withText: "Delete Group", type: "", menus: [null]
          },
          {
            key: "portcall", isDropdown: 0, withText: "Access Rights", type: "", menus: [null]
          },
          {
            key: "portcall", isDropdown: 1, withText: "More", type: "", menus: [
              { "href": null, "icon": null, "label": "Active", modalKey: "active"  },
              { "href": null, "icon": null, "label": "In Active", modalKey: "inactive"  },
              ]
          },
        ],
      },
    ],
    isMoreoprtion: false
  },

}


export default CONFIGURATION;