import React from 'react';
import { Table, Badge, Tooltip } from 'antd';
import { ArrowUpOutlined, CloudOutlined, CompressOutlined } from '@ant-design/icons';

const WeatherInfo = ({ weatherData }) => {
  const renderWindInfo = (height, windData, directionData) => {
    const windSpeed = formatWeatherValue(windData);
    const windDirection = formatWeatherValue(directionData);
    return (
      <tr key={height}>
        <td><strong>{height}</strong></td>
        <td>{windSpeed}</td>
        <td>{windDirection}</td>
      </tr>
    );
  };

  const formatWeatherValue = (data) => {
    const sgValue = data?.sg ? <Badge color="blue" text={`SG: ${data.sg}`} /> : <Badge color="gray" text="SG: N/A" />;
    const noaaValue = data?.noaa ? <Badge color="green" text={`NOAA: ${data.noaa}`} /> : <Badge color="gray" text="NOAA: N/A" />;
    return (
      <Tooltip title={`${sgValue.props.text}, ${noaaValue.props.text}`}>
        {sgValue} {noaaValue}
      </Tooltip>
    );
  };

  return (
    <div style={{
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '10px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#f9f9f9'
    }}>
      <h4><ArrowUpOutlined /> Wind</h4>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px', backgroundColor: '#fff', borderRadius: '8px' }}>
        <thead>
          <tr style={{ backgroundColor: '#003e78', color: '#fff' }}>
            <th>Height</th>
            <th>Speed (m/s)</th>
            <th>Direction (°)</th>
          </tr>
        </thead>
        <tbody>
          {renderWindInfo('10m', weatherData?.wind10m, weatherData?.windDirection10m)}
          {renderWindInfo('50m', weatherData?.wind50m, weatherData?.windDirection50m)}
          {renderWindInfo('80m', weatherData?.wind80m, weatherData?.windDirection80m)}
        </tbody>
      </table>

      <h4><CloudOutlined /> Waves & Swell</h4>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px', backgroundColor: '#fff', borderRadius: '8px' }}>
        <tbody>
          <tr>
            <td><strong>Swell Height:</strong></td>
            <td>{formatWeatherValue(weatherData?.swellHeight)} m</td>
          </tr>
          <tr>
            <td><strong>Swell Direction:</strong></td>
            <td>{formatWeatherValue(weatherData?.swellDirection)}°</td>
          </tr>
          <tr>
            <td><strong>Wave Height:</strong></td>
            <td>{formatWeatherValue(weatherData?.waveHeight)} m</td>
          </tr>
        </tbody>
      </table>

      <h4><CompressOutlined /> Temperature</h4>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px', backgroundColor: '#fff', borderRadius: '8px' }}>
        <tbody>
          <tr>
            <td><strong>Surface:</strong></td>
            <td>{formatWeatherValue(weatherData?.airTemperature)}°C</td>
          </tr>
          <tr>
            <td><strong>80m:</strong></td>
            <td>{formatWeatherValue(weatherData?.airTemperature80m) || 'SG: N/A, NOAA: N/A'}°C</td>
          </tr>
        </tbody>
      </table>

      <h4><CompressOutlined /> Pressure</h4>
      <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: '#fff', borderRadius: '8px' }}>
        <tbody>
          <tr>
            <td><strong>Air Pressure:</strong></td>
            <td>{formatWeatherValue(weatherData?.airPressure)} hPa</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WeatherInfo;
