import React from "react";
import Loader from "./Loader";
import "./loading.css";
import LoaderAtCenter from "./LoaderAtCenter";

function Loading(props) {
  if (props.error) {
    return (
      <div className="loader-container">Error! Please refresh the page</div>
    );
  } else if (props.pastDelay) {
    return (
      <div className="loader-container">
        {" "}
        <Loader />{" "}
      </div>
    );
  } else if (props.roushan) {
    return (
      <div id="loader-container">
       
        <img
          src="https://d2olrrfwjazc7n.cloudfront.net/website/assets/loader/loaderSvg.svg"
          alt="Loading"
          class="spinning-image"
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );
  } else {
    return <LoaderAtCenter />;
  }
}

export default Loading;
