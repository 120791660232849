import React from "react";
import { Card, Tooltip, Button, Typography } from "antd";
import { Image } from "antd";
import { Typography as AntdTypography } from "antd";
import "../components/Layout/Tooltip/Tooltip.css";
const { Meta } = Card;
const { Text } = AntdTypography;

const PopToolTip = ({ text, title }) => {
  return (
    <Card style={{ maxWidth: 345 }} className="toolTipCard">
      <div style={{ backgroundColor: "#eee2e6"}}>
        <Image height={140} src={process.env.REACT_APP_IMAGE_PATH+"vessel_tooltip.png"} alt="Vessel Img" />
      </div>
      <Card.Meta
        title={
          <Text style={{ textAlign: "center" }} className="toolTipTitle">
            {title}
          </Text>
        }
        description={text?text:title}
      />
    </Card>
  );
};

export default PopToolTip;
