import { Modal } from "antd";
import React, { useState } from "react";
import Tags from "./Tags";

const TagsModal = ({showTagsModal,setShowTagsModal}) => {
  return (
    <Modal
      title="Tags"
      open={showTagsModal}
      closable={true}
      onCancel={()=>setShowTagsModal(false)}
      footer={null}
      centered
      width={1000}
      bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
    >
      <Tags />
    </Modal>
  );
};

export default TagsModal;
