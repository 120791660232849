import { Table } from "antd";
import dayjs from "dayjs";
import React from "react";
import { MdOutlineArrowLeft, MdOutlineArrowRight } from "react-icons/md";

const VesselInfoTable = ({ vesselTableData }) => {
  const columns = [
    {
      title: "Vessel Name",
      dataIndex: "SHIPNAME",
      key: "SHIPNAME",
      align: "center",
    },
    {
      title: "Vessel Type",
      dataIndex: "TYPE_NAME",
      key: "TYPE_NAME",
      align: "center",
    },
    {
      title: <div style={{ textAlign: "center" }}>Move Type</div>,
      dataIndex: "MOVE_TYPE",
      key: "MOVE_TYPE",
      align: "center",
      render: (item) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {item === "Arrival" ? (
              <MdOutlineArrowRight color="#3ae868" size="30" />
            ) : (
              <MdOutlineArrowLeft color="#f04a5e" size="30" />
            )}
            <span
              style={{
                background: item === "Arrival" ? "#3ae868" : "#f04a5e",
                padding: "4px 8px",
                borderRadius: "8px",
              }}
              // style={{background: item === "Departure" ? 'rgb(255 39 0 / 51%)' : '#00ff0078', margin: '0'}}
            >
              {item}
            </span>
          </div>
        );
      },
      width: "150px",
    },
    {
      title: "UTC TimeStamp",
      dataIndex: "TIMESTAMP_UTC",
      key: "TIMESTAMP_UTC",
      align: "center",
      render: (item) => dayjs(item).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Distance Travelled (In NM)",
      dataIndex: "DISTANCE_TRAVELLED",
      key: "DISTANCE_TRAVELLED",
      align: "center",
      render: (item) => <span>{item}&nbsp;NM</span>,
    },
    {
      title: "Avg Voy Speed (In KN)",
      dataIndex: "VOYAGE_SPEED_AVG",
      key: "VOYAGE_SPEED_AVG",
      align: "center",
      render: (item) => <span> {(Number(item) * 0.1).toFixed(2)}&nbsp;KN</span>,
    },
    {
      title: "Max Voy Speed (In KN)",
      dataIndex: "VOYAGE_SPEED_MAX",
      key: "VOYAGE_SPEED_MAX",
      align: "center",
      render: (item) => <span>{(Number(item) * 0.1).toFixed(2)}&nbsp;KN</span>,
    },
    {
      title: "Last Call Distance (In NM)",
      dataIndex: "DISTANCE_LEG",
      key: "DISTANCE_LEG",
      align: "center",
      render: (item) => <span>{item ? item: 0}&nbsp;NM</span>,
    },
    {
      title: "Voy Time (EXCL. Anchor/Canal)",
      dataIndex: "ELAPSED_MOANCH",
      key: "ELAPSED_MOANCH",
      align: "center",
      render: (item) => <span>{item ? item: 0}&nbsp;min</span>,
    },
    {
      title: "Voy Idle Time (In min)",
      dataIndex: "VOYAGE_IDLE_TIME_MINS",
      key: "VOYAGE_IDLE_TIME_MINS",
      align: "center",
      render: (item) => <span>{item ? item: 0}&nbsp;min</span>,
    },
    {
      title: "Draught",
      dataIndex: "DRAUGHT",
      key: "DRAUGHT",
      align: "center",
      render: (item) => <span>{item}&deg;</span>,
    },
    {
      title: "Intransit",
      dataIndex: "INTRANSIT",
      key: "INTRANSIT",
      align: "center",
    },
    {
      title: "Local TimeStamp",
      dataIndex: "TIMESTAMP_LT",
      key: "TIMESTAMP_LT",
      align: "center",
      render: (item) => dayjs(item).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Load Status",
      dataIndex: "LOAD_STATUS",
      key: "LOAD_STATUS",
      align: "center",
    },
    {
      title: "Port Operation",
      dataIndex: "PORT_OPERATION",
      key: "PORT_OPERATION",
      align: "center",
    },
    {
      title: "IMO",
      dataIndex: "IMO",
      key: "IMO",
      align: "center",
    },
    {
      title: "MMSI",
      dataIndex: "MMSI",
      key: "MMSI",
      align: "center",
    },
    {
      title: "Market",
      dataIndex: "MARKET",
      key: "MARKET",
      align: "center",
    },
    {
      title: "Ship Class",
      dataIndex: "SHIPCLASS",
      key: "SHIPCLASS",
      align: "center",
    },
  ];
  return (
    <div>
      <div style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            backgroundColor: "#eaeaea",
            border: "1px solid #ccc",
            borderRadius: "5px",
            marginBottom: "20px",
          }}
        >
          <span
            style={{ fontSize: "16px", color: "#333", marginRight: "15px" }}
          >
            Port Name: {vesselTableData.length && vesselTableData[0].PORT_NAME}
          </span>
          <span
            style={{ fontSize: "16px", color: "#333", marginRight: "15px" }}
          >
            Longitude:{" "}
            {vesselTableData.length && vesselTableData[0].PORT_LON
              ? Number(vesselTableData[0].PORT_LON).toFixed(5)
              : "-"}
          </span>
          <span style={{ fontSize: "16px", color: "#333" }}>
            Latitude:{" "}
            {/* {vesselTableData.length && vesselTableData[0].PORT_LAT.toFixed(5)} */}
            {vesselTableData.length && vesselTableData[0].PORT_LON
              ? Number(vesselTableData[0].PORT_LAT).toFixed(5)
              : "-"}
          </span>
        </div>

        <Table
          bordered
          scroll={{ x: "max-content" }}
          rowHoverable
          dataSource={vesselTableData}
          columns={columns}
          style={{
            borderRadius: "5px",
            backgroundColor: "white",
            overflow: "hidden",
          }}
          className="vessel-table"
        />
      </div>
    </div>
  );
};

export default VesselInfoTable;
