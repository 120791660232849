import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Avatar,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Input,
  List,
  Menu,
  message,
  Popover,
  Select,
  Skeleton,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchFilters from "./SearchFilter";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  disabledDate,
  events,
  formatTimestamp,
  getInitial,
  returnccbcc,
} from "./constants";
import CurrentMail from "./CurrentMail";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setAllEmails, setVesselName } from "../../actions/emailActions";
import MailRightbar from "./mailRightbar/MailRightbar";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import dayjs from "dayjs";

const Listview = ({ page, setPage, handleSearch, searchData, mailType }) => {
  const allMails = useSelector((state) => state.email.allEmails);
  const sentMails = useSelector((state) => state.email.sentList);
  const draftMails = useSelector((state) => state.email.draftList);
  const binMails = useSelector((state) => state.email.binList);
  const archivedMails = useSelector((state) => state.email.archivedList);
  const importantMails = useSelector((state) => state.email.importantList);
  const spamMails = useSelector((state) => state.email.spamList);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMail, setSelectedMail] = useState({});
  const [currentMail, setCurrentMail] = useState({});
  const [openCurrentMail, setOpenCurrentMail] = useState(false);
  // const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [unique, setUnique] = useState([]);
  const [mailData, setMailData] = useState(allMails);
  const [filterData, setFilterData] = useState({ emails: [] });
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const [isFilters, setIsfilters] = useState(false);
  const [filterSpin, setFilterSpin] = useState(false);
  const [notMore, setNotMore] = useState(true);
  const [filterPagination, setFilterPagination] = useState({
    page: 1,
    pageSize: 20,
  });

  const [filters, setFilters] = useState({
    Invoice: [],
    Sender: [],
    attach: [],
    comments: [],
    date: [],
    mailType: [],
    reciver: [],
    subject: [],
    text: [],
    viewedBy: [],
    tags: [],
    date: [],
  });

  const menu = (
    <Menu>
      <RangePicker allowClear showTime showSecond={false} />
    </Menu>
  );

  useEffect(() => {
    if (searchData.length > 0) {
      setMailData(searchData);
    } else if (mailType === "sent") {
      setMailData(sentMails);
    } else if (mailType === "draft") {
      setMailData(draftMails);
    } else if (mailType === "bin") {
      setMailData(binMails);
    } else if (mailType === "archived") {
      setMailData(archivedMails);
    } else if (mailType === "important") {
      setMailData(importantMails);
    } else if (mailType === "spam") {
      setMailData(spamMails);
    } else if (isFilters) {
      setMailData(filterData.emails);
    } else {
      setMailData(allMails);
    }
  }, [searchData, allMails, mailType, filterPagination, filterData]);

  const loadMoreData = () => {
    if (loading) {
      return;
    }

    if (isFilters) {
      setFilterPagination({
        ...filterPagination,
        page: filterPagination.page + 1,
      });
    } else {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    loadMoreData();
    findUniqueData();
  }, []);

  const handleMailClick = (mail) => {
    setSelectedMail(mail);
    setCurrentMail(mail);
    setOpenCurrentMail(true);
    dispatch(setVesselName({ vesselName: "", isVisible: false }));
  };

  const handleCurrentMailClose = () => {
    setOpenCurrentMail(false);
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedItems(allMails.map((item) => item));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (e, email) => {
    e.stopPropagation();

    console.log("handleSelectItem", email);

    // Use a functional update to ensure you're working with the latest state.
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(email)) {
        // Remove the email if it's already selected
        return prevSelectedItems.filter((item) => item !== email);
      } else {
        // Add the email if it's not selected
        return [...prevSelectedItems, email];
      }
    });
  };

  const markImportant = (userInpu1) => {
    // Destructure the userInput and event from the input object
    const { userInput, event } = userInpu1;
    event.stopPropagation();

    const { _id, important, client } = userInput;

    // Map over allMails to find the email that matches the _id and toggle its important property
    const currentMails = mailData.map((mail) => {
      if (mail._id === _id) {
        // Toggle the important property
        return {
          ...mail,
          important: mail.hasOwnProperty("important") ? !mail.important : true,
        };
      }
      return mail;
    });

    // Update the state with the modified emails
    // setMailData(currentMails);
    dispatch(setAllEmails({ emails: currentMails }));

    // Prepare the payload to be sent via WebSocket
    const payload = {
      context: client,
      _id,
      important: !important, // Toggle the important value
      event: "markImportant",
    };
    // Send the event to the WebSocket
    events.markImportant(payload);
  };

  const handleDeleteMail = async (GmailMessageId, _id, client) => {
    try {
      const payLoad = {
        GmailMessageId,
        _id,
        context: client,
        trashed: true,
        permanent: false,
        event: "deleteMail",
      };

      // Check if webSocketManager is available and WebSocket is open
      const webSocketManager = window?.webSocketManager;
      if (
        webSocketManager &&
        webSocketManager.socket.readyState === WebSocket.OPEN
      ) {
        // Create a unique responseId to track this request
        const responseId = Date.now();

        // Create a promise to handle the delete response
        const responsePromise = new Promise((resolve, reject) => {
          // Define a temporary event handler
          const handleResponse = (response) => {
            // console.log({ response });
            // if (response?.responseId === responseId) {
            if (response?.content?.AcknowledgmentID) {
              message.success("Mail successfully deleted");
              setCurrentMail({});
              handleCurrentMailClose();
              const filteredMails = mailData?.filter(
                (mail) => mail?._id !== _id
              );
              setMailData(filteredMails);
            } else {
              message.error("Failed to delete mail");
            }
            resolve(response);
            // Remove the event listener after handling the response
            webSocketManager.off("deleteMail", handleResponse);
            // }
          };

          // Register the temporary event handler
          webSocketManager.on("deleteMail", handleResponse);
        });

        // Send payload with the unique responseId
        await webSocketManager.send({ ...payLoad, responseId });

        // Await the response promise
        await responsePromise;
      } else {
        message.error("Please try again.");
        console.error("WebSocket is not open or not initialized.");
      }
    } catch (error) {
      console.error("WebSocket error:", error);
      message.error("Failed to delete mail due to WebSocket error");
    }
  };

  const handleDeleteConfirmation = (mailDetail) => {
    // console.log({mailDetail})
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteMail(
          mailDetail.GmailMessageId,
          mailDetail._id,
          mailDetail.client
        );
        // handleDeleteMail(mailDetail: {})
      }
    });
  };

  const findUniqueData = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/find-unique`;
      const payload = { owner_email: "*" };
      const response = await postAPICall(url, payload, "post", (data) => {
        if (data.data) {
          setUnique(data?.data);
        }
      });
    } catch (e) {
      console.error("Error fetching unique data:", e);
    }
  };

  const handleViewedBy = async (value) => {
    try {
      const trimmedValue = value.trim();

      if (trimmedValue.length > 1) {
        const url = `${process.env.REACT_APP_MT_URL}mail/all-mails?query=${trimmedValue}`;

        const response = await postAPICall(url, {}, "get", (data) => {
          const options = data?.emails?.map((mail) => ({
            label: mail,
            value: mail,
          }));

          setUnique((prev) => ({
            ...prev,
            viewMails: options,
          }));
        });
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  const handleChange = (name, value) => {
    if (value) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: [...prevFilters[name], value], // Update the specific filter dynamically
      }));
    } else {
      openNotificationWithIcon("info", "Put Some value for filter.");
    }
  };

  const handleChipClose = (item, name) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: prevFilters[name].filter((val) => val !== item), // Remove the specific filter value
    }));
  };

  const onChange = (dates, value, dateString) => {
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = dayjs(new Date(dates[0])).format("YYYY/MM/DD");
      const formattedEndDate = dayjs(new Date(dates[1])).format("YYYY/MM/DD");

      const dateRangeString = `${formattedStartDate}-${formattedEndDate}`;
      if (value) {
        setFilters((prev) => ({
          ...prev,
          [value]: [...(prev[value] || []), dateRangeString],
        }));
      }
    }
  };

  const renderSelectedChips = (filterKey, filterValues) => {
    return filterValues.length ? (
      filterValues?.map((item) => (
        <Tag
          color="processing"
          key={item}
          closable
          onClose={() => handleChipClose(item, filterKey)}
        >
          {item}
        </Tag>
      ))
    ) : (
      <span style={{ fontSize: "12px", color: "lightgray" }}>
        No Active Filter
      </span>
    );
  };

  const handleSelectChange = (value, field) => {
    setFilters((prev) => ({
      ...prev,
      [field]: Array.isArray(value) ? value : [value], // Always store as array
    }));
  };

  const handleFilterSubmit = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/getFIlterTonnageMails?page=${filterPagination.page}&pageSize=${filterPagination.pageSize}`;
      const updatedFilters = { ...filters };

      const payload = {
        owner_email: "*",
        query: [updatedFilters], // Use the cloned filters object
      };

      // Check if any filter has data
      const hasData = Object.values(payload.query[0]).some((field) =>
        Array.isArray(field) ? field.length > 0 : field
      );

      if (hasData) {
        setIsfilters(true);
        setFilterSpin(true);
        const response = await postAPICall(url, payload, "post", (data) => {
          // console.log("oapoapisada", data);
          const newMails = data?.emails;

          if (newMails?.length > 0) {
            setFilterData((prev) => ({
              ...prev,
              emails: [...(prev.emails || []), ...newMails], // Use a fallback empty array if prev.emails is undefined
            }));
            setFilterSpin(false);
          } else {
            setNotMore(false);
            openNotificationWithIcon("info", "No data available");
            setFilterSpin(false);
          }
        });
      } else {
        setIsfilters(false);
        setMailData(allMails);
      }
    } catch (e) {
      console.error("Error filtering data:", e);
      setFilterSpin(false);
    }
  };

  useEffect(() => {
    handleFilterSubmit();
  }, [filters, filterPagination, isFilters]);

  const TagsPopover = ({ item }) => {
    const content = (
      <div style={{ display: "flex", flexWrap: "wrap", columnGap: "3px" }}>
        {item?.tags?.length > 0
          ? item?.tags?.map((tag, index) => (
              <span
                style={{
                  textAlign: "center",
                  marginBottom: "3px",
                  width: "48%",
                }}
                className="list_view_tags"
                key={index}
              >
                {tag}
              </span>
            ))
          : "No Tags Available"}
      </div>
    );

    return (
      <Popover
        content={content}
        title="Tags"
        arrow
        trigger={["click", "hover"]}
      >
        {item?.tags?.length > 0
          ? item?.tags.slice(0, 1).map((tag, index) => (
              <span className="list_view_tags" key={index}>
                {tag}
              </span>
            ))
          : "N/A"}
      </Popover>
    );
  };

  const ListCheckbox = ({ item }) => {
    const content = <div>HIIII</div>;
    return (
      <Popover content={content} trigger={["click"]}>
        <div>
          <Checkbox
            key={item["_id"] || item?.id}
            checked={selectedItems.includes(item)}
            // onChange={(e) => {
            //   e.stopPropagation();
            // }}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectItem(e, item);
            }}
            style={{ marginRight: "10px" }}
          />
        </div>
      </Popover>
    );
  };

  console.log("Selected Item", selectedItems);

  const handleBulkAction = (eventType) => {
    const payload = selectedItems?.map((email) => ({
      GmailMessageId: email?.GmailMessageId, // Assuming selectedItems contains GmailMessageIds
      event: eventType,
      context: "inbox", // Adjust based on your context
      inbox: "fix@theoceann.ai", // Set the correct inbox dynamically if needed
    }));
    console.log("Bulk payload sent:", payload);

    if (window.mySocketMail && window.mySocketMail.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(payload));
      message.info("Operation Success.")
    } else {
      console.error("WebSocket connection is not open");
    }
  };

  // Handler functions for different actions
  const handleMarkAsRead = () => handleBulkAction("markAsRead");
  const handleMarkAsUnread = () => handleBulkAction("markAsUnread");
  const handleDeleteMails = () => handleBulkAction("deleteMail");
  const handleMoveToSpam = () => handleBulkAction("moveToSpam");
  const handleMarkAsImportant = () => handleBulkAction("markAsImportant");
  const handleMarkAsUnimportant = () => handleBulkAction("markAsUnimportant");

  return (
    <div className="list-view" style={{ position: "relative" }}>
      {!openCurrentMail ? (
        <>
          {selectedItems?.length > 0 && (
            <div className="selected_items_box">
              <Tooltip title="Marked Read.">
                <Icon icon={"tabler:mail-opened"} onClick={handleMarkAsRead} />
              </Tooltip>

              <Tooltip title="Marked as Unread.">
                <Icon icon={"tabler:mail"} onClick={handleMarkAsUnread} />
              </Tooltip>

              <Tooltip title="Delete Selected Mails.">
                <Icon
                  icon={"tabler:trash"}
                  style={{ color: "red" }}
                  onClick={handleDeleteMails}
                />
              </Tooltip>

              <Tooltip title="Move to Spam.">
                <Icon
                  icon={"tabler:alert-octagon"}
                  style={{ color: "gray" }}
                  onClick={handleMoveToSpam}
                />
              </Tooltip>

              <Tooltip title="Mark as Unimportant.">
                <Icon
                  icon={"tabler:star-filled"}
                  style={{ color: "goldenrod" }}
                  onClick={handleMarkAsUnimportant}
                />
              </Tooltip>

              <Tooltip title="Mark as Important.">
                <Icon
                  icon={"tabler:star"}
                  style={{ color: "goldenrod" }}
                  onClick={handleMarkAsImportant}
                />
              </Tooltip>
            </div>
          )}

          <div className="mail_list_header">
            <div></div>

            <div className="mail_filter_row_box">
              <div className="mail_filter_row">
                <div
                  className="filter_icons"
                  onClick={() => setIsFilterOpen(!isFilterOpen)}
                >
                  <Icon icon={"tabler:filter"} />
                </div>

                <Drawer
                  mask={false}
                  className="mail_filter_drawer"
                  title="Search Filters :"
                  onClose={() => setIsFilterOpen(false)}
                  open={isFilterOpen}
                >
                  <SearchFilters />
                </Drawer>

                <Input type="text" placeholder="Search for emails..." />
                <Dropdown overlay={menu} placement="bottomLeft" arrow>
                  <div className="filter_icons">
                    <Icon icon={"tabler:calendar"} />
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="list-view-filter-table">
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center", width: "50px" }}></td>
                  <td>
                    <div>
                      <b>Received Date</b>
                    </div>
                    <RangePicker
                      value={null}
                      disabledDate={disabledDate}
                      onChange={(event, dateString) =>
                        onChange(event, "date", dateString)
                      }
                    />
                  </td>
                  <td>
                    <div>
                      <b>Sender</b>
                    </div>
                    <AutoComplete
                      placeholder="Search Sender"
                      style={{ width: 200 }}
                      onSearch={handleViewedBy}
                      onSelect={(value) => handleChange("Sender", value)}
                      options={unique?.viewMails || []}
                    />
                  </td>
                  <td>
                    <div>
                      <b>Subject</b>
                    </div>
                    <Input
                      placeholder="Subject"
                      onPressEnter={(e) =>
                        handleChange("subject", e.target.value)
                      }
                      onBlur={(e) => handleChange("subject", e.tar.value)}
                    />
                  </td>
                  <td>
                    <div>
                      <b>Viewed By</b>
                    </div>
                    <AutoComplete
                      placeholder="Search Viewed by"
                      style={{ width: 200 }}
                      onSearch={handleViewedBy}
                      allowClear
                      onSelect={(value) => handleChange("viewedBy", value)}
                      options={unique?.viewMails || []}
                    />
                  </td>
                  <td>
                    <div>
                      <b>Tags</b>
                    </div>
                    <Select
                      allowClear
                      showSearch
                      maxTagCount={0}
                      mode="multiple"
                      placeholder="Choose Tags"
                      value={filters?.tags}
                      onChange={(value) => handleSelectChange(value, "tags")}
                    >
                      {unique?.unique_tags_mails?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <div>
                      <b>Mail type</b>
                    </div>
                    <Select
                      placeholder="Mail Type"
                      mode="multiple"
                      maxTagCount={0}
                      value={filters?.mailType}
                      onChange={(value) =>
                        handleSelectChange(value, "mailType")
                      }
                    >
                      <Select.Option value="cargo">Cargo</Select.Option>
                      <Select.Option value="tonnage">Tonnage</Select.Option>
                      <Select.Option value="fixture">Fixture</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </td>
                  <td>
                    <div>
                      <b>Receivers</b>
                    </div>
                    <Select
                      allowClear
                      maxTagCount={0}
                      value={filters?.reciver}
                      showSearch
                      mode="multiple"
                      placeholder="Choose Receivers"
                      onChange={(value) => handleSelectChange(value, "reciver")}
                    >
                      {unique?.unique_receiver_mails?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                </tr>

                {/* Second row for displaying selected filters */}
                <tr className="chips-table-row">
                  <td style={{ width: "89px" }}>
                    <Checkbox
                      id="selectall"
                      onChange={handleSelectAll}
                      checked={selectAll}
                    />
                  </td>
                  <td>{renderSelectedChips("date", filters.date)}</td>
                  <td>{renderSelectedChips("Sender", filters.Sender)}</td>
                  <td>{renderSelectedChips("subject", filters.subject)}</td>
                  <td>{renderSelectedChips("viewedBy", filters.viewedBy)}</td>
                  <td>{renderSelectedChips("tags", filters.tags)}</td>
                  <td>{renderSelectedChips("mailType", filters.mailType)}</td>
                  <td>{renderSelectedChips("reciver", filters.reciver)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="list-view-title-row-box"
            style={{ position: "sticky", top: "0" }}
          >
            <table className="list-view-title-row">
              <tbody>
                <tr>
                  <td style={{ width: "75px" }}>Select</td>
                  <td style={{ width: "95px" }}>Timestamp</td>
                  <td style={{ width: "250px" }}>Sender</td>
                  <td style={{ width: "300px" }}>Subject</td>

                  <td style={{ width: "199px" }}>Viewed By</td>
                  <td>Tags</td>
                  <td style={{ width: "199px" }}>Mail type/Vessels</td>
                  <td>Receivers</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            id="scrollableDiv"
            className="mail_scrolls"
            style={{
              height: "85vh",
              overflowY: "scroll",
              padding: "0 16px",
              border: "1px solid rgba(140, 140, 140, 0.35)",
            }}
          >
            <InfiniteScroll
              dataLength={mailData.length}
              next={loadMoreData}
              hasMore={notMore}
              loader={
                <>
                  {[...Array(5).keys()].map((item) => (
                    <Skeleton
                      avatar
                      paragraph={{
                        rows: 1,
                      }}
                      active
                    />
                  ))}
                </>
              }
              endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={mailData}
                split
                renderItem={(item, index) => {
                  const ccBccList = returnccbcc(item);
                  return (
                    <List.Item
                      key={item.email}
                      onClick={() => handleMailClick(item)}
                      className={item?.isRead ? "already-read" : "not-read"}
                    >
                      <ListCheckbox item={item} />
                      <List.Item.Meta
                        avatar={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div className="list-view-timestamp">
                              {formatTimestamp(item.TIMESTAMP || item.Date)}
                            </div>
                            {/* <div className="user_mail_profile">
                              {getInitial(item?.Sender)}
                            </div> */}
                          </div>
                        }
                        title={<a>{item.Sender}</a>}
                        description={
                          <>
                            <div className="mail_list_view_container">
                              <div className="mail_list_description">
                                <div className="mail_list_sub">
                                  <b>{item.Subject}</b>
                                  {item?.Threads && item?.Threads.length > 0 ? (
                                    <Tooltip
                                      title={`Thread count ${
                                        item?.Threads
                                          ? item?.Threads.length
                                          : ""
                                      }`}
                                    >
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          padding: "2px 6px",
                                          color: "green",
                                        }}
                                      >
                                        {item?.Threads
                                          ? item?.Threads.length
                                          : ""}
                                      </span>
                                    </Tooltip>
                                  ) : null}
                                </div>
                                <Tooltip
                                  title={
                                    Array.isArray(item?.readBy)
                                      ? item.readBy.map((data, index) => (
                                          <div key={index}>{String(data)}</div> // Convert to string
                                        ))
                                      : item?.readBy
                                      ? String(item.readBy) // Convert to string
                                      : "unread email"
                                  }
                                >
                                  <div style={{ marginLeft: "4px" }}>
                                    <Icon
                                      icon="mdi:email-outline"
                                      fontSize={14}
                                      style={{ color: "#1C5CE9" }}
                                    />
                                  </div>
                                </Tooltip>
                                <div style={{ marginLeft: "4px" }}>
                                  <Tooltip
                                    title={
                                      ccBccList.length > 0
                                        ? ccBccList?.map((ccItem, i) => (
                                            <div key={i}>
                                              {ccItem?.name || ccItem?.email}
                                            </div>
                                          ))
                                        : "N/A"
                                    }
                                  >
                                    <div>
                                      <Icon
                                        icon="ph:users"
                                        fontSize={14}
                                        style={{ color: "#00712D" }}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                {item?.labelIds?.includes("DRAFT") && (
                                  <span style={{ color: "#dd4b39" }}>
                                    (Draft)
                                  </span>
                                )}

                                <div className="mail_list_snippet">
                                  {item.snippet}
                                </div>

                                {item?.isRead && Array.isArray(item?.readBy) ? (
                                  item?.readBy?.map((readByItem, i) => (
                                    <span key={i} className="ReadBy-text">
                                      {String(readByItem)}
                                    </span> // Convert to string
                                  ))
                                ) : item?.isRead ? (
                                  <span className="ReadBy-text">
                                    {String(item.isRead)}
                                  </span> // Convert to string
                                ) : (
                                  <span className="ReadBy-text">N/A</span>
                                )}

                                <div className="list_view_tags_box">
                                  <TagsPopover item={item} />
                                </div>

                                <div className="mail_list_labels mailType">
                                  <Tooltip title="Mail Type">
                                    <span className="mail_list_badge">
                                      {item.MailType}
                                    </span>
                                  </Tooltip>
                                </div>

                                <div className="mail_list_labels mail_vessels">
                                  {Array.isArray(item?.vessel_name) &&
                                    item?.vessel_name.length > 0 &&
                                    item?.vessel_name
                                      .slice(0, 1)
                                      .map((vessel, index) => (
                                        <Tooltip
                                          key={index}
                                          title="Vessel Name"
                                        >
                                          <span className="mail_list_badge">
                                            {vessel}
                                          </span>
                                        </Tooltip>
                                      ))}
                                </div>

                                <Avatar.Group>
                                  {/* Check if bccRecipients is a valid array and map over it */}
                                  {Array.isArray(item?.bccRecipients) &&
                                    item?.bccRecipients.length > 0 &&
                                    item?.bccRecipients.map((sender, index) => (
                                      <Tooltip
                                        key={`bcc-${index}`}
                                        title={
                                          sender?.name ||
                                          sender?.emailAddress?.name
                                        }
                                      >
                                        <Avatar
                                          size="small"
                                          style={{
                                            background: "green",
                                          }}
                                        >
                                          {getInitial(
                                            sender?.name ||
                                              sender?.emailAddress?.name
                                          )}
                                        </Avatar>
                                      </Tooltip>
                                    ))}

                                  {/* Check if ccRecipients is a valid array and map over it */}
                                  {Array.isArray(item?.ccRecipients) &&
                                    item?.ccRecipients.length > 0 &&
                                    item?.ccRecipients.map((sender, index) => (
                                      <Tooltip
                                        key={`cc-${index}`}
                                        title={
                                          sender?.name ||
                                          sender?.emailAddress?.name
                                        }
                                      >
                                        <Avatar
                                          size="small"
                                          style={{
                                            background: "orange",
                                          }}
                                        >
                                          {getInitial(
                                            sender?.name ||
                                              sender?.emailAddress?.name
                                          )}
                                        </Avatar>
                                      </Tooltip>
                                    ))}
                                </Avatar.Group>
                              </div>

                              <div className="mail_list_attach_icon">
                                <Icon
                                  fontSize={14}
                                  icon="mdi:attachment"
                                  style={{ color: "purple" }}
                                />
                                <Tooltip title="Click to Delete this Mail.">
                                  <Icon
                                    fontSize={14}
                                    style={{ color: "red" }}
                                    icon="tabler:trash"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteConfirmation({
                                        GmailMessageId: item?.GmailMessageId,
                                        _id: item?._id,
                                        client: item?.client,
                                      });
                                    }}
                                  />
                                </Tooltip>

                                <Tooltip title="Click to add or manage tags for this email to categorize it.">
                                  <Icon
                                    fontSize={14}
                                    style={{ color: "green" }}
                                    icon="tabler:tag"
                                  />
                                </Tooltip>

                                <Tooltip title="Click to mark as important or unimportant.">
                                  <Icon
                                    fontSize={14}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      markImportant({ event, userInput: item });
                                    }}
                                    icon={
                                      item?.important
                                        ? "tabler:star-filled"
                                        : "tabler:star"
                                    }
                                    style={{ color: "orange" }}
                                  />
                                </Tooltip>

                                <Avatar.Group>
                                  {/* Check if bccRecipients is a valid array and map over it */}
                                  {Array.isArray(item?.bccRecipients) &&
                                    item?.bccRecipients.length > 0 &&
                                    item?.bccRecipients.map((sender, index) => (
                                      <Tooltip
                                        key={`bcc-${index}`}
                                        title={
                                          sender?.name ||
                                          sender?.emailAddress?.name
                                        }
                                      >
                                        <Avatar
                                          size="small"
                                          style={{
                                            background: "violet",
                                          }}
                                        >
                                          {getInitial(
                                            sender?.name ||
                                              sender?.emailAddress?.name
                                          )}
                                        </Avatar>
                                      </Tooltip>
                                    ))}

                                  {/* Check if ccRecipients is a valid array and map over it */}
                                  {Array.isArray(item?.ccRecipients) &&
                                    item?.ccRecipients.length > 0 &&
                                    item?.ccRecipients.map((sender, index) => (
                                      <Tooltip
                                        key={`cc-${index}`}
                                        title={
                                          sender?.name ||
                                          sender?.emailAddress?.name
                                        }
                                      >
                                        <Avatar
                                          size="small"
                                          style={{
                                            background: "brown",
                                          }}
                                        >
                                          {getInitial(
                                            sender?.name ||
                                              sender?.emailAddress?.name
                                          )}
                                        </Avatar>
                                      </Tooltip>
                                    ))}
                                </Avatar.Group>
                              </div>
                            </div>
                          </>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </InfiniteScroll>
          </div>
        </>
      ) : (
        <>
          {/* <CurrentMail
            mail={selectedMail}
            handleCurrentMailClose={handleCurrentMailClose}
          />
          {selectedMail.Threads && selectedMail.Threads.length > 0
            ? selectedMail.Threads?.map((thread) => (
                <div style={{ padding: "0px 14px" }}>
                  <CurrentMail threads={true} mail={thread} />
                </div>
              ))
            : ""} */}
          <div style={{ padding: "0 55px 0 24px", minHeight: 280 }}>
            <CurrentMail
              mail={currentMail}
              setCurrentMail={setCurrentMail}
              threads={false}
              threadData={currentMail?.Threads}
              allMails={mailData}
              setMailData={setMailData}
              handleDeleteMail={handleDeleteConfirmation}
              handleCurrentMailClose={handleCurrentMailClose}
              openCurrentMail={openCurrentMail}
            />

            {(currentMail["_id"] ||
              currentMail["id"] ||
              currentMail?.GmailMessageId) && (
              <MailRightbar currentMail={currentMail} />
            )}
          </div>
        </>
      )}

      <Spin fullscreen spinning={filterSpin} />
    </div>
  );
};

export default Listview;
