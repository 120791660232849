import React, { Component } from 'react';
import {  Progress, Tabs, Spin } from 'antd';
import {HistoryOutlined,CaretUpOutlined, CloudOutlined, StockOutlined,SwapOutlined } from '@ant-design/icons';
import URL_WITH_VERSION, {
  getAPICall,IMAGE_PATH
} from '../../shared';
const TabPane = Tabs.TabPane;
class ShipDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shipDetail: null,
      report_type: 'Noon'
    }
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let type = 0
    if (this.props && this.props.data && this.props.data.report_types) {
      type = this.props.data.report_types === "Arrival" ? 191 :
        this.props.data.report_types === "Noon" ? 189 :
          this.props.data.report_types === "Departure" ? 190 : null
      this.setState({
        report_type: this.props.data.report_types
      })
    }
    let _url = `${URL_WITH_VERSION}/noon-verification/sreport?ae=${"this.props.data.vessel_id"}&type=${type}`;
    const response = await getAPICall(_url);
    const data = await response['data'];
    this.props.changeTitle(data && data['vessel_namevspm_id'] ? data['vessel_namevspm_id'] : 'Spring')
    this.setState({
      shipDetail: data,
    })
  };

  render() {
    const { shipDetail, report_type } = this.state
    let total = 0;
    if (shipDetail && shipDetail.list_data && shipDetail.list_data.length) {
      if (shipDetail.list_data[0] && shipDetail.list_data[0].tco2e_ifo)
        total = total + parseFloat(shipDetail.list_data[0].tco2e_ifo);
      if (shipDetail.list_data[0] && shipDetail.list_data[0].tco2e_vlsfo)
        total = total + parseFloat(shipDetail.list_data[0].tco2e_vlsfo);
      if (shipDetail.list_data[0] && shipDetail.list_data[0].tco2e_lsmgo)
        total = total + parseFloat(shipDetail.list_data[0].tco2e_lsmgo);
      if (shipDetail.list_data[0] && shipDetail.list_data[0].tco2e_mgo)
        total = total + parseFloat(shipDetail.list_data[0].tco2e_mgo);
      if (shipDetail.list_data[0] && shipDetail.list_data[0].tco2e_ulsfo)
        total = total + parseFloat(shipDetail.list_data[0].tco2e_ulsfo);
    }
    return (
      <div className="body-wrapper">
        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <div className="shipdetails-header">
                <div className="shipdetail-header-img">
                  <img src={IMAGE_PATH+"icons/ship.png"} alt="" />
                </div>
                <div className="shipdetail-header-kms">
                  <p style={{ color: "rgb(0, 157, 196)" }}>
                    <b>
                    <HistoryOutlined className="mr-2" />
                      GPS ( N/A)
                    </b>
                  </p>
                  <p style={{ color: "rgb(0, 157, 196)" }}>
                    <b>
                    <CaretUpOutlined  className="mr-2" />
                      CO2 Emission:&nbsp;
                    </b>
                    <span >{total}</span>
                  </p>
                </div>
                <div className="shipdetail-header-lat-long">
                  <p className="text-right" style={{ color: '#0726ff' }}>{shipDetail && shipDetail.maininformation && shipDetail.maininformation.lat ? shipDetail.maininformation.lat : '--'}N{" , "}{shipDetail && shipDetail.maininformation && shipDetail.maininformation.lng ? shipDetail.maininformation.lng : '--'}W</p>
                  <p className="text-right" style={{ color: '#0726ff' }}>{shipDetail && shipDetail.maininformation && shipDetail.maininformation.time_zone ? shipDetail.maininformation.time_zone : '--'}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Progress percent={50} showInfo={false} />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <p className="text-left" style={{ color: '#e65cb5' }}><b>Hours to GO</b>:&nbsp;&nbsp;{shipDetail && shipDetail.portinformation && shipDetail.portinformation.hours_to_go ? shipDetail.portinformation.hours_to_go : '--'}</p>
                </div>

                <div className="col-md-4">
                  <p className="text-center" style={{ color: '#eda1d2' }}>
                    <b>ETA</b>:&nbsp;&nbsp;{shipDetail && shipDetail.portinformation && shipDetail.portinformation.eta ? shipDetail.portinformation.eta : '--'}
                  </p>
                </div>

                <div className="col-md-4">
                  <p className="text-right" style={{ color: '#ba5d99' }}>
                    <b>DTG</b>:&nbsp;&nbsp;{shipDetail && shipDetail.portinformation && shipDetail.portinformation.dtg ? shipDetail.portinformation.dtg : '--'}
                  </p>
                </div>
              </div>

              <Tabs defaultActiveKey="performance" size="small">
                <TabPane className="pt-3" tab="Performance" key="performance">
                  <p>
                    <b>Latest Report ({report_type})</b>{' '}
                    <span className="float-right">
                      <a>Sailing</a>
                    </span>
                  </p>
                  <div className="row">
                    <div className="col-md-3">
                      <p style={{ color: "#fc28a1" }}>
                        <b>Time:</b>
                        &nbsp;&nbsp;{shipDetail && shipDetail.portinformation && shipDetail.portinformation.eta ? shipDetail.portinformation.eta : '--'}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p style={{ color: "#f739db" }}>
                      <HistoryOutlined  className="mr-2" />
                        {shipDetail && shipDetail.vesselanddistance && shipDetail.vesselanddistance.steaming_hr ? shipDetail.vesselanddistance.steaming_hr : '--'}(Hr)
                      </p>
                    </div>

                    <div className="col-md-3">
                      <p style={{ color: "#f79b4f" }}>
                      <CloudOutlined  className="mr-2" />
                        {shipDetail && shipDetail.vesselanddistance && shipDetail.vesselanddistance.sea_state ? shipDetail.vesselanddistance.sea_state : '--'}{' '}(BFT)
                      </p>
                    </div>

                    <div className="col-md-3">
                      <p style={{ color: "#f76b60" }}>
                      <StockOutlined  className="mr-2" />
                        {shipDetail && shipDetail.vesselanddistance && shipDetail.vesselanddistance.swell ? shipDetail.vesselanddistance.swell : '--'}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <h2 className={shipDetail && shipDetail.vesselanddistance && shipDetail.vesselanddistance.reported_spd && parseFloat(shipDetail.vesselanddistance.reported_spd) > 0 ? "mb-0 text-success" : "mb-0 text-danger"}>{shipDetail && shipDetail.vesselanddistance && shipDetail.vesselanddistance.reported_spd ? shipDetail.vesselanddistance.reported_spd : '--'}
                        {/* <small><sup className="text-dark">-0.8</sup></small> */}
                      </h2>
                      <p className="m-0"><HistoryOutlined  className="mr-2" />Knts</p>
                      <p className="m-0">Reported speed</p>
                    </div>

                    <div className="col-md-4">
                      <h2 style={{ color: 'rgb(0, 157, 196)' }}>{shipDetail && shipDetail.list_data && shipDetail.list_data.length > 0
                        && shipDetail.list_data[0].change_spd_overall ? shipDetail.list_data[0].change_spd_overall : '--'}%</h2>
                      <p className="m-0"><SwapOutlined className="mr-2" />Per/Day</p>
                      <p className="m-0">Speed variation</p>
                    </div>

                    <div className="col-md-4">
                      <h2 className={shipDetail && shipDetail.vesselanddistance && shipDetail.vesselanddistance.ordered_spd && parseFloat(shipDetail.vesselanddistance.ordered_spd) > 0 ? "mb-0 text-success" : "mb-0 text-danger"}>{shipDetail && shipDetail.vesselanddistance && shipDetail.vesselanddistance.ordered_spd ? shipDetail.vesselanddistance.ordered_spd : '--'}
                        {/* <small><sup className="text-dark">-0.8</sup></small> */}
                      </h2>
                      <p className="m-0"><HistoryOutlined  className="mr-2" />Knts</p>
                      <p className="m-0">Ordered Speed</p>
                    </div>

                    <div className="col-md-4">
                      <h2 style={{ color: '#65f0e6' }}>{shipDetail && shipDetail.vesselanddistance && shipDetail.vesselanddistance.mid_draft ? shipDetail.vesselanddistance.mid_draft : '--'}<span style={{ fontSize: '15px' }}>&nbsp;Meter</span></h2>
                      {/* <p className="m-0"><Icon type="download" className="mr-2"/>m</p> */}
                      <p className="m-0">Mid draft</p>
                    </div>

                    <div className="col-md-4">
                      <h2 style={{ color: '#2ed9cc' }}>{shipDetail && shipDetail.vesselanddistance && shipDetail.vesselanddistance.slip_precentage ? shipDetail.vesselanddistance.slip_precentage : '--'}</h2>
                      {/* <p className="m-0"><Icon type="history" className="mr-2"/>kts</p> */}
                      <p className="m-0">Slip %</p>
                    </div>

                    <div className="col-md-4">
                      <h2 style={{ color: '#03ffea' }}>
                        {shipDetail && shipDetail.list_data && shipDetail.list_data.length > 0
                          && shipDetail.list_data[0].avg_sea_cons ? shipDetail.list_data[0].avg_sea_cons : '--'}
                      </h2>
                      {/* <p className="m-0"><Icon type="code-sandbox" className="mr-2"/>000 MT</p> */}
                      <p className="m-0">Fuel cons./Day</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <ul className="more-tab-details">
                        <li>Fuel cons. Per day <span><b>
                          {shipDetail && shipDetail.list_data && shipDetail.list_data.length > 0
                            && shipDetail.list_data[0].avg_sea_cons ? shipDetail.list_data[0].avg_sea_cons : '--'}
                        </b></span></li>
                        <li>Nominal Slip <span><b>{shipDetail && shipDetail.vesselanddistance && shipDetail.vesselanddistance.slip_precentage ? shipDetail.vesselanddistance.slip_precentage : '--'}</b> %</span></li>
                        <li>Total Co2 Emission <span><b>
                          {total}
                        </b> %</span></li>
                      </ul>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default ShipDetail;
