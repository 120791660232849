import React from 'react';

const PortDetails = ({ portData }) => {
  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Port Details</h2>
      <div style={styles.row}>
        <div style={styles.column}>
          <div style={styles.field}>
            <span style={styles.label}>Port Name:</span> 
            <span style={styles.value}>{portData?.port_name}</span>
          </div>
          <div style={styles.field}>
            <span style={styles.label}>Latitude:</span> 
            <span style={styles.value}>{portData?.latitude ? portData.latitude : 'N/A'}</span>
          </div>
          <div style={styles.field}>
            <span style={styles.label}>Longitude:</span> 
            <span style={styles.value}>{portData?.longitude ? portData.longitude : 'N/A'}</span>
          </div>
          <div style={styles.field}>
            <span style={styles.label}>ECA:</span> 
            <span style={styles.value}>{portData?.eca ? 'Yes' : 'No'}</span>
          </div>
        </div>
        <div style={styles.column}>
        <div style={styles.field}>
            <span style={styles.label}>Country:</span> 
            <span style={styles.value}>{portData?.country_name}</span>
          </div>
          <div style={styles.column}>
          <div style={styles.field}>
            <span style={styles.label}>Port Type:</span> 
            <span style={styles.value}>{portData?.port_type}</span>
          </div>
          <div style={styles.field}>
            <span style={styles.label}>European Status:</span> 
            <span style={styles.value}>{portData?.european_status ? 'Yes' : 'No'}</span>
          </div>
          <div style={styles.field}>
            <span style={styles.label}>Low Sulphur:</span> 
            <span style={styles.value}>{portData?.low_sulphur ? 'Yes' : 'No'}</span>
          </div>
        </div>
        
          
        </div>
      </div>
    </div>
  );
};

// Example styling to make the layout visually appealing
const styles = {
  container: {
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    // backgroundColor: '#f9f9f9',
    // maxWidth: '600px',
    // margin: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    textAlign: 'start',
    marginBottom: '20px',
    color: '#333',
    fontSize: '18px',
    fontWeight:"bold"
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  },
  column: {
    flex: 1,
  },
  field: {
    marginBottom: '10px',
  },
  label: {
    fontWeight: 'bold',
    color: '#555',
    fontSize: '16px',
  },
  value: {
    marginLeft: '5px',
    color: '#333',
    fontSize: '16px',
  },
};

export default PortDetails;
