import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import DynamicMap from "../dynamic-vspm/DynamicMap";
import mapboxgl from "mapbox-gl";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../shared";
import { LoadingOutlined } from "@ant-design/icons";
import PortCongestionSlider from "./PortCongestionSlider";
import VesselInfoTable from "./VesselInfoTable";
// import PortCongestionSlider from "./PortCongestionSlider";

mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_TOKEN}`;

const PortCongestion = (props) => {
  const [filters, setFilters] = useState({
    port_name: "",
    weekForm: "1",
    weekTo: "",
    year: "2024",
  });
  const [vesselTableData, setVesselTableData] = useState([]);
  const [coords, setCoords] = useState({
    lng: "",
    lat: "",
  });
  const [mapcoords, setMapcoords] = useState({
    lng: "",
    lat: "",
  });
  const [port, setPort] = useState(props?.ports);
  const [portName, setPortName] = useState("");
  const [portNameOptions, setPortNameOptions] = useState([]);
  const [portCongestion, setPortCongestion] = useState([{}]);
  const [selectedPort, setSelectedPort] = useState("");
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(false);
  const mapContainer = useRef(null);
  const { Option } = Select;
  const [currentPort, setCurrentPort] = useState(null);
  const [market, setMarket] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [ShipClass, setShipClass] = useState([]);
  const [selectedShipClass, setSelectedShipClass] = useState("");
  const [isVessel, setIsVessel] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const initializeMap = () => {
      const maxBounds = [
        [-180, -90], // Southwest coordinates
        [180, 90], // Northeast coordinates
      ];

      const fetchPortData = async () => {
        try {
          const value = props.ports[0].port_name;
          const urlSelect = `${process.env.REACT_APP_MT_URL}marine/get-port-congestion-name?port_name=${value}`;
          const respSelect = await postAPICall(urlSelect);

          if (respSelect.error && props.ports) {
            openNotificationWithIcon(
              "info",
              "MarineTraffic and Netpass port ID mismatch!!",
              2
            );
            return;
          }

          if (respSelect.error) {
            openNotificationWithIcon("info", "No Port Found!!", 2);
            return;
          }

          let newPort = respSelect.data.filter(
            (port) => port.port_name === value.toUpperCase()
          );
          if (newPort.length > 0) {
            setSelectedPort(newPort[0].port_id);
            // setPort(newPort[0].port_id)
            setCoords({ lng: newPort[0].centerx, lat: newPort[0].centery });
            setCurrentPort(value);
          } else {
            openNotificationWithIcon("info", "No Port Found!!", 2);
            return;
          }
          const weekFrom = filters.weekForm === "" ? 1 : filters.weekForm;
          const weekTo = filters.weekTo === "" ? 1 : filters.weekTo;

          const urlSearch = `${process.env.REACT_APP_MT_URL}marine/get-port-congestion?portid=${newPort[0].port_id}&week=${weekFrom},${weekTo}&year=${filters.year}`;
          const respSearch = await getAPICall(urlSearch);

          if (respSearch?.data === undefined) {
            openNotificationWithIcon("info", "No Data Found!!");
            return;
          } else {
            setPortCongestion(respSearch.data);

            setMapcoords({
              lng: newPort[0].centerx,
              lat: newPort[0].centery,
            });
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      };

      if (props.ports) {
        fetchPortData();
      }

      const mapInstance = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [0, 0],
        minZoom: 2,
        projection: "mercator",
        maxBounds: maxBounds,
        zoom: 2,
        preserveDrawingBuffer: true,
      });

      mapInstance.addControl(new mapboxgl.NavigationControl());
      setMap(mapInstance);
    };

    initializeMap();

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (map && mapcoords.lng && mapcoords.lat) {
      const marker = new mapboxgl.Marker()
        .setLngLat([mapcoords.lng, mapcoords.lat])
        .addTo(map);

      map.flyTo({
        center: [mapcoords.lng, mapcoords.lat],
        zoom: 10,
        essential: true,
      });

      return () => {
        marker.remove();
      };
    }
  }, [map, mapcoords]);

  useEffect(() => {
    if (portName.length >= 3) {
      setTimeout(() => {
        getVesselName();
      }, 1000);
    }
  }, [portName]);

  useEffect(() => {
    getMarketnClass();
  }, []);

  const getMarketnClass = async () => {
    try {
      const resp = await getAPICall(
        `${process.env.REACT_APP_MT_URL}marine/port-congestion-drop-data`
      );

      if (resp.length > 0) {
        setMarket(resp);
      } else {
        // message.error("Something went wrong")
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getVesselName = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}marine/get-port-congestion-name?port_name=${portName}`;
      const resp = await postAPICall(url);
      if (resp.error && props.ports) {
        openNotificationWithIcon(
          "info",
          "MarineTraffic and Netpass port ID mismatch!!",
          2
        );
        return;
      }
      if (resp.error) {
        openNotificationWithIcon("info", "No Port Found!!", 2);
        return;
      }

      setPortNameOptions(resp.data || []);
      setPort(resp.data || []);
    } catch (e) {
      console.log(e);
    }
  };

  const getPortName = (value) => {
    setPortName(value);
  };

  const handleInputWeekFromChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    if (name === "port_name") {
      setPortName(value);
    }
  };

  const handleInputWeekToChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    if (name === "port_name") {
      setPortName(value);
    }
  };

  const handleSelectChange = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      year: value,
    }));
  };

  const handleSearch = async (PortID) => {
    try {
      setLoading(true);
      if (filters.weekForm === "") {
        openNotificationWithIcon("info", "Please enter week number");
        return;
      }
      // const weekTo = filters.week === "" ? 1 : filters.week;
      const weekFrom = filters.weekForm === "" ? 1 : filters.weekForm;
      const weekTo = filters.weekTo === "" ? 1 : filters.weekTo;
      let url = `${
        process.env.REACT_APP_MT_URL
      }marine/get-port-congestion?portid=${
        selectedPort ? selectedPort : PortID
      }&week=${weekFrom},${weekTo}&year=${
        filters.year
      }&market=${selectedMarket}&shipclass=${selectedShipClass}`;
    
      if (selectedMarket === "") {
        url = `${
          process.env.REACT_APP_MT_URL
        }marine/get-port-congestion?portid=${
          selectedPort ? selectedPort : PortID
        }&week=${weekFrom},${weekTo}&year=${filters.year}`;
      }

      const resp = await getAPICall(url);

      if (resp.hasOwnProperty('error')) {
        openNotificationWithIcon("info", resp.error);
      } else {
        setPortCongestion(resp.data);
        setMapcoords({
          lng: coords.lng,
          lat: coords.lat,
        });
      }
      setLoading(false);
      setCurrentIndex(0)
    } catch (e) {
      console.log(e);
    }
  };

  const handlePortNameSelect = (value, option) => {
    setSelectedPort(value);
    setCoords({ lng: option.long, lat: option.lat });
  };

  const handleSelectPort = async (value, option) => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}marine/get-port-congestion-name?port_name=${value}`;
      const resp = await postAPICall(url);
      if (resp.error && props.ports) {
        openNotificationWithIcon(
          "info",
          "MarineTraffic and Netpass port ID mismatch!!",
          2
        );
        return;
      }
      if (resp.error) {
        openNotificationWithIcon("info", "No Port Found!!", 2);
        return;
      }
      let newPort = resp.data.filter(
        (port) => port.port_name === value.toUpperCase()
      );
      setSelectedPort(newPort[0].port_id);
      setCoords({ lng: newPort[0].centerx, lat: newPort[0].centery });
      setCurrentPort(value);
    } catch (e) {
      console.log(e);
    }
  };


  const handleReset = () => {
    setFilters({
      port_name: "",
      week: "",
      year: "2024",
    });
    setCurrentPort("");
    setPortNameOptions([]);
    setPortName("");
    setPortCongestion([{}]);
    setMapcoords({
      lng: "",
      lat: "",
    });
  };

  const handleMarketChange = (value) => {
    setSelectedMarket(value);
    const selectedMarket = market.find((item) => item.market_name === value);
    if (selectedMarket) {
      setShipClass(
        selectedMarket.size_categories.map((category) => category.shipclass)
      );
    } else {
      setShipClass([]);
    }
  };

  const handleShipClassChange = (value) => {
    setSelectedShipClass(value);
  };

  const getVesselInfo = async (portId) => {
    let weekfrom = filters.weekForm === "" ? 1 : filters.weekForm;
    let weekTo = filters.weekTo === "" ? 1 : filters.weekTo;
    // let url = `${
    //   process.env.REACT_APP_MT_URL
    // }marine/port-congestion-vessel-data?week=${weekfrom},${weekTo}&year=2024&portid=${portId}`;
    let url = `${process.env.REACT_APP_MT_URL}marine/port-congestion-vessel-data?week=${weekfrom},${weekTo}&year=2024&portid=${selectedPort ? selectedPort : portCongestion.PORT_ID}`
 
    let response = await getAPICall(url);
    if(response.hasOwnProperty('error')){
      openNotificationWithIcon('info', response.error)
    }else{
      setVesselTableData(response.data);
      setIsVessel(true);
    }
  };

  console.log('props.....', port);
  
  return (
    <div className="mt-3">
      <div className="card p-3 me-2 ms-2 m-2">
        <span className="d-block mb-2">
          <b>Search Filters :</b>
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom:'4px',
            gap:'8px'
          }}
        >
          <div style={{flex:'1'}}>
            <label className="pc-label mb-1" >
              Port Name <span style={{ color: "red" }}>*</span>
            </label>
            {!props.ports ? (
              <Select
                showSearch
                placeholder="Enter minimum 3 Letters*"
                bordered
                onChange={handlePortNameSelect}
                onSearch={getPortName}
                value={
                  portNameOptions.length === 0
                    ? "Enter minimum 3 Letters*"
                    : undefined
                }
                filterOption={false}
              >
                {portNameOptions.map((option) => (
                  <Option
                    lat={option.centery}
                    long={option.centerx}
                    key={option.port_id}
                    value={option.port_id}
                  >
                    {option.port_name}
                  </Option>
                ))}
              </Select>
            ) : (
              <Select
                showSearch
                onSelect={handleSelectPort}
                options={port.map((option) => ({
                  lable: option.port_name,
                  value: option.port_name,
                  key: option.id,
                }))}
                value={currentPort}
                onSearch={getPortName}
              />
            )}
          </div>
          <div style={{flex:'1'}}>
            <label>
              Market Name
            </label>
            <Select
              showSearch
              placeholder={
                <span style={{ fontWeight: "normal" }}>Select Market</span>
              }
              defaultActiveFirstOption
              onChange={(value) => handleMarketChange(value)}
            >
              {market.length > 0 &&
                market.map((item, index) => (
                  <Option key={index} value={item.market_name}>
                    {item.market_name}
                  </Option>
                ))}
            </Select>
          </div>
          <div style={{flex:'1'}}>
            <label>
              Ship Class 
            </label>
            <Select
              showSearch
              placeholder={
                <span style={{ fontWeight: "normal" }}>
                  {ShipClass.length > 0
                    ? "Select Ship Class"
                    : "Select Market First!!"}
                </span>
              }
              defaultActiveFirstOption
              onChange={(value) => handleShipClassChange(value)}
            >
              {ShipClass.length > 0 &&
                ShipClass.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: "4px",
              flex:'1'
            }}
          >
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 10px",
                backgroundColor:
                  portNameOptions.length === 0 && !currentPort
                    ? "#cccccc"
                    : "#1890ff",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor:
                  portNameOptions.length === 0 && !currentPort
                    ? "not-allowed"
                    : "pointer",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s ease",
              }}
              disabled={portNameOptions.length === 0 && !currentPort}
              onClick={() => getVesselInfo(portCongestion.PORT_ID)}
            >
              <span> Vessels Info</span>
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap:'8px'
          }}
        >
          <div style={{flex:'1'}}>
            <label>
              Week from <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              type="number"
              name="weekForm"
              id="weekFrom"
              placeholder="Week from"
              bordered
              value={filters.weekForm}
              onChange={handleInputWeekFromChange}
            />
          </div>
          <div style={{flex:'1'}}>
            <label>
              Week To
            </label>
            <Input
              type="number"
              name="weekTo"
              id="weekTo"
              placeholder="Week to"
              bordered
              value={filters.weekTo}
              onChange={handleInputWeekToChange}
            />
          </div>
          <div style={{flex:'1'}}>
            <label>
              Year <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              placeholder="Select Year"
              defaultActiveFirstOption
              defaultValue={filters.year}
              onChange={handleSelectChange}
              showSearch
              allowClear
            >
              <Option value="2024">2024</Option>
              <Option value="2023">2023</Option>
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              // alignItems: "center",
              flex:'1',
              gap: "8px",
            }}
          >
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 10px",
                backgroundColor:
                  portNameOptions.length === 0 && !currentPort
                    ? "#cccccc"
                    : "#1890ff",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor:
                  portNameOptions.length === 0 && !currentPort
                    ? "not-allowed"
                    : "pointer",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s ease",
              }}
              disabled={portNameOptions.length === 0 && !currentPort}
              onClick={() => handleSearch(portCongestion.PORT_ID)}
            >
              <span> Search</span>
              {loading ? (
                <LoadingOutlined
                  style={{ marginLeft: "8px", fontSize: "16px" }}
                />
              ) : (
                <SearchOutlined
                  style={{ marginLeft: "8px", fontSize: "16px" }}
                />
              )}
            </button>
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 10px",
                backgroundColor: "#ff4d4f", // Red for reset
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s ease",
              }}
              onClick={handleReset}
            >
              <span> Reset</span>
              <UndoOutlined style={{ marginLeft: "8px", fontSize: "16px" }} />
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex gap-2 m-2">
        <div className="p-2 card" style={{ width: "60%" }}>
          <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
            <span className="d-block mb-1">
              <b>See on Map :</b>
            </span>
            <div
              style={{
                overflow: "hidden",
                position: "relative",
                height: "100%",
                borderRadius: "6px",
              }}
            >
              {/* <DynamicMap zoom={3} /> */}
              <div
                ref={mapContainer}
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
        <PortCongestionSlider currentIndex = {currentIndex} setCurrentIndex={setCurrentIndex} components={portCongestion} />
      </div>
      <Modal
        title="Port Vessels Information"
        visible={isVessel}
        onCancel={() => setIsVessel(false)}
        width={"80%"}
        footer={null}
      >
        <VesselInfoTable vesselTableData={vesselTableData} />
      </Modal>
    </div>
  );
};

export default PortCongestion;
