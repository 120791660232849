import React, { useEffect } from "react";
import { Form, Input, DatePicker, Checkbox } from "antd";
import moment from "moment";
import URL_WITH_VERSION, {
  awaitPostAPICall,
  getAPICall,
  openNotificationWithIcon,
  useStateCallback,
} from "../../../../shared";
import SOARecapReport from "../../../../shared/components/All-Print-Reports/SOARecapReport";
import dayjs from "dayjs";
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const ConfirmStatementAccountRecap = (props) => {
  const [state, setState] = useStateCallback({
    showForm: false,
    showInvoice: false,
    invoiceData: {},
    statementAC: {
      tco_id: props.tciID,
      tco_code: props.tcID || 0,
      vessel_id: props.vesselID || 0,
      vessel_name: undefined,
      chartrer_id: props.chartrerID || 0,
      chartrer_name: undefined,
      date_from: props.from || null,
      date_to: props.to || null,
      account_head: {
        TCOH: true,
        TCOADCOM: true,
        TCOBCOM: true,
        TCOBB: true,
        TCOILOHC: true,
        TCOIHC: true,
        BD: true,
        TCOREA: true,
        BR: true,
        TCOLAST: true,
        TCOGRA: true,
        TCOVIC: true,
        TCOOTHREV: true,
        TCOOTHEXP: true,
      },
    },
  });

  useEffect(() => {
    const getFormdata = async () => {
      let request = await getAPICall(
        `${URL_WITH_VERSION}/tco/edit?e=${state?.statementAC?.tco_code}&sn=1`
      );
      let response = await request["data"];
      if (response) {
        let sac = Object.assign({}, state.statementAC);
        sac["vessel_name"] = response["vessel_name"];
        sac["chartrer_name"] = response["chartrer_name"];
        sac["date_from"] = sac["date_from"] ? moment(sac["date_from"]) : null;
        sac["date_to"] = sac["date_to"] ? moment(sac["date_to"]) : null;
        setState(
          (prevState) => ({ ...prevState, statementAC: sac }),
          () => setState((prevState) => ({ ...prevState, showForm: true }))
        );
      } else {
        setState((prevState) => ({ ...prevState, showForm: true }));
      }
    };
    getFormdata();
  }, []);

  const onChangeEvent = (event, name) => {
    let sac = Object.assign({}, state.statementAC);
    if (moment.isMoment(event)) {
      sac[name] = event;
    } else if (
      event &&
      event.hasOwnProperty("target") &&
      event["target"] &&
      event["target"].hasOwnProperty("checked") &&
      sac["account_head"].hasOwnProperty(name)
    ) {
      sac["account_head"][name] = event["target"]["checked"];
    }
    setState((prevState) => ({ ...prevState, statementAC: sac }));
  };

  const onClickSOAButton = async () => {
    try {
      state.statementAC.hire_invoice = true;
      delete state?.statementAC?.tco_code;
      let request = await awaitPostAPICall(
        `${URL_WITH_VERSION}/report/tco-hire-statement-account`,
        state.statementAC
      );
      let respdata = await request;
      let response = await request["data"];

      if (response) {
        setState(
          (prevState) => ({ ...prevState, invoiceData: response }),
          () => {
            setState((prevState) => ({
              ...prevState,
              showInvoice: true,
              showForm: false,
            }));
          }
        );
      } else {
        openNotificationWithIcon("info", respdata.message, 2);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const { statementAC, showForm, showInvoice, invoiceData } = state;

  return (
    <>
      {showForm ? (
        <div className="body-wrapper modalWrapper">
          <article className="article toolbaruiWrapper">
            <div className="box box-default">
              <div className="box-body">
                <Form>
                  <div className="row p10">
                    <div className="col-md-6">
                      <FormItem {...formItemLayout} label="Vessel Name">
                        <Input
                          type="text"
                          size="default"
                          defaultValue={statementAC.vessel_name}
                          readOnly
                        />
                      </FormItem>
                    </div>

                    <div className="col-md-6">
                      <FormItem {...formItemLayout} label="Chartered">
                        <Input
                          type="text"
                          size="default"
                          defaultValue={statementAC.chartrer_name}
                          readOnly
                        />
                      </FormItem>
                    </div>

                    <div className="col-md-6">
                      <FormItem {...formItemLayout} label="Date From">
                        <DatePicker
                          value={dayjs(statementAC.date_from)}
                          format={"YYYY-MM-DD HH:mm"}
                          placeholder="YYYY-MM-DD HH:MM"
                          onChange={(date, dateString) =>
                            onChangeEvent(dateString, "date_from")
                          }
                          showTime={{ defaultValue: moment("00:00", "HH:mm") }}
                        />
                      </FormItem>
                    </div>

                    <div className="col-md-6">
                      <FormItem {...formItemLayout} label="Date To">
                        <DatePicker
                          value={dayjs(statementAC.date_to)}
                          format={"YYYY-MM-DD HH:mm"}
                          placeholder="YYYY-MM-DD HH:MM"
                          onChange={(date, datestring) =>
                            onChangeEvent(datestring, "date_to")
                          }
                          showTime={{ defaultValue: moment("00:00", "HH:mm") }}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="row p10">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={statementAC["account_head"]["TCOH"]}
                            checked={statementAC["account_head"]["TCOH"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOH")}
                          >
                            <span>Hire Payment</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCOADCOM"]
                            }
                            checked={statementAC["account_head"]["TCOADCOM"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOADCOM")}
                          >
                            <span>Add Comm</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCOBCOM"]
                            }
                            checked={statementAC["account_head"]["TCOBCOM"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOBCOM")}
                          >
                            <span>Broker Comm</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCOBB"]
                            }
                            checked={statementAC["account_head"]["TCOBB"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOBB")}
                          >
                            <span>Ballast Bonus</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={true}
                            checked={true}
                            onChange={(evt) => onChangeEvent(evt, "")}
                          >
                            <span>Other Adjustment</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCOILOHC"]
                            }
                            checked={statementAC["account_head"]["TCOILOHC"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOILOHC")}
                          >
                            <span>ILHOC</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCOGRA"]
                            }
                            checked={statementAC["account_head"]["TCOGRA"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOGRA")}
                          >
                            <span>Gratuity</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCOVIC"]
                            }
                            checked={statementAC["account_head"]["TCOVIC"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOVIC")}
                          >
                            <span>Victualling</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCOLAST"]
                            }
                            checked={statementAC["account_head"]["TCOLAST"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOLAST")}
                          >
                            <span>Lashing & Stowage</span>
                          </Checkbox>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={true}
                            checked={true}
                            onChange={(evt) => onChangeEvent(evt, "")}
                          >
                            <span>Off Hire Period</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={true}
                            checked={true}
                            onChange={(evt) => onChangeEvent(evt, "")}
                          >
                            <span>Off Hire Bunker</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={true}
                            checked={true}
                            onChange={(evt) => onChangeEvent(evt, "")}
                          >
                            <span>Off Hire Adj.</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCOIHC"]
                            }
                            checked={statementAC["account_head"]["TCOIHC"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOIHC")}
                          >
                            <span>IHC</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={statementAC["account_head"]["BD"]}
                            checked={statementAC["account_head"]["BD"]}
                            onChange={(evt) => onChangeEvent(evt, "BD")}
                          >
                            <span>Bunker Items</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCOREA"]
                            }
                            checked={statementAC["account_head"]["TCOREA"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOREA")}
                          >
                            <span>Rep. Exp/Allowance</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCOOTHREV"]
                            }
                            checked={statementAC["account_head"]["TCOOTHREV"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOOTHREV")}
                          >
                            <span>other revenue</span>
                          </Checkbox>
                        </div>
                        <div className="col-md-4" style={{ paddingRight: 0 }}>
                          <Checkbox
                            defaultChecked={
                              statementAC["account_head"]["TCOOTHEXP"]
                            }
                            checked={statementAC["account_head"]["TCOOTHEXP"]}
                            onChange={(evt) => onChangeEvent(evt, "TCOOTHEXP")}
                          >
                            <span>other expenses</span>
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row p10">
                    <div className="col-md-12">
                      <button
                        className="btn ant-btn-primary mr-2"
                        onClick={onClickSOAButton}
                      >
                        SOA-R
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </article>
        </div>
      ) : undefined}

      {showInvoice ? <SOARecapReport invData={invoiceData} /> : undefined}
    </>
  );
};

export default ConfirmStatementAccountRecap;
