import React from "react";

import {
  Layout,
  Table,
  Typography,
  Input,
  Row,
  Col,
  Divider,
  Modal,
  message,
  Spin,
  Popconfirm,
} from "antd";

import { DeleteOutlined, EnvironmentOutlined } from "@ant-design/icons";
import URL_WITH_VERSION, {
  objectToQueryStringFunc,
  getAPICall,
  apiDeleteCall,
  postAPICall,
  openNotificationWithIcon,
} from "../../shared";
import ToolbarUI from "../../components/CommonToolbarUI/toolbar_index";
const portPostion = () => {
  let tableHeaders = [
    {
      title: "Port Name",
      dataIndex: "Port_Name",
      key: "port_name",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Vessel Name",
      dataIndex: "Vessel_name",
      key: "vessel_name",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Vessel Type",
      dataIndex: "Vessel_Type",
      key: "vessel_type",
      ellipsis: "true",
      width: "100px",
    },
    {
      title: "Dead weight",
      dataIndex: "Dead_Weight",
      key: "dead_weight",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Year build",
      dataIndex: "Year_Build",
      key: "year_build",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Flag",
      dataIndex: "Flag",
      key: "flag",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "ATA",
      dataIndex: "ATA",
      key: "ata",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "ETA",
      dataIndex: "ETA",
      key: "eta",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Berth Occupance",
      dataIndex: "Berth_Occupance",
      key: "berth_occupance",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Anchoring Waiting Time",
      dataIndex: "Anchoring_Waiting_Time",
      key: "anchoring waiting time",
      ellipsis: "true",
      width: "100px",
    },
    {
      title: "Length",
      dataIndex: "Length",
      key: "length",
      ellipsis: "true",
      width: "100px",
    },
  ];
  return (
    <div>
      <Table
        bordered
        columns={tableHeaders}
        // dataSource={this.state.data}
        // scroll={{
        //   x: 'max-content',
        //   y: 400,
        // }}
        scroll={{ x: "max-content" }}
        // loading={this.state.loading}
        className="inlineTable resizeableTable"
        size="small"
        pagination={false}
        rowClassName={(r, i) =>
          i % 2 === 0
            ? "table-striped-listing"
            : "dull-color table-striped-listing"
        }
      />
    </div>
  );
};

export default portPostion;
