  export const europeanCountryCodes = [
    'AL', // Albania
    'AD', // Andorra
    'AM', // Armenia
    'AT', // Austria
    'AZ', // Azerbaijan
    'BY', // Belarus
    'BE', // Belgium
    'BA', // Bosnia and Herzegovina
    'BG', // Bulgaria
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DK', // Denmark
    'EE', // Estonia
    'FI', // Finland
    'FR', // France
    'GE', // Georgia
    'DE', // Germany
    'GR', // Greece
    'HU', // Hungary
    'IS', // Iceland
    'IE', // Ireland
    'IT', // Italy
    'KZ', // Kazakhstan
    'XK', // Kosovo
    'LV', // Latvia
    'LI', // Liechtenstein
    'LT', // Lithuania
    'LU', // Luxembourg
    'MK', // North Macedonia
    'MT', // Malta
    'MD', // Moldova
    'MC', // Monaco
    'ME', // Montenegro
    'NL', // Netherlands
    'NO', // Norway
    'PL', // Poland
    'PT', // Portugal
    'RO', // Romania
    'RU', // Russia
    'SM', // San Marino
    'RS', // Serbia
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SE', // Sweden
    'CH', // Switzerland
    'TR', // Turkey
    'UA', // Ukraine
    'GB', // United Kingdom
    'VA', // Vatican City
  ];
  

  

  export const europeanCountryCodesWithName=[
    { name: 'Albania', code: 'AL' },
    { name: 'Andorra', code: 'AD' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Greece', code: 'GR' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Italy', code: 'IT' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kosovo', code: 'XK' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'North Macedonia', code: 'MK' },
    { name: 'Malta', code: 'MT' },
    { name: 'Moldova', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Montenegro', code: 'ME' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Norway', code: 'NO' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russia', code: 'RU' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Serbia', code: 'RS' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'Vatican City', code: 'VA' },
  ];
  