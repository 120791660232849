import React, { Component } from "react";
import { Table, Tabs, Input, Row, Col, Form, Spin, Alert } from "antd";
//import ToolbarUI from "components/ToolbarUI";

import TcovPL from "../PL/tcov/PLindex";
import TctoPl from "../PL/tcto/PLindex";
import VIVOPL from "../PL/voyrelet/PLindex";

import VoyageReletPL from "../PL/voyrelet/PLindex";
import { BunkerDetailsCalculation } from "../../NormalForm/normalhelper";

class PL extends Component {
  callback = (evt) => { };
  constructor(props) {
    super(props);
    //console.log("props :", props);
    this.state = {
      dollarUSLocale: Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      formData: this.props.formData || {},
      estimatePL: this.props.estimatePL || false,
      estimateDatavalue: this.props.estimateData || {},
      showPL: false,
      vesselAmount: 0,
      showEstimatePl: this.props.showEstimatePl,
      showExactpl: this.props.formData,
      viewTabs: this.props.viewTabs,
    };
  }

  componentDidMount() {
    this.setState(
      {
        ...this.state,
        showPL: false,
      },
      () => this.setState({ ...this.state, showPL: true })
    );
  }

  render() {
    const { formData, estimateDatavalue, showEstimatePl, viewTabs } =
      this.state;

    let patharr = window.location.href;

    return (
      <>
        {patharr.includes("EST-TCOV") ||
          patharr.includes("TCE") ||
          patharr.includes("TCOV-FULL")? (
          <TcovPL
            formData={formData}
            estimateData={estimateDatavalue}
            showEstimatePl={showEstimatePl}
          />
        ) : patharr.includes("VIVO-FULL") ? (
          <VIVOPL
            formData={formData}
            estimateData={estimateDatavalue}
            showEstimatePl={showEstimatePl}
          />
        )
          : patharr.includes("EST-TCTO") ||
            patharr.includes("TCR") ||
            patharr.includes("TCTO-FULL") ? (
            <TctoPl
              formData={formData}
              estimateData={estimateDatavalue}
              showEstimatePl={showEstimatePl}
            />
          ) : patharr.includes("EST-VOYR") ? (
            <VoyageReletPL
              formData={formData}
              estimateData={estimateDatavalue}
              showEstimatePl={showEstimatePl}
            />
          ) : (
            <div className="col col-lg-12">
              <Spin tip="Loading...">
                <Alert message=" " description="Please wait..." type="info" />
              </Spin>
            </div>
          )}
      </>
    );
  }
}

export default PL;
