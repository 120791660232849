import React from 'react';
import { Form, Input, Button } from 'antd';

const getValue = (value) => (value ? value : 'N/A');

const ViewCargoForm = ({data}) => {
  return (
    <Form
      className="view_tonnage_form"
      layout="vertical"
      initialValues={{
        DateTime: getValue(data.DateTime),
        Sender_mail: getValue(data.Sender_mail),
        Sender_names: getValue(data.Sender_names),
        Client: getValue(data.Client),
        cargo: getValue(data.cargo),
        cargo_type: getValue(data.cargo_type),
        company: getValue(data.company),
        laycan: getValue(data.laycan),
        load_port: getValue(data.load_port.port),
        discharge_port: getValue(data.discharge_port.port),
        duration: getValue(data.duration),
        phone_numbers: getValue(data.sender_full_details.phone_numbers.join(', ')),
        emails: getValue(data.sender_full_details.emails.join(', ')),
        skype_ids: getValue(data.sender_full_details.skype_ids.join(', ')),
      }}
    >
      <Form.Item label="Date and Time" name="DateTime">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Sender Email" name="Sender_mail">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Sender Name" name="Sender_names">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Client" name="Client">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Cargo" name="cargo">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Cargo Type" name="cargo_type">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Company" name="company">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Laycan" name="laycan">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Load Port" name="load_port">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Discharge Port" name="discharge_port">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Duration" name="duration">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Phone Numbers" name="phone_numbers">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Emails" name="emails">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Skype IDs" name="skype_ids">
        <Input disabled />
      </Form.Item>
    </Form>
  );
};

export default ViewCargoForm;
