import MailLayout from "./MailLayout";

const BinList = () => {
    return(
        <>
        <MailLayout bin={true} />
        </>
    )
}

export default BinList;