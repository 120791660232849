// import React, { Component } from 'react';
// import { Button,  Tooltip } from 'antd';
// import {PrinterOutlined} from '@ant-design/icons';
// import ReactToPrint from "react-to-print";
// import '../print-report.scss';

// class ComponentToPrint extends React.Component {
//     render() {
//         return (
//             <article className="article">
//                 <div className="box box-default print-wrapper">
//                     <div className="box-body">

//                         <div className="invoice-wrapper">
//                             <section className="invoice-container hire" id="invoice">

//                                 <div className="invoice-inner">
//                                     <div className="row">
//                                         <div className="col-12">
//                                             <span className="title">LDC</span>
//                                             <p className="sub-title">Louis Dreyfus Company</p>
//                                         </div>
//                                     </div>

//                                     <div className="row view-list">
//                                         <div className="col-12">
//                                             <div className="sub-heading text-right">Statement of Account Recap</div>
//                                         </div>

//                                         <div className="col-12">
//                                             <div className="wrap-data">

//                                                 <div className="col-4 text-left">
//                                                     <ul className="list-unstyled">
//                                                         <li><strong>Vessel:</strong> GOLDEN ROSE</li>
//                                                         <li><strong>Delivery:</strong> May 05 2021</li>
//                                                     </ul>
//                                                 </div>

//                                                 <div className="col-4 text-left">
//                                                     <ul className="list-unstyled">
//                                                         <li><strong>CP Date:</strong> May 05 2021</li>
//                                                         <li><strong>Redelivery:</strong> May 05 2021</li>
//                                                     </ul>
//                                                 </div>

//                                                 <div className="col-4 text-left">
//                                                     <ul className="list-unstyled">
//                                                         <li><strong>Charterered From:</strong> GOLDEN ROSE INC.</li>
//                                                         <li><strong>Printed On:</strong> May 05 2021</li>
//                                                     </ul>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div className="divider my-2" />

//                                     <div className="row">

//                                         <div className="col-md-12">
//                                             <div className="table-responsive custom-table">
//                                                 <table className="table">
//                                                     <thead>
//                                                         <tr>
//                                                             <th scope="col" rowSpan="3">Gross Hire</th>
//                                                         </tr>
//                                                     </thead>
//                                                     <tbody>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">05/04/19 15;36 - 08/07/19 02:18, 94,445833 @13,250.00</th>
//                                                             <td className="text-right no-padding">1,251,407.29</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">100.00% Off Hire Time Deduction</th>
//                                                             <td className="text-right no-padding">(1,286.35)</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr>
//                                                             <th scope="row">Total Off Hire</th>
//                                                             <td className="text-right no-padding-right"><div className="amount">(1,286.35)</div></td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr>
//                                                             <th scope="row" className="no-top-padding">Net Hire</th>
//                                                             <td className="text-right no-top-padding no-padding">1,250,120.94</td>
//                                                             <td></td>
//                                                         </tr>
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                         </div>

//                                         <div className="spacer" />

//                                         <div className="col-md-12">
//                                             <div className="table-responsive custom-table">
//                                                 <table className="table">
//                                                     <thead>
//                                                         <tr>
//                                                             <th scope="col" rowSpan="3">Hire Commissions</th>
//                                                         </tr>
//                                                     </thead>
//                                                     <tbody>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">Off Hire Address Commission (3.75%)</th>
//                                                             <td className="text-right no-padding">48.24</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">Address Commission from 05/04/19 15:36 - 08/07/19 02:18</th>
//                                                             <td className="text-right no-padding">(46,927.77)</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr>
//                                                             <th scope="row">Total Hire Commission</th>
//                                                             <td className="text-right no-padding"><div className="amount">(46,879.54)</div></td>
//                                                             <td></td>
//                                                         </tr>
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                         </div>

//                                         <div className="spacer" />

//                                         <div className="col-md-12">
//                                             <div className="table-responsive custom-table">
//                                                 <table className="table">
//                                                     <thead>
//                                                         <tr>
//                                                             <th scope="col" rowSpan="3">Bunker Value on Delivery</th>
//                                                         </tr>
//                                                     </thead>
//                                                     <tbody>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">HFO Cost On Delivery 1247.455 MT @ 425.00 USD/MT</th>
//                                                             <td className="text-right no-padding">530,168.38</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">MGO Cost On Delivery 1247.455 MT @ 650.00 USD/MT</th>
//                                                             <td className="text-right no-padding">83,369.65</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr>
//                                                             <th scope="row">Total Bunker Delivery</th>
//                                                             <td className="text-right no-padding"><div className="amount">613,538.03</div></td>
//                                                             <td></td>
//                                                         </tr>
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                         </div>

//                                         <div className="spacer" />

//                                         <div className="col-md-12">
//                                             <div className="table-responsive custom-table">
//                                                 <table className="table">
//                                                     <thead>
//                                                         <tr>
//                                                             <th scope="col" rowSpan="3">Bunker Value on Redelivery</th>
//                                                         </tr>
//                                                     </thead>
//                                                     <tbody>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">HFO Cost On Redelivery 1247.455 MT @ 425.00 USD/MT</th>
//                                                             <td className="text-right no-padding">(530,168.38)</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">MGO Cost On Redelivery 1247.455 MT @ 425.00 USD/MT</th>
//                                                             <td className="text-right no-padding">(83,369.65)</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr>
//                                                             <th scope="row">Total Bunker Redelivery</th>
//                                                             <td className="text-right no-padding"><div className="amount">(613,538.03)</div></td>
//                                                             <td></td>
//                                                         </tr>
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                         </div>

//                                         <div className="spacer" />

//                                         <div className="col-md-12">
//                                             <div className="table-responsive custom-table">
//                                                 <table className="table">
//                                                     {/* <thead>
//                                                         <tr>
//                                                             <th scope="col" rowSpan="3">Bunker Value on Delivery</th>
//                                                         </tr>
//                                                     </thead> */}
//                                                     <tbody>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">Gratuities from 05/04/19 15:36 - 08/07/19 02:18</th>
//                                                             <td className="text-right no-padding">4,618.35</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">Gratuities For Off Hire</th>
//                                                             <td className="text-right no-padding">(4.70)</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr>
//                                                             <th scope="row">Total C/V/E</th>
//                                                             <td className="text-right no-padding"><div className="amount">4,613.65</div></td>
//                                                             <td></td>
//                                                         </tr>
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                         </div>

//                                         <div className="spacer" />

//                                         <div className="col-md-12">
//                                             <div className="table-responsive custom-table">
//                                                 <table className="table">
//                                                     <tr>
//                                                         <th scope="col" rowSpan="3">Owner's Expenses</th>
//                                                     </tr>
//                                                 </table>
//                                             </div>
//                                         </div>

//                                         <div className="spacer" />

//                                         <div className="col-md-12">
//                                             <div className="table-responsive custom-table">
//                                                 <table className="table">
//                                                     <thead>
//                                                         <tr>
//                                                             <th scope="col" rowSpan="3">Other Adjustment</th>
//                                                         </tr>
//                                                     </thead>
//                                                     <tbody>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">100.00% OFF HIRE MGO 1.480 MT @650.00</th>
//                                                             <td className="text-right no-padding">(962.00)</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">ILOHC</th>
//                                                             <td className="text-right no-padding">5,000.00</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">Off hire survey (3 party B+c cost)</th>
//                                                             <td className="text-right no-padding">(600.00)</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">Total Off Hire Bunker</th>
//                                                             <td className="text-right no-padding"><div className="amount">(962.00)</div></td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr>
//                                                             <th scope="row">Total Other</th>
//                                                             <td className="text-right no-padding">4,400.00</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr>
//                                                             <th scope="row">Total Estimates Expenses</th>
//                                                             <td></td>
//                                                             <td className="text-right no-padding">0.00</td>
//                                                         </tr>
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                         </div>

//                                         <div className="spacer" />

//                                         <div className="col-md-12">
//                                             <div className="table-responsive custom-table">
//                                                 <table className="table">
//                                                     <thead>
//                                                         <tr>
//                                                             <th scope="col" rowSpan="3">Payment</th>
//                                                         </tr>
//                                                     </thead>
//                                                     <tbody>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">Amount Paid On 05/22/2019</th>
//                                                             <td className="text-right no-padding">1,024,169.35</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">Amount Paid On 07/22/2019</th>
//                                                             <td className="text-right no-padding">140,816.63</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">Amount Paid On 08/22/2019</th>
//                                                             <td className="text-right no-padding">48,692.21</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">Total Amount Paid</th>
//                                                             <td className="text-right no-padding">(1,213,678.0)</td>
//                                                             <td></td>
//                                                         </tr>
//                                                         <tr className="content">
//                                                             <th scope="row" className="no-padding">Balance in Owner's Favor</th>
//                                                             <td className="text-right no-padding">(9)</td>
//                                                             <td></td>
//                                                         </tr>
//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                         </div>

//                                     </div>

//                                     <hr />

//                                     <div className="row">
//                                         <div className="col-12">
//                                             <ul className="list-unstyled">
//                                                 <li><strong>Banking Details:</strong> ING BANK N.V.</li>
//                                                 <li><strong>Swift Code:</strong> INGBNL2AXXXX</li>
//                                                 <li><strong>Account No.:</strong> NL57INGB00201127367</li>
//                                             </ul>
//                                         </div>
//                                     </div>

//                                 </div>
//                             </section>
//                         </div>
//                     </div>
//                 </div>
//             </article>
//         );
//     }
// }

// class SOARecapReport extends Component {

//     constructor() {
//         super();
//         this.state = {
//             name: 'Printer'
//         };
//     }

//     printReceipt() {
//         window.print();
//     }

//     render() {
//         return (
//             <div className="body-wrapper modalWrapper modal-report-wrapper">
//                 <article className="article">
//                     <div className="box box-default">
//                         <div className="box-body">
//                             <div className="toolbar-ui-wrapper">
//                                 <div className="leftsection">
//                                     <span key="first" className="wrap-bar-menu">
//                                         <ul className="wrap-bar-ul">
//                                             <li>
//                                                 <Tooltip title="Back">
//                                                     <Button type="dashed" shape="circle" icon="double-left" size={'default'} onClick={() => this.props.changeRecapState(true)} />
//                                                 </Tooltip></li>
//                                             <li><span className="text-bt">Statement of Account Recap</span></li>
//                                         </ul>
//                                     </span>
//                                 </div>
//                                 <div className="rightsection">
//                                     <span key="first" className="wrap-bar-menu">
//                                         <ul className="wrap-bar-ul">
//                                             <li><ReactToPrint
//                                                 trigger={() => <span className="text-bt"><PrinterOutlined /> Print</span>}
//                                                 content={() => this.componentRef}
//                                             /></li>
//                                         </ul>
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </article>

//                 <article className="article">
//                     <div className="box box-default">
//                         <div className="box-body print-scroll">
//                             <ComponentToPrint ref={(el) => (this.componentRef = el)} />
//                         </div>
//                     </div>
//                 </article>

//             </div>
//         )
//     }
// }

// export default SOARecapReport;

import React, { useRef, forwardRef, useState, useEffect } from "react";
import { Button, Flex, Tooltip } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

import ReactToPrint from "react-to-print";
import "../print-report.scss";

const ComponentToPrint = forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(props.vesseDetail);
  }, [data]);

  return (
    <article className="article" ref={ref}>
      <div className="box box-default print-wrapper">
        <div className="box-body">
          <div className="invoice-wrapper">
            <section className="invoice-container hire" id="invoice">
              <div className="invoice-inner">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center"> 
                    <img
                        className="reportlogo"
                        src={data.my_company_logo ?? "N/A"}
                        alt="Company Logo"
                      ></img>
                      <p className="sub-title">{data.my_company_name}</p>
                    </div>
                  </div>
                </div>
                <div className="row view-list">
                  <div className="col-12">
                    <div className="wrap-data border-line">
                      <div className="col-4 text-left">
                        <ul className="list-unstyled">
                          <li className="laytime-list">
                            <span>Vessel:</span>
                            <span>{data.vessel_name}</span>
                          </li>
                          <li className="laytime-list">
                            <span>Delivery:</span>
                            <span>{data.period_form}</span>
                          </li>
                        </ul>
                      </div>

                      <div className="col-4 text-left">
                        <ul className="list-unstyled">
                          <li className="laytime-list">
                            <span>CP Date:</span>
                            <span>{data.cp_date}</span>
                          </li>
                          <li className="laytime-list">
                            <span>Redelivery:</span>
                            <span>{data.delivery_date}</span>
                          </li>
                        </ul>
                      </div>

                      <div className="col-4 text-left">
                        <ul className="list-unstyled">
                          <li className="laytime-list">
                            <span>Charterered From:</span>
                            <span>{data.counterparty_name}</span>
                          </li>
                          <li className="laytime-list">
                            <span>Printed On:</span>
                            <span>{data.printed}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="divider my-2" />

                <div className="row laytime-heading">
                  <div className="row laytime-heading">
                    {data?.invoice?.length > 0
                      ? data?.invoice?.map((e) => {
                          return (
                            <>
                              <div className="col-md-12">
                                <div className="table-responsive custom-table">
                                  <table className="table th-withoutborder">
                                    <thead>
                                      <tr>
                                        <div
                                          scope="col"
                                          rowSpan="3"
                                          style={{
                                            paddingLeft: "0px",
                                          }}
                                        >
                                          {e.title}
                                        </div>
                                        {/* <th scope="col" rowSpan="3">
                                        {e.title}
                                      </th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {e["description"].map((ed) => {
                                        return (
                                          <>
                                            <tr className="content">
                                              <th
                                                scope="row"
                                                className="no-padding"
                                              >
                                                {ed.details}
                                              </th>
                                              {
                                                <td className="text-right no-padding">
                                                  &nbsp;
                                                </td>
                                              }

                                              {
                                                <td className="text-right no-padding">
                                                  {ed["amount"] > 0
                                                    ? ed["amount"]
                                                    : ed["amount"] * -1}
                                                </td>
                                              }
                                            </tr>
                                          </>
                                        );
                                      })}
                                      <tr>
                                        <th scope="row">Net {e.title}</th>
                                        <td />
                                        <td
                                          className="text-right"
                                          style={{
                                            borderTop: "2px solid #000",
                                          }}
                                        >
                                          {e["net_amount"] < 0
                                            ? "(" + e["net_amount"] * -1 + ")"
                                            : e["net_amount"]}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="spacer" />
                            </>
                          );
                        })
                      : undefined}

                    <div className="col-md-12">
                      <div className="table-responsive custom-table">
                        <table className="table th-withoutborder">
                          <thead>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                scope="col"
                                rowSpan="3"
                                style={{ paddingLeft: "0px" }}
                              >
                                Total Invoice Amount
                              </div>
                              <div
                                style={{
                                  display: "inline",
                                  borderTop: "2px solid black",
                                  padding: "0px",
                                  borderBottom: "0px",
                                }}
                              >
                                {data.total_amount}
                              </div>
                            </div>
                            {/* <tr>
                              <th scope="col" rowSpan="3">
                                Total Invoice Amount
                              </th>
                              <tr
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginTop: "5px",
                                  padding: "0px",
                                  borderBottom: "0px",
                                }}
                              >
                                <tr
                                  style={{
                                    display: "inline",
                                    borderTop: "2px solid black",
                                    padding: "0px",
                                    borderBottom: "0px",
                                  }}
                                  className="text-right"
                                >
                                  {data.total_amount}
                                </tr>
                              </tr>
                            </tr> */}
                          </thead>
                        </table>
                      </div>
                    </div>

                    <div className="spacer" />

                    <div className="spacer" />

                    <div className="col-md-12">
                      <div className="table-responsive custom-table">
                        <table className="table th-withoutborder">
                          <thead>
                            <div
                              scope="col"
                              rowSpan="3"
                              style={{ paddingLeft: "0px" }}
                            >
                              Payment
                            </div>
                            {/* <tr className="border-bottom-line">
                              <th scope="col" rowSpan="3">
                                Payment
                              </th>
                            </tr> */}
                          </thead>
                          <tbody>
                            <tr className="content ">
                              <div
                                scope="row laytime-heading"
                                className="no-padding"
                                style={{
                                  width: "100%",
                                }}
                              >
                                {data?.payments?.map((e) => {
                                  return (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <tr>Amount Paid On:{e.cp_date}</tr>
                                        <span
                                          style={{
                                            fontWeight: "normal",
                                          }}
                                        >
                                          <tr>{e.amount}</tr>
                                        </span>
                                      </div>
                                    </>
                                  );
                                })}

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <tr>Total Amount Paid</tr>
                                  <tr
                                    style={{
                                      marginTop: "8px",
                                      borderTop: "2px solid black",
                                    }}
                                  >
                                    {data.total_payment_amount}
                                  </tr>
                                </div>
                              </div>
                            </tr>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "0px",
                                padding: "0px",
                                marginTop: "16px",
                                borderBottom: "2px solid black",
                              }}
                            >
                              <tr
                                style={{
                                  padding: "0px",
                                  margin: "0px",
                                  border: "0px",
                                }}
                              >
                                Balance In Owner's Favor
                              </tr>
                              <tr
                                style={{
                                  padding: "0px",
                                  margin: "0px",
                                  border: "0px",
                                  borderTop: "2px solid black",
                                }}
                              >
                                {" "}
                                {data.total_amount - data.total_payment_amount}
                                .00
                              </tr>
                            </div>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row view-list">
                      <div class="col-lg-12">
                        <ul class="list-unstyled">
                          <li
                            className="laytime-list"
                            style={{ margin: "6px", paddingLeft: "0px" }}
                          >
                            Banking Details:
                          </li>
                        </ul>

                        <ul class="list-unstyled" style={{ margin: "0px" }}>
                          <li className="laytime-list">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingLeft: "6px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                Beneficiary Name :{" "}
                              </span>
                              <span
                                style={{
                                  fontWeight: "lighter",
                                  paddingLeft: "4px",
                                }}
                              >
                                {" "}
                                {data?.bank_ajent?.benificiary_name}
                              </span>
                            </div>
                          </li>
                        </ul>

                        <ul
                          class="list-unstyled"
                          style={{ margin: "0px", paddingLeft: "6px" }}
                        >
                          <li className="laytime-list">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                Bank Name:{" "}
                              </span>
                              <span
                                style={{
                                  fontWeight: "lighter",
                                  paddingLeft: "4px",
                                }}
                              >
                                {" "}
                                {data?.bank_ajent?.benificiary_bank_name}
                              </span>
                            </div>
                          </li>
                        </ul>

                        <ul
                          class="list-unstyled"
                          style={{ margin: "0px", paddingLeft: "6px" }}
                        >
                          <li className="laytime-list">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                Bank Address:
                              </span>
                              <span
                                style={{
                                  fontWeight: "lighter",
                                  paddingLeft: "4px",
                                }}
                              >
                                {" "}
                                {data?.bank_ajent?.b_country_name}
                              </span>
                            </div>
                          </li>
                        </ul>

                        <ul
                          class="list-unstyled"
                          style={{ margin: "0px", paddingLeft: "6px" }}
                        >
                          <li className="laytime-list">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                Account No: :
                              </span>
                              <span
                                style={{
                                  fontWeight: "lighter",
                                  paddingLeft: "4px",
                                }}
                              >
                                {data?.bank_ajent?.account_no}
                              </span>
                            </div>
                          </li>
                        </ul>

                        <ul
                          class="list-unstyled"
                          style={{ margin: "0px", paddingLeft: "6px" }}
                        >
                          <li className="laytime-list">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                Swift Code:
                              </span>
                              <span
                                style={{
                                  fontWeight: "lighter",
                                  paddingLeft: "4px",
                                }}
                              >
                                {" "}
                                {data?.bank_ajent?.cb_swift_code}
                              </span>
                            </div>
                          </li>
                        </ul>

                        <ul
                          class="list-unstyled"
                          style={{ margin: "0px", paddingLeft: "6px" }}
                        >
                          <li className="laytime-list">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>IBAN:</span>
                              <span
                                style={{
                                  fontWeight: "lighter",
                                  paddingLeft: "4px",
                                }}
                              >
                                {" "}
                                {data?.bank_ajent?.iban}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </article>
  );
});

const SOARecapReport = (props) => {
  const [state, setState] = useState({
    name: "Printer",
  });

  const componentRef = useRef();

  const printReceipt = () => {
    window.print();
  };

  return (
    <div className="body-wrapper modalWrapper modal-report-wrapper">
      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            <div className="toolbar-ui-wrapper">
              <div className="leftsection">
                {/* <span key="first" className="wrap-bar-menu">
                  <ul className="wrap-bar-ul">
                    <li>
                      <Tooltip title="Back">
                        <Button
                          type="dashed"
                          shape="circle"
                          icon="double-left"
                          size={"default"}
                          onClick={() => props.changeAccountState(true)}
                        />
                      </Tooltip>
                    </li>
                    <li>
                      <span className="text-bt">Statement of Account Recap</span>
                    </li>
                  </ul>
                </span> */}
              </div>
              <div className="rightsection">
                <span key="first" className="wrap-bar-menu">
                  <ul className="wrap-bar-ul">
                    <li>
                      <ReactToPrint
                        trigger={() => (
                          <span className="text-bt">
                            <PrinterOutlined /> Print
                          </span>
                        )}
                        content={() => componentRef.current}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="article">
        <div className="box box-default">
          <div className="box-body print-scroll">
            <ComponentToPrint ref={componentRef} vesseDetail={props.invData} />
          </div>
        </div>
      </article>
    </div>
  );
};

export default SOARecapReport;
