import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tabs,
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import URL_WITH_VERSION, {
  apiDeleteCall,
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../../shared";
const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const FormItem = Form.Item;

class Remark extends Component {
  constructor(props) {
    super(props);
    const column = [
      {
        title: "Remarks",
        dataIndex: "remark",
        key: "remark",
        width: "70%",
        render: (text) => (
          <div style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
            {this.safeDecodeBase64(text)}
          </div>
        ),
      },
      {
        title: "Date",
        dataIndex: "remark_date",
        key: "remark_date",
        width: "20%",
        render(text) {
          return {
            props: {
              style: { color: "#1d565c" },
            },
            children: (
              <div>
                {text && text !== "0000-00-00 00:00:00"
                  ? moment(text).format("YYYY-MM-DD HH:mm")
                  : ""}
              </div>
            ),
          };
        },
      },
      {
        title: "Action",
        key: "action",
        width: "10%",
        render: (text, record) => {
          return (
            <div className="editable-row-operations">
              <span
                className="iconWrapper"
                onClick={(e) => this.handleEdit(record)}
              >
                <EditOutlined />
              </span>
              <span className="iconWrapper cancel">
                <Popconfirm
                  title="Are you sure, you want to delete it?"
                  onConfirm={() => this.handleDelete(record.id)}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </span>
            </div>
          );
        },
      },
    ];
    this.state = {
      loading: false,
      responseData: [],
      columns: column,
      remarksId: this.props.remarksID || "",
      remarkType: this.props.remarkType,
      invoice_no: this.props.remarkInvNo,

      remarkText: "",
      remarkData: {},
      editRemarkData: null,
    };
    this.responsedataref = React.createRef();
  }

  safeDecodeBase64 = (text) => {
    try {
      return atob(text);
    } catch (error) {
      console.error("Error decoding base64:", error);
      return "Error Decoding";
    }
  };

  componentDidMount = () => {
    this.getRemarkData();
  };

  getRemarkData = async () => {
    this.setState({ ...this.state, loading: true });
    const { remarksId, remarkType } = this.state;
    console.log("remarkType", remarkType);
    let _url = `${URL_WITH_VERSION}/${remarkType}/remark/edit?ae=${remarksId}`;
    const response = await getAPICall(_url);
    const data = await response;

    let responseData = data && data.data ? data.data : [];

    this.responsedataref.current = responseData;
    this.setState({
      ...this.state,
      responseData: responseData,
      loading: false,
    });
  };

  saveFormData = () => {
    this.setState({ ...this.state, loading: true });
    const { remarkText, remarkType, remarkData, editRemarkData } = this.state;

    if (remarkText === "") {
      openNotificationWithIcon("info", "Please Enter Some Text");
      this.setState({ ...this.state, loading: false });
      return;
    }

    try {
      let suURL = `${URL_WITH_VERSION}/${remarkType}/remark/save`;
      let suMethod = "POST";
      if (editRemarkData) {
        suURL = `${URL_WITH_VERSION}/${remarkType}/remark/update`;
        suMethod = "PUT";
      }
      const data = {
        ...remarkData,
        remark: btoa(remarkText), // Ensure the remark is encoded before sending
      };
      data.remark_date = moment(data.remark_date);
      postAPICall(suURL, data, suMethod, (data) => {
        if (data && data.data) {
          openNotificationWithIcon("success", data.message);
          this.getRemarkData();
          this.setState({
            ...this.state,
            loading: false,
            remarkText: "",
            editRemarkData: null,
          });
        } else {
          openNotificationWithIcon("error", data.message);
          this.setState({
            ...this.state,
            loading: false,
            remarkText: "",
            editRemarkData: null,
          });
        }
      });
    } catch (err) {
      openNotificationWithIcon("error", "something went wrong");
      this.setState({ ...this.state, loading: false });
    }
  };

  handleRemarkChange = (e) => {
    const { editRemarkData } = this.state;
    let _remarkData;
    if (editRemarkData) {
      _remarkData = {
        ...editRemarkData,
        // invoice_no: this.props.remarksID,
        trans_no: this.props.trans_no,
        remark: e.target.value,
        remark_date: moment(),
      };
    } else {
      _remarkData = {
        invoice_no: this.props.remarksID,
        remark: e.target.value,
        remark_date: moment(),
        remark_type: this.props.idType,
      };
    }

    if (!_remarkData.remark_type) {
      delete _remarkData.remark_type;
    }

    this.setState({
      ...this.state,
      remarkText: e.target.value,
      remarkData: _remarkData,
    });
  };

  handleEdit = (record) => {
    const _responseData = this.responsedataref.current.filter(
      (item) => item.id !== record.id
    );

    this.setState({
      responseData: _responseData,
      remarkText: atob(record.remark), // Decode the value for editing
      editRemarkData: record,
      remarkData: record,
    });
  };

  handleDelete = (id) => {
    const { remarkType } = this.state;
    apiDeleteCall(
      `${URL_WITH_VERSION}/${remarkType}/remark/delete`,
      { id: id },
      (data) => {
        if (data) {
          openNotificationWithIcon("success", data.message, 2);
          this.getRemarkData();
        } else {
          openNotificationWithIcon("error", data.message, 2);
        }
      }
    );
  };

  render() {
    const { responseData, loading, remarkText, columns } = this.state;

    return (
      <>
        {loading === false ? (
          <Tabs defaultActiveKey="1">
            <TabPane key="ao2">
              <Table
                className="pl-summary-list-view"
                bordered
                columns={columns}
                dataSource={responseData}
                pagination={false}
                rowClassName={(r, i) =>
                  i % 2 === 0
                    ? "table-striped-listing"
                    : "dull-color table-striped-listing"
                }
              />
              <Row gutter={16} className="m-t-18">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form>
                    <FormItem
                      label="Remark"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <TextArea
                        placeholder="Enter Remark"
                        autoSize={{ minRows: 6, maxRows: 6 }}
                        value={remarkText}
                        onChange={this.handleRemarkChange}
                        style={{
                          border: "1px solid #d9d9d9",
                          borderRadius: "4px",
                        }}
                      />
                    </FormItem>
                    <FormItem wrapperCol={{ offset: 8 }}>
                      <Button type="primary" onClick={this.saveFormData}>
                        Save
                      </Button>
                    </FormItem>
                  </Form>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        ) : (
          <div className="col col-lg-12">
            <Spin tip="Loading...">
              <Alert message=" " description="Please wait..." type="info" />
            </Spin>
          </div>
        )}
      </>
    );
  }
}

export default Remark;
