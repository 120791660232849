import { Flex, Typography } from 'antd';
import React, { useMemo } from 'react';
const Text = Typography.Text;

const PortCongestionSlider = ({ components, currentIndex,setCurrentIndex }) => {
    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % components.length);
    };

    const prevSlide = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + components.length) % components.length
        );
    };

    const borderBottom = {
        borderBottom: "1px solid #dae3dc",
        minHeight: "30px",
    };
    const labelStyle = {
        color: "rgb(92 92 92)",
        lineHeight: "2",
        fontWeight: "500",
    };

    const containerStyle = {
        position: 'relative',
        width: '100%',
        maxWidth: '800px',
        margin: 'auto',
        overflow: 'hidden',
    };

    const sliderStyle = {
        display: 'flex',
        transition: 'transform 0.5s ease-in-out',
        transform: `translateX(-${currentIndex * 100}%)`,
    };

    const slideStyle = {
        minWidth: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const buttonStyle = {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        border: 'none',
        padding: '10px',
        cursor: 'pointer',
        zIndex: 1,
    };

    const prevButtonStyle = {
        ...buttonStyle,
        left: '10px',
    };

    const nextButtonStyle = {
        ...buttonStyle,
        right: '10px',
    };

    
    const slides = useMemo(() => {
        return components.map((portCongestion, index) => (
            <div key={index} style={slideStyle}>
                <div className="card" style={{ width: '100%' }}>
                    <Flex title="Information">
                        <div className="p-3 pb-0" style={{width:'100%'}}>
                            <div className="port-congestion-card">
                                <span className="pc-title">General</span>
                                <div style={{ columnCount: "2" }}>
                                    <Flex justify="space-between" style={borderBottom}>
                                        <Text style={labelStyle}>Vessels</Text>
                                        <Text>{portCongestion.VESSELS || "-"}</Text>
                                    </Flex>
                                    <Flex justify="space-between" style={borderBottom}>
                                        <Text style={labelStyle}>Market</Text>
                                        <Text>{portCongestion.MARKET || "-"}</Text>
                                    </Flex>
                                    <Flex justify="space-between" style={borderBottom}>
                                        <Text style={labelStyle}>Port ID</Text>
                                        <Text>{portCongestion.PORT_ID || "-"}</Text>
                                    </Flex>
                                    <Flex justify="space-between" style={borderBottom}>
                                        <Text style={labelStyle}>Week</Text>
                                        <Text>{portCongestion.WEEK || "-"}</Text>
                                    </Flex>
                                    <Flex justify="space-between" style={borderBottom}>
                                        <Text style={labelStyle}>Year</Text>
                                        <Text>{portCongestion.YEAR || "-"}</Text>
                                    </Flex>
                                    <Flex justify="space-between" style={borderBottom}>
                                        <Text style={labelStyle}>Shipclass</Text>
                                        <Text>{portCongestion.SHIPCLASS || "-"}</Text>
                                    </Flex>
                                </div>
                            </div>

                            <div className="port-congestion-card">
                                <span className="pc-title">Time Related</span>
                                <Flex justify="space-between" style={borderBottom}>
                                    <Text style={labelStyle}>Time Anchorage (Days)</Text>
                                    <Text>{portCongestion.TIME_ANCH || "-"}</Text>
                                </Flex>
                                <Flex justify="space-between" style={borderBottom}>
                                    <Text style={labelStyle}>
                                        Time Anchorage Difference (Days)
                                    </Text>
                                    <Text>{portCongestion.TIME_ANCH_DIFF || "-"}</Text>
                                </Flex>
                                <Flex justify="space-between" style={borderBottom}>
                                    <Text style={labelStyle}>Time Anchorage Difference (%)</Text>
                                    <Text>{portCongestion.TIME_ANCH_DIFF_PERC || "-"}</Text>
                                </Flex>
                                <Flex justify="space-between" style={borderBottom}>
                                    <Text style={labelStyle}>
                                        Time Anchorage Difference Standard Deviation (Days)
                                    </Text>
                                    <Text>{portCongestion.TIME_ANCH_STDEV || "-"}</Text>
                                </Flex>
                            </div>

                            <div className="port-congestion-card">
                                <span className="pc-title">Port Related</span>
                                <Flex justify="space-between" style={borderBottom}>
                                    <Text style={labelStyle}>Port Time (Days)</Text>
                                    <Text>{portCongestion.TIME_PORT || "-"}</Text>
                                </Flex>
                                <Flex justify="space-between" style={borderBottom}>
                                    <Text style={labelStyle}>Port Time Difference (Days)</Text>
                                    <Text>{portCongestion.TIME_PORT_DIFF || "-"}</Text>
                                </Flex>
                                <Flex justify="space-between" style={borderBottom}>
                                    <Text style={labelStyle}>Port Time Difference (%)</Text>
                                    <Text>{portCongestion.TIME_PORT_DIFF_PERC || "-"}</Text>
                                </Flex>
                                <Flex justify="space-between" style={borderBottom}>
                                    <Text style={labelStyle}>
                                        Port Time Standard Deviation (Days)
                                    </Text>
                                    <Text>{portCongestion.TIME_PORT_STDEV || "-"}</Text>
                                </Flex>
                            </div>
                        </div>
                    </Flex>
                </div>
            </div>
        ));
    }, [components]); 

    return (
        <div style={containerStyle}>
            {components.length > 0 && (
                <button style={prevButtonStyle} onClick={prevSlide}>
                    ❰
                </button>
            )}
            <div style={sliderStyle}>
                {slides}
            </div>
            {components.length > 0 && (
                <button style={nextButtonStyle} onClick={nextSlide}>
                    ❱
                </button>
            )}
        </div>
    );
};

export default PortCongestionSlider;
