import React, { Component } from 'react';
import { Table, Form, Input, Button, Tabs, Upload, Icon, TimePicker } from 'antd';

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const columns = [
  {
    title: 'Title Name',
    dataIndex: 'name',
  },

  {
    title: 'Description',
    dataIndex: 'description',
  },
];
const data = [
  {
    key: '1',
    name: 'JT1',
    description: 'Lorem Ipsum is simply dummy text',
  },
  {
    key: '2',
    name: 'JT1',
    description: 'Lorem Ipsum is simply dummy text',
  },
  {
    key: '3',
    name: 'JT1',
    description: 'Lorem Ipsum is simply dummy text',
  },
];

class TcovPortInformation extends Component {
  render() {
    return (
      <div className="body-wrapper modalWrapper">
        <Tabs defaultActiveKey="Birth1information" size="small">
          <TabPane className="pt-3" tab="Birth 1 Information" key="Birth1Information">
            <Table
              bordered
              columns={columns}
              dataSource={data}
              pagination={false}
              footer={() => (
                <div className="text-center">
                  <Button type="link">Add New</Button>
                </div>
              )}
            />
          </TabPane>
          <TabPane className="pt-3" tab="Birth 2 Information" key="Birth2information">
            <Table
              bordered
              columns={columns}
              dataSource={data}
              pagination={false}
              footer={() => (
                <div className="text-center">
                  <Button type="link">Add New</Button>
                </div>
              )}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default TcovPortInformation;
