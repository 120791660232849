import * as route from "../constants";

// const classDropdown = [
//   { value: 'DRY', label: 'DRY' },
//   { value: 'LIQUID', label: 'LIQUID' },
//   { value: 'UNSPECIFIED', label: 'UNSPECIFIED' },
//   {}
// ];

export const TABLE_FIELDS = {
  subs: {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "no_of_users",
        title: "No Of Users",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
      {
        dataIndex: "per_user_price_m",
        title: "Per User Price",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
      {
        dataIndex: "plan_name",
        title: "Plan Name",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
      {
        dataIndex: "plan_code",
        title: "Plan Code",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
      {
        dataIndex: "plan_price",
        title: "Plan Price",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
      {
        dataIndex: "plan_type",
        title: "Plan Type",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
      {
        dataIndex: "validity_days",
        title: "Validity Days",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
    ],
    key: route.SUBSCRIPTION,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "beaufort-scale": {
    tableheads: [
      {
        title: "No",
        dataIndex: "scale",
        width: 150,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 500,
        f_req: true,
        f_type: "textarea",
        editable: "true",
      },
    ],
    key: route.BEAUFORT_SCALE,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "cargo-description": {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "cargo_name",
        title: "Cargo Name",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "cargo_description",
        title: "Cargo Description",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "status",
        title: "Status",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.CARGO_DESCRIPTION,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  currencies: {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "currency",
        title: "Currency",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "country",
        title: "Country",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "status",
        title: "Status",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.CURRENCIES,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "currency-types": {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "currency",
        title: "Currency",
        width: 250,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "country",
        title: "Country",
        width: 350,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.CURRENCIE_TYPES,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },

  "bank-names": {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "name",
        title: "Names",
        width: 250,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 350,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
      ,
      {
        dataIndex: "short_code",
        title: "Short Code",
        width: 350,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
    ],
    key: route.BANKNAMES,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
    addOption: true,
  },

  "fuel-grades": {
    tableheads: [
      {
        dataIndex: "short_code",
        title: "Short Name",
        width: 150,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "grade_name",
        title: "Grade Name",
        width: 500,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "sulphur_percentage",
        title: "Sulphur %",
        width: 150,
        f_req: true,
        f_type: "number",
        editable: "false",
      },
    ],
    key: route.GRADES,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "delay-reasons": {
    tableheads: [
      {
        dataIndex: "id",
        title: "No.",
        f_req: false,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "delay_reason",
        title: "Reason",
        width: 300,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "property",
        title: "Property",
        width: 400,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.DELAY_REASON,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "delay-types": {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        f_req: false,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Activity",
        f_req: true,
        width: 300,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "performance_cal",
        title: "Stream/Stop",
        width: 300,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.DELAY_TYPES,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "freight-rate": {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        f_req: false,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "term_name",
        title: "Term Name",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "rate",
        title: "Rate",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "type",
        title: "Type",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "status",
        title: "Status",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "insert_date",
        title: "Inserted Date",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.FREIGHT_RATE,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "freight-codes": {
    tableheads: [
      {
        dataIndex: "code",
        title: "Code",
        width: 150,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 500,
        f_req: true,
        f_type: "textarea",
        editable: "false",
      },
      {
        dataIndex: "commissionable",
        title: "Commissionable",
        width: 200,
        f_req: false,
        f_type: "boolean",
        editable: "false",
      },
    ],
    key: route.FREIGHT_TERM,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "fuel-consumption-categories": {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        f_req: false,
        width: 150,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "type",
        title: "Consumption Type Name",
        width: 500,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.FUEL_CONSUMPTION_CATEGORIES,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "fuel-types": {
    tableheads: [
      {
        dataIndex: "code",
        title: "Code",
        f_req: true,
        width: 150,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "fuel_type",
        title: "Type",
        f_req: true,
        width: 200,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "fuel_description",
        title: "Description",
        width: 400,
        f_req: true,
        f_type: "textarea",
        editable: "false",
      },
      {
        dataIndex: "group",
        title: "G",
        width: 100,
        f_req: true,
        f_type: "dropdown",
        f_dyc_extras: {
          options: [],
          orderBy: "id",
          selectCols: {
            value: "id",
            label: ["short_code", "grade_name"],
            pv: ["sulphur_percentage", "co2_factor"],
            show: "short_code",
          },
          whereClause: { status: "1" },
          url: "master/list?t=grades&l=0",
        },
        editable: "false",
      },
      {
        dataIndex: "co2_factor",
        title: "Sulphur %",
        f_req: true,
        width: 200,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.FUEL_TYPE,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "fuel-zone": {
    tableheads: [
      {
        dataIndex: "zone",
        title: "Zone",
        f_req: true,
        width: 200,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "propulsion_sea",
        title: "Propulsion at Sea (Propulsion/Aux)",
        f_req: false,
        width: 300,
        f_type: "dropdown",
        f_dyc_extras: {
          url: "master/list?t=fulty&l=0&p=1",
          selectCols: { value: "code", label: "code" },
        },
        editable: "false",
      },
      {
        dataIndex: "consumption_in_port",
        title: "Consumption In Port (L/D/Idel)",
        width: 300,
        f_req: false,
        f_type: "dropdown",
        f_dyc_extras: {
          url: "master/list?t=fulty&l=0&p=1",
          selectCols: { value: "code", label: "code" },
        },
        editable: "false",
      },
      {
        dataIndex: "misc_consumption",
        title: "Misc. Consumption (Heat/IGS, etc.)",
        width: 300,
        f_req: false,
        f_type: "dropdown",
        f_dyc_extras: {
          url: "master/list?t=fulty&l=0&p=1",
          selectCols: { value: "code", label: "code" },
        },
        editable: "false",
      },
    ],
    key: route.FUEL_ZONE,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "laytime-commence": {
    tableheads: [
      {
        dataIndex: "code",
        title: "Code",
        width: 150,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "name",
        title: "Term",
        width: 300,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 400,
        f_req: false,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.LAYTIME_TO_COMMENCE,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "laytime-types": {
    tableheads: [
      {
        dataIndex: "code",
        title: "Code",
        width: 200,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "name",
        title: "Term",
        width: 300,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "paragraph",
        title: "Description",
        width: 400,
        f_req: false,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.LAYTIME_TYPE,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "loading-costs": {
    tableheads: [
      {
        dataIndex: "id",
        title: "Code",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "name",
        title: "Term",
        width: 300,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "paragraph",
        title: "Description",
        width: 400,
        f_req: false,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.LOADING_COST,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "loadline-zone": {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "load_line",
        title: "Load Line",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "salinity",
        title: "Salinity",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "status",
        title: "Status",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.LOADLINE_ZONE,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "nor-tender": {
    tableheads: [
      {
        dataIndex: "id",
        title: "Code",
        width: 150,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "name",
        title: "Term",
        width: 300,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "paragraph",
        title: "Description",
        width: 400,
        f_req: false,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.NOR_TO_TENDER,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "payment-term": {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "code",
        title: "Code",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "period_basis",
        title: "Period Basis",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "period",
        title: "Period",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "status",
        title: "Status",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.PAYMENT_TERM,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "port-activity": {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "activity_name",
        title: "Activity Name",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "status",
        title: "Status",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.PORT_ACTIVITY,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "shinc-terms": {
    tableheads: [
      {
        dataIndex: "id",
        title: "Code",
        f_req: false,
        width: 150,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "code",
        title: "Term",
        f_req: true,
        width: 300,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 400,
        f_req: false,
        f_type: "textarea",
        editable: "false",
      },
      {
        dataIndex: "factor",
        title: "Factor",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "s_term",
        title: "S. Term",
        f_req: false,
        width: 100,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.PORT_TERM,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "sea-states": {
    tableheads: [
      {
        dataIndex: "scale",
        title: "No",
        f_req: true,
        width: 200,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 500,
        f_req: true,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.SEA_FORCE,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "swell-states": {
    tableheads: [
      {
        dataIndex: "scale",
        title: "No",
        f_req: true,
        width: 200,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 500,
        f_req: true,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.SEA_SWELLS,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "shifting-terms": {
    tableheads: [
      {
        dataIndex: "id",
        title: "Code",
        width: 150,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "name",
        title: "Term",
        f_req: true,
        width: 300,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "paragraph",
        title: "Description",
        width: 400,
        f_req: true,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.SHIFTING_TERMS,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "time-used": {
    tableheads: [
      {
        dataIndex: "code",
        title: "Code",
        f_req: true,
        width: 150,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "name",
        title: "Term",
        f_req: true,
        width: 300,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "paragraph",
        title: "Description",
        width: 400,
        f_req: false,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.TIME_USED,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "time-tender": {
    tableheads: [
      {
        dataIndex: "code",
        title: "Code",
        f_req: true,
        width: 150,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "name",
        title: "Term",
        f_req: true,
        width: 300,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "paragraph",
        title: "Description",
        width: 400,
        f_req: false,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.TIME_TO_RENDER,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "trade-areas": {
    tableheads: [
      {
        dataIndex: "code",
        title: "Code",
        f_req: true,
        width: 150,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 400,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "turnover_ration",
        title: "Turnover Ration",
        width: 250,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.TRADE,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "units-measure": {
    tableheads: [
      {
        dataIndex: "short_name",
        title: "Code",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "unit_name",
        title: "Description",
        f_req: true,
        width: 300,
        f_type: "textarea",
        editable: "false",
      },
      {
        dataIndex: "short_code",
        title: "T",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "alt_code",
        title: "Alt Code",
        f_req: false,
        width: 200,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "conversion_factor",
        title: "Conversion Factor",
        width: 200,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.UNIT_MEASURES,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "unit-selection": {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "type",
        title: "Type",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "status",
        title: "Status",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.UNIT_SELECTION,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "class-societies": {
    tableheads: [
      {
        dataIndex: "vessel_class_name",
        title: "Code",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.VESSEL_CLASS_SOCIETY,
    pageWidths: {
      lg: 4,
      sm: 12,
      md: 4,
      xs: 12,
    },
    isPagination: false,
  },
  "vessel-fuel-type": {
    tableheads: [
      {
        dataIndex: "id",
        title: "S. No.",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "fuel_type_id",
        title: "Fuel Type Id",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "fuel_type",
        title: "Fuel Type",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "price",
        title: "Price",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "b_ist",
        title: "B Ist",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "laden",
        title: "Laden",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "vessel_fuel_load",
        title: "Vessel Fuel Load",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "disch",
        title: "Disch",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "idle",
        title: "Idle",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "estimate_id",
        title: "Estimate Id",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "vessel_id",
        title: "Vessel Id",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "status",
        title: "Status",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.VESSEL_FUEL_TYPE,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "vessel-types": {
    tableheads: [
      {
        dataIndex: "vessel_type",
        title: "Type",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.VESSEL_TYPE,
    pageWidths: {
      lg: 6,
      sm: 12,
      md: 6,
      xs: 12,
    },
    isPagination: false,
  },
  "working-days": {
    tableheads: [
      {
        dataIndex: "code",
        title: "Code",
        width: 150,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "short_name",
        title: "Term",
        width: 300,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 400,
        f_req: false,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.WHEATHER_WORKING,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  /*
    'loadline-zone': {
        'tableheads': [
            { dataIndex: 'id', title: 'S. No.', width: 100, f_req: false, f_type: "text", editable: "false" },
            { dataIndex: 'load_line', title: 'Load Line', width: 100, f_req: true, f_type: "text", editable: "true" },
            { dataIndex: 'salinity', title: 'Salinity', width: 100, f_req: true, f_type: "text", editable: "true" },
            { dataIndex: 'status', title: 'Status', width: 100, f_req: true, f_type: "text", editable: "true" }
        ],
        'key': route.LOADLINE_ZONE,
        'pageWidths': {
            'lg': 12,
            'sm': 12,
            'md': 12,
            'xs': 12
        },
        'isPagination': false
    },
    'payment-term': {
        'tableheads': [
            { dataIndex: 'id', title: 'S. No.', width: 100, f_req: false, f_type: "text", editable: "false" },
            { dataIndex: 'code', title: 'Code', f_req: true, width: 100, f_type: "text", editable: "true" },
            { dataIndex: 'description', title: 'Description', width: 100, f_req: true, f_type: "text", editable: "true" },
            { dataIndex: 'period_basis', title: 'Period Basis', width: 100, f_req: true, f_type: "text", editable: "true" },
            { dataIndex: 'period', title: 'Period', f_req: true, width: 100, f_type: "text", editable: "true" },
            { dataIndex: 'status', title: 'Status', width: 100, f_req: true, f_type: "text", editable: "true" }
        ],
        'key': route.PAYMENT_TERM,
        'pageWidths': {
            'lg': 12,
            'sm': 12,
            'md': 12,
            'xs': 12
        },
        'isPagination': false
    },*/

  "port-list": {
    tableheads: [
      {
        dataIndex: "port_name",
        title: "Port Name",
        width: 100,
        invisible: "false",
        f_req: true,
        f_typ: "text",
        editable: "false",
      },
      {
        dataIndex: "port_type",
        title: "Port Type",
        width: 100,
        invisible: "false",
        f_req: true,
        f_typ: "text",
        editable: "false",
      },
      {
        dataIndex: "lat",
        title: "Lat",
        invisible: "false",
        width: 100,
        f_req: true,
        f_typ: "text",
        editable: "false",
      },
      {
        dataIndex: "long",
        title: "Long",
        invisible: "false",
        width: 100,
        f_req: true,
        f_typ: "text",
        editable: "false",
      },
      {
        dataIndex: "country_id",
        title: "Country Name",
        f_req: false,
        width: 100,
        f_type: "dropdown",
        // f_dyc_extras: classDropdown,
        editable: "false",
      },
      {
        dataIndex: "PortID",
        title: "Port Id",
        invisible: "false",
        width: 100,
        f_req: true,
        f_typ: "text",
        editable: "false",
      },
    ],
    key: route.PORT,
    pageWidths: {
      lg: 8,
      sm: 12,
      md: 8,
      xs: 12,
    },
    isPagination: false,
    isColumnSelection: true,
  },
  "port-functions": {
    tableheads: [
      {
        dataIndex: "short_code",
        title: "Code",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "function_name",
        title: "Port Operation",
        f_req: true,
        width: 300,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "delay_type",
        title: "Delay Type",
        f_req: false,
        width: 300,
        f_type: "dropdown",
        f_dyc_extras: {
          options: [],
          orderBy: "id",
          selectCols: { value: "id", label: "description" },
          whereClause: { status: "1" },
          url: "master/list?t=delty&l=0",
        },
        editable: "false",
      },
      {
        dataIndex: "default_delay_bunkers",
        title: "Default Delay Bunkers",
        width: 200,
        f_req: false,
        f_type: "boolean",
        editable: "false",
      },
      {
        dataIndex: "idle_off_cons",
        title: "Idle/Off Cons",
        width: 200,
        f_req: false,
        f_type: "boolean",
        editable: "false",
      },
    ],
    key: route.PORT_FUNCTION,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "port-activities-term": {
    tableheads: [
      {
        dataIndex: "short_name",
        title: "Short Name",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "activity_name",
        title: "Activities",
        width: 200,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "from_date_time",
        title: "From Date/time",
        width: 200,
        f_req: true,
        f_type: "datetime",
        editable: "false",
      },
      {
        dataIndex: "day",
        title: "Day",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "true",
      },
      {
        dataIndex: "act_type",
        title: "Act Type",
        width: 100,
        f_req: true,
        f_type: "dropdown",
        f_dyc_extras: {
          options: [],
          orderBy: "id",
          selectCols: { value: "id", label: "code" },
          whereClause: { status: "1" },
          url: "master/list?t=actpe&l=0",
        },
        editable: "false",
      },
      {
        dataIndex: "laytime_calc",
        title: "Laytime Calc Import",
        width: 100,
        f_req: true,
        f_type: "boolean",
        editable: "false",
      },
      {
        dataIndex: "remarks",
        title: "Remarks",
        width: 200,
        f_req: true,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.PORT_ACTIVITY,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "country-list": {
    tableheads: [
      {
        dataIndex: "country_name",
        title: "Country Name",
        width: 250,
        invisible: "false",
        f_req: true,
        f_typ: "text",
        editable: "true",
        sorter: (a, b) =>
          a.country_name && b.country_name
            ? a.country_name.localeCompare(b.country_name)
            : null,
      },
      {
        dataIndex: "currency",
        title: "Currency",
        width: 150,
        invisible: "false",
        f_req: true,
        f_typ: "text",
        editable: "true",
        sorter: (a, b) =>
          a.currency && b.currency
            ? a.currency.localeCompare(b.currency)
            : null,
      },
      {
        dataIndex: "flag",
        title: "Flag",
        width: 120,
        invisible: "false",
        f_req: false,
        f_typ: "text",
        editable: "true",
      },
      {
        dataIndex: "c_code",
        title: "Code",
        width: 150,
        invisible: "false",
        f_req: true,
        f_typ: "text",
        editable: "true",
      },
      {
        dataIndex: "currency_alpha_code",
        title: "Currency Name",
        width: 150,
        invisible: "false",
        f_req: true,
        f_typ: "text",
        editable: "true",
      },
      {
        dataIndex: "numeric_currency_code",
        title: "Currency Code",
        width: 100,
        invisible: "false",
        f_req: true,
        f_typ: "text",
        editable: "true",
      },
      // {
      //   dataIndex: 'monor_unit',
      //   title: 'Monor Unit',
      //   width: 150,
      //   invisible: 'false',
      //   f_req: true,
      //   f_typ: 'text',
      //   editable: 'false',
      // },
      // {
      //   dataIndex: 'c_active',
      //   title: 'Active',
      //   width: 150,
      //   invisible: 'false',
      //   f_req: true,
      //   f_typ: 'text',
      //   editable: 'false',
      // },
      // {
      //   dataIndex: 'isd_code',
      //   title: 'ISD Code',
      //   width: 100,
      //   invisible: 'false',
      //   f_req: false,
      //   f_typ: 'text',
      //   editable: 'false',
      // },
    ],
    key: route.COUNTRY,
    pageWidths: {
      lg: 12,
      sm: 10,
      md: 10,
      xs: 10,
    },
    isPagination: false,
    isColumnSelection: true,
    addOption: true,
  },
  "charter-party-forms": {
    tableheads: [
      {
        dataIndex: "name",
        title: "Name",
        width: 200,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 200,
        f_req: true,
        f_type: "textarea",
        editable: "false",
      },
      {
        dataIndex: "file",
        title: "File",
        width: 200,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.CHARTY_PARTY_FORMS,
    pageWidths: {
      lg: 6,
      sm: 12,
      md: 6,
      xs: 12,
    },
    isPagination: false,
  },
  "normal-off": {
    tableheads: [
      {
        dataIndex: "id",
        title: "Code",
        width: 150,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "term",
        title: "Term",
        width: 300,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 400,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.NORMAL_OFFICE_HOURS,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "other-loading-terms": {
    tableheads: [
      {
        dataIndex: "id",
        title: "Code",
        width: 150,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "name",
        title: "Term",
        width: 300,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "paragraph",
        title: "Description",
        width: 400,
        f_req: false,
        f_type: "textarea",
        editable: "false",
      },
    ],
    key: route.OTHER_LOADING_TERMS,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "operations-ledger": {
    tableheads: [
      {
        dataIndex: "code",
        title: "Code",
        f_req: true,
        width: 150,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 400,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "category",
        title: "Category",
        f_req: true,
        width: 250,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.OPERATION_LEDGER,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  "vessel-daily-cost": {
    tableheads: [
      {
        dataIndex: "id",
        title: "Code",
        width: 100,
        f_req: false,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "vessel_name",
        title: "Vessel Name",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "daily_cost_code",
        title: "Cost Code",
        width: 100,
        f_req: true,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "daily_cost_description",
        title: "Description",
        width: 100,
        f_req: true,
        f_type: "textarea",
        editable: "false",
      },
      {
        dataIndex: "daily_cost",
        title: "Cost",
        f_req: true,
        f_type: "text",
        width: 100,
        editable: "false",
      },
    ],
    key: route.VESSEL_DAILY_COST,
    pageWidths: {
      lg: 6,
      sm: 12,
      md: 6,
      xs: 12,
    },
    isPagination: false,
  },
  "vessel-fleets": {
    tableheads: [
      {
        dataIndex: "fleet",
        title: "Fleet",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.VESSEL_FLEET,
    pageWidths: {
      lg: 6,
      sm: 12,
      md: 6,
      xs: 12,
    },
    isPagination: false,
  },
  "opa-rates": {
    addOption: false,
    tableheads: [
      {
        dataIndex: "id",
        title: "Code",
        f_req: false,
        width: 100,
        f_type: "text",
        editable: "false",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 400,
        f_req: true,
        f_type: "textarea",
        editable: "false",
      },
      {
        dataIndex: "rate",
        title: "Rate",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "false",
      },
    ],
    key: route.OPA_RATES,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
  },
  // 'address-list': {
  //     'tableheads': [
  //         { dataIndex: 'year', title: 'Short Name', f_req: true, f_type: "text", editable: "true" },
  //         { dataIndex: 'description', title: 'Full Name', f_req: true, f_type: "text", editable: "true" },
  //         { dataIndex: 'description', title: 'Address Type', f_req: true, f_type: "text", editable: "true" },
  //         { dataIndex: 'description', title: 'Address', f_req: true, f_type: "text", editable: "true" },
  //         { dataIndex: 'description', title: 'Phone Number', f_req: true, f_type: "text", editable: "true" },
  //         { dataIndex: 'description', title: 'Currency', f_req: true, f_type: "text", editable: "true" }
  //     ],
  //     'key': null,
  //     'pageWidths': {
  //         'lg': 12,
  //         'sm': 12,
  //         'md': 12,
  //         'xs': 12
  //     },
  //     'isPagination': false
  // },
  "cargo-groups": {
    tableheads: [
      {
        dataIndex: "type",
        title: "Type",
        f_req: true,
        width: 100,
        f_type: "text",
        editable: "true",
      },
      {
        dataIndex: "description",
        title: "Description",
        width: 400,
        f_req: false,
        f_type: "textarea",
        editable: "true",
      },
      {
        dataIndex: "class",
        title: "Class",
        f_req: false,
        width: 200,
        f_type: "dropdown",
        f_dyc_extras: {
          options: [],
          orderBy: "id",
          selectCols: {
            value: "short_name",
            label: "short_name",
            show: "short_name",
          },
          whereClause: { status: "1" },
          url: "master/list?t=carcla&l=0",
        },
        editable: "true",
      },
    ],
    key: route.CARGO_GROUP,
    pageWidths: {
      lg: 12,
      sm: 12,
      md: 12,
      xs: 12,
    },
    isPagination: false,
    addOption: true,
  },
};
