import { Button, Checkbox, Drawer, Input } from 'antd';
import React from 'react';

const INITIAL_STATE = {
    columns: [],
    sidebarVisible: false,
    searchField: null
};

class SidebarColumnFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE
    }

    static getDerivedStateFromProps(props, state) {
        if (props && props.columns && props.columns.length > 0 && props.sidebarVisible !== state.sidebarVisible) {
            props.columns.map((e, index) => {
                e["invisible"] = (e.hasOwnProperty("invisible") ? e["invisible"] : "false")
            })
            return props;
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        let currentProps = this.props;
        if (prevProps.sidebarVisible !== currentProps.sidebarVisible) {
            currentProps.columns.map(e => {
                e["invisible"] = (e.hasOwnProperty("invisible") ? e["invisible"] : "false")
            })
            this.setState(currentProps)
        }
    }

    onFieldHide = (index) => {
        let columns = Object.assign([], this.state.columns);
        if (index || index === 0) {
            let table = columns[index]
            table["invisible"] = (table["invisible"] === "true" ? "false" : "true")
        }

        let columnLength = columns.filter(x => this.checkFieldHide(x) && x.invisible === "false").length
        columns.map(e => {
            if (this.checkFieldHide(e))
                e["width"] = (e.invisible === "false") ? `calc(${(100 / columnLength)}% - 100px)` : '0%'
        })

        this.setState({
            ...this.state,
            columns
        }, () => this.props.callback({ 'actionName': 'column-filter', 'sidebarVisible': this.state.sidebarVisible, columns }))
    }

    onResetFilters = () => {
        let columns = Object.assign([], this.state.columns);
        if (columns && columns.length > 0) {
            let columnLength = columns.filter(x => this.checkFieldHide(x)).length
            columns.map((e, i) => {
                if (this.checkFieldHide(e)) {
                    e["invisible"] = (e.hasOwnProperty("isReset") ? "true" : "false")
                    e["width"] = (e.hasOwnProperty("isReset") ? "0%" : `calc(${(100 / columnLength)}% - 100px)`)
                }
            })
            this.setState({
                ...this.state,
                columns,
                searchField: null
            }, () => this.props.callback({ 'actionName': 'column-filter', 'sidebarVisible': this.state.sidebarVisible, columns }))
        }
    }

    onSearchField = (e) => this.setState({ "searchField": e })

    onClose = () => this.props.callback({ 'actionName': 'column-filter', 'sidebarVisible': false });

    checkFieldHide = (item) => (item.hasOwnProperty('dataIndex') && item.dataIndex !== 'action') || (item.hasOwnProperty('key') && item.key !== 'action')

    render() {
        const { sidebarVisible, columns, searchField } = this.state;
        return (
            <Drawer
                title="Filter Column"
                placement="right"
                closable={true}
                onClose={this.onClose}
               open={sidebarVisible}
                // getContainer={false}
                // style={{ position: 'absolute' }}
                width={400}
            >
                <div className="row">
                    <div className="col-lg-12">
                        <Input
                            key="search"
                            type="text"
                            name="search"
                            placeholder="Filter"
                            value={searchField}
                            onChange={(field) => this.onSearchField(field.target.value)}
                        />
                    </div>
                    <div className="col-lg-12 mt-2">
                        {
                            columns.length > 0
                                ? (
                                    columns
                                        .filter(name => (searchField ? name.title.toLowerCase().indexOf(searchField) > -1 : true))
                                        .map((item, index) => {
                                            return (
                                                this.checkFieldHide(item) ?
                                                    <div className="checkbox mt-2" key={index}>
                                                        <Checkbox
                                                            id={"index-" + index}
                                                            name={"index-" + index}
                                                            checked={item.invisible === "false"}
                                                            onChange={() => this.onFieldHide(index)}
                                                        >
                                                            {item.title}
                                                        </Checkbox>
                                                    </div>
                                                    : undefined
                                            )
                                        })
                                ) : null
                        }
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.onResetFilters} style={{ marginRight: 8 }}>Reset</Button>
                </div>
            </Drawer>
        )
    }
}

SidebarColumnFilter.defaultProps = INITIAL_STATE

export default SidebarColumnFilter;