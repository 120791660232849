import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import {
  Form,
  Input,
  Row,
  Col,
  Modal,
  Radio,
  Checkbox,
  Spin,
  Alert,
  Tooltip,
  Button,
} from "antd";
import NormalFormIndex from "../../NormalForm/normal_from_index";
import ConfirmStatement from "../../../routes/chartering/routes/make-payment/ConfirmStatement";
import StatementOfAccountReport from "../All-Print-Reports/StatementOfAccountReport";
//import MakePayment from '../../../components/MakePayment';
import SOARecapReport from "../All-Print-Reports/SOARecapReport";

import URL_WITH_VERSION, {
  getAPICall,
  openNotificationWithIcon,
  useStateCallback,
} from "../../../shared";
import OtherStatement from "../other-statement/OtherStatement";

const FormItem = Form.Item;

const getFormItemLayout = () => {
  return {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
};

const HireStatement = React.forwardRef((props, ref) => {
  //console.log("props in hirestatement from tci", props);
  const [state, setState] = useStateCallback({
    showDiv: true,
    showDivAccount: true,
    showDivRecap: true,
    showDivOtherReport: true,
    value: 1,
    frmName: "hire_payment_form",
    externalFormFields: null,
    visible: false,
    tciID: props.tciID,
    formData: props.paymentData || {},
    paymentFormData: props.paymentFormData || {},
    paymentData: null,
    showForm: false,
  });

  const totalAmountpaidRef = useRef(0);
  const printRef = useRef();
  const updatePaymentRows = async (formData, tciID, paymentFormData) => {
    try {
      let response = await getAPICall(
        `${URL_WITH_VERSION}/make_payment/list?l=0`,

        {
          id: {
            where:
              "`vessel_id`= " +
              tciID.vessel_id +
              " AND `tc_id`='" +
              tciID.tc_id +
              "'",
          },
        }
      );
      let respData = await response["data"];
      const updatedFormData = { ...formData, "-": respData };
      setState((prevState) => {
        const newState = Object.assign({}, prevState);
        newState["formData"]["-"] = respData;
        return newState;
      });

      return respData ? respData : respData;
    } catch (err) {
      console.log("err", err);
    }
  };

  const {
    frmName,
    externalFormFields,
    showDiv,
    showDivAccount,
    showDivOtherReport,
    showDivRecap,
    formData,
    showForm,
    tciID,
  } = state;

  useEffect(() => {
    const getFormData = async () => {
      try {
        const data = await updatePaymentRows(
          state.formData,
          state.tciID,
          state.paymentFormData
        );

        if (data) {
          const { formData } = state;

          let totalAmount = 0;

          formData["-"].map((e) => {
            totalAmount = totalAmount + e.amount_usd.replaceAll(",", "") * 1;
          });
          totalAmountpaidRef.current = totalAmount;
          setState((prevState) => {
            const newState = Object.assign({}, prevState);
            newState["showForm"] = true;
            newState["externalFormFields"] = {
              isShowInMainForm: true,
              content: getExternalFormFields(),
            };
            return newState;
          });
        } else {
          openNotificationWithIcon(
            "info",
            "Please Generate Make Payment Invoice First"
          );
          props.closeModal();
        }
      } catch (err) {
        console.log("err", err);
      }
    };

    getFormData();
  }, []);

  const getExternalFormFields = () => {
    return (
      <>
        <Row style={{ width: "97%", margin: "10px auto" }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Radio.Group onChange={onChange} value={state.value}>
              <Radio value={1}>Invoiced</Radio>
              {/* <Radio value={2}>Paid</Radio> */}
            </Radio.Group>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}></Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <FormItem {...getFormItemLayout()} label="Total">
              <Input
                placeholder="0.00"
                style={{ textAlign: "right" }}
                disabled
                value={totalAmountpaidRef.current.toFixed(2)}
              />
            </FormItem>
          </Col>
        </Row>
      </>
    );
  };

  const changeState = (showDiv) =>
    setState((prevState) => ({ ...prevState, showDiv }));

  const changeAccountState = (showDivAccount) =>
    setState((prevState) => ({ ...prevState, showDivAccount }));

  const changeOtherReport = (showDivOtherReport) =>
    setState((prevState) => ({ ...prevState, showDivOtherReport }));

  const changeRecapState = (showDivRecap) =>
    setState((prevState) => ({ ...prevState, showDivRecap }));

  const onChange = (e) =>
    setState((prevState) => ({ ...prevState, value: e.target.value }));

  return (
    <>
      {(() => {
        if (showDivAccount === false) {
          return (
            <div className="reports-container">
              <StatementOfAccountReport
                changeAccountState={(e1) => changeAccountState(e1)}
              />
            </div>
          );
        } else if (showDivOtherReport === false) {
          return (
            <div className="reports-container">
              <OtherStatement
                changeOtherReport={(e3) => changeOtherReport(e3)}
              />
            </div>
          );
        } else if (showDivRecap === false) {
          return (
            <div className="reports-container">
              <SOARecapReport changeRecapState={(e2) => changeRecapState(e2)} />
            </div>
          );
        } else if (showDiv === true) {
          return (
            <div className="body-wrapper modalWrapper" ref={ref}>
              <article className="article ">
                <div className="box box-default">
                  <div className="box-body">
                    {externalFormFields && showForm ? (
                      <NormalFormIndex
                        key={"key_" + frmName + "_0"}
                        formClass="label-min-height"
                        formData={formData}
                        showForm={true}
                        frmCode={frmName}
                        inlineLayout={true}
                        extraFormFields={externalFormFields}
                        showToolbar={[
                          {
                            isLeftBtn: [],
                            isRightBtn: [
                              {
                                key: "s2",
                                isSets: [
                                  // { key: "make_payment", isDropdown: 0, withText: "Make Payment", type: "", menus: null},
                                  {
                                    key: "hire_statement",
                                    isDropdown: 0,
                                    withText: "Hire Statement",
                                    type: "",
                                    menus: null,
                                    event: (key) => {
                                      changeState(!showDiv);
                                    },
                                  },
                                  // { key: "statementof_account", isDropdown: 0, withText: "Statement of Account", type: "", menus: null, "event": (key) => { }},   //"event": (key) => //{ changeAccountState(!showDivAccount) }},
                                  // { key: "other_report", isDropdown: 0, withText: "Other Report", type: "", menus: null, "event": (key) => { }},   //{ changeOtherReport(!showDivOtherReport) }},
                                  {
                                    key: "print",
                                    isDropdown: 0,
                                    withText: "Print",
                                    type: "",
                                    menus: null,
                                    event: (key) => {
                                      props.handlePrint();
                                    },
                                  },
                                ],
                              },
                            ],
                            isResetOption: false,
                          },
                        ]}
                        // extraTableButton={{
                        //   "-": [{"icon": "eye", "onClickAction": (action) => { console.log(action) }}],
                        // }}
                      />
                    ) : (
                      <div className="col col-lg-12">
                        <Spin tip="Loading...">
                          <Alert
                            message=" "
                            description="Please wait..."
                            type="info"
                          />
                        </Spin>
                      </div>
                    )}
                  </div>
                </div>
              </article>
            </div>
          );
        }
      })()}

      {showDiv === false ? (
        // <div className="reports-container"><HireStatementReport changeState={(e) => changeState(e)} /></div>
        <Modal
          title="Hire Statement"
          open={!showDiv}
          width="80%"
          onCancel={() => changeState(true)}
          style={{ top: "10px" }}
          bodyStyle={{ maxHeight: 790, overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          <ConfirmStatement
            vesselID={tciID.vessel_id}
            chartrerID={tciID.chartrer_id}
            tciID={tciID.tci_id}
            tcID={tciID.tc_id}
            from={tciID.delivery_date}
            to={tciID.redelivery_date}
          />
        </Modal>
      ) : undefined}
    </>
  );
});

export default HireStatement;
