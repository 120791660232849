import React from "react";
import NormalFormIndex from "../../../shared/NormalForm/normal_from_index";
import URL_WITH_VERSION, {
  getAPICall,
  postAPICall,
  openNotificationWithIcon,
  useStateCallback,
} from "../../../shared";
import { Modal } from "antd";
import CommissionPayment from "../CommissionPayment/TcoCommisionpayment";
import { EyeOutlined } from "@ant-design/icons";

const CommissionSummary = (props) => {
  const [state, setState] = useStateCallback({
    responseData: { frm: [], tabs: [], active_tab: {} },
    frmKey: "frm_key_" + props.frmCode,
    isVisiblePayment: false,
    frmName: "tco_commission_summary",
    formData: props.formData || {},
    commissionData: props.commissionData || {},
    frmOptions: props.frmOptions || [],
    isEdit: false,
    editData: null,
    frmOption: null,
    oldFromData: props.oldFromData || {},
  });

  const saveOpenPayment = async (e) => {
    const { commissionData } = state;
    if (e === "new_payment") {
      const makeCommissionEntry = {
        id: 0,
        vessel_id: state.commissionData.vessel_id,
        tc_code: state.formData.tc_code,
      };
      const response1 = await getAPICall(
        `${URL_WITH_VERSION}/accounts/tco-borker/commission?t=tco&e=${state.formData.tco_id}`
      );
      const respSData = await response1["data"];
      makeCommissionEntry["tc_code"] = state.formData.tc_code;
      if (!makeCommissionEntry.tco_id) {
        makeCommissionEntry["tco_id"] = state.formData.tco_id;
      }
      const frmOptions = [
        { key: "broker", data: respSData["brokers"] },
        //{ key: 'remmitance_bank', data: respSData['banks'] },
      ];
      setState((prevState) => ({
        ...prevState,
        commissionData: {
          ...commissionData,
          mycompny_id: respSData["mycompny_id"],
        },
      }));

      setState((prevState) => ({
        ...prevState,
        isVisiblePayment: true,
        isEdit: false,
        editData: null,
        commissionData: makeCommissionEntry,
        frmOption: frmOptions,
      }));
    }
  };

  const onCancelPayment = (e) => {
    setState((prevState) => ({ ...prevState, isVisiblePayment: false }));
  };

  const saveFormData = (data) => {
    const { frmName } = state;
    let suURL = `${URL_WITH_VERSION}/commission/save?frm=${frmName}`;
    let suMethod = "POST";

    postAPICall(suURL, data, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message);
        onCancelPayment();
      } else {
        openNotificationWithIcon("error", data.message);
      }
    });
  };

  const editCommisionData = async (invoice_no) => {
    try {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/commission/tco-edit?e=${invoice_no}`
      );
      const respData = await response["data"];

      const response1 = await getAPICall(
        `${URL_WITH_VERSION}/accounts/tco-borker/commission?t=tco&e=${respData.tco_id}`
      );
      const respSData = await response1["data"];
      respData["mycompny_id"] = respSData["mycompny_id"];

      const frmOptions = [
        { key: "broker", data: respSData["brokers"] },
        { key: "remmitance_bank", data: respSData["banks"] },
      ];
      // let data1 = {
      //   'broker': respData.inv_type,
      //   'tci_id': respData.tci_id
      //  }
      //  let suURL = `${URL_WITH_VERSION}/accounts/borker/commission`;
      //  let suMethod = 'POST';
      //  postAPICall(suURL, data1, suMethod, data => {
      //   if (data && data.data) {
      //     Object.assign(respData, data.data)
      //   }});
      //   setTimeout(() => {
      //     setState({ isEdit: true, editData: respData, isVisiblePayment:true ,frmOption : frmOptions})
      //   }, 2000, this);
      Object.assign(respData, { disablefield: ["broker"] });
      setState((prevState) => ({
        ...prevState,
        editData: respData,
        isVisiblePayment: true,
        frmOption: frmOptions,
        isEdit: true,
      }));
    } catch (err) {
      openNotificationWithIcon("info", "No Data Found", 5);
    }
  };

  const editCommision = async (action, data) => {
    data &&
      data["-"].map((e, index) => {
        if (action.index === index) {
          editCommisionData(e.invoice_no);
        }
        return true;
      });
  };

  const {
    frmName,
    formData,
    commissionData,
    frmOptions,
    isEdit,
    editData,
    isVisiblePayment,
    frmOption,
    oldFromData,
  } = state;

  return (
    <div className="body-wrapper">
      {props.frmCode ? (
        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <NormalFormIndex
                key={"key_" + frmName + "_0"}
                formClass="label-min-height"
                frmCode={props.frmCode}
                formData={formData}
                tableFormAddNewRow={true}
                showForm={true}
                addForm={true}
                showButtons={[]}
                showToolbar={[
                  {
                    isLeftBtn: [
                      {
                        key: "s1",
                        isSets: [
                          {
                            id: "1",
                            key: "new_payment",
                            type: "solution",
                            withText: "New Payment",
                            event: (key, data) => {
                              saveOpenPayment(key);
                            },
                          },
                        ],
                      },
                    ],
                    isRightBtn: [],
                    isResetOption: false,
                  },
                ]}
                extraTableButton={{
                  "-": [
                    {
                      icon: <EyeOutlined />,
                      onClickAction: (action, data) =>
                        editCommision(action, data),
                    },
                  ],
                }}
                inlineLayout={true}
                frmOptions={frmOptions}
              />
            </div>
          </div>
        </article>
      ) : undefined}
      {isVisiblePayment && (
        <Modal
          title="Time Charter Commission Payment"
          open={isVisiblePayment}
          width="80%"
          style={{ top: "2%" }}
          onCancel={onCancelPayment}
          footer={null}
        >
          {frmName ? (
            <CommissionPayment
              isEdit={isEdit}
              oldFromData={oldFromData}
              invoice_no={state?.editData?.invoice_no}
              frmOptions={frmOption}
              //commissionData={isEdit === true ? editData : commissionData}
              commissionData={editData}
              closeModal={() =>
                setState({ ...state, isVisiblePayment: !isVisiblePayment })
              }
            />
          ) : undefined}
        </Modal>
      )}
    </div>
  );
};

export default CommissionSummary;
