import React, { useState, useEffect } from "react";
import { Menu, Layout } from "antd";
import RightBARCONFIGURATION from "../../constants/rightbar-configuration";
import { useNavigate } from "react-router-dom";
import Tour from "reactour";

const { Sider } = Layout;

const RightBarUI = ({
  pageTitle,
  callback,
  showBunkerAlert,
  setShowBunkerAlert = () => {},
}) => {
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState(["1"]);
  const [open, setopen] = useState(false);
  const [rigthbarConfigurationArray, setRightbarConfigurationArray] =
    useState(null);

  useEffect(() => {
    setopen(true);
  }, []);

  useEffect(() => {
    if (pageTitle) {
      setSelectedKeys(["1"]);
      setRightbarConfigurationArray(
        RightBARCONFIGURATION && RightBARCONFIGURATION[pageTitle]
      );
    }
  }, [pageTitle]);

  const onClickButton = (key, options) => {
    if (key === "home") {
      navigate("/chartering-dashboard");
      return;
    }
    if (callback) callback(key, options);
  };

  return (
    <div className="roushan">
      <Sider trigger={null} collapsible collapsed={true} onCollapse={true}>
        <Menu
          theme="dark"
          defaultSelectedKeys={selectedKeys}
          mode="inline"
          className="rigtsidebar-menu "
        >
          {rigthbarConfigurationArray
            ? rigthbarConfigurationArray.menuoption.map((menu, i) => (
                <Menu.Item
                  key={i}
                  id={menu.id}
                  onClick={() => onClickButton(menu.key, menu)}
                  onMouseEnter={() => {
                    if (menu?.key === "bunker") {
                      setShowBunkerAlert(false);
                    }
                  }}
                >
                  {menu?.key === "bunker" && showBunkerAlert && (
                    <div
                      style={{
                        position: "absolute",
                        right: "40px",
                        top: "10px",
                        backgroundColor: "black",
                        padding: "2px 8px",
                        borderRadius: "5px",
                      }}
                    >
                      Check here for Bunker Price.
                    </div>
                  )}
                  {menu.icon}
                  <span>{menu.title}</span>
                </Menu.Item>
              ))
            : null}
        </Menu>
      </Sider>
    </div>
  );
};

export default RightBarUI;
