import React from "react";
import { Form, Button, Select, Table, Popconfirm } from "antd";
import URL_WITH_VERSION, {
  getAPICall,
  postAPICall,
  apiDeleteCall,
  openNotificationWithIcon,
} from "../../shared/index";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class PortServicedForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      countrySelected: undefined,
      ports: [],
      selectedPorts: [],

      selectedkey: [],
      tableData: [],
      oldFrmData: this.props.oldFrmData || {},
    };
   
  }

  getCountries = async () => {
    let _url = `${URL_WITH_VERSION}/master/list?t=couli&l=0&c=${this.state.oldFrmData.country}`;
    const response = await getAPICall(_url);
    const data = await response["data"];
    this.setState(
      (prev) => ({
        ...prev,
        countries: data,
        countrySelected: data[0].country_name,
      }),
      () =>
        this.setState((prev) => ({
          ...prev,
          countries: data,
          countrySelected: data[0].country_name,
        }))
    );

    // this.setState((prev) => ({
    //   ...prev,
    //   countrySelected: data[0].country_name,
    // }));
    this.handleCountryChange(this.state.oldFrmData.country);
  };

  componentDidMount = async () => {
    let tData = [];
    this.getCountries();

    const request = await getAPICall(
      `${URL_WITH_VERSION}/address/pdmsedit?e=${this.state.oldFrmData["id"]}`
    );
    const response = await request["data"];

    if (response && response.length > 0) {
      tData = response;
    }

    this.setState({ ...this.state, tableData: tData });
  };

  handleCountryChange = async (value) => {
    //this.setState({ countrySelected: value }, async () => {
    let _url = `${URL_WITH_VERSION}/port/country-port/${value}`;
    const response = await getAPICall(_url);
    const data = await response["data"];

    this.setState({ ports: data });
    //});
  };

  onEditRecord = (record) => {
    const { tableData } = this.state;

    let items = tableData.filter((e, i) => i === record.key);

    this.handleCountryChange(items[0].countryID);
    this.setState({
      ...this.state,
      selectedPorts: record.ports.split(","),
      countrySelected: items[0].countryID,
    });
  };

  onDeleteRecord = (record) => {
    const { tableData } = this.state;

    try {
      let items = tableData.filter((e, i) => i === record.key);

      if (items[0]["id"]) {
        apiDeleteCall(
          `${URL_WITH_VERSION}/address/pdms/delete`,
          { id: items[0]["id"] },
          (resp) => {
            if (resp["data"] === true) {
              openNotificationWithIcon("success", resp.message, 5);
              let tData = tableData.filter((e, i) => i !== record.key);
              this.setState({ ...this.state, tableData: tData });
            } else {
              let dataMessage = resp.message;
              let msg = "<div className='row'>";
              if (typeof dataMessage !== "string") {
                Object.keys(dataMessage).map(
                  (i) =>
                    (msg +=
                      "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
                );
              } else {
                msg += dataMessage;
              }
              msg += "</div>";
              openNotificationWithIcon(
                "error",
                <div dangerouslySetInnerHTML={{ __html: msg }} />,
                5
              );
            }
          }
        );
      } else {
        let tData = tableData.filter((e, i) => i !== record.key);
        this.setState({ ...this.state, tableData: tData });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
      openNotificationWithIcon("error", "An error occurred while deleting.", 5);
    }
  };

  handleSubmit = () => {
    // e.preventDefault();
    const { countries, ports, countrySelected, selectedPorts, tableData } =
      this.state;
    let portID = [],
      countryNames = [],
      tData = [],
      findIndex = -1,
      selectedPid = [];
    let cName = countries.filter((e) => countrySelected === e.id);
    let pIDs = ports.filter((e) => {
      if (selectedPorts.indexOf(e.name) >= 0) {
        let v = selectedPorts.filter(
          (e1) => e1.toLowerCase() === e.name.toLowerCase()
        );
        return v && v.length > 0;
      }
      return true;
    });

    for (var a in selectedPorts) {
      let amarid = ports.filter((e) => {
        if (selectedPorts[a].indexOf(e.name) >= 0) {
          return e.id;
        }
        //return true;
      });
      amarid.map((e) => selectedPid.push(e.id));
    }

    pIDs.map((e) => portID.push(e.id));
    cName.map((e) => countryNames.push(e.country_name));

    tableData.map((e, i) => {
      tData.push(e);
      if (e.countryID === countrySelected && findIndex === -1) {
        findIndex = i;
      }
      return true;
    });

    if (findIndex < 0) {
      tData.push({
        key: countrySelected,
        countryID: countrySelected,
        portIDs: portID,
        selectedPid: selectedPid,
        country: countryNames.join(),
        ports: selectedPorts.join(),
      });
    } else if (findIndex >= 0) {
      tData[findIndex] = {
        key: countrySelected,
        countryID: countrySelected,
        portIDs: portID,
        selectedPid: selectedPid,
        country: countryNames.join(),
        ports: selectedPorts.join(),
        id: tData[findIndex].id || undefined,
      };
    }

    this.setState({ ...this.state, tableData: tData, isDataChanged: true });
  };

  handlePortChange = (value, key) => {
    this.setState({
      selectedPorts: [...value],
    });
  };

  saveData = () => {
    const { tableData, oldFrmData, selectedPorts, ports, isDataChanged } =
      this.state;

    if (!isDataChanged) {
      openNotificationWithIcon("info", " Add to list first than save.", 5);
      return;
    }

    let data = [];
    let suURL = `${URL_WITH_VERSION}/address/pdmssave`;
    let suMethod = "POST";

    tableData.forEach((e) =>
      data.push({
        id: e.id || undefined,
        country_id: this.state.oldFrmData.country,
        port_id: e.selectedPid,
        address_id: oldFrmData["id"],
      })
    );

    postAPICall(suURL, data, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message, 5);
        if (
          this.props.hasOwnProperty("modalCloseEvent") &&
          typeof this.props.modalCloseEvent === "function"
        ) {
          this.props.modalCloseEvent(data);
        }
        this.setState({ isDataChanged: false });
      } else {
        let dataMessage = data.message;
        let msg = "<div className='row'>";
        if (typeof dataMessage !== "string") {
          Object.keys(dataMessage).map(
            (i) =>
              (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
          );
        } else {
          msg += dataMessage;
        }
        msg += "</div>";
        openNotificationWithIcon(
          "info",
          <div dangerouslySetInnerHTML={{ __html: msg }} />,
          5
        );
      }
    });
  };

  render() {
    const { countrySelected, selectedPorts, tableData } = this.state;

    const children = [],
      port = [],
      data = [];

    //this.state.countries.map((each, index) => children.push(<Option value={each.id} key={index}>{each.country_name}</Option>));
    this.state.ports.map((each, index) =>
      port.push(
        <Option value={each.name} key={each.id}>
          {each.name}
        </Option>
      )
    );

    const columns = [
      // {
      //   title: 'Selected Country',
      //   dataIndex: 'country',
      //   key: 'country'
      // },
      {
        title: "Selected Ports",
        dataIndex: "ports",
        key: "ports",
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => {
          return (
            <div className="editable-row-operations">
              <span
                className="iconWrapper"
                onClick={() => this.onEditRecord(record)}
              >
                <EditOutlined />
              </span>
              <span className="iconWrapper cancel">
                <Popconfirm
                  title="Are you sure, you want to delete agent on this country?"
                  onConfirm={() => this.onDeleteRecord(record)}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </span>
            </div>
          );
        },
      },
    ];

    this.state.tableData.map((each, index) => {
      data.push({ key: index, country: each.country, ports: each.ports });
      return data;
    });

    return (
      <Form onFinish={this.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <Form.Item {...formItemLayout} label="Country">
              <Select
                value={countrySelected}
                defaultValue={countrySelected}
                disabled
                style={{ width: "100%" }}
                placeholder="Please Select Country"
                name="country"
              >
                {children}
              </Select>
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item {...formItemLayout} label="Port">
              <Select
                value={selectedPorts}
                defaultValue={selectedPorts}
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Please Select Port"
                name="port"
                onChange={this.handlePortChange}
              >
                {port}
              </Select>
            </Form.Item>
          </div>
        </div>

        <Table
          bordered
          scroll={{ x: "max-content" }}
          className="mt-3"
          columns={columns}
          dataSource={data}
          rowClassName={(r, i) =>
            i % 2 === 0
              ? "table-striped-listing"
              : "dull-color table-striped-listing"
          }
        />

        <Form.Item className="mt-3">
          <Button
            type="primary"
            htmlType="submit"
            disabled={selectedPorts.length === 0}
          >
            Add to List
          </Button>
          <Button
            style={{ marginLeft: "15px" }}
            type="primary"
            disabled={selectedPorts.length === 0}
            onClick={this.saveData}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default PortServicedForm;
