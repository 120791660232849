import MailLayout from "./MailLayout";

const SentList = () => {
    return(
        <>
        <MailLayout sent={true} />
        </>
    )
}

export default SentList;