import React, { useRef, forwardRef, useState, useEffect } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { Modal, Spin } from "antd";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Email from "../../components/Email";

const ComponentToPrint = forwardRef((props, ref) => {
  const [formReportdata, setFormReportdata] = useState(
    Object.assign({}, props.data || {})
  );

  return (
    <article className="article toolbaruiWrapper" ref={ref}>
      <div className="box box-default" id="divToPrint">
        <div className="box-body">
          <div className="invoice-inner-download mt-3">
            <div className="row">
              <div className="col-12 text-center">
                <img
                  className="title reportlogo"
                  src={formReportdata.logo}
                  alt="No img"
                  crossOrigin="anonymous"
                />
                <p className="sub-title m-0">
                  {formReportdata.company_name || "N/A"}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="text-center invoice-top-address">
                <p>{formReportdata.address || "N/A"}</p>
              </div>
            </div>
          </div>

          <div className="row p10">
            <div className="col-md-12 tc-table">
              <table className="table custom-table-bordered">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Cargo Contract ID :</td>
                    <td>{formReportdata.cargo_contract_id || "N/A"}</td>

                    <td className="font-weight-bold">Vessel Type :</td>
                    <td className="">{formReportdata.vessel_type || "N/A"}</td>

                    <td className="font-weight-bold">Contract Type :</td>
                    <td className="">
                      {formReportdata.contract_type || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Cargo (COA) ID :</td>
                    <td>
                      {formReportdata.cargo_coa
                        ? formReportdata.cargo_coa
                        : "N/A"}
                    </td>

                    <td className="font-weight-bold">My Company/LOB :</td>
                    <td className="">
                      {formReportdata.my_company || "N/A"} /{" "}
                      {formReportdata.lob_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">Vessel Sub Type:</td>
                    <td className="">
                      {formReportdata.vessel_sub_type ?? "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">VC-Purchase ID :</td>
                    <td className="">
                      {formReportdata.vc_purchase_id
                        ? formReportdata.vc_purchase_id
                        : "N/A"}
                    </td>

                    <td className="font-weight-bold">Invoice By :</td>
                    <td className="">{formReportdata.invoice_by || "N/A"}</td>

                    <td className="font-weight-bold">Trade Area :</td>
                    <td className="">
                      {formReportdata.trade_area_name || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Cargo Name :</td>

                    <td className="">{formReportdata.cargo_name || "N/A"}</td>

                    <td className="font-weight-bold">Min Inv. Qty/Unit :</td>
                    <td className="">
                      {formReportdata.min_inv_qty || "0.00"} /{" "}
                      {formReportdata.min_inv_unit || "0.00"}
                    </td>

                    <td className="font-weight-bold">Voyage No. :</td>
                    <td>
                      {formReportdata.voyage ? formReportdata.voyage : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Cargo group :</td>
                    <td className="">{formReportdata.cargo_group || "N/A"}</td>

                    <td className="font-weight-bold">BL Qty/Unit :</td>
                    <td className="">
                      {formReportdata.bl_qty || "N/A"} /{" "}
                      {formReportdata.bl_unit_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">SF(M3/MT, Ft3/MT) :</td>
                    <td className="">
                      {formReportdata.m3_mt || "N/A"} /{" "}
                      {formReportdata.ft3_mt || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Charterer :</td>
                    <td className="">
                      {formReportdata.charterer_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">Currency :</td>
                    <td className="">
                      {formReportdata.currency_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">Load/Disch Term :</td>
                    <td className="">
                      {formReportdata.ld_load_disch_term_ || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">CP Qty/Unit :</td>
                    <td className="">
                      {formReportdata.cp_qty
                        ? parseFloat(formReportdata.cp_qty).toFixed(2)
                        : "0.00"}{" "}
                      / {formReportdata.cp_unit || "N/A"}
                    </td>

                    <td className="font-weight-bold">Freight Bill Via :</td>
                    <td className="">
                      {formReportdata.freight_bill_via || "0.00"}
                    </td>

                    <td className="font-weight-bold">Load/Disch Currency :</td>
                    <td className="">
                      {formReportdata.load_disch_currency_name || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Min/Max Qty :</td>
                    <td className="">
                      {formReportdata.min_qty
                        ? parseFloat(formReportdata.min_qty).toFixed(2)
                        : "N/A"}{" "}
                      /{" "}
                      {formReportdata.max_qty
                        ? parseFloat(formReportdata.max_qty).toFixed(2)
                        : "N/A"}
                    </td>

                    <td className="font-weight-bold">Freight Type :</td>
                    <td className="">
                      {formReportdata.freight_type_name || "N/A"}{" "}
                    </td>

                    <td className="font-weight-bold">Load Dem/Dep :</td>
                    <td className="">
                      {formReportdata.load_dem || "N/A"} /
                      {formReportdata.load_dep || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">CP Date/Place :</td>
                    <td className="">
                      {formReportdata.cp_date || "N/A"} /
                      {formReportdata.cp_place || "N/A"}
                    </td>

                    <td className="font-weight-bold">Freight Rate :</td>
                    <td className="">
                      {formReportdata.freight_rate || "0.00"}
                    </td>

                    <td className="font-weight-bold">Discharge Dem/Dep :</td>
                    <td className="">
                      {formReportdata.discharge_dem || "N/A"} /{" "}
                      {formReportdata.discharge_dep || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Laycan From/To :</td>
                    <td className="">
                      {formReportdata.laycan_from || "N/A"} /{" "}
                      {formReportdata.laycan_to || "N/A"}
                    </td>

                    <td className="font-weight-bold">Nominated Vessel :</td>
                    <td className="">
                      {formReportdata.nomiated_vessel_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">Fixed By :</td>
                    <td>
                      {formReportdata.fixed_by
                        ? formReportdata.fixed_by
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Option %. :</td>
                    <td className="">{formReportdata.booking_no || "0.00"}</td>

                    <td className="font-weight-bold">CP Draft (m) :</td>
                    <td className="">{formReportdata.cp_draft_m || "N/A"}</td>

                    <td className="font-weight-bold">Status :</td>
                    <td className="">
                      {formReportdata.cargo_status_name || "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table custom-table-bordered">
                <tbody>
                  <td className="border-0">
                    <p className="TopHeading font-weight-bold">Load Options</p>
                    <table className="table custom-table-bordered tc-table">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th>Port/Area</th>
                          <th>L/D Rates</th>
                          <th>L/H Rates</th>
                          <th>Terms</th>
                          <th>TT</th>
                          <th>PExp</th>
                          <th>Draft</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.loadoptions &&
                        formReportdata.loadoptions.length > 0
                          ? formReportdata.loadoptions.map((e, idx) => {
                              return (
                                <>
                                  <tr
                                    key={idx}
                                    style={{ border: "1px solid black" }}
                                  >
                                    <td>{e.port_area_name || "N/A"}</td>
                                    <td>
                                      {e.l_d_rates
                                        ? parseFloat(e.l_d_rates).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>
                                      {e.l_h_rates
                                        ? parseFloat(e.l_h_rates).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>{e.ld_terms_name || "N/A"}</td>
                                    <td>{e.ld_tt || "0.00"}</td>
                                    <td>{e.pexp || "0.00"}</td>
                                    <td>{e.draft || "0.00"}</td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                  </td>

                  <td className="border-0">
                    <p className="TopHeading  font-weight-bold">
                      Discharge Options
                    </p>
                    <table className="table custom-table-bordered tc-table">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th>Port/Area</th>
                          <th>L/D Rates</th>
                          <th>L/H Rates</th>
                          <th>Terms</th>
                          <th>TT</th>
                          <th>PExp</th>
                          <th>Draft</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.dischargeoptions &&
                        formReportdata.dischargeoptions.length > 0
                          ? formReportdata.dischargeoptions.map((e, idx) => {
                              return (
                                <>
                                  <tr
                                    key={idx}
                                    style={{ border: "1px solid black" }}
                                  >
                                    <td>{e.port_area_name || "N/A"}</td>
                                    <td>
                                      {e.l_d_rates
                                        ? parseFloat(e.l_d_rates).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>
                                      {e.l_h_rates
                                        ? parseFloat(e.l_h_rates).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>{e.ld_terms_name || "N/A"}</td>
                                    <td>{e.ld_tt || "0.00"}</td>
                                    <td>{e.pexp || "0.00"}</td>
                                    <td>{e.draft || "0.00"}</td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                  </td>
                </tbody>
              </table>

              <p className="TopHeading font-weight-bold">Cargo Options</p>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>F</th>
                    <th>Port</th>
                    <th>Cargo group</th>
                    <th>CP Qty</th>
                    <th>Unit</th>
                    <th>L/D Rate(Days)</th>
                    <th>L/H Rate(Hours)</th>
                    <th>Terms</th>
                    <th>TT</th>
                    <th>PD</th>
                    <th>EXPD</th>
                    <th>Port Exp</th>
                    <th>Other Exp</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.itineraryoptions &&
                  formReportdata.itineraryoptions.length > 0
                    ? formReportdata.itineraryoptions.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.io_f_name || "N/A"}</td>
                              <td>{e.port_id_name || "N/A"}</td>
                              <td>{e.cargo_name || "N/A"}</td>
                              <td>
                                {e.io_qty
                                  ? parseFloat(
                                      e.io_qty.replaceAll(",", "")
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                              <td>{e.unit_name || "N/A"}</td>
                              <td>
                                {e.l_d_rate
                                  ? parseFloat(
                                      e.l_d_rate.replaceAll(",", "")
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                              <td>{e.io_ru || "N/A"}</td>
                              <td>{e.io_terms_name || "N/A"}</td>
                              <td>{e.io_tt || "0.00"}</td>
                              <td>{e.io_pd || "0.00"}</td>
                              <td>{e.io_xp || "0.00"}</td>
                              <td>
                                {e.port_exp
                                  ? parseFloat(
                                      e.port_exp.replaceAll(",", "")
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                              <td>
                                {e.o_exp
                                  ? parseFloat(
                                      e.o_exp.replaceAll(",", "")
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">
                Cargo Pricing & Billing
              </p>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Load Port(S)</th>
                    <th>Discharge Port(S)</th>
                    <th>Cargo</th>
                    <th>Frt Type</th>
                    <th>Amount</th>
                    <th>Table</th>
                    <th>Basis</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["--"] &&
                  formReportdata["--"] &&
                  formReportdata["--"].length > 0
                    ? formReportdata["--"].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.load_ports_name || "N/A"}</td>
                              <td>{e.discharge_ports_name || "N/A"}</td>
                              <td>{e.cargo_name || "N/A"}</td>
                              <td>{e.frt_type_name || "N/A"}</td>
                              <td>
                                {e.amount
                                  ? parseFloat(
                                      e.amount.replaceAll(",", "")
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                              <td>{e.table || "N/A"}</td>
                              <td>{e.basis || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <td className="border-0">
                    <p className="TopHeading  font-weight-bold">Broker</p>
                    <table className="table custom-table-bordered tc-table">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th>Broker</th>
                          <th>Amount</th>
                          <th>Rate Type</th>
                          <th>Pay Method</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.broker &&
                        formReportdata.broker.length > 0
                          ? formReportdata.broker.map((e, idx) => {
                              return (
                                <>
                                  <tr key={idx}>
                                    <td>{e.broker_name || "N/A"}</td>
                                    <td>
                                      {e.amount
                                        ? parseFloat(
                                            e.amount.replaceAll(",", "")
                                          ).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>{e.rate_type_name || "N/A"}</td>
                                    <td>{e.pay_method_name || "N/A"}</td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                  </td>

                  <td className="border-0">
                    <p className="TopHeading  font-weight-bold">
                      Extra Freight Term
                    </p>
                    <table className="table custom-table-bordered tc-table">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th>Code</th>
                          <th>Extra Freight Term</th>
                          <th>Rate/Lump</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.extrafreightterm &&
                        formReportdata.extrafreightterm.length > 0
                          ? formReportdata.extrafreightterm.map((e, idx) => {
                              return (
                                <>
                                  <tr key={idx}>
                                    <td>{e.code || "N/A"}</td>
                                    <td>
                                      {e.extra_freight_term_name || "N/A"}
                                    </td>
                                    <td>
                                      {e.rate_lump
                                        ? parseFloat(
                                            e.rate_lump.replaceAll(",", "")
                                          ).toFixed(2)
                                        : "N/A"}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                  </td>
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">
                Billing and Banking details
              </p>
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Remit Bank :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {formReportdata.billingandbankingdetails
                          .remittance_bank_name || "N/A"}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">Account No :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {formReportdata.billingandbankingdetails.account_no ||
                          "N/A"}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">1st Invoice(%) :</td>
                    {formReportdata.billingandbankingdetails &&
                    formReportdata.billingandbankingdetails.inv_per ? (
                      <td className="">
                        {parseFloat(
                          formReportdata.billingandbankingdetails.inv_per.replaceAll(
                            ",",
                            ""
                          )
                        ).toFixed(2)}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Balance(%) :</td>
                    {formReportdata.billingandbankingdetails &&
                    formReportdata.billingandbankingdetails.bal_per ? (
                      <td className="">
                        {parseFloat(
                          formReportdata.billingandbankingdetails.bal_per.replaceAll(
                            ",",
                            ""
                          )
                        ).toFixed(2)}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Payment Term :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {
                          formReportdata.billingandbankingdetails
                            .payment_term_name
                        }
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Due Date :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {formReportdata.billingandbankingdetails.due_date}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Billing Days :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {formReportdata.billingandbankingdetails.billing_days ||
                          "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Freight Surcharge :</td>
                    {formReportdata.billingandbankingdetails &&
                    formReportdata.billingandbankingdetails
                      .freight_surcharge ? (
                      <td className="">
                        {parseFloat(
                          formReportdata.billingandbankingdetails.freight_surcharge.replaceAll(
                            ",",
                            ""
                          )
                        ).toFixed(2)}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Billing Basis :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {formReportdata.billingandbankingdetails
                          .billing_basis_name || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">
                Supplier/Receiver Info
              </p>
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Final Destination :</td>
                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].final_destination || "N/A"}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">Supplier :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].supplier_name || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Receiver :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].reciever_name || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Ref Company :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].ref_company_name || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">VAT No / Tax % :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].vat_no} /{" "}
                        {parseFloat(formReportdata["-------"].vat_per).toFixed(
                          2
                        )}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">OBL No. :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].obl_no || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Ref contact :</td>
                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].ref_contract || "N/A"}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">Commingle Code :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].commingle_code || "N/A"}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">Trader :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].trader_name || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">
                Estimate Rev/Exp Info
              </p>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Description Type</th>
                    <th>Rev/Exp</th>
                    <th>Type Of Rev/Exp</th>
                    <th>Price</th>
                    <th>Currency</th>
                    <th>Total Amount($)</th>
                    <th>Final Amount</th>
                    <th>Account Code</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.revexpinfo &&
                  formReportdata.revexpinfo &&
                  formReportdata.revexpinfo.length > 0
                    ? formReportdata.revexpinfo.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.description || "N/A"}</td>
                              <td>{e.rev_exp_name || "N/A"}</td>
                              <td>{e.re_type_name || "N/A"}</td>
                              <td>{e.re_amt_curr || "N/A"}</td>
                              <td>{e.re_currency_name || "N/A"}</td>
                              <td>
                                {e.exchange_rate
                                  ? parseFloat(
                                      e.exchange_rate.replaceAll(",", "")
                                    ).toFixed(2)
                                  : "N/A"}
                              </td>
                              <td>
                                {e.amount_base
                                  ? parseFloat(
                                      e.amount_base.replaceAll(",", "")
                                    ).toFixed(2)
                                  : "N/A"}
                              </td>
                              <td>{e.code || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">Rebill</p>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>F</th>
                    <th>Port</th>
                    <th>Ledger Code</th>
                    <th>Ledger Code Description</th>
                    <th>Cost Details Description</th>
                    <th>Rebillable</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.rebillsettings &&
                  formReportdata.rebillsettings.length > 0
                    ? formReportdata.rebillsettings.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.cv_f_name || "N/A"}</td>
                              <td>{e.cv_port_name || "N/A"}</td>
                              <td>{e.ledger || "N/A"}</td>
                              <td>{e.ledger_code_description || "N/A"}</td>
                              <td>{e.cost_detail_description || "N/A"}</td>
                              <td>{e["rebillable"] === 1 ? "Yes" : "No"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">Dem/Des Term</p>
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Load Dem/Des :</td>

                    {formReportdata["...."] && formReportdata["...."] ? (
                      <td className="">
                        {formReportdata["...."].load_dem} /{" "}
                        {formReportdata["...."].load_des}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Total Time Bar :</td>
                    {formReportdata["....."] && formReportdata["....."] ? (
                      <td className="">
                        {formReportdata["....."].total_time_bar || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Per Day/Per hour :</td>

                    {formReportdata["...."] && formReportdata["...."] ? (
                      <td className="">
                        {formReportdata["...."].per_day === 1 ? "Yes" : "No"} /{" "}
                        {formReportdata["...."].per_hour === 1 ? "Yes" : "No"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">TT Hours :</td>
                    {formReportdata["....."] && formReportdata["....."] ? (
                      <td className="">
                        {formReportdata["....."].tt_hours} /{" "}
                        {formReportdata["....."].tt_hours}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Disc Dem/Des :</td>

                    {formReportdata["...."] && formReportdata["...."] ? (
                      <td className="">
                        {formReportdata["...."].disch_dem} /{" "}
                        {formReportdata["...."].disch_des}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Freight Surcharge :</td>
                    {formReportdata["....."] && formReportdata["....."] ? (
                      <td className="">
                        {formReportdata["....."].freight_surcharge || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Per Day/Per hour :</td>

                    {formReportdata["...."] && formReportdata["...."] ? (
                      <td className="">
                        {formReportdata["...."].d_per_day === 1 ? "Yes" : "No"}{" "}
                        /{" "}
                        {formReportdata["...."].d_per_hour === 1 ? "Yes" : "No"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Bunker Surcharge :</td>
                    {formReportdata["....."] && formReportdata["....."] ? (
                      <td className="">
                        {formReportdata["....."].bunker_surcharge || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Dem/Des Curr :</td>
                    {formReportdata["...."] && formReportdata["...."] ? (
                      <td className="">
                        {formReportdata["...."].dem_des_curr_name || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Admin Charges :</td>
                    {formReportdata["....."] && formReportdata["....."] ? (
                      <td className="">
                        {formReportdata["....."].admin_charges || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Demurrage :</td>

                    {formReportdata["...."] && formReportdata["...."] ? (
                      <td className="">
                        {formReportdata["...."].demurrage || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Dem/Des Commitable :</td>

                    {formReportdata["....."] && formReportdata["....."] ? (
                      <td className="">
                        {formReportdata["....."]["dem_des_commitable"] === 1
                          ? "Yes"
                          : "No"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Laytime :</td>
                    {formReportdata["...."] && formReportdata["...."] ? (
                      <td className="">
                        {formReportdata["...."].laytime || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Reversible All Port :</td>
                    {formReportdata["....."] && formReportdata["....."] ? (
                      <td className="">
                        {formReportdata["....."]["reversible_all_port"] === 1
                          ? "Yes"
                          : "No"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Laytime Allowed :</td>

                    {formReportdata["...."] && formReportdata["...."] ? (
                      <td className="">
                        {formReportdata["...."].laytime_allowed || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Non Rev. All Port :</td>
                    {formReportdata["....."] && formReportdata["....."] ? (
                      <td className="">
                        {formReportdata["....."]["non_rev_all_port"] === 1
                          ? "Yes"
                          : "No"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Days / Hours :</td>
                    {formReportdata["...."] && formReportdata["...."] ? (
                      <td className="">
                        {formReportdata["...."].days === 1 ? "Yes" : "No"} /{" "}
                        {formReportdata["...."].hours === 1 ? "Yes" : "No"}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">-</td>
                    <td className="">-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
});

const CargoContractReports = (props) => {
  const [pdfData, setPdfData] = useState();
  const [userInput, setUserInput] = useState();
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setname] = useState("Printer");
  const componentRef = useRef();

  const printReceipt = () => {
    window.print();
  };

  useEffect(() => {
    setUserInput(props.data);
  }, []);

  const sendEmailReportModal = async () => {
    try {
      setLoading(true);

      const quotes = document.getElementById("divToPrint");

      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      const blob = doc.output("blob");
      setLoading(false);
      setPdfData(blob);
      setEmailModal(true);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const printDocument = () => {
    var quotes = document.getElementById("divToPrint");

    html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 25;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("Cargo Contract Report.pdf");
    });
  };

  return (
    <div className="body-wrapper modalWrapper">
      <article className="article toolbaruiWrapper">
        <div className="box box-default">
          <div className="box-body">
            <div className="toolbar-ui-wrapper">
              <div className="leftsection"></div>
              <div className="rightsection">
                <span className="wrap-bar-menu">
                  <ul className="wrap-bar-ul">
                    <li onClick={sendEmailReportModal}>Send Email</li>
                    <li onClick={printDocument}>Download</li>
                    <li>
                      <ReactToPrint
                        trigger={() => (
                          <span className="text-bt">
                            <PrinterOutlined /> Print
                          </span>
                        )}
                        content={() => componentRef.current}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            <ComponentToPrint ref={componentRef} data={props.data} />
          </div>
        </div>
      </article>

      {emailModal && (
        <Modal
          title="New Message"
          visible={emailModal}
          onOk={() => {
            setEmailModal(false);
          }}
          onCancel={() => {
            setEmailModal(false);
          }}
          footer={null}
        >
          {pdfData && (
            <Email
              handleClose={() => {
                setEmailModal(false);
              }}
              attachmentFile={pdfData}
              title={window.corrector(
                `Cargo_Contract_report||${userInput.cargo_name}||${userInput.charterer_name}||${userInput.company_name}||${userInput.vessel_owner_name}`
              )}
            />
          )}
        </Modal>
      )}

      {loading && (
        <div
          style={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default CargoContractReports;
