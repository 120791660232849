import React, { Component, useEffect, useRef } from "react";
import { useParams,useLocation} from "react-router-dom";
import { Button, Layout, Drawer, Row, Col, Tooltip, Modal } from "antd";
import NormalFormIndex from "../../../../../shared/NormalForm/normal_from_index";
import URL_WITH_VERSION, {
  postAPICall,
  withLocation,
  // awaitPostAPICall,
  openNotificationWithIcon,
  objectToQueryStringFunc,
  getAPICall,
  apiDeleteCall,
  useStateCallback,
} from "../../../../../shared";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../../../../shared/attachments";
import RightBarUI from "../../../../../components/RightBarUI";
import RightSearch from "../../tcto/right-panel/RightSearch";
import Properties from "../Properties";
import PL from "../../../../../shared/components/PL";
import EstimateSummary from "../../tcto/right-panel/EstimateSummary";
import TcovPortInformation from "../../../../right-port-info/TcovPortInformation";
import AttachmentFile from "../../../../../shared/components/Attachment";
import { CloseOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";

const { Content } = Layout;

const QuickEstimateTCOV = (props) => {
  const [state, setState] = useStateCallback({
    visible: false,
    showArray: [],
    formDataArrays: [],
    showCollapse: [true],
    frmName: "tcov_form",
    isEdit: false,
    formDataValues: props.formData || {},
    visibleDrawer: false,
    title: undefined,
    loadComponent: undefined,
    width: 1200,
    fullToQuick: false,
    refreshForm: false,
    showAddButton: true,
    estimateID: props.estimateID||null,
  });
  const params=useParams();
  const location=useLocation();
  const formDataref = useRef();

  useEffect(() => {
    loadInitialData();
  // onAddMore();
  }, []);

  const loadInitialData = async () => {
    let estimateID = null;
    let respData = undefined,
      response = undefined;
     if(params.id){
      estimateID= params.id;
     }




    if (
      (params?.id && location?.pathname ===`/edit-quick-estimate/:${estimateID}`) ||
      estimateID
    ) {
      let qParams = { ae: params.id || estimateID };
      let qParamString = objectToQueryStringFunc(qParams);
      response = await getAPICall(
        `${URL_WITH_VERSION}/tcov/quick-estimate/edit?${qParamString}`
      );
      respData = await response["data"];
      if (
        respData &&
        respData["cargodetails"] &&
        respData["cargodetails"].hasOwnProperty("f_type") &&
        respData["cargodetails"]["f_type"] === "104"
      ) {
        respData["cargodetails"]["disablefield"] = ["quantity"];
      }
      formDataref.current = respData;
      setState(
        (prevState) => ({
          ...prevState,
          formDataValues: {...respData},
          fullToQuick: false,
          showAddButton: false,
          formDataArrays: [...prevState.formDataArrays, ...[respData]],
          estimateID: params.id,
        }),
        () => onAddMore("0", false, true)
      );
     
    } else {
      onAddMore();
    }
  };

  const onCloseDrawer = () =>
    setState((prevState) => ({
      ...prevState,
      visibleDrawer: false,
      title: undefined,
      loadComponent: undefined,
    }));

  const onClickRightMenu = async (key, options) => {
    onCloseDrawer();
    let loadComponent = undefined;
    switch (key) {
      case "search":
        loadComponent = <RightSearch history={props?.router?.navigate} />;
        break;
      case "properties":
        loadComponent = <Properties />;
        break;
      case "pl":
        loadComponent = <PL />;
        break;
      case "summary":
        loadComponent = <EstimateSummary />;
        break;
      case "portinformation":
        loadComponent = <TcovPortInformation />;
        break;
      case "attachment":
        openNotificationWithIcon("info", "Attachments are not allowed here.");
        // const { estimateID } = this.state;
        // if (estimateID) {
        //   const attachments = await getAttachments(estimateID, "EST");
        //   const callback = (fileArr) =>
        //     uploadAttachment(fileArr, estimateID, "EST", "tcov");
        //   loadComponent = (
        //     <AttachmentFile
        //       uploadType="Estimates"
        //       attachments={attachments}
        //       onCloseUploadFileArray={callback}
        //       deleteAttachment={(file) =>
        //         deleteAttachment(file.url, file.name, "EST", "tcov")
        //       }
        //       tableId={0}
        //     />
        //   );
        // } else {
        //   openNotificationWithIcon("info", "Attachments are not allowed here.");
        // }
        break;
    }

    setState((prevState) => ({
      ...prevState,
      visibleDrawer: true,
      title: options.title,
      loadComponent: loadComponent,
      width: options.width && options.width > 0 ? options.width : 1200,
    }));
  };

  const viewFullEstimate = (index = "") => {
    const { formDataValues, formDataArrays } = state;
    const { navigate } = props.router;
    if (
      formDataArrays &&
      formDataArrays[index] &&
      formDataArrays[index].hasOwnProperty("tcov_id")
    ) {
      navigate(`/edit-voyage-estimate/${formDataArrays[index]["tcov_id"]}`);
    } else if (
      formDataArrays[index] ||
      (formDataValues &&
        formDataValues.hasOwnProperty("id") &&
        formDataValues["id"] > 0 &&
        !formDataValues.hasOwnProperty("tcov_id"))
    ) {
      navigate(
        `/edit-voyage-estimate/${formDataArrays[index]["estimate_id"]}`,
        { state: formDataArrays[index] }
        // state:formDataValues,
      );
    } else {
      openNotificationWithIcon("error", "Quick estimate to be generated first");
    }
  };

 



  const onAddMore = async (index = "", editValue = false, editing = false) => {
    const {
      showArray,
      showCollapse,
      formDataValues,
      fullToQuick,
      rightBtnPanel,
    } = state;

    let _formData = Object.assign({}, {});
    if (editValue || editing == true) {
      _formData = Object.assign({}, formDataValues);
    }

    if (editValue === true && editing === false) {
      showArray[index] = [];
    }

    setState(
      (prevState) => ({
        ...prevState,
        formDataValues: _formData,
        isEdit:
          formDataValues &&
          formDataValues.hasOwnProperty("id") &&
          formDataValues["id"] > 0,
        rightBtnPanel:
          _formData && _formData.hasOwnProperty("id") && _formData["id"] > 0
            ? rightBtnPanel
            : [
                {
                  key: "s2",
                  isSets: [
                    {
                      id: "2",
                      key: "menu",
                      isDropdown: 1,
                      type: "small-dash",
                      withText: "Menu",
                      showButton: true,
                      showToolTip: true,
                      // menus: null,
                      menus: [
                        {
                          id: "21",
                          key: "menu-1",
                          href: null,
                          icon: null,
                          label: "Convert to Full Estimate",
                          modalKey: null,
                        },

                        // {
                        //   id: "22",
                        //   key: "menu-2",
                        //   href: null,
                        //   icon: null,
                        //   label: "Go to Quick TCTO",
                        //   modalKey: null,
                        // },

                        // {
                        //   id: "23",
                        //   key: "menu-3",
                        //   href: null,
                        //   icon: null,
                        //   label: "Go Quick Voy relet",
                        //   modalKey: null,
                        //   event: () => this.viewFullEstimate(index), //this.props.router.navigate('/voy-relet-full-estimate')
                        // },

                        {
                          id: "24",
                          key: "menu-4",
                          href: null,
                          icon: null,
                          label: "Bunker-Live",
                          modalKey: null,
                        },

                        {
                          id: "25",
                          key: "menu-5",
                          href: null,
                          icon: null,
                          label: "Route to Map",
                          modalKey: null,
                        },

                        {
                          id: "26",
                          key: "menu-6",
                          href: null,
                          icon: null,
                          label: "Voyage optimize",
                          modalKey: null,
                        },
                      ],
                    },
                  ],
                },
              ],
      }),

      () => {
        if (editValue === true) {
          showArray[index] =getContent(index, true);
        } else {
          showArray.push(getContent(showArray.length, false));
        }
        showCollapse[showArray.length] = true;
        setState((prevState)=>({
          ...prevState,
          showArray: [...showArray],
          showCollapse,
        }));
      }
    );
  };

  const onLessMore = (index) => {
    const { showArray } = state;
    if (index > 0 && index < showArray.length) {
      showArray[index] = undefined;
      setState({ showArray: [...showArray] });
    }
  };

  const onToggle = (i) => {
    state.showCollapse[i] = !state.showCollapse[i];
    setState(
      (prevState) => ({ ...prevState, showCollapse: state.showCollapse }),
      () => {
        state.showArray[i] = getContent(i);
        setState({ ...state, showArray: state.showArray });
      }
    );
  };

  const onSaveFormData = async (data, index) => {
    let _url = `${URL_WITH_VERSION}/tcov/save?frm=tcov_form`;
    let method = "POST";
    if (data && data.hasOwnProperty("id")) {
      _url = `${URL_WITH_VERSION}/tcov/update?frm=tcov_form`;
      method = "PUT";
    }
    if (data) {
      setState({
        ...state,
        refreshForm: true,
      });
      delete data["vesseldetails"]["vessel_code"];
      data["vesseldetails"]["tci_d_hire"] =
        data["vesseldetails"]["tci_d_hire"] &&
        data["vesseldetails"]["tci_d_hire"] != ""
          ? data["vesseldetails"]["tci_d_hire"]
          : "0.00";
      formDataref.current = { ...data };

      await postAPICall(`${_url}`, data, method, async (response) => {
        if (response && response.data) {
          openNotificationWithIcon("success", response.message);
        } else {
          openNotificationWithIcon("error", response.message);
        }

        if (data && response.data) {
          let estimateID =
            (response && response.row && response.row.estimateID) ||
            data["estimate_id"];
          let qParams = { ae: estimateID };
          let qParamString = objectToQueryStringFunc(qParams);
          let response1 = await getAPICall(
            `${URL_WITH_VERSION}/tcov/quick-estimate/edit?${qParamString}`
          );
          let respData = await response1["data"];

          // if (
          //   respData &&
          //   respData["cargodetails"] &&
          //   respData["cargodetails"].hasOwnProperty("f_type") &&
          //   respData["cargodetails"]["f_type"] === "104"
          // ) {
          //   respData["cargodetails"]["disablefield"] = ["quantity"];
          // }

          formDataref.current = respData;
          setState(
            (prevState) => ({
              ...prevState,
              isEdit: false,
              formDataValues: { ...respData },
              refreshForm: false,
              showAddButton: true,
              // showArray:[]
              formDataArrays: [...state.formDataArrays, ...[respData]],
            }),
            () => onAddMore(index, true)
          );
        } else {
          setState({ ...state, isEdit: false, refreshForm: false });
        }
      });
    }
  };

  const copyQuickEstimate = (
    data,
    index,
    editValue = false,
    editing = false
  ) => {
    const {
      showArray,
      showCollapse,
      formDataValues,
      fullToQuick,
      rightBtnPanel,
    } = state;

    delete data["estimate_id"];
    delete data["id"];
    let _formData = Object.assign({}, data);
    if (editValue || editing == true) {
      _formData = Object.assign({}, formDataValues);
    }

    if (editValue === true && editing === false) {
      showArray[index] = [];
    }

    formDataref.current = _formData;
    setState(
      (prevState) => ({
        ...prevState,
        formDataValues: _formData,
        isEdit:
          formDataValues &&
          formDataValues.hasOwnProperty("id") &&
          formDataValues["id"] > 0,
        rightBtnPanel:
          _formData && _formData.hasOwnProperty("id") && _formData["id"] > 0
            ? rightBtnPanel
            : [
                {
                  key: "s2",
                  isSets: [
                    {
                      id: "2",
                      key: "menu",
                      isDropdown: 1,
                      type: "small-dash",
                      withText: "Menu",
                      showButton: true,
                      showToolTip: true,
                      // menus: null,
                      menus: [
                        {
                          id: "21",
                          key: "menu-1",
                          href: null,
                          icon: null,
                          label: "Convert to Full Estimate",
                          modalKey: null,
                        },
                        {
                          id: "24",
                          key: "menu-4",
                          href: null,
                          icon: null,
                          label: "Bunker-Live",
                          modalKey: null,
                        },

                        {
                          id: "25",
                          key: "menu-5",
                          href: null,
                          icon: null,
                          label: "Route to Map",
                          modalKey: null,
                        },

                        {
                          id: "26",
                          key: "menu-6",
                          href: null,
                          icon: null,
                          label: "Voyage optimized",
                          modalKey: null,
                        },
                      ],
                    },
                  ],
                },
              ],
      }),
      () => {
        if (editValue === true) {
          showArray[index] = getContent(index, true);
        } else {
          showArray.push(getContent(showArray.length, false));
        }
        showCollapse[showArray.length] = true;
        setState({
          ...state,
          showArray: showArray,
          showCollapse,
        });
      }
    );
  };

  const deleteData = (id, tcov_id, innerCB, index) => {
    apiDeleteCall(
      `${URL_WITH_VERSION}/tcov/delete`,
      { id: id, tcov_id: tcov_id },
      (resData) => {
        if (resData.data === true) {
          openNotificationWithIcon("success", resData.message);
          if (index > 0) {
            onLessMore(index);
          } else {
            props?.router?.navigate("/quick-estimate-list");
          }
        } else {
          openNotificationWithIcon("error", resData.message);
        }
      }
    );
  };

  const getContent = (index, edit = false) => {
    const {
      showCollapse,
      frmName,
      gID,
      formDataValues,
      isEdit,
      rightBtnPanel,
      formDataArrays,
    } = state;
    return (
      <>
        {!showCollapse[index] ? (
          <div className="all-sections wrap-small-collapse">
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="toolbar-ui-wrapper">
                  <div className="action-btn">
                    <Tooltip title="Expand">
                      <Button
                        type="dashed"
                        shape="circle"
                        icon="right"
                        size={"default"}
                        onClick={() => onToggle(index)}
                      />
                    </Tooltip>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />

            <div className="body-se">
              <h1 class="main-title main-text">
                {state.formDataValues && state.formDataValues["estimate_id"]
                  ? state.formDataValues["estimate_id"]
                  : "EST-TCOV-0000" + index}
              </h1>
            </div>
          </div>
        ) : (
          <div className="all-sections">
            {state.refreshForm === false ? (
              <NormalFormIndex
                key={"key_" + frmName + index}
                formClass="label-min-height"
                formData={state.ormDataValues}
              //  formData={Object.assign({}, formDataref.current)}
                showForm={true}
                frmCode={frmName}
                addForm={true}
                showButtons={[
                  { id: "cancel", title: "Reset", type: "danger" },
                  {
                    id: "save",
                    title: "Save",
                    type: "primary",
                    event: (data) => {
                      onSaveFormData(data, index);
                    },
                  },
                ]}
                formDevideInCols="1"
                inlineLayout={true}
                showToolbar={[
                  {
                    isLeftBtn: [
                      {
                        key: "s1",
                        isSets: [
                          {
                            id: "1",
                            key: "save",
                            type: <SaveOutlined />,
                            withText: "Save",
                            showButton: true,
                            showToolTip: true,
                            event: (key, data) => {
                              onSaveFormData(data, index);
                            },
                          },

                          {
                            id: "2",
                            key: "delete",
                            type: <DeleteOutlined />,
                            withText: "Delete",
                            showToolTip: true,
                            event: (key, saveData, innerCB) => {
                              if (saveData["id"] && saveData["id"] > 0) {
                                Modal.confirm({
                                  title: "Confirm",
                                  content:
                                    "Are you sure, you want to delete it?",
                                  onOk: () =>
                                    deleteData(
                                      saveData["id"],
                                      saveData["tcov_id"],
                                      innerCB,
                                      index
                                    ),
                                });
                              }
                            },
                          },

                          {
                            id: "3",
                            key: "close",
                            type:  <CloseOutlined />,
                            withText: "Close",
                            showButton: true,
                            showToolTip: true,
                            event: (key) => {
                              onLessMore(index);
                            },
                          },

                          // {
                          //   id: '7',
                          //   key: 'collapse',
                          //   type: 'left',
                          //   withText: 'Collapse',
                          //   showButton: true,
                          //   showToolTip: true,
                          //   event: key => {
                          //     this.onToggle(index);
                          //   },
                          // },
                        ],
                      },
                    ],
                    isRightBtn: [
                      {
                        key: "s2",
                        isSets: [
                          {
                            id: "3",
                            key: "fullest",
                            isDropdown: 0,
                            type: "small-dash",
                            withText: "Full Estimate",
                            showButton: true,
                            showToolTip: true,
                            event: () => viewFullEstimate(index),
                          },

                          {
                            id: "2",
                            key: "menu",
                            isDropdown: 1,
                            type: "small-dash",
                            withText: "Menu",
                            showButton: true,
                            showToolTip: true,
                            menus: [
                              {
                                id: "24",
                                key: "menu-4",
                                href: null,
                                icon: null,
                                label: "Bunker-Live",
                                modalKey: null,
                              },

                              {
                                id: "25",
                                key: "menu-5",
                                href: null,
                                icon: null,
                                label: "Route to Map",
                                modalKey: null,
                              },

                              {
                                id: "26",
                                key: "menu-6",
                                href: null,
                                icon: null,
                                label: "Voyage optimize",
                                modalKey: null,
                              },

                              {
                                id: "27",
                                key: "menu-7",
                                href: null,
                                icon: null,
                                label: "Copy Estimate",
                                modalKey: null,

                                event: (key, data) => {
                                  let data1 = Object.assign({}, data);

                                  data1 &&
                                  data1.hasOwnProperty("id") &&
                                  data1["id"] > 0
                                    ? copyQuickEstimate(data1, index)
                                    : openNotificationWithIcon(
                                        "error",
                                        "Please save Quick Estimate first.",
                                        5
                                      );
                                },
                              },
                            ],
                          },
                          // {
                          //   id: '1',
                          //   key: 'createtcov',
                          //   isDropdown: 0,
                          //   withText: 'Full TCOV',
                          //   type: '',
                          //   menus: null,
                          //   event: (key, data) => this?.props?.router?.navigate(`/edit-voy-est/${data.estimate_id}`),
                          // },
                        ],
                      },
                    ],
                    isResetOption: false,
                  },
                ]}
              />
            ) : undefined}
          </div>
        )}
      </>
    );
  };


  return (
    <div className="tcov-wrapper scroll-borad">
      <Layout className="layout-wrapper">
        <Layout style={{ border: "none" }}>
          <Content className="content-wrapper">
             <div className="wrap-scrumboard-design">
              {state.showArray.map((field,index) => {
                return <> {field} </>;
              })}
            </div> 
          </Content>
        </Layout>
        <RightBarUI
          pageTitle="tcov-quick-righttoolbar"
          callback={(data, options) => onClickRightMenu(data, options)}
        />
        {state.loadComponent !== undefined &&
        state.title !== undefined &&
        state.visibleDrawer === true ? (
          <Drawer
            title={state.title}
            placement="right"
            closable={true}
            onClose={onCloseDrawer}
            open={state.visibleDrawer}
            getContainer={false}
            style={{ position: "absolute" }}
            width={state.width}
            maskClosable={false}
            className="drawer-wrapper-container"
          >
            <div className="tcov-wrapper">
              <div className="layout-wrapper scrollHeight">
                <div className="content-wrapper noHeight">
                  {state.loadComponent}
                </div>
              </div>
            </div>
          </Drawer>
        ) : undefined}
      </Layout>
    </div>
  );
};

export default QuickEstimateTCOV;
