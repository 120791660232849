import MailLayout from "./MailLayout";

const ImportantList = () => {
    return(
        <>
        <MailLayout important={true} />
        </>
    )
}

export default ImportantList;