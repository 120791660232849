import React from "react";
import { Select, Input, DatePicker, Button } from "antd";


const SearchFilters = () => {
  return (
    <div className="search-filters">
      <div className="filter-item">
        <label>Vessels</label>
        <Select style={{ width: "100%" }} placeholder="Select Vessel">
          <Select.Option value="vessel1">Vessel 1</Select.Option>
          <Select.Option value="vessel2">Vessel 2</Select.Option>
          {/* Add more vessel options as needed */}
        </Select>
      </div>
      <div className="filter-item">
        <label>DWT</label>
        <Input.Group compact>
          <Input style={{ width: "50%" }} placeholder="Start DWT" type="number" />
          <Input style={{ width: "50%" }} placeholder="End DWT" type="number" />
        </Input.Group>
      </div>
      <div className="filter-item">
        <label>Sender Email</label>
        <Input type="email" placeholder="Sender Email" />
      </div>
      <div className="filter-item">
        <label>Received Date</label>
        <DatePicker style={{ width: "100%" }} />
      </div>
      <div className="filter-item">
        <label>Tags</label>
        <Select mode="multiple" style={{ width: "100%" }} placeholder="Select Tags">
          <Select.Option value="tag1">Tag 1</Select.Option>
          <Select.Option value="tag2">Tag 2</Select.Option>
          {/* Add more tag options as needed */}
        </Select>
      </div>
      <div className="filter-item">
        <label>With Attachment</label>
        <Select style={{ width: "100%" }} placeholder="Select">
          <Select.Option value="yes">Yes</Select.Option>
          <Select.Option value="no">No</Select.Option>
        </Select>
      </div>
      <div className="filter-item">
        <label>Label Email Subject</label>
        <Input placeholder="Label Email Subject" />
      </div>
      <div className="filter-item">
        <label>Email Receiver</label>
        <Input placeholder="Email Receiver" />
      </div>
      <div className="filter-item">
        <label>Email Body</label>
        <Input placeholder="Email Body" />
      </div>
      <div className="filter-item">
        <Button type="primary" style={{ width: "100%", lineHeight: '2' }}>Apply all filters</Button>
      </div>
    </div>
  );
};

export default SearchFilters;
