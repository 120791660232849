import React from 'react'
import CustomLoader from './CustomLoader'

const LoaderAtCenter = () => {
  return (
    <div  style={{
        position:"fixed",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex:1
        }}>
        <CustomLoader />
    </div>
  )
}

export default LoaderAtCenter