import React, { Component } from 'react';
import { Spin, Alert } from 'antd';
import NormalFormIndex from '../../../../../shared/NormalForm/normal_from_index';
import URL_WITH_VERSION, { getAPICall, objectToQueryStringFunc, openNotificationWithIcon } from '../../../../../shared';

class RightSearch extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      frmName: undefined,
      listData: []
    }
  }

  componentDidMount() {
    if (this.props.showExtraFields === true) {
      this.setState({...this.state, frmName: 'voy_relet_search_form' });
    } else {
      this.setState({...this.state, frmName: 'tcov_search_form'});
    }
  }

  findData = async (data) => {
  
    let _url = `${URL_WITH_VERSION}/tcov/find?l=10`;
    if (this.props.showExtraFields === true) {
      // _url = `${URL_WITH_VERSION}/voyage-relet/find?l=4`
      _url = `${URL_WITH_VERSION}/tcto/finds?l=10`
    }
    let header = {'where': {'OR': data}}
    const response = await getAPICall(_url, header);
    let respData = await response['data'];

    if (respData && typeof respData === 'object' && respData.hasOwnProperty('length')) {
      if(respData.length === 0) {
        openNotificationWithIcon("info", "No data available as per request.",3)
      }
      else {
        this.setState({ ...this.state, listData: respData });
      }
    }
  }

  rowClick = (estimateID) => {
    if (this.props.showExtraFields === true) {
      // this.props.history.push(`/voy-relet-full-estimate-edit/${estimateID}`);
      this.props.history?.push(`/edit-tc-est-fullestimate/${estimateID}`);
    } else {
      this.props.history?.push(`/edit-quick-estimate/${estimateID}`);
    }

    window.location.reload();
  }

  render() {
    const { frmName, listData } = this.state;

    return (
      <div className="body-wrapper modalWrapper m-0">
        <article className="article toolbaruiWrapper">
          {
            frmName && frmName !== '' ?
              <NormalFormIndex
                key={'key_' + frmName + '_0'}
                formClass="label-min-height"
                formData={{}}
                showForm={true}
                frmCode={frmName}
                addForm={true}
                showButtons={[
                  {
                    id: 'save',
                    title: 'Find',
                    type: 'primary',
                    event: data => {
                      this.findData(data);
                    },
                  },
                ]}
                formDevideInCols="1"
                inlineLayout={true}
              />
            : <div className="col col-lg-12">
              <Spin tip="Loading...">
                <Alert
                  message=" "
                  description="Please wait..."
                  type="info"
                />
              </Spin>
            </div>
          }
        </article>
        < hr />
        
        {
          listData && listData.length > 0 ? 
            <article className="article toolbaruiWrapper">
              <h5>Show Result</h5>
              {listData.map((e, i) => <article key={"list-" + e.id + "-" + i} className="article" onClick={() => this.rowClick(e.estimate_id)}>
                  <div className="box box-default">
                    <div className="bunkerInvoiceWrapper pl-2">
                      <p className="heading mb-0"><strong>{e.estimate_id}</strong></p>
                      <p className="sub-heading mb-0">{e.vessel_name}, {e.vessel_type}</p>
                      <p className="value m-0">{e.cargoname}</p>
                    </div>
                  </div>
                </article>
              )}
              {
                this.props.showExtraFields === true ? 
                  <p>
                    {/* <button onClick={() => this.props.history.push('/voy-relet-list')} className="btn btn-primary">Full Est. List</button> */}
                    <button onClick={() => this.props.history.push('/TC-Estimate-list')} className="btn btn-primary">Full Est. List</button>
                    <button onClick={() => this.props.history.push('/quick-estimate-list')} className="btn btn-info ml-2">Quick List</button>
                  </p>
                : <p>
                    <button onClick={() => this.props.history.push('/Voyage-Estimate-list')} className="btn btn-primary">Full Est. List</button>
                    <button onClick={() => this.props.history.push('/quick-estimate-list')} className="btn btn-info ml-2">Quick List</button>
                  </p>
              }
            </article>
          : undefined
        }
      </div>
    );
  }
}

export default RightSearch;
