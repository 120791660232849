import { Tabs, Timeline, Card, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import Item from "antd/es/list/Item";
import dayjs from "dayjs";

const BestMatchDrawerContent = ({ currentMatch, cargo }) => {
  console.log("currentMatch", currentMatch);

  const [activeKey, setActiveKey] = useState("1");
  const [events, setEvents] = useState([]);
  const [orderData, setOrderData] = useState([]);

  const [loadingNew, setLoadingNew] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);

  const handleBestMatch = async () => {
    try {
      setLoadingNew(true);
      let url;
      let payload = {};

      if (cargo) {
        url = `${process.env.REACT_APP_MT_URL}mail/best-match-cargo`;
        payload = {
          cargo_size: currentMatch.cargo_size,
          cargo_type: currentMatch.cargo_type,
          change_tab: "global",
          laycan: currentMatch.laycan
            ? currentMatch.laycan.includes("/") // Check if it's a date range
              ? dayjs(
                  currentMatch.laycan.split("/")[0],
                  ["DD-MM-YYYY", "YYYY-MM-DD"],
                  true
                ).format("YYYY-MM-DD") // Format only the first date in the range
              : dayjs(
                  currentMatch.laycan,
                  ["DD-MM-YYYY", "YYYY-MM-DD"],
                  true
                ).format("YYYY-MM-DD") // Format the single date
            : null,

          load_port: currentMatch.load_port.port,
        };
      } else {
        payload = {
          change_tab: "global",
          dwt: currentMatch?.dwt,
          open_area: currentMatch?.open_area,
          vessel_type: currentMatch?.vessel_type,
        };
        url = `${process.env.REACT_APP_MT_URL}mail/best-match-tonnage`;
      }

      const response = await postAPICall(url, payload, "post", (data) => {
        if (data.matched_documents && data.matched_documents.length > 0) {
          let events = [];
          // Map each item in the data to an object with necessary details
          if (cargo) {
            events = data?.matched_documents?.map((item, index) => ({
              vessel_name: item?.vessel_name || "N/A",
              open_area: item?.open_area || "N/A",
              open_date: item?.open_date || "N/A",
              dwt: item?.dwt || "N/A",
              vessel_type: item?.vessel_type || "N/A",
              sub_vessel_type: item?.sub_vessel_type || "N/A",
              build_year:
                item?.build_year && Array.isArray(item?.build_year)
                  ? item?.build_year.join(", ")
                  : item?.build_year || "N/A",
            }));
          } else {
            events = data?.matched_documents?.map((item, index) => ({
              company: item?.company || "N/A",
              tct: item?.tct || "N/A",
              load_port: item?.load_port?.port || "Unknown port",
              laycan: item?.laycan || "N/A",
              cargo: item?.cargo || "N/A",
            }));
          }

          setEvents(events);
          setLoadingNew(false);
        } else {
          openNotificationWithIcon("info", "No Best Match Found!");
          setLoadingNew(false);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    handleBestMatch();
  }, []);

  const getMyCargoOrder = async () => {
    try {
      setOrderLoading(true);
      let url;
      let payload = {};

      if (cargo) {
        payload = {
          cargo_size: currentMatch.cargo_size,
          cargo_type: currentMatch.cargo_type,
          change_tab: "not global",
          laycan: currentMatch.laycan
            ? currentMatch.laycan.includes("/") // Check if it's a date range
              ? dayjs(
                  currentMatch.laycan.split("/")[0],
                  ["DD-MM-YYYY", "YYYY-MM-DD"],
                  true
                ).format("YYYY-MM-DD") // Format only the first date in the range
              : dayjs(
                  currentMatch.laycan,
                  ["DD-MM-YYYY", "YYYY-MM-DD"],
                  true
                ).format("YYYY-MM-DD") // Format the single date
            : null,

          load_port: currentMatch.load_port.port,
        };
        url = `${process.env.REACT_APP_MT_URL}mail/best-match-cargo`;
      } else {
        payload = {
          change_tab: "not global",
          dwt: currentMatch?.dwt,
          open_area: currentMatch?.open_area,
          vessel_type: currentMatch?.vessel_type,
        };
        url = `${process.env.REACT_APP_MT_URL}mail/best-match-tonnage`;
      }

      const response = await postAPICall(url, payload, "POST", (value) => {
        if (value.matched_documents && value.matched_documents.length > 0) {
          setOrderData(value.matched_documents);
          setOrderLoading(false);
        } else {
          openNotificationWithIcon("info", "No Data found for My Order Cargo");
          setOrderLoading(false);
        }
      });
    } catch (error) {
      console.error("Error fetching my cargo order data:", error);
    }
  };

  // Timeline component to display data as cards
  const TimelineBestMatch = () => (
    <div className="mail_scrolls" style={{ height: "75vh", overflowY: "auto" }}>
      {loadingNew ? (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <Spin />
        </div>
      ) : (
        <Timeline>
          {cargo
            ? events?.map((event, index) => (
                <Item key={index}>
                  <div style={{ padding: "7px" }}>
                    <Card
                      title={event?.vessel_name?.toUpperCase() || "N/A"}
                      bordered={false}
                      style={{ marginBottom: 16 }}
                      className="timeline-item-card"
                    >
                      <div className="timeline-item-details">
                        <p>
                          <strong>Open Area :</strong> {event.open_area}
                        </p>
                        <p>
                          <strong>Open Date:</strong> {event.open_date}
                        </p>
                        <p>
                          <strong>Dead Weight</strong> {event.dwt}
                        </p>
                        <p>
                          <strong>Build Year:</strong> {event.build_year}
                        </p>
                        <p>
                          <strong>Vessel Type:</strong> {event.vessel_type}
                        </p>
                        <p>
                          <strong>Vessel Sub Type:</strong>{" "}
                          {event.sub_vessel_type}
                        </p>
                      </div>
                    </Card>
                  </div>
                </Item>
              ))
            : events?.map((event, index) => (
                <Item key={index}>
                  <div style={{ padding: "7px" }}>
                    <Card
                      title={event?.company?.toUpperCase()}
                      bordered={false}
                      style={{ marginBottom: 16 }}
                      className="timeline-item-card"
                    >
                      <div className="timeline-item-details">
                        <p>
                          <strong>TCT:</strong> {event.tct}
                        </p>
                        <p>
                          <strong>Load Port:</strong> {event.load_port}
                        </p>
                        <p>
                          <strong>Laycan:</strong> {event.laycan}
                        </p>
                        <p>
                          <strong>Cargo:</strong> {event.cargo}
                        </p>
                      </div>
                    </Card>
                  </div>
                </Item>
              ))}
        </Timeline>
      )}
    </div>
  );

  const MyOrderCargo = () => (
    <div
      className="mail_scrolls myOrderBox"
      style={{ height: "75vh", overflowY: "auto" }}
    >
      {orderLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <Spin />
        </div>
      ) : (
        <Timeline mode="right">
          {cargo
            ? orderData?.map((event, index) => (
                <Item key={index}>
                  <div style={{ padding: "7px" }}>
                    <Card
                      title={
                        <span style={{ display: "block", textAlign: "left" }}>
                          {event?.vessel_name?.toUpperCase() || "N/A"}
                        </span>
                      }
                      bordered={false}
                      style={{ marginBottom: 16 }}
                      className="timeline-item-card"
                    >
                      <div className="timeline-item-details">
                        <p>
                          <strong>Open Area :</strong>{" "}
                          {event.open_area || "N/A"}
                        </p>
                        <p>
                          <strong>Open Date:</strong> {event.open_date || "N/A"}
                        </p>
                        <p>
                          <strong>Dead Weight</strong> {event.dwt || "N/A"}
                        </p>
                        <p>
                          <strong>Build Year:</strong>{" "}
                          {event.build_year || "N/A"}
                        </p>
                        <p>
                          <strong>Vessel Type:</strong>{" "}
                          {event.vessel_type || "N/A"}
                        </p>
                        <p>
                          <strong>Vessel Sub Type:</strong>{" "}
                          {event.sub_vessel_type || "N/A"}
                        </p>
                      </div>
                    </Card>
                  </div>
                </Item>
              ))
            : orderData?.map((event, index) => (
                <Item key={index}>
                  <div style={{ padding: "7px" }}>
                    <Card
                      title={
                        <p style={{ textAlign: "left", margin: "0" }}>
                          {event?.company?.toUpperCase() || "N/A"}
                        </p>
                      }
                      bordered={false}
                      style={{ marginBottom: 16 }}
                      className="timeline-item-card myOrder"
                    >
                      <div className="timeline-item-details">
                        <p>
                          <strong>TCT:</strong> {event?.tct}
                        </p>
                        <p>
                          <strong>Load Port:</strong> {event?.load_port?.port}
                        </p>
                        <p>
                          <strong>Laycan:</strong> {event?.laycan}
                        </p>
                        <p>
                          <strong>Cargo:</strong> {event?.cargo}
                        </p>
                      </div>
                    </Card>
                  </div>
                </Item>
              ))}
        </Timeline>
      )}
    </div>
  );
  // Define the items for the Tabs component
  const items = [
    {
      key: "1",
      label: "Global Cargo",
      children: <TimelineBestMatch />,
    },
    {
      key: "2",
      label: "My Order Cargo",
      children: <MyOrderCargo />,
    },
  ];

  const handleTabChange = (tab) => {
    setActiveKey(tab);
    if (tab === "2" && orderData.length === 0) {
      getMyCargoOrder();
    }
  };

  return (
    <>
      <div style={{ paddingBottom: "10px" }}>
        <table className="bestMatchUpperTable">
          {cargo ? (
            <tbody>
              <tr>
                <td>Cargo Name</td>
                <td>---</td>
                <td style={{ fontWeight: "bold" }}>
                  {currentMatch?.cargo?.toUpperCase() || "N/A"}
                </td>
              </tr>
              <tr>
                <td>Voyage Type</td>
                <td>---</td>
                <td>{currentMatch?.tct.toUpperCase() || "N/A"}</td>
              </tr>
              <tr>
                <td>Cargo Type</td>
                <td>---</td>
                <td>
                  {currentMatch?.cargo_type.toUpperCase()}
                </td>
              </tr>
              <tr>
                <td>Load/Disc Port</td>
                <td>---</td>
                <td style={{ fontWeight: "bold" }}>
                  {(currentMatch.load_port.port || 'Unknown') + '/' + (currentMatch?.discharge_port.port || 'Unknown')}
                </td>
              </tr>
              <tr>
                <td>Laycan Date</td>
                <td>---</td>
                <td>
                  {currentMatch?.laycan ? currentMatch?.laycan : "N/A"}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td>Vessel Name</td>
                <td>---</td>
                <td style={{ fontWeight: "bold" }}>
                  {currentMatch?.vessel_name?.toUpperCase() || "N/A"}
                </td>
              </tr>
              <tr>
                <td>Dead Weight</td>
                <td>---</td>
                <td>{currentMatch?.dwt ? currentMatch?.dwt + " MT" : "N/A"}</td>
              </tr>
              <tr>
                <td>Build Year</td>
                <td>---</td>
                <td>
                  {Array.isArray(currentMatch?.build_year)
                    ? currentMatch?.build_year?.join(", ")
                    : currentMatch?.build_year}
                </td>
              </tr>
              <tr>
                <td>Open Area</td>
                <td>---</td>
                <td style={{ fontWeight: "bold" }}>
                  {currentMatch?.open_area
                    ? currentMatch?.open_area?.toUpperCase()
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Open Dates</td>
                <td>---</td>
                <td>
                  {currentMatch?.open_date ? currentMatch?.open_date : "N/A"}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <Tabs
        animated
        centered
        defaultActiveKey={activeKey}
        onChange={handleTabChange}
        items={items}
      />
    </>
  );
};

export default BestMatchDrawerContent;
