import React, { Component } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import moment from "moment";
import html2canvas from "html2canvas";
class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    const formReportdata = {};

    this.state = {
      formReportdata: Object.assign(formReportdata, this.props.data || {}),
    };
  }
  render() {
    const { formReportdata } = this.state;

    return (
      <article className="article toolbaruiWrapper">
      <div className="box box-default" id="divToPrint">
        <div className="box-body">
          <div className="invoice-inner-download mt-3">
            <div className="row">
              <div className="col-12 text-center">
                <img
                  className="reportlogo text-center"
                  src={formReportdata.logo}
                  alt="formReportdata"
                ></img>
                <p className="sub-title m-0">{formReportdata.full_name}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="text-center invoice-top-address">
                <p>{formReportdata.address}</p>
              </div>
            </div>
          </div>
          <table className="table  custom-table-bordered tc-table">
            <br />
            <tbody>
              <td className="border-0">
                <h5 className="font-weight-bold tc-sub-header">
                  Vessel Details
                </h5>
                <table className="table table-bordered ">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Vessel Name/Code :</td>
                      <td className="text-right">
                        {formReportdata.vessel_name
                          ? formReportdata.vessel_name
                          : "N/A"}
                        /
                        {formReportdata.vessel_code
                          ? formReportdata.vessel_code
                          : "N/A"}
                      </td>
                      <td className="font-weight-bold">My Company/LOB :</td>
                      <td className="text-right">
                        {formReportdata.my_company_lob_name
                          ? formReportdata.my_company_lob_name
                          : "N/A"}{" "}
                        /{" "}
                        {formReportdata.company_lob_name
                          ? formReportdata.company_lob_name
                          : "N/A"}
                      </td>

                      <td className="font-weight-bold">Voy Est. ID :</td>
                      <td className="text-right">
                        {formReportdata.estimate_id
                          ? formReportdata.estimate_id
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">DWT :</td>
                      <td className="text-right">
                        {formReportdata.dwt ? formReportdata.dwt : "N/A"}
                      </td>
                      <td className="font-weight-bold">C/P Date :</td>
                      <td className="text-right">
                        {formReportdata.cp_date
                          ? formReportdata.cp_date
                          : "N/A"}
                      </td>
                      <td className="font-weight-bold">
                        Fixed By/Ops User :
                      </td>
                      <td className="text-right">
                        {formReportdata.fixed_by_user
                          ? formReportdata.fixed_by_user
                          : "N/A"}
                        /
                        {formReportdata.ops_user_name
                          ? formReportdata.ops_user_name
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">HF/TCI Code :</td>
                      <td className="text-right">
                        {formReportdata.add_percentage
                          ? formReportdata.add_percentage
                          : "N/A"}
                        /
                        {formReportdata.bro_percentage
                          ? formReportdata.bro_percentage
                          : "N/A"}
                      </td>
                      <td className="font-weight-bold">Daily Hire rate :</td>
                      <td className="text-right">
                        {formReportdata.tci_d_hire
                          ? formReportdata.tci_d_hire
                          : "N/A"}
                      </td>
                      <td className="font-weight-bold">Commence Date :</td>
                      <td className="text-right">
                        {formReportdata.commence_date
                          ? formReportdata.commence_date
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">
                        Add Com./Broker % :
                      </td>
                      <td className="text-right">
                        {formReportdata.add_percentage
                          ? formReportdata.add_percentage
                          : "N/A"}
                        /
                        {formReportdata.bro_percentage
                          ? formReportdata.bro_percentage
                          : "N/A"}
                      </td>
                      <td className="font-weight-bold">WF (%) :</td>
                      <td className="text-right">
                        {formReportdata.dwf ? formReportdata.dwf : "N/A"}
                      </td>
                 
                      <td className="font-weight-bold">Completing Date :</td>
                      <td className="text-right">
                        {formReportdata.completing_date
                          ? formReportdata.completing_date
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold"> Other Cost:</td>
                      <td className="text-right">
                        {formReportdata.bb ? formReportdata.bb : "N/A"}
                      </td>
                      

                      <td className="font-weight-bold">Ballast Bonus :</td>
                      <td className="text-right">
                        {formReportdata.ballast_bonus
                          ? formReportdata.ballast_bonus
                          : "N/A"}
                      </td>
                     
                      <td className="font-weight-bold">
                        {" "}
                        Total Voyage Days :
                      </td>
                      <td className="text-right">
                        {formReportdata.total_days
                          ? formReportdata.total_days
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Routing Type :</td>
                      <td className="text-right">
                        {formReportdata.routing_type_name
                          ? formReportdata.routing_type_name
                          : "N/A"}
                      </td>

                      <td className="font-weight-bold">Voyage Ops Type:</td>
                      <td className="text-right">
                        {formReportdata.ops_type_name
                          ? formReportdata.ops_type_name
                          : "N/A"}
                      </td>
                      <td className="font-weight-bold">Trade Area :</td>
                      <td className="text-right">
                        {formReportdata.trade_area_name
                          ? formReportdata.trade_area_name
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      
                      <td className="font-weight-bold">ECA Fuel Grade:</td>
                      <td className="text-right">
                        {formReportdata.trade_area_name
                          ? formReportdata.trade_area_name
                          : "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </table>
               
                <table className="table">
                  <tbody>
                    <td>
                      <h5 className="font-weight-bold">Eco/Cp speed</h5>
                      <table className="table border-table ">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th rowSpan="2">SPD Type</th>
                            <th className="text-center">Ballast Bonus</th>
                            <th className="text-center">Laden</th>
                          </tr>
                          <tr className="HeaderBoxText">
                            <th>Kt</th>
                            <th>Cons</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata["-"] &&
                          formReportdata["-"] &&
                          formReportdata["-"].length > 0
                            ? formReportdata["-"].map((e, idx) => {
                                return (
                                  <>
                                    <tr key={idx}>
                                      <td>
                                        {e.spd_type ? e.spd_type : "N/A"}
                                      </td>
                                      <td>
                                        {e.ballast_spd
                                          ? e.ballast_spd
                                          : "N/A"}
                                      </td>

                                      <td>
                                        {e.laden_spd ? e.laden_spd : "N/A"}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            : undefined}
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <h5 className="font-weight-bold">
                        Speed/Cons and price $
                      </h5>
                      <table className="table border-table ">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th>CP$</th>
                            <th>Fuel Grade</th>
                            <th>Sea Con B</th>
                            <th>Sea Con.L</th>
                            <th>Load</th>
                            <th>Disch</th>
                            <th>Idle</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata["."] &&
                          formReportdata["."] &&
                          formReportdata["."].length > 0
                            ? formReportdata["."].map((e, idx) => {
                                return (
                                  <>
                                    <tr key={idx}>
                                      <td>
                                        {e.cp_price ? e.cp_price : "N/A"}
                                      </td>
                                      <td>
                                        {e.fuel_code ? e.fuel_code : "N/A"}
                                      </td>
                                      <td>
                                        {e.ballast_value
                                          ? e.ballast_value
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {e.laden_value
                                          ? e.laden_value
                                          : "N/A"}
                                      </td>
                                      
                                      <td>
                                        {e.con_loading
                                          ? e.con_loading
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {e.con_disch ? e.con_disch : "N/A"}
                                      </td>
                                      <td>
                                        {e.con_ideal_on
                                          ? e.con_ideal_on
                                          : "N/A"}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            : undefined}
                        </tbody>
                      </table>
                    </td>
                  </tbody>
                </table>

                <h5 className="font-weight-bold tc-sub-header">Cargo</h5>
                <table className="table border-table ">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th>S/N</th>
                      <th>Cargo ID</th>
                      <th>Charterer Party</th>
                      <th>Cargo name</th>
                      <th>CP qty</th>
                      <th>UNIT</th>
                      <th>Opt%</th>
                      <th>OPT Type</th>
                      <th>Freight Type</th>
                      <th>Frt rate</th>
                      <th>Lumsum</th>
                      <th>B Comm%</th>
                      <th>Extra Rev</th>
                      <th>Currency</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.cargos && formReportdata.cargos.length > 0
                      ? formReportdata.cargos.map((e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.serial_no ? e.serial_no : "N/A"}</td>
                                <td>
                                  {e.cargo_contract_id
                                    ? e.cargo_contract_id
                                    : "N/A"}
                                </td>

                                <td>
                                  {e.charterer_name
                                    ? e.charterer_name
                                    : "N/A"}
                                </td>
                                <td>
                                  {e.cargo_name1 ? e.cargo_name1 : "N/A"}
                                </td>
                                <td>{e.cp_qty ? e.cp_qty : "N/A"}</td>
                                <td>{e.cp_unit ? e.cp_unit : "N/A"}</td>
                                <td>
                                  {e.opt_percentage
                                    ? e.opt_percentage
                                    : "N/A"}
                                </td>
                                <td>{e.opt_type ? e.opt_type : "N/A"}</td>
                                <td>{e.frt_type ? e.frt_type : "N/A"}</td>
                                <td>{e.frat_rate ? e.frat_rate : "N/A"}</td>
                                <td>{e.lumsum ? e.lumsum : "N/A"}</td>
                                <td>
                                  {e.b_commission ? e.b_commission : "N/A"}
                                </td>
                                <td>{e.extra_rev ? e.extra_rev : "N/A"}</td>
                                <td>{e.currency ? e.currency : "N/A"}</td>
                              </tr>
                            </>
                          );
                        })
                      : undefined}
                  </tbody>
                </table>

                {/* Port Itinerary Table */}
                <table className="table voy-table ">
                  <tbody>
                    <h5 className="font-weight-bold tc-sub-header">
                      Port Itinerary
                    </h5>
                    <table className="table border-table n">
                      <thead className="">
                        <tr className="HeaderBoxText ">
                          <th>Port</th>
                          <th>Crossed</th>
                          <th>Eca/Seca Dist</th>
                          <th>Eca/Seca Days</th>
                          <th>Function</th>
                          <th>Passage</th>
                          <th>Miles</th>
                          <th>STYPE</th>
                          <th>WF%</th>
                          <th>SPD</th>
                          <th>Eff-SPD</th>
                          <th>GSD</th>
                          <th>TSD</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.portitinerary &&
                        formReportdata.portitinerary &&
                        formReportdata.portitinerary.length > 0
                          ? formReportdata.portitinerary.map((e, idx) => {
                              return (
                                <>
                                  <tr key={idx}>
                                    <td>{e.port ? e.port : "N/A"}</td>
                                    <td>{e.crossed ? e.crossed : "N/A"}</td>
                                    <td>
                                      {e.seca_length ? e.seca_length : 0}
                                    </td>
                                    <td>{e.eca_days ? e.eca_days : "N/A"}</td>
                                    <td>{e.funct ? e.funct : "N/A"}</td>
                                    <td>{e.passage ? e.passage : "N/A"}</td>
                                    <td>{e.miles ? e.miles : "N/A"}</td>

                                    <td>{e.s_type ? e.s_type : "N/A"}</td>
                                    <td>{e.wf_per ? e.wf_per : "N/A"}</td>
                                    <td>{e.speed ? e.speed : "N/A"}</td>
                                    <td>
                                      {e.eff_speed ? e.eff_speed : "N/A"}
                                    </td>
                                    <td>{e.gsd ? e.gsd : "N/A"}</td>
                                    <td>{e.tsd ? e.tsd : "N/A"}</td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                    <table className="table border-table n">
                      <thead className="">
                        <tr className="HeaderBoxText ">
                          <th>XSD</th>
                          <th>L/D QTY</th>
                          <th>L/D Rate(D)</th>
                          <th>L/D Rate(H)</th>
                          <th>L/D Term </th>
                          <th>TurnTime(H)</th>
                          <th>P Days</th>
                          <th>XPD</th>
                          <th>Est.P Days</th>
                          <th>P.EXP</th>
                          <th>Demurrage/Dispatch</th>
                          <th>Dem/Des Final Amount</th>
                          <th>Euro. Port</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.portitinerary &&
                        formReportdata.portitinerary &&
                        formReportdata.portitinerary.length > 0
                          ? formReportdata.portitinerary.map((e, idx) => {
                              return (
                                <>
                                  <tr key={idx}>
                                    <td>{e.xsd ? e.xsd : "N/A"}</td>
                                    <td>{e.l_d_qty ? e.l_d_qty : "N/A"}</td>
                                    <td>{e.l_d_rate ? e.l_d_rate : 0}</td>
                                    <td>
                                      {e.l_d_rate1 ? e.l_d_rate1 : "N/A"}
                                    </td>
                                    <td>{e.l_d_term ? e.l_d_term : "N/A"}</td>
                                    <td>
                                      {e.turn_time ? e.turn_time : "N/A"}
                                    </td>
                                    <td>{e.days ? e.days : "N/A"}</td>

                                    <td>{e.xpd ? e.xpd : "N/A"}</td>
                                    <td>{e.e_p_days ? e.e_p_days : "N/A"}</td>
                                    <td>{e.p_exp ? e.p_exp : "N/A"}</td>
                                    <td>{e.dem_disp ? e.dem_disp : "N/A"}</td>
                                    <td>
                                      {e.dem_disp_amt
                                        ? e.dem_disp_amt
                                        : "N/A"}
                                    </td>
                                    <td>{e.is_eur ? e.is_eur : "N/A"}</td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>

                    {/* Bunker Details table */}
                    <h4 className="font-weight-bold tc-sub-header">
                      Bunker Details
                    </h4>
                    <table className="table border-table table-invoice-report-colum">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th rowSpan="2">Port</th>
                          <th rowSpan="2">Miles</th>
                          <th rowSpan="2">Function</th>
                          <th rowSpan="2">TSD</th>
                          <th rowSpan="2">Eca/Seca Dist</th>
                          <th rowSpan="2">SPD Type</th>
                          <th rowSpan="2">Eca/Seca Days</th>
                          <th rowSpan="2">SPD</th>
                          <th rowSpan="2">Arrival</th>
                          <th rowSpan="2">Departure</th>
                          <th colSpan="5">Fuel Grade (Sea Cons. In MT)</th>
                          
                        </tr>
                        <tr className="HeaderBoxText">
                          <th>IFO</th>
                          <th>VLSFO</th>
                          <th>LSMGO</th>
                          <th>MGO</th>
                          <th>ULSFO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.bunkerdetails &&
                        formReportdata.bunkerdetails &&
                        formReportdata.bunkerdetails.length > 0
                          ? formReportdata.bunkerdetails.map((e, idx) => {
                              return (
                                <>
                                  <tr key={idx}>
                                    <td>{e.port}</td>
                                    <td>{e.miles}</td>
                                    <td>{e.funct}</td>
                                    <td>{e.tsd}</td>
                                    <td>{e.seca_length}</td>
                                    <td>{e.spd_type}</td>
                                    <td>{e.seca_length}</td>
                                    <td>{e.speed}</td>
                                    <td>
                                      {e.arrival_date_time &&
                                      e.arrival_date_time !=
                                        "0000-00-00 00:00:00"
                                        ? moment(
                                            e.arrival_date_time,
                                            "ddd, DD MMM YYYY HH:mm:ss z"
                                          ).format("YYYY-MM-DD HH:mm")
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {e.departure &&
                                      e.departure != "0000-00-00 00:00:00"
                                        ? moment(
                                            e.departure,
                                            "ddd, DD MMM YYYY HH:mm:ss z"
                                          ).format("YYYY-MM-DD HH:mm")
                                        : "N/A"}
                                    </td>

                                    <td>{e.ifo}</td>
                                    <td>{e.vlsfo}</td>
                                    <td>{e.lsmgo}</td>
                                    <td>{e.mgo}</td>
                                    <td>{e.ulsfo}</td>
                                    
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>

                    <table className="table border-table table-invoice-report-colum">
                      <thead>
                        <tr className="HeaderBoxText">
                        <th rowSpan="2">Eca/Seca Cons.(in Mt)</th>
                          <th rowSpan="2">Sea Co2 Emission</th>
                          <th colSpan="5">Arrival ROB</th>
                          <th colSpan="5">Port Cons. Fuel</th>
                         
                        </tr>
                        <tr className="HeaderBoxText">
                          <th>IFO</th>
                          <th>VLSFO</th>
                          <th>LSMGO</th>
                          <th>MGO</th>
                          <th>ULSFO</th>

                          <th>IFO</th>
                          <th>VLSFO</th>
                          <th>LSMGO</th>
                          <th>MGO</th>
                          <th>ULSFO</th>

                         
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.bunkerdetails &&
                        formReportdata.bunkerdetails &&
                        formReportdata.bunkerdetails.length > 0
                          ? formReportdata.bunkerdetails.map((e, idx) => {
                              return (
                                <>
                                  <tr key={idx}>
                                  <td>{e.arob_ifo}</td>
                                    <td>{e.pc_ifo}</td>

                                    <td>{e.pc_vlsfo}</td>
                                    <td>{e.pc_lsmgo}</td>
                                    <td>{e.pc_mgo}</td>
                                    <td>{e.pc_ulsfo}</td>

                                    <td>{e.r_ifo}</td>

                                    <td>{e.r_vlsfo}</td>
                                    <td>{e.r_lsmgo}</td>
                                    <td>{e.r_mgo}</td>
                                    <td>{e.r_ulsfo}</td>

                                    <td>{e.dr_ifo}</td>

                                    <td>{e.dr_vlsfo}</td>
                                    <td>{e.dr_lsmgo}</td>
                                    <td>{e.dr_mgo}</td>
                                    <td>{e.dr_ulsfo}</td>
                                    <td>{e.dr_ulsfo}</td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                    <table className="table border-table table-invoice-report-colum">
                      <thead>
                        <tr className="HeaderBoxText">
                        <th colSpan="5">Received</th>
                          <th colSpan="5">Departure ROB</th>
                        </tr>
                        <tr className="HeaderBoxText">
                          <th>IFO</th>
                          <th>VLSFO</th>
                          <th>LSMGO</th>
                          <th>MGO</th>
                          <th>ULSFO</th>

                          <th>IFO</th>
                          <th>VLSFO</th>
                          <th>LSMGO</th>
                          <th>MGO</th>
                          <th>ULSFO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.bunkerdetails &&
                        formReportdata.bunkerdetails &&
                        formReportdata.bunkerdetails.length > 0
                          ? formReportdata.bunkerdetails.map((e, idx) => {
                              return (
                                <>
                                  <tr key={idx}>
                                    <td>{e.pc_ifo}</td>
                                    <td>{e.pc_vlsfo}</td>
                                    <td>{e.pc_lsmgo}</td>
                                    <td>{e.pc_mgo}</td>
                                    <td>{e.pc_ulsfo}</td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                    

                    <h4 className="font-weight-bold tc-sub-header">
                      Port Date Details
                    </h4>
                    <table className="table border-table table-invoice-report-colum">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th>Port ID</th>
                          <th>Port</th>
                          <th>Funct.</th>
                          <th>MILES</th>
                          <th>Passage</th>
                          <th>STYPE</th>
                          <th>SPD</th>
                          <th>WF%</th>
                          <th>TSD</th>
                          <th>XSD</th>
                          <th>Arrival Date Time</th>
                          <th>Day</th>
                          <th>T.PDays</th>
                          <th>Departure</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.portdatedetails &&
                        formReportdata.portdatedetails &&
                        formReportdata.portdatedetails.length > 0
                          ? formReportdata.portdatedetails.map((e, idx) => {
                              return (
                                <>
                                  <tr key={idx}>
                                    <td>{e.port_id}</td>
                                    <td>{e.port}</td>
                                    <td>{e.funct_name}</td>
                                    <td>{e.miles}</td>
                                    <td>{e.passage_name}</td>
                                    <td>{e.s_type_name}</td>
                                    <td>{e.speed}</td>
                                    <td>{e.wf_per}</td>
                                    <td>{e.tsd}</td>
                                    <td>{e.xsd}</td>
                                    <td>
                                      {e.arrival_date_time &&
                                      e.arrival_date_time !=
                                        "0000-00-00 00:00"
                                        ? moment(
                                            e.arrival_date_time,
                                            "ddd, DD MMM YYYY HH:mm:ss z"
                                          ).format("YYYY-MM-DD HH:mm")
                                        : "N/A"}
                                    </td>
                                    <td>{e.day}</td>
                                    <td>{e.pdays}</td>
                                    <td>
                                      {e.departure &&
                                      e.departure != "0000-00-00 00:00"
                                        ? moment(e.departure).format(
                                            "YYYY-MM-DD HH:mm"
                                          )
                                        : "N/A"}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                  </tbody>
                </table>
                <h4 className="font-weight-bold tc-sub-header">
                  CII Dynamics
                </h4>
                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th rowSpan="2">CII Ref</th>
                      <th rowSpan="2">CII Required</th>
                      <th rowSpan="2">CII Attained</th>
                      <th rowSpan="2">CII Rating</th>
                      <th rowSpan="2">CII Band</th>
                      <th rowSpan="2">Predicted Required CII 2025</th>
                      <th rowSpan="2">Predicted CII Rating 2025</th>
                      <th rowSpan="2">Year</th>
                      <th rowSpan="2">Port</th>
                      <th rowSpan="2">Function</th>
                      <th rowSpan="2">Passage</th>
                      <th colSpan="3">Operation Days</th>
                      <th rowSpan="2">DISTANCE SAILED</th>
                    </tr>
                    <tr className="HeaderBoxText">
                      <th>Sea</th>
                      <th>Port</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.bunkerdetails &&
                    formReportdata.bunkerdetails &&
                    formReportdata.bunkerdetails.length > 0
                      ? formReportdata.bunkerdetails.map((e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.port_id}</td>
                                <td>{e.port}</td>
                                <td>{e.funct_name}</td>
                                <td>{e.miles}</td>
                                <td>{e.passage_name}</td>
                                <td>{e.spd_type_name}</td>
                                <td>{e.speed}</td>
                                <td>
                                  {e.arrival_date_time &&
                                  e.arrival_date_time != "0000-00-00 00:00:00"
                                    ? moment(
                                        e.arrival_date_time,
                                        "ddd, DD MMM YYYY HH:mm:ss z"
                                      ).format("YYYY-MM-DD HH:mm")
                                    : "N/A"}
                                </td>
                                <td>
                                  {e.departure &&
                                  e.departure != "0000-00-00 00:00:00"
                                    ? moment(
                                        e.departure,
                                        "ddd, DD MMM YYYY HH:mm:ss z"
                                      ).format("YYYY-MM-DD HH:mm")
                                    : "N/A"}
                                </td>
                                <td>{e.ifo}</td>
                                <td>{e.vlsfo}</td>
                                <td>{e.lsmgo}</td>
                                <td>{e.mgo}</td>
                                <td>{e.ulsfo}</td>
                                <td>{e.ulsfo}</td>
                              </tr>
                            </>
                          );
                        })
                      : undefined}
                  </tbody>
                </table>
                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th colSpan="5"> Sea Consumption</th>
                      <th colSpan="5">Port Consumption</th>
                      <th rowSpan="2">Co2 Emission VLSFO/ULSFO</th>
                      <th rowSpan="2">Co2 Emission MGO/LSMGO</th>
                      <th rowSpan="2">Co2 Emission IFO</th>
                      <th rowSpan="2">Total Co2 Emission (MT)</th>
                    </tr>
                    <tr className="HeaderBoxText">
                      <th>IFO</th>
                      <th>VLSFO</th>
                      <th>LSMGO</th>
                      <th>MGO</th>
                      <th>ULSFO</th>

                      <th>IFO</th>
                      <th>VLSFO</th>
                      <th>LSMGO</th>
                      <th>MGO</th>
                      <th>ULSFO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.bunkerdetails &&
                    formReportdata.bunkerdetails &&
                    formReportdata.bunkerdetails.length > 0
                      ? formReportdata.bunkerdetails.map((e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.port_id}</td>
                                <td>{e.port}</td>
                                <td>{e.funct_name}</td>
                                <td>{e.miles}</td>
                                <td>{e.passage_name}</td>
                                <td>{e.spd_type_name}</td>
                                <td>{e.speed}</td>
                                <td>
                                  {e.arrival_date_time &&
                                  e.arrival_date_time != "0000-00-00 00:00:00"
                                    ? moment(
                                        e.arrival_date_time,
                                        "ddd, DD MMM YYYY HH:mm:ss z"
                                      ).format("YYYY-MM-DD HH:mm")
                                    : "N/A"}
                                </td>
                                <td>
                                  {e.departure &&
                                  e.departure != "0000-00-00 00:00:00"
                                    ? moment(
                                        e.departure,
                                        "ddd, DD MMM YYYY HH:mm:ss z"
                                      ).format("YYYY-MM-DD HH:mm")
                                    : "N/A"}
                                </td>

                                <td>{e.ifo}</td>
                                <td>{e.vlsfo}</td>
                                <td>{e.lsmgo}</td>
                                <td>{e.mgo}</td>
                                <td>{e.ulsfo}</td>
                              </tr>
                            </>
                          );
                        })
                      : undefined}
                  </tbody>
                </table>
                <h4 className="font-weight-bold tc-sub-header">EU ETS</h4>
                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th rowSpan="2">Year</th>
                      <th rowSpan="2">Port</th>
                      <th rowSpan="2">Function</th>
                      <th colSpan="5">Sea Consumption</th>
                      <th colSpan="5">Port Consumption</th>

                      <th rowSpan="2">Sea Emission (MT)</th>
                      <th rowSpan="2">Port Emission (MT)</th>
                    </tr>
                    <tr className="HeaderBoxText">
                      <th>IFO</th>
                      <th>VLSFO</th>
                      <th>LSMGO</th>
                      <th>MGO</th>
                      <th>ULSFO</th>

                      <th>IFO</th>
                      <th>VLSFO</th>
                      <th>LSMGO</th>
                      <th>MGO</th>
                      <th>ULSFO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.bunkerdetails &&
                    formReportdata.bunkerdetails &&
                    formReportdata.bunkerdetails.length > 0
                      ? formReportdata.bunkerdetails.map((e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.port_id}</td>
                                <td>{e.port}</td>
                                <td>{e.funct_name}</td>
                                <td>{e.miles}</td>
                                <td>{e.passage_name}</td>
                                <td>{e.spd_type_name}</td>
                                <td>{e.speed}</td>
                                <td>
                                  {e.arrival_date_time &&
                                  e.arrival_date_time != "0000-00-00 00:00:00"
                                    ? moment(
                                        e.arrival_date_time,
                                        "ddd, DD MMM YYYY HH:mm:ss z"
                                      ).format("YYYY-MM-DD HH:mm")
                                    : "N/A"}
                                </td>
                                <td>
                                  {e.departure &&
                                  e.departure != "0000-00-00 00:00:00"
                                    ? moment(
                                        e.departure,
                                        "ddd, DD MMM YYYY HH:mm:ss z"
                                      ).format("YYYY-MM-DD HH:mm")
                                    : "N/A"}
                                </td>

                                <td>{e.ifo}</td>
                                <td>{e.vlsfo}</td>
                                <td>{e.lsmgo}</td>
                                <td>{e.mgo}</td>
                                <td>{e.ulsfo}</td>

                                <td>{e.arob_ifo}</td>
                              </tr>
                            </>
                          );
                        })
                      : undefined}
                  </tbody>
                </table>

                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th rowSpan="2">Total Emission(MT)</th>
                      <th rowSpan="2">Phase In %</th>
                      <th rowSpan="2">Sea ETS %</th>
                      <th rowSpan="2">Sea ETS Emission (MT)</th>
                      <th rowSpan="2">Port ETS %</th>
                      <th rowSpan="2">Port ETS Emission (MT)</th>
                      <th rowSpan="2">Total EU ETS (MT)</th>
                      <th rowSpan="2">Total EU ETS Expense</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.bunkerdetails &&
                    formReportdata.bunkerdetails &&
                    formReportdata.bunkerdetails.length > 0
                      ? formReportdata.bunkerdetails.map((e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.arob_vlsfo}</td>
                                <td>{e.port_id}</td>
                                <td>{e.port}</td>
                                <td>{e.funct_name}</td>
                                <td>{e.miles}</td>
                                <td>{e.passage_name}</td>
                                <td>{e.spd_type_name}</td>
                                <td>{e.speed}</td>
                              </tr>
                            </>
                          );
                        })
                      : undefined}
                  </tbody>
                </table>
              </td>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    );
  }
}

class TcovReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Printer",
    };
  }

  printReceipt() {
    window.print();
  }
  printDocument() {
    var quotes = document.getElementById("divToPrint");

    html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 22;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 24,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("TCOV.pdf");
    });
  }

  render() {
    return (
      <div className="body-wrapper modalWrapper">
        <article className="article toolbaruiWrapper">
          <div className="box box-default">
            <div className="box-body">
              <div className="toolbar-ui-wrapper">
                <div className="leftsection"></div>
                <div className="rightsection">
                  <span className="wrap-bar-menu">
                    <ul className="wrap-bar-ul">
                      <li onClick={this.printDocument}>Download</li>
                      <li>
                        <ReactToPrint
                          trigger={() => (
                            <span className="text-bt">
                              <PrinterOutlined /> Print
                            </span>
                          )}
                          content={() => this.componentRef}
                        />
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <ComponentToPrint
                ref={(el) => (this.componentRef = el)}
                data={this.props.data}
              />
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default TcovReports;
