import React from "react";
import { Row, Col, Typography, Tag } from "antd";
// import { chipStyles } from 'src/constant/chipStyles';
import { chipStyles } from "../constants";

const Tags = () => {
  const Taglist = [
    "Payable",
    "Receivable",
    "Invoice",
    "invoice no.",
    "Hire",
    "Bank Name",
    "Balance",
    "Agent",
    "Supplier",
    "charterer",
    "Owner",
    "Broker",
    "PDA",
    "FDA",
    "Due date",
    "Freight Invoice",
    "Hire Invoice",
    "SOF (Statement Of Fact)",
    "NOR (Notice of Readiness)",
    "Loading Start",
    "Loading Stop",
    "Discharging Start",
    "Discharging Stop",
    "Vessel Moored",
    "Vessel Anchored",
    "Vessel Delivered",
    "Delivery Date",
    "Redelivery Date",
    "Noon Report",
    "ETA",
    "ETD",
    "Arrival",
    "Departure",
    "Date& time",
    "Bunker Invoice",
    "Port DA",
    "Port Disbursement",
    "Portcall",
    "FDA Due",
    "Survey Invoice",
    "Delivery Report",
    "Redelivery Report",
    "PFHS",
    "FHS",
    "Port agent",
    "Master",
    "IFO",
    "VLSFO",
    "ULSFO",
    "LSMGO",
    "MGO",
    "Dry Vessel",
    "Tanker Vessel",
    "vessel_name",
    "imo",
    "open_area",
    "open_date",
    "build_year",
    "cranes",
    "flag",
    "loa",
    "beam",
    "bod",
    "dwt",
    "draft",
    "hold_and_hatches",
    "grt_and_nrt",
    "speed_and_consumption",
    "grain_and_bale",
    "tpc",
    "Cargo Name",
    "laycan",
    "load_port",
    "discharge_port",
    "address_com",
    "Qty",
    "discharge_term",
    "broker_com",
    "load_term",
    "CARGO_NAME",
    "ACCOUNT",
    "LOAD_RATE",
    "DISCHARGE_RATE",
    "LOAD_PORT",
    "DISCHARGE_PORT",
    "LAYCAN",
    "DELIVERY",
    "RE_DELIVERY",
    "ADD_COM",
    "DURATION",
    "FREIGHT",
    "TCT",
    "Hire Invoice",
    "Freight Invoice",
    "Commission Invoice",
    "Brokerage Invoice",
    "Credit Note",
    "Debit",
    "Bunker Invoice",
    "On Hire / Off Hire",
    "Revenue / Expenses",
    "Hire Commission",
    "PDA Invoice",
    "FDA Invoice",
    "Claim Invoice",
    "Charter party",
    "Fixture note",
    "Voyage instruction",
    "B/L",
    "Pilot",
    "Invoice No",
    "Voyage No.",
    "CP Date",
    "Fixture No.",
    "Delivery Notice",
    "Redelivery Notice",
    "FFA",
    "Voyage schedule",
    "Arrival date",
    "Departure Date",
    "Portcall appointment",
  ];

  const Chartering = [
    "Vessel Name",
    "Imo",
    "Open Area",
    "Open Date",
    "Build Year",
    "Cranes",
    "Flag",
    "Loa",
    "Beam",
    "Bod",
    "Dwt",
    "Draft",
    "Hold And Hatches",
    "Grt And Nrt",
    "Speed And Consumption",
    "Grain And Bale",
    "Tpc",
    "Cargo Name",
    "Address Com",
    "Qty",
    "Broker Com",
    "Load Term",
    "Account",
    "Load Rate",
    "Discharge Rate",
    "Delivery",
    "Re Delivery",
    "Add Com",
    "Duration",
    "Freight",
    "Hire",
    "Charterer",
    "Owner",
    "Vessel Moored",
    "Vessel Anchored",
    "Vessel Delivered",
    "Delivery Date",
    "Redelivery Date",
    "Date& time",
    "IFO",
    "VLSFO",
    "ULSFO",
    "LSMGO",
    "MGO",
    "Dry Vessel",
    "Tanker Vessel",
    "Address Com",
    "Discharge Term",
    "Broker Com",
    "Cargo Name",
    "Account",
    "Load Rate",
    "Load Port",
    "Discharge Port",
    "Laycan",
    "Add Com",
    "Duration",
    "Tct",
  ];

  const opf = [
    "Hire Invoice",
    "Freight Invoice",
    "Commission Invoice",
    "Brokerage Invoice",
    "Credit Note",
    "Debit",
    "Bunker Invoice",
    "On Hire / Off Hire",
    "Revenue / Expenses",
    "Hire Commission",
    "PDA Invoice",
    "FDA Invoice",
    "Claim Invoice",
    "Charter Party",
    "Fixture Note",
    "Agent",
    "Voyage Instruction",
    "B/L",
    "Pilot",
    "Due Date",
    "Invoice No",
    "Voyage No",
    "CP Date",
    "Fixture No",
    "Delivery Notice",
    "Redelivery Notice",
    "PFHS",
    "FFA",
    "Voyage Schedule",
    "Noon Report",
    "ETA",
    "ETD",
    "Arrival Date",
    "Departure Date",
    "Date & Time",
    "Bunker Invoice",
    "Port DA",
    "Portcall Appointment",
    "FDA Due",
    "Payable",
    "Receivable",
    "Invoice",
    "Invoice no.",
    "Bank Name",
    "Balance",
    "PDA",
    "FDA",
    "Due date",
    "SOF (Statement Of Fact)",
    "NOR (Notice of Readiness)",
    "Loading Start",
    "Loading Stop",
    "Discharging Start",
    "Discharging Stop",
    "Noon Report",
    "Arrival",
    "Departure",
    "Port Disbursement",
    "FDA Due",
    "Survey Invoice",
    "Delivery Report",
    "Redelivery Report",
    "PFHS",
    "FHS",
    "Port Agent",
    "Master",
    "Charter Party",
    "Fixture Note",
    "Voyage Instruction",
    "Invoice No",
    "Voyage No.",
    "Fixture No.",
    "FFA",
    "Voyage Schedule",
    "Arrival Date",
    "Departure Date",
    "Portcall Appointment",
  ];
  // Ship Management:
  const shipingManagment = [
    "Dry Docking",
    "Flag State Compliance",
    "Crew Rotation",
    "Crew Sign-On/Off",
    "Seafarer Certificates",
    "Technical Maintenance",
    "Vessel Performance",
    "ISM/ISPS Compliance",
    "Health and Safety",
    "Payroll Processing",
    "Voyage Planning",
    "Bunker Reports",
    "Training Schedules",
    "Onboard Drills",
    "Crew Welfare",
  ];

  const oilAndGas = [
    "Trade Confirmation",
    "Cargo Allocation",
    "Hedge Positions",
    "Pricing Updates",
    "Shipment Schedules",
    "Quality Reports",
    "Market Analysis",
    "Risk Management",
    "Contract Negotiation",
    "Inventory Management",
    "Freight Rates",
    "Regulatory Compliance",
    "Supply Chain Coordination",
    "Customs Documentation",
    "Logistics Planning",
  ];

  const chunkSize = 14;
  const tagChunks = [];

  for (let i = 0; i < Taglist.length; i += chunkSize) {
    tagChunks.push(Taglist.slice(i, i + chunkSize));
  }

  return (
    <Row
      style={{
        padding: "0.5rem",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "white",
      }}
    >
      <Col
        style={{
          padding: "0.75rem",
          border: "1px solid #e0e0e0",
          borderRadius: "0.5rem",
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "0.5rem",
          }}
        >
          Chartering
        </Typography>
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {Chartering.map((tag, index) => (
            <Tag
              key={`chartering-${index}`}
              style={{
                backgroundColor:
                  chipStyles[index % chipStyles.length].backgroundColor,
                color: chipStyles[index % chipStyles.length].color,
                borderColor: chipStyles[index % chipStyles.length].color,
                fontSize: "12px",
                borderRadius: "4px",
              }}
            >
              {tag}
            </Tag>
          ))}
        </div>
      </Col>

      <Col
        style={{
          padding: "0.75rem",
          border: "1px solid #e0e0e0",
          borderRadius: "0.5rem",
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "0.5rem",
          }}
        >
          Operation & finance and General Email
        </Typography>
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {opf.map((tag, index) => (
            <Tag
              key={`opf-${index}`}
              style={{
                backgroundColor:
                  chipStyles[index % chipStyles.length].backgroundColor,
                color: chipStyles[index % chipStyles.length].color,
                borderColor: chipStyles[index % chipStyles.length].color,
                fontSize: "12px",
                borderRadius: "4px",
              }}
            >
              {tag}
            </Tag>
          ))}
        </div>
      </Col>
      <Col
        style={{
          padding: "0.75rem",
          border: "1px solid #e0e0e0",
          borderRadius: "0.5rem",
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "0.5rem",
          }}
        >
          Ship Management
        </Typography>
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {shipingManagment.map((tag, index) => (
            <Tag
              key={`opf-${index}`}
              style={{
                backgroundColor:
                  chipStyles[index % chipStyles.length].backgroundColor,
                color: chipStyles[index % chipStyles.length].color,
                borderColor: chipStyles[index % chipStyles.length].color,
                fontSize: "12px",
                borderRadius: "4px",
              }}
            >
              {tag}
            </Tag>
          ))}
        </div>
      </Col>
      <Col
        style={{
          padding: "0.75rem",
          border: "1px solid #e0e0e0",
          borderRadius: "0.5rem",
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "0.5rem",
          }}
        >
          Oil and Gas
        </Typography>
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {oilAndGas.map((tag, index) => (
            <Tag
              key={`opf-${index}`}
              style={{
                backgroundColor:
                  chipStyles[index % chipStyles.length].backgroundColor,
                color: chipStyles[index % chipStyles.length].color,
                borderColor: chipStyles[index % chipStyles.length].color,
                fontSize: "12px",
                borderRadius: "4px",
              }}
            >
              {tag}
            </Tag>
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default Tags;
