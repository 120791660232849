import React, { Component } from 'react';
import { PrinterOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    const formReportdata ={
      
    }

    this.state = {
      formReportdata:Object.assign(formReportdata, this.props.data || {}),
    }
    
  }
  render() {
    console.log(this.props.data)
    const{formReportdata} = this.state
     return (
      <article className="article toolbaruiWrapper">
        <div className="box box-default" id="divToPrint">
          <div className="box-body">
            <div className="invoice-inner-download mt-3">
              <div className="row">
                <div className="col-12 text-center">
                  <span className="title">{formReportdata && formReportdata.logo?formReportdata.logo:" "}</span>
                  <p className="sub-title m-0">{formReportdata && formReportdata.full_name?formReportdata.full_name:" "}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="text-center invoice-top-address">
                  <p>{formReportdata && formReportdata.address?formReportdata.address:" "}</p>
                </div>
              </div>
            </div>

            <table className="table table-bordered table-invoice-report-colum">
              <tbody>
                <tr>
                  <td className="font-weight-bold">A/C Payable/Receivable :</td>
                  <td>{formReportdata&& formReportdata.invoice_name?formReportdata.invoice_name:" "}</td>

                  <td className="font-weight-bold">Accounting Date :</td>
                  <td>{formReportdata && formReportdata.accounting_date ? moment(formReportdata.accounting_date).format('DD-MMMM-YYYY'):" "}</td>

                  <td className="font-weight-bold">PO Number :</td>
                  <td>{formReportdata && formReportdata.po_number?formReportdata.po_number:" "}</td>
                </tr>

                <tr>
                  <td className="font-weight-bold">Invoice Date :</td>
                  <td>{formReportdata && formReportdata.invoice_date ? moment(formReportdata.invoice_date).format('DD-MMMM-YYYY'):" "}</td>

                  <td className="font-weight-bold">I/C Trans No. :</td>
                  <td>{formReportdata && formReportdata.i_c_trans_no?formReportdata.i_c_trans_no:" "}</td>

                  <td className="font-weight-bold">Sent/Received Date :</td>
                  <td>{formReportdata && formReportdata.received_date ? moment(formReportdata.received_date).format('DD-MMMM-YYYY'):" "}</td>
                </tr>

                <tr>
                  <td className="font-weight-bold">Invoice/TDE Status :</td>
                  <td>{formReportdata && formReportdata.tde_status?formReportdata.tde_status:" "}</td>

                  <td className="font-weight-bold">Invoice Type :</td>
                  <td>{formReportdata && formReportdata.invoice_type?formReportdata.invoice_type:" "}</td>

                  <td className="font-weight-bold">Approval User :</td>
                  <td>{formReportdata && formReportdata.approval?formReportdata.approval:" "}</td>
                </tr>

                <tr>
                  <td className="font-weight-bold">Vendor :</td>
                  <td>{formReportdata && formReportdata.vendor_name?formReportdata.vendor_name:" "}</td>

                  <td className="font-weight-bold">Invoice Amount :</td>
                  <td>{formReportdata && formReportdata.invoice_amount?formReportdata.invoice_amount:" "}</td>

                  <td className="font-weight-bold">Bill Via :</td>
                  <td>{formReportdata && formReportdata.bill_via_name?formReportdata.bill_via_name:" "}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Amount Base :</td>
                  <td colSpan="5">{formReportdata && formReportdata.account_base?formReportdata.account_base:" "}</td>
                </tr>
              </tbody>
            </table>

            <table className="table border-table table-invoice-report-colum">
              <thead>
                <tr>
                  <th>Bank Code</th>
                  <th>Approval</th>
                  <th>Date Paid</th>
                  <th>By</th>
                  <th>Check/WT No.</th>
                  <th>Amount Paid</th>
                  <th>Amount Base</th>
                  <th>Pay Trans No.</th>
                </tr>
              </thead>
              <tbody>
                {formReportdata['paymententrytable'] && formReportdata['paymententrytable'].length > 0 && 
                formReportdata['paymententrytable'].map((pe, index)=>{
                  return(
                     <tr key={index}>
                      <td>{pe.bank_code?pe.bank_code:" "}</td>
                      <td>{pe.approval?pe.approval:" "}</td>
                      <td>{moment(pe.date_paid).format('DD-MMMM-YYYY')}</td>
                      <td>{pe.payment_by_name?pe.payment_by_name:" "}</td>
                      <td>{pe.check_wt_no?pe.check_wt_no:" "}</td>
                      <td>{pe.amount_paid?pe.amount_paid:" "}</td>
                      <td>{pe.amount_base?pe.amount_base:" "}</td>
                      <td>{pe.pay_trans_no?pe.pay_trans_no:" "}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            <table className="table table-bordered table-invoice-report-colum">
              <tbody>
                <tr>
                  <td className="font-weight-bold">Remittance Bank :</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>

                  <td className="font-weight-bold">Pay Bank/Code :</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>

                  <td className="font-weight-bold">Payment Mode :</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>

                  <td className="font-weight-bold">Total :</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                </tr>
              </tbody>
            </table>

            <h4>Accounting View</h4>

            <table className="table border-table table-invoice-report-colum">
              <thead>
                <tr>
                  <th>Comp</th>
                  <th>LOB</th>
                  <th>Vessel Code</th>
                  <th>Vessel Name</th>
                  <th>Account</th>
                  <th>Ap/AR Acct</th>
                  <th>Voy</th>
                  <th>Port</th>
                  <th>IC</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                </tr>

              </tbody>
            </table>

            <h4>Operation View</h4>

            <table className="table border-table table-invoice-report-colum">
              <thead>
                <tr>
                  <th>Acct Date</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Tax %</th>
                  <th>Trade Area</th>
                  <th>Cargo</th>
                  <th>Code</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                  <td>{formReportdata && formReportdata.description?formReportdata.description:" "}</td>
                  <td>{formReportdata && formReportdata.amount?formReportdata.amount:" "}</td>
                </tr>

                
              </tbody>
            </table>

            <table className="table table-bordered table-invoice-report-colum">
              <tbody>
                <tr>
                  <td className="font-weight-bold">Opr Last User :</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>

                  <td className="font-weight-bold">Acct Last User :</td>
                  <td>{formReportdata && formReportdata.repso_days? formReportdata.repso_days:" "}</td>

                  <td className="font-weight-bold">Acct Last Update :</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>

                  <td className="font-weight-bold">Total :</td>
                  <td>{formReportdata && formReportdata.repso_days?formReportdata.repso_days:" "}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
    );
  }
}

class TdeReport extends Component {
  constructor() {
    super();
    this.state = {
      name: 'Printer',

    };
  }

  printReceipt() {
    window.print();
  }
  printDocument() {
    htmlToImage.toPng(document.getElementById('divToPrint'), { quality: 0.95 })
        .then(function (dataUrl) {
          var link = document.createElement('a');
          link.download = 'my-image-name.jpeg';
          const pdf = new jsPDF();
          const imgProps= pdf.getImageProperties(dataUrl);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight);
          pdf.save("download.pdf"); 
        });
  }
  render() {
    return (
      <div className="body-wrapper modalWrapper">
        <article className="article toolbaruiWrapper">
          <div className="box box-default">
            <div className="box-body">
              <div className="toolbar-ui-wrapper">
                <div className="leftsection"></div>
                <div className="rightsection">
                  <span className="wrap-bar-menu">
                    <ul className="wrap-bar-ul">
                    <li onClick={this.printDocument}>
                      Download
                      </li>
                      <li>
                        <ReactToPrint
                          trigger={() => (
                            <span className="text-bt">
                            <PrinterOutlined /> Print
                            </span>
                          )}
                          content={() => this.componentRef}
                        />
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="article">
          <div className="box box-default">
            <div className="box-body">
            <ComponentToPrint ref={el => (this.componentRef = el)} data={this.props.data}/>
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default TdeReport;
