import React, { Component, useEffect } from "react";
import { Row, Col, Layout } from "antd";
import NormalFormIndex from "../../shared/NormalForm/normal_from_index";
import URL_WITH_VERSION, {
  postAPICall,
  openNotificationWithIcon,
  getAPICall,
  useStateCallback,
} from "../../shared";
import { SaveOutlined } from "@ant-design/icons";

const { Content } = Layout;

const InvoicePopup = (props) => {
  const [state, setState] = useStateCallback({
    frmName: "download_invoice_form",
    frmVisible: true,

    formData: {
      // bill_from: props?.data?.my_company,
      bill_from: props.myCompanyLob
        ? props.myCompanyLob
        : props?.data?.my_company,
      bill_to: props?.data?.counterparty,
      acc_type: props?.data?.acc_type || props?.data?.ap_ar,
      tax_invoice: false,
      credit_note: false,
      "..": [
        {
          bank_check: "",
          benificiary_name: props?.data?.bank_ajent?.benificiary_name,
          benificiary_bank: props?.data?.bank_ajent?.benificiary_bank,
          benificiary_bank_name: props?.data?.bank_ajent?.benificiary_bank_name,
          branch: props?.data?.bank_ajent?.branch,
          account_no: props?.data?.bank_ajent?.account_no,
          swift_code: props?.data?.bank_ajent?.swift_code,
          correspondent_bank: props?.data?.bank_ajent?.correspondent_bank,
          cb_swift_code: props?.data?.bank_ajent?.cb_swift_code,
          iban: props?.data?.bank_ajent?.iban,
          ib_name: props?.data?.bank_ajent?.ib_name,
          b_country: props?.data?.bank_ajent?.b_country,
        },
      ],
    },

    oldformdata: props?.data,
    type: props?.type,
    invoice_no: props?.data?.invoice_no,
    isSaved: false,
  });

  useEffect(() => {
    const newdata = async () => {
      const { formData } = state;
      if (props?.myCompanyLob) {
        const respdata = await getAPICall(
          `${URL_WITH_VERSION}/address/company/${props.myCompanyLob}`
        );

        let _formdata = Object.assign({}, formData);
        _formdata[".."] = respdata["data"];

        setState((prev) => ({ ...prev, formData: _formdata }));
      }
    };
    newdata();
  }, []);

  const saveFormData = async (data) => {
    const { frmName, type, invoice_no } = state;
    data = {
      ...data,
      invoice_no,
      isSaved: true,
    };
    if (data) {
      if (!data.bill_from) {
        openNotificationWithIcon(
          "info",
          "Please select the bill from first",
          3
        );
        return;
      }
      try {
        let _invoiceReport = Object.assign({}, data);
        const respdata = await getAPICall(
          `${URL_WITH_VERSION}/address/company/${data.bill_from}?ae=4`
        );

        const fetchedData = await postAPICall(
          `${URL_WITH_VERSION}/address/company-post`,
          {
            from_id: data.bill_from,
            to_id: data.bill_to,
          },
          "post",
          (data) => {
            props.getData(
              data["data"][1]["my_company_name"],
              data["data"][0]["my_company_name"]
            );
          }
        );

        const response = await respdata["data"];

        let checked_bank = data[".."].find((item) => item.bank_check == true);
        _invoiceReport["bank_ajent"] = { ...checked_bank };
        let responsedata = {
          ..._invoiceReport,
          ...response,
          credit_note: data["credit_note"],
          tax_invoice: data["tax_invoice"],
          acc_type: data["acc_type"],
        };
        if (
          response &&
          response.hasOwnProperty("id") &&
          response["id"] != undefined
        ) {
          openNotificationWithIcon("success", "Saved successfully", 2);

          props.updatepopup(responsedata);
        } else {
          openNotificationWithIcon("error", response.message);
        }
        setState((prevState) => ({
          ...prevState,
          invoiceReport: responsedata,
        }));
      } catch (err) {
        openNotificationWithIcon("error", "Something went wrong", 3);
      }
    }
  };

  return (
    <div className="wrap-rightbar full-wraps">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <div className="fieldscroll-wrap">
              <div className="body-wrapper">
                <article className="article">
                  <div className="box box-default">
                    <div className="box-body">
                      {state.frmVisible ? (
                        <NormalFormIndex
                          key={"key_" + state.frmName + "_0"}
                          formClass="label-min-height"
                          formData={state.formData}
                          showForm={true}
                          frmCode={state.frmName}
                          frmVisible={state.frmVisible}
                          showToolbar={[
                            {
                              isLeftBtn: [
                                {
                                  isSets: [
                                    {
                                      id: "3",
                                      key: "save",
                                      type: <SaveOutlined />,
                                      withText: "Save",
                                      showToolTip: true,
                                      event: (key, data) => saveFormData(data),
                                    },
                                  ],
                                },
                              ],
                              isRightBtn: [],
                            },
                          ]}
                          inlineLayout={true}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default InvoicePopup;
