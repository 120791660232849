import { combineReducers } from 'redux';
import settingsReducer from './settingsReducer';
import { routerReducer } from 'react-router-redux';
import chat from './chatReducer';
import emailReducer from './emailReducers'

const rootReducer = combineReducers({
  settings: settingsReducer,
  routing: routerReducer,
  chat: chat,
  email: emailReducer
});

export default rootReducer;
