import React, { Component } from "react";
import { Checkbox } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import Email from "../../components/Email";
import html2canvas from "html2canvas";

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    const formReportdata = {};

    this.state = {
      formReportdata: Object.assign(formReportdata, this.props.data || {}),
    };
  }
  render() {
    const { formReportdata } = this.state;
    return (
      <article className="article toolbaruiWrapper">
        <div className="box box-default" id="divToPrint">
          <div className="box-body">
            <div className="invoice-inner-download mt-3">
              <div className="row">
                <div className="col-12 text-center">
                  <span className="title">
                    <img
                      className="reportlogo"
                      src={formReportdata.logo}
                      alt="No Img"
                      crossOrigin="anonymous"
                    />
                  </span>
                  <p className="sub-title m-0">{formReportdata.full_name}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="text-center invoice-top-address">
                  <p>{formReportdata.address}</p>
                </div>
              </div>
            </div>

            <div className="row p10">
              <div className="col-md-12">
                <table className="table custom-table-bordered tc-table">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">
                        Company (Full Name) :
                      </td>
                      <td>{formReportdata.comp_full_name}</td>

                      <td className="font-weight-bold">Company Short Name :</td>
                      <td>{formReportdata.short_name}</td>

                      <td className="font-weight-bold">Currency :</td>
                      <td>{formReportdata.currency}</td>
                      {/* <td>{formReportdata &&formReportdata.accountinformation &&formReportdata.accountinformation[0]&&  formReportdata.accountinformation[0]["currency"]?formReportdata.accountinformation[0]["currency"]:"N/A"}</td>  */}
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Country :</td>
                      <td>{formReportdata.country}</td>

                      <td className="font-weight-bold">Country Code :</td>
                      <td>{formReportdata.country_code}</td>

                      <td className="font-weight-bold">Phone :</td>
                      <td>
                        {formReportdata && formReportdata.phone_number
                          ? formReportdata.phone_number
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Fax :</td>
                      <td>
                        {formReportdata.fax_number
                          ? formReportdata.fax_number
                          : "N/A"}
                      </td>

                      <td className="font-weight-bold">Telex Number :</td>
                      <td>
                        {formReportdata.telex_number
                          ? formReportdata.telex_number
                          : "N/A"}
                      </td>

                      <td className="font-weight-bold">Email :</td>
                      <td>
                        {formReportdata.email ? formReportdata.email : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Web Page :</td>
                      <td>
                        {formReportdata.web_page
                          ? formReportdata.web_page
                          : "N/A"}
                      </td>

                      <td className="font-weight-bold">Main Contact:</td>
                      <td>
                        {formReportdata.main_contact
                          ? formReportdata.main_contact
                          : "N/A"}
                      </td>
                      <td className="font-weight-bold">Credit Terms :</td>
                      <td>
                        {formReportdata.credit_terms_name
                          ? formReportdata.credit_terms_name
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      {/* <td className="font-weight-bold">Credit Terms :</td>
                      <td>{formReportdata.credit_terms_name?formReportdata.credit_terms_name:"N/A"}</td> */}

                      <td className="font-weight-bold">Credit Line :</td>
                      <td>
                        {formReportdata.credit_line
                          ? formReportdata.credit_line
                          : "N/A"}
                      </td>

                      <td className="font-weight-bold">VAT No :</td>
                      <td>
                        {formReportdata.vat_no ? formReportdata.vat_no : "N/A"}
                      </td>
                      <td className="font-weight-bold">&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>

                <h4 className="font-weight-bold tc-sub-header">Company Type</h4>

                <table className="table custom-table-bordered tc-table">
                  <tbody>
                    <tr>
                      <td>
                        {formReportdata.companytype.hasOwnProperty("PA") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}
                        Port Agent
                      </td>
                      <td>
                        {formReportdata.companytype.hasOwnProperty("PS") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}{" "}
                        Service Provider
                      </td>
                      <td>
                        {formReportdata.companytype.hasOwnProperty("C") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}{" "}
                        Charterer
                      </td>
                      <td>
                        {formReportdata.companytype.hasOwnProperty("B") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}{" "}
                        All Broker
                      </td>
                      <td>
                        {formReportdata.companytype.hasOwnProperty("RR") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}{" "}
                        Receiver
                      </td>
                      <td>
                        {formReportdata.companytype.hasOwnProperty("BS") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}{" "}
                        Bunker Supplier
                      </td>
                    </tr>

                    <tr>
                      <td>
                        {formReportdata.companytype.hasOwnProperty("W") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}{" "}
                        My Company
                      </td>
                      <td>
                        {formReportdata.companytype.hasOwnProperty("Z") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}{" "}
                        Own Company Branch
                      </td>
                      <td>
                        {formReportdata.companytype.hasOwnProperty("SH") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}{" "}
                        Shipper ( Supplier )
                      </td>
                      <td>
                        {formReportdata.companytype.hasOwnProperty("OW") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}{" "}
                        Ship Owner
                      </td>

                      <td>
                        {formReportdata.companytype.hasOwnProperty("M") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}
                        Ship Management
                      </td>
                      <td>
                        {formReportdata.companytype.hasOwnProperty("T") ? (
                          <Checkbox disabled checked="true" />
                        ) : (
                          <Checkbox disabled />
                        )}
                        Misc. Company
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h4 className="font-weight-bold tc-sub-header">
                  Bank & Account Details
                </h4>
                <table className="table custom-table-bordered tc-table">
                  <thead>
                    <tr className="HeaderBoxText text-white">
                      <th>Benificiary Name</th>
                      <th>Benificiary Bank</th>
                      <th>Branch</th>
                      <th>Acc. No.</th>
                      <th>Swift Code</th>
                      <th>Correspondent Bank</th>
                      <th>CB Swift Code</th>
                      <th>IBAN</th>
                      <th>International Bank Name</th>
                      <th>Country</th>
                      {/* <th>Payment Method</th>
                      <th>Account Type</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata["bank&accountdetails"] &&
                    formReportdata["bank&accountdetails"].length > 0
                      ? formReportdata["bank&accountdetails"].map((e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.benificiary_name}</td>
                                <td>
                                  {e.benificiary_bank
                                    ? e.benificiary_bank
                                    : "N/A"}
                                </td>
                                <td>{e.branch}</td>
                                <td>{e.account_no}</td>
                                <td>{e.swift_code}</td>
                                <td>
                                  {e.correspondent_bank
                                    ? e.correspondent_bank
                                    : "N/A"}
                                </td>
                                <td>{e.cb_swift_code}</td>
                                <td>{e.iban}</td>
                                <td>{e.ib_name}</td>
                                <td>{e.b_country ? e.b_country : "N/A"}</td>
                                {/* <td>{e.pay_method}</td>
                            <td>{e.account_type?e.account_type:"N/A"}</td> */}
                              </tr>
                            </>
                          );
                        })
                      : undefined}
                  </tbody>
                </table>

                <h4 className="font-weight-bold tc-sub-header">Contacts</h4>
                <table className="table custom-table-bordered tc-table">
                  <thead>
                    <tr className="HeaderBoxText text-white">
                      <th>Name</th>
                      <th>Director No</th>
                      <th>Fax No</th>
                      <th>Home No</th>
                      <th>Mobile No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.contacts &&
                    formReportdata.contacts.length > 0
                      ? formReportdata.contacts.map((e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.company_name}</td>
                                <td>{e.director_no}</td>
                                <td>{e.fax_no}</td>
                                <td>{e.home_no}</td>
                                <td>{e.mobile_no}</td>
                              </tr>
                            </>
                          );
                        })
                      : undefined}
                  </tbody>
                </table>

                <h4 className="font-weight-bold tc-sub-header">
                  Additional Information
                </h4>
                <table className="table custom-table-bordered tc-table">
                  <thead>
                    <tr className="HeaderBoxText text-white">
                      <th>Name</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.additionalinformation &&
                    formReportdata.additionalinformation.length > 0
                      ? formReportdata.additionalinformation.map((e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.ai_name ? e.ai_name : "N/A"}</td>
                                <td>
                                  {e.ai_description ? e.ai_description : "N/A"}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : undefined}
                  </tbody>
                </table>

                <h4 className="font-weight-bold tc-sub-header">Sub Company</h4>
                <table className="table custom-table-bordered tc-table">
                  <thead>
                    <tr className="HeaderBoxText text-white">
                      <th>Short Code</th>
                      <th>Company Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.subcompany &&
                    formReportdata.subcompany.length > 0
                      ? formReportdata.subcompany.map((e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.short_code}</td>
                                <td>{e.compny_name}</td>
                              </tr>
                            </>
                          );
                        })
                      : undefined}
                  </tbody>
                </table>

                <h4 className="font-weight-bold tc-sub-header">
                  Account Information
                </h4>
                <table className="table custom-table-bordered tc-table">
                  <thead>
                    <tr className="HeaderBoxText text-white">
                      <th>Currency</th>
                      <th>Acct. No.</th>
                      <th>Swift Code</th>
                      <th>Correspondent Bank</th>
                      <th>CB Swift Code</th>
                      <th>Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.accountinformation &&
                    formReportdata.accountinformation.length > 0
                      ? formReportdata.accountinformation.map((e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.currency ? e.currency : "N/A"}</td>
                                <td>{e.acc_no}</td>
                                <td>{e.swift_code}</td>
                                <td>{e.cor_bank ? e.cor_bank : "N/A"}</td>
                                <td>{e.cb_s_code}</td>
                                <td>{e["active"] == 1 ? "Yes" : "No"}</td>
                              </tr>
                            </>
                          );
                        })
                      : undefined}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

class NewCompanyReport extends Component {
  constructor() {
    super();
    this.state = {
      name: "Printer",
      pdfData: "",
      userInput: "",
      emailModal: false,
      loading: false,
      mailTitlePayload: {},
    };
  }

  printReceipt() {
    window.print();
  }

  sendEmailReportModal = async () => {
    try {
      this.setState({ loading: true });

      const quotes = document.getElementById("divToPrint");

      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }

      // Create Blob
      const blob = doc.output("blob");

      // Use the blob as needed (e.g., send it to the server, create a download link, etc.)

      this.setState({
        loading: false,
        pdfData: blob,
        emailModal: true,
      });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
      // Handle errors here
    }
  };

  printDocument() {
    var quotes = document.getElementById("divToPrint");

    html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
      scale: 2,
    }).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 25;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("Address-Company.pdf");
    });
  }

  render() {
    return (
      <div className="body-wrapper modalWrapper">
        <article className="article toolbaruiWrapper">
          <div className="box box-default">
            <div className="box-body">
              <div className="toolbar-ui-wrapper">
                <div className="leftsection"></div>
                <div className="rightsection">
                  <span className="wrap-bar-menu">
                    <ul className="wrap-bar-ul">
                      <li
                        onClick={this.sendEmailReportModal}
                        style={{
                          cursor: this.state.loading
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        Send Email
                      </li>

                      <li onClick={this.printDocument}>Download</li>
                      <li>
                        <ReactToPrint
                          trigger={() => (
                            <span className="text-bt">
                              <PrinterOutlined /> Print
                            </span>
                          )}
                          content={() => this.componentRef}
                        />
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <ComponentToPrint
                ref={(el) => (this.componentRef = el)}
                data={this.props.data}
              />
            </div>
          </div>
        </article>

        {this.state.emailModal && (
          <Modal
            title="New Message"
            visible={this.state.emailModal}
            onOk={() => {
              this.setState({ emailModal: false });
            }}
            onCancel={() => {
              this.setState({ emailModal: false });
            }}
            footer={null}
          >
            {this.state.pdfData && (
              <Email
                handleClose={() => {
                  this.setState((prevState) => ({
                    emailModal: false,
                  }));
                }}
                attachmentFile={this.state.pdfData}
                title={window.corrector(
                  `Address_List_Report||${this.props.data.comp_full_name}`
                )}

                // title={`Address_List_Report|| ${this.props.data.comp_full_name}`}
              />
            )}
          </Modal>
        )}
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    );
  }
}

export default NewCompanyReport;
