import React, { useEffect, useState, useRef } from "react";
import { Modal } from "antd";
import DownloadInvoice from "../chartering/routes/make-payment/DownloadInvoice";
import Remarks from "../../shared/components/Remarks";
import NormalFormIndex from "../../shared/NormalForm/normal_from_index";
import {
  DeleteOutlined,
  ReloadOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import URL_WITH_VERSION, {
  postAPICall,
  getAPICall,
  openNotificationWithIcon,
  objectToQueryStringFunc,
  useStateCallback,
} from "../../shared";
import TdeReport from "../operation-reports/TdeReport";
import { SaveOutlined, EditOutlined } from "@ant-design/icons";
import Attachment from "../../shared/components/Attachment";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../shared/attachments";
import moment from "moment";

const TDE = (props) => {
  const [refreshCounter, setRefreshCounter] = useState(0);

  const [state, setState] = useStateCallback({
    frmOptions: [],
    frmVisible: false,
    frmName: "tde_form",
    oldformData: props.formData || {},
    tdeData: {},
    formdata1: {},
    receivablePayableType: props.receivablePayableType || undefined,
    invoiceType: props.invoiceType || undefined,
    popupFroms: props.popupFroms || {},
    showTDEForm: false,
    modals: {
      DownloadInvoice: false,
      isShowTdeReport: false,
      estimateID: undefined,
    },
    portDetails: props.PortExpense || null,
    VoyID: props.vendor,
    msg: "",
    invoice_no: props.invoiceNo || null,
    isRemarkModel: false,
    tde_id: props?.formData.id || null,
  });
  let formdataref = useRef();

  useEffect(() => {
    getTdeData();
  }, []);

  const getTdeData = async () => {
    let { oldformData, modelVisible, invoice_no } = state;
    let target = null;
    let resp = null;
    let tdeData = null;

    if (invoice_no) {
      let amount = 0;
      let bill_via = null;
      let invoice_type = null;
      let invoice_date = null;
      let received_date = null;
      let vessel = null;
      let vendor = null;
      let inv_status = null;
      let invoice_amount = null;
      let account_base = null;
      let ar_pr_account_no = null;
      let voyage_manager_id = null;
      let po_number = null;
      let invoice = null;
      let payment_term = null;
      let voyage = null;
      let total = null;
      const response = await getAPICall(`${URL_WITH_VERSION}/tde/list`);
      let respData = response["data"];

      let responseAddressData = null;
      let voyageData = null;

      if (props.invoiceType === "other-expense") {
        let voyage = await getAPICall(
          `${URL_WITH_VERSION}/voyage-manager/edit?ae=${oldformData.estimate_id}`
        );
        voyageData = voyage["data"];

        const responseData = await getAPICall(
          `${URL_WITH_VERSION}/address/edit?ae=${oldformData.vendor}`
        );
        responseAddressData = responseData["data"];
      } else if (props.invoiceType === "DeviationInvoice") {
        let voyage = await getAPICall(
          `${URL_WITH_VERSION}/voyage-manager/edit?ae=${oldformData.voyage}`
        );
        voyageData = voyage["data"];

        const responseData = await getAPICall(
          `${URL_WITH_VERSION}/address/edit?ae=${oldformData.counter_party}`
        );
        responseAddressData = responseData["data"];
      } else if (props.invoiceType === "hire_payable") {
        const responseData = await getAPICall(
          `${URL_WITH_VERSION}/address/edit?ae=${oldformData.charterer_from}`
        );
        responseAddressData = responseData["data"];
      } else if (props.invoiceType === "portExpense") {
        let voyage = await getAPICall(
          `${URL_WITH_VERSION}/voyage-manager/edit?ae=${oldformData.voyage_manager_id}`
        );
        voyageData = voyage["data"];

        const responseData = await getAPICall(
          `${URL_WITH_VERSION}/address/edit?ae=${oldformData.agent_full_name}`
        );
        responseAddressData = responseData["data"];
      } else if (props.invoiceType === "FreightInvoice") {
        let voyage = await getAPICall(
          `${URL_WITH_VERSION}/voyage-manager/edit?ae=${oldformData.voy_no}`
        );
        voyageData = voyage["data"];

        const responseData = await getAPICall(
          `${URL_WITH_VERSION}/address/edit?ae=${oldformData.counterparty}`
        );
        responseAddressData = responseData["data"];
      } else if (props.invoiceType === "commission_invoice") {
        const responseData = await getAPICall(
          `${URL_WITH_VERSION}/address/edit?ae=${oldformData.broker}`
        );
        responseAddressData = responseData["data"];

        let voyage = await getAPICall(
          `${URL_WITH_VERSION}/voyage-manager/edit?ae=${oldformData.voyage_id_name}`
        );
        voyageData = voyage["data"];
      } else if (props.invoiceType === "bunker-invoice") {
        const responseData = await getAPICall(
          `${URL_WITH_VERSION}/address/edit?ae=${oldformData.my_company}`
        );
        responseAddressData = responseData["data"];

        let voyage = await getAPICall(
          `${URL_WITH_VERSION}/voyage-manager/edit?ae=${oldformData.voyage_no}`
        );
        voyageData = voyage["data"];
      }

      let account_no =
        responseAddressData &&
        responseAddressData["bank&accountdetails"] &&
        responseAddressData["bank&accountdetails"].length > 0
          ? responseAddressData["bank&accountdetails"][0] &&
            responseAddressData["bank&accountdetails"][0]["account_no"]
          : "";

      let swift_code =
        responseAddressData &&
        responseAddressData["bank&accountdetails"] &&
        responseAddressData["bank&accountdetails"].length > 0
          ? responseAddressData["bank&accountdetails"][0] &&
            responseAddressData["bank&accountdetails"][0]["swift_code"]
          : "";

      if (respData && respData.length > 0) {
        target = respData.find((item) => item.invoice_no === invoice_no);

        if (target && target.hasOwnProperty("id") && target["id"] > 0) {
          resp = await getAPICall(
            `${URL_WITH_VERSION}/tde/edit?e=${target["id"]}`
          );

          setState((prev) => ({ ...prev, tde_id: target["id"] }));
        }
      }

      let accounting = [];
      if (target && resp && resp["data"] && resp["data"].hasOwnProperty("id")) {
        tdeData = resp["data"];

        if (props.invoiceType === "portExpense") {
          if (oldformData["invoice_type"] == 209) {
            amount = `${oldformData["agreed_est_amt"]}`;
          } else if (oldformData["invoice_type"] == 208) {
            amount = `${oldformData["total_amt"]}`;
          } else if (oldformData["invoice_type"] == 210) {
            amount = `${oldformData["total_amt"]}`;
          }

          accounting = [
            {
              company: voyageData["my_company_lob"],
              vessel_name: voyageData["vessel_id"],
              voyage: oldformData["voyage_manager_id"],
              ap_ar_acct: account_no,
              vessel_code: oldformData["vessel_code"],
              amount: amount,
              description: `port-expense@${amount}`,
              lob: voyageData.company_lob,
              account: swift_code,
              port: oldformData["port"],
              id: -9e6,
            },
          ];

          tdeData["accounting"] = accounting;
          tdeData["--"] = { total: target["invoice_amount"] };

          let rem_data = null;

          if (resp && resp["data"] && resp["data"]["----"]) {
            rem_data = resp["data"]["----"][0];
            const value = tdeData["--"]["total"] - rem_data["total"];
            if (tdeData["paymententrytable"][0]["payment_status"] === 208) {
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: value,
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            } else {
              tdeData["----"]["total_due"] = tdeData["account_base"];
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: tdeData["account_base"],
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            }
          }

          setState((prevState) => ({
            ...prevState,
            tdeData: Object.assign({}, tdeData),
            frmVisible: true,
          }));
        } else if (props.invoiceType === "other-expense") {
          tdeData["voyage_manager_id"] = tdeData["voy_id"] || tdeData["voyage"];

          if (
            oldformData.otherrevenueexpenses &&
            oldformData.otherrevenueexpenses.length > 0
          ) {
            oldformData.otherrevenueexpenses.map((e, index) => {
              accounting.push({
                vessel_name: oldformData["vessel"],
                voyage: oldformData["estimate_id"],
                ap_ar_acct: tdeData["ar_pr_account_no"],
                account: e.code,
                vessel_code:
                  voyageData && voyageData.hasOwnProperty("vessel_code")
                    ? voyageData["vessel_code"]
                    : undefined,
                company: oldformData["bill_via"],
                amount: e.amount,
                description: e.description,
                port: e.port_name,
                lob:
                  voyageData && voyageData.hasOwnProperty("company_lob")
                    ? voyageData["company_lob"]
                    : undefined,
                id: -9e6 + index,
              });
              return true;
            });
          }

          tdeData["accounting"] = accounting;
          tdeData["--"] = { total: target["invoice_amount"] };

          let rem_data = null;

          if (resp && resp["data"] && resp["data"]["----"]) {
            rem_data = resp["data"]["----"][0];
            const value = tdeData["--"]["total"] - rem_data["total"];
            if (tdeData["paymententrytable"][0]["payment_status"] === 208) {
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: value,
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            } else {
              tdeData["----"]["total_due"] = tdeData["account_base"];
              // if (rem_data !== null) {
              //   tdeData["----"] = {
              //     total_due: tdeData["account_base"],
              //     total: rem_data[0]["total"],
              //     remittance_bank: rem_data[0]["remittance_bank"],
              //   };
              // }

              if (
                rem_data !== null &&
                rem_data.length > 0 &&
                rem_data[0]["total"] !== undefined
              ) {
                tdeData["----"] = {
                  total_due: tdeData["account_base"],
                  total: rem_data[0]["total"],
                  remittance_bank: rem_data[0]["remittance_bank"],
                };
              } else {
                // Handle case where rem_data is empty or does not contain expected data
                console.error(
                  "rem_data is empty or does not contain 'total' property."
                );
              }
            }
          }

          setState((prevState) => ({
            ...prevState,
            tdeData: Object.assign({}, tdeData),
            frmVisible: true,
          }));
        } else if (props.invoiceType === "DeviationInvoice") {
          oldformData &&
            oldformData["description"] &&
            oldformData["description"].length > 0 &&
            oldformData["description"].map((val, ind) => {
              accounting.push({
                company: target["bill_via"],
                amount: val.final_amt,

                lob: voyageData["company_lob"],
                vessel_code: oldformData["vessel_code"],
                description: val.activity_name,

                editable: true,
                vessel_name: oldformData["vessel"],
                account: swift_code,
                ap_ar_acct: account_no,
                key: "table_row_" + ind,
                voyage: oldformData["voyage"],
                id: -9e6 + ind,
              });
              return true;
            });

          tdeData["accounting"] = accounting;
          tdeData["--"] = { total: target["invoice_amount"] };

          let rem_data = null;

          if (resp && resp["data"] && resp["data"]["----"]) {
            rem_data = resp["data"]["----"][0];
            const value = tdeData["--"]["total"] - rem_data["total"];
            if (tdeData["paymententrytable"][0]["payment_status"] === 208) {
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: value,
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            } else {
              tdeData["----"]["total_due"] = tdeData["account_base"];
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: tdeData["account_base"],
                  total: rem_data[0]["total"],
                  remittance_bank: rem_data[0]["remittance_bank"],
                };
              }
            }
          }

          setState((prevState) => ({
            ...prevState,
            tdeData: Object.assign({}, tdeData),
            frmVisible: true,
          }));
        } else if (props.invoiceType === "hire_payable") {
          if (Array.isArray(tdeData["----"]) && tdeData["----"].length > 0) {
            tdeData["----"] = tdeData["----"][0];
          }

          let vessel_lobdata = JSON.parse(localStorage.getItem("tco_id"));

          oldformData &&
            oldformData["-"] &&
            oldformData["-"].length > 0 &&
            oldformData["-"].map((val, ind) => {
              accounting.push({
                company: target["bill_via"],
                amount: val.amount,
                lob: vessel_lobdata?.["company_lob"],
                vessel_code: vessel_lobdata?.["vessel_code"],
                description: val.description,
                editable: true,
                vessel_name: oldformData["vessel_id"],
                account: swift_code,
                ap_ar_acct: target["ar_pr_account_no"],
                key: "table_row_" + ind,
                voyage: target["voyage_manager_id"],
                port: "",
                ic: "",
                id: -9e6 + ind,
              });
              return true;
            });

          tdeData["accounting"] = accounting;
          tdeData["--"] = { total: target["invoice_amount"] };

          let rem_data = null;

          if (resp && resp["data"] && resp["data"]["----"]) {
            rem_data = resp["data"]["----"];
            const value =
              parseFloat(tdeData["--"]["total"]) -
              parseFloat(rem_data["total"]);
            if (tdeData["paymententrytable"][0]["payment_status"] === 208) {
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: value,
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            } else {
              tdeData["----"]["total_due"] = tdeData["account_base"];
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: tdeData["account_base"],
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            }
          }

          setState((prevState) => ({
            ...prevState,
            tdeData: Object.assign({}, tdeData),
            frmVisible: true,
          }));
        } else if (props.invoiceType === "FreightInvoice") {
          if (oldformData["-----"] && oldformData["-----"].length > 0) {
            oldformData["-----"].map((e, index) =>
              accounting.push({
                description: e["description"],
                amount: e["amount"],
                account: e["fc_heads"],
                company: target["bill_via"],
                lob:
                  voyageData && voyageData.hasOwnProperty("company_lob")
                    ? voyageData["company_lob"]
                    : undefined,
                vessel_code:
                  voyageData && voyageData.hasOwnProperty("vessel_code")
                    ? voyageData["vessel_code"]
                    : undefined,
                vessel_name: target["vessel"],
                port: "select port",
                ap_ar_acct: target["ar_pr_account_no"],
                voyage: oldformData["voy_no"],
                id: -9e6 + index,
              })
            );
          }

          tdeData["accounting"] = accounting;
          tdeData["--"] = { total: target["invoice_amount"] };

          let rem_data = null;

          if (resp && resp["data"] && resp["data"]["----"]) {
            rem_data = resp["data"]["----"][0];
            const value =
              parseFloat(tdeData["--"]["total"]) -
              parseFloat(rem_data["total"]);
            if (tdeData["paymententrytable"][0]["payment_status"] === 208) {
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: value,
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            } else {
              tdeData["----"]["total_due"] = resp["data"]["account_base"];
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: resp["data"]["account_base"],
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            }
          }

          setState((prevState) => ({
            ...prevState,
            tdeData: Object.assign({}, tdeData),
            frmVisible: true,
          }));
        } else if (props.invoiceType === "commission_invoice") {
          // tdeData["ap_ar_acct"] = account_no;
          // tdeData["lob"] =
          //   voyageData && voyageData.hasOwnProperty("company_lob")
          //     ? voyageData["company_lob"]
          //     : undefined;
          tdeData["accounting"] = accounting;
          tdeData["--"] = { total: target["invoice_amount"] };

          let rem_data = null;

          if (resp && resp["data"] && resp["data"]["----"]) {
            rem_data = resp["data"]["----"][0];
            const value = tdeData["--"]["total"] - rem_data["total"];
            if (tdeData["paymententrytable"][0]["payment_status"] === 208) {
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: value,
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            } else {
              tdeData["----"]["total_due"] = tdeData["account_base"];
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: tdeData["account_base"],
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            }
          }

          setState((prevState) => ({
            ...prevState,
            tdeData: Object.assign({}, tdeData),
            frmVisible: true,
          }));
        } else if (props.invoiceType === "bunker-invoice") {
          if (oldformData["...."] && oldformData["...."].length > 0) {
            oldformData["...."].map((e, index) => {
              accounting.push({
                vessel_name: tdeData["vessel"],
                voyage: voyageData["voyage_number"],
                ap_ar_acct: tdeData["ar_pr_account_no"],
                vessel_code: voyageData["vessel_code"],
                company: tdeData["bill_via"],
                amount: e.invoice_total,
                description: `bunker-invoive @ ${e.invoice_total}`,
                lob:
                  voyageData && voyageData.hasOwnProperty("company_lob")
                    ? voyageData["company_lob"]
                    : undefined,
                port: voyageData["b_port_name"],
                id: -9e6 + index,
              });
              return true;
            });
          }

          tdeData["accounting"] = accounting;
          tdeData["--"] = { total: target["invoice_amount"] };

          let rem_data = null;

          if (resp && resp["data"] && resp["data"]["----"]) {
            rem_data = resp["data"]["----"][0];
            const value =
              parseFloat(tdeData["--"]["total"]) -
              parseFloat(rem_data["total"]);
            if (tdeData["paymententrytable"][0]["payment_status"] === 208) {
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: value,
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            } else {
              tdeData["----"]["total_due"] = resp["data"]["account_base"];
              if (rem_data !== null) {
                tdeData["----"] = {
                  total_due: resp["data"]["account_base"],
                  total: rem_data["total"],
                  remittance_bank: rem_data["remittance_bank"],
                };
              }
            }
          }

          setState((prevState) => ({
            ...prevState,
            tdeData: Object.assign({}, tdeData),
            frmVisible: true,
          }));
        }
      } else {
        let frmData = {};
        let accounting = [];
        if (props.invoiceType === "portExpense") {
          if (oldformData["invoice_type"] == 209) {
            amount = `${oldformData["agreed_est_amt"]}`;
          } else if (oldformData["invoice_type"] == 208) {
            amount = `${oldformData["total_amt"]}`;
          } else if (oldformData["invoice_type"] == 210) {
            amount =
              oldformData["--"] && oldformData["--"].length > 0
                ? oldformData["--"]
                    .reduce((total, e) => total + Number(e.fda_amount), 0)
                    .toFixed(2)
                : 0;
            // amount = `${formData["total_amt"]}`;
          }

          accounting = [
            {
              company: voyageData["my_company_lob"],
              vessel_name: voyageData["vessel_id"],
              voyage: oldformData["voyage_manager_id"],
              ap_ar_acct: account_no,
              vessel_code: oldformData["vessel_code"],
              amount: amount,
              description: `port-expense@${amount}`,
              lob: voyageData.company_lob,
              account: swift_code,
              port: oldformData["port"],
              id: -9e6,
            },
          ];

          bill_via = voyageData["my_company_lob"];
          invoice_no = invoice_no;
          invoice_type = oldformData["invoice_type"];
          invoice_date =
            oldformData["invoice_type"] == 209
              ? oldformData["pda_inv_date"]
              : oldformData["fda_inv_date"];
          received_date =
            oldformData["invoice_type"] == 209
              ? oldformData["adavnce_sent_date"]
              : oldformData["fda_sent_date"];
          vessel = voyageData["vessel_id"];
          vendor = oldformData["agent_full_name"];

          inv_status =
            oldformData["invoice_type"] == 209
              ? oldformData["pda_adv_status"]
              : oldformData["fda_status"];
          invoice_amount = amount;
          account_base = amount;
          ar_pr_account_no = account_no;
          voyage_manager_id = voyageData["id"];
          po_number = oldformData["po_no"];
          total = oldformData["invoice_total"];

          frmData = {
            bill_via,
            invoice_no,
            invoice_type,
            invoice_date,
            received_date,
            vessel,
            vendor,
            inv_status,
            invoice_amount,
            account_base,
            ar_pr_account_no,
            voyage_manager_id,
            po_number,
            payment_term,
            voyage,
            accounting: [],
          };

          frmData["accounting"] = accounting;
          frmData["--"] = {
            total: oldformData["agreed_est_amt"],
          };
          frmData["----"] = {
            total_due: oldformData["agreed_est_amt"],
          };

          formdataref.current = frmData;
          setState(() => ({ ...state, tdeData: frmData, frmVisible: true }));
        } else if (props.invoiceType === "other-expense") {
          bill_via = oldformData["bill_via"];
          invoice = oldformData["acc_type"];
          invoice_date = oldformData["due_date"];
          invoice_type = oldformData["invoice_type"];
          vessel = oldformData["vessel"];
          vendor = oldformData["vendor"];
          voyage_manager_id = oldformData["voyage_manager_id"];
          inv_status = oldformData["invoice_status"];
          invoice_amount =
            oldformData["....."] && oldformData["....."]["total_amount"];
          account_base =
            oldformData["....."] && oldformData["....."]["total_amount"];
          ar_pr_account_no = account_no;
          //voy_no= oldformData["voyage_manager_id"];
          po_number = oldformData["po_number"];
          received_date = oldformData["recieved_date"]
            ? oldformData["recieved_date"]
            : oldformData["claim_date"];
          payment_term = oldformData["payment_terms"];

          if (
            oldformData.otherrevenueexpenses &&
            oldformData.otherrevenueexpenses.length > 0
          ) {
            oldformData.otherrevenueexpenses.map((e, index) => {
              accounting.push({
                vessel_name: oldformData["vessel"],
                voyage: voyageData["voyage_number"],
                ap_ar_acct: account_no,
                vessel_code: voyageData["vessel_code"],
                company: oldformData["bill_via"],
                amount: e.amount,
                description: e.description,
                port: e.port_name,
                lob: voyageData["company_lob"],
                account: e.code,
                id: -9e6 + index,
              });
              return true;
            });
          }
          frmData["voyID"] = oldformData["estimate_id"];

          frmData = {
            bill_via,
            invoice_no,
            invoice_type,
            invoice_date,
            received_date,
            vessel,
            vendor,
            inv_status,
            invoice_amount,
            account_base,
            ar_pr_account_no,
            voyage_manager_id,
            po_number,
            payment_term,
            voyage,
            accounting: [],
          };

          frmData["accounting"] = accounting;
          frmData["--"] = {
            total: oldformData["....."]["total_amount"],
          };
          frmData["----"] = {
            total_due: oldformData["....."]["total_amount"],
          };

          formdataref.current = frmData;
          setState(() => ({ ...state, tdeData: frmData, frmVisible: true }));
        } else if (props.invoiceType === "DeviationInvoice") {
          oldformData &&
            oldformData["description"] &&
            oldformData["description"].length > 0 &&
            oldformData["description"].map((val, ind) => {
              accounting.push({
                company: voyageData["my_company_lob"],
                account: swift_code,
                lob: voyageData["company_lob"],
                vessel_code: oldformData["vessel_code"],
                description: val.activity_name,

                vessel_name: oldformData["vessel"],
                amount: val.final_amt,
                ap_ar_acct: account_no,
                voyage: oldformData["voyage"],
                id: -9e6 + ind,
              });
              return true;
            });

          invoice = oldformData["account_type"];
          po_number = oldformData["po_number"];
          invoice_date = oldformData["due_date"];
          received_date = oldformData["received_date"];
          //invoice_no= oldformData.inv_no,
          ar_pr_account_no = account_no;
          inv_status = oldformData.inv_status;
          invoice_type = oldformData.inv_type;
          voyage_manager_id = voyageData.id;
          vendor = ""; // no vendor exist in offhire deviation
          vessel = oldformData["vessel"];
          invoice_amount = oldformData.total;
          bill_via = oldformData.my_company;
          account_base = oldformData.total;
          payment_term = oldformData.terms;

          frmData = {
            bill_via,
            invoice_no,
            invoice_type,
            invoice_date,
            received_date,
            vessel,
            vendor,
            inv_status,
            invoice_amount,
            account_base,
            ar_pr_account_no,
            voyage_manager_id,
            po_number,
            payment_term,
            voyage,
            accounting: [],
          };

          frmData["accounting"] = accounting;
          frmData["--"] = {
            total: oldformData["total"],
          };
          frmData["----"] = {
            total_due: oldformData["total"],
          };

          formdataref.current = frmData;
          setState(() => ({ ...state, tdeData: frmData, frmVisible: true }));
        } else if (props.invoiceType === "hire_payable") {
          let vessel_lobdata = JSON.parse(localStorage.getItem("tco_id"));

          oldformData &&
            oldformData["-"] &&
            oldformData["-"].length > 0 &&
            oldformData["-"].map((val, ind) => {
              accounting.push({
                company: oldformData["my_company"],
                account: swift_code,
                lob: vessel_lobdata?.["company_lob"],
                vessel_code: vessel_lobdata?.["vessel_code"],
                description: val.description,
                vessel_name: oldformData["vessel_id"],
                amount: val.amount,
                ap_ar_acct: account_no,
                voyage: oldformData["voyage_manager_id"],
                port: "",
                ic: "",
                id: -9e6 + ind,
              });
              return true;
            });

          invoice = oldformData["acc_type"];
          vessel = oldformData["vessel_id"];
          po_number = oldformData["po_number"];
          invoice_date = oldformData["due_date"];
          received_date = oldformData["received_date"];
          ar_pr_account_no = account_no;
          inv_status = oldformData.payment_status;
          invoice_type = "";
          vendor = oldformData.charterer_from;
          invoice_amount = oldformData.amount;
          bill_via = oldformData.my_company;
          account_base = oldformData.amount;
          payment_term = oldformData.terms;

          frmData = {
            bill_via,
            invoice_no,
            invoice_type,
            invoice_date,
            received_date,
            vessel,
            vendor,
            inv_status,
            invoice_amount,
            account_base,
            ar_pr_account_no,
            voyage_manager_id,
            po_number,
            payment_term,
            voyage,
            accounting: [],
          };

          frmData["accounting"] = accounting;
          frmData["--"] = {
            total: oldformData["amount"],
          };
          frmData["----"] = {
            total_due: oldformData["amount"],
          };

          formdataref.current = frmData;
          setState(() => ({ ...state, tdeData: frmData, frmVisible: true }));
        } else if (props.invoiceType === "FreightInvoice") {
          if (oldformData["-----"] && oldformData["-----"].length > 0) {
            oldformData["-----"].map((e, index) =>
              accounting.push({
                id: -9e6 + index,
                description: e["description"],
                amount: e["amount"],
                account: e["fc_heads"],
                company: oldformData["my_company"],
                lob:
                  voyageData && voyageData.hasOwnProperty("company_lob")
                    ? voyageData["company_lob"]
                    : undefined,
                vessel_code:
                  voyageData && voyageData.hasOwnProperty("vessel_code")
                    ? voyageData["vessel_code"]
                    : undefined,
                vessel_name: oldformData["vessel"],
                port: "select port",
                ap_ar_acct: account_no,
                voyage: oldformData["voy_no"],
                id: -9e6 + index,
              })
            );
          }
          invoice = oldformData["acc_type"];
          invoice_date = oldformData["due_date"];
          invoice_type = oldformData["invoice_type"];
          po_number = oldformData["po_number"];
          received_date = oldformData["received_date"];
          payment_term = oldformData["payment_terms"];
          vessel = oldformData["vessel"];
          vendor = oldformData["counterparty"];
          bill_via = oldformData["my_company"];
          voyage = oldformData["voy_no"];
          voyage_manager_id = oldformData["voyage_id"];
          invoice_amount = oldformData["invoice_total"];
          account_base = oldformData["invoice_total"];
          ar_pr_account_no = account_no;
          inv_status = oldformData["fia_status"];

          frmData = {
            bill_via,
            invoice_no,
            invoice_type,
            invoice_date,
            received_date,
            vessel,
            vendor,
            inv_status,
            invoice_amount,
            account_base,
            ar_pr_account_no,
            voyage_manager_id,
            po_number,
            payment_term,
            voyage,
            accounting: [],
          };

          frmData["accounting"] = accounting;
          frmData["--"] = {
            total: oldformData["total_amount"],
          };
          frmData["----"] = {
            total_due: oldformData["total_amount"],
          };

          formdataref.current = frmData;
          setState(() => ({ ...state, tdeData: frmData, frmVisible: true }));
        } else if (props.invoiceType === "commission_invoice") {
          accounting.push({
            //id: -9e6 + index,
            description: oldformData["..."][0].description,
            amount: oldformData.total_amount,
            account: swift_code,
            company: voyageData.my_company_lob && voyageData.my_company_lob,
            lob:
              voyageData && voyageData.hasOwnProperty("company_lob")
                ? voyageData["company_lob"]
                : undefined,
            vessel_code: oldformData["vessel_code"],
            vessel_name: oldformData["vessel_name"],
            port: "select port",
            ap_ar_acct: account_no,
            voyage: oldformData["voyage_id_name"],
          });

          ar_pr_account_no = account_no;
          invoice = oldformData["acc_type"];
          invoice_date = oldformData["due_date"];
          invoice_type = oldformData["invoice_type"];
          po_number = oldformData["po_number"];
          received_date = oldformData["received_date"];
          payment_term = oldformData["payment_terms"];
          vessel = oldformData["vessel"];
          bill_via = voyageData.my_company_lob && voyageData.my_company_lob;
          voyage = oldformData["voyage_id_name"];
          voyage_manager_id = oldformData["voyage_id"];
          invoice_amount = oldformData["total_amount"];
          account_base = oldformData["total_amount"];

          frmData = {
            bill_via,
            invoice_no,
            invoice_type,
            invoice_date,
            received_date,
            vessel,
            vendor,
            inv_status,
            invoice_amount,
            account_base,
            ar_pr_account_no,
            voyage_manager_id,
            po_number,
            payment_term,
            voyage,
            accounting: [],
          };

          frmData["accounting"] = accounting;
          frmData["--"] = {
            total: oldformData?.total_amount ?? 0,
          };
          frmData["----"] = {
            total_due: oldformData?.total_amount ?? 0,
          };

          formdataref.current = frmData;
          setState(() => ({ ...state, tdeData: frmData, frmVisible: true }));
        } else if (props.invoiceType === "bunker-invoice") {
          if (oldformData["...."] && oldformData["...."].length > 0) {
            oldformData["...."].map((e, index) => {
              accounting.push({
                vessel_name: oldformData["vessel"],
                voyage: oldformData["voyage_no"],
                ap_ar_acct: account_no,
                vessel_code: voyageData["vessel_code"],
                company: voyageData["my_company_lob"],
                amount: e.invoice_total,
                account: swift_code,
                description: `bunker-invoive @ ${e.invoice_total}`,
                port: oldformData["port_name"],
                lob:
                  voyageData && voyageData.hasOwnProperty("company_lob")
                    ? voyageData["company_lob"]
                    : undefined,
                id: -9e6 + index,
              });
              return true;
            });
          }
          bill_via = voyageData["my_company_lob"];
          invoice = oldformData["acc_type"];
          //invoice_no= formData["invoice_no"];
          invoice_date = oldformData["invoice_date"];
          received_date = oldformData["recieved_date"];
          vessel = oldformData["vessel"];
          vendor = oldformData["vendor"];
          voyage_manager_id = voyageData["id"];
          // inv_status: formData["inv_status_name"];
          inv_status = oldformData["invoice_status"];
          invoice_amount =
            oldformData["grand_total"] ||
            (oldformData["-"] && oldformData["-"]["grand_total"]);
          account_base =
            oldformData["grand_total"] ||
            (oldformData["-"] && oldformData["-"]["grand_total"]);
          ar_pr_account_no = account_no;
          //voy_no: formData["voyage_no"];
          po_number = oldformData["po_number"];
          payment_term = oldformData["payment_terms"];

          frmData = {
            bill_via,
            invoice_no,
            invoice_type,
            invoice_date,
            received_date,
            vessel,
            vendor,
            inv_status,
            invoice_amount,
            account_base,
            ar_pr_account_no,
            voyage_manager_id,
            po_number,
            payment_term,
            voyage,
            accounting: [],
          };

          frmData["accounting"] = accounting;
          frmData["--"] = {
            total: oldformData["-"]["grand_total"],
          };
          frmData["----"] = {
            total_due: oldformData["-"]["grand_total"],
          };

          formdataref.current = frmData;
          setState(() => ({ ...state, tdeData: frmData, frmVisible: true }));
        }
      }
    } else if (props.invoiceType == "tc-commission") {
      setState((prevState) => ({
        ...prevState,
        tdeData: props.formData || {},
        frmVisible: true,
      }));
    } else if (props.invoiceType == "TDE") {
      setState((prevState) => ({
        ...prevState,
        tdeData: props.formData || {},
        frmVisible: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        msg: `Without "Invoice No" you cant't access the TDE Form`,
        modelVisible: !modelVisible,
        frmVisible: true,
      }));
    }
  };

  const editTdeData = async (id) => {
    try {
      const resp2 = await getAPICall(`${URL_WITH_VERSION}/tde/edit?e=${id}`);

      if (resp2 && resp2.data) {
        const setData = resp2.data;
        setData["--"] = { ...setData?.["--"]?.[0] };
        setData["----"] = { ...setData?.["----"]?.[0] };
        if (setData["paymententrytable"][0]["payment_status"] === 208) {
          let diff = setData["--"]["total"] - setData["----"]["total"];

          setData["----"] = {
            ...setData["----"],
            total_due: diff,
          };

          setState({ ...state, tdeData: setData, formVisible: true });
        } else {
          // setData["----"]["total_due"] = setData["account_base"];

          setData["----"] = {
            ...setData["----"],
            total_due: setData["account_base"],
          };

          setState({ ...state, tdeData: setData, formVisible: true });
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const saveFormData = (data) => {
    const currentDate = moment();
    const formattedDate = currentDate.format("YYYY-MM-DD");
    data["received_date"] = formattedDate;
    setState({ ...state, frmVisible: false });
    const { frmName } = state;
    let suMethod = "POST";
    let type = "save";
    let total_pay = 0;
    data["voyage"] = data["voyage_manager_id"];
    data["paymententrytable"].map((p) => {
      total_pay += Number(p.amount_base);
    });
    data["total_pay"] = total_pay;
    if (data.id) {
      suMethod = "PUT";
      type = "update";
    }

    let suURL = `${URL_WITH_VERSION}/tde/${type}?frm=${frmName}`;

    postAPICall(suURL, data, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message, 2);
        editTdeData(data.row.id);

        if (type === "save") {
          window.emitNotification({
            n_type: "TDE Created",
            msg: window.notificationMessageCorrector(
              `TDE is created, for Invoice number(${data.invoice_no}), VM id(${data.voyage_manager_id}), by ${window.userName}`
            ),
          });
        } else {
          window.emitNotification({
            n_type: "TDE Updated",
            msg: window.notificationMessageCorrector(
              `TDE is updated, for Invoice number(${data.invoice_no}), VM id(${data.voyage_manager_id}), by ${window.userName}`
            ),
          });
        }

        setState({ ...state, frmVisible: true });

        if (props.closeTde) {
          props.closeTde();
        }

        // if (this.props.isEdit === false) {
        //   setTimeout(() => {
        //     this.props.saveUpdateClose(data.row);
        //   }, 3000);
        // }
        // if (props.hasOwnProperty("modalCloseEvent")) {
        //   setTimeout(() => {
        //     props.modalCloseEvent();
        //   }, 3000);
        // }
      } else {
        openNotificationWithIcon("error", data.message);
      }
    });
  };

  const _onDeleteFormData = (postData) => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => deleteFormData(postData),
    });
  };

  const deleteFormData = async (data) => {
    const { frmName, modelVisible } = state;
    setState({ ...state, frmVisible: false });

    let delete_data = {
      id: data.id,
    };
    let suURL = `${URL_WITH_VERSION}/tde/delete`;
    let suMethod = "delete";

    postAPICall(suURL, delete_data, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message);
        getTdeData();
        window.emitNotification({
          n_type: "TDE deleted",
          msg: window.notificationMessageCorrector(
            `TDE is deleted, for Invoice number(${delete_data.invoice_no}), VM id(${delete_data.voyage_manager_id}), by ${window.userName}`
          ),
        });
        // if (this.props.isEdit === true) {
        //   this.setState({ modelVisible: !modelVisible });
        //   this.props.saveUpdateClose();
        // }
        // if (this.props.hasOwnProperty("deleteTde")) {
        //   this.props.deleteTde();
        //   this.props.saveUpdateClose();
        // }
        // if (this.props.hasOwnProperty("modalCloseEvent")) {
        //   this.props.modalCloseEvent();
        // }
      } else {
        openNotificationWithIcon("error", data.message);
      }
    });
  };

  const resetFromData = () => {
    setRefreshCounter((prevCounter) => prevCounter + 1);
  };

  const resetform = () => {
    const { formdata1, invoiceType } = state;

    setState((prevState) => ({
      ...prevState,
      tdeData: Object.assign({}, formdataref.current),
      frmVisible: true,
    }));
  };

  const onClickExtraIcon = async (action, data) => {
    let groupKey = action["gKey"];
    let frm_code = "tde_form";
    let delete_id = data && data.id;
    if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
      let data1 = {
        id: delete_id,
        frm_code: frm_code,
        group_key: groupKey.replace(/\s/g, "").toLowerCase(),
      };
      postAPICall(
        `${URL_WITH_VERSION}/tr-delete`,
        data1,
        "delete",
        (response) => {
          if (response && response.data) {
            openNotificationWithIcon("success", response.message);
          } else {
            openNotificationWithIcon("error", response.message);
          }
        }
      );
    }
  };

  const ShowAttachment = async (isShowAttachment) => {
    let loadComponent = undefined;
    // const { tde_id } = state;
    const { tdeData } = state;

    if (tdeData.id && isShowAttachment) {
      const attachments = await getAttachments(tdeData.id, "EST");
      const callback = (fileArr) =>
        uploadAttachment(fileArr, tdeData.id, "EST", "port-expense");
      loadComponent = (
        <Attachment
          uploadType="Estimates"
          attachments={attachments}
          onCloseUploadFileArray={callback}
          deleteAttachment={(file) =>
            deleteAttachment(file.url, file.name, "EST", "port-expense")
          }
          tableId={0}
        />
      );
      setState((prevState) => ({
        ...prevState,
        isShowAttachment: isShowAttachment,
        loadComponent: loadComponent,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isShowAttachment: isShowAttachment,
        loadComponent: undefined,
      }));
    }
  };

  const handleRemark = () => {
    setState((prev) => ({
      ...prev,
      isRemarkModel: !prev.isRemarkModel,
    }));
  };

  return (
    <div className="modalWrapper">
      <article className="article toolbaruiWrapper">
        <div className="box box-default">
          <div className="box-body" key={refreshCounter}>
            {state.frmVisible ? (
              <NormalFormIndex
                key={"key_" + state.frmName + "_0"}
                formClass="label-min-height"
                formData={state.tdeData}
                //   formData={formdataref.current}
                showForm={true}
                frmCode={state.frmName}
                addForm={true}
                frmOptions={state.frmOptions}
                showButtons={
                  [
                    // { "id": "cancel", "title": "Reset", "type": "danger" },
                    // { "id": "save", "title": "Save", "type": "primary", "event": (data) => { this.saveFormData(data) } }
                  ]
                }
                showToolbar={[
                  {
                    isLeftBtn: [
                      {
                        key: "s1",
                        isSets: [
                          // { id: "0", key: "search", type: <SearchOutlined />, withText: "", event: (key, data) =>
                          {
                            id: "1",
                            key:
                              state.tdeData.tde_id === undefined
                                ? "save"
                                : "update ",
                            type: <SaveOutlined />,
                            withText:
                              state.tdeData.tde_id === undefined
                                ? "Save"
                                : "Update ",
                            showToolTip: true,
                            event: (key, data) => saveFormData(data),
                          },
                          {
                            id: "2",
                            key: state.tdeData.trans_no ? "delete" : "reset",
                            type: state.tdeData.trans_no ? (
                              <DeleteOutlined />
                            ) : (
                              <SyncOutlined />
                            ),
                            withText: state.tdeData.trans_no
                              ? "Delete"
                              : "Refresh",
                            showToolTip: true,
                            event: (key, data) => {
                              state.tdeData.trans_no
                                ? _onDeleteFormData(data)
                                : resetFromData(data);
                            },
                          },

                          // {
                          //   id: "3",
                          //     key: "edit",
                          //     type: <EditOutlined/>,
                          //     withText: "Remark",
                          //     showToolTip: true,
                          //     event: (key, data) => handleRemark(),
                          // },
                          // state.tdeData.trans_no&&(
                          // {
                          //   id: "4",
                          //     key:"reset",
                          //     type:state.tdeData.trans_no ? <SyncOutlined/>:null,
                          //     withText: "Refresh",
                          //     showToolTip: true,
                          //     event: (key, data) => resetFromData(),
                          // })

                          {
                            id: "3",
                            key: "edit",
                            type: <EditOutlined />,
                            withText: "Remark",
                            showToolTip: true,
                            event: (key, data) => {
                              if (data.id) {
                                handleRemark(data);
                              } else {
                                openNotificationWithIcon(
                                  "info",
                                  "Please save TDE first",
                                  2
                                );
                              }
                            },
                          },
                        ],
                      },
                    ],
                    isRightBtn: [
                      {
                        key: "s2",
                        isSets: [
                          // { key: "pay_current_invoice", isDropdown: 0, withText: "Pay Current Invoice", type: "", menus: null, "event": (key) => { console.log(key); } },
                          // { key: "attachments", isDropdown: 0, withText: "Attachments", type: "", menus: null, "event": (key) => { console.log(key); } },
                          // { key: "reverse", isDropdown: 0, withText: "Reverse", type: "", menus: null, "event": (key) => { console.log(key, true); } },
                          // {
                          //   key: 'report',
                          //   isDropdown: 0,
                          //   withText: 'Report',
                          //   type: '',
                          //   menus: null,
                          //   event: key => '',
                          // },
                          {
                            key: "attachment",
                            isDropdown: 0,
                            withText: "Attachment",
                            type: "",
                            menus: null,
                            event: (key, data) => {
                              data &&
                              data.hasOwnProperty("id") &&
                              data["id"] > 0
                                ? ShowAttachment(true)
                                : openNotificationWithIcon(
                                    "info",
                                    "Please Save TDE First.",
                                    3
                                  );
                            },
                          },
                        ],
                      },
                    ],
                    isResetOption: false,
                  },
                ]}
                inlineLayout={true}
                isShowFixedColumn={["Accounting", "Payment Entry Table"]}
                tableRowDeleteAction={(action, data) =>
                  onClickExtraIcon(action, data)
                }
              />
            ) : undefined}
          </div>
          <div className="box-body" />
        </div>
        {state.isShowAttachment ? (
          <Modal
            style={{ top: "2%" }}
            title="Upload Attachment"
            open={state.isShowAttachment}
            onCancel={() => ShowAttachment(false)}
            width="50%"
            footer={null}
          >
            {state.loadComponent}
          </Modal>
        ) : undefined}
      </article>

      {/* 
      <Modal
        style={{ top: "2%" }}
        title="Download Invoice"
        open={state.modals["DownloadInvoice"]}
        onCancel={() => showHideModal(false, "DownloadInvoice")}
        width="90%"
        footer={null}
      >
        <DownloadInvoice />
      </Modal> */}

      {/* {state.isShowTdeReport ? (
        <Modal
          style={{ top: "2%" }}
          title="Report"
          open={state.isShowTdeReport}
          onOk={handleOk}
          onCancel={() => TdeReport(false)}
          width="95%"
          footer={null}
        >
          <TdeReport data={reportFormData} />
        </Modal>
      ) : undefined} */}

      {state.modelVisible ? (
        <Modal
          title="Confirmation"
          open={state.modelVisible}
          onOk={() => {
            setState(
              (prevState) => ({
                ...prevState,
                frmVisible: false,
                modelVisible: !state.modelVisible,
              }),
              () => resetform()
            );
          }}
          onCancel={() =>
            setState({ ...state, modelVisible: !state.modelVisible })
          }
        >
          <p>{state.msg}</p>
        </Modal>
      ) : undefined}

      {state.isRemarkModel && (
        <Modal
          width={600}
          title="Remark"
          open={state.isRemarkModel}
          onOk={() => {
            setState({ isRemarkModel: true });
          }}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, isRemarkModel: false }))
          }
          footer={false}
        >
          <Remarks
            remarksID={state.tdeData.trans_no}
            remarkType="tde"
            idType="TDE"
          />
        </Modal>
      )}
    </div>
  );
};

export default TDE;
