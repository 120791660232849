import { Popover, Select } from 'antd'
import React from 'react'
import PopToolTip from './Tooltip';

const ToolTipPopover = ({ row, children }) => {
    if (row.group_name === 'Port Itinerary') {
        return children
    }

    return (
        <Popover
            placement="right"
            destroyTooltipOnHide={true}
            content={<PopToolTip text={row.f_tool_tip} title={row.name} />}
            overlayClassName="custom-tooltip"
        >
            {children}
        </Popover>
    )
}

export default ToolTipPopover