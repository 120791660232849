import { useState, forwardRef } from "react";
import { PrinterOutlined } from '@ant-design/icons';
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import moment from "moment";

import html2canvas from "html2canvas";
import { useRef } from "react";

const  ComponentToPrint = forwardRef((props, ref) => {

  const [formReportdata, setFormReportdata] = useState(Object.assign({}, props.data || {}))

    return (
      <article className="article toolbaruiWrapper" ref={ref}>
        <div className="box box-default" id="divToPrint">
          <div className="box-body">
            <table className="table table-bordered table-invoice-report-colum">
              <tbody>
                <tr>
                  <td className="font-weight-bold">Vessel Name:</td>
                  <td>
                    {formReportdata && formReportdata.vessel_name
                      ? formReportdata.vessel_name
                      : "N/A"}
                  </td>

                  <td className="font-weight-bold">My Company/LOB:</td>
                  <td>
                    {formReportdata && formReportdata.my_company_name
                      ? formReportdata.my_company_name
                      : "N/A"}
                  </td>

                  <td className="font-weight-bold">Status:</td>
                  <td>
                    {formReportdata && formReportdata.v_status_name
                      ? formReportdata.v_status_name
                      : "N/A"}
                  </td>
                </tr>

                <tr>
                  <td className="font-weight-bold">Vessel File code:</td>
                  <td>
                    {formReportdata && formReportdata.vessel_code
                      ? formReportdata.vessel_code
                      : "N/A"}
                  </td>

                  <td className="font-weight-bold">Fixed By User:</td>
                  <td>
                    {formReportdata && formReportdata.fixed_by_name
                      ? formReportdata.fixed_by_name
                      : "N/A"}
                  </td>

                  <td className="font-weight-bold">Commence date:</td>
                  <td>
                    {formReportdata && formReportdata.commence
                      ?  moment(formReportdata.commence).format("YYYY-MM-DD HH:mm")
                      : "0000-00-00 00:00:00"}
                  </td>
                </tr>

                <tr>
                  <td className="font-weight-bold">Reg./Desp. Owner:</td>
                  <td>
                    {formReportdata && formReportdata.owner_name
                      ? formReportdata.owner_name
                      : "N/A"}
                  </td>

                  <td className="font-weight-bold">Contract Type:</td>
                  <td>
                    {formReportdata && formReportdata.con_type_name
                      ? formReportdata.con_type_name
                      : "N/A"}
                  </td>

                  <td className="font-weight-bold">Release date:</td>
                  <td>
                    {formReportdata && formReportdata.release_date&&formReportdata.release_date!=="0000-00-00 00:00:00"
                      ? moment(formReportdata.release_date).format("YYYY-MM-DD HH:mm")
                      : "0000-00-00 00:00:00"}
                  </td>
                </tr>

                <tr>
                  <td className="font-weight-bold">Beneficiary:</td>
                  <td>
                    {formReportdata && formReportdata.beneficiary_name
                      ? formReportdata.beneficiary_name
                      : "N/A"}
                  </td>

                  <td className="font-weight-bold">Config Date:</td>
                  <td>
                    {formReportdata && formReportdata.config_date && formReportdata.config_date!=="0000-00-00 00:00:00"
                      ? moment(formReportdata.config_date).format("YYYY-MM-DD HH:mm")
                      : "0000-00-00 00:00:00"}
                  </td>

                  <td className="font-weight-bold">Ownership type:</td>
                  <td>
                    {formReportdata && formReportdata.ownership_type_name
                      ? formReportdata.ownership_type_name
                      : "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
    );
  })

const  VesselFileReport = (props) => {

  const [name, setName] = useState("Printer")
  const componentRef = useRef()

  const printReceipt = () =>{
    window.print();
  }

  const printDocument = () => {
    // in this method report is downloading, but logo is not coming showing err of loading image of s3.
    html2canvas(document.getElementById("divToPrint"), {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    }).then(function(canvas) {
      // download image
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(canvas);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(canvas, "PNG", 0, 20, pdfWidth, pdfHeight);
      pdf.save("vesselfileReport.pdf");
    }).catch = (err) => {
      console.log(err);
    };
  };

    return (
      <div className="body-wrapper modalWrapper">
        <article className="article toolbaruiWrapper">
          <div className="box box-default">
            <div className="box-body">
              <div className="toolbar-ui-wrapper">
                <div className="leftsection" />
                <div className="rightsection">
                  <span className="wrap-bar-menu">
                    <ul className="wrap-bar-ul">
                      <li onClick={printDocument}>Download</li>

                      <li>
                        <ReactToPrint
                          trigger={() => (
                            <span className="text-bt">
                            <PrinterOutlined />Print
                            </span>
                          )}
                          content={() => componentRef.current}
                        />
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <ComponentToPrint
                ref={componentRef}
                data={props.data}
              />
            </div>
          </div>
        </article>
      </div>
    );
  }

export default VesselFileReport;
