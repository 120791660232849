import {
  ConfigProvider,
  Layout,
  Tooltip,
  DatePicker,
  Input,
  Dropdown,
  Menu,
  Drawer,
  Flex,
  Skeleton,
  Divider,
  Typography,
} from "antd";
import {
  events,
  formatTimestamp,
  handleDeleteConfirmation,
  returnccbcc,
} from "./constants";
import { useEffect, useState } from "react";
// import "./mails.css";
import '../mail-folder/mails.css'
import CurrentMail from "../mail-folder/CurrentMail";
import { Icon } from "@iconify/react";
import Searchfilters from "./SearchFilter";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { openNotificationWithIcon } from "../../shared";
//import { setAllEmails, setIsSearcihng, setSentMail } from "../../actions/emailActions";
import Swal from "sweetalert2";
import { ALLEmails } from "./constants";

const MailSpiltView = ({ handleSearch, searchData, mailType }) => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [currentMail, setCurrentMail] = useState({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [page, setPage] = useState(1);
  // GLOBAL STATES IMPORTS
  const allMails = [...ALLEmails];
  const sentMails = [];
  const draftMails = [];

  const binMails = [];
  const archivedMails = [];
  const importantMails = [];
  const spamMails = [];

  const isSearching = "";
  const [mailData, setMailData] = useState([]);
  const [query, setQuery] = useState("");

  const { Content, Sider } = Layout;

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleRenderMail = (mail) => {
    setCurrentMail(mail);
  };

  const markImportant = (userInpu1) => {
    // Destructure the userInput and event from the input object
    const { userInput, event } = userInpu1;
    event.stopPropagation();

    const { _id, important, client } = userInput;

    // Map over allMails to find the email that matches the _id and toggle its important property
    const currentMails = mailData.map((mail) => {
      if (mail._id === _id) {
        // Toggle the important property
        return {
          ...mail,
          important: mail.hasOwnProperty("important") ? !mail.important : true,
        };
      }
      return mail;
    });

    // Update the state with the modified emails
    // setMailData(currentMails);
    //  dispatch(setAllEmails({ emails: currentMails }));

    // Prepare the payload to be sent via WebSocket
    const payload = {
      context: client,
      _id,
      important: !important, // Toggle the important value
      event: "markImportant",
    };
    // Send the event to the WebSocket
    events.markImportant(payload);
  };

  useEffect(() => {
    if (searchData?.length > 0) {
      setMailData(searchData);
    } else if (mailType === "sent") {
      setMailData(sentMails);
    } else if (mailType === "draft") {
      setMailData(draftMails);
    } else if (mailType === "bin") {
      setMailData(binMails);
    } else if (mailType === "archived") {
      setMailData(archivedMails);
    } else if (mailType === "important") {
      setMailData(importantMails);
    } else if (mailType === "spam") {
      setMailData(spamMails);
    } else {
      setMailData(allMails);
    }
  }, []);

  const menu = (
    <Menu>
      <RangePicker allowClear showTime showSecond={false} />
    </Menu>
  );

  const loadMoreData = () => {
    if (isSearching) {
      handleSearch(query);
    }
    // setPage(page + 1);
  };

  const handleFilterSearch = (query) => {
    const trimmedQuery = query.replace(/^\s+/, "");
    setQuery(trimmedQuery);
    if (trimmedQuery.length > 0) {
      handleSearch(trimmedQuery);
      // dispatch(setIsSearcihng(true));
    } else {
      openNotificationWithIcon("info", "Please Enter some text to search.");
    }
  };

  const handleSearchingTyping = (query) => {
    const trimmedQuery = query.trim();
    if (trimmedQuery.length > 0) {
      // dispatch(setIsSearcihng(false));
    }
  };

  const handleDeleteMail = (GmailMessageId, _id, client) => {
    const payLoad = {
      GmailMessageId,
      _id,
      context: client,
      trashed: true,
      parmanent: false,
      event: "deleteMail",
    };

    const filteredMails = mailData?.filter((mail) => mail?._id !== _id);

    events.deleteMail(payLoad);

    if (filteredMails.length > 0) {
      setMailData(filteredMails);
      setCurrentMail(filteredMails[0]);
    }

    // fetchEmailsAfterDelete(filteredMails, currentPage + 1)
  };

  const handleDeleteConfirmation = (mailDetail) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteMail(
          mailDetail.GmailMessageId,
          mailDetail._id,
          mailDetail.client
        );
        // handleDeleteMail(mailDetail: {})
      }
    });
  };

  return (
    <div className="layout_container">
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              headerBg: "#fff",
            },
          },
        }}
      >
        <Layout>
          {/* <Header>Header</Header> */}

          <Layout hasSider style={{ height: "100%" }}>
            <Sider
              theme="light"
              width={"40%"}
              collapsed={collapsed}
              onCollapse={handleCollapse}
            >
              <div className="mail_list_container">
                {/* //////////// MAIL LIST FILTERS //////////////// */}
                <div className="mail_filter_row_box">
                  <div className="mail_filter_row">
                    <div
                      className="filter_icons"
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                    >
                      <Icon icon={"tabler:filter"} />
                    </div>

                    <Drawer
                      mask={false}
                      className="mail_filter_drawer"
                      title="Search Filters :"
                      onClose={() => setIsFilterOpen(false)}
                      open={isFilterOpen}
                    >
                      <Searchfilters />
                    </Drawer>

                    <Input
                      type="text"
                      onPressEnter={(e) => handleFilterSearch(e.target.value)}
                      onChange={(e) => handleSearchingTyping(e.target.value)}
                      placeholder="Search for emails..."
                    />
                    <Dropdown overlay={menu} placement="bottomLeft" arrow>
                      <div className="filter_icons">
                        <Icon icon={"tabler:calendar"} />
                      </div>
                    </Dropdown>
                  </div>
                </div>
                {/* <div className="mail_list_title">
                  <h5>Email List</h5>
                </div> */}

                <div
                  id="scrollableDiv"
                  className="mail_scrolls"
                  style={{
                    height: "85vh",
                    overflowY: "scroll",
                    padding: "0 7px",
                    // marginTop: "20px",
                    border: "1px solid rgba(140, 140, 140, 0.35)",
                  }}
                >
                  <InfiniteScroll
                    dataLength={mailData.length}
                    next={loadMoreData}
                    hasMore
                    loader={
                      <>
                        {[...Array(3).keys()].map((item) => (
                          <Skeleton
                            avatar
                            paragraph={{
                              rows: 1,
                            }}
                            active
                          />
                        ))}
                      </>
                    }
                    endMessage={
                      <Divider plain>It is all, nothing more 🤐</Divider>
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    {/* ///////////////// Email List //////////////// */}
                    {mailData?.length > 0
                      ? mailData?.map((item) => {
                          const ccBccList = returnccbcc(item);
                          let modifiedMail = { ...item };
                          return (
                            <div
                              key={item["_id"]}
                              className={`mail_list_item ${
                                item === currentMail && "current_active"
                              }`}
                              onClick={() => handleRenderMail(item)}
                            >
                              <Flex
                                align="center"
                                justify="space-between"
                                style={{ marginBottom: "5px" }}
                              >
                                <p className="sender_name">
                                  {item?.mailClient === "outlook" ? (
                                    <>
                                      <>
                                        <Typography
                                          sx={{
                                            display: "block",
                                            fontWeight: item?.isRead
                                              ? "600"
                                              : "700",
                                            fontSize: "14px",
                                            // color: item?.isRead
                                            //   ? "inherit"
                                            //   : "black",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            minWidth: "150px",
                                            maxWidth: "150px",
                                            width: "100%",
                                          }}
                                        >
                                          {item?.from?.emailAddress?.name?.slice(
                                            0,
                                            20
                                          ) ?? ""}{" "}
                                        </Typography>

                                        {item?.Threads &&
                                        item?.Threads.length > 0 ? (
                                          <Tooltip
                                            title={`Thread count ${
                                              item?.Threads
                                                ? item?.Threads.length
                                                : ""
                                            }`}
                                          >
                                            <span
                                              style={{
                                                fontSize: "12px",
                                                padding: "2px 6px",
                                                color: "green",
                                              }}
                                            >
                                              {item?.Threads
                                                ? item?.Threads.length
                                                : ""}
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          ""
                                        )}
                                      </>

                                      <Tooltip
                                        title={
                                          Array.isArray(item?.readBy)
                                            ? item.readBy.map((data, index) => (
                                                <div key={index}>{data}</div>
                                              ))
                                            : item?.readBy
                                            ? item.readBy
                                            : "unread email"
                                        }
                                      >
                                        <div style={{ marginLeft: "4px" }}>
                                          <Icon
                                            icon="mdi:email-outline"
                                            fontSize={14}
                                            style={{ color: "#1C5CE9" }}
                                          />
                                        </div>
                                      </Tooltip>
                                      <div style={{ marginLeft: "4px" }}>
                                        {/* {ccBccList.length > 0 && */}
                                        <Tooltip
                                          // title={'mashu testing'}
                                          title={
                                            ccBccList.length > 0
                                              ? ccBccList?.map((item, i) => (
                                                  <div>
                                                    <div>
                                                      {item?.name ||
                                                        item?.email}
                                                    </div>
                                                  </div>
                                                ))
                                              : "N/A"
                                          }
                                        >
                                          <div>
                                            <Icon
                                              icon="ph:users"
                                              fontSize={14}
                                              style={{ color: "#00712D" }}
                                            />
                                          </div>
                                        </Tooltip>
                                        {/* } */}
                                      </div>
                                    </>
                                  ) : item?.mailClient == "custom" ? (
                                    <div
                                      style={{
                                        display: "block",
                                        fontWeight: item?.isRead
                                          ? "600"
                                          : "700",
                                        fontSize: "14px",
                                        // color: item?.isRead ? "inherit" : "black",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        minWidth: "150px",
                                        maxWidth: "150px",
                                        width: "100%",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: isSearching
                                          ? // item.Sender
                                            item?.Sender?.includes("<")
                                            ? item?.From[0]
                                                ?.split("<")[0]
                                                .replace(/[^a-zA-Z0-9 ]/g, "")
                                                .slice(0, 20)
                                            : item?.From[0]
                                                ?.replace(/[^a-zA-Z0-9 ]/g, "")
                                                .slice(0, 20)
                                          : modifiedMail?.From[0]?.split(
                                              "<"
                                            )[0] ||
                                            modifiedMail?.owner_email
                                              ?.replace(/[^a-zA-Z0-9 ]/g, "")
                                              .slice(0, 20),
                                      }}
                                    ></div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "block",
                                        fontWeight: item?.isRead
                                          ? "600"
                                          : "700",
                                        fontSize: "14px",
                                        // color: item?.isRead ? "inherit" : "black",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        minWidth: "150px",
                                        maxWidth: "150px",
                                        width: "100%",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: isSearching
                                          ? // mail.Sender
                                            item?.Sender?.includes("<")
                                            ? item?.Sender?.split("<")[0]
                                                .replace(/[^a-zA-Z0-9 ]/g, "")
                                                .slice(0, 20)
                                            : item?.Sender?.replace(
                                                /[^a-zA-Z0-9 ]/g,
                                                ""
                                              ).slice(0, 20)
                                          : modifiedMail?.Sender?.split(
                                              "<"
                                            )[0] ||
                                            modifiedMail?.owner_email
                                              ?.replace(/[^a-zA-Z0-9 ]/g, "")
                                              .slice(0, 20),
                                      }}
                                    ></div>
                                  )}
                                </p>
                                <span className="mail_list_time">
                                  {formatTimestamp(item.TIMESTAMP || item.Date)}
                                </span>
                              </Flex>

                              <p className="subject">
                                {item.Subject ? (
                                  <span>
                                    <b>Subject</b> : {item.Subject}{" "}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>

                              <p className="snippet">{item.snippet}</p>

                              <div className="mail_list_label_box">
                                <div className="mail_list_labels mailType">
                                  <Tooltip title="Mail Type">
                                    <span className="mail_list_badge">
                                      {item.MailType}
                                    </span>
                                  </Tooltip>
                                </div>

                                <div className="mail_list_labels vessels">
                                  {item.vessel_name?.length > 0 && (
                                    // item.vessel_name?.map((vessel, index) => (
                                    <Tooltip title="Vessel Name">
                                      <span className="mail_list_badge">
                                        {item.vessel_name[0]}
                                      </span>
                                    </Tooltip>
                                  )}
                                </div>
                              </div>

                              <div className="mail_list_attach_icon">
                                <Icon
                                  fontSize={14}
                                  icon="mdi:attachment"
                                  style={{ color: "purple" }}
                                />
                                <Tooltip title="Click to Delete this Mail.">
                                  <Icon
                                    fontSize={14}
                                    style={{ color: "red" }}
                                    icon="tabler:trash"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteConfirmation({
                                        GmailMessageId: item?.GmailMessageId,
                                        _id: item?._id,
                                        client: item?.client,
                                      });
                                    }}
                                  />
                                </Tooltip>
                                {item.vessel_name?.length > 1 && (
                                  <Tooltip
                                    title={
                                      "Attached Vessels : " +
                                      item.vessel_name.join(", ").toUpperCase()
                                    }
                                  >
                                    <Icon
                                      fontSize={14}
                                      style={{ color: "green" }}
                                      icon="tabler:tag"
                                    />
                                  </Tooltip>
                                )}
                                <Tooltip title="Click to mark as important or unimportant.">
                                  <Icon
                                    fontSize={14}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      markImportant({ event, userInput: item });
                                    }}
                                    icon={
                                      item?.important
                                        ? "tabler:star-filled"
                                        : "tabler:star"
                                    }
                                    style={{ color: "orange" }}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          );
                        })
                      : [...Array(5).keys()].map((item) => (
                          <div style={{ padding: "14px 16px" }}>
                            <Skeleton
                              avatar
                              paragraph={{
                                rows: 1,
                              }}
                              active
                            />
                          </div>
                        ))}
                  </InfiniteScroll>
                </div>
              </div>
            </Sider>

            <Layout>
              <Content style={{ padding: "0 24px", minHeight: 280 }}>
                <CurrentMail mail={currentMail} threads={false} />

                {currentMail.Threads && currentMail.Threads.length > 0
                  ? currentMail.Threads?.map((thread) => (
                      <CurrentMail threads={true} mail={thread} />
                    ))
                  : ""}
              </Content>
            </Layout> 





          </Layout>
        </Layout>
      </ConfigProvider>
    </div>
  );
};

export default MailSpiltView;
