import { Drawer } from "antd";
import React from "react";
import BestMatchDrawerContent from "../BestMatchDrawerContent";

const BestMatchDrawer = ({
  showBestMatchDrawer,
  setShowBestMatchDrawer,
  currentMail,
}) => {
  const currentMatch = {
    dwt: currentMail?.dwt,
    open_area: currentMail?.open_area,
    open_data: currentMail?.open_date,
    vessel_type: currentMail?.vessel_type,
    vessel_name:
      currentMail?.vessel_name?.length > 0
        ? currentMail?.vessel_name?.map((name) => name.toUpperCase()).join(", ")
        : "",
    build_year: currentMail?.build_year,
  };
  return (
    <Drawer
      className="mail-side-drawer"
      title="Best Match"
      onClose={() => setShowBestMatchDrawer(false)}
      open={showBestMatchDrawer}
      size="default"
    >
      <BestMatchDrawerContent currentMatch={currentMatch} />
    </Drawer>
  );
};

export default BestMatchDrawer;
