import React, { Component } from 'react';
import { Table, Row, Col } from 'antd';

const columns = [
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '20%',
  },
];

const data = [
  {
    //   key: 1,
    //   description: 'Itinerary',

    //   children: [
    //     {
    //       key: 12,
    //       description: 'Booston (USA) (L)',

    //       children: [
    //         {
    //           key: 121,
    //           description: 'Port Activities',
    //         },

    //         {
    //           key: 121,
    //           description: 'Activity Report',
    //         },

    //         {
    //           key: 121,
    //           description: 'Port Expence',
    //         },

    //         {
    //           key: 121,
    //           description: 'Cargo Handling',
    //           children: [
    //             {
    //               key: 121,
    //               description: 'Option 1',
    //             },

    //             {
    //               key: 121,
    //               description: 'Option 2',
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       key: 121,
    //       description: 'Inspector (None)',
    //     },
    //   ],
    // },

    // {
    //   key: 121,
    //   description: 'Delay',
    // },

    // {
    //   key: 121,
    //   description: 'Houston (D)',
    //   children: [
    //     {
    //       key: 121,
    //       description: 'Option 1',
    //     },

    //     {
    //       key: 121,
    //       description: 'Option 2',
    //     },
    //   ],
    // },

    // {
    key: 121,
    description: 'Other Rev/Exp',
  },

  {
    key: 121,
    description: 'Delay',
  },

  {
    key: 121,
    description: 'Freight Invoice',
  },

  {
    key: 121,
    description: 'Laytime',
  },

  {
    key: 121,
    description: 'Commissions',
  },

  {
    key: 121,
    description: 'Rebill',
  },

  {
    key: 121,
    description: 'Attachments',
    children: [
      {
        key: 121,
        description: 'Voyage Attachments',
      },

      {
        key: 121,
        description: 'Fixture Attachments',
      },

      {
        key: 121,
        description: 'Cargo Attachments',
      },
    ],
  },
];

class EstimateSummary extends Component {
  render() {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table columns={columns} dataSource={data} pagination={false} bordered />
        </Col>
      </Row>
    );
  }
}

export default EstimateSummary;
