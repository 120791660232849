import React, { forwardRef, useEffect, useRef, useState } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Modal } from "antd";
import Email from "../../../../components/Email";
const ComponentToPrint = forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  const [max, setMax] = useState();
  // useEffect(() => {
  //   setData(props.vesseDetail);
  // }, [data]);

  useEffect(() => {
    if (props.vesseDetail?.TCO_DATA) {
      const tempData = props.vesseDetail?.TCO_DATA;

      // if (
      //   props.vesseDetail?.TCO_DATA?.[2] &&
      //   props.vesseDetail?.TCO_DATA?.[2].code === "TCOVOFHD"
      // ) {
      //   const temp = props.vesseDetail?.TCO_DATA?.[1];

      //   tempData[1] = props.vesseDetail?.TCO_DATA?.[2];

      //   tempData[2] = temp;

      //   setData(tempData);
      // } else {
      //   setData(props.vesseDetail);
      // }

      // console.log("tempData :", tempData);

      let orderedData = [];
      for (let item of props.vesseDetail?.TCO_DATA) {
        if (item?.title === "Gross Hire") {
          orderedData[0] = item;
        } else if (item?.title === "Vessel Off Hire Delay") {
          orderedData[1] = item;
        } else if (item?.title === "Voyage Off Hire Delay") {
          orderedData[2] = item;
        } else if (item?.title === "Hire Commission") {
          orderedData[3] = item;
        } else {
          orderedData.push(item);
        }
      }
      let newState = {
        ...props.vesseDetail,
        TCO_DATA: orderedData,
      };
      setData(newState);

      let newAmount = 0;

      let netAmt = 0;
      let netTot = 0;
      for (let elm of tempData) {
        if (elm.code === "TCOH") {
          newAmount = elm.net_amount;
        }
        if (elm.code === "TCOOFFHD") {
          netTot = parseFloat(elm.net_amount);
        }
        if (elm.code === "TCOVOFHD") {
          netAmt = parseFloat(elm.net_amount);
        }
      }
      const amtNet = netAmt + netTot;
      setMax(Number(newAmount) + Number(netAmt));

      // console.log("newAmount", newAmount);

      // console.log("netAmt", netAmt);
    }

    // console.log(props.vesseDetail);

    // setData(props.vesseDetail);
  }, []);

  let cal_total_amount = 0;
  return (
    <article className="article" ref={ref}>
      <div className="box box-default print-wrapper" id="divToPrint">
        <div className="box-body">
          <div className="invoice-wrapper">
            <section className="invoice-container hire" id="invoice">
              <div className="invoice-inner">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center">
                      {/* <span className="title">{data.my_company_logo}</span> */}
                      <img
                        className="reportlogo"
                        src={data.my_company_logo ?? "N/A"}
                        alt="Company Logo"
                      ></img>
                      <p className="sub-title">
                        {data.my_company_name ?? "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row view-list">
                  <div className="col-12">
                    <div
                      className="wrap-data border-line"
                      style={{ flexWrap: "wrap" }}
                    >
                      <div className="col-4 text-left">
                        <ul className="list-unstyled">
                          <li className="laytime-list">
                            <span>Vessel:</span>
                            <span>{data.vessel_name ?? "N/A"}</span>
                          </li>
                          <li className="laytime-list">
                            <span>Delivery:</span>
                            <span>{data.actual_delivery ?? "N/A"}</span>
                          </li>
                        </ul>
                      </div>

                      <div className="col-4 text-left">
                        <ul className="list-unstyled">
                          <li className="laytime-list">
                            <span>CP Date:</span>
                            <span>{data.cp_date ?? "N/A"}</span>
                          </li>
                          <li className="laytime-list">
                            <span>Redelivery:</span>
                            <span>{data.actual_redelivery ?? "N/A"}</span>
                          </li>
                        </ul>
                      </div>

                      <div className="col-4 text-left">
                        <ul className="list-unstyled">
                          <li className="laytime-list">
                            <span>Charterered:</span>
                            <span>{data.my_company_name ?? "N/A"}</span>
                          </li>
                          <li className="laytime-list">
                            <span>Printed On:</span>
                            <span>{data.printed ?? "N/A"}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-4 text-left">
                        <ul className="list-unstyled">
                          <li className="laytime-list">
                            <span>Account Type:</span>
                            <span>{data.acc_type ?? "N/A"}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="divider my-2" />

                <div className="row laytime-heading">
                  <div className="row laytime-heading">
                    {data?.TCO_DATA?.length > 0
                      ? data?.TCO_DATA?.map((e) => {
                          cal_total_amount =
                            cal_total_amount + parseFloat(e.net_amount);
                          return (
                            <>
                              <div className="col-md-12">
                                <div className="table-responsive custom-table">
                                  <table className="table th-withoutborder">
                                    <thead>
                                      <tr>
                                        <div
                                          scope="col"
                                          rowSpan="3"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          {e.title}
                                        </div>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {e.description.length > 0 &&
                                        e.description?.map((ed) => {
                                          return (
                                            <>
                                              <tr className="content">
                                                <th
                                                  scope="row"
                                                  className="no-padding"
                                                >
                                                  {ed?.details ||
                                                    ed?.description
                                                      ?.details}{" "}
                                                </th>
                                                {
                                                  <td className="text-right no-padding">
                                                    &nbsp;
                                                  </td>
                                                }

                                                {
                                                  <td className="text-right no-padding">
                                                    {ed["amount"] > 0
                                                      ? ed["amount"]
                                                      : ed["amount"] * -1}
                                                  </td>
                                                }
                                              </tr>
                                            </>
                                          );
                                        })}
                                      <tr>
                                        <th scope="row">Net {e.title}</th>
                                        <td />
                                        <td
                                          className="text-right"
                                          style={{
                                            borderTop: "2px solid #000",
                                          }}
                                        >
                                          {e["net_amount"] < 0
                                            ? "(" + e["net_amount"] * -1 + ")"
                                            : e["net_amount"]}
                                        </td>
                                      </tr>
                                      {(e.code === "TCOOFFHD" ||
                                        e.code == "TCOVOFHD") && (
                                        <tr
                                          style={{ marginTop: "-3rem" }}
                                          className="col-md-12"

                                          // style={{

                                          //   display: "grid",

                                          //   gridTemplateColumns:

                                          //     "50rem 6rem auto",

                                          //   justifyItems: "stretch",

                                          // }}
                                        >
                                          <td>
                                            {" "}
                                            <p

                                            // style={{

                                            //   borderTop: "2px solid black",

                                            //   width: "5rem !important",

                                            // }}
                                            >
                                              {" "}
                                              Net Hire
                                            </p>
                                          </td>

                                          <td></td>

                                          <td
                                            style={{
                                              borderTop: "2px solid black",

                                              textAlign: "right",
                                            }}
                                          >
                                            {" "}
                                            {max.toFixed(2)}
                                          </td>
                                        </tr>
                                      )}
                                      {/* {e.code == "TCOVOFHD" && (
                                        <tr
                                          style={{ marginTop: "-3rem" }}
                                          className="col-md-12"
                                          // style={{
                                          //   display: "grid",
                                          //   gridTemplateColumns:
                                          //     "50rem 6rem auto",
                                          //   justifyItems: "stretch",
                                          // }}
                                        >
                                          <td>
                                            {" "}
                                            <p
                                            // style={{
                                            //   borderTop: "2px solid black",
                                            //   width: "5rem !important",
                                            // }}
                                            >
                                              {" "}
                                              Net Hire
                                            </p>
                                          </td>
                                          <td></td>
                                          <td
                                            style={{
                                              borderTop: "2px solid black",
                                              textAlign: "right",
                                            }}
                                          >
                                            {" "}
                                            {max.toFixed(2)}
                                          </td>
                                        </tr>
                                      )} */}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="spacer" />
                            </>
                          );
                        })
                      : undefined}

                    <div className="col-md-12">
                      <div className="table-responsive custom-table">
                        <table className="table th-withoutborder">
                          <thead>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                scope="col"
                                rowSpan="3"
                                style={{
                                  paddingLeft: "0px",
                                  borderTop: "2px solid black",
                                }}
                              >
                                Total Amount
                              </div>

                              <div
                                style={{
                                  display: "inline",
                                  borderTop: "2px solid black",
                                  padding: "0px",
                                  borderBottom: "0px",
                                }}
                              >
                                {cal_total_amount}
                              </div>
                              {/* <tr
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginTop: "5px",
                                  padding: "0px",
                                  borderBottom: "0px",
                                }}
                              >
                                <tr
                                  style={{
                                   
                                  }}
                                  className="text-right"
                                ></tr>
                              </tr> */}
                            </div>
                          </thead>
                        </table>
                      </div>
                    </div>

                    <div className="spacer" />

                    <div className="spacer" />

                    <div className="spacer" />

                    <div className="col-md-12">
                      <div className="table-responsive custom-table">
                        <table className="table th-withoutborder">
                          <thead>
                            <tr className="border-bottom-line">
                              <div
                                scope="col"
                                rowSpan="3"
                                style={{ paddingLeft: "0px" }}
                              >
                                Payment
                              </div>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="content ">
                              <div
                                scope="row laytime-heading"
                                className="no-padding"
                                style={{
                                  width: "100%",
                                }}
                              >
                                {data?.paymnets &&
                                  data.paymnets[0]?.description?.length > 0 &&
                                  data.paymnets[0].description.map((e) => {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <tr>{e?.details} </tr>
                                          <span
                                            style={{
                                              fontWeight: "normal",
                                            }}
                                          >
                                            <tr>{e?.amount} </tr>
                                          </span>
                                        </div>
                                      </>
                                    );
                                  })}

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <tr>Total Amount Paid</tr>
                                  <tr
                                    style={{
                                      marginTop: "8px",
                                      borderTop: "2px solid black",
                                    }}
                                  >
                                    {data?.paymnets?.length > 0
                                      ? data.paymnets[0]?.net_amount
                                      : "0.00"}
                                  </tr>
                                </div>
                              </div>
                            </tr>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "0px",
                                padding: "0px",
                                marginTop: "16px",
                                borderBottom: "2px solid black",
                              }}
                            >
                              <tr
                                style={{
                                  padding: "0px",
                                  margin: "0px",
                                  border: "0px",
                                }}
                              >
                                Balance In Charterer's Favor
                              </tr>
                              <tr
                                style={{
                                  padding: "0px",
                                  margin: "0px",
                                  border: "0px",
                                  borderTop: "2px solid black",
                                }}
                              >
                                {(
                                  cal_total_amount -
                                  (data?.paymnets?.length > 0
                                    ? data.paymnets[0]?.net_amount
                                    : 0)
                                ).toFixed(2)}
                              </tr>
                            </div>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {data?.acc_type === "RECEIVABLE" && (
                      <div className="row view-list">
                        <div class="col-lg-12">
                          <ul class="list-unstyled">
                            <li
                              className="laytime-list"
                              style={{ margin: "6px", paddingLeft: "0px" }}
                            >
                              Banking Details:
                            </li>
                          </ul>

                          <ul class="list-unstyled" style={{ margin: "0px" }}>
                            <li className="laytime-list">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  paddingLeft: "6px",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Beneficiary Name :{" "}
                                </span>
                                <span
                                  style={{
                                    fontWeight: "lighter",
                                    paddingLeft: "4px",
                                  }}
                                >
                                  {" "}
                                  {data?.bank_details?.benificiary_name ??
                                    "N/A"}
                                </span>
                              </div>
                            </li>
                          </ul>

                          <ul
                            class="list-unstyled"
                            style={{ margin: "0px", paddingLeft: "6px" }}
                          >
                            <li className="laytime-list">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Bank Name:{" "}
                                </span>
                                <span
                                  style={{
                                    fontWeight: "lighter",
                                    paddingLeft: "4px",
                                  }}
                                >
                                  {" "}
                                  {data?.bank_details?.benificiary_bank_name ??
                                    "N/A"}
                                </span>
                              </div>
                            </li>
                          </ul>

                          <ul
                            class="list-unstyled"
                            style={{ margin: "0px", paddingLeft: "6px" }}
                          >
                            <li className="laytime-list">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Bank Address:
                                </span>
                                <span
                                  style={{
                                    fontWeight: "lighter",
                                    paddingLeft: "4px",
                                  }}
                                >
                                  {" "}
                                  {data?.bank_details?.b_country_name ?? "N/A"}
                                </span>
                              </div>
                            </li>
                          </ul>

                          <ul
                            class="list-unstyled"
                            style={{ margin: "0px", paddingLeft: "6px" }}
                          >
                            <li className="laytime-list">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  Account No: :
                                </span>
                                <span
                                  style={{
                                    fontWeight: "lighter",
                                    paddingLeft: "4px",
                                  }}
                                >
                                  {data?.bank_details?.account_no ?? "N/A"}
                                </span>
                              </div>
                            </li>
                          </ul>

                          <ul
                            class="list-unstyled"
                            style={{ margin: "0px", paddingLeft: "6px" }}
                          >
                            <li className="laytime-list">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Swift Code:
                                </span>
                                <span
                                  style={{
                                    fontWeight: "lighter",
                                    paddingLeft: "4px",
                                  }}
                                >
                                  {" "}
                                  {data?.bank_details?.cb_swift_code ?? "N/A"}
                                </span>
                              </div>
                            </li>
                          </ul>

                          <ul
                            class="list-unstyled"
                            style={{ margin: "0px", paddingLeft: "6px" }}
                          >
                            <li className="laytime-list">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  IBAN:
                                </span>
                                <span
                                  style={{
                                    fontWeight: "lighter",
                                    paddingLeft: "4px",
                                  }}
                                >
                                  {" "}
                                  {data?.bank_details?.iban ?? "N/A"}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </article>
  );
});

const TCOStatementOfAccountReport = (props) => {
  // console.log("prop99999999=======", props.invData);
  const [state, setState] = useState({
    name: "Printer",
  });

  const [pdfData, setPdfData] = useState();
  const [loading, setLoading] = useState(false);
  const [emailModal, setEmailModal] = useState(false);

  const componentRef = useRef();

  const printReceipt = () => {
    window.print();
  };

  const sendEmailReportModal = async () => {
    try {
      setLoading(true);
      const quotes = document.getElementById("divToPrint");
      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        sacle: 2,
      });
      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      const blob = doc.output("blob");
      setLoading(false);
      setPdfData(blob);
      setEmailModal(true);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const printDocument = () => {
    var quotes = document.getElementById("divToPrint");

    html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 25;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("StatementOFAccount.pdf");
    });
  };

  return (
    <div className="body-wrapper modalWrapper modal-report-wrapper">
      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            <div className="toolbar-ui-wrapper">
              <div className="leftsection"></div>
              <div className="rightsection">
                <span key="first" className="wrap-bar-menu">
                  <ul className="wrap-bar-ul">
                    <li onClick={sendEmailReportModal}>Send Email</li>
                    <li onClick={printDocument}>Download</li>
                    <li>
                      <ReactToPrint
                        trigger={() => (
                          <span className="text-bt">
                            <PrinterOutlined /> Print
                          </span>
                        )}
                        content={() => componentRef.current}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="article">
        <div className="box box-default">
          <div className="box-body print-scroll">
            <ComponentToPrint ref={componentRef} vesseDetail={props.invData} />
          </div>
        </div>
      </article>

      {emailModal && (
        <Modal
          title="New Message"
          open={emailModal}
          onOk={() => {
            setEmailModal(false);
          }}
          onCancel={() => {
            setEmailModal(false);
          }}
          footer={null}
        >
          {pdfData && (
            <Email
              handleClose={() => {
                setEmailModal(false);
              }}
              attachmentFile={pdfData}
              title={window.corrector(
                `StatementOfAccountReport  ${props.invData.vessel_name} || ${props.invData.my_company_name} || ${props.invData.counterparty_name} || ${props.invData.tc_code} `
              )}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default TCOStatementOfAccountReport;
