import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import URL_WITH_VERSION, { getAPICall } from '../../shared';
import { FIELDS } from '../../shared/tableFields';
const VoyageManagerInfo = ({ vesselId,vmInfoData }) => {
    
    const [state, setState] = useState({
        columns: [],
        responseData: []

    })

    useEffect(() => {
        let tableHeaders = Object.assign([], FIELDS && FIELDS['vm-list'] ? FIELDS['vm-list']["tableheads"] : [])
        setState({ ...state, columns: tableHeaders ,responseData:vmInfoData }, () => {
        });
       
    }, [])
    return (
        <>

            <div className="body-wrapper">
                <article className="article">
                    <div className="box box-default">
                        <div className="box-body">
                            <div>
                                <Table
                                    className="inlineTable editableFixedHeader resizeableTable"
                                    bordered
                                    // components={components}
                                    columns={state.columns}
                                    scroll={{ x: "max-content" }}
                                    dataSource={state.responseData ?? "NA"}
                                    // loading={state.loading}
                                    pagination={false}
                                    rowClassName={(r, i) =>
                                        i % 2 === 0
                                            ? "table-striped-listing"
                                            : "dull-color table-striped-listing"
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </>


    );
};

export default VoyageManagerInfo;
