import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const ShadowContainer = ({ children }) => {
  const shadowRef = useRef(null);
  const shadowRootRef = useRef(null);

  useEffect(() => {
    if (shadowRef.current && !shadowRootRef.current) {
      shadowRootRef.current = shadowRef.current.attachShadow({ mode: 'open' });
    }

    if (shadowRootRef.current) {
      ReactDOM.render(children, shadowRootRef.current);
    }

    return () => {
      if (shadowRootRef.current) {
        ReactDOM.unmountComponentAtNode(shadowRootRef.current);
      }
    };
  }, [children]);

  return <div ref={shadowRef}></div>;
};

export default ShadowContainer;
