export const stepsobj = {
  "#/add-voyage-estimate": [
    {
      selector: "#vessel_id",
      content: " Select vessel here",
    },
    {
      selector: "#my_company_lob",
      content: " Select company lob",
    },
    {
      selector: "#tci_code",
      content: " Select TCI  code",
    },
    {
      selector: "#commence_date",
      content: " Fill commence date",
    },
    {
      selector: "#completing_date",
      content: " Fill completing date",
    },

    {
      selector: "#port",
      content: " Select port here",
    },
    {
      selector: "#funct",
      content: " Select function here",
    },
    {
      selector: "#passage",
      content: " Select passage here",
    },
    {
      selector: "#s_type",
      content: " Select speed type here",
    },
    {
      selector: '[data-node-key="CII Dynamics"]',
      content: " Check cii dynamics tab after filling port itinerary",
    },
    {
      selector: '[data-node-key="EU ETS"]',
      content: " Check EUETS tab after filling port itinerary",
    },
    {
      selector: "#pnl",
      id: "pnl",
      content: " Check pnl",
    },

    {
      selector: "#map",
      id: "map",
      content: " Check Map Intelligence",
    },

    {
      selector: "#bunker",
      id: "bunker",
      content: " Check Bunker live prices",
    },
    {
      selector: "#save",
      content: " Save form",
    },
    {
      selector: "#plus",
      content: " Create new form",
    },
    {
      selector: "#refresh",
      content: " Refresh form",
    },
  ],

  "#/voy-relet-full-estimate": [
    {
      selector: "#vessel_id",
      content: " Select vessel here",
    },
    {
      selector: "#my_company_lob",
      content: " Select company lob",
    },
    {
      selector: "#commence_date",
      content: " Fill commence date",
    },
    {
      selector: "#completing_date",
      content: " Fill completing date",
    },
    {
      selector: "#port",
      content: " Select port here",
    },
    {
      selector: "#funct",
      content: " Select function here",
    },
    {
      selector: "#passage",
      content: " Select passage here",
    },
    {
      selector: "#s_type",
      content: " Select speed type here",
    },
    {
      selector: '[data-node-key="CII Dynamics"]',
      content: " Check cii dynamics tab after filling port itinerary",
    },
    {
      selector: '[data-node-key="EU ETS"]',
      content: " Check EUETS tab after filling port itinerary",
    },
    {
      selector: "#pnl",
      id: "pnl",
      content: " Check pnl",
    },
    // {
    //   selector: "#map",
    //   id: "map",
    //   content: " Check Map Intelligence",
    // },
    {
      selector: "#bunker",
      id: "bunker",
      content: " Check Bunker live prices",
    },
    {
      selector: "#save",
      content: " Save form",
    },
    {
      selector: "#plus",
      content: " Create new form",
    },
    {
      selector: "#refresh",
      content: " Refresh form",
    },
  ],

  "#/tc-est-fullestimate": [
    {
      selector: "#vessel_id",
      content: "Select vessel here",
    },
    {
      selector: "#my_company",
      content: "Select company lob",
    },
    {
      selector: "#tci_code",
      content: "Select TCI  code",
    },
    {
      selector: "#tco_code",
      content: "Select TCO  code",
    },
    {
      selector: "#commence_date",
      content: "Fill commence date",
    },
    {
      selector: "#completing_date",
      content: "Fill completing date",
    },

    {
      selector: "#port",
      content: "Select port here",
    },
    {
      selector: "#funct",
      content: "Select function here",
    },
    {
      selector: "#passage",
      content: "Select passage here",
    },
    {
      selector: "#s_type",
      content: "Select speed type here",
    },
    {
      selector: '[data-node-key="CII Dynamics"]',
      content: "Check cii dynamics tab after filling port itinerary",
    },
    {
      selector: '[data-node-key="EU ETS"]',
      content: "Check EUETS tab after filling port itinerary",
    },
    {
      selector: "#pnl",
      id: "pnl",
      content: "Check pnl",
    },

    {
      selector: "#map",
      id: "map",
      content: "Check Map Intelligence",
    },

    {
      selector: "#bunker",
      id: "bunker",
      content: "Check Bunker live prices",
    },
    {
      selector: "#bunker_plan",
      id: "map",
      content: "Check Bunker plan here",
    },
    {
      selector: "#save",
      content: "Save form",
    },
    {
      selector: "#plus",
      content: "Create new form",
    },
    {
      selector: "#refresh",
      content: "Refresh form",
    },
  ],
  "#/chartering-dashboard": [
    {
      selector: "#Chartering",
      content: "Check chartering here",
      position: "right",
    },
    {
      selector: "#operation",
      content: "Check operation here",
      position: "right",
    },
    {
      selector: "#finance",
      content: "Check finance here",
      position: "right",
    },
    {
      selector: "#oceannmail",
      content: "Check oceann mail here",
      position: "right",
    },
    {
      selector: "#serviceDesk",
      content: "Check noon portal and other services here",
      position: "bottom",
    },
  ],

  "#/service-desk": [
    {
      selector: "#noonPortal",
      content: "Check Noon portal here",
      position: "bottom",
    },
  ],

  "#/bunker-invoice-list": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],
  "#/PDA-list": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],
  "#/FDA-list": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],

  "#/claim-listing": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],
  "#/dispute-claim-listing": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],

  "#/other-expense-list": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],

  "#/other-revenue-list": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],
  "#/OffHire-Deviation-list": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],

  "#/initial-freight-invoice-summary": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],

  "#/freight-commission-list": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],

  "#/hire-payable-list": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],

  "#/hire-receivable-list": [
    {
      selector: "#graph",
      content: "Check graph here",
      position: "bottom",
    },
  ],
};
