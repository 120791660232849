import * as types from '../constants/actionTypes';

const initialState = {
    data:[],
    key:""
}
const chat = (state = initialState, action) => {
    switch (action.type) {
      case types.SET_CHAT_DATA:
        return {
          ...state,
          data: action.payload.data,
          key:action.payload.key
        };
      case types.RESET_CHAT_DATA:
        return {
          data: [],
          key:""
        };

      default:
        return state;
    }
  }
  export default chat;