import MailLayout from "./MailLayout";

const SpamList = () => {
    return(
        <>
        <MailLayout spam={true} />
        </>
    )
}

export default SpamList;