import { Button, Card, Flex, Input, Select, Table, Typography } from "antd";
// import DynamicMap from "../dynamic-vspm/DynamicMap";
import mapboxgl from "mapbox-gl";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  SearchOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../../../shared";
import { LoadingOutlined } from "@ant-design/icons";
import SpotPrice from "../../../port-to-port/SpotPrice";
import moment from "moment";
import WeatherInfo from "./WeatherInfo";
import PortDetails from "./PortDetails";

mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_TOKEN}`;

const columns = [
  {
    title: "Port",
    dataIndex: "port",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Country",
    dataIndex: "country",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Grade",
    dataIndex: "grade",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Price($)",
    dataIndex: "price",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Latitude",
    dataIndex: "latitude",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Longitude",
    dataIndex: "longitude",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Price Type",
    dataIndex: "priceType",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Last Updated",
    dataIndex: "lastUpdated",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Net Change($)",
    dataIndex: "netChange",
    render: (el) => {
      if (el && el > 0) {
        return (
          <span
            className="spot-first"
            style={{ color: "#16F529", align: "middle" }}
          >
            {"$ "}
            {el.toFixed(2)}{" "}
            <ArrowUpOutlined color="green" className="hack_arrow" />
          </span>
        );
      } else if (el && el < 0) {
        return (
          <span className="spot-first" style={{ color: "red" }}>
            {"$ "}
            {el.toFixed(2)}
            &nbsp; <ArrowDownOutlined color="red" className="hack_arrow" />
          </span>
        );
      } else {
        return "---";
      }
    },
  },
];

const PortInfo = (props) => {
  const [filters, setFilters] = useState({
    port_name: "",
    week: "1",
    year: "2024",
  });

  //   const [portData, setPortData] = useState(null);

  const [coords, setCoords] = useState({
    lng: "",
    lat: "",
  });

  const [mapcoords, setMapcoords] = useState({
    lng: "",
    lat: "",
  });
  const [portName, setPortName] = useState("");
  const [portNameOptions, setPortNameOptions] = useState([]);
  const [portCongestion, setPortCongestion] = useState({});
  const [selectedPort, setSelectedPort] = useState("");
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(false);
  const mapContainer = useRef(null);
  const { Text } = Typography;
  const { Option } = Select;
  const [currentPort, setCurrentPort] = useState(null);
  const [market, setMarket] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [ShipClass, setShipClass] = useState([]);
  // const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedShipClass, setSelectedShipClass] = useState("");
  const [state, setState] = useState({
    isLoading: false,
    bunkerPrice: [],
    weatherData: null,
    currentPort: props?.portInfo?.portData || null,
  });

  const borderBottom = {
    borderBottom: "1px solid #dae3dc",
    minHeight: "30px",
  };
  const labelStyle = {
    color: "rgb(92 92 92)",
    lineHeight: "2",
    fontWeight: "500",
  };

  const [pageSize, setPageSize] = useState(10);
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    if (props?.BunkerExSpotPrices?.length > 0) {
      let data = props?.BunkerExSpotPrices?.filter(
        (item) => item?.port == props?.portInfo?.portData?.port_name
      );
      setState((prevState) => ({
        ...prevState,
        bunkerPrice: data,
      }));
    }
  }, [props.portInfo]);

  const fetchPortWeatherInfo = async (data) => {
    let date = moment().format("YYYY-MM-DD HH:mm");
    try {
      const url = `${process.env.REACT_APP_VM_LOG}ext-api/v1/PortWeatherBunker/weather?lat=${data?.lat}&lng=${data?.lng}&dateW=${date}`;
      const resp = await getAPICall(url);
      if (resp?.length) {
        setWeatherData(resp[0]);
        setState((prevState) => ({ ...prevState, weatherData: resp[0] }));
      } else {
        setWeatherData(null);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    const initializeMap = () => {
      const maxBounds = [
        [-180, -90], // Southwest coordinates
        [180, 90], // Northeast coordinates
      ];

      setMapcoords({
        lng: props?.portInfo?.portData?.longitude,
        lat: props?.portInfo?.portData?.latitude,
        // lat:18.9483,
        // lng:72.8442
      });

      fetchPortWeatherInfo({
        lng: props?.portInfo?.portData?.longitude,
        lat: props?.portInfo?.portData?.latitude,
      });

      const mapInstance = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [0, 0],
        minZoom: 2,
        projection: "mercator",
        maxBounds: maxBounds,
        zoom: 2,
        preserveDrawingBuffer: true,
      });

      mapInstance.addControl(new mapboxgl.NavigationControl());
      setMap(mapInstance);
    };

    initializeMap();

    // return () => {
    //   if (map) {
    //     map?.remove();
    //   }
    // };
  }, [props]);

  useEffect(() => {
    if (map && mapcoords.lng && mapcoords.lat) {
      const marker = new mapboxgl.Marker()
        .setLngLat([mapcoords.lng, mapcoords.lat])
        .addTo(map);

      map.flyTo({
        center: [mapcoords.lng, mapcoords.lat],
        zoom: 10,
        essential: true,
      });

      return () => {
        marker.remove();
      };
    }
  }, [map, mapcoords]);

  return (
    <div className="mt-1">
      {/* <div className="card p-3 me-2 ms-2 m-2">
        <span className="d-block mb-2">
          <b>Search Filters :</b>
        </span>
        <div className="row align-items-end">
          <div className="col-md-2">
            <label className="pc-label mb-1">
              Port Name <span style={{ color: "red" }}>*</span>
            </label>
            {!props.ports ? (
              <Select
                showSearch
                placeholder="Enter minimum 3 Letters*"
                bordered
                onChange={handlePortNameSelect}
                onSearch={getPortName}
                value={
                  portNameOptions.length === 0
                    ? "Enter minimum 3 Letters*"
                    : undefined
                }
                filterOption={false}
              >
                {portNameOptions.map((option) => (
                  <Option
                    lat={option.centery}
                    long={option.centerx}
                    key={option.port_id}
                    value={option.port_id}
                  >
                    {option.port_name}
                  </Option>
                ))}
              </Select>
            ) : (
              <Select
                onSelect={handleSelectPort}
                options={props.ports.map((option) => ({
                  lable: option.port_name,
                  value: option.port_name,
                  key: option.id,
                }))}
                value={currentPort}
              />
            )}
          </div>
          <div className="col-md-1">
            <label className="pc-label mb-1">
              Week <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              type="number"
              name="week"
              id="week"
              placeholder="Week"
              bordered
              value={filters.week}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-2">
            <label className="pc-label mb-1">
              Year <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              placeholder="Select Year"
              defaultActiveFirstOption
              defaultValue={filters.year}
              onChange={handleSelectChange}
              showSearch
              allowClear
            >
              <Option value="2024">2024</Option>
              <Option value="2023">2023</Option>
            </Select>
          </div>
          <div className="col-md-2">
            <label className="pc-label mb-1">
              Market Name <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              showSearch
              placeholder={
                <span style={{ fontWeight: "normal" }}>Select Market</span>
              }
              defaultActiveFirstOption
              onChange={(value) => handleMarketChange(value)}
            >
              {market.length > 0 &&
                market.map((item, index) => (
                  <Option key={index} value={item.market_name}>
                    {item.market_name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="col-md-2">
            <label className="pc-label mb-1">
              Ship Class <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              showSearch
              placeholder={
                <span style={{ fontWeight: "normal" }}>
                  {ShipClass.length > 0
                    ? "Select Ship Class"
                    : "Select Market First!!"}
                </span>
              }
              defaultActiveFirstOption
              onChange={(value) => handleShipClassChange(value)}
              style={{ minWidth: "200px" }}
            >
              {ShipClass.length > 0 &&
                ShipClass.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          </div>
          <div
            style={{ display: "flex", marginLeft: "36px" }}
            className="col-md-2"
          >
            <Button
              style={{
                background:
                  portNameOptions.length === 0 ? "grey !important" : "primary",
                display: "flex",
                alignItems: "center",
                gap: "1px",
              }}
              disabled={
                portNameOptions.length === 0 && !currentPort ? true : false
              }
              onClick={() => handleSearch(portCongestion.PORT_ID)}
            >
              <span> Search</span>
              {loading ? (
                <LoadingOutlined style={{ fontSize: "14px" }} />
              ) : (
                <SearchOutlined style={{ margin: "0px", marginRight: "0px" }} />
              )}
            </Button>
            <Button
              style={{ display: "flex", alignItems: "center" }}
              color="primary"
              onClick={handleReset}
            >
              <span> Reset</span> <UndoOutlined />
            </Button>
          </div>
        </div>
      </div> */}

      <div className="d-flex gap-2 m-2">
        <div className="p-2 card" style={{ width: "41%" }}>
          <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
            <span className="d-block mb-1">
              <b>See on Map :</b>
            </span>
            <div
              style={{
                overflow: "hidden",
                position: "relative",
                height: "100%",
                borderRadius: "6px",
              }}
            >
              {/* <DynamicMap zoom={3} /> */}
              <div
                ref={mapContainer}
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
        <div className="" style={{ width: "58%" }}>
          <PortDetails portData={props?.portInfo?.portData} />
        </div>
      </div>
      <hr />
      {state?.bunkerPrice?.length > 0 && (
        <div className="mt-3">
          <h3 className="pc-title">Bunker Price</h3>
          <Table
            // className="spot"
            columns={columns}
            dataSource={state?.bunkerPrice}
            size="medium"
            pagination={false}

            // pagination={{
            //   pageSize: pageSize,
            //   showSizeChanger: true,
            //   onShowSizeChange: (current, size) => {
            //     setPageSize(size);
            //   },
            //   total:
            //     props?.BunkerExSpotPrices && props.BunkerExSpotPrices.length > 0
            //       ? props.BunkerExSpotPrices.length
            //       : 0,
            // }}
            // onChange={handleTableChange}
          />
        </div>
      )}
      <div className="mt-2">
        <h3>
          Weather at Port (
          <span style={{ fontStyle: "italic" }}>
            {props?.portInfo?.portData?.port_name}
          </span>
          )
        </h3>
        {/* <h3 style={{ borderBottom: '2px solid #1890ff', paddingBottom: '10px', marginBottom: '20px', textAlign:"center" }}>Weather at Port</h3> */}
        <WeatherInfo weatherData={state?.weatherData} />,
      </div>
    </div>
  );
};

export default PortInfo;
