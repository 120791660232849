import React, { useState, useEffect } from "react";
import { Form, Select, Layout, Row, Col } from "antd";
import NormalFormIndex from "../NormalForm/normal_from_index";
const FormItem = Form.Item;
const Option = Select.Option;
const { Content } = Layout;
const DemDesForm = (props) => {
  const [state, setState] = useState({
    formData:props.formData,
    frmCode: "dem_des_form",
    frmName: "dem_des_form",
    frmVisible: true,
  });

  useEffect(() => {
    let portDays = props?.formData?.days ?? 0;
    let xtraPortDays = props?.formData?.xpd ?? 0;
    let esstimateDays = props?.formData?.e_p_days ?? 0;
    let demDesdays = esstimateDays - (portDays * 1 + xtraPortDays * 1);
 
    // let desDays = demDesdays > 0 ?60 :61;
   
    let _frmdata = {
      e_p_days: isNaN(esstimateDays) ? 0 : esstimateDays,
      days: isNaN(portDays) ? 0 : portDays,
      xpd: isNaN(xtraPortDays) ? 0 : xtraPortDays,
      // dem_rate: 0,
      // des_rate: 0,
      dem_des_days: isNaN(demDesdays) ? 0 : demDesdays.toFixed(2),
     // dem_des_amt: 0,
   
    };
   
    setState((prevState) => ({ ...prevState, formData: { ..._frmdata } }));
  }, []);

 





 
  



 
//   useEffect(() => {
//     let portDays = props?.formData?.days ?? 0;
//     let xtraPortDays = props?.formData?.xpd ?? 0;
//     let esstimateDays = props?.formData?.e_p_days ?? 0;
//     let demDesdays = esstimateDays - (portDays * 1 + xtraPortDays * 1);
//     let demDes = 'Des'; // Default value assuming positive
  
   
//     if (portDays < 0 || props?.formData?.dem_des_amt < 0) {
//       demDes = 'Dem';
//     }
//     console.log('demDes',demDes)
//     let _frmdata = {
//       e_p_days: isNaN(esstimateDays) ? 0 : esstimateDays,
//       days: isNaN(portDays) ? 0 : portDays,
//       xpd: isNaN(xtraPortDays) ? 0 : xtraPortDays,
//       dem_des_days: isNaN(demDesdays) ? 0 : demDesdays.toFixed(2),
//       dem_des: demDes, 
//     };
//     setState((prevState) => ({ ...prevState, formData: { ..._frmdata } }));
//     console.log('_frmdata',_frmdata)
//   }, []);
  
//  console.log('props',props)



  // useEffect(() => {
  //   let portDays = props?.formData?.days ?? 0;
  //   let xtraPortDays = props?.formData?.xpd ?? 0;
  //   let esstimateDays = props?.formData?.e_p_days ?? 0;
  //   let demDesdays = esstimateDays - (portDays * 1 + xtraPortDays * 1);
  
   
  //   let demDes = portDays < 0 || props?.formData?.dem_des_amt < 0 ? 60 : 61;
  //   console.log('demdes',demDes)
  
  //   let _frmdata = {
  //     e_p_days: isNaN(esstimateDays) ? 0 : esstimateDays,
  //     days: isNaN(portDays) ? 0 : portDays,
  //     xpd: isNaN(xtraPortDays) ? 0 : xtraPortDays,
  //     dem_des_days: isNaN(demDesdays) ? 0 : demDesdays.toFixed(2),
  //     des_days:demDes
      
  //   };
  
  //   setState((prevState) => ({ ...prevState, formData: { ..._frmdata } }));
  // }, []);
  


  const ImportFormData = (data) => {
    props.modalCloseEvent(data);
  };


  return (
    <div className="body-wrapper">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <Row gutter={16} style={{ marginRight: 0 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="body-wrapper">
                  <article className="article toolbaruiWrapper">
                    <div className="box box-default">
                      <div className="box-body">
                        {state.frmVisible ? (
                          <NormalFormIndex
                            key={"key_" + state.frmName + "_0"}
                            formClass="label-min-height"
                            formData={state.formData}
                            showForm={true}
                            frmCode={state.frmName}
                            frmVisible={state.frmVisible}
                            addForm={true}
                            inlineLayout={true}
                            showButtons={[
                              {
                                id: "import",
                                title: "import",
                                type: "primary",
                                event: (data) => {
                                  ImportFormData(data);
                                },
                              },
                            ]}
                          />
                        ) : undefined}
                      </div>
                    </div>
                  </article>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default DemDesForm;
