import React from 'react';
import { Spin, Row, Col, Alert } from 'antd';

const contentStyle = {
  // padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};

const content = <div style={contentStyle} />;

const spinStyle = {
  // width: 50,
  // height: 50,
  animation: 'spin 1s linear infinite',
};

const customSpinIndicator = (
  <img
    src="https://d2olrrfwjazc7n.cloudfront.net/website/assets/loader/loaderSvg.svg"
    alt="Loading"
    style={spinStyle}
  />
);

const keyframes = `
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;

const CustomLoader = () => (
  <>
    <style>{keyframes}</style>
    <Row gutter={[16, 16]} justify="center">
      <Col>
        <Spin indicator={customSpinIndicator} size="large">
          <Alert
            message=" "
            description="Please wait..."
            type="info"
          />
          {content}
        </Spin>
      </Col>
    </Row>
  </>
);

export default CustomLoader;
