import React from "react";
import { EditOutlined,DeleteOutlined } from "@ant-design/icons";


class RightContextMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      "menuProperties": this.props.menuProperties || undefined,
      "tableName": this.props.tableName || undefined,
      "rowAttr": this.props.rowAttr,
      "record": this.props.record,
      "visible": this.props.visible,
      "x": this.props.x,
      "y": this.props.y,
      "showMenuOptions": this.props.menuOptions || [
        {title: "Edit", key: "menu-evt-edit", "icon": <EditOutlined />, event: (record, rowAttr) => { this.onClickEvent("edit", record, rowAttr); }},
        {title: "Delete", key: "menu-evt-delete", "icon": <DeleteOutlined />, event: (record, rowAttr) => { this.onClickEvent("delete", record, rowAttr); }}
      ]
    }
  }

  onClickEvent = (evtType, record, rowAttr) => this.props.eventListener({eventType: evtType, record: record, rowAttr: rowAttr});

  render() {
    const { showMenuOptions, record, visible, x, y, rowAttr, tableName, menuProperties } = this.state;
    const _menuProperties = menuProperties && menuProperties[tableName] ? menuProperties[tableName] : undefined
    return <>
      {
        visible ?
        <ul className="popup" style={{"left": `${x}px`, "top": `${y}px`}}>
          {showMenuOptions.map(e => {
            if (!_menuProperties) {
              return <li key={e.key} onClick={() => e.event(record, rowAttr)}>
                {e.icon ? e.icon : undefined}
                {e.title}
              </li>
            } else if (_menuProperties && _menuProperties.length > 0 && _menuProperties.indexOf(e.key) >= 0) {
              return <li key={e.key} onClick={() => e.event(record, rowAttr)}>
                {e.icon ? e.icon  : undefined}
                {e.title}
              </li>
            }
          })}
        </ul> : undefined
      }
    </>
  }
}

export default RightContextMenu