import React from 'react';
import { Modal, Table } from 'antd';
import ToolbarUI from '../../../components/CommonToolbarUI/toolbar_index'
import URL_WITH_VERSION, { getAPICall, objectToQueryStringFunc } from '../../../shared';
import { FIELDS } from '../../../shared/tableFields';


class VoyageList extends React.Component {
  constructor(props) {
    super(props);
    let tableHeaders = Object.assign([], FIELDS && FIELDS['voyage-list'] ? FIELDS['voyage-list']["tableheads"] : [])
    this.state = {
      loading: false,
      columns: tableHeaders,
      responseData: [],
      pageOptions: { pageIndex: 1, pageLimit: 20, totalRows: 0 },
      typesearch:{}
    };
  }

  componentDidMount = () => {
    this.setState({ ...this.state, loading: true }, () => this.getTableData());
  }

  getTableData = async (searchtype = {}) => {
    const { pageOptions } = this.state;

    let qParams = { "p": pageOptions.pageIndex, "l": pageOptions.pageLimit };
    let headers = { "order_by": { "id": "desc" } };
    let search=searchtype && searchtype.hasOwnProperty('searchOptions') && searchtype.hasOwnProperty('searchValue')?searchtype:this.state.typesearch;

    if (search && search.hasOwnProperty('searchValue') && search.hasOwnProperty('searchOptions') && search['searchOptions'] !== '' && search['searchValue'] !== '') {
      let wc = {}
      if (search['searchOptions'].indexOf(';') > 0) {
        let so = search['searchOptions'].split(';');
        wc = { "OR": {} };
        so.map(e => wc['OR'][e] = { "l": search['searchValue'] });
      } else {
        wc = { "OR": {} }
        wc["OR"][search['searchOptions']] = { "l": search['searchValue'] }
        // wc[search['searchOptions']] = { l: search['searchValue'] };

      }

      headers['where'] = wc;
      this.state.typesearch={'searchOptions':search.searchOptions,'searchValue':search.searchValue}

    }
    this.setState({
      ...this.state,
      loading: true,
      responseData: []
    });

    let qParamString = objectToQueryStringFunc(qParams);

    let _url = `${URL_WITH_VERSION}/voyage-manager/list?${qParamString}`;
    const request = await getAPICall(_url, headers);
    const response = await request;
    const totalRows = response && response.total_rows ? response.total_rows : 0;
    this.setState({
      ...this.state,
      responseData: (response && response.data ? response.data : []),
      pageOptions: { pageIndex: pageOptions.pageIndex, pageLimit: pageOptions.pageLimit, totalRows: totalRows },
      loading: false
    });
  }

  callOptions = (evt) => {
    if (evt.hasOwnProperty('searchOptions') && evt.hasOwnProperty('searchValue')) {
      let pageOptions = this.state.pageOptions;
      let search = { 'searchOptions': evt['searchOptions'], 'searchValue': evt['searchValue'] };
      pageOptions['pageIndex'] = 1;
      this.setState({ ...this.state, search: search, pageOptions: pageOptions }, () => {
        this.getTableData(search);
      });

    } else if (evt && evt.hasOwnProperty('actionName') && evt['actionName'] === 'reset-serach') {
      let pageOptions = this.state.pageOptions;
      pageOptions['pageIndex'] = 1;
      this.setState({ ...this.state, search: {}, pageOptions: pageOptions,typesearch:{} }, () => {
        this.getTableData();
      });

    } else if (evt && evt.hasOwnProperty('actionName') && evt['actionName'] === 'column-filter') {
      // column filtering show/hide
      let responseData = this.state.responseData;
      let columns = Object.assign([], this.state.columns);
      // console.log(columns)

      this.setState({
        ...this.state,
        sidebarVisible: (evt.hasOwnProperty("sidebarVisible") ? evt.sidebarVisible : !this.state.sidebarVisible),
        columns: (evt.hasOwnProperty("columns") ? evt.columns : columns)
      })
    } else {
      let pageOptions = this.state.pageOptions;
      pageOptions[evt['actionName']] = evt['actionVal'];

      if (evt['actionName'] === 'pageLimit') {
        pageOptions['pageIndex'] = 1;
      }

      this.setState({ ...this.state, pageOptions: pageOptions }, () => {
        this.getTableData()
      })
    }
  }

  render() {
    const { responseData, columns, loading, search, pageOptions } = this.state
    return (
      <div className="body-wrapper">
        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <div className="form-wrapper">
              </div>
              <div className="section" style={{ width: '100%', marginBottom: '10px', paddingLeft: '15px', paddingRight: '15px' }}>
                {
                  loading === false ?
                    <ToolbarUI routeUrl={'voyage-manager-list-toolbar'} optionValue={{ 'pageOptions': pageOptions, 'columns': columns, 'search': search }} callback={(e) => this.callOptions(e)}
                      dowloadOptions={[
                        { title: 'CSV', event: () => this.onActionDonwload('csv', 'voyage') },
                        { title: 'PDF', event: () => this.onActionDonwload('pdf', 'voyage') },
                        { title: 'XLS', event: () => this.onActionDonwload('xlsx', 'voyage') }
                      ]}
                    />
                    : undefined
                }
              </div>
              <div>
                <Table
                  className="inlineTable editableFixedHeader resizeableTable"
                  bordered
                  columns={columns}
                  scroll={{ x: 'max-content' }}
                  dataSource={responseData}
                  loading={loading}
                  pagination={false}
                  rowClassName={(r, i) => ((i % 2) === 0 ? 'table-striped-listing' : 'dull-color table-striped-listing')}
                />
              </div>
            </div>
          </div>
        </article>


      </div>
    );
  }
}

export default VoyageList;