import React, { Component } from "react";
import { Modal } from "antd";

var initialState = {
  modalHeader: null,
  modalBody: null,
  modalStatus: false,
  modalWidth: "90%",
  modalFooter: null,
  onCancelFunc: null,
};

class ModalAlertBox extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  static getDerivedStateFromProps(props, state) {
    if (props && !state.modalBody) {
      return props;
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    let currentProps = this.props;
    if (prevProps.modalStatus !== currentProps.modalStatus) {
      this.setState(currentProps);
    }
  }
  onCancel = () => {
    if (typeof this.props.onCancelFunc === "function") {
      this.props.onCancelFunc();
    }
  };

  render() {
    let { modalStatus, modalHeader, modalBody, modalWidth, modalFooter } =
      this.state;

    return (
      <Modal
        title={modalHeader}
        open={modalStatus}
        width={modalWidth}
        footer={modalFooter}
        onCancel={() => this.onCancel()}
        className="success"
        style={{ top: "2%" }}
      >
        {modalBody
          ? typeof modalBody === "string"
            ? modalBody
            : modalBody()
          : null}
      </Modal>
    );
  }
}

ModalAlertBox.defaultProps = initialState;

export default ModalAlertBox;
