import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table, Input, Button, Modal } from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  SearchOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import URL_WITH_VERSION, {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../shared";
import FuturePrice from "./FuturePrice";
import { useDispatch, useSelector } from "react-redux";
import { deleteChatData } from "../../actions/chatActions";
import { useLocation } from "react-router";

const columns = [
  {
    title: "Port",
    dataIndex: "port",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Country",
    dataIndex: "country",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Grade",
    dataIndex: "grade",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Price($)",
    dataIndex: "price",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Latitude",
    dataIndex: "latitude",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Longitude",
    dataIndex: "longitude",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Price Type",
    dataIndex: "priceType",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Last Updated",
    dataIndex: "lastUpdated",
    align: "left",
    render: (el) => <span className="spot-first">{el}</span>,
  },
  {
    title: "Net Change($)",
    dataIndex: "netChange",
    render: (el) => {
      if (el && el > 0) {
        return (
          <span
            className="spot-first"
            style={{ color: "#16F529", align: "middle" }}
          >
            {"$ "}
            {el.toFixed(2)}{" "}
            <ArrowUpOutlined color="green" className="hack_arrow" />
          </span>
        );
      } else if (el && el < 0) {
        return (
          <span className="spot-first" style={{ color: "red" }}>
            {"$ "}
            {el.toFixed(2)}
            &nbsp; <ArrowDownOutlined color="red" className="hack_arrow" />
          </span>
        );
      } else {
        return "---";
      }
    },
  },
];
const SpotPrice = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState(null);
  const [futurePricedata, setFuturePriceData] = useState(
    props.BunkerExFuturePrices || [{}]
  );
  const [filteredData, setFilteredData] = useState([{}]);
  const [pageSize, setPageSize] = useState(50);
  const bunkerDataFromChat = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const location  = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;

    // Function to clear chat data if navigating away from /voyage path
    const clearChatData = () => {
      if (bunkerDataFromChat?.key === "filter_bunker_price") {
        dispatch(deleteChatData());
      }
    };

    // Set up the cleanup function only if on the /voyage path
    if (currentPath === "/spot-price") {
      return () => {
        if (location.pathname !== "/spot-price") {
          clearChatData();
        }
      };
    }

    // Cleanup effect for all other paths
    return () => {};

  }, [dispatch, location, bunkerDataFromChat]);

  const getportdata = async () => {
    let dataArr = [];
    let respdata;
    let futureDataArr = [];
    if (bunkerDataFromChat?.key === "filter_bunker_price") {
      //TODO: set setData & setFuturePriceData value from chat response & prevent API call
      bunkerDataFromChat?.data.map((el) => {
        dataArr.push({
          port: el.Port,
          country: el.Country,
          grade: el.Grade,
          price: el.Price,
          latitude: el.Latitude,
          longitude: el.Longitude,
          priceType: el.PriceType,
          lastUpdated: moment(el.LastUpdatedOnUTC).format("YYYY-MM-DD HH:mm"),
          netChange: el.NetChange,
        });
        futureDataArr.push({
          port: el.Port,
          futureMonth: moment(el.FutureMonth).format("YYYY-MM-DD"),
          grade: el.Grade,
          lastUpdated: moment(el.LastUpdatedOnUTC).format("YYYY-MM-DD HH:mm"),
          price: el.Price,
        });
      });
      setData(dataArr);
      setFilteredData(dataArr);
      setFuturePriceData(futureDataArr);
    } else {
      try {
        setIsLoading(true);
        if (!props.BunkerExSpotPrices) {
          const data = await getAPICall(
            `${URL_WITH_VERSION}/port-bunker-activity/spotprice/fast`
          );

          respdata = await data.data;
        }

        if (
          !props.BunkerExSpotPrices &&
          respdata.BunkerExSpotPrices &&
          respdata.BunkerExSpotPrices.length > 0
        ) {
          respdata.BunkerExSpotPrices.map((el) => {
            dataArr.push({
              port: el.Port,
              country: el.Country,
              grade: el.Grade,
              price: el.Price,
              latitude: el.Latitude,
              longitude: el.Longitude,
              priceType: el.PriceType,
              lastUpdated: moment(el.LastUpdatedOnUTC).format(
                "YYYY-MM-DD HH:mm"
              ),
              netChange: el.NetChange,
            });
          });
          setData(dataArr);
          setFilteredData(dataArr);
          setIsLoading(false);
        }
        setIsLoading(false);
        // let futureDataArr = [];
        if (
          !props.BunkerExFuturePrices &&
          respdata.BunkerExFuturePrices &&
          respdata.BunkerExFuturePrices.length > 0
        ) {
          respdata.BunkerExFuturePrices.map((el) => {
            futureDataArr.push({
              port: el.Port,
              futureMonth: moment(el.FutureMonth).format("YYYY-MM-DD"),
              grade: el.Grade,
              lastUpdated: moment(el.LastUpdatedOnUTC).format(
                "YYYY-MM-DD HH:mm"
              ),
              price: el.Price,
            });
          });
          setFuturePriceData(futureDataArr);
        }
      } catch (err) {
        console.log("something went wrong", err);
      }
    }
  };

  const live_bunker = async () => {
    let dataArr = [];
    let respdata;
    try {
      //setIsLoading(true);
      if (!props.BunkerExSpotPrices) {
        const data = await getAPICall(
          `${URL_WITH_VERSION}/port-bunker-activity/spotprice/live`
        );

        respdata = data.data;
      }

      // setIsLoading(true);

      if (
        !props.BunkerExSpotPrices &&
        respdata.BunkerExSpotPrices &&
        respdata.BunkerExSpotPrices.length > 0
      ) {
        respdata.BunkerExSpotPrices.map((el) => {
          dataArr.push({
            port: el.Port,
            country: el.Country,
            grade: el.Grade,
            price: el.Price,
            latitude: el.Latitude,
            longitude: el.Longitude,
            priceType: el.PriceType,
            lastUpdated: moment(el.LastUpdatedOnUTC).format("YYYY-MM-DD HH:mm"),
            netChange: el.NetChange,
          });
        });
        setData(dataArr);
        setFilteredData(dataArr);
        setIsLoading(false);
      }

      // else if(props.BunkerExSpotPrices.length){

      //   props.BunkerExSpotPrices.map((el) => {
      //     dataArr.push({
      //       port: el.Port,
      //       country: el.Country,
      //       grade: el.Grade,
      //       price: el.Price,
      //       latitude: el.Latitude,
      //       longitude: el.Longitude,
      //       priceType: el.PriceType,
      //       lastUpdated: moment(el.LastUpdatedOnUTC).format("YYYY-MM-DD HH:mm"),
      //       netChange: el.NetChange,
      //     });
      //   });

      //   setData(dataArr);
      //   setIsLoading(false);
      // }

      // setIsLoading(false);
      let futureDataArr = [];
      if (
        !props.BunkerExFuturePrices &&
        respdata.BunkerExFuturePrices &&
        respdata.BunkerExFuturePrices.length > 0
      ) {
        respdata.BunkerExFuturePrices.map((el) => {
          futureDataArr.push({
            port: el.Port,
            futureMonth: moment(el.FutureMonth).format("YYYY-MM-DD"),
            grade: el.Grade,
            lastUpdated: moment(el.LastUpdatedOnUTC).format("YYYY-MM-DD HH:mm"),
            price: el.Price,
          });
        });

        setFuturePriceData(futureDataArr);
      }
    } catch (err) {
      console.log("something went wrong", err);
    }
  };

  const handleFuturePrice = () => {
    setModalVisible(true);
  };
  const handleCancelFuturePrice = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    (async () => {
      await getportdata();
      if(bunkerDataFromChat?.key !== "filter_bunker_price"){
        setTimeout(() => {
          live_bunker();
        }, 3000);
      }
    })();
  }, [bunkerDataFromChat]);

  // const onSearchFilter = (e) => {
  //   const { value } = e.target;

  //   if (value.length > 2) {
  //     const filtered = data.filter((item) => {
  //       return (
  //         item.port.toLowerCase().includes(value.toLowerCase()) ||
  //         item.country.toLowerCase().includes(value.toLowerCase()) ||
  //         item.grade.toLowerCase().includes(value.toLowerCase()) ||
  //         (item.price !== null &&
  //           item.price !== undefined &&
  //           item.price.toString().includes(value)) ||
  //         (item.netChange !== null &&
  //           item.netChange !== undefined &&
  //           item.netChange.toString().includes(value))
  //       );
  //     });

  //     setFilteredData(filtered);
  //   } else if (value.length === 0) {
  //     setFilteredData([]);
  //   }
  // };

  const onSearchFilter = (e) => {
    const { value } = e.target;

    const searchValue = value.toLowerCase();

    if (value.length >= 0) {
      const filtered = data.filter((item) => {
        const itemPortLower = item.port.toLowerCase();
        const itemCountryLower = item.country.toLowerCase();
        const itemGradeLower = item.grade.toLowerCase();
        const itemPriceString =
          item.price !== null && item.price !== undefined
            ? item.price.toString()
            : "";
        const itemNetChangeString =
          item.netChange !== null && item.netChange !== undefined
            ? item.netChange.toString()
            : "";

        return (
          itemPortLower.includes(searchValue) ||
          itemCountryLower.includes(searchValue) ||
          itemGradeLower.includes(searchValue) ||
          itemPriceString.includes(searchValue) ||
          itemNetChangeString.includes(searchValue)
        );
      });

      setFilteredData(filtered);
    }
  };

  const handleTableChange = (pagination) => {
    if (pagination.pageSize) {
      setPageSize(pagination.pageSize);
    }
  };

  let tableData =
    data > 0
      ? data
      : props.BunkerExSpotPrices
      ? props.BunkerExSpotPrices
      : filteredData;

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Input
            placeholder="Search"
            name="filter"
            prefix={<SearchOutlined className="site-form-item-icon" />}
            style={{ width: "300px", margin: "0 auto" }}
            onChange={onSearchFilter}
          />
        </div>

        <div style={{display:"flex"}}>
        {bunkerDataFromChat?.key == "filter_bunker_price" && (
                <div className="action-btn">
                  <Button
                    type="primary"
                    onClick={(e) => dispatch(deleteChatData())}
                  >
                    Reload <ReloadOutlined style={{ transform: "scaleX(-1)" }} />
                  </Button>
                </div>
              )}
          <Button type="primary" onClick={handleFuturePrice}>
            Future Price
          </Button>
        </div>
      </div>

      <Table
        className="spot"
        columns={columns}
        dataSource={tableData}
        size="medium"
        loading={isLoading}
        // pagination={{
        //   pageSize: 50,
        //   showSizeChanger: true,
        //   total: filteredData.length > 0 ? filteredData.length : data.length,
        // }}

        pagination={{
          pageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
          total:
            filteredData && filteredData.length > 0
              ? filteredData.length
              : data && data.length
              ? data.length
              : 0,
        }}
        onChange={handleTableChange}
      />

      <Modal
        title={
          <p style={{ textAlign: "center", fontSize: "x-large" }}>
            Future Bunker Price
          </p>
        }
        open={modalVisible}
        onCancel={handleCancelFuturePrice}
        width="max-content"
        style={{ top: 0, left: 0, marginTop: "1rem" }}
        footer={null}
      >
        <FuturePrice futurePricedata={futurePricedata} />
      </Modal>
    </div>
  );
};

export default SpotPrice;
