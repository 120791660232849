import { DownOutlined } from "@ant-design/icons";
import { Button, Menu, Popover } from "antd";
import MenuItem from "antd/es/menu/MenuItem";
import SubMenu from "antd/es/menu/SubMenu";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const EstimateMenu = (props) => {
  const [option, setOption] = useState([]);
  const navigate = useNavigate();
  const options = [
    {
      name: "Go To Voyage Estimate",
      listMenu: "Go To Voyage Estimate List",
      key: "voy-estimate",
      path: "/add-voyage-estimate",
      listPath: "/Voyage-Estimate-list",
    },
    {
      name: "Go To TC Estimate",
      listMenu: "Go To TC Estimate List",
      key: "tc-estimate",
      path: "/tc-est-fullestimate",
      listPath: "/TC-Estimate-list",
    },
    {
      name: "Go To Voy. Relet",
      listMenu: "Go To Voy. Relet List",
      key: "voy-relet",
      path: "/voy-relet-full-estimate",
      listPath: "/voy-relet-list",
    },
  ];

  useEffect(() => {
    const filteredOptions = options?.filter((ele) => ele.key !== props.data);
    setOption(filteredOptions);
  }, [props.data]);

  const handleNavigation = (estimateName, type) => {
    const chosenPath = option?.filter((ele) => ele.key == estimateName);
    const path = type == "page" ? chosenPath[0]?.path : chosenPath[0]?.listPath;
    navigate(path);
  };

  return (
    <>
      <Popover
        placement="bottom"
        destroyTooltipOnHide={true}
        content={
          <Menu>
            {option?.map((option, index) => (
              <React.Fragment key={index}>
                <MenuItem
                  key={`${option.key}-main`}
                  onClick={() => handleNavigation(option?.key,"page")}
                  style={{ cursor: "pointer", width: "12rem" }}
                >
                  {option?.name}
                </MenuItem>
                <MenuItem
                  key={`${option.key}-list`}
                  onClick={() => handleNavigation(option?.key,"list")}
                  style={{
                    cursor: "pointer",
                    width: "12rem",
                    // marginLeft: "1rem",
                  }}
                >
                  {option?.listMenu}
                </MenuItem>
              </React.Fragment>
              // <MenuItem key={index} style={{ width: "10rem" }}>
              //   <span
              //     className="option-menu"
              //     onClick={(e) => {
              //       handleNavigation(option?.key, "page");
              //     }}
              //     style={{ cursor: "pointer" }}
              //   >
              //     {option?.name}
              //   </span>
              //   <span
              //     className="option-menu"
              //     onClick={() => handleNavigation(option?.key, "list")}
              //   >
              //     -List
              //   </span>
              // </MenuItem>
            ))}
          </Menu>
        }
      >
        <Button
          style={{
            width: "8rem",
            padding: "0 1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "16rem",
            }}
          >
            <p style={{ margin: 0, flex: 1 }}>Estimates</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "0.5rem",
              }}
            >
              <DownOutlined />
            </div>
          </div>
        </Button>
      </Popover>
    </>
  );
};

export default EstimateMenu;
