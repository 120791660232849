import React from "react";
import URL_WITH_VERSION, { IMAGE_PATH } from "../../shared";
const FilterLocalDbViewItem = ({
  dataset,
  onGetSelectedData,
  style
}) => {
  const handleSelect = () => {
    onGetSelectedData(dataset)
  }

  return (
    <div className="list-item" onClick={handleSelect} style={style}>
      <img loading="lazy" src={IMAGE_PATH+"icons/ship.png"} alt="" />
      <div className="middle-wrapper">
        <span className="vessel-name">{dataset.vessel_name}</span>
        <div className="middle-wrapper-discription">
          <span>
            Port : {dataset.vessel_last_pos}
          </span>
          <span>
            IMO no. : {dataset.imo_no}
          </span>
          <span>
            Latitude : {dataset.last_pos_lat}
          </span>
          <span>
            Longitute : {dataset.last_pos_lon}
          </span>
        </div>
      </div>
      <div className="last-wrapper">
        <p>Speed : {dataset.speed}</p>
      </div>
    </div>
  )
}

export default FilterLocalDbViewItem;