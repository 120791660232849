import MailLayout from "./MailLayout";

const DraftList = () => {
    return(
        <>
        <MailLayout draft={true} />
        </>
    )
}

export default DraftList;