import { useState, useEffect, useRef} from "react";
import NormalFormIndex from "../../shared/NormalForm/normal_from_index";
import { Layout, Row, Col, Drawer, Modal } from "antd";
import {
  postAPICall,
  openNotificationWithIcon,
  getAPICall,
  apiDeleteCall,
  awaitPostAPICall,
  useStateCallback,
} from "../../shared";
import URL_WITH_VERSION from "../../shared";
import RightBarUI from "../../components/RightBarUI";
import moment from "moment";
import AttachmentFile from "../../shared/components/Attachment";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../shared/attachments";
import VesselFileReport from "../operation-reports/VesselFileReport";
import { DeleteOutlined, MenuFoldOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";


const { Content } = Layout;

const initialformdata = {
  ownership_type: "",
  vessel: "",
  vessel_code: "",
  commence: "",
  owner: "",
  beneficiary: "",
  my_company: "",
  fixed_by: "",
  con_type: "",
  config_date: "",
  release_date: "",
  v_status: "",
  id: "",
};

const  VesselFile  = (props) => {

  const [state, setState] = useStateCallback({
    frmName: "Vessel_file_form",
    formData: Object.assign({}, initialformdata, props.formdata),
    frmVisible: true,
    showTDE: false,
    showDownloadInvoice: false,
    tdeData: null,
    showInvoicePopup: false,
    invoiceReport: null,
    isSaved: false,
    popupdata: null,
    loadComponent: false,
    title: undefined,
    isshowVesselFileReport: false,
    reportFormData: null,
    isVesselfileFixed: false,
    isVesselFileSchedule: false,
  })

  const formdataref = useRef()


  useEffect(() => {
    const { formData } = state;
    if (formData && formData["id"]) {
      setState(prevState => ({
        ...prevState,
        frmVisible: false,
        formData: {
          ownership_type:
            (props.formdata && props.formdata.ownership_type) ||
            null,
          vessel: (props.formdata && props.formdata.vessel) || null,
          vessel_code:
            (props.formdata && props.formdata.vessel_code) || null,
          commence:
            (props.formdata && props.formdata.commence) || null,
          owner: (props.formdata && props.formdata.owner) || null,
          beneficiary:
            (props.formdata && props.formdata.beneficiary) || null,
          my_company:
            (props.formdata && props.formdata.my_company) || null,
          fixed_by:
            (props.formdata && props.formdata.fixed_by) || null,
          con_type:
            (props.formdata && props.formdata.con_type) || null,
          config_date:
            (props.formdata && props.formdata.config_date) || null,
          release_date:
            (props.formdata && props.formdata.release_date) || null,
          v_status:
            (props.formdata && props.formdata.v_status) || null,
          id: (props.formdata && props.formdata.id) || null,
        },
        isVesselfileFixed:
          props.formdata && props.formdata.is_fixed == 1
            ? true
            : false,
        isVesselFileSchedule:
          props.formdata && props.formdata.is_schedule == 1
            ? true
            : false,
      }),
        () => setState(prevState => ({ ...prevState, frmVisible: true }))
      );
    }
  },[])

  const saveFormData = (vData) => {
    let type = "save";
    let suMethod = "POST";
    if (vData.hasOwnProperty("id") && vData["id"]) {
      type = "update";
      suMethod = "PUT";
      vData = {
        ...vData,
        commence: vData.commence ? moment(vData.commence) : "",
        config_date: vData.config_date ? moment(vData.config_date) : "",
        release_date: vData.release_date ? moment(vData.release_date) : "",
      };
    }
    if (vData.hasOwnProperty("ownership_type") && vData["ownership_type"]==="") {
      vData["ownership_type"] = "162"
    }
    const { frmName } = state;
    formdataref.current = vData;
    setState(prevState => ({ ...prevState, frmVisible: false }));
    let suURL = `${URL_WITH_VERSION}/vessel-file/${type}`;
    postAPICall(suURL, vData, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message);

        setState(prevState => ({
          ...prevState,
          formData: {
            ...formdataref.current,
            id: data.data.id,
            vessel_code: data.data.vessel_code,
          },
        }));
        onEditClick(state.formData.id);
      } else {
        openNotificationWithIcon("error", data.message);
        setState(prevState => ({
          ...prevState,
          frmVisible: true,
          formData: formdataref.current,
        }));
      }
    });
  };

  const onEditClick = async (id) => {
    const { formData } = state;

    if (id) {
      setState(prevState => ({ ...prevState, frmVisible: false }));
      const response = await getAPICall(
        `${URL_WITH_VERSION}/vessel-file/edit?e=${id}`
      );
      const respData = await response["data"];
      setState(prevState => ({ ...prevState, formData: respData, frmVisible: true }));
    } else {
      openNotificationWithIcon("error", "Something went wrong", 4);
      setState(prevState => ({ ...prevState, frmVisible: true }));
    }
  };

  const _onCreateFormData = () => {
    setState(prevState =>({
      ...prevState,
      frmVisible: false,
      formData: { ...initialformdata },
      isVesselfileFixed: false,
      isVesselFileSchedule: false,
    }),
      () => setState(prevState => ({ ...prevState, frmVisible: true }))
    );
  };

  const onCloseDrawer = () =>
    setState(prevState => ({
      ...prevState,
      visibleDrawer: false,
      title: undefined,
      loadComponent: undefined,
    }));

  const onClickRightMenu = async (key, options) => {
    const { formData } = state;
    onCloseDrawer();
    let loadComponent = undefined;

    switch (key) {
      case "attachment":
        const { formData } = state;
        if (formData.id) {
          const attachments = await getAttachments(formData.id, "EST");
          const callback = (fileArr) =>
            uploadAttachment(fileArr, formData.id, "EST", "tci");
          loadComponent = (
            <AttachmentFile
              uploadType="Estimates"
              attachments={attachments}
              onCloseUploadFileArray={callback}
              deleteAttachment={(file) =>
                deleteAttachment(file.url, file.name, "EST", "tci")
              }
              tableId={0}
            />
          );
        } else {
          openNotificationWithIcon(
            "info",
            "Please save first, to Upload attachments."
          );
        }
        break;
      default:
        break;
    }

    setState(prevState => ({
      ...prevState,
      visibleDrawer: true,
      title: options.title,
      loadComponent: loadComponent,
      width: options.width && options.width > 0 ? options.width : 1200,
    }));
  };

  const _onDeleteFormData = (postData) => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => DeleteFormData(postData),
    });
  };

  const DeleteFormData = (data) => {
    const { frmName } = state;
    let URL = `${URL_WITH_VERSION}/vessel-file/delete`;

    if (data && data["id"]) {
      apiDeleteCall(URL, { id: data["id"] }, (resp) => {
        if (resp && resp.data) {
          openNotificationWithIcon("success", resp.message);
          if (props.hasOwnProperty("modalCloseEvent")) {
            props.modalCloseEvent();
          }else{
          _onCreateFormData();
          }
        } else {
          openNotificationWithIcon("error", resp.message);
        }
      });
    } else {
      openNotificationWithIcon("info", "Please fill the form first.", 3);
    }
  };

  const openVesselFileReport = async (showVesselFileReport) => {
    if (showVesselFileReport) {
      try {
        const responseReport = await getAPICall(
          `${URL_WITH_VERSION}/vessel-file/report?e=${
            state.formData["id"]
          }`
        );
        const respDataReport = await responseReport["data"];

        if (respDataReport) {
          setState(prevState => ({...prevState, reportFormData: respDataReport, isshowVesselFileReport: showVesselFileReport}))
        } else {
          openNotificationWithIcon("error", "Unable to show report", 5);
        }
      } catch (err) {
        openNotificationWithIcon("error", "Something went wrong.", 5);
      }
    } else {
      setState(prevState => ({...prevState, isshowVesselFileReport: showVesselFileReport}))
    }
  };

  const performFix = async () => {
    const { formData } = state;
    setState(prevState => ({ ...prevState, frmVisible:false}))
    if (formData && formData.hasOwnProperty("id") && formData["id"] > 0) {
      let _url = `${URL_WITH_VERSION}/vessel-file/fix`,
        data = { id: state.formData.id };
      let response = await awaitPostAPICall(_url, data);
      let respData = await response;
      if (respData["data"] !== false) {
        openNotificationWithIcon("success", respData.message);
        onEditClick(state.formData.id);
        setState(prevState => ({ ...prevState, isVesselfileFixed: true,frmVisible:true }));
      } else {
        let dataMessage = respData.message;
        let msg = "<div className='row'>";
        if (typeof dataMessage !== "string") {
          Object.keys(dataMessage).map(
            (i) =>
              (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
          );
        } else {
          msg += dataMessage;
        }
        msg += "</div>";
        openNotificationWithIcon(
          "error",
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        );
        setState(prevState => ({ ...prevState, frmVisible:true}))
      }
    } else {
      openNotificationWithIcon('info','Please save Vessel file First.',3)
    }
  };

  const scheduleVesselFile = async () => {
    const { formData } = state;

    const response = await getAPICall(
      `${URL_WITH_VERSION}/vessel-file/schedule/${state.formData["id"]}`
    );
    const respData = await response;
    setState(prevState => ({ ...prevState, frmVisible:false}))
    if (respData["data"] === true) {
      openNotificationWithIcon("success", respData["message"]);
      // if (formData && formData.id) {
      //   _onLeftSideListClick(formData.id)
      // }
      if (
        props.modalCloseEvent &&
        typeof props.modalCloseEvent === "function"
      ) {
        props.modalCloseEvent();
      }
      // window.location.href='#/vessel-file-list';
      onEditClick(state.formData.id);
      // setState({ ...state, frmVisible:true})
    } else if (respData["data"] === false) {
      let dataMessage = respData["message"];
      let msg = "<div className='row'>";

      if (typeof dataMessage !== "string") {
        Object.keys(dataMessage).map(
          (i) =>
            (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
        );
      } else {
        msg += dataMessage;
      }

      msg += "</div>";
      openNotificationWithIcon(
        "error",
        <div dangerouslySetInnerHTML={{ __html: msg }} />
      );
    }
  };

    const {
      formData,
      frmName,
      frmVisible,
      loadComponent,
      visibleDrawer,
      title,
      isshowVesselFileReport,
      reportFormData,
      isVesselfileFixed,
      isVesselFileSchedule,
    } = state;

    return (
      <div className="tcov-wrapper full-wraps voyage-fix-form-wrap cargo wrap-rightbar wrap-rightbar-full">
        <Layout className="layout-wrapper">
          <Layout>
            <Content className="content-wrapper">
              <Row gutter={16} style={{ marginRight: 0 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} />
                <div className="body-wrapper">
                  <article className="article">
                    <div className=" box-default">
                      <div className="box-body">
                        {frmVisible ? (
                          <NormalFormIndex
                            key={"key_" + frmName + "_0"}
                            formClass="label-min-height"
                            formData={formData}
                            showForm={true}
                            frmCode={frmName}
                            addForm={true}
                            inlineLayout={true}
                            showToolbar={[
                              {
                                leftWidth: 8,
                                rightWidth: 16,
                                isLeftBtn: [
                                  {
                                    key: "s1",
                                    isSets: [
                                      {
                                        id: "1",
                                        key: "menu-fold",
                                        type: <MenuFoldOutlined/>,
                                        withText: "List",
                                        showToolTip: true,
                                        event: "menu-fold",
                                      },
                                      {
                                        id: "2",
                                        key: "add",
                                        type: <PlusOutlined />,
                                        withText: "Add New",
                                        showToolTip: true,
                                        event: (key, data) =>
                                          _onCreateFormData(),
                                      },

                                      {
                                        id: "3",
                                        key: "save",
                                        type: <SaveOutlined />,
                                        withText: formData.id
                                          ? "Update"
                                          : "Save",
                                        showToolTip: true,
                                        event: (key, data) =>
                                          saveFormData(data),
                                      },
                                      {
                                        id: "4",
                                        key: "delete",
                                        type: <DeleteOutlined />,
                                        withText: "Delete",
                                        showToolTip: true,
                                        event: (key, data) =>
                                          _onDeleteFormData(data),
                                      },
                                    ],
                                  },
                                ],
                                isRightBtn: [
                                  {
                                    key: "s2",
                                    isSets: [
                                      {
                                        key: isVesselFileSchedule
                                          ? ""
                                          : isVesselfileFixed
                                            ? "schedule"
                                            : "fix",
                                        isDropdown: 0,
                                        withText: isVesselFileSchedule
                                          ? ""
                                          : isVesselfileFixed
                                            ? "Schedule"
                                            : "Fix",
                                        type: "",
                                        menus: null,
                                        event: () => {
                                          Modal.confirm({
                                            title: "Confirm",
                                            content: isVesselFileSchedule
                                              ? ""
                                              : isVesselfileFixed
                                                ? "Are you sure, you want to Schedule it?"
                                                : "Are you sure, you want to Fix it?",
                                            onOk: () =>
                                              isVesselFileSchedule
                                                ? ""
                                                : isVesselfileFixed
                                                  ? scheduleVesselFile()
                                                  : performFix(),
                                          });
                                        },
                                      },


                                      {
                                        key: "closed/open",
                                        isDropdown: 0,
                                        withText: "Closed/Open",
                                        type: "",
                                        menus: null,
                                        event: (key) => {
                                          //console.log(key);
                                        },
                                      },

                                      {
                                        key: "report",
                                        isDropdown: 0,
                                        withText: "Report",
                                        type: "",
                                        menus: null,
                                        event: (key) =>
                                          openVesselFileReport(true),
                                      },
                                    ],
                                  },
                                ],
                                isResetOption: false,
                              },
                            ]}
                            sideList={{
                              //statusColor : formData && formData[0]['tci_status_name'] &&  formData['tci_status_name'] == 'FIX' ? '#28a745' : formData['status_name'] == 'PENDING' ? '#01bcd4' : '#9e9e9e',
                              //selectedID: selectedID,
                              showList: true,
                              title: "Vessel File List",
                              uri: "/vessel-file/list?p=1&l=10",
                              columns: [
                                ["vessel_code", "v_status_name"],
                                "vessel_name",
                                "owner_name",
                              ],
                              icon: true,
                              searchString:
                                "Vessel File Code,Status,Vessel Name,Owner Name",
                              rowClickEvent: (evt) => onEditClick(evt.id),
                            }}
                          />
                        ) : (
                          undefined
                        )}
                      </div>
                    </div>
                  </article>
                </div>
              </Row>
            </Content>
          </Layout>

          <RightBarUI
            pageTitle="vessel-file-righttoolbar"
            callback={(data, options) => onClickRightMenu(data, options)}
          />

          {loadComponent !== undefined &&
          title !== undefined &&
          visibleDrawer === true ? (
            <Drawer
              title={state.title}
              placement="right"
              closable={true}
              onClose={onCloseDrawer}
             open={state.visibleDrawer}
              getContainer={false}
              style={{ position: "absolute" }}
              width={state.width}
              maskClosable={false}
              className="drawer-wrapper-container"
            >
              <div className="tcov-wrapper">
                <div className="layout-wrapper scrollHeight">
                  <div className="content-wrapper noHeight">
                    {state.loadComponent}
                  </div>
                </div>
              </div>
            </Drawer>
          ) : (
            undefined
          )}

          {isshowVesselFileReport ? (
            <Modal
              style={{ top: "2%" }}
              title="Reports"
              open={isshowVesselFileReport}
              onCancel={() => openVesselFileReport(false)}
              width="95%"
              footer={null}
            >
              <VesselFileReport data={reportFormData} />
            </Modal>
          ) : (
            undefined
          )}
        </Layout>
      </div>
    );
  }

export default VesselFile;
