import { Col, Row, Typography } from "antd";
import React from "react";
import {
  fulldate,
  parseSender,
  sendermaillReturn,
  toMailReturn,
} from "../../services/email_utils";

const containerStyle = {
  padding: "16px",
  fontFamily: "Arial, sans-serif",
};

const headerRowStyle = {
  display: "flex",
  alignItems: "flex-start", // Align items to the start (top) for consistent vertical alignment
  marginBottom: "8px",
};

const labelStyle = {
  fontWeight: "bold",
  whiteSpace: "nowrap",
  width: "70px",
  minWidth: "70px",
  maxWidth: "70px",
};

const valueStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const multiValueStyle = {
  display: "flex",
  flexDirection: "column",
};

const EmailHeader = ({ currentMail }) => {
  const { mailClient, ccRecipients, CC, bccRecipients, BCC, Subject, subject } =
    currentMail || {};

  const renderSenderEmailDetails = () => {
    const senderInfo = sendermaillReturn(currentMail);

    if (typeof senderInfo !== "string") {
      return "";
    }

    return senderInfo
      .split(",")
      .map((item) => {
        const { name, email } = parseSender(item);
        return `
            <div style="width: 14.5rem; overflow: hidden; text-overflow: ellipsis;">
              <span class="sender-name" style="font-weight: bold;">${name}</span>
              <span class="sender-email" style="color: gray; font-size: 0.9em;">&lt;${email}&gt;</span>
            </div>
          `;
      })
      .join("");
  };

  const renderRecipients = () => {
    const recipients = toMailReturn(currentMail);

    if (typeof recipients !== "string" && !Array.isArray(recipients)) {
      return "";
    }

    const recipientList =
      typeof recipients === "string" ? recipients.split(",") : recipients;

    return recipientList
      .map((item, index) => {
        const { name, email } = parseSender(item);
        return `
        <div key="${index}" style="width: 14.5rem; overflow: hidden; text-overflow: ellipsis;">
          <span class="sender-name" style="font-weight: bold;">${name}</span>
          <span class="sender-email" style="color: gray; font-size: 0.9em;">&lt;${email}&gt;</span>
        </div>
      `;
      })
      .join("");
  };

  const renderCcBccRecipients = (recipients) => {
    if (typeof recipients !== "string" && !Array.isArray(recipients)) {
      return "";
    }

    const recipientList =
      typeof recipients === "string" ? recipients.split(",") : recipients;

    return recipientList
      .map((item, index) => {
        const { name, email } = parseSender(item);
        return `
          <div key="${index}" style="width: 14.5rem; overflow: hidden; text-overflow: ellipsis;">
            <span class="sender-name" style="font-weight: bold;">${name}</span>
            <span class="sender-email" style="color: gray; font-size: 0.9em;">&lt;${email}&gt;</span>
          </div>
        `;
      })
      .join("");
  };

  return (
    <div style={containerStyle} onClick={(e)=>e.stopPropagation()}>
      <div style={headerRowStyle}>
        <div style={labelStyle}>From:</div>
        <div style={multiValueStyle}>
          {/* <div style={valueStyle}>Oner Tokcan / Nautical Bulk Carriers</div>
          <div style={valueStyle}>oner.tokcan@nbcarriers.com</div> */}

          {currentMail?.mailClient === "outlook" ? (
            <>
              <Typography sx={{ fontWeight: 500, marginLeft: "10px" }}>
                {currentMail?.from?.emailAddress?.name ?? ""}
              </Typography>
              <Typography sx={{ marginLeft: "10px" }}>
                {currentMail?.from?.emailAddress?.address ?? ""}
              </Typography>
            </>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: renderSenderEmailDetails(),
              }}
            ></div>
          )}
        </div>
      </div>
      <div style={headerRowStyle}>
        <div style={labelStyle}>To:</div>
        <div style={multiValueStyle}>
          {currentMail?.mailClient === "outlook" ? (
            currentMail?.toRecipients?.map((el, index) => (
              <Typography
                key={index}
                sx={{ fontWeight: "500", marginLeft: "10px" }}
              >
                {el?.emailAddress?.address ?? ""} {el?.emailAddress?.name ?? ""}
              </Typography>
            ))
          ) : (
            <div dangerouslySetInnerHTML={{ __html: renderRecipients() }}></div>
          )}
        </div>
      </div>

      <div style={headerRowStyle}>
        <div style={labelStyle}>Date:</div>

        <div span={20} style={valueStyle}>
          {fulldate(currentMail)}
        </div>
      </div>

      <div style={headerRowStyle}>
        <div style={labelStyle}>Subject:</div>

        <div style={valueStyle}>
          {Subject || subject ? (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  Subject?.replace(/[^a-zA-Z0-9 ]/g, "") ||
                  subject?.replace(/[^a-zA-Z0-9 ]/g, ""),
              }}
              style={{whiteSpace:"break-spaces",overflow:"hidden"}}
            ></div>
          ) : (
            <span>No subject</span>
          )}
        </div>
      </div>
      {/* <div style={headerRowStyle}>
        <div style={labelStyle}>Date:</div>
        <div style={valueStyle}>Sep 16, 2024, 4:53 PM</div>
      </div>
      <div style={headerRowStyle}>
        <div style={labelStyle}>Subject:</div>
        <div style={valueStyle}>
          {currentMail?.Subject || currentMail?.subject || ""}
        </div>
      </div> */}
    </div>
  );
};

export default EmailHeader;
