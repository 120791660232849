import React, { useEffect, useState } from "react";
import URL_WITH_VERSION, { getAPICall } from "../../../../shared";
import { Select } from "antd";

const VoyagePopUp = (props) => {
  let { id } = props.completeDataObject;
  let { tcbunkeradjustment } = props.completeDataObject;
  const [options, setOptions] = useState(null);
  useEffect(() => {
    let apiUrl = null;

    const getData = async () => {
      try {
        if (id) {
          if (
            props?.referenceElement?.id ===
            "tab_time_charter_in_tc_bunker_adj_form"
          ) {
            apiUrl = `${URL_WITH_VERSION}/tci/voyage/${id}`;
          } else if (
            props?.referenceElement?.id === "tab_tco_tc_bunker_adj_form"
          ) {
            apiUrl = `${URL_WITH_VERSION}/tco/voyage/${id}`;
          }

          const response = await getAPICall(apiUrl);
          const data = response.data;
          const newOptions = data.map((option) => ({
            key: option.id,
            label: option.voyage_number,
            value: option.name,
          }));
          setOptions(newOptions);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, []);

  const handleSelect = (val, option) => {
    // console.log("val....", val, option);
    props.selectVoyageNo({ voyage_number: option ? option.label : "" });
  };

  return (
    <>
      <Select
        placeholder="Select voyage number"
        value={
          tcbunkeradjustment[props.index > 0 ? props.index : 0]
            ?.voyage_number || undefined
        }
        allowClear={true}
        options={options}
        onChange={handleSelect}
      />
    </>
  );
};

export default VoyagePopUp;
