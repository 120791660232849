import { Icon } from "@iconify/react/dist/iconify.js";
import { message, Tooltip } from "antd";
import moment from "moment";
// import { useSelector } from "react-redux";
import Swal from "sweetalert2";

// const allMails = useSelector((state) => state.email.allEmails);

export const events = {
  markImportant: (userInput) => {
    console.log("socket event", userInput);
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
      if (userInput.important === true) {
        message.success("Marked as important.");
      } else {
        message.success("Marked as not important.");
      }
    } else {
      console.error("WebSocket is not open.");
    }
  },

  markIsRead: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }
  },

  deleteMail: (userInput) => {
    console.log("DELETE SOCKET", userInput);
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }

    if (userInput?.trashed) {
      message.success("Mail Deleted");
    }
  },

  sendEvent: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
      console.log("userinput", userInput);
    } else {
      console.error("WebSocket is not open.");
    }
  },

  applylabel: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
      message.success("Label Applied.");
    } else {
      console.error("WebSocket is not open.");
    }
  },

  sendDraftMail: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }
  },
  applytags: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }
  },
  archivedMail: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
      if (userInput.Archive) {
        message.success("Conversation archived.");
      } else {
        message.success("Conversation unarchived.");
      }
    } else {
      console.error("WebSocket is not open.");
    }
  },
  chatEvent: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }
  },
};

export const handleSocketEvent = async (payLoad) => {
  try {
    // Check if webSocketManager is available and WebSocket is open
    const webSocketManager = window?.webSocketManager;
    if (
      webSocketManager &&
      webSocketManager.socket.readyState === WebSocket.OPEN
    ) {
      // Create a unique responseId to track this request
      const responseId = Date.now();

      // Create a promise to handle the delete response
      const responsePromise = new Promise((resolve, reject) => {
        // Define a temporary event handler
        const handleResponse = (response) => {
          if (response?.content?.AcknowledgmentID) {
            //   if (payLoad?.event === "spamMail") {
            //     payLoad?.Spam
            //       ? openNotificationWithIcon(
            //           "success",
            //           "Mail successfully marked as spam."
            //         )
            //       : openNotificationWithIcon(
            //           "success",
            //           "Email successfully unmarked as spam."
            //         );
            //   } else if (payLoad?.event === "archivedMail") {
            //     payLoad?.Archive
            //       ? openNotificationWithIcon(
            //           "success",
            //           "Mail successfully archived."
            //         )
            //       : openNotificationWithIcon(
            //           "success",
            //           "Email successfully removed from archive."
            //         );
            //   }
            //   setCurrentMail({});
            //   handleCurrentMailClose();
            //   const filteredMails = allMails?.filter(
            //     (mail) => mail?._id !== payLoad?._id
            //   );
            //   setMailData(filteredMails);
            
            return true;
          } else {
            message.error("Please try again.");
          }
          resolve(response);
          // Remove the event listener after handling the response
          webSocketManager.off(payLoad?.event, handleResponse);
        };

        // Register the temporary event handler
        webSocketManager.on(payLoad?.event, handleResponse);
      });

      // Send payload with the unique responseId
      await webSocketManager.send({ ...payLoad, responseId });

      // Await the response promise
      await responsePromise;
    } else {
      message.error("Please try again.");
      console.error("WebSocket is not open or not initialized.");
    }
  } catch (error) {
    console.error("WebSocket error:", error);
    message.error("WebSocket error");
  }
};

export function formatArrayToString(array) {
  return `${array}`;
}

export const disabledDate = (current) => {
  // Disable dates after today
  return current && current > moment().endOf("day");
};

export function formatTimestamp(timestamp) {
  // Convert the timestamp to a Date object
  let date = new Date(timestamp);

  // Get the current date
  let now = new Date();

  // Check if the date is today
  if (date.toDateString() === now.toDateString()) {
    return `${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  }

  // Check if the date is in the current year
  if (date.getFullYear() === now.getFullYear()) {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "long",
    })}`;
  }

  // Otherwise, return the full date
  return `${date.getDate()} ${date.toLocaleString("default", {
    month: "long",
  })} ${date.getFullYear()}`;
}

export const getInitial = (sender) => {
  if (!sender) return "A";
  const firstAlpha = sender.match(/[A-Za-z]/);
  return firstAlpha ? firstAlpha[0].toUpperCase() : "A";
};

export const returnccbcc = (mail) => {
  console.log("cbb bss");
  let arr = [];
  if (mail?.mailClient == "outlook") {
    if (mail.ccRecipients || mail.bccRecipients) {
      mail.ccRecipients.map((data) => {
        arr.push({
          name: data?.emailAddress?.name,
          email: data?.emailAddress?.address,
        });
      });
    }
    if (mail.bccRecipients) {
      mail.bccRecipients.map((data) => {
        arr.push({
          name: data?.emailAddress?.name,
          email: data?.emailAddress?.address,
        });
      });
    }

    // [{emailAddress: {address: "dry@trinity-ship.com", name: "Trinity-Ship Brokers"}}]
  } else if (mail?.mailClient == "custom") {
    if (mail.ccRecipients || mail.bccRecipients) {
      mail.CC.map((data) => {
        const parsedNail = parseSender(data);
        arr.push({
          name: parsedNail?.name,
          email: parsedNail?.email,
        });
      });
    }
    if (mail.BCC) {
      mail.BCC.map((data) => {
        const parsedNail = parseSender(data);
        arr.push({
          name: parsedNail?.name,
          email: parsedNail?.email,
        });
      });
    }
  } else {
    if (mail.ccRecipients || mail.bccRecipients) {
      mail.CC.map((data) => {
        const parsedNail = parseSender(data);
        arr.push({
          name: parsedNail?.name,
          email: parsedNail?.email,
        });
      });
    }
    if (mail?.BCC) {
      Array.isArray(mail?.BCC) &&
        mail?.BCC?.map((data) => {
          const parsedNail = parseSender(data);
          arr.push({
            name: parsedNail?.name,
            email: parsedNail?.email,
          });
        });
    }
  }
  return arr;
};

export function parseSender(sender) {
  // debugger
  let name = "";
  let email = "";
  if (sender) {
    // Regular expression to match the name and email pattern
    const match = sender?.match(/(.*?)\s*<(.+?)>/);
    let name = "";
    let email = "";

    if (match) {
      // Extract name and email if pattern is matched
      name = match[1].trim().replace(/['"]/g, ""); // Remove quotes from the name
      email = match[2].trim();
    } else {
      // If no match, check if the sender string is a valid email
      const emailMatch = sender?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if (emailMatch) {
        email = sender.trim();
      }
    }

    // If email is still empty, set name as the sender string without quotes
    if (!email) {
      name = sender.trim().replace(/['"]/g, "");
    }

    return { name, email };
  }
  return { name, email };
}

export const formatNumber = (num) => {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1) + "B"; // Billion
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + "M"; // Million
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + "K"; // Thousand
  } else {
    return num.toString(); // Less than thousand
  }
};

const getFileType = (filename) => {
  const wordRegex = new RegExp("(.*?).(docx|doc)$");
  const isWordFile = wordRegex.test(filename);

  const excelRegex = new RegExp("(.*?).(xlsx|xls)$");
  const isExcelFile = excelRegex.test(filename);

  const pdfRegex = new RegExp("(.*?).(pdf)$");
  const isPDFFile = pdfRegex.test(filename);

  const imageRegex = new RegExp("(.*?).(jpg|jpeg|png|gif)$");
  const isImageFile = imageRegex.test(filename);

  let fileType = "";
  if (isWordFile) {
    fileType = "word";
  }

  if (isExcelFile) {
    fileType = "excel";
  }

  if (isPDFFile) {
    fileType = "pdf";
  }

  if (isImageFile) {
    fileType = "image";
  }

  return fileType;
};

const attachmentsBaseURL = "https://files.theoceann.com";

export const getAttachmentsURL = (email, attachment, GmailMessageId) => {
  const { attachment_id, filename } = attachment;

  const fileType = getFileType(filename);

  const url = `${attachmentsBaseURL}/view_attachment?email=${email}&attachment_id=${attachment_id}
      &GmailMessageId=${GmailMessageId}&file_name=${filename}`;

  return { url, fileType };
};

export const capitaliseFirstLetter = (userInput) => {
  try {
    if (!userInput) {
      return userInput;
    }

    if (Array.isArray(userInput)) {
      // If userInput is an array, map over it to capitalize each element
      return userInput.map((data) =>
        data ? data.slice(0, 1).toUpperCase() + data.slice(1) : data
      );
    }
    // For a single string, capitalize the first letter and return
    if (typeof userInput === "string") {
      // return userInput?.slice(0, 1).toUpperCase() + userInput.slice(1);
      return userInput
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else {
      // If userInput is not a string (e.g., number), return it as is
      return [userInput];
    }
  } catch (error) {
    console.log(error);
  }
};

export const rowDataFormatter = (userInput) => {
  try {
    {
      if (Array.isArray(userInput)) {
        return (
          <div>
            {userInput.map((owner, index) => (
              <div key={index}>{owner}</div>
            ))}
          </div>
        );
      } else {
        return userInput;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const formatDate121 = (time, dateString, fullDate) => {
  const currentDate = new Date();
  const givenDate = new Date(Date.parse(dateString));

  // Extracting day, month, and year separately from givenDate
  const day = givenDate.getDate();
  const monthIndex = givenDate.getMonth();
  const year = givenDate.getFullYear();

  // const day = givenDate.getDate();
  // const monthIndex = givenDate.getMonth();
  // const year = givenDate.getFullYear();
  const hours = givenDate.getHours();
  const minutes = givenDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (!fullDate) {
    if (isNaN(givenDate.getTime())) {
      return "N/A"; // Handle invalid date string
    } else if (givenDate.toDateString() === currentDate.toDateString()) {
      return time;
    } else if (year === currentDate.getFullYear()) {
      // If the year is the same as the current year, return only the date and month
      return `${day} ${monthNames[monthIndex]}`;
    } else {
      // Otherwise, return the full date
      return `${day} ${monthNames[monthIndex]} ${year}`;
    }
  } else
    return `${day} ${monthNames[monthIndex]} ${year} ${formattedHours}:${minutes} ${ampm}`;
};

export const convertToCSV = (objArray) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  const headers = Object.keys(array[0]).join(",");
  str += headers + "\r\n";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line !== "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }

  return str;
};

export const chipStyles = [
  {
    color: "#4B0082",
    border: "1px solid #4B0082",
    backgroundColor: "#E6E6FA",
    fontSize: "10px",
  },
  {
    color: "#2B5179",
    border: "1px solid #2B5179",
    backgroundColor: "#E2F0FD",
    fontSize: "10px",
  },
  {
    color: "#556B2F",
    border: "1px solid #556B2F",
    backgroundColor: "#F0E68C",
    fontSize: "10px",
  },
  {
    color: "#D2691E",
    border: "1px solid #D2691E",
    backgroundColor: "#FFDEAD",
    fontSize: "10px",
  },
  {
    color: "#B22222",
    border: "1px solid #B22222",
    backgroundColor: "#FFDEAD",
    fontSize: "10px",
  },
  {
    color: "#146F6F",
    border: "1px solid #146F6F",
    backgroundColor: "#E2FDFD",
    fontSize: "10px",
  },
  {
    color: "#6F551B",
    border: "1px solid #6F551B",
    backgroundColor: "#FDF4E2",
    fontSize: "10px",
  },
  {
    color: "#3D1B6F",
    border: "1px solid #3D1B6F",
    backgroundColor: "#EDE2FD",
    fontSize: "10px",
  },
  {
    color: "#6F3D1B",
    border: "1px solid #6F3D1B",
    backgroundColor: "#FDE9E2",
    fontSize: "10px",
  },
  {
    color: "#1B6F44",
    border: "1px solid #1B6F44",
    backgroundColor: "#E2FDF1",
    fontSize: "10px",
  },
  {
    color: "#1B4F6F",
    border: "1px solid #1B4F6F",
    backgroundColor: "#E2F2FD",
    fontSize: "10px",
  },
  {
    color: "#4F6F1B",
    border: "1px solid #4F6F1B",
    backgroundColor: "#F4FDE2",
    fontSize: "10px",
  },
  {
    color: "#1B6F6F",
    border: "1px solid #1B6F6F",
    backgroundColor: "#E2FDFD",
    fontSize: "10px",
  },
  {
    color: "#6F5A1B",
    border: "1px solid #6F5A1B",
    backgroundColor: "#FDF1E2",
    fontSize: "10px",
  },
  {
    color: "#1B3D6F",
    border: "1px solid #1B3D6F",
    backgroundColor: "#E2EDF2",
    fontSize: "10px",
  },
  {
    color: "#3D6F1B",
    border: "1px solid #3D6F1B",
    backgroundColor: "#EDFDE2",
    fontSize: "10px",
  },
  {
    color: "#1B6F2B",
    border: "1px solid #1B6F2B",
    backgroundColor: "#E2FDF4",
    fontSize: "10px",
  },
  {
    color: "#6F2B1B",
    border: "1px solid #6F2B1B",
    backgroundColor: "#FDE2E2",
    fontSize: "10px",
  },
  {
    color: "#2B6F1B",
    border: "1px solid #2B6F1B",
    backgroundColor: "#E2FDE2",
    fontSize: "10px",
  },
  {
    color: "#6F1B2B",
    border: "1px solid #6F1B2B",
    backgroundColor: "#FDE2E2",
    fontSize: "10px",
  },
];
