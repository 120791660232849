import { Dropdown, message, Spin, Tooltip } from "antd";
import SplitView from "./SplitView";
import { useEffect, useState } from "react";
import { postAPICall } from "../../shared";
import { Icon } from "@iconify/react/dist/iconify.js";
import ListView from "./ListView";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllEmails,
  setArchivedMail,
  setBinMail,
  setDraftMail,
  setFilterData,
  setHasMore,
  setImpMail,
  setSentMail,
  setSpamMail,
} from "../../actions/emailActions";
import { useParams } from "react-router";

const MailLayout = (props) => {
  const [view, setView] = useState("split");
  const [page, setPage] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [mailType, setMailType] = useState("");
  const [searchText, setSearchtext] = useState("");
  const [loading, setLoading] = useState(false);
  // const [filterPayload, setFilterPayload] = useState([]);
  const dispatch = useDispatch();
  const { label_name } = useParams();
  const isSearching = useSelector((state) => state.email.isSearching);
  const isFiltering = useSelector((state) => state.email.isFiltering);
  const filterData = useSelector((state) => state.email.filterData);
  const filterPayload = useSelector((state) => state.email.filterPayload);

  const items = [
    {
      key: "1",
      label: (
        <div onClick={() => handleView("list")}>
          <Icon
            style={{ color: "white !important", fontSize: "16px" }}
            className=""
            icon={"tabler:list"}
          />
          <span> List View</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => handleView("split")}>
          <Icon
            style={{ color: "white !important", fontSize: "16px" }}
            className=""
            icon={"tabler:layout-board-split"}
          />
          <span> Split View</span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (props.sent) {
      fetchEmails("sent");
    } else if (props.draft) {
      fetchEmails("draft");
    } else if (props.bin) {
      fetchEmails("bin");
    } else if (props.archived) {
      fetchEmails("archived");
    } else if (props.important) {
      fetchEmails("important");
    } else if (props.spam) {
      fetchEmails("spam");
    } else if (isFiltering) {
      handleFilterPagination();
    } else if (!isSearching) {
      fetchEmails("inbox");
    } else {
      handleSearch(searchText);
    }
  }, [
    props.sent,
    props.draft,
    props.bin,
    props.archived,
    props.important,
    props.spam,
    isSearching,
    isFiltering,
    searchText,
    page,
  ]);

  useEffect(() => {
    if (label_name && props?.labels) {
      fetchEmails("labels");
    }
  }, [label_name]);

  useEffect(() => {
    const MailView = localStorage.getItem("MailView");
    setView(MailView);
  }, []);

  function handleView(view) {
    localStorage.setItem("MailView", view);
    setView(view);
  }

  const fetchEmails = async (mailType) => {
    try {
      setMailType(mailType);
      setLoading(true);
      const url = `${process.env.REACT_APP_MT_URL}mail/getEmails${
        page > 1 ? "?page=" + page + "&pageSize=20" : ""
      }`;
      const data = {
        [mailType]: "*",
      };

      if (mailType === "bin") {
        data.trash = "*";
      } else if (mailType === "labels") {
        data.current_mail = "*";
        data.labels = label_name || "";
      }
      const method = "POST";
      const resp = await postAPICall(url, data, method, (data) => {
        if (data?.emails.length !== 0) {
          dispatch(setHasMore(true));
        } else {
          dispatch(setHasMore(false));
        }

        if (mailType === "draft") {
          dispatch(setDraftMail(data));
        } else if (mailType === "sent") {
          dispatch(setSentMail(data));
        } else if (mailType === "spam") {
          dispatch(setSpamMail(data));
        } else if (mailType === "bin") {
          dispatch(setBinMail(data));
        } else if (mailType === "archived") {
          dispatch(setArchivedMail(data));
        } else if (mailType === "important") {
          dispatch(setImpMail(data));
        } else if (mailType === "inbox") {
          dispatch(setAllEmails(data));
        } 
        setSearchData(data?.data || []); // Safely accessing data and handling undefined
        setLoading(false);
      });
    } catch (err) {
      console.error("Error fetching emails", err);
      setLoading(false);
    }
  };

  // SEARCH IN THE MAILS
  const handleSearch = async (text) => {
    try {
      setLoading(true);
      setSearchtext(text);
      const url = `${process.env.REACT_APP_MT_URL}mail/Search${
        isSearching ? "?page=" + page + "&pageSize=20" : ""
      }`;
      const data = {
        query: text,
        owner_email: "*",
      };
      const method = "POST";
      const resp = await postAPICall(url, data, method, (data) => {
        console.log("Emails fetched successfully", data);

        if (data?.data?.length !== 0) {
          dispatch(setHasMore(true));
          setSearchData(data?.data || []);
          setLoading(false);
          // Safely accessing data and handling undefined
        } else {
          dispatch(setHasMore(false));
        }
      });
    } catch (err) {
      setLoading(false);
      console.error("Error searching emails", err);
    }
  };

  const handleFilterPagination = async (data) => {
    try {
      // setFilterPayload(data);
      setLoading(true);
      const url = `${process.env.REACT_APP_MT_URL}mail/getFIlterTonnageMails${
        "?page=" + page + "&pageSize=20"
      }`;

      const method = "POST";
      const resp = await postAPICall(url, filterPayload, method, (data) => {
        console.log("Emails fetched successfully", data);
        if (data?.emails.length !== 0) {
          dispatch(setHasMore(true));
          dispatch(setFilterData(data));
          setLoading(false);
        } else {
          setLoading(false);
          dispatch(setHasMore(false));
        }
      });
    } catch (err) {
      setLoading(false);
      console.error("Error searching emails", err);
    }
  };

  console.log("isSearching ==>>>>>", isSearching);

  return (
    <>
      <div className="mail_upper_header">
        <div className="mail_header_icons">
          <Tooltip title="Click to change the view">
            <Dropdown
              menu={{
                items,
              }}
              placement="bottom"
            >
              <div className="upper_header_btn">
                <Icon
                  style={{ color: "white !important", fontSize: "20px" }}
                  className=""
                  icon={"tabler:layout-list"}
                />
              </div>
            </Dropdown>
          </Tooltip>
        </div>
      </div>

      <Spin fullscreen spinning={loading} />

      {/* ////// Conditionally Rendering the VIEWS ////////// */}
      {view === "split" ? (
        <SplitView
          mailType={mailType}
          searchData={searchData}
          page={page}
          setPage={setPage}
          handleSearch={handleSearch}
          handleFilterPagination={handleFilterPagination}
        />
      ) : (
        <ListView
          mailType={mailType}
          page={page}
          setPage={setPage}
          handleSearch={handleSearch}
          searchData={searchData}
          handleFilterPagination={handleFilterPagination}
        />
      )}
    </>
  );
};

export default MailLayout;
