import React from 'react';
// import { Spin } from 'antd
// import image1 from "../../../public/assets/loader.svg"




const Loader = () => (
  // <Spin size="large" />
  // /assets/profile-img/profilephoto.png
  <img src={`${process.env.REACT_APP_IMAGE_PATH}loader.svg`} alt="Loading..."></img>
)
export default Loader;
