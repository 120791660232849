import React, { Component, useEffect, useRef, useState } from "react";
import { Modal, Spin } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import moment from "moment";
import Email from "../../components/Email";
//import "../../../shared/components/All-Print-Reports/print-report.scss";
import "../../shared/components/All-Print-Reports/print-report.scss";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";
import InvoicePopup from "./InvoicePopup";
import { openNotificationWithIcon, useStateCallback } from "../../shared";
import { forwardRef } from "react";

const ComponentToPrint = forwardRef((props, ref) => {
  const [state, setState] = useStateCallback({
    title: "",
    company_name: "",
    company_address: "",
    company_logo: "",
    vendor_name: "",
    vendor_address: "",
    vendor_account_no: "",
    benificiary_name: "",
    benificiary_bank_name: "",
    vendor_branch: "",
    vessel: "",
    port: "",
    activity: "",
    po_number: "N/A",
    payment_terms: "",
    invoice_no: "",
    delivery_date: "",
    curr: "",
    iban: "",
    swift_code: "",
    due_date: "",
    inv_date: "N/A",
    subtotal: 0,
    cargo_name: "",
    trans_no: "",
    invoice_amt: 0,
    final_amt_us: 0,
    final_amt_loc: 0,
    tax_invoice: false,
    credit_notes: false,
    acc_type: 0,
    cp_date: props.cpDate,
    curr_name: "USD-USA",
    pdaTableArray: props.data["."] || props.data["--"] || [],
  });

  const parseValue = (val) => {
    if (!val) return "0.00";

    const num =
      typeof val === "number" && !isNaN(val)
        ? val
        : Number(val.replace(/[^\d.-]/g, ""));
    return num || num === 0
      ? num.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "0.00";
  };

  useEffect(() => {
    const { type, data, valueData, billTo, billFrom } = props;

    let total_amount = "0.00";
    let cargo_name = "n/a";
    let inv_date = 0;
    if (type == "frightCommisionInvoice" && data["..."].length >= 1) {
      total_amount = data?.total_amount;
      inv_date =
        data?.invoice_date && moment(data?.invoice_date).format("DD/MM/YYYY");
    } else if (type == "frightInvoice") {
      total_amount = data.invoice_total;
      cargo_name = props.data[".."][0]["cargo_name"];
      inv_date =
        data?.invoice_date && moment(data?.invoice_date).format("DD/MM/YYYY");
    } else if (type == "otherExpenseInvoice") {
      total_amount = data["....."].total_amount;
      inv_date =
        data?.invoice_date && moment(data?.invoice_date).format("DD/MM/YYYY");
      cargo_name = data?.["otherrevenueexpenses"]?.[0]?.["cargo_name"];
    } else if (type == "DeviationInvoice") {
      total_amount = data?.total;
      inv_date = moment(data?.inv_date).format("DD/MM/YYYY");
    } else if (type == "bunkerInvoice") {
      total_amount = data.grand_total;
      inv_date =
        data?.invoice_date && moment(data?.invoice_date).format("DD/MM/YYYY");
    } else if (type == "claimInvoice") {
      total_amount = data.final_amt_us;
      inv_date =
        valueData?.invoice_date &&
        moment(valueData?.invoice_date).format("DD/MM/YYYY");
    } else if (type == "pdaInvoice") {
      // total_amount = data['.'] && data['.'].reduce((acc, curr) => parseFloat(acc.agreed_adv) + parseFloat(curr.agreed_adv), 0 );
      let sum = 0;
      for (let val of state.pdaTableArray) {
        sum += parseFloat(val?.agreed_adv);
      }
      total_amount = sum;

      inv_date =
        data?.fda_inv_date && moment(data?.fda_inv_date).format("DD/MM/YYYY");
    } else if (type == "fdaInvoice") {
      let sum = 0;
      for (let val of state.pdaTableArray) {
        sum += parseFloat(val?.fda_amount);
      }
      total_amount = sum;
      inv_date =
        data?.fda_inv_date && moment(data?.fda_inv_date).format("DD/MM/YYYY");
    } else if (type == "commissionInvoice") {
      total_amount = data.invoice_amt;
      inv_date =
        data?.invoice_date && moment(data?.invoice_date).format("DD/MM/YYYY");
    }
    setState((prevState) => ({
      ...prevState,

      title:
        type == "frightCommisionInvoice"
          ? "Freight Commision Invoice"
          : type == "DeviationInvoice"
          ? "Deviation Invoice"
          : type == "frightInvoice"
          ? "Freight Invoice"
          : type == "otherExpenseInvoice"
          ? data["rev_exp"] === 173
            ? "Other Revenue Invoice"
            : "Other Expense Invoice"
          : type == "bunkerInvoice"
          ? "Bunker Invoice"
          : type == "claimInvoice"
          ? "Claim Invoice"
          : type == "commissionInvoice"
          ? "Commission Invoice"
          : type == "fdaInvoice"
          ? "FDA Invoice"
          : "PDA Invoice",

      company_name:
        (data && data.my_company_name != "N/A") || billFrom
          ? data.my_company_name || billFrom
          : "N/A",
      company_logo: data && data.my_company_logo ? data.my_company_logo : "N/A",
      company_address:
        data && data.my_company_address ? data.my_company_address : "N/A",
      vendor_name: billTo && billTo ? billTo : "N/A",
      vendor_address: data?.counterparty_address,
      vendor_account_no: data?.bank_ajent?.account_no,
      benificiary_bank_name: data?.bank_ajent?.benificiary_bank_name,
      benificiary_name: data?.bank_ajent?.benificiary_name,
      iban: data?.bank_ajent?.iban,
      swift_code: data?.bank_ajent?.swift_code,
      vendor_branch: data?.bank_ajent?.branch,
      vessel: data?.vessel_name,
      due_date:
        (data?.due_date && moment(data?.due_date).format("DD/MM/YYYY")) ||
        (data?.fda_inv_date &&
          moment(data?.fda_inv_date).format("DD/MM/YYYY")) ||
        "N/A",
      inv_date: inv_date,
      invoice_no: data?.invoice_no || data?.disburmnt_inv || data?.inv_no,
      invoice_amt: parseValue(total_amount),
      tax_invoice: data?.tax_invoice,
      credit_notes: data?.credit_note,
      acc_type: data?.acc_type,
      curr_name: data?.currency ? data.currency : "USD-USA",
      final_amt_us:
        parseValue(data["final_amt_us"]) || parseValue(data["final_amt_loc"]),
      final_amt_loc: parseValue(data.final_amt_loc),
      cargo_name: cargo_name,
      payment_terms: props.data["payment_term_name"] || data["payment_terms"],
      po_number: data.po_number || data.po_no,
      pdaTableArray: data["."] || props.data["--"] || [],
    }));

    return () => {
      setState({});
    };
  }, [props]);

  return (
    <article className="article toolbaruiWrapper" ref={ref}>
      <div className="box box-default" id="divToPrint">
        <div className="box-invoice">
          <div className="invoice-inner-download mt-3">
            <div className="row">
              <div className="col-12 text-center">
                <img
                  className="reportlogo"
                  src={state.company_logo}
                  // crossOrigin="anonymous"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="text-center invoice-top-address">
                <p>{state.company_name}</p>
                <p> {state.company_address}</p>
              </div>
            </div>
          </div>

          <hr className="mb-0 mt-0 invoice-line" />

          <div className="row p10">
            <div className="col-md-12">
              <table className="table table-invoice-report">
                <tbody>
                  <tr>
                    <td>
                      <h4 className="mb-1">
                        <b>Bill To</b>
                      </h4>
                      {/* <p className="m-0">
                          Abc Meritime Pvt. Ltd <br /> 80 Broad Street Monrovla <br /> Liberia
                        </p> */}
                      <p className="m-0">
                        {state.vendor_name} <br /> {state.vendor_address} <br />
                      </p>
                    </td>

                    <td className="text-right">
                      <p>
                        <span className="h3 font-weight-bold mr-4">
                          {state.tax_invoice ? "Tax Invoice" : undefined}
                        </span>
                        <span className="h3 font-weight-bold mr-4">
                          {state.credit_notes ? "Credit Note" : undefined}
                        </span>
                        <span className="h3 font-weight-bold mr-4">
                          {state.title}
                        </span>
                      </p>
                      <p className="m-0">
                        Invoice No.: <b>{state.invoice_no}</b> | Invoice Date:{" "}
                        <b> {state.inv_date}</b>
                      </p>

                      <p className="m-0">
                        {props.invoiceType && (
                          <>
                            Invoice Type : <b>{props.invoiceType}</b> |
                          </>
                        )}{" "}
                        Due Date:
                        <b>{state.due_date}</b>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr className="mb-0 mt-0 invoice-line" />
          <div className="row p10">
            <div className="col-md-12">
              <table className="table table-invoice-report">
                <tbody>
                  <tr>
                    <td>
                      <ul className="list-unstyled">
                        <li className="laytime-list">
                          <p className="m-0">
                            <b>Vessel: </b> {state.vessel}
                          </p>
                          {/* <span>Vessel:</span> <span> {state.vessel}</span> */}
                        </li>
                      </ul>
                    </td>

                    {/*  <td> <p className="m-0">
                          CP Date: <b>May 05 2021</b>
                        </p>  </td>*/}
                    {props.type != "pdaInvoice" &&
                      props.type != "claimInvoice" &&
                      props.type != "DeviationInvoice" &&
                      props.type != "bunkerInvoice" &&
                      props.type != "fdaInvoice" &&
                      props.type != "frightCommisionInvoice" &&
                      props.type != "DeviationInvoice" && (
                        <td>
                          {props.type != "pdaInvoice" &&
                            props.type != "claimInvoice" &&
                            props.type != "DeviationInvoice" &&
                            props.type != "bunkerInvoice" &&
                            props.type != "fdaInvoice" &&
                            props.type != "frightCommisionInvoice" &&
                            props.type != "DeviationInvoice" && (
                              <p className="m-0">
                                <b> Cargo Name: </b> {state.cargo_name ?? "N/A"}
                              </p>
                            )}
                        </td>
                      )}

                    {/* {state.port != "N/A" && props.type !== 'frightInvoice' &&  props.type !== 'frightCommisionInvoice' && (
                      <td>
                        <p className="m-0">
                          <b>Port Name: </b> {state.port}
                        </p>
                      </td>
                    )} */}

                    <td style={{ display: "flex", gap: "110px" }}>
                      {state.type === "frightInvoice" ? (
                        state.cp_date !== "N/A" && (
                          <p className="m-0">
                            {/* <b>CP Date: </b> {state.cp_date}  */}
                          </p>
                        )
                      ) : props.type !== "DeviationInvoice" &&
                        props.type !== "claimInvoice" ? (
                        <p className="m-0">
                          <b>PO Number: </b> {state.po_number}
                        </p>
                      ) : null}

                      {state.activity != "N/A" &&
                        state.type == "frightInvoice" && (
                          <p className="m-0">
                            Activity: <b> {state.activity}</b>
                          </p>
                        )}

                      {
                        <p className="m-0">
                          {/* <b>CP Date: </b> {props.cpDate} */}
                        </p>
                      }
                    </td>
                  </tr>
                  <tr>
                    {state.trans_no != "N/A" && (
                      <td>
                        <p className="m-0">
                          <b> Currency:</b> {state.curr_name}
                        </p>
                      </td>
                    )}
                    {/* {state.delivery_date != "N/A" && props.type !== 'frightInvoice' &&  props.type !== 'frightCommisionInvoice' && (
                      <td>
                        <p className="m-0">
                          <b> Delivery: </b> {state.delivery_date}
                        </p>
                      </td>
                    )} */}
                    {/* <td>
                        <p className="m-0">
                          Redelivery: <b>May 05 2021</b>
                        </p>
                      </td> */}
                    <td>
                      <ul className="list-unstyled">
                        <li className="laytime-list">
                          <p className="m-0">
                            <b>Printed On: </b> {moment().format("DD/MM/YYYY")}
                          </p>
                          {/* <span>Printed On:</span>{" "}
                          <span>{moment().format("DD/MM/YYYY")}</span> */}
                        </li>

                        {/* <li className="laytime-list">
                            <span>Payment Terms:</span>{" "}
                            <span>{payment_terms}</span>
                          </li> */}
                      </ul>
                    </td>

                    <td>
                      <ul className="list-unstyled">
                        {/* <li className="laytime-list">
                            <span>Printed On:</span>{" "}
                            <span>{moment().format("DD MMMM YYYY")}</span>
                          </li> */}

                        {props.type != "pdaInvoice" &&
                          state.payment_terms != "N/A" &&
                          props.type != "DeviationInvoice" &&
                          props.type != "fdaInvoice" &&
                          props.type != "claimInvoice" && (
                            <li className="laytime-list">
                              <p className="m-0">
                                <b>Payment Terms: </b> {state.payment_terms}
                              </p>
                              {/* <span>Payment Terms:</span>{" "}
                              <span>{state.payment_terms}</span> */}
                            </li>
                          )}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr className="mb-0 mt-0 invoice-line" />

          <div className="row p10">
            <div className="col-md-12">
              <p className="border-bottom">
                <b>
                  Description <span className="float-right">Amount (USD)</span>
                </b>
              </p>

              {/* {state.type == "fdaInvoice" ? '' : state.type == "pdaInvoice" ? '' : state.type == "bunkerInvoice" ? '' : state.type == "claimInvoice" ? '' : state.type == "commissionInvoice" ? '' : state.type == "otherExpenseInvoice" ? '' : state.type == "DeviationInvoice" ? '' : state.type == "frightInvoice" ?
                'heeh' : state.type == "frightCommisionInvoice" ? '' : (
                  ""
                )} */}

              {props.type == "fdaInvoice" ? (
                state.pdaTableArray &&
                state.pdaTableArray.length > 0 &&
                state.pdaTableArray.map((value, index) => {
                  return (
                    <p className="m-0 invoice-list" key={index}>
                      <span>
                        {value.category_name
                          ? value.category_name
                          : value.cost_item}
                      </span>
                      <span className="float-right">
                        {value.fda_amount > 0
                          ? parseValue(value.fda_amount)
                          : "0.00"}
                      </span>
                    </p>
                  );
                })
              ) : props.type == "pdaInvoice" ? (
                state.pdaTableArray &&
                state.pdaTableArray.length > 0 &&
                state.pdaTableArray.map((value, index) => {
                  return (
                    <p className="m-0 invoice-list" key={index}>
                      <span>
                        {value.category_name
                          ? value.category_name
                          : value.cost_item}
                      </span>
                      <span className="float-right">
                        {value.agreed_adv > 0 ? value.agreed_adv : 0.0}
                      </span>
                    </p>
                  );
                })
              ) : props.type == "bunkerInvoice" ? (
                <table className="custom-table-bordered">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Grade</th>
                      <th>Sulphur</th>
                      <th>Invoice Qty</th>
                      <th>Reced Qty</th>
                      <th>Fuel Cost</th>
                      <th>Barge Rate</th>
                      <th>Barge Cost</th>
                      <th>Other Cost</th>
                      <th>Sales Tax</th>
                      <th>Net Cost</th>
                      <th>Port Charge</th>
                      <th>Invoice Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data &&
                      props.data["...."] &&
                      props.data["...."].length > 0 &&
                      props.data["...."].map((value, index) => {
                        let invtotal = parseFloat(
                          value?.invoice_total?.replaceAll(",", "")
                        );
                        return (
                          <tr key={index}>
                            <td>{value.fuel_type}</td>
                            <td>{value.grade_name}</td>
                            <td>{value.sulphur}</td>
                            <td>{value.invoice_qty}</td>
                            <td>{value.recieved_qty}</td>
                            <td>{value.fuel_cost}</td>
                            <td>{value.barge_rate}</td>
                            <td>{value.barge_cost}</td>
                            <td>{value.other_cost}</td>
                            <td>{value.sales_tax}</td>
                            <td>{value.net_cost}</td>
                            <td>{value.port_charge}</td>
                            <td>{invtotal > 0 ? invtotal : "0.0"}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : props.type == "claimInvoice" ? (
                <div className="col-md-12">
                  <ul className="list-unstyled">
                    <li className="laytime-list">
                      <span>Final Dem/Des Amount:</span>{" "}
                      <span className="float-right">
                        {" "}
                        {parseValue(props.valueData.demurrage_amount)}
                      </span>
                    </li>
                    <li className="laytime-list">
                      <span>Other Extra Time/Amount:</span>{" "}
                      <span className="float-right">
                        {parseValue(props.valueData.origional_claim_amount)}
                      </span>
                    </li>
                    <li className="laytime-list">
                      <span>Other Adjustment </span>{" "}
                      <span className="float-right">
                        {parseValue(props.valueData.amount_paid_to_owner)}
                      </span>
                    </li>
                    <li className="laytime-list">
                      <span>Final Total Claim </span>{" "}
                      <span className="float-right">
                        {parseValue(props.valueData.final_total_claim)}
                      </span>
                    </li>

                    <li className="laytime-list">
                      <span>Broker Comm. </span>{" "}
                      <span className="float-right">
                        {parseValue(props.valueData.broker_commission)}
                      </span>
                    </li>

                    <li className="laytime-list">
                      <span>Address Comm.</span>{" "}
                      <span className="float-right">
                        {parseValue(props.valueData.address_commission)}
                      </span>
                    </li>
                  </ul>
                </div>
              ) : props.type == "commissionInvoice" ? (
                props.data &&
                props.data["-"] &&
                props.data["-"].length > 0 &&
                props.data["-"].map((value, index) => {
                  return (
                    <p className="m-0 invoice-list" key={index}>
                      <span>
                        {value.description} @ the rate {value.rate}%
                      </span>
                      <span className="float-right">
                        {value.commission > 0
                          ? parseValue(value.commission)
                          : "0.00"}
                      </span>
                    </p>
                  );
                })
              ) : props.type == "otherExpenseInvoice" ? (
                props?.data?.otherrevenueexpenses?.length > 0 &&
                props.data.otherrevenueexpenses.map((value, index) => {
                  return (
                    <p className="m-0 invoice-list" key={index}>
                      <span>{value.description}</span>
                      <span className="float-right">
                        {value && value["amount"]
                          ? parseValue(value["amount"]?.split(",")?.join(""))
                          : "0.00"}
                      </span>
                    </p>
                  );
                })
              ) : props.type == "DeviationInvoice" ? (
                props.data?.description?.length > 0 &&
                props.data.description.map((value, index) => {
                  return (
                    <p className="m-0 invoice-list" key={index}>
                      <span>{value.activity_name}</span>
                      <span className="float-right">
                        {value && value["final_amt"] ? value["final_amt"] : 0}
                      </span>
                    </p>
                  );
                })
              ) : props.type == "frightInvoice" ? (
                props.data &&
                props.data["-----"] &&
                props.data["-----"].length > 0 &&
                props.data["-----"].map((value, index) => {
                  return (
                    <p className="m-0 invoice-list" key={index}>
                      <span>{value.description}</span>
                      <span className="float-right">
                        {value.amount > 0 ? parseValue(value.amount) : "0.00"}
                      </span>
                    </p>
                  );
                })
              ) : props.type == "frightCommisionInvoice" ? (
                props.data &&
                props.data["..."] &&
                props.data["..."].length > 0 &&
                props.data["..."].map((value, index) => {
                  return (
                    <p className="m-0 invoice-list" key={index}>
                      <span>{value.description}</span>
                      <span className="float-right">
                        {value && value.commission_amount > 0
                          ? parseValue(value.commission_amount)
                          : "0.00"}
                      </span>
                    </p>
                  );
                })
              ) : (
                ""
              )}
            </div>
          </div>
          <hr className="mb-0 mt-0 invoice-line" />
          {/*                               
            <div className="row p10">
              <div className="col-md-3 ml-auto">
                <h5 className="m-0">
                  <b>Subtotal</b> <span className="float-right">{(parseFloat(valueData.actual_claim_amount) + parseFloat(valueData.agreed_amount) + parseFloat(valueData.total_amount)).toFixed(2)}</span>
                 
                </h5>
              </div>
            </div> */}

          <hr className="mb-0 mt-0 invoice-line" />
          <div className="row p10">
            <div className="col-md-3 ml-auto">
              <h5 className="m-0">
                <b> Total Amount(USD-USA)</b>{" "}
                <span className="float-right">
                  {state?.invoice_amt
                    ? parseValue(state?.invoice_amt)
                    : parseValue(state?.invoice_amt)}
                </span>
              </h5>
              {/* <h5 style={{margin:"10px 0px"}} >
                  <b>Currency/Exch.rate</b> <span className="float-right">{ }</span>
                 
                </h5> */}

              {/* {props.type !== 'frightInvoice' && props.type !== 'frightCommisionInvoice' &&
                <h4 className="m-0">
                  <b>Final Amount({state.curr_name})</b>{" "}
                  <span className="float-right">
                    {state.final_amt_loc
                      ? parseValue(state.final_amt_loc)
                      : parseValue(state.invoice_amt)}
                  </span>
                </h4>
              } */}
            </div>
          </div>

          <hr className="mb-0 mt-0 invoice-line" />
          <div className="row p10">
            <div className="col-md-12">
              <table className="table table-invoice-report">
                <tbody>
                  <tr>
                    {state.acc_type == 78 ? (
                      <td>
                        <h4 className="mb-1">
                          <b> Bank Account Details</b>
                        </h4>

                        <div className='"col-4 text-left"'>
                          <ul className="list-unstyled">
                            <li className="laytime-list">
                              <span>Beneficiary Name:</span>{" "}
                              <span>
                                {" "}
                                {state.benificiary_name != "N/A"
                                  ? state.benificiary_name
                                  : "N/A"}
                              </span>
                            </li>
                            <li className="laytime-list">
                              <span>Bank Name:</span>{" "}
                              <span>
                                {" "}
                                {state.benificiary_bank_name != "N/A"
                                  ? state.benificiary_bank_name
                                  : "N/A"}
                              </span>
                            </li>
                            <li className="laytime-list">
                              <span> Bank Branch:</span>{" "}
                              <span>
                                {state.vendor_branch != "N/A"
                                  ? state.vendor_branch
                                  : "N/A"}
                              </span>
                            </li>
                            <li className="laytime-list">
                              <span>Account No:</span>{" "}
                              <span>
                                {state.vendor_account_no != "N/A"
                                  ? state.vendor_account_no
                                  : "N/A"}
                              </span>
                            </li>

                            <li className="laytime-list">
                              <span>IBAN:</span>{" "}
                              <span>
                                {state.iban != "N/A" ? state.iban : "N/A"}
                              </span>
                            </li>

                            <li className="laytime-list">
                              <span>Swift Code:</span>{" "}
                              <span>
                                {state.swift_code != "N/A"
                                  ? state.swift_code
                                  : "N/A"}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </td>
                    ) : undefined}

                    <td className="text-right">
                      <h4 className="mb-1">
                        <b>Bill From :</b>
                      </h4>
                      <p className="m-0">
                        {state.company_name}
                        <br />
                        {state.company_address} <br />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr className="mb-0 mt-0 invoice-line" />
          <div>
            <span className="invoice-disclaimer">
              Disclaimer: This is a system-generated invoice and does not
              require a signature.
            </span>
          </div>
        </div>
      </div>
    </article>
  );
});

const CreateInvoice = (props) => {
  const [pdfData, setPdfData] = useState();
  const [userInput, setUserInput] = useState();
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mailTitlePayload, setMailTitlePayload] = useState({});

  const [state, setState] = useStateCallback({
    name: "Printer",
    formData: Object.assign({}, props.formData || {}),
    type: props.type || "voyageManager",
    loader: false,
    isSaved: false,
    DATA:
      props.frightCommisionInvoice ||
      props.frightInvoice ||
      props.bunkerInvoice ||
      props.claimInvoice ||
      props.commissionInvoice ||
      props.PortExpensePDA ||
      props.otherExpenseInvoice ||
      props.DeviationInvoice ||
      null,
    claimInvoice: props.claimInvoice,
    isshowmodal: false,
  });

  const componentRef = useRef();

  const printReceipt = () => {
    window.print();
  };

  useEffect(() => {
    // const { type, DATA } = props.data;
    // const { invoice_no, my_company_name, vessel_name, po_no } = DATA;
    // const tempArray = [type, invoice_no, my_company_name, vessel_name, po_no];

    // Set state variables
    setUserInput(props.data);
  }, []);

  //   if (props.data) {
  //     const { type, DATA } = props.data;
  //     if (DATA) {
  //       const { invoice_no, my_company_name, vessel_name, po_no } = DATA;
  //       const tempArray = [type, invoice_no, my_company_name, vessel_name, po_no];

  //       // Set state variables
  //       setUserInput(props.data);
  //       setTitleArray(tempArray);
  //     } else {
  //       console.error("DATA is undefined in props.data:", props.data);
  //       // Handle the case where DATA is undefined
  //     }
  //   } else {
  //     console.error("props.data is undefined");
  //     // Handle the case where props.data is undefined
  //   }

  // }, [props.data]);

  const sendEmailReportModal = async () => {
    try {
      setLoading(true);

      const quotes = document.getElementById("divToPrint");

      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }

      // Create Blob
      const blob = doc.output("blob");

      // Use the blob as needed (e.g., send it to the server, create a download link, etc.)
      setLoading(false);
      setPdfData(blob);
      setEmailModal(true);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      // this.setState({ loading: false });
      // Handle errors here
    }
  };

  const printDocument = () => {
    const { type } = state;
    // in this method report is downloading, but logo is not coming showing err of loading image of s3.
    html2canvas(document.getElementById("divToPrint"), {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
      scale: 2,
    }).then(function (canvas) {
      // download image
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(canvas);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${type}Report.pdf`);
    }).catch = (err) => {};
  };

  const { DATA, type, isshowmodal, loader } = state;

  return (
    <div className="body-wrapper modalWrapper">
      <article className="article toolbaruiWrapper">
        <div className="box box-default">
          <div className="box-body">
            <div className="toolbar-ui-wrapper">
              <div className="leftsection" />
              <div className="rightsection">
                <span className="wrap-bar-menu">
                  <ul className="wrap-bar-ul">
                    <li
                      onClick={sendEmailReportModal}
                      style={{ cursor: loading ? "not-allowed" : "pointer" }}
                    >
                      Send Email
                    </li>
                    <li>
                      <span className="text-bt" onClick={printDocument}>
                        {" "}
                        Download Invoice
                      </span>
                    </li>
                    <li>
                      <ReactToPrint
                        trigger={() => (
                          <span className="text-bt">
                            <PrinterOutlined /> Print
                          </span>
                        )}
                        documentTitle="BunkerInvoiceReport"
                        content={() => componentRef.current}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            <ComponentToPrint
              valueData={state.claimInvoice}
              data={DATA}
              type={type}
              ref={componentRef}
              invoiceType={props?.frightInvoice?.invoice_type}
              cpDate={props?.frightInvoice?.cp_date}
              billTo={props?.billTo}
              billFrom={props?.billFrom}
            />
          </div>
        </div>
      </article>
      {emailModal && (
        <Modal
          title="New Message"
          visible={emailModal}
          onOk={() => {
            setEmailModal(false);
          }}
          onCancel={() => {
            setEmailModal(false);
          }}
          footer={null}
        >
          {pdfData && (
            <Email
              handleClose={() => {
                setEmailModal(false);
              }}
              attachmentFile={pdfData}
              title={window.corrector(
                `${props.type}||${state.DATA.invoice_no}||${state.DATA.my_company_name}||${state.DATA.vessel_name}||${state.DATA.po_no}`
              )}
              // title={`${props.type} || ${state.DATA.invoice_no?state.DATA.invoice_no:'___'}||${state.DATA.my_company_name?state.DATA.my_company_name:'___'}|| ${state.DATA.vessel_name?state.DATA.vessel_name:'___'}||${state.DATA.po_no?state.DATA.po_no:'___'}`}
            />
          )}
        </Modal>
      )}
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default CreateInvoice;
