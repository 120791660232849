import React, { Component } from 'react'
import { Modal } from 'antd';
import ShipDetail from './ShipDetail';
import URL_WITH_VERSION, { IMAGE_PATH} from "../../shared";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./Style.css";

const REACT_APP_MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line
mapboxgl.accessToken = REACT_APP_MAPBOX_TOKEN;

export default class DynamicMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lng: 41.15653,
            lat: 41.32111,
            zoom: this.props?.zoom ? this.props?.zoom : 2,
            visible: false,
            test: 1
        };
        this.mapContainer = React.createRef();
    }

    componentDidMount() {
        this.maprender()
    }
    maprender() {
        //console.log("shipData", this.props.data);
        const vesselData = this.props.data;
        let features = [];
        let dataObj = [
            { longitude: 41.15653, latitude: 41.32111, port: "Port 1" },
            { longitude: 42.15653, latitude: 40.32111, port: "Port 2" },
            { longitude: 40.15653, latitude: 42.32111, port: "Port 3" }
        ];
        

        dataObj.map((obj) => {
            let d = obj && obj.length > 0 ? obj[0] : null
            if (d != null) {
                features.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [d.longitude, d.latitude],
                    },
                    properties: {
                        title: d.port,
                    },
                })
            }
        });

        const map = new mapboxgl.Map({
            container: this.mapContainer.current,
            // style: "mapbox://styles/techtheocean/cl6yw3vjx000h14s0yrxn5cf6",
            style: "mapbox://styles/mapbox/dark-v11",
            projection :"equirectangular",
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
        });
        map.on("load", () => {
            map.on('click', 'places', (e) => {
                // console.log("e....",e);
                this.setState({
                    test: e.lngLat.lat,
                    visible: true
                });
            });

            map.loadImage(
                IMAGE_PATH+"icons/mapArrowGreen.png",
                (error, image) => {
                    if (error) throw error;
                    map.addImage("greenIcon", image);
                    map.addSource('places', {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            features: features
                        }
                    });

                    // Add a symbol layer
                    map.addLayer({
                        'id': 'places',
                        'type': 'symbol',
                        'source': 'places',
                        layout: {
                            "icon-image": "greenIcon",
                            "icon-rotate": ["get", "rotation"],
                            // get the title name from the source's "title" property
                            "text-field": ["get", "title"],
                            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                            "text-offset": [0, 1.25],
                            "text-anchor": "top",
                        },
                    });


                    map.getSource("places").setData({
                        type: "FeatureCollection",
                        features: this.props.data || []
                    });
                }
            );
        });
    }
    render() {
        return (
            <div>

                <div ref={this.mapContainer} className="map-container" />
                {this.state.visible ?
                    <Modal
                        className="ship-detail-modal"
                        style={{ top: '2%' }}
                        title={this.state.test}
                        open={this.state.visible}
                        onCancel={() => this.setState({ visible: false })}
                        width="40%"
                        footer={null}
                        header={null}
                    >
                        <ShipDetail data={{}} changeTitle={(data) => this.setState({ vessel_name: data })} />
                    </Modal>
                    :
                    undefined
                }

            </div>
        );
    }
}
