import React, { forwardRef, useRef, useState, useEffect } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import Email from "../../../../components/Email";

const ComponentToPrint = forwardRef((props, ref) => {

  const [formReportdata, setFormReportdata] = useState(
    Object.assign({}, props.data || {})
  );

  return (
    <article className="article toolbaruiWrapper" ref={ref}>
      <div className="box box-default" id="divToPrint">
        <div className="box-body">
          <div className="invoice-inner-download mt-3">
            <div className="row">
              <div className="col-12 text-center">
                <span className="title">
                  <img
                    className="reportlogo"
                    src={formReportdata.logo}
                    crossOrigin="anonymous"
                    alt="no img"
                  />
                </span>
                <p className="sub-title m-0">
                  {formReportdata.company_fixed_with_name
                    ? formReportdata.company_fixed_with_name
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="text-center invoice-top-address">
                <p>{formReportdata.address ? formReportdata.address : "N/A"}</p>
              </div>
            </div>
          </div>

          <div className="row p10">
            <div className="col-md-12">
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">VC Purchase Id :</td>
                    <td>{formReportdata.vc_purchase_id || "N/A"}</td>

                    <td className="font-weight-bold">Vessel Type:</td>
                    <td className="">
                      {formReportdata.vessel_type_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">Vessel Sub Type:</td>
                    <td className="">
                      {formReportdata.vessel_sub_type || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">COA-VC Purch ID</td>
                    <td>{formReportdata.coa_vc_purchase_id || "N/A"}</td>
                    <td className="font-weight-bold">My Company / LOB :</td>
                    <td className="">
                      {formReportdata.my_company_name || "N/A"} /{" "}
                      {formReportdata.lob_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">Status :</td>
                    <td>{formReportdata.c_status_name || "N/A"}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Cargo Name :</td>
                    <td>{formReportdata.cargo_name || "N/A"}</td>

                    <td className="font-weight-bold">Invoice By:</td>
                    <td>{formReportdata.invoice_by_name || "N/A"}</td>

                    <td className="font-weight-bold">Trade Area:</td>
                    <td>{formReportdata.trade_area_name || "N/A"}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Cargo Group</td>
                    <td className="">
                      {formReportdata.cargo_group_name || "N/A"}
                    </td>
                    <td className="font-weight-bold">Min INV Qty/Unit:</td>
                    <td>
                      {formReportdata.min_inv_qty || "N/A"}/{" "}
                      {formReportdata.min_inv_unit_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">Voyage No. :</td>
                    <td></td>
                  </tr>

                  <tr></tr>

                  <tr>
                    <td className="font-weight-bold">Owner:</td>
                    <td>{formReportdata.owner || "N/A"}</td>
                    <td className="font-weight-bold">BL Qty/Unit:</td>
                    <td className="">
                      {formReportdata.bl_qty || "N/A"}/{" "}
                      {formReportdata.bl_unit_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">SF(M3/MT/FT3/MT):</td>
                    <td>
                      {formReportdata.stow_m3_mt
                        ? formReportdata.stow_m3_mt
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">CP Qty/Unit:</td>
                    <td>
                      {formReportdata.cp_qty || "N/A"}/{" "}
                      {formReportdata.cp_unit_name || "N/A"}
                    </td>
                    <td className="font-weight-bold">Currency:</td>
                    <td>{formReportdata.currency_name || "N/A"}</td>

                    <td className="font-weight-bold">Load/Disch Term:</td>
                    <td className="">
                      {formReportdata.load_term_name || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">Option %:</td>
                    <td>{formReportdata.option_percentage || "N/A"}</td>
                    <td className="font-weight-bold">Freight Bill Via:</td>
                    <td>{formReportdata.freight_bill_via_name || "N/A"}</td>
                    <td className="font-weight-bold">Load/Disch $</td>
                    <td>{formReportdata.load_disch_currency_name || "N/A"}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold"> Min/Max Qty</td>
                    <td>
                      {formReportdata.min_qty || "N/A"}/{" "}
                      {formReportdata.max_qty || "N/A"}
                    </td>
                    <td className="font-weight-bold">Freight type:</td>
                    <td>{formReportdata.freight_type_name || "N/A"}</td>
                    <td className="font-weight-bold">Load Dem / Desp</td>
                    <td>
                      {formReportdata.load_dem || "N/A"}/{" "}
                      {formReportdata.load_dep || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold"> CP Date/Place:</td>
                    <td>
                      {formReportdata.cp_date || "N/A"}/{" "}
                      {formReportdata.cp_place || "N/A"}
                    </td>
                    <td className="font-weight-bold">Freight rate:</td>
                    <td>{formReportdata.freight_rate || "N/A"}</td>
                    <td className="font-weight-bold">Disch Dem / Desp</td>
                    <td>
                      {formReportdata.discharge_dem || "N/A"}/{" "}
                      {formReportdata.discharge_dep || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">CP Draft (M):</td>
                    <td>{formReportdata.cp_draft_m || "N/A"}</td>
                    <td className="font-weight-bold">Nominated Vessel:</td>
                    <td>{formReportdata.nomiated_vessel_name || "N/A"}</td>
                    <td className="font-weight-bold">Fixed By:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">Laycan From/To:</td>
                    <td>
                      {formReportdata.laycan_from || "N/A"}/{" "}
                      {formReportdata.laycan_to || "N/A"}
                    </td>
                    <td className="font-weight-bold">Cargo Contract ID:</td>
                    <td>{formReportdata.cargo_contract_id || "N/A"}</td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>

              <table className="table custom-table-bordered">
                <tbody>
                  <td className="border-0">
                    <p className="TopHeading font-weight-bold">Load Options</p>
                    <table className="table custom-table-bordered tc-table">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th>Port/Area</th>
                          <th>L/D Rates</th>
                          <th>L/H Rates</th>
                          <th>Terms</th>
                          <th>TT</th>
                          <th>PExp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.loadoptions &&
                        formReportdata.loadoptions.length > 0
                          ? formReportdata.loadoptions.map((e, idx) => {
                              return (
                                <>
                                  <tr
                                    key={idx}
                                    style={{ border: "1px solid black" }}
                                  >
                                    <td>{e.port_area_name || "N/A"}</td>
                                    <td>
                                      {e.l_d_rates
                                        ? parseFloat(e.l_d_rates).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>
                                      {e.l_h_rates
                                        ? parseFloat(e.l_h_rates).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>{e.ld_terms_name || "N/A"}</td>
                                    <td>{e.ld_tt || "0.00"}</td>
                                    <td>{e.pexp || "0.00"}</td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                  </td>

                  <td>
                    <p className="TopHeading font-weight-bold">
                      Discharge Options
                    </p>
                    <table className="table custom-table-bordered tc-table">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th>Port/Area</th>
                          <th>L/D Rates</th>
                          <th>L/H Rates</th>
                          <th>Terms</th>
                          <th>TT</th>
                          <th>PExp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.dischargeoptions &&
                        formReportdata.dischargeoptions.length > 0
                          ? formReportdata.dischargeoptions.map((e, idx) => {
                              return (
                                <>
                                  <tr
                                    key={idx}
                                    style={{ border: "1px solid black" }}
                                  >
                                    <td>{e.port_area_name || "N/A"}</td>
                                    <td>
                                      {e.l_d_rates
                                        ? parseFloat(e.l_d_rates).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>
                                      {e.l_h_rates
                                        ? parseFloat(e.l_h_rates).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>{e.ld_terms_name || "N/A"}</td>
                                    <td>{e.ld_tt || "0.00"}</td>
                                    <td>{e.pexp || "0.00"}</td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                  </td>
                </tbody>
              </table>

              <h4 className="TopHeading  font-weight-bold">Cargo Itinerary</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Port</th>
                    <th>F</th>
                    <th>Cargo Group</th>
                    <th>CP Qty</th>
                    <th>Unit</th>
                    <th>L/D Rate(D)</th>
                    <th>L/D Rate(H)</th>
                    <th>Terms</th>
                    <th>TT</th>
                    <th>P Days</th>
                    <th>Ext Days</th>
                    <th>Port Exp</th>
                    <th>Other Exp. Draft</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.itineraryoptions &&
                  formReportdata.itineraryoptions.length > 0
                    ? formReportdata.itineraryoptions.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.cv_f_name || "N/A"}</td>
                              <td>{e.io_f_name || "N/A"}</td>
                              <td>{e.cargo_name || "N/A"}</td>
                              <td>{e.io_qty || "N/A"}</td>
                              <td>{e.unit_name || "N/A"}</td>
                              <td>{e.l_d_rate || "N/A"}</td>
                              <td>{e.l_d_rate_h || "N/A"}</td>
                              <td>{e.io_terms_name || "N/A"}</td>
                              <td>{e.io_tt || "N/A"}</td>
                              <td>{e.io_pd || "N/A"}</td>
                              <td>{e.ext_days || "N/A"}</td>
                              <td>{e.port_exp || "N/A"}</td>
                              <td>{e.o_exp || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">
                Cargo Pricing & Billing
              </p>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Load Port(S)</th>
                    <th>Discharge Port(S)</th>
                    <th>Cargo</th>
                    <th>Frt Type</th>
                    <th>Amount</th>
                    <th>Table</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["--"] &&
                  formReportdata["--"] &&
                  formReportdata["--"].length > 0
                    ? formReportdata["--"].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.load_ports_name || "N/A"}</td>
                              <td>{e.ldischarge_ports_name || "N/A"}</td>
                              <td>{e.cargo_name1 || "N/A"}</td>
                              <td>{e.freight_type_name || "N/A"}</td>
                              <td>
                                {e.amount
                                  ? parseFloat(
                                      e.amount.replaceAll(",", "")
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                              <td>{e.p_table || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <td className="border-0">
                    <p className="TopHeading  font-weight-bold">Broker</p>
                    <table className="table custom-table-bordered tc-table">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th>Broker</th>
                          <th>Amount</th>
                          <th>Rate Type</th>
                          <th>Pay Method</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.broker &&
                        formReportdata.broker.length > 0
                          ? formReportdata.broker.map((e, idx) => {
                              return (
                                <>
                                  <tr key={idx}>
                                    <td>{e.broker_name || "N/A"}</td>
                                    <td>
                                      {e.amount
                                        ? parseFloat(
                                            e.amount.replaceAll(",", "")
                                          ).toFixed(2)
                                        : "0.00"}
                                    </td>
                                    <td>{e.rate_type_name || "N/A"}</td>
                                    <td>{e.pay_method_name || "N/A"}</td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                  </td>

                  <td className="border-0">
                    <p className="TopHeading  font-weight-bold">
                      Extra Freight Term
                    </p>
                    <table className="table custom-table-bordered tc-table">
                      <thead>
                        <tr className="HeaderBoxText">
                          <th>Extra Freight Term</th>
                          <th>Code</th>

                          <th>Rate/Lump</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formReportdata.extrafreightterm &&
                        formReportdata.extrafreightterm.length > 0
                          ? formReportdata.extrafreightterm.map((e, idx) => {
                              return (
                                <>
                                  <tr key={idx}>
                                    <td>
                                      {e.extra_freight_term_name || "N/A"}
                                    </td>
                                    <td>{e.code || "N/A"}</td>

                                    <td>
                                      {e.rate_lump
                                        ? parseFloat(
                                            e.rate_lump.replaceAll(",", "")
                                          ).toFixed(2)
                                        : "N/A"}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : undefined}
                      </tbody>
                    </table>
                  </td>
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">
                Billing and Banking details
              </p>
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Remit Bank :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {formReportdata.billingandbankingdetails
                          .remittance_bank_name || "N/A"}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">Account No :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {formReportdata.billingandbankingdetails.account_no ||
                          "N/A"}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">1st Invoice(%) :</td>

                    {formReportdata.billingandbankingdetails &&
                    formReportdata.billingandbankingdetails.inv_per ? (
                      <td className="">
                        {parseFloat(
                          formReportdata.billingandbankingdetails.inv_per.replaceAll(
                            ",",
                            ""
                          )
                        ).toFixed(2)}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Balance(%) :</td>

                    {formReportdata.billingandbankingdetails &&
                    formReportdata.billingandbankingdetails.bal_per ? (
                      <td className="">
                        {parseFloat(
                          formReportdata.billingandbankingdetails.bal_per.replaceAll(
                            ",",
                            ""
                          )
                        ).toFixed(2)}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Payment Term :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {
                          formReportdata.billingandbankingdetails
                            .payment_term_name
                        }
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Due Date :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {formReportdata.billingandbankingdetails.due_date}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Billing Days :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {formReportdata.billingandbankingdetails.billing_days ||
                          "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Freight Surcharge :</td>

                    {formReportdata.billingandbankingdetails &&
                    formReportdata.billingandbankingdetails
                      .freight_surcharge ? (
                      <td className="">
                        {parseFloat(
                          formReportdata.billingandbankingdetails.freight_surcharge.replaceAll(
                            ",",
                            ""
                          )
                        ).toFixed(2)}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Billing Basis :</td>

                    {formReportdata.billingandbankingdetails ? (
                      <td className="">
                        {formReportdata.billingandbankingdetails
                          .billing_basis_name || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">
                Supplier/Receiver Info
              </p>
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Final Destination :</td>
                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].final_destination || "N/A"}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">Supplier :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].supplier_name || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Receiver :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].reciever_name || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Ref Company :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].ref_company_name || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">VAT No / Tax % :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].vat_no} /{" "}
                        {parseFloat(formReportdata["-------"].vat_per).toFixed(
                          2
                        )}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">OBL No. :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].obl_no || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Ref contact :</td>
                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].ref_contract || "N/A"}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">Commingle Code :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].commingle_code || "N/A"}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">Trader :</td>

                    {formReportdata["-------"] && formReportdata["-------"] ? (
                      <td className="">
                        {formReportdata["-------"].trader || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">
                Estimate Rev/Exp Info
              </p>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Description Type</th>
                    <th>Rev/Exp</th>
                    <th>Type Of Rev/Exp</th>
                    <th>Price</th>
                    <th>Currency</th>
                    <th>Total Amount($)</th>
                    <th>Final Amount</th>
                    <th>Account Code</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.revexpinfo &&
                  formReportdata.revexpinfo &&
                  formReportdata.revexpinfo.length > 0
                    ? formReportdata.revexpinfo.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.description || "N/A"}</td>
                              <td>{e.rev_exp_name || "N/A"}</td>
                              <td>{e.re_type_name || "N/A"}</td>
                              <td>{e.price || "N/A"}</td>
                              <td>{e.re_currency_name || "N/A"}</td>
                              <td>
                                {e.total_amount
                                  ? parseFloat(
                                      e.total_amount.replaceAll(",", "")
                                    ).toFixed(2)
                                  : "N/A"}
                              </td>
                              <td>
                                {e.final_amount
                                  ? parseFloat(
                                      e.final_amount.replaceAll(",", "")
                                    ).toFixed(2)
                                  : "N/A"}
                              </td>
                              <td>{e.account_code || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">Rebill</p>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>F</th>
                    <th>Port</th>
                    <th>Ledger Code</th>
                    <th>Ledger Code Description</th>
                    <th>Cost Details Description</th>
                    <th>Rebillable</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.rebillsettings &&
                  formReportdata.rebillsettings.length > 0
                    ? formReportdata.rebillsettings.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.cv_f_name || "N/A"}</td>
                              <td>{e.cv_port_name || "N/A"}</td>
                              <td>{e.ledger_code || "N/A"}</td>
                              <td>{e.ledger_code_description || "N/A"}</td>
                              <td>{e.cost_details_description || "N/A"}</td>
                              <td>{e["rebillable"] === 1 ? "Yes" : "No"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <p className="TopHeading  font-weight-bold">Dem/Des Term</p>
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Load Dem/Des :</td>

                    {formReportdata["-"] && formReportdata["-"] ? (
                      <td className="">
                        {formReportdata["-"].load_dem} /{" "}
                        {formReportdata["-"].load_des}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Total Time Bar :</td>
                    {formReportdata["---"] && formReportdata["---"] ? (
                      <td className="">
                        {formReportdata["---"].total_time_bar || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Per Day/Per hour :</td>

                    {formReportdata["-"] && formReportdata["-"] ? (
                      <td className="">
                        {formReportdata["-"].per_day === 1 ? "Yes" : "No"} /{" "}
                        {formReportdata["-"].per_hour === 1 ? "Yes" : "No"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">TT Hours :</td>

                    {formReportdata["---"] && formReportdata["---"] ? (
                      <td className="">{formReportdata["---"].tt_hours}</td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Disc Dem/Des :</td>

                    {formReportdata["-"] && formReportdata["-"] ? (
                      <td className="">
                        {formReportdata["-"].disch_dem} /{" "}
                        {formReportdata["-"].disch_des}
                      </td>
                    ) : undefined}
                    <td className="font-weight-bold">Freight Surcharge :</td>

                    {formReportdata["---"] && formReportdata["---"] ? (
                      <td className="">
                        {formReportdata["---"].freight_surcharge || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Per Day/Per hour :</td>

                    {formReportdata["-"] && formReportdata["-"] ? (
                      <td className="">
                        {formReportdata["-"].d_per_day === 1 ? "Yes" : "No"} /{" "}
                        {formReportdata["-"].d_per_hour === 1 ? "Yes" : "No"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Bunker Surcharge :</td>

                    {formReportdata["---"] && formReportdata["---"] ? (
                      <td className="">
                        {formReportdata["---"].bunker_surcharge || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Dem/Des Curr :</td>

                    {formReportdata["-"] && formReportdata["-"] ? (
                      <td className="">
                        {formReportdata["-"].dem_des_curr_name || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Admin Charges :</td>

                    {formReportdata["---"] && formReportdata["---"] ? (
                      <td className="">
                        {formReportdata["---"].admin_charges || "N/A"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Demurrage :</td>

                    {formReportdata["-"] && formReportdata["-"] ? (
                      <td className="">
                        {formReportdata["-"].demurrage || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Dem/Des Commitable :</td>

                    {formReportdata["---"] && formReportdata["---"] ? (
                      <td className="">
                        {formReportdata["---"]["dem_des_commitable"] === 1
                          ? "Yes"
                          : "No"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Laytime :</td>

                    {formReportdata["-"] && formReportdata["-"] ? (
                      <td className="">
                        {formReportdata["-"].laytime || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Reversible All Port :</td>

                    {formReportdata["---"] && formReportdata["---"] ? (
                      <td className="">
                        {formReportdata["---"]["reversible_all_port"] === 1
                          ? "Yes"
                          : "No"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Laytime Allowed :</td>

                    {formReportdata["-"] && formReportdata["-"] ? (
                      <td className="">
                        {formReportdata["-"].laytime_allowed || "N/A"}
                      </td>
                    ) : undefined}

                    <td className="font-weight-bold">Non Rev. All Port :</td>

                    {formReportdata["---"] && formReportdata["---"] ? (
                      <td className="">
                        {formReportdata["---"]["non_rev_all_port"] === 1
                          ? "Yes"
                          : "No"}
                      </td>
                    ) : undefined}
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Days / Hours :</td>

                    {formReportdata["-"] && formReportdata["-"] ? (
                      <td className="">
                        {formReportdata["-"].hours === 1 ? "Yes" : "No"}
                      </td>
                    ) : undefined}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
});

const CargoPurchaseReport = (props) => {
  const [pdfData, setPdfData] = useState();
  const [userInput, setUserInput] = useState();
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mailTitlePayload, setMailTitlePayload] = useState({});

  const componentRef = useRef(null);

  useEffect(() => {
    setUserInput(props.data);
  }, []);

  const sendEmailReportModal = async () => {
    try {
      setLoading(true);

      const quotes = document.getElementById("divToPrint");

      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      const blob = doc.output("blob");

      setLoading(false);
      setPdfData(blob);
      setEmailModal(true);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const printDocument = () => {
    const quotes = document.getElementById("divToPrint");
    html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("p", "mm");
      let position = 8;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      const imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 10,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("Cargo_Purchase_Report.pdf");
    });
  };

  return (
    <div className="body-wrapper modalWrapper">
      <article className="article toolbaruiWrapper">
        <div className="box box-default">
          <div className="box-body">
            <div className="toolbar-ui-wrapper">
              <div className="leftsection"></div>
              <div className="rightsection">
                <span className="wrap-bar-menu">
                  <ul className="wrap-bar-ul">
                    <li onClick={sendEmailReportModal}>Send Email</li>
                    <li onClick={printDocument}>Download</li>
                    <li>
                      <ReactToPrint
                        trigger={() => (
                          <span className="text-bt">
                            <PrinterOutlined />
                            Print
                          </span>
                        )}
                        content={() => componentRef.current}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            <ComponentToPrint ref={componentRef} data={props.data} />
          </div>
        </div>
      </article>

      {emailModal && (
        <Modal
          title="New Message"
          visible={emailModal}
          onOk={() => {
            setEmailModal(false);
          }}
          onCancel={() => {
            setEmailModal(false);
          }}
          footer={null}
        >
          {pdfData && (
            <Email
              handleClose={() => {
                setEmailModal(false);
              }}
              attachmentFile={pdfData}
              title={window.corrector(
                `Cargo_Purchase_Report||${userInput.vessel_name}||${userInput.charterer_name}||${userInput.company_lob_name}||${userInput.tco_status_name}`
              )}
            />
          )}
        </Modal>
      )}
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default CargoPurchaseReport;
