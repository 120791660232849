import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Select, Spin, Layout, Drawer, Modal, Alert } from "antd";
import NormalFormIndex from "../../../../../shared/NormalForm/normal_from_index";
import {
  DeleteOutlined,
  MenuFoldOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import URL_WITH_VERSION, {
  apiDeleteCall,
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
  useStateCallback,
} from "../../../../../shared";

const FormItem = Form.Item;
const Option = Select.Option;
const { Content } = Layout;
const RepositionAnalysis = ({ formdata }) => {
  const [state, setState] = useStateCallback({
    refreshForm: false,
    frmName: "tcov_full_repos_form",
    formData: {},
  });

  const formdataref = useRef(null);

  useEffect(() => {
    try {
      let itrspeed = formdata?.["portitinerary"]?.[0]?.["speed"] ?? 0;
      let open_port =
        formdata?.["portitinerary"]?.[0]?.["port"] ?? "select port";
      let passagetype = formdata?.["portitinerary"]?.[0]?.["passage"];

      let fuelarr = [];
      formdata?.["."]?.map((el) => {
        let fuel_obj = {
          fuel_type: el.fuel_type,
          consvalue: passagetype == "1" ? el.ballast_value : el.laden_value,
          cons: passagetype == "1" ? el.ballast_value : el.laden_value,
          price: 0,
          cost: 0,
        };
        fuelarr.push(fuel_obj);
      });

      setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          open_port,
          wf_per: 5,
          daily_hire: formdata?.["tci_d_hire"] ?? 0,
          itrspeed,
          "-": fuelarr,
          total_repos_exp: 0,
          estimate_id: formdata.estimate_id,
          // id: "",
        },
      }));
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  const saveFormData = async (data, innercb) => {
    try {
      let suURL = `${URL_WITH_VERSION}/voyage-manager/repo-analysis/save`;
      let suMethod = "POST";
      if (data && data.hasOwnProperty("id") && data["id"]) {
        suURL = `${URL_WITH_VERSION}/voyage-manager/repo-analysis/update`;
        suMethod = "PUT";
        //data["id"] = data["id"];
      }
      formdataref.current = data;
      await postAPICall(suURL, data, suMethod, async (data) => {
        if (data && data.data) {
          openNotificationWithIcon("success", data.message, 3);

          let _formdata = Object.assign({ id: data.row }, formdataref.current);

          setState((prevState) => ({ ...prevState, formData: _formdata }));
          _onLeftSideListClick(data.row || formdataref.current.id);
        } else {
          let dataMessage = data.message;
          let msg = "<div className='row'>";
          if (typeof dataMessage !== "string") {
            Object.keys(dataMessage).map(
              (i) =>
                (msg +=
                  "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
            );
          } else {
            msg += dataMessage;
          }
          msg += "</div>";
          openNotificationWithIcon(
            "error",
            <div dangerouslySetInnerHTML={{ __html: msg }} />
          );
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const deleteData = (id, tcov_id, innercb) => {
    try {
      setState((prevState) => ({ ...prevState, refreshForm: true }));
      let URL = `${URL_WITH_VERSION}/voyage-manager/repo-analysis/delete`;
      apiDeleteCall(URL, { id: id }, (resp) => {
        if (resp && resp.data) {
          openNotificationWithIcon("success", resp.message);
          setState((prevState) => ({
            ...prevState,
            formData: {},
            refreshForm: false,
          }));
        } else {
          openNotificationWithIcon("error", resp.message);
        }
      });
    } catch (err) {
      console.log("err", err);
      setState((prevState) => ({ ...prevState, refreshForm: false }));
    }
  };

  const _onLeftSideListClick = async (id) => {
    setState((prevState) => ({ ...prevState, refreshForm: true }));
    try {
      const data = await getAPICall(
        `${URL_WITH_VERSION}/voyage-manager/repo-analysis/edit?ae=${id}`
      );
      setState((prevState) => ({
        ...prevState,
        formData: Object.assign({}, data.data),
        refreshForm: false,
      }));
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="tcov-wrapper full-wraps">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <div className="fieldscroll-wrap">
              <div className="body-wrapper">
                <article className="article">
                  <div className="box box-default">
                    <div className="box-body common-fields-wrapper">
                      {state.refreshForm === false ? (
                        <NormalFormIndex
                          key={"key_" + state.frmName + "_0"}
                          formClass="label-min-height"
                          formData={state.formData}
                          showForm={true}
                          frmCode={state.frmName}
                          showToolbar={[
                            {
                              isLeftBtn: [
                                {
                                  key: "s1",
                                  isSets: [
                                    {
                                      id: "7",
                                      key: "menu-fold",
                                      type: <MenuFoldOutlined />,
                                      withText: "List",
                                      showToolTip: true,
                                      event: "menu-fold",
                                    },
                                    // { id: '8', key: 'plus', type: 'plus', withText: '', event: (key, saveData, innerCB) => (innerCB && typeof innerCB === 'function' ? innerCB() : undefined) },
                                    {
                                      id: "2",
                                      key: "save",
                                      type: <SaveOutlined />,
                                      withText: "Save",
                                      showToolTip: true,
                                      event: (key, saveData, innerCB) => {
                                        saveFormData(saveData, innerCB);
                                      },
                                    },
                                    {
                                      id: "3",
                                      key: "delete",
                                      type: <DeleteOutlined />,
                                      withText: "Delete",
                                      showToolTip: true,
                                      event: (key, saveData, innerCB) => {
                                        if (
                                          saveData["id"] &&
                                          saveData["id"] > 0
                                        ) {
                                          Modal.confirm({
                                            title: "Confirm",
                                            content:
                                              "Are you sure, you want to delete it?",
                                            onOk: () =>
                                              deleteData(
                                                saveData["id"],
                                                saveData["tcov_id"],
                                                innerCB
                                              ),
                                          });
                                        }
                                      },
                                    },
                                  ],
                                },
                              ],
                              isRightBtn: [
                                {
                                  key: "rbs1",
                                  isSets: [
                                    {
                                      key: "reports",
                                      isDropdown: 0,
                                      withText: "Reports",
                                      type: "",
                                      menus: [],
                                    },
                                  ],
                                },
                              ],
                              isResetOption: false,
                            },
                          ]}
                          inlineLayout={true}
                          sideList={{
                            selectedID: formdata.estimate_id,
                            showList: true,
                            title: "Reposition List",
                            uri: "/voyage-manager/repo-analysis/list?l=0",
                            columns: [
                              "open_port",
                              "repos_port",
                              "total_repos_exp",
                            ],
                            icon: true,
                            rowClickEvent: (evt) =>
                              _onLeftSideListClick(evt.id),
                          }}
                          showSideListBar={!formdata}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default RepositionAnalysis;
