import React, { Component } from 'react';
import NormalFormIndex from '../../../../shared/NormalForm/normal_from_index';
import URL_WITH_VERSION, { postAPICall, openNotificationWithIcon } from '../../../../shared';
import { SaveOutlined } from '@ant-design/icons';

class CargoChildForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      frmName: 'cargo_term_details',
      formData: this.props.formData || {},
      saveEvent: this.props.onSaveEvent,
      isShowFixedColumn: this.props.isShowFixedColumn || []
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  }
  importData= (postData) => {
    const {saveEvent } = this.state;
    Object.keys(postData).forEach((key) => (postData[key] == null) && delete postData[key]);
    openNotificationWithIcon('success','Cargo Term detail is imported successfully.');
    saveEvent(postData);
  }
  saveFormData = (postData, innerCB) => {
    const { frmName, saveEvent } = this.state;
    let _url = "save";
    let _method = "post";
    if (postData.hasOwnProperty('id')) {
      _url = "update";
      _method = "put";
    }

    Object.keys(postData).forEach((key) => (postData[key] == null) && delete postData[key]);
    postAPICall(`${URL_WITH_VERSION}/cargo-terms/${_url}?frm=${frmName}`, postData, _method, (data) => {
      if (data.data) {
        openNotificationWithIcon('success', data.message);
        if (innerCB && typeof innerCB === 'function') {
          innerCB();
        }
        if (saveEvent && typeof saveEvent === 'function' && data.row) {
          saveEvent(data.row);
        }
      } else {
        let dataMessage = data.message;
        let msg = "<div className='row'>";

        if (typeof dataMessage !== "string") {
          Object.keys(dataMessage).map(i => msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>");
        } else {
          msg += dataMessage
        }

        msg += "</div>"
        openNotificationWithIcon('error', <div className="notify-error" dangerouslySetInnerHTML={{ __html: msg }} />)
      }
    });
  }

  render() {
    const { frmName, formData, isShowFixedColumn } = this.state;
    let rightBar=[]
    if (this.props && this.props.hasOwnProperty('isvoyagemanger') && this.props.isvoyagemanger) {
      rightBar = []
    }
    else {
      rightBar = [
        {
          key: 'addcargo',
          isDropdown: 0,
          withText: 'Import',
          type: 'import',
          menus: null,
          event: (key, data) => {
            this.importData(data)
          },
        },
      ]

    }
    return (
      <div className="body-wrapper">
        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <NormalFormIndex
                key={'key_' + frmName + '_0'} formClass="label-min-height" formData={formData}
                showForm={true}
                addForm={true}
                frmCode={frmName}
                showButtons={[]}
                showToolbar={[{
                  isLeftBtn: [
                    {
                      key: "s1",
                      isSets: [
                        { id: "2", key: "save", type: <SaveOutlined />, withText: "Save",showToolTip: true, "event": (key, saveData, innerCB) => { this.saveFormData(saveData, innerCB) } },
                      ]
                    }
                  ],
                  isRightBtn: [
                    {
                      key: "rbs1",
                      isSets:rightBar
                    }
                  ],
                  isResetOption: false
                }]}
                inlineLayout={true}
                isShowFixedColumn={isShowFixedColumn}
              />
            </div>
          </div>
        </article>
      </div>
    )
  }
}

export default CargoChildForm;
