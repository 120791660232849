import {
  DownloadOutlined,
  FilterOutlined,
  PlusOutlined,
  PrinterOutlined,
  FileTextOutlined,
  ReloadOutlined,
  DownOutlined,
} from "@ant-design/icons";

const TOOLBARCONFIGURATION = {
  "voyage-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { value: "all", options: "" },
          { value: "short_name", options: "Short Name" },
          { value: "full_name", options: "Full Name" },
          { value: "address_type", options: "Company Type" },
          { value: "currency", options: "Currency" },
        ],
      },
    ],

    isCenterSection: [
      {
        isPagination: 1,
        total: 100,
        current: 1,
        pageLimit: 10,
      },
    ],

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "voyage-operation",
            tooltip: "",
            size: null,
            type: "",
            text: "Voyage Operation",
          },
        ],
      },
    ],
  },

  "datacenter-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          // put the dynamic data here for master list
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "vessel-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "All",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "owner_ship_name", label: "Ownership" },

          { value: "vessel_code ", label: "Vessel Code" },
          { value: "vessel_type_name", label: "Vessel Type" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "vessel-file-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "All",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "my_company_name", label: "My Company" },
          { value: "owner_name", label: "Reg./Deps Owner" },
          { value: "con_type_name", label: "Contract Type" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "hire-payable-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "vessel_type", label: "Vessel Type" },
          { value: "trans_no", label: "Trans No" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "hire-receiveable-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "freight-invoice-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Invoice No", value: "invoice_no" },
          { label: "Invoice Date", value: "invoice_date" },
          { label: "Due Date", value: "due_date" },
          { label: "Vessel", value: "vessel_name" },
          { label: "Voy No", value: "voy_no" },
          { label: "Counter Party", value: "counterparty" },
          { label: "Invoice Type", value: "invoice_type" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "freight-commission-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Invoice No", value: "invoice_no" },
          { label: "Voyage No", value: "voy" },
          { label: "Vessel", value: "vessel_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "transaction-summary-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "voyage_manager_id", label: "Voy No" },
          { value: "trans_no", label: "Trans No" },
          { value: "invoice_type_name", label: "Invoice Type" },
          { value: "vendor_name", label: "Vendor" },
          { value: "po_number", label: "Po No" },
          { value: "invoice_no", label: "Invoice No" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "vendor-transaction-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vendor Name", value: "vendor_name" },
          { label: "AP/AR Type", value: "invoice_name" },
          { label: "Transc. No", value: "trans_no" },
          { label: "Invoice Type", value: "invoice_type_name" },
          { label: "PO No", value: "po_number" },
          { label: "Invoice. No", value: "invoice_no" },
          { label: "Vessel Name", value: "vessel_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "cargo-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "cargo_contract_id", label: "Cargo Contract Id" },
          { value: "charterer_name", label: "Charterer Name" },
          { value: "company", label: "Company" },
          { value: "freight_type", label: "Freight Type" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          // {
          //   key: "download",
          //   tooltip: "Download",
          //   size: "small",
          //   type: <DownOutlined />,
          //   text: "",
          // },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "new-cargo-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "short_name", label: "Short Name" },
          { value: "full_name", label: "Full Name" },
          { value: "group", label: "Cargo Group" },
          { value: "product_code", label: "Product Code" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "address-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "short_name", label: "Short Name" },
          { value: "full_name", label: "Company Name" },
          { value: "address_type", label: "Address Type" },
          { value: "phone_number", label: "Phone Number" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
          {
            key: "new-entry",
            tooltip: "New Address Form",
            size: "small",
            type: <PlusOutlined />,
            text: "New Address Form",
          },
        ],
      },
    ],
  },

  "tc-in-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "ALL",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "tc_code", label: "TCI Code" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "owner_name", label: "Owner Name" },
          { value: "tci_status_name", label: "Tci Status" },
          { value: "company_fixed_with_name", label: "Company" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "tcov-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { value: "", label: "All" },
          { value: "estimate_id", label: "Quick Estimate ID" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "ballast_port", label: "Ballast Port" },
          { value: "repos_port", label: "Reposition Port" },
        ],
      },
    ],
    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "tcov-list-toolbar1": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "imo_no", label: "IMO No" },
          { value: "vessel_dwt", label: "DWT" },
        ],
      },
    ],
    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          // { key: "download", tooltip: "Download", size: "small", type: <DownloadOutlined />, text: "" },

          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },

          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "tcov-list-toolbar2": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { value: "", label: "All" },
          { value: "estimate_id", label: "Voyage Estimate Id" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "vessel_type_name", label: "Vessel Type" },
          { value: "my_company_name", label: "My Company Lob" },
          { value: "tci_code", label: "Tci Id" },
        ],
      },
    ],
    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "vivo-list-toolbar2": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { value: "", label: "All" },
          { value: "estimate_id", label: "Voyage Relet Estimate Id" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "vessel_type", label: "Vessel Type" },
          // { value: "my_company_name", label: "My Company Lob" },
          // { value: "tci_code", label: "Tci Id" },
        ],
      },
    ],
    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "chartring-dashboard-list": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { value: "", label: "All" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "voyage_number", label: "Voyage No" },
          { value: "vessel_type_name", label: "Vessel Type" },
        ],
      },
    ],
    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "reset-serach",
            tooltip: "Reset Search",
            size: "small",
            type: <ReloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "Finance-Dashboard-list": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { value: "", label: "All" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "voyage_number", label: "voyage_no" },
          { value: "tci_code", label: "TCI Code" },
        ],
      },
    ],
    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "reset-serach",
            tooltip: "Reset Search",
            size: "small",
            type: <ReloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "track-my-fleet": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "IMO", label: "IMO No" },
          { value: "SHIPNAME", label: "Vessel Name" },
          { value: "SHIPTYPE", label: "Current Port Name" },
          { label: "LON", value: "last_pos_lon" },
          { label: "LAT", value: "last_pos_lat" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "voy-relet-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "tcto-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "TC Est. Code", value: "estimate_id" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "vessel_type", label: "Vessel Type" },
          { value: "company_fixed_with", label: "My Company" },
          // { value: "charterer_name", label: "Charter" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "ports-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "PortID", label: "Port ID" },
          { value: "port_name", label: "Port Name" },
          { value: "latitude", label: "Latitude" },
          { value: "port_type", label: "Port Type" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "countries-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "country_name", label: "Country Name" },
          { value: "currency", label: "Currency" },
          { value: "currency_alpha_code", label: "Currency Alpha Code" },
          { value: "numeric_currency_code", label: "Numeric Currency Code" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "coa-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },

          { value: "contract_id", label: "COA Cargo(ID)" },
          { value: "cargo_name1", label: "Cargo Name" },
          { value: "charterer1", label: "Charterer" },
          { value: "status_name", label: "Status" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "voyage-fix-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "advance-payment-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "TDE No", value: "tde" },
          { label: "My Company", value: "my_company_name" },
          { label: "Vendor Company", value: "vendor_company_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "voyage-manager-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "vesselName", label: "Vessel Name" },
          { value: "voyageNumber", label: "Voyage No" },
          { value: "imo", label: "IMO No." },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "voyage-manager-list-toolbar1": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "voyage_number", label: "Voyage No" },
          //  { value: "imo", label: "IMO No." }
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "port-performance-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },

          { value: "vessel_name", label: "Vessel Name" },
          { value: "estimate_id", label: "Voyage No" },
          { value: "port", label: "Port Name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "reset-serach",
            tooltip: "Reset Search",
            size: "small",
            type: <ReloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "port-expenses-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "agent_full_name", label: "Agent Name" },
          { value: "portid", label: "portid" },
          { value: "voyage_manager_id", label: "Voy No" },
          { value: "fda_status_name", label: "FDA Status" },
          { value: "pda_adv_status_name", label: "PDA Status" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "voyage-history-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel Name", value: "vesselName" },
          { label: "IMO No.", value: "imo_no" },
          { label: "Voyage Numer", value: "voyageNo" },
          { label: "Passage Type", value: "passageType" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "voyage-cargo-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "cargo_contract_name", label: "Cargo Contract ID" },
          { value: "charterer_name", label: "Charterer" },
          { value: "my_companyc_status", label: "Company" },
          { value: "nomiated_vessel", label: "Nomiated Vessel" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "tco-manager-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "ALL",
        customeDropdownValue: 1,
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "tc_code", label: "TCO Code" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "bill_via_name", label: "Bill Via" },
          { value: "charterer_name", label: "Charter" },
          { value: "company_fixed_with_name", label: "Company" },
          { value: "tco_status_name", label: "TCO Status" },
        ],
      },
    ],
    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "cargo-search-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "list-coa-vci-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "contract_id", label: "COA(VC)ID" },
          { value: "owner_name", label: "Owner Name" },
          { value: "cp_date", label: "C/P Date" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "port-activity-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "report",
            tooltip: "Report",
            size: "small",
            type: <FileTextOutlined />,
            text: "",
          },
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "printer",
            tooltip: "Print",
            size: "small",
            type: <PrinterOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "port-function-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "report",
            tooltip: "Report",
            size: "small",
            type: <FileTextOutlined />,
            text: "",
          },
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "printer",
            tooltip: "Print",
            size: "small",
            type: <PrinterOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "myport-calls-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Portcall ref Id", value: "port_call_ref_id" },
          { label: "Vessel", value: "vessel_name" },
          { label: "Vessel Code", value: "vessel_code" },
          { label: "Port", value: "portcall_port_name" },
          { label: "Agent", value: "agent_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "report",
            tooltip: "Report",
            size: "small",
            type: <FileTextOutlined />,
            text: "",
          },
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "printer",
            tooltip: "Print",
            size: "small",
            type: <PrinterOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "other-expense-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel Name", value: "vessel_name" },
          { label: "Voy No", value: "estimate_id" },
          { label: "Voyage Type", value: "vessel_type_name" },
          { label: "Commence Date", value: "commence_date" },
          { label: "Completed Date", value: "completing_date" },
        ],
        // isDropdownOption: [
        //   { label: "All", value: "" },
        //   { label: "Vessel Name", value: "vessel_name" },
        //   { label: "Voy No", value: "voyage_number" },
        //   { label: "Voyage Type", value: "vessel_type_name" },
        //   // { label: "Vessel Sub Type", value: "vessel_sub_type" },
        // ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "reset-serach",
            tooltip: "Reset Search",
            size: "small",
            type: <ReloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "common-invoices-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel Name", value: "vessel_name" },
          { label: "Voy No", value: "voy_no" },
          { label: "Invoice No", value: "invoice_no" },
        ],
        // isDropdownOption: [
        //   { label: "All", value: "" },
        //   { label: "Vessel Name", value: "vessel_name" },
        //   { label: "Voy No", value: "voyage_number" },
        //   { label: "Voyage Type", value: "vessel_type_name" },
        //   // { label: "Vessel Sub Type", value: "vessel_sub_type" },
        // ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "reset-serach",
            tooltip: "Reset Search",
            size: "small",
            type: <ReloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "report-list": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel Name", value: "vessel_name" },
          { label: "Vessel Code", value: "vessel_code" },
          { label: "Voy No", value: "voy_no" },
          { label: "Report Type", value: "report_type" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "reset-serach",
            tooltip: "Reset Search",
            size: "small",
            type: <ReloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  laytime_summary_list_toolbar: {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel", value: "vessel" },
          { label: "Status", value: "l_status" },
          { label: "Counter Party", value: "charterer_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          // { key: "report", tooltip: "Report", size: "small", type: <FileTextOutlined />, text: "" },
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          // { key: "printer", tooltip: "Print", size: "small", type: <PrinterOutlined />, text: "" },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "user-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Unique ID", value: "user_id" },
          { label: "Username", value: "user_name" },
          { label: "First Name", value: "first_name" },
          { label: "Last Name", value: "last_name" },
          { label: "Email", value: "user_email" },

          { label: "Phone No", value: "phone_number" },
          { label: "Designation", value: "designation_name" },
          { label: "Organization", value: "organization" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "access-control-menulist-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Menu", value: "id" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      // {
      //   buttonSet: [
      //     { key: "download", tooltip: "Download", size: "small", type: <DownloadOutlined />, text: "" },
      //     { key: "column-filter", tooltip: "Column Filter", size: "small", type: <FilterOutlined />, text: "" },
      //   ],
      // },
    ],
  },
  "user-roles-access-right": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "bunker-req-summery-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel", value: "vessel_name" },
          { label: "Port", value: "port_name" },
          { label: "Request Status", value: "request_status_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "user-roles-acl-permissions": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "user-roles-assignment-access-right": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "subscriber-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "activate-vessel-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Voyage No", value: "voyage_number" },
          { label: "Vessel Name", value: "vessel_name" },
          { label: "Vessel Code", value: "vessel_code" },
          { label: "Vessel Type", value: "vessel_type_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "service-provider-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "company-user-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "Vessel-schedule-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel Name", value: "vessel_name" },
          { label: "Company Name", value: "my_company_lob_name" },
        ],
      },
      // {
      //   isDropdown: 1,
      //   isInput: 1,
      //   defaultDropdownValue: "Vessel Name",
      //   isDropdownOption: [{ label: "Vessel Name", value: "vessel_name" }],
      // },
      // {
      //   isDropdown: 1,
      //   isInput: 1,
      //   defaultDropdownValue: "Company Name",
      //   isDropdownOption: [{ label: "Company Name", value: "my_company_lob_name" }],
      // },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "user-port-call-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Portcall ref Id", value: "port_call_ref_id" },
          { label: "Vessel", value: "vessel_name" },
          { label: "Vessel Code", value: "vessel_code" },
          { label: "Port", value: "portcall_port_name" },
          { label: "Agent", value: "agent_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "tc-commission-invoice-list": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Tci Code", value: "tc_code" },
          { label: "Broker", value: "broker" },
          { label: "Invoice No", value: "invoice_no" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "address-commission-invoice-list": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel Name", value: "vessel_name" },
          { label: "Com Status Id", value: "com_status" },
          { label: "Broker", value: "broker" },
          { label: "Cargo Id", value: "cargo_id" },
          { label: "Voyage No", value: "voy" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "port-info-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "pda-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel", value: "vessel" },
          { value: "voyage_manager_id", label: "Voy No" },
          { value: "port", label: "Port" },
          { value: "pda_adv_status_name", label: "PDA Adv. Status" },
          { value: "agent_full_name", label: "Agent" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "voyage-efficiency-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { value: "vessel_name", label: "Vessel Name" },
          { value: "vessel_type", label: "Cargo Name" },
          { value: "my_company_name", label: "Port" },
          { value: "pda_adv_status_name", label: "Commence Date" },
          { value: "tci_code", label: "Completed Date" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "fda-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel", value: "vessel" },
          { value: "voyage_manager_id", label: "Voy No" },
          { value: "port", label: "Port" },
          { value: "fda_status_name", label: "FDA Status" },
          { value: "agent_full_name", label: "Agent" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "bunker-purchased-order-summery-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vendor Name", value: "vendor_name" },

          { label: "Requirement Id", value: "requirement_id" },
          { label: "Port Name", value: "port_name" },
          { label: "Purchase Status", value: "purchase_status_name" },
          { label: "Request Status", value: "request_status_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "bunker-purchase-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel Name", value: "vessel_name" },
          { value: "voyage_no", label: "Voyage No" },
          { label: "Port Name", value: "port_name" },
          { label: "Requirement Id", value: "requirement_id" },
          { label: "Company Name", value: "my_company_name" },
          { label: "Request Status", value: "request_status_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "bunker-req-summery-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "archieve-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  claim_list_toolbar: {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel Name", value: "vessel_name" },
          { label: "Voyage No", value: "voyage_no" },
          // { label: "Invoice No", value: "invoice_no" },
          { label: "Counter party", value: "counter_Party_name" },
          // { label: "Company Name", value: "company_name" },
          // { label: "Claim Status", value: "claim_status_name" },
          { label: "Invoice No", value: "invoice_no" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "offhire-deviation-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Invoice No", value: "inv_no" },
          { label: "Counter party", value: "counter_party_name" },
          { label: "Company Name", value: "my_company_name" },
          { label: "Account Type", value: "account_type_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "bunker-invoice-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel Name", value: "vessel_name" },
          { label: "Voy No", value: "voyage_no" },
          { label: "Port", value: "port_area_name" },
          { label: "Inv Status", value: "invoice_status" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },

  "cargo-enquiry-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Account For", value: "account_for" },
          { label: "Cargo Name", value: "cargoname" },
          { label: "L Port", value: "load_port_options" },
          { label: "D Port", value: "discharge_port_options" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "tonnages-enquiry-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel Name", value: "vessels_name" },
          { label: "DWT", value: "dwt" },
          { label: "Open Position", value: "open_position" },
          { label: "Bunker On Delivery", value: "bunker_on_delivery_name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "hire-schedule-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "All", value: "" },
          { label: "Vessel Name", value: "vessel_name" },
          { label: "Company Name", value: "company_name" },
          { label: "Invoice No", value: "invoice_no" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "hire-schedule-calendor-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [{ label: "All", value: "" }],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "portdata-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "UNLOCODE", value: "unlocode" },
          { value: "portname", label: "Port Name" },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          {
            key: "download",
            tooltip: "Download",
            size: "small",
            type: <DownloadOutlined />,
            text: "",
          },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
  "vesseldata-list-toolbar": {
    isLeftSection: [
      {
        isDropdown: 1,
        isInput: 1,
        defaultDropdownValue: "all",
        isDropdownOption: [
          { label: "IMO No", value: "lrimoshipno" },
          { value: "shipname", label: "Vessel Name" },
          {
            value: "maritimemobileserviceidentitymmsinumber",
            label: "MMSI Number",
          },
        ],
      },
    ],

    isCenterSection: {
      isPagination: 1,
      totalRows: 0,
      pageIndex: 1,
      pageLimit: 10,
    },

    isRightSection: [
      {
        buttonSet: [
          // {
          //   key: "download",
          //   tooltip: "Download",
          //   size: "small",
          //   type: <DownloadOutlined />,
          //   text: "",
          // },
          {
            key: "column-filter",
            tooltip: "Column Filter",
            size: "small",
            type: <FilterOutlined />,
            text: "",
          },
        ],
      },
    ],
  },
};

export default TOOLBARCONFIGURATION;
