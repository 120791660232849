import React, { useEffect } from "react";
import {
  Row,
  Col,
  Layout,
  Drawer,
  Modal,
  Spin,
  Alert,
  notification,
  Radio,
  Checkbox,
  Input,
  Form,
} from "antd";
import URL_WITH_VERSION, {
  getAPICall,
  useStateCallback,
} from "../../../../../shared";
import NormalFormIndex from "../../../../../shared/NormalForm/normal_from_index";
import StatementOfAccountReport from "../../../../../shared/components/All-Print-Reports/StatementOfAccountReport";
import OtherStatement from "../../../../../shared/components/other-statement/OtherStatement";
import SOARecapReport from "../../../../../shared/components/All-Print-Reports/SOARecapReport";
import ConfirmStatement from "../../make-payment/ConfirmStatement";
import data from "../../../../dynamic-vspm/vesselCi/data";
import ConfirmStatementAccount from "../../make-payment/ConfirmStatementAccount";
const FormItem = Form.Item;
const getFormItemLayout = () => {
  return {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
};
const { Content } = Layout;
const StatementofAccount = React.forwardRef((props, ref) => {
  // console.log("props statment", props);
  const [state, setState] = useStateCallback({
    showDiv: true,
    showDivAccount: true,
    showDivRecap: true,
    showDivOtherReport: true,
    value: 1,
    frmName: "hire_statement_of_account_form",
    externalFormFields: null,
    frmVisible: true,
    visible: false,
    tciID: props.data,
    formData: {},
    paymentData: null,
    showForm: false,
    totalAmountpaid: 0,
    formData: {
      ...props.data,
    },
    refreshForm: false,
  });

  const updatePaymentRows = async (tciID) => {
    const { formData } = state;

    setState((prev) => ({ ...prev, frmVisible: false }));
    let response = await getAPICall(
      `${URL_WITH_VERSION}/make_payment/list?l=0`,
      {
        id: {
          where:
            "`vessel_id`= " +
            tciID.vessel_id +
            " AND `tc_id`='" +
            tciID.tc_id +
            "'",
        },
      }
    );
    let respData = await response["data"];
    let totalAmount = 0;
    if (respData) {
      respData.map((e) => {
        totalAmount = totalAmount + e.amount_usd.replaceAll(",", "") * 1;
      });
    }
    let _formdata = Object.assign({}, formData);
    _formdata["-"] = respData;
    setState((prevState) => ({
      ...prevState,
      formData: { ..._formdata },
      totalAmountpaid: totalAmount,
      showForm: true,
      frmVisible: true,
    }));

    return true;
  };

  const {
    showDiv,
    externalFormFields,
    tciID,
    showDivAccount,
    showDivOtherReport,
    showDivRecap,
    frmVisible,
    showForm,
  } = state;

  useEffect(() => {
    getFormData();
  }, [state.totalAmountpaid]);

  const getFormData = async () => {
    const data = await updatePaymentRows(
      state.formData,
      state.formData,
      state.paymentFormData
    );

    if (data) {
      setState((prevState) => ({
        ...prevState,
        showForm: true,
        frmVisible: true,
        externalFormFields: {
          isShowInMainForm: true,
          content: getExternalFormFields(),
        },
      }));
    }
  };

  const getExternalFormFields = () => {
    return (
      <>
        <Row
          style={{
            width: "97%",
            margin: "10px auto",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Radio.Group onChange={onChange} value={state.value}>
              <Radio value={1}>SOA</Radio>
              {/* <Radio value={2}>Paid</Radio> */}
            </Radio.Group>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <FormItem {...getFormItemLayout()} label="Total">
              <Input
                type={"number"}
                placeholder="0.00"
                style={{ textAlign: "right" }}
                disabled
                value={state.totalAmountpaid.toFixed(2)}
              />
            </FormItem>
          </Col>
        </Row>
      </>
    );
  };
  const changeAccountState = (showDivAccount) =>
    setState((prevState) => ({ ...prevState, showDivAccount }));

  const changeOtherReport = (showDivOtherReport) =>
    setState((prevState) => ({ ...prevState, showDivOtherReport }));

  const changeRecapState = (showDivRecap) =>
    setState((prevState) => ({ ...prevState, showDivRecap }));

  const changeState = (showDiv) => {
    setState((prevState) => ({ ...prevState, showDiv }));
  };

  const onChange = (e) =>
    setState((prevState) => ({ ...prevState, value: e.target.value }));

  return (
    <>
      <div className="tcov-wrapper full-wraps" ref={ref}>
        <Layout className="layout-wrapper">
          <Layout>
            <Content className="content-wrapper">
              <div className="fieldscroll-wrap">
                <div className="body-wrapper">
                  <article className="article">
                    <div className="box box-default">
                      <div className="box-body common-fields-wrapper">
                        {externalFormFields && state.frmVisible ? (
                          <NormalFormIndex
                            key={"key_" + state.frmName + "_0"}
                            formClass="label-min-height"
                            formData={state.formData}
                            showForm={true}
                            frmCode={state.frmName}
                            addForm={true}
                            extraFormFields={externalFormFields}
                            showToolbar={[
                              {
                                isLeftBtn: [],
                                isRightBtn: [
                                  {
                                    key: "s2",
                                    isSets: [
                                      // { key: "make_payment", isDropdown: 0, withText: "Make Payment", type: "", menus: null},
                                      {
                                        key: "statement_of_account",
                                        isDropdown: 0,
                                        withText: "Statement Of Account",
                                        type: "",
                                        menus: null,
                                        event: (key) => {
                                          changeState(!showDiv);
                                        },
                                      },
                                      // { key: "statementof_account", isDropdown: 0, withText: "Statement of Account", type: "", menus: null, "event": (key) => { }},   //"event": (key) => //{ changeAccountState(!showDivAccount) }},
                                      // { key: "other_report", isDropdown: 0, withText: "Other Report", type: "", menus: null, "event": (key) => { }},   //{ changeOtherReport(!showDivOtherReport) }},
                                      {
                                        key: "print",
                                        isDropdown: 0,
                                        withText: "Print",
                                        type: "",
                                        menus: null,
                                        event: (key) => {
                                          props.handlePrint();
                                        },
                                      },
                                    ],
                                  },
                                ],
                                isResetOption: false,
                              },
                            ]}
                            inlineLayout={true}
                            tableRowDeleteAction={(action, data) => {}}
                            //  onClickExtraIcon(action, data)

                            isShowFixedColumn={["-"]}
                          />
                        ) : undefined}
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>

      {showDiv === false ? (
        // <div className="reports-container"><HireStatementReport changeState={(e) => changeState(e)} /></div>
        <Modal
          title="Statement Of Account" //2 modal
          open={!showDiv}
          width="80%"
          onCancel={() => changeState(true)}
          style={{ top: "10px" }}
          bodyStyle={{ maxHeight: 790, overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          {/* {console.log("statement tcid props", tciID.chartered_from)} */}
          <ConfirmStatementAccount
            vesselID={tciID.vessel_id}
            chartrerID={tciID.chartered_from}
            tciID={tciID.tci_id}
            tcID={tciID.tc_id}
            from={tciID.delivery_date}
            to={tciID.redelivery_date}
          />
        </Modal>
      ) : undefined}
    </>
  );
});

export default StatementofAccount;
