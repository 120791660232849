export const ReturnTemplateTonnageOrder = (formData) => {
    return `
    <div>
    <div>
    <p><i>Please propose suitable cargo for below tonnage.</i></p>
    <table>
        <tbody>
            <tr><td><b>Open Date</b></td><td>${(formData.open_from ? formData.open_from : "NA")}</td></tr>
            <tr><td><b>Open Area</b></td><td>${(formData.open_position ? formData.open_position : "NA")}</td></tr>
            <tr><td><b>Vessel Name</b></td><td>${(formData.vessel_name ? formData.vessel_name : "NA")}</td></tr>
            <tr><td><b>DWT</b></td><td>${(formData.dwt ? formData.dwt : "NA")}</td></tr>
            <tr><td><b>IMO</b></td><td>${(formData.imo_no ? formData.imo_no : "NA")}</td></tr>
            <tr><td><b>Flag</b></td><td>${(formData.flag ? formData.flag : "NA")}</td></tr>
            <tr><td><b>Built Year</b></td><td>${(formData.built_year ? formData.built_year : "NA")}</td></tr>
            <tr><td><b>Bunker On Delivery</b></td><td>${(formData.bunker_on_delivery ? formData.bunker_on_delivery : "NA")}</td></tr>
            <tr><td><b>LOA</b></td><td>${(formData.loa ? formData.loa : "NA")}</td></tr>
            <tr><td><b>Beam</b></td><td>${(formData.beam ? formData.beam : "NA")}</td></tr>
            <tr><td><b>Hatches</b></td><td>${(formData.hatches ? formData.hatches : "NA")}</td></tr>
            <tr><td><b>Speed</b></td><td>${(formData.speed ? formData.speed : "NA")}</td></tr>
            <tr><td><b>Cons</b></td><td>${(formData.cons ? formData.cons : "NA")}</td></tr>
            <tr><td><b>Other Vessel Details</b></td><td>${(formData.vessel_other_details ? formData.vessel_other_details : "NA")}</td></tr>
        </tbody>
    </table>
</div>

    </div>
    `;

  };


  export const ReturnTemplateTonnageFirm = (formData) => {
    return `
    <html>
    <head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="Generator" content="Microsoft Word 15 (filtered medium)"></head>
        <div>
        <p><i>Please propose suitable cargo for below tonnage.</i></p>
       
          <table>
          <tbody>
          <tr><td><b>Vessel Name</b></td><td>${(formData.vesselName ? formData.vesselName : "NA")}</td></tr>
          <tr><td><b>Open Date</b></td><td>${(formData.openDate ? formData.openDate : "NA")}</td></tr>
          <tr><td><b>Open Area</b></td><td>${(formData.openArea ? formData.openArea : "NA")}</td></tr>
          <tr><td><b>BOD</b></td><td>${(formData.bod ? formData.bod : "NA")}</td></tr>
          <tr><td><b>Last 5 Cargo</b></td><td>${(formData.last5Cargo ? formData.last5Cargo : "NA")}</td></tr>
          <tr><td><b>ETA</b></td><td>${(formData.eta ? formData.eta : "NA")}</td></tr>
          <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
          <tr><td><i>Vessel Particulars</i></td><td>&nbsp;</td></tr>
          <tr><td><b>LOA</b></td><td>${(formData.loa ? formData.loa : "NA")}</td></tr>
          <tr><td><b>BEAM</b></td><td>${(formData.beam ? formData.beam : "NA")}</td></tr>
          <tr><td><b>DWT</b></td><td>${(formData.dwt ? formData.dwt : "NA")}</td></tr>
          <tr><td><b>Hatches</b></td><td>${(formData.hatches ? formData.hatches : "NA")}</td></tr>
          <tr><td><b>GRT</b></td><td>${(formData.grt ? formData.grt : "NA")}</td></tr>
          <tr><td><b>NRT</b></td><td>${(formData.nrt ? formData.nrt : "NA")}</td></tr>
          <tr><td><b>Sea Consumption</b></td><td>${(formData.seaConsumption ? formData.seaConsumption : "NA")}</td></tr>
          <tr><td><b>Port Consumption</b></td><td>${(formData.portConsumption ? formData.portConsumption : "NA")}</td></tr>
          <tr><td><b>Holds Cubic Feet Capacity</b></td><td>${(formData.holdsCubicFeetCapacity ? formData.holdsCubicFeetCapacity : "NA")}</td></tr>
          <tr><td><b>Sizes of Hatches LxB</b></td><td>${(formData.sizesOfHatches ? formData.sizesOfHatches : "NA")}</td></tr>
          <tr><td><b>Numbers of Cranes</b></td><td>${(formData.numOfCranes ? formData.numOfCranes : "NA")}</td></tr>
          <tr><td><b>Numbers of Grabs</b></td><td>${(formData.numOfGrabs ? formData.numOfGrabs : "NA")}</td></tr>
          <tr><td><b>Agent Details</b></td><td>${(formData.agentDetails ? formData.agentDetails : "NA")}</td></tr>
          <tr><td><b>Account Details</b></td><td>${(formData.accountDetails ? formData.accountDetails : "NA")}</td></tr>
          <tr><td><b>Hire Rate</b></td><td>${(formData.hireRate ? formData.hireRate : "NA")}</td></tr>
          <tr><td><b>Bunker Rates</b></td><td>${(formData.bunkerRates ? formData.bunkerRates : "NA")}</td></tr>
          <tr><td><b>ILOHC Rates</b></td><td>${(formData.ilohcRates ? formData.ilohcRates : "NA")}</td></tr>
          <tr><td><b>Other Rates</b></td><td>${(formData.otherRates ? formData.otherRates : "NA")}</td></tr>
          <tr><td><b>Address Commission</b></td><td>${(formData.addressCommission ? formData.addressCommission : "NA")}</td></tr>
          <tr><td><b>Broker Commission</b></td><td>${(formData.brokerCommission ? formData.brokerCommission : "NA")}</td></tr>
          <tr><td><b>Last 3 Cargo</b></td><td>${(formData.last3Cargo ? formData.last3Cargo : "NA")}</td></tr>
          <tr><td><b>Last 3 Charterers</b></td><td>${(formData.last3Charterers ? formData.last3Charterers : "NA")}</td></tr>
          <tr><td><b>Last 3 Ports</b></td><td>${(formData.last3Ports ? formData.last3Ports : "NA")}</td></tr>
          <tr><td><b>Name of Hull or Hull No</b></td><td>${(formData.hullOrHullNo ? formData.hullOrHullNo : "NA")}</td></tr>
          <tr><td><b>Class</b></td><td>${(formData.Class ? formData.Class : "NA")}</td></tr>
          <tr><td><b>Last Drydock</b></td><td>${(formData.lastDrydock ? formData.lastDrydock : "NA")}</td></tr>
          <tr><td><b>Last Special Survey</b></td><td>${(formData.lastSpecialSurvey ? formData.lastSpecialSurvey : "NA")}</td></tr>
          <tr><td><b>Next DD</b></td><td>${(formData.nextDD ? formData.nextDD : "NA")}</td></tr>
          <tr><td><b>SS DUE</b></td><td>${(formData.ssDue ? formData.ssDue : "NA")}</td></tr>
          <tr><td><b>Collisions in the last 12 months</b></td><td>${(formData.collisionsLast12Months ? formData.collisionsLast12Months : "NA")}</td></tr>
          <tr><td><b>Details of any PSC detention in the last 12 months</b></td><td>${(formData.pscDetentionLast12Months ? formData.pscDetentionLast12Months : "NA")}</td></tr>
          <tr><td><b>Other Details</b></td><td>${(formData.otherDetails ? formData.otherDetails : "NA")}</td></tr>
          </tbody>
          </table>
     
        </div>
        </html>
    `;

  };

  export const ReturnTemplateCargoOrder = (formData) => {
    console.log("formData cargo",formData)
    return `
    <html>
    <head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="Generator" content="Microsoft Word 15 (filtered medium)">
   </head>
        <div>
            <div>
            <p><i>Please propose suitable tonnage for below cargo.</i></p>
            <table>

            <tbody>
                    <tr><td><b>Cargo Name</b></td>  <td>${(formData?.cargo_name ? formData.cargo_name : "NA")} </td></tr>
                    <tr><td><b>Account For</b></td><td> ${(formData?.account_for ? formData.account_for : "NA")}</td></tr>
                    <tr><td><b>Cargo Details</b></td><td> ${(formData?.cargo_details ? formData.cargo_details : "NA")}</td></tr>
                    <tr><td><b>CP Qty</b></td><td>${(formData?.cp_qty ? formData.cp_qty : "NA")} mt </td></tr>
                    <tr><td><b>Min Qty</b></td><td>${(formData?.min_qty ? formData.min_qty : "NA")} mt </td></tr>
                    <tr><td><b>Max Qty</b></td><td> ${(formData?.max_qty ? formData.max_qty : "NA")} mt</td></tr>
                    <tr><td><b>Freight Rate</b></td><td> ${(formData?.fright_rate ? formData.fright_rate : "NA")}</td></tr>
                    <tr><td><b>Broker Commission %</b></td><td> ${(formData?.broker_commission ? formData.broker_commission : "NA")} %</td></tr>
                    <tr><td><b>Load Port Options</b></td><td> ${(formData?.load_port_options ? formData.load_port_options : "NA")}</td></tr>
                    <tr><td><b>Discharge Port Options</b></td><td> ${(formData?.discharge_port_options ? formData.discharge_port_options : "NA")}</td></tr>
                    <tr><td><b>Load Term</b></td><td> ${(formData?.load_term ? formData.load_term : "NA")}</td></tr>
                    <tr><td><b>Discharge Load Term</b></td> <td> ${(formData?.discharge_load_term ? formData.discharge_load_term : "NA")}</td></tr>
                    <tr><td><b>DEM</b></td><td> ${(formData?.dem ? formData.dem : "NA")}</td></tr>
                    <tr><td><b>DES</b></td><td> ${(formData?.des ? formData.des : "NA")}</td></tr>
                    <tr><td><b>Other Details</b></td>  <td> ${(formData?.other_details ? formData.other_details : "NA")}</td></tr>
                    </tbody>

                </table>
            </div>
        </div>
        </html>
    `;

  };

  export const ReturnTemplateCargoFirm = (formData) => {
    return `
    <html>
    <head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="Generator" content="Microsoft Word 15 (filtered medium)"></head>
        <div>
        <div style={{display:"flex"}}>
      <p>  <i>Please propose suitable Tonnage for below cargo.</i></p>
                <table>
                <tr><td><b>Cargo Quantity</b></td><td>${(formData.cargoQuantity ? formData.cargoQuantity : "NA")}</td></tr>
                <tr><td><b>Load Port</b></td><td>${(formData.loadPort ? formData.loadPort : "NA")}</td></tr>
                <tr><td><b>Discharge Port</b></td><td>${(formData.dischargePort ? formData.dischargePort : "NA")}</td></tr>
                <tr><td><b>Laycan</b></td><td>${(formData.laycan ? formData.laycan : "NA")}</td></tr>
                <tr><td><b>Load Rate</b></td><td>${(formData.loadRate ? formData.loadRate : "NA")}</td></tr>
                <tr><td><b>Discharge Rate</b></td><td>${(formData.dischargeRate ? formData.dischargeRate : "NA")}</td></tr>
                <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><td><b>Owner</b></td><td>${(formData.owner ? formData.owner : "NA")}</td></tr>
                <tr><td><b>Disponent Owner</b></td><td>${(formData.disponentOwner ? formData.disponentOwner : "NA")}</td></tr>
                <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><td><i>Vessel Particulars</i></td><td>&nbsp;</td></tr>
                <tr><td><b>LOA</b></td><td>${(formData.loa ? formData.loa : "NA")}</td></tr>
                <tr><td><b>BEAM</b></td><td>${(formData.beam ? formData.beam : "NA")}</td></tr>
                <tr><td><b>DWT</b></td><td>${(formData.dwt ? formData.dwt : "NA")} mt</td></tr>
                <tr><td><b>Hatches</b></td><td>${(formData.hatches ? formData.hatches : "NA")}</td></tr>
                <tr><td><b>GRT</b></td><td>${(formData.grt ? formData.grt : "NA")}</td></tr>
                <tr><td><b>NRT</b></td><td>${(formData.nrt ? formData.nrt : "NA")}</td></tr>
                <tr><td><b>Sea Consumption</b></td><td>${(formData.seaConsumption ? formData.seaConsumption : "NA")}</td></tr>
                <tr><td><b>Port Consumption</b></td><td>${(formData.portConsumption ? formData.portConsumption : "NA")}</td></tr>
                <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><td><b>Beneficiary Account</b></td><td>${(formData.beneficiaryAccount ? formData.beneficiaryAccount : "NA")}</td></tr>
                <tr><td><b>Beneficiary Bank Details</b></td><td>${(formData.beneficiaryBankDetails ? formData.beneficiaryBankDetails : "NA")}</td></tr>
                <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                <tr><td><b>Charterers</b></td><td>${(formData.charterers ? formData.charterers : "NA")}</td></tr>
                <tr><td><b>Freight Rate</b></td><td>${(formData.freightRate ? formData.freightRate : "NA")}</td></tr>
                <tr><td><b>Demurage Rate</b></td><td>${(formData.demurrageRate ? formData.demurrageRate : "NA")}</td></tr>
                <tr><td><b>Despatch Rate</b></td><td>${(formData.despatchRate ? formData.despatchRate : "NA")}</td></tr>
                <tr><td><b>Address Commm</b></td><td>${(formData.addressComm ? formData.addressComm : "NA")}</td></tr>
                <tr><td><b>Broker Comm</b></td><td>${(formData.brokerComm ? formData.brokerComm : "NA")}</td></tr>
                <tr><td><b>Other Rates</b></td><td>${(formData.otherRates ? formData.otherRates : "NA")}</td></tr>                
                </table>
            </div>
        </div>
      
  `;

  };