import React, { useState } from "react";
import { Form, Button, Spin, Layout, Alert, Input } from "antd";
import { useStateCallback } from "../../../../../shared";
const { Content } = Layout;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};
const FormItem = Form.Item;
const WorldScale = (props) => {
  const [state, setState] = useStateCallback({
    frmName: "worldscale_rate",
    // formData: {
    //   ws_flat: 0,
    //   freight_rate: 0,
    //   frat_rate: 0,
    //   ttl_freight: null,
    // },
    frmVisible: true,
  });
  const [formData, setFormData] = useState({
    ws_flat: 0,
    freight_rate: 0,
    frat_rate: 0,
    ttl_freight: null,
  });

  const ImportFormData = () => {
    props.modalCloseEvent(formData);
  };

  const handleChange = (name, value) => {
    if (name === "freight_rate") {
      let cpQty = props?.formData?.cp_qty ?? 0;
      let wsFlat = isNaN(formData.ws_flat) ? 0 : formData.ws_flat * 1;
      let freightRate = isNaN(value) ? 0 : value * 1;
      let finalFreight = wsFlat * freightRate * 0.01;
      let TotalFreight = cpQty * finalFreight;

    
      setFormData({
        ...formData,
        [name]: value,
        frat_rate: isNaN(finalFreight) ? 0 : finalFreight.toFixed(2),
        ttl_freight: isNaN(TotalFreight) ? 0 : TotalFreight.toFixed(2),
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <div className="tcov-wrapper full-wraps">
      <Layout className="layout-wrapper">
        <Content className="content-wrapper">
          <div className="fieldscroll-wrap">
            {state.frmVisible ? (
              <div className="body-wrapper">
                <article className="article">
                  <div className="box box-default">
                    <div className="box-body">
                      <Form>
                        <FormItem
                          {...formItemLayout}
                          label="Select From Existing Route"
                        ></FormItem>
                        <FormItem {...formItemLayout} label="WS 100 (Flat)">
                          <Input
                            type={"number"}
                            name="ws_flat"
                            value={formData["ws_flat"]}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            addonAfter="$/MT"
                          />
                        </FormItem>

                        <FormItem {...formItemLayout} label="Frieght rate (%)">
                          <Input
                            type={"number"}
                            className="ant-input"
                            name="freight_rate"
                            value={formData["freight_rate"]}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </FormItem>

                        <FormItem {...formItemLayout} label="Final flat rate">
                          <Input
                            type={"number"}
                            name="frat_rate"
                            value={formData["frat_rate"]}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            addonAfter="$/MT"
                          />
                        </FormItem>

                        <FormItem {...formItemLayout} label="Total freight">
                          <Input
                            type={"number"}
                            className="ant-input"
                            name="ttl_freight"
                            value={formData["ttl_freight"]}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </FormItem>

                        <div className="action-btn text-right">
                          <Button
                            type="primary"
                            onClick={() => ImportFormData()}
                          >
                            Import
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </article>
              </div>
            ) : (
              <div className="col col-lg-12">
                <Spin tip="Loading...">
                  <Alert message=" " description="Please wait..." type="info" />
                </Spin>
              </div>
            )}
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default WorldScale;
