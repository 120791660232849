import React from 'react';
import {CloseOutlined} from '@ant-design/icons';
import { List, AutoSizer, ScrollSync } from "react-virtualized";
import FilterLocalDbViewItem from './local-filter-view';

const VesselLargeListFilter = ({
  listData = [],
  onGetSelectedData,
  onCloseFilter,
  handleSearchValue,
  closeVesselList
}) => {

  const renderRow = ({ index, key, style }) => {
    const dataset = listData[index]; 
    return (
      <FilterLocalDbViewItem
        key={key}
        style={style}
        dataset={dataset}
        onGetSelectedData={onGetSelectedData}
      />
    )
  }

  return (
    <div className="filter-search-list" style={{ width: "450px" }}>
      <div className="close-icon-wrapper" onClick={() => {
        onCloseFilter();
        closeVesselList()
      }}>
      <CloseOutlined />
      </div>
      <div className="close-icon" >
        <input type="text" onChange={handleSearchValue} placeholder="Search vessel list by name" />
      </div>
      <div className="list-data-wrapper">
        <ScrollSync>
          {({ onScroll, scrollTop, isScrolling }) => (
            <div className="list-data" style={{ height: "calc(100vh - 180px)" }}>
              <AutoSizer>
                {
                  ({ width, height }) => {
                    return (
                      <List
                        width={width}
                        height={height}
                        rowHeight={140}
                        onScroll={onScroll}
                        rowRenderer={renderRow}
                        rowCount={listData.length}
                        overscanRowCount={15}
                        scrollTop={scrollTop}
                        isScrolling={isScrolling}
                      />
                    )
                  }
                }
              </AutoSizer>
            </div>
          )
          }
        </ScrollSync>

        {
          !(listData.length) && <div>no data found</div>
        }
      </div>
    </div>
  );
};

export default VesselLargeListFilter;